<template>
  <v-container fluid style="height: 100%" class="d-flex align-center">
    <v-row v-if="!visitor_info" class="text-center">
        <v-col cols="12">
            <p class=" text-h4">
                Autorización de Visitante
            </p>
        </v-col>
        <v-col cols="12">
            <i
                v-if="allowed"
                class="fal fa-shield-check success--text ma-4"
                style="font-size: 100px"
            ></i>
            <i
                v-if="!allowed"
                class="fal fa-ban error--text ma-4"
                style="font-size: 100px"
            ></i>
            <p class="grey--text text-subtitle-2">
                {{ allowed ? 'ACCESO APROBADO' : 'ACCESO DENEGADO' }}
            </p>
        </v-col>
        <v-col cols="12" class="d-flex justify-center align-center mt-4">
            <v-img max-width="110px" src="../assets/logo.png"></v-img>
        </v-col>
    </v-row>
    <v-row v-if="visitor_info" class="text-center">
      <v-col cols="12">
        <p class=" text-h4">
          Validación de Visitante
        </p>
      </v-col>
      <v-col v-if="visitor_info.solved == true" cols="12">
        <i
          class="fal fa-exclamation-circle yellow--text ma-4"
          style="font-size: 100px"
        ></i>
        <p class="grey--text text-subtitle-2">
          ESTE TICKET YA FUE AUTORIZADO POR OTRO USUARIO
        </p>
      </v-col>
      <v-col v-if="visitor_info.solved == false" cols="12">
        <p class="grey--text text-subtitle-1">
          Por favor verifique la identidad y autorice el ingreso de la siguiente persona:
        </p>
        <p class="grey--text text-subtitle-2 mt-4">
          NOMBRE: <strong class="white--text">{{ visitor_info.visitor_name }}</strong>
        </p>
        <p class="grey--text text-subtitle-2">
          CEDULA: <strong class="white--text">{{ visitor_info.document_number }}</strong>
        </p>
      </v-col>
      <v-col v-if="visitor_info.solved == false" cols="12" class="d-flex justify-center align-center">
        <v-img
            :src="visitor_info.face_img"
            class="white--text rounded-edges"
            max-height="200px"
            max-width="250px"
        ></v-img>
      </v-col>
      <v-col v-if="visitor_info.solved == false" cols="12">
        <v-row class="pa-0 ma-0">
            <v-col cols="12" class="mb-0 pb-0 d-flex justify-center align-center">
                <v-btn min-width="200" color="success" @click="auth(true)">AUTORIZAR</v-btn>
            </v-col>
            <v-col cols="12" class="mt-1 pt-1 d-flex justify-center align-center">
                <v-btn min-width="200" color="red" @click="auth(false)">NO AUTORIZAR</v-btn>
            </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="d-flex justify-center align-center">
        <v-img max-width="110px" src="../assets/logo.png"></v-img>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";

export default {
  name: "VisitorValidation",
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    visitor_info: null,
    allowed: false
  }),
  methods: {
    getImage() {
        let image_uuid = "ad3d6940-464a-11ed-814a-00e04c36003b.jpg"
         this.visitor_info = {
            name: "ALEXANDER OSSA MONTOYA",
            document_number: "1037608589",
            //face_img: globals.APIURL + "entry/registry-pic/" + image_uuid
            face_img: globals.APIURL + "entry/daily-pic/" + image_uuid
        }
    },

    getTicket(ID) {
      axios
        .post(globals.APIURL + "entry/visitor-ticket", {
          uuid: ID,
        })
        .then((res) => {
          if (res.data) {
            console.log(res.data)
            res.data[0].face_img = globals.APIURL + "entry/daily-pic/" + res.data[0].img_visitor
            this.visitor_info = res.data[0]
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    auth(allow_entry) {
      delete this.visitor_info.face_img
      this.visitor_info.solved = true;
      this.visitor_info.allowed_entry = allow_entry;
      this.visitor_info.solved_date = globals.COdate();
      axios
        .post(globals.APIURL + "entry/solve-visitor-auth", {
          ticketInfo: this.visitor_info
        })
        .then((res) => {
          if (res.status == 200) {
            this.allowed = allow_entry
            this.visitor_info = null
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.getTicket(this.$route.query.ticket)
  },
};
</script>
<style>
tr:hover {
  background-color: transparent !important;
}
</style>
