<template>
  <div>
    <v-row class="text-center">
      <v-col cols="12" class="d-flex justify-center align-center">
        <span class="text-subtitle-1">Documento Usuario: </span>
        <strong class="white--text mx-2">{{ registryData.document_number }}</strong>
      </v-col>
      <v-col cols="12" class="d-flex justify-center align-center">
        <span class="text-subtitle-1">Nombre Usuario: </span>
        <strong class="white--text mx-2">{{
          registryData.first_name +
          " " +
          registryData.first_last_name +
          " " +
          registryData.second_last_name
        }}</strong>
      </v-col>
      <v-col cols="12" class="d-flex justify-center align-center">
        <span class="text-subtitle-1 mr-2">Almuerzos asignados: </span>
        <div v-if="!editMode">
          <strong class="white--text mr-2">{{ registryData.lunch_number != null && registryData.lunch_number > 0 ? registryData.lunch_number : "S/A" }}</strong>
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="primary"
            @click="editMode = !editMode"
          >
            <v-icon dark>
              mdi-lead-pencil
            </v-icon>
          </v-btn>
        </div>
        <div v-if="editMode" class="d-flex align-center">
          <v-text-field
            v-model="registryData.lunch_number"
            type="number"
            label="Cantidad de Almuerzos"
            outlined
            dense
            hide-details
            dark
            color="secondary"
          ></v-text-field>
          <v-btn
            class="mx-1"
            fab
            dark
            x-small
            color="success"
            @click="saveLunchCount(registryData.lunch_number)"
          >
            <v-icon dark>
              mdi-content-save
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-1"
            fab
            dark
            x-small
            color="red"
            @click="editMode = !editMode"
          >
            <v-icon dark>
              mdi-cancel
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="cancel"
          rounded
          large
          outlined
          class="px-4"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
import auth from "../../services/auth";

export default {
  name: "AdminLunch",   
  props: {
    registryData: {
      type: Object,
    },
  },
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    snackbarCreation: false,
    snackbarColorCreation: "",
    snackbarTextCreation: "",
    loading: false,
    dialogDelete: false,
    editMode: false
  }),
  methods: {
    saveLunchCount(lunchCount) {
      let sedes = []
      for (let i = 0; i < this.registryData.company_person.Campus_company_person.length; i++) {
        const element = this.registryData.company_person.Campus_company_person[i];

        sedes.push(element.campus.uuid)
      }

      let body = {
        company_id: auth.getUserEnterpriseID(),
        sedes: sedes,
        document_number: this.registryData.document_number,
        lunch_number: lunchCount
      }

      console.log(body)

      axios({
          method: "POST",
          url: "https://tob9omblt1.execute-api.us-east-1.amazonaws.com/Prod/lunch/",
          data: JSON.stringify(body),
        })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            this.editMode = false;
            this.snackbarText = "Se añadieron correctamente los almuerzos.";
            this.snackbarColor = "success";
            this.snackbar = true;
          }
        });
    }
  },
  mounted() {
    console.log("PERSONA: ", this.registryData);
  },
};
</script>
<style>
</style>
