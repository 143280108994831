const logout = () => {
    localStorage.removeItem('access-token');
    localStorage.removeItem('id');
    localStorage.removeItem('enterpriseID');
    localStorage.removeItem('enterpriseName');
    localStorage.removeItem('username');
    localStorage.removeItem('name');
    localStorage.removeItem('lastname');
    localStorage.removeItem('role');
    localStorage.removeItem('role-name');
    localStorage.removeItem('selected-sede');
    localStorage.removeItem('modules');
    localStorage.removeItem('sede-name');
    localStorage.removeItem('grouped-zones');
    localStorage.removeItem("Vang_asset");
    localStorage.removeItem("Vang_rol");
    localStorage.removeItem("Vang_org");
    localStorage.removeItem("Vang_sede");
};

const loggedIn = () => {
    return localStorage.getItem('access-token') !== null;
};

const getUserID = () => {
    return localStorage.getItem('id');
};

const getUserEnterpriseID = () => {
    return localStorage.getItem('enterpriseID');
};

const getUserEnterpriseName = () => {
    return localStorage.getItem('enterpriseName');
};

const getUsername = () => {
    return localStorage.getItem('username');
};

const getName = () => {
    return localStorage.getItem('name');
};

const getLastname = () => {
    return localStorage.getItem('lastname');
};

const getRole = () => {
    return localStorage.getItem('role');
};

const getRoleName = () => {
    return localStorage.getItem('role-name');
};

const getSelectedSede = () => {
    return localStorage.getItem('selected-sede');
};

const getModules = () => {
    return localStorage.getItem('modules');
};

const getSedeName = () => {
    return localStorage.getItem('sede-name');
};

const getGZ = () => {
    return JSON.parse(localStorage.getItem('grouped-zones'));
};

// --------------------------------------------------------
const get_org = () => {
    return localStorage.getItem('Vang_org');
};

const get_org_sede = () => {
    return localStorage.getItem('Vang_sede');
};

const get_asset_id = () => {
    return localStorage.getItem('Vang_asset');
};

const get_rol = () => {
    return localStorage.getItem('Vang_rol');
};

// ** Schedule localstorageItems ---------------------------

const scheduleLogout = () => {
    localStorage.removeItem('schedule-person-id');
    localStorage.removeItem('schedule-company-person-id');
    localStorage.removeItem('schedule-company-id');
    localStorage.removeItem('schedule-campus-id');
    localStorage.removeItem('schedule-suborg-id');
}

const schedule_loggedin = () => {
    return localStorage.getItem('schedule-person-id') !== null;
};

const get_schedule_person_id = () => {
    return localStorage.getItem('schedule-person-id');
};

const get_schedule_company_person_id = () => {
    return localStorage.getItem('schedule-company-person-id');
};

const get_schedule_company_id = () => {
    return localStorage.getItem('schedule-company-id');
};

const get_schedule_campus_id = () => {
    return localStorage.getItem('schedule-campus-id');
};

const get_schedule_suborg_id = () => {
    return localStorage.getItem('schedule-suborg-id');
};

export default { getGZ, logout, loggedIn, getUserID, getUserEnterpriseID, getUserEnterpriseName, getUsername, getName, getLastname, getRole, getRoleName, get_asset_id, get_rol, get_org, get_org_sede, getSelectedSede, getModules, getSedeName, scheduleLogout, schedule_loggedin, get_schedule_person_id, get_schedule_company_person_id, get_schedule_company_id, get_schedule_campus_id, get_schedule_suborg_id }