<template>
  <v-container>
     <div style="max-width: 300px; margin: 30px auto;" id="initDoc">
        <img style="width: 100%;" src="../assets/logo.png">
    </div>

    <div class="ctDoc">
        <div>
            <br/><br/>
            <p><a href="#doc01" style="color: inherit"><i class="fal fa-link"></i> MANUAL DE POLITICAS Y PROCEDIMIENTOS PARA EL TRATAMIENTO DE DATOS PERSONALES</a></p>
            <br/>
            <p><a href="#doc02" style="color: inherit"><i class="fal fa-link"></i> CONSENTIMIENTO PARA TRATAMIENTO DE DATOS WEB</a></p>
            <br/>
            <p><a href="#doc03" style="color: inherit"><i class="fal fa-link"></i> AVISO DE PRIVACIDAD</a></p>
            <br/><br/>
        </div>
        <div>
            <br/>
            <hr>
            <br/><br/><br/><br/><br/>
            <div id="doc01">
                <p>&nbsp;</p>
                <h2>MANUAL DE POLITICAS Y PROCEDIMIENTOS PARA EL TRATAMIENTO DE DATOS PERSONALES VANGUARD TECHNOLOGIES S.A.S.</h2>
                <br>
                <ul style="margin-left: 20px;">
                    <li>
                        <p>Contexto Legal.</p>
                    </li>
                    <li>
                        <p>Definiciones.</p>
                    </li>
                    <li>
                        <p>Aceptaci&oacute;n del Manual Corporativo de Pol&iacute;ticas y Procedimientos para el Tratamiento de Datos Personales.</p>
                    </li>
                    <li>
                        <p>Responsable del Tratamiento de Datos Personales.</p>
                    </li>
                    <li>
                        <p>Finalidades del Tratamiento de Datos Personales.</p>
                    </li>
                    <li>
                        <p>Derechos de los Titulares de Datos Personales.</p>
                    </li>
                    <li>
                        <p>Contacto de los Titulares de Datos Personales.</p>
                    </li>
                    <li>
                        <p>Ejercicio de Derechos.</p>
                        <ul style="margin-left: 20px;">
                            <li>
                                <p>Derecho de Acceso y Consulta.</p>
                            </li>
                            <li>
                                <p>Derecho de Queja y Reclamo.</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>Tratamiento de Datos Sensibles y de Menores de Edad.</p>
                    </li>
                    <li>
                        <p>Pol&iacute;ticas de Seguridad.</p>
                    </li>
                    <li>
                        <p>Transferencia Internacional de Datos.</p>
                    </li>
                    <li>
                        <p>Vigencia.</p>
                    </li>
                </ul>
                <p><br></p>
                <p><br></p>
                <p style="text-align: center;"><b>I. CONTEXTO LEGAL</b></p>
                <br>
                <p>El Tratamiento de los Datos Personales se hace en cumplimiento de la Ley Estatutaria 1581 de 2012, por la cual se dictan disposiciones generales para la protecci&oacute;n de datos personales, en especial, de los Art&iacute;culos 17, Literal K, y 18, Literal F, el Art&iacute;culo 13 de la Ley 1377 de 2013, por la cual se reglamenta la precedente Ley y los Art&iacute;culos 15 y 20 de la Constituci&oacute;n Pol&iacute;tica de Colombia.</p>
                <p>El presente Manual Corporativo de Pol&iacute;ticas y Procedimientos para el Tratamiento de Datos Personales ser&aacute; aplicable a la totalidad de los datos recogidos por El Responsable de dicho tratamiento.</p>
                <br>
                <p style="text-align: center;"><b>II. DEFINICIONES</b></p>
                <br>
                <p>Los conceptos que se definen a continuaci&oacute;n son, en su esencia, los consagrados en el Art&iacute;culo 3 del Decreto 1377 de 2013:</p>
                <p><br></p>
                <ol style="margin-left: 20px;">
                    <li>
                        <p>Autorizaci&oacute;n:&nbsp;Consentimiento previo, expreso e informado de El Titular para llevar a cabo el tratamiento de datos personales.</p>
                    </li>
                    <li>
                        <p>Aviso de privacidad:&nbsp;Comunicaci&oacute;n verbal o escrita generada por El Responsable, dirigida al Titular para el tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de la pol&iacute;tica de tratamiento de datos que le ser&aacute; aplicable, la forma de acceder a las misma y las finalidades del tratamiento que se pretende dar a los datos personales.</p>
                    </li>
                    <li>
                        <p>Base de datos:&nbsp;Conjunto organizado de datos personales que sea objeto de tratamiento.</p>
                    </li>
                    <li>
                        <p>Datos personales:&nbsp;Cualquier informaci&oacute;n vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.</p>
                    </li>
                    <li>
                        <p>Datos p&uacute;blicos:&nbsp;Datos que no sean semiprivados, privados o sensibles. Son considerados datos p&uacute;blicos, entre otros, los datos relativos al estado civil de las personas, a su profesi&oacute;n u oficio y a su calidad de comerciante o de servidor p&uacute;blico. Por su naturaleza, los datos p&uacute;blicos pueden estar contenidos, entre otros, en registros p&uacute;blicos, documentos p&uacute;blicos, gacetas y boletines oficiales y sentencias judiciales debidamente ejecutoriadas que no est&eacute;n sometidas a reserva.</p>
                    </li>
                    <li>
                        <p>Datos sensibles:&nbsp;Datos que afectan la intimidad de El Titular o cuyo uso indebido puede conducir a su discriminaci&oacute;n, tales como aquellos que revelen el origen racial o &eacute;tnico, la orientaci&oacute;n pol&iacute;tica, las convicciones religiosas o filos&oacute;ficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promuevan intereses de cualquier partido pol&iacute;tico o que garanticen los derechos y garant&iacute;as de partidos pol&iacute;ticos de oposici&oacute;n, as&iacute; como los datos relativos a la salud, a la vida sexual y los datos biom&eacute;tricos.</p>
                    </li>
                    <li>
                        <p>Encargado del tratamiento:&nbsp;Persona natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio con otros, realice el tratamiento de datos personales por cuenta de El Responsable del tratamiento.</p>
                    </li>
                    <li>
                        <p>Responsable del tratamiento:&nbsp;Persona natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio con otros, decida sobre la base de datos y/o el tratamiento de los datos en &eacute;sta contenidos.</p>
                    </li>
                    <li>
                        <p>Titular:&nbsp;Persona natural cuyos datos personales sean objeto de tratamiento.</p>
                    </li>
                    <li>
                        <p>Transferencia:&nbsp;La transferencia de datos tiene lugar cuando El Responsable y/o Encargado del tratamiento de datos personales, ubicado en la Rep&uacute;blica de Colombia, env&iacute;a la informaci&oacute;n o los datos personales a un receptor, que a su vez es responsable del tratamiento y se encuentra ubicado dentro o fuera del pa&iacute;s.</p>
                    </li>
                    <li>
                        <p>Transmisi&oacute;n:&nbsp;La transmisi&oacute;n de datos personales tiene lugar cuando El Responsable remite los datos personales a El Encargado para su tratamiento, dentro o fuera del territorio de la Rep&uacute;blica de Colombia.</p>
                    </li>
                    <li>
                        <p>Tratamiento:&nbsp;Cualquier operaci&oacute;n o conjunto de operaciones que recaigan sobre datos personales, tales como su recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o supresi&oacute;n.</p>
                    </li>
                </ol>
                <p><br></p>
                <p style="text-align: center;"><b>III. ACEPTACI&Oacute;N DEL MANUAL CORPORATIVO DE POL&Iacute;TICAS Y PROCEDIMIENTOS PARA EL TRATAMIENTO DE DATOS PERSONALES</b></p>
                <br>
                <p>En consonancia con el Art&iacute;culo 9 de la Ley Estatutaria 1581 de 2012, todo tratamiento que verse sobre datos personales requerir&aacute; autorizaci&oacute;n previa de El Titular. Los Titulares entienden que quien facilite informaci&oacute;n estimada como datos personales, acepta de manera expresa que estos ser&aacute;n tratados por&nbsp;VANGUARD TECHNOLOGIES S.A.S., tal y como se establece en el presente Manual.</p>
                <br/>
                <p>En el mismo sentido, esta norma establece que no se requerir&aacute; autorizaci&oacute;n para el tratamiento de los siguientes datos:</p>
                <br>
                <ol style="margin-left: 20px;">
                    <li>
                        <p>Datos de naturaleza p&uacute;blica;</p>
                    </li>
                    <li>
                        <p>Informaci&oacute;n que sea requerida por autoridades p&uacute;blicas, en ejercicio de sus funciones, a trav&eacute;s de actos administrativos, sentencias judiciales o cualquier otro de car&aacute;cter legal;</p>
                    </li>
                    <li>
                        <p>Datos relacionados con el Registro Civil de las personas;</p>
                    </li>
                    <li>
                        <p>Casos de urgencia m&eacute;dica o sanitaria; y</p>
                    </li>
                    <li>
                        <p>Tratamiento de informaci&oacute;n autorizado por la Ley para fines hist&oacute;ricos, estad&iacute;sticos o cient&iacute;ficos.</p>
                    </li>
                </ol>
                <p><br></p>
                <p style="text-align: center;"><b>IV. RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES</b></p>
                <br>
                <p>Las bases de datos objeto de esta Pol&iacute;tica son responsabilidad, en los t&eacute;rminos de la Ley 1581 de 2012, de&nbsp;VANGUARD TECHNOLOGIES S.A.S.,&nbsp;identificado con el NIT. 901370664 - 5, compa&ntilde;&iacute;a ubicada en la Carrera 43a No. 19 &ndash; 17, Centro Empresarial Block, Oficina 607 del Municipio de Medell&iacute;n, Antioquia (en adelante, &ldquo;El Responsable&rdquo;), la cual podr&aacute; ser contactada a trav&eacute;s del correo electr&oacute;nico:&nbsp;<a href="mailto:mr@vanguardvision.ai" style="color: inherit">mr@vanguardvision.ai</a></p>
                <p><br></p>
                <p style="text-align: center;"><b>V. FINALIDADES DEL TRATAMIENTO DE DATOS PERSONALES</b></p>
                <br>
                <p>En el desarrollo del giro ordinario de sus negocios EL RESPONSABLE realizar&aacute; un tratamiento de los datos personales correspondientes a su recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o supresi&oacute;n de los datos personales de personas naturales y jur&iacute;dicas, contenidas en bases de datos para finalidades legitimas, en cumplimiento de la Constituci&oacute;n Pol&iacute;tica de Colombia y la Ley. Los datos personales ser&aacute;n utilizados con fines de videovigilancia, a partir del an&aacute;lisis de datos biom&eacute;tricos que facilite el reconocimiento facial y la autenticaci&oacute;n de la identificaci&oacute;n de personas naturales, la detecci&oacute;n de posibles riesgos y amenazas a la seguridad de sus clientes, realizar activaciones de marca, estructurar y ejecutar estrategias de mercadeo, env&iacute;o de promociones, para fines estad&iacute;sticos, conocer y examinar el comportamiento documental del cliente, efectuar las gestiones necesarias tendientes a confirmar y actualizar la informaci&oacute;n del cliente para cumplir con las funciones y obligaciones procedentes de diversas operaciones realizadas a trav&eacute;s del comercio electr&oacute;nico, establecer, mantener y/o terminar una relaci&oacute;n contractual, conocer la ubicaci&oacute;n y datos de contacto del cliente para el env&iacute;o de notificaciones con fines de seguridad, almacenamiento de informaci&oacute;n de navegaci&oacute;n de la plataforma&nbsp;vanguardtech.solutions, uso de cookies de navegaci&oacute;n, construcci&oacute;n del perfilamiento de usuario, env&iacute;o de facturas y/o documentos de compra, transferencia y/o transmisi&oacute;n a terceros, y cualquier otra actividad conexa a la actividad comercial desarrollada por El Responsable.</p>
                <br/>
                <p>Los sistemas de navegaci&oacute;n y los softwares necesarios para el funcionamiento de esta plataforma recogen datos personales, la trasmisi&oacute;n de estos es inherente al funcionamiento de los sistemas de internet. Es posible de la informaci&oacute;n recogida, se derive la identificaci&oacute;n de usuarios que utilizan los sistemas web a pesar de que estos datos no sean recolectados para tal fin. Dentro de estos datos se encuentran los siguientes:</p>
                <br>
                <ol style="margin-left: 20px;">
                    <li>
                        <p>Nombre de dominio del equipo utilizado para acceder a la p&aacute;gina web;&nbsp;</p>
                    </li>
                    <li>
                        <p>Par&aacute;metros relativos al sistema operativo del usuario;</p>
                    </li>
                    <li>
                        <p>Direcci&oacute;n IP;</p>
                    </li>
                    <li>
                        <p>Direcci&oacute;n URL; y</p>
                    </li>
                    <li>
                        <p>Fecha y hora.</p>
                    </li>
                </ol>
                <br/>
                <p>Los datos mencionados con anterioridad se utilizan &uacute;nicamente para la recolecci&oacute;n de datos estad&iacute;sticos an&oacute;nimos, para generar par&aacute;metros de uso y de mejora para la plataforma.</p>
                <p><br></p>
                <p style="text-align: center;"><b>VI. DERECHOS DE LOS TITULARES DE DATOS PERSONALES</b></p>
                <br>
                <p>En sujeci&oacute;n a los Art&iacute;culos 21 y 22 del Decreto 1377 de 2013 y el Art&iacute;culo 8 de 1581 de 2012, Los Titulares de los datos personales ostentan unos derechos de los cuales pueden hacer uso en relaci&oacute;n con el tratamiento de sus datos personales, los cuales podr&aacute;n ser ejercidos:</p>
                <br>
                <ol style="margin-left: 20px;">
                    <li>
                        <p>Por el Titular, quien deber&aacute; acreditar su identidad en forma suficiente por los distintos medios que le ponga a disposici&oacute;n El Responsable;</p>
                    </li>
                    <li>
                        <p>Por sus causahabientes, quienes deber&aacute;n acreditar tal calidad;</p>
                    </li>
                    <li>
                        <p>Por el representante y/o apoderado de El Titular, previa acreditaci&oacute;n de la representaci&oacute;n o apoderamiento; y</p>
                    </li>
                    <li>
                        <p>Por estipulaci&oacute;n a favor de otro y para otro.</p>
                    </li>
                </ol>
                <p><br></p>
                <p>Los derechos de los ni&ntilde;os, ni&ntilde;as o adolescentes se ejercer&aacute;n por las personas que est&eacute;n facultadas para representarlos.</p>
                <p><br></p>
                <p>Los derechos que tienen los Titulares en relaci&oacute;n con el tratamiento de sus datos personales son:</p>
                <br>
                <ul style="margin-left: 20px;">
                    <li>
                        <p>Derecho de Acceso y Consulta &ndash;&nbsp;derecho de El Titular a ser informado por El Representante, previa solicitud de &eacute;ste, respecto al origen, uso y finalidad que les ha dado a sus datos personales;</p>
                    </li>
                    <li>
                        <p>Derecho de Queja y Reclamo &ndash;&nbsp;la Ley distingue entre cuatro tipos de reclamo:</p>
                        <ol style="margin-left: 20px;">
                            <li>
                                <p>Reclamo de correcci&oacute;n &ndash;&nbsp;derecho a actualizar, rectificar o modificar aquellos datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento est&eacute; expresamente prohibido o no haya sido autorizado por El Titular;</p>
                            </li>
                            <li>
                                <p>Reclamo de supresi&oacute;n &ndash;&nbsp;derecho a que se supriman los datos que resulten inadecuados, excesivos o que no respeten los principios, derechos y/o garant&iacute;as constitucionales y/o legales;</p>
                            </li>
                            <li>
                                <p>Reclamo de revocaci&oacute;n &ndash;&nbsp;derecho a dejar sin efecto la autorizaci&oacute;n previa prestada para el tratamiento de sus datos personales;</p>
                            </li>
                            <li>
                                <p>Reclamo de infracci&oacute;n &ndash;&nbsp;derecho a solicitar que se subsane el incumplimiento de la normativa en materia de protecci&oacute;n de datos.</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>Derecho a Solicitar Prueba de la Autorizaci&oacute;n Otorgada a El Responsable del Tratamiento &ndash;&nbsp;salvo cuando expresamente se except&uacute;e como requisito para el tratamiento, de conformidad con lo previsto en el Art&iacute;culo 10 de la Ley 1581 de 2012;</p>
                    </li>
                    <li>
                        <p>Derecho a Presentar ante la Superintendencia de Industria y Comercio Quejas por Infracciones &ndash;&nbsp;El Titular o causahabiente solo podr&aacute; elevar esta queja una vez haya agotado el tr&aacute;mite de consulta o reclamo ante El Responsable o Encargado del tratamiento.</p>
                    </li>
                </ul>
                <p><br></p>
                <p style="text-align: center;"><b>VII. CONTACTO DE LOS TITULARES DE DATOS PERSONALES</b></p>
                <br>
                <p>Los Titulares de los datos personales tratados podr&aacute;n ejercer sus derechos a trav&eacute;s del correo electr&oacute;nico:&nbsp;<a href="mailto:mr@vanguardvision.ai" style="color: inherit">mr@vanguardvision.ai</a></p>
                <p><br></p>
                <p style="text-align: center;"><b>VIII. EJERCICIO DE DERECHOS</b></p>
                <br>
                <p><b>1. DERECHO DE ACCESO Y CONSULTA</b></p>
                <br>
                <p>El Titular podr&aacute; consultar sus datos personales de forma gratuita en los siguientes casos: (i) una vez cada mes calendario; y (ii) cada vez que existan modificaciones sustanciales de las Pol&iacute;ticas de Tratamiento de Datos Personales de El Responsable que motiven nuevas consultas. Para consultas cuya periodicidad exceda la enunciada, El Responsable podr&aacute; cobrar a El Titular los gastos de env&iacute;o, reproducci&oacute;n y, en su caso, certificaci&oacute;n de documentos.</p>
                <p><br></p>
                <p>Este derecho podr&aacute; ser ejercido por El Titular mediante la radicaci&oacute;n de una solicitud de consulta de manera escrita ante&nbsp;VANGUARD TECHNOLOGIES S.A.S., compa&ntilde;&iacute;a ubicada en la Carrera 43a No. 19 &ndash; 17, Centro Empresarial Block, Oficina 607 del Municipio de Medell&iacute;n, Antioquia a trav&eacute;s de correo postal remitido a esta direcci&oacute;n o mediante correo electr&oacute;nico a la direcci&oacute;n:&nbsp;mr@vanguardvision.ai, indicando en el Asunto &ldquo;Ejercicio del Derecho de Acceso o Consulta&rdquo;. La solicitud deber&aacute; contener los siguientes datos:</p>
                <br>
                <ol style="margin-left: 20px;">
                    <li>
                        <p>Nombre y apellidos de El Titular;</p>
                    </li>
                    <li>
                        <p>Fotocopia de la C&eacute;dula de Ciudadan&iacute;a de El Titular y, en su caso, de la persona que lo representa, as&iacute; como del documento acreditativo de tal representaci&oacute;n;</p>
                    </li>
                    <li>
                        <p>Petici&oacute;n en la que se concreta la solicitud de acceso o consulta;</p>
                    </li>
                    <li>
                        <p>Direcci&oacute;n para notificaciones, fecha y firma del solicitante; y</p>
                    </li>
                    <li>
                        <p>De ser el caso, los documentos que acrediten la consulta realizada.</p>
                    </li>
                </ol>
                <p><br></p>
                <p>Dej&aacute;ndolo claro en la solicitud, se podr&aacute; solicitar la informaci&oacute;n en cualquiera de las siguientes modalidades:</p>
                <br>
                <ol style="margin-left: 20px;">
                    <li>
                        <p>Visualizaci&oacute;n en pantalla;</p>
                    </li>
                    <li>
                        <p>Por escrito, con copia o fotocopia remitida por correo certificado o no;</p>
                    </li>
                    <li>
                        <p>Telecopia; o</p>
                    </li>
                    <li>
                        <p>Correo u otro medio electr&oacute;nico.</p>
                    </li>
                </ol>
                <p><br></p>
                <p>Una vez recibida la solicitud, tendr&aacute; El Responsable un plazo m&aacute;ximo de diez (10) d&iacute;as h&aacute;biles para resolverla. En caso de no ser posible resolver la solicitud en el plazo enunciado, se informar&aacute; al solicitante la raz&oacute;n de la tardanza y tendr&aacute; El Responsable una pr&oacute;rroga de cinco (5) d&iacute;as h&aacute;biles de acuerdo con el Art&iacute;culo 14 de la Ley 1581 de 2012.</p>
                <br>
                <p><b>2. DERECHO DE QUEJA Y RECLAMO</b></p>
                <br>
                <p>El Titular de los datos puede ejercer el derecho de reclamo sobre sus datos personales mediante un escrito dirigido a&nbsp;VANGUARD TECHNOLOGIES S.A.S., compa&ntilde;&iacute;a ubicada en la Carrera 43a No. 19 &ndash; 17, Centro Empresarial Block, Oficina 607 del Municipio de Medell&iacute;n, Antioquia a trav&eacute;s de correo postal remitido a esta direcci&oacute;n o mediante correo electr&oacute;nico a la direcci&oacute;n:&nbsp;mr@vanguardvision.ai, indicando en el Asunto &ldquo;Ejercicio del Derecho de Queja y Reclamo&rdquo;. La solicitud deber&aacute; contener los siguientes datos:</p>
                <br>
                <ol style="margin-left: 20px;">
                    <li>
                        <p>Nombre y apellidos de El Titular;</p>
                    </li>
                    <li>
                        <p>Fotocopia de la C&eacute;dula de Ciudadan&iacute;a de El Titular y, en su caso, de la persona que lo representa, as&iacute; como del documento acreditativo de tal representaci&oacute;n;</p>
                    </li>
                    <li>
                        <p>Descripci&oacute;n de los hechos y petici&oacute;n en la que se concreta la solicitud de correcci&oacute;n, supresi&oacute;n, revocaci&oacute;n o infracci&oacute;n;</p>
                    </li>
                    <li>
                        <p>Direcci&oacute;n para notificaciones, fecha y firma del solicitante; y</p>
                    </li>
                    <li>
                        <p>Documentos acreditativos de la petici&oacute;n formulada que se quieran hacer valer, cuando corresponda.</p>
                    </li>
                </ol>
                <p><br></p>
                <p>Si el reclamo resulta incompleto, se requerir&aacute; al solicitante dentro de los cinco (5) d&iacute;as siguientes a la recepci&oacute;n del reclamo para que subsane las fallas; transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante aporte la informaci&oacute;n requerida, se entender&aacute; que &eacute;ste ha desistido del reclamo. Una vez recibido el reclamo completo, se incluir&aacute; en la base de datos una leyenda que diga &ldquo;reclamo en tr&aacute;mite&rdquo; y el motivo de este en un t&eacute;rmino no mayor a dos (2) d&iacute;as h&aacute;biles; dicha leyenda deber&aacute; mantenerse hasta que el reclamo sea decidido.</p>
                <p><br></p>
                <p>El Responsable resolver&aacute; el reclamo en un plazo m&aacute;ximo de quince (15) d&iacute;as h&aacute;biles contados a partir de la fecha de recibo de este; cuando no fuere posible atender al reclamo dentro de dicho t&eacute;rmino, se informar&aacute; al interesado los motivos de la demora y la fecha en que se atender&aacute; su reclamo, la cual en ning&uacute;n caso podr&aacute; superar los ocho (8) d&iacute;as h&aacute;biles siguientes al vencimiento del primer t&eacute;rmino.</p>
                <p><br></p>
                <p>Una vez agotado el tr&aacute;mite de reclamo, El Titular o causahabiente podr&aacute; elevar queja ante la Superintendencia de Industria y Comercio.</p>
                <p><br></p>
                <p style="text-align: center;"><b>IX. TRATAMIENTO DE DATOS SENSIBLES Y DE MENORES DE EDAD</b></p>
                <p><br></p>
                <p>VANGUARD TECHNOLOGIES S.A.S.&nbsp;podr&aacute; procesar Datos Sensibles de El Titular, siempre y cuando medie autorizaci&oacute;n previa y expresa de &eacute;ste, en los casos en que el procesamiento de tales datos est&eacute; permitido por la Ley. Mediante esta Pol&iacute;tica y otorgando su consentimiento, El Titular reconoce que El Responsable le ha informado: (i) cu&aacute;les de sus datos personales que ser&aacute;n recolectados son considerados como Datos Sensibles; (ii) el prop&oacute;sito de dicho tratamiento; y (iii) que, por la calidad de los datos, no est&aacute; obligado a proporcionar dicha informaci&oacute;n ni a autorizar su tratamiento a El Responsable, salvo por los casos previstos en la Ley Aplicable.</p>
                <p><br></p>
                <p>En particular, El Responsable podr&aacute; procesar los siguientes Datos Sensibles y, de forma exclusiva, para el prop&oacute;sito establecido a continuaci&oacute;n:</p>
                <p><br></p>
                <ol style="margin-left: 20px;">
                    <li>
                        <p>Audios, im&aacute;genes y videos de El Titular &ndash; esto incluye la informaci&oacute;n solicitada por el personal de vigilancia y la captada por las c&aacute;maras y el equipo de vigilancia de la compa&ntilde;&iacute;a y sus clientes para fines de realizar procesos de vigilancia y autenticaci&oacute;n de la identificaci&oacute;n de personas naturales;</p>
                    </li>
                    <li>
                        <p>Datos biom&eacute;tricos &ndash; se refiere al an&aacute;lisis de datos biom&eacute;tricos que facilite el reconocimiento facial y la autenticaci&oacute;n de la identificaci&oacute;n de personas naturales y la detecci&oacute;n de posibles riesgos y amenazas a la seguridad de sus clientes;</p>
                    </li>
                    <li>
                        <p>Datos Sensibles que resulten de la geolocalizaci&oacute;n &ndash; esto comprende informaci&oacute;n en tiempo real sobre la ubicaci&oacute;n de El Titular que se encuentre utilizando un dispositivo en el cual haya sido instalada alguna de las aplicaciones de&nbsp;VANGUARD TECHNOLOGIES S.A.S.&nbsp;o cuando se encuentre en uso espec&iacute;fico de la aplicaci&oacute;n, seg&uacute;n lo autorice El Titular. Su finalidad ser&aacute; indicarle a El Titular el Manual de Pol&iacute;ticas y Procedimientos para el Tratamiento de Datos Personales propio de cada uno de los clientes que utilice la aplicaci&oacute;n para la autenticaci&oacute;n de la identificaci&oacute;n del personal a ingresar a sus instalaciones;</p>
                    </li>
                    <li>
                        <p>Informaci&oacute;n m&eacute;dica en el caso de los Empleados &ndash; esta informaci&oacute;n corresponde a aquella que deviene necesaria para atender situaciones de emergencia y a la obtenida durante el transcurso de cualquier examinaci&oacute;n m&eacute;dica regulada por la Resoluci&oacute;n 2346 de 2007 y cualquier otra regulaci&oacute;n complementaria;</p>
                    </li>
                    <li>
                        <p>Datos de Menores de Edad &ndash; el uso de las aplicaciones de&nbsp;VANGUARD TECHNOLOGIES S.A.S.&nbsp;no estar&aacute; disponible para personas naturales que no acrediten la mayor&iacute;a de edad legal en Colombia. No obstante, en relaci&oacute;n con los datos personales de ni&ntilde;os, ni&ntilde;as y adolescentes que podr&aacute;n ser recolectados y tratados por El Responsable cuando se trate de informaci&oacute;n relacionada con los hijos de Los Titulares o Los Empleados, esta ser&aacute; tratada en relaci&oacute;n con las obligaciones de seguridad. Para lo anterior, los padres o quienes legalmente ejerzan la representaci&oacute;n de los menores de edad, deber&aacute;n consultar con &eacute;stos sobre su consentimiento y autorizar su tratamiento. En cualquier caso, El Responsable respetar&aacute; los derechos de los ni&ntilde;os, ni&ntilde;as y adolescentes y se cerciorar&aacute; de que sus derechos prevalezcan durante el tratamiento de sus datos personales.</p>
                    </li>
                </ol>
                <p><br></p>
                <p style="text-align: center;"><b>X. POL&Iacute;TICAS DE SEGURIDAD</b></p>
                <br>
                <p>El Responsable, con el fin de cumplir con el principio de seguridad instaurado en el Literal G del Art&iacute;culo 4 de la Ley 1581 de 2012, ha implementado las medidas t&eacute;cnicas, humanas y administrativas necesarias para garantizar la seguridad de los registros evitando su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o fraudulento.</p>
                <p><br></p>
                <p>Por otra parte, El Responsable, mediante la suscripci&oacute;n de los correspondientes contratos de transferencia y/o transmisi&oacute;n de datos personales, ha requerido a los terceros y/o Encargados del tratamiento con los que trabaje, la implementaci&oacute;n de las medidas de seguridad necesarias para garantizar la seguridad y confidencialidad de la informaci&oacute;n en el tratamiento de los datos personales.</p>
                <p><br></p>
                <p style="text-align: center;"><b>XI.TRANSFERENCIA INTERNACIONAL DE DATOS</b></p>
                <br>
                <p>En l&iacute;nea con el T&iacute;tulo VIII de la Ley 1581 de 2012, est&aacute; expresamente prohibida la transferencia de datos personales a pa&iacute;ses que no cuenten con las condiciones de seguridad establecidas en la aludida Ley. Cuando un pa&iacute;s no cumpla con las condiciones establecidas por la Superintendencia de Industria y Comercio, se entender&aacute; prohibida la transferencia de datos personales a este pa&iacute;s; bajo este entendido, las condiciones del pa&iacute;s al que se fueran a transferir los datos, en ning&uacute;n caso podr&aacute;n ser inferiores a las que establece la Ley 1581 de 2012. No se entender&aacute; prohibida la transferencia de datos a terceros pa&iacute;ses cuando corresponda a:</p>
                <br>
                <ol style="margin-left: 20px;">
                    <li>
                        <p>Intercambio de datos de car&aacute;cter m&eacute;dico, cuando as&iacute; lo exija el tratamiento de El Titular por razones de salud o higiene p&uacute;blica;</p>
                    </li>
                    <li>
                        <p>Transferencias bancarias o burs&aacute;tiles, conforme a la legislaci&oacute;n que les resulte aplicable;</p>
                    </li>
                    <li>
                        <p>Transferencias acordadas en el marco de tratados internacionales en los cuales la Rep&uacute;blica de Colombia sea parte, con fundamento en el principio de reciprocidad;</p>
                    </li>
                    <li>
                        <p>Transferencias necesarias para la ejecuci&oacute;n de un contrato entre el Titular y El Responsable del tratamiento o para la ejecuci&oacute;n de medidas precontractuales, siempre y cuando se cuente con la autorizaci&oacute;n de El Titular;</p>
                    </li>
                    <li>
                        <p>Transferencias legalmente exigidas para la salvaguardia del inter&eacute;s p&uacute;blico o para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial; o cuando</p>
                    </li>
                    <li>
                        <p>El Titular haya otorgado su autorizaci&oacute;n expresa e inequ&iacute;voca para la transferencia;</p>
                    </li>
                </ol>
                <p><br></p>
                <p>Para los casos que no est&eacute;n contemplados dentro de las excepciones expuestas, ser&aacute; la Superintendencia de Industria y Comercio la entidad competente para anunciar el veredicto sobre la posibilidad o prohibici&oacute;n de la transferencia de datos a terceros pa&iacute;ses. El funcionario encargado quedar&aacute; facultado para requerir la informaci&oacute;n necesaria, al igual que para adelantar las diligencias que sean imperativas para determinar la viabilidad de la operaci&oacute;n.</p>
                <p><br></p>
                <p>Siempre que exista un contrato de transmisi&oacute;n de datos entre El Responsable y un Encargado ubicado por fuera del territorio colombiano, no se requerir&aacute; del consentimiento o autorizaci&oacute;n de El Titular.</p>
                <p><br></p>
                <p style="text-align: center;"><b>XII. VIGENCIA</b></p>
                <br>
                <p>Las bases de datos objeto de tratamiento por parte de El Responsable, ser&aacute;n tratadas por el tiempo que se requiera para la finalidad para la cual son recolectados los datos contenidos en esta. Una vez se haya cumplido con la finalidad o finalidades para las cuales fueron recolectados los datos, sin perjuicio de lo que dispongan otras normas legales, se proceder&aacute; por parte de El Responsable a la supresi&oacute;n total de los datos, a menos de que exista alguna obligaci&oacute;n legal o contractual de mantenerlos bajo custodia.</p>
                <p><br></p>
                <p>El presente Manual de Pol&iacute;ticas y Procedimientos para el Tratamiento de Datos Personales entra en vigencia a partir del 11 de marzo de 2022.</p>
            </div>
            <br/><br/><br/><br/><br/>
            <hr>
            <br/><br/><br/><br/><br/>
            <div id="doc02">
                <h2>CONSENTIMIENTO PARA EL TRATAMIENTO DE DATOS WEB</h2>
                <p><br></p>
                <p>Se le informa a El Usuario que, seg&uacute;n la Ley Estatutaria 1581 de 2012, por la cual se dictan disposiciones generales para la protecci&oacute;n de datos personales, y el Decreto 1377 de 2013 que la reglamenta, los datos diligenciados en este formulario ser&aacute;n incorporados en una base de datos bajo la responsabilidad de&nbsp;VANGUARD TECHNOLOGIES S.A.S.&nbsp;y ser&aacute;n tratados con fines de videovigilancia, a partir del an&aacute;lisis de datos biom&eacute;tricos que facilite el reconocimiento facial y la autenticaci&oacute;n de la identificaci&oacute;n de personas naturales, la detecci&oacute;n de posibles riesgos y amenazas a la seguridad de sus clientes, realizar activaciones de marca, estructurar y ejecutar estrategias de mercadeo, env&iacute;o de promociones, para fines estad&iacute;sticos, conocer y examinar el comportamiento documental del cliente, efectuar las gestiones necesarias tendientes a confirmar y actualizar la informaci&oacute;n del cliente para cumplir con las funciones y obligaciones procedentes de diversas operaciones realizadas a trav&eacute;s del comercio electr&oacute;nico, establecer, mantener y/o terminar una relaci&oacute;n contractual, conocer la ubicaci&oacute;n y datos de contacto del cliente para el env&iacute;o de notificaciones con fines de seguridad, almacenamiento de informaci&oacute;n de navegaci&oacute;n de la plataforma&nbsp;vanguardtech.solutions, uso de cookies de navegaci&oacute;n, construcci&oacute;n del perfilamiento de usuario, env&iacute;o de facturas y/o documentos de compra, transferencia y/o transmisi&oacute;n a terceros, y cualquier otra actividad conexa a la actividad comercial desarrollada por El Responsable.</p>
                <p><br></p>
                <p>Es de car&aacute;cter facultativo suministrar informaci&oacute;n que verse sobre Datos Sensibles, entendidos como aquellos que afecten la intimidad de El Titular o cuyo uso indebido puede conducir a su discriminaci&oacute;n, tales como aquellos que revelen el origen racial o &eacute;tnico, la orientaci&oacute;n pol&iacute;tica, las convicciones religiosas o filos&oacute;ficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promuevan intereses de cualquier partido pol&iacute;tico o que garanticen los derechos y garant&iacute;as de partidos pol&iacute;ticos de oposici&oacute;n, as&iacute; como los datos relativos a la salud, a la vida sexual y los datos biom&eacute;tricos.</p>
                <p><br></p>
                <p>Los temas relativos a la Pol&iacute;tica de Tratamiento de Datos Personales de El Responsable, al igual que las modificaciones sustanciales que sobre esta se realicen, podr&aacute;n consultarse a trav&eacute;s de solicitud escrita enviada a la Carrera 43a No. 19 &ndash; 17, Centro Empresarial Block, Oficina 607 del Municipio de Medell&iacute;n, Antioquia o al correo electr&oacute;nico:&nbsp;<a href="mailto:mr@vanguardvision.ai" style="color: inherit">mr@vanguardvision.ai</a>. En su calidad de Titular, usted puede ejercitar los derechos de acceso, consulta, aclaraci&oacute;n, correcci&oacute;n, modificaci&oacute;n, supresi&oacute;n, revocaci&oacute;n, queja o reclamo por infracci&oacute;n sobre el tratamiento de sus datos personales, indicando en el asunto el derecho que desea ejercer. El Usuario declara haber le&iacute;do la Cl&aacute;usula anterior y estar conforme con esta.</p>
            </div>
            <br/><br/><br/><br/><br/>
            <hr>
            <br/><br/><br/><br/><br/>
            <div id="doc03">
                <h2>AVISO DE PRIVACIDAD</h2>
                <p>&nbsp;</p>
                <p>En cumplimiento de la Ley Estatutaria 1581 de 2012, por la cual se dictan disposiciones generales para la protecci&oacute;n de datos personales, y el Decreto 1377 de 2013 que la reglamenta, el presente Aviso de Privacidad tiene por objeto obtener la autorizaci&oacute;n previa, expresa e informada de El Titular de los Datos Personales para el Tratamiento, la Transmisi&oacute;n y la Transferencia de sus datos a terceras entidades. Las condiciones del referido tratamiento son las siguientes:</p>
                <br/>
                <ol style="margin-left: 20px;">
                    <li>
                        <p>VANGUARD TECHNOLOGIES S.A.S., identificada con NIT. 901.370.664 &ndash; 5, ser&aacute; El Responsable del tratamiento de los datos personales de El Titular;</p>
                    </li>
                    <br/>
                    <li>
                        <p>Con el prop&oacute;sito de ofrecer una atenci&oacute;n integral a sus clientes, empleados, proveedores, contratistas, entre otros, los datos personales de Los Titulares ser&aacute;n tratados en su su recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o supresi&oacute;n con fines de videovigilancia, a partir del an&aacute;lisis de datos biom&eacute;tricos que facilite el reconocimiento facial y la autenticaci&oacute;n de la identificaci&oacute;n de personas naturales, la detecci&oacute;n de posibles riesgos y amenazas a la seguridad de sus clientes, realizar activaciones de marca, estructurar y ejecutar estrategias de mercadeo, env&iacute;o de promociones, para fines estad&iacute;sticos, conocer y examinar el comportamiento documental del cliente, efectuar las gestiones necesarias tendientes a confirmar y actualizar la informaci&oacute;n del cliente para cumplir con las funciones y obligaciones procedentes de diversas operaciones realizadas a trav&eacute;s del comercio electr&oacute;nico, establecer, mantener y/o terminar una relaci&oacute;n contractual, conocer la ubicaci&oacute;n y datos de contacto del cliente para el env&iacute;o de notificaciones con fines de seguridad, almacenamiento de informaci&oacute;n de navegaci&oacute;n de la plataforma&nbsp;vanguardtech.solutions, uso de cookies de navegaci&oacute;n, construcci&oacute;n del perfilamiento de usuario, env&iacute;o de facturas y/o documentos de compra, transferencia y/o transmisi&oacute;n a terceros, y cualquier otra actividad conexa a la actividad comercial desarrollada por El Responsable;</p>
                    </li>
                    <br/>
                    <li>
                        <p>Es de car&aacute;cter facultativo suministrar informaci&oacute;n que verse sobre Datos Sensibles, entendidos como aquellos que afecten la intimidad de El Titular o cuyo uso indebido puede conducir a su discriminaci&oacute;n, tales como aquellos que revelen el origen racial o &eacute;tnico, la orientaci&oacute;n pol&iacute;tica, las convicciones religiosas o filos&oacute;ficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promuevan intereses de cualquier partido pol&iacute;tico o que garanticen los derechos y garant&iacute;as de partidos pol&iacute;ticos de oposici&oacute;n, as&iacute; como los datos relativos a la salud, a la vida sexual y los datos biom&eacute;tricos;</p>
                    </li>
                    <br/>
                    <li>
                        <p>Los temas relativos a la Pol&iacute;tica de Tratamiento de Datos Personales de El Responsable, al igual que las modificaciones sustanciales que sobre esta se realicen, podr&aacute;n consultarse a trav&eacute;s de solicitud escrita enviada a la Carrera 43a No. 19 &ndash; 17, Centro Empresarial Block, Oficina 607 del Municipio de Medell&iacute;n, Antioquia o al correo electr&oacute;nico:&nbsp;<a href="mailto:mr@vanguardvision.ai" style="color: inherit">mr@vanguardvision.ai</a>. En su calidad de Titular, usted puede ejercitar los derechos de acceso, consulta, aclaraci&oacute;n, correcci&oacute;n, modificaci&oacute;n, supresi&oacute;n, revocaci&oacute;n, queja o reclamo por infracci&oacute;n sobre el tratamiento de sus datos personales, indicando en el asunto el derecho que desea ejercer. El Usuario declara haber le&iacute;do la Cl&aacute;usula anterior y estar conforme con esta.</p>
                    </li>
                </ol>
                <p><br></p>
                <p>Medell&iacute;n:&nbsp;Carrera 43a No. 19 &ndash; 17, Centro Empresarial Block, Oficina 607</p>
                <p>E &ndash; mail:&nbsp;<a href="mailto:mr@vanguardvision.ai" style="color: inherit">mr@vanguardvision.ai</a></p>
                <p>Tel&eacute;fonos:&nbsp;(*)</p>
                <p><br></p>
            </div>
        </div>
    </div>

    <div class="btnTop">
        <a href="#initDoc"><i style="color: white" class="fal fa-arrow-alt-up"></i></a>
    </div>

  </v-container>
</template>

<script>

export default {
  name: "PoliticsComponent",
  data: () => ({
  }),
  methods: {
  },
  created() {},
};
</script>
<style>
h2, b, ul, ol, a {
    color: #60A5FA;
    font-family: arial;
}
p {
    color: rgb(234, 234, 234);
}
.ctDoc {
    max-width: 900px;
    width: 100%;
    background: #31394C;
    padding: 20px;
    margin: 0 auto;
    border-radius: 5px;
}
.btnTop {
    position: fixed;
    margin: 20px;
    padding: 10px 14px;
    right: 10px;
    bottom: 20px;
    color: #fff;
    background: #60A5FA;
    border-radius: 5px;
    font-size: 1.3rem;
    cursor: pointer;
}
</style>