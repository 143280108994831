<template>
    <v-row class="pa-0 ma-0">
      <v-overlay opacity="1" :value="overlay">
        <v-card color="primary" dark>
          <v-card-text>
            Autenticando, por favor espere...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-overlay>
      <v-row v-if="exampleTest && exampleTest !== ''" class="ma-0 pa-0">
        <v-col v-if="typeUse == 'selfie'" cols="12" class="d-flex justify-center align-center ma-0 pa-0">
          <div class="video-container" style="max-width: 250px;">  
            <div class="face-indicator" style="max-width: 250px;" v-show="activateCam"></div>
            <video v-show="!photoTaked" ref="video" style="max-width: 250px;" class="ma-0 pa-0" />
          </div>
          <!-- <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="450" :height="337.5" class="ma-0 pa-0"></canvas> -->
          <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="widthPhoto" :height="heightPhoto" class="ma-0 pa-0"></canvas>
        </v-col>
        <v-col v-if="typeUse == 'frontDoc' || typeUse == 'backDoc'" cols="12" class="d-flex justify-center align-center ma-0 pa-0">
          <div class="video-container-doc" style="max-width: 300px;">  
            <div class="doc-indicator" style="max-width: 300px;" v-show="activateCam"></div>
            <video v-show="!photoTaked" ref="video" style="max-width: 300px;" class="ma-0 pa-0" />
          </div>
          <!-- <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="450" :height="337.5" class="ma-0 pa-0"></canvas> -->
          <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="widthPhoto" :height="heightPhoto" class="ma-0 pa-0"></canvas>
        </v-col>
        <v-col v-if="typeUse != 'frontDoc' && typeUse != 'backDoc' && typeUse != 'selfie'" cols="12" class="d-flex justify-center align-center ma-0 pa-0">
          <video v-show="!photoTaked" ref="video" style="max-width: 70%; max-height: 80%;" class="ma-0 pa-0" />
          <!-- <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="450" :height="337.5" class="ma-0 pa-0"></canvas> -->
          <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="widthPhoto" :height="heightPhoto" class="ma-0 pa-0"></canvas>
        </v-col>
        <div v-if="!isMobile" style="width: 100%" class="d-flex justify-center">
          <v-col cols="6" class="pb-0 d-flex justify-center">
            <v-select
              v-model="cameraSelected"
              :items="cameras"
              item-value="id"
              item-text="label"
              outlined
              dense
              hide-details
              color="white"
              item-color="white"
              style="max-width: 340px;"
              prepend-icon="mdi-camera"
              @change="openCamera"
            ></v-select>
          </v-col>
        </div>
        <v-col cols="12" class="d-flex justify-center align-center pb-0">
          <v-btn
            v-if="!photoTaked"
            color="success"
            class="white--text"
            x-small
            title="TOMAR FOTO"
            @click="capture"
          >
            <!-- <v-icon dark>mdi-camera-iris</v-icon> -->
            Tomar Foto
          </v-btn>
          <v-btn
            v-if="photoTaked"
            color="secondary"
            class="white--text"
            x-small
            title="TOMAR FOTO"
            @click="photoTaked = false"
          >
            <!-- <v-icon dark>mdi-camera-iris</v-icon> -->
            Tomar Otra Foto
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!exampleTest" class="ma-0 pa-0">
        <v-col v-if="typeUse == 'selfie'" cols="12" class="d-flex justify-center align-center ma-0 pa-0">
          <div class="video-container" style="max-width: 250px;">  
            <div class="face-indicator" style="max-width: 250px;" v-show="activateCam"></div>
            <video v-show="!photoTaked" ref="video" style="max-width: 250px;" class="ma-0 pa-0" />
          </div>
          <!-- <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="450" :height="337.5" class="ma-0 pa-0"></canvas> -->
          <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="widthPhoto" :height="heightPhoto" class="ma-0 pa-0"></canvas>
        </v-col>
        <v-col v-if="typeUse == 'frontDoc' || typeUse == 'backDoc'" cols="12" class="d-flex justify-center align-center ma-0 pa-0">
          <div class="video-container-doc" style="max-width: 300px;">  
            <div class="doc-indicator" style="max-width: 300px;" v-show="activateCam"></div>
            <video v-show="!photoTaked" ref="video" style="max-width: 300px;" class="ma-0 pa-0" />
          </div>
          <!-- <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="450" :height="337.5" class="ma-0 pa-0"></canvas> -->
          <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="widthPhoto" :height="heightPhoto" class="ma-0 pa-0"></canvas>
        </v-col>
        <v-col v-if="typeUse != 'frontDoc' && typeUse != 'backDoc' && typeUse != 'selfie'" cols="12" class="d-flex justify-center align-center ma-0 pa-0">
          <video v-show="!photoTaked" ref="video" style="max-width: 70%; max-height: 80%;" class="ma-0 pa-0" />
          <!-- <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="450" :height="337.5" class="ma-0 pa-0"></canvas> -->
          <canvas v-show="photoTaked" id="photoTaken" ref="canvas" :width="widthPhoto" :height="heightPhoto" class="ma-0 pa-0"></canvas>
        </v-col>
        <v-col v-if="typeUse != 'sign-in'" cols="12" class="d-flex justify-center align-center pb-0">
          <v-btn
            v-if="!photoTaked"
            color="success"
            class="white--text"
            x-small
            title="TOMAR FOTO"
            @click="capture"
          >
            <!-- <v-icon dark>mdi-camera-iris</v-icon> -->
            Tomar Foto
          </v-btn>
          <v-btn
            v-if="photoTaked"
            color="secondary"
            class="white--text"
            x-small
            title="TOMAR FOTO"
            @click="photoTaked = false"
          >
            <!-- <v-icon dark>mdi-camera-iris</v-icon> -->
            Tomar Otra Foto
          </v-btn>
        </v-col>
        <v-col v-if="typeUse == 'sign-in'" cols="12" class="d-flex justify-center align-center pb-0">
          <v-btn
            v-if="!photoTaked"
            color="primary"
            class="white--text"
            x-small
            title="TOMAR FOTO"
            @click="capture"
          >
            <!-- <v-icon dark>mdi-camera-iris</v-icon> -->
            Autenticar
          </v-btn>
          <v-btn
            v-if="photoTaked"
            color="secondary"
            class="white--text"
            x-small
            title="TOMAR FOTO"
            @click="photoTaked = false"
          >
            <!-- <v-icon dark>mdi-camera-iris</v-icon> -->
            Tomar Otra Foto
          </v-btn>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" timeout="10000" :color="snackbarColor" top>
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
</template>
<script>
import axios from "axios";
import globals from "../../globals";
//import auth from "../../services/auth";
//import BarChart from "./charts/bar.vue";
import { v4 as uuidv4 } from "uuid";
import {S3} from "aws-sdk";

export default {
  name: "TakePhoto",
  props: {
    typeUse: String,
    imageName: String,
    exampleTest: String
  },
  data: () => ({
    overlay: false,
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    imageLink: "",
    activateCam: false,
    mediaStream: null,
    photoTaked: false,
    widthPhoto: 245,
    heightPhoto: 177,
    camNotFound: false,
    cameras: [],
    cameraSelected: null,
    isMobile: false,
  }),
  computed: {
    config() {
      return {
        bucketName: "vanguard-id",
        region: process.env.VUE_APP_S3_REGION,
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
        signatureVersion: 'v4',
      }
    },

    S3Client() {
      return new S3(this.config)
    }
  },
  methods: {
    openCameraMobile() {
      this.activateCam = true;

      let camConfig;
      if (this.typeUse == "sign-in" || this.typeUse == "selfie") {
        camConfig = {video: true}
      } else {
        if (this.camNotFound == true) {
          camConfig = {video: true}
        } else {
          /* camConfig = {video: {
            facingMode: {exact: "environment"}
          }} */
          // TODO: Posible solve to the iphone bug
          camConfig = {video: {
            facingMode: "environment",
            exact: "environment"
          }}
        }
        
      }
      navigator.mediaDevices.getUserMedia(camConfig)
        .then(mediaStream => {
          this.mediaStream = mediaStream;
          this.$refs.video.srcObject = mediaStream
          this.$refs.video.play()
          this.getVideoDevices();
        })
        .catch(error => {
          //console.error('getUserMedia() error:', error)
         /*  if (error == ' DOMException: Permission denied') {
            console.log("Es necesario que active los permisos de la cámara web.")
          } */
          if (error.constraint && error.constraint == "facingMode") {
            this.camNotFound = true;
            this.openCameraMobile()
          } else {
            this.snackbar = true;
            this.snackbarColor = "red";
            this.snackbarText = "Por favor verifique que tenga activados los permisos para el uso de la camara."
          }
        })
    },

    openCamera() {
      this.activateCam = true;

      let camConfig;
      if (this.typeUse == "sign-in" || this.typeUse == "selfie") {
        camConfig = {video: { deviceId: this.cameraSelected}}
      } else {
        if (this.camNotFound == true) {
          camConfig = {video: { deviceId: this.cameraSelected}}
        } else {
          /* camConfig = {video: {
            facingMode: {exact: "environment"}
          }} */
          // TODO: Posible solve to the iphone bug
          camConfig = {video: {
            deviceId: this.cameraSelected,
            facingMode: "environment",
            exact: "environment"
          }}
        }
        
      }
      navigator.mediaDevices.getUserMedia(camConfig)
        .then(mediaStream => {
          this.mediaStream = mediaStream;
          this.$refs.video.srcObject = mediaStream
          this.$refs.video.play()
          this.getVideoDevices();
        })
        .catch(error => {
          //console.error('getUserMedia() error:', error)
         /*  if (error == ' DOMException: Permission denied') {
            console.log("Es necesario que active los permisos de la cámara web.")
          } */
          if (error.constraint && error.constraint == "facingMode") {
            this.camNotFound = true;
            this.openCamera()
          } else {
            this.snackbar = true;
            this.snackbarColor = "red";
            this.snackbarText = "Por favor verifique que tenga activados los permisos para el uso de la cámara."
          }
        })
    },

    reduceImageQuality(inputBlob, quality, typeUse) {
      var reader = new FileReader();

      reader.onload = () => {
        var image = new Image();

        image.onload = () => {
          var canvas = document.createElement('canvas');
          var context = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;

          context.drawImage(image, 0, 0);

          canvas.toBlob((resultBlob) => {
            if (typeUse == 'sign-in') {
              this.savePhoto(resultBlob)
            } else {
              this.savePhotoRegister(resultBlob)
            }
          }, 'image/jpeg', quality);
        };

        image.src = reader.result;
      };

      reader.readAsDataURL(inputBlob);
    },

    capture() {
      this.photoTaked = true;
      const context = this.$refs.canvas.getContext('2d');
      /* //context.drawImage(this.$refs.video, 0, 0, 450, 337.5);
      context.drawImage(this.$refs.video, 0, 0, this.widthPhoto, this.heightPhoto); */

       // Obtener dimensiones originales de la imagen
      const originalWidth = this.$refs.video.videoWidth;
      const originalHeight = this.$refs.video.videoHeight;

      // Calcular nuevas dimensiones para ajustar al canvas manteniendo proporciones
      const aspectRatio = originalWidth / originalHeight;
      let newWidth, newHeight;

      if (originalWidth > originalHeight) {
        newWidth = this.widthPhoto;
        newHeight = this.widthPhoto / aspectRatio;
      } else {
        newHeight = this.heightPhoto;
        newWidth = this.heightPhoto * aspectRatio;
      }

      //context.drawImage(this.$refs.video, 0, 0, 450, 337.5);
      context.drawImage(this.$refs.video, 0, 0, newWidth, newHeight);

      const mediaStreamTrack = this.mediaStream.getVideoTracks()[0]
      const imageCapture = new window.ImageCapture(mediaStreamTrack)
      return imageCapture.takePhoto().then(blob => {
        this.reduceImageQuality(blob, 0.8, this.typeUse)
      })
    },

    savePhoto(blob) {
      this.overlay = true;
      this.S3Client.upload({
        Bucket: "vanguard-id",
        Key: "login" + uuidv4() + ".jpg",
        Body: blob,
        ContentType: 'image/jpeg',
      },  (err, data) => {
        if (err) {
          console.log(err);
          this.overlay = false;
        }

        //console.log("Respuesta de imagen subida a S3", data)

        axios({
          method: 'POST',
          baseURL: globals.APIURL + "identity/face-rekognition-auth",
          headers: {
              "Access-Control-Allow-Origin": "*"
          },
          data: {
            login_img_key: data.key,
          },
        }).then((res) => {
          this.$emit('photo-taken-auth', res)
          this.overlay = false;
          const tracks = this.mediaStream.getTracks()
          tracks.forEach(track => {
            track.stop();
          });

          this.activateCam = false;
          this.photoTaked = false;

        })
        .catch((err) => {
          this.overlay = false;
          console.log(err.response.data)
          const tracks = this.mediaStream.getTracks()
          tracks.forEach(track => {
            track.stop();
          });

          this.activateCam = false;
          this.photoTaked = false;
          this.openCamera();
          this.snackbar = true;
          this.snackbarColor = "red"
          this.snackbarText = "No fue posible autenticar, por favor intentelo nuevamente. Recuerde que tener una buena iluminación y no tener tapaboca o lentes de sol es importante."
        });
      });
    },

    savePhotoRegister(blob) {
      this.S3Client.upload({
        Bucket: "vanguard-id",
        Key: this.imageName + ".jpg",
        Body: blob,
        ContentType: 'image/jpeg',
      },  (err, data) => {
        if (err) {
          console.log(err);
        }
        //console.log("Respuesta de imagen subida a S3 en registro", data)

        if (this.typeUse == "selfie") {
          this.$emit('photo-taken-selfie', data)
        } else if (this.typeUse == "frontDoc") {
          this.$emit('photo-taken-front', data)
        } else if (this.typeUse == "backDoc") {
          this.$emit('photo-taken-back', data)
        }
      });
    },

    turnOffCamera() {
      const tracks = this.mediaStream.getTracks()
      tracks.forEach(track => {
				track.stop();
			});

      this.activateCam = false;
      this.photoTaked = false;
    },

    example() {
      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/face-rekognition-auth",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          login_img_key: "login-1234-4321.jpg",
        },
      }).then((res) => {
          this.$emit('photo-taken-auth', res)
          const tracks = this.mediaStream.getTracks()
          tracks.forEach(track => {
            track.stop();
          });

          this.activateCam = false;
          this.photoTaked = false;
        })
        .catch((err) => {
          console.log(err.response.data.Message)
          const tracks = this.mediaStream.getTracks()
          tracks.forEach(track => {
            track.stop();
          });

          this.activateCam = false;
          this.photoTaked = false;
        });
    },

    getVideoDevices() {
      navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        let videoDevices = devices.filter(device => device.kind === 'videoinput');
        videoDevices.forEach(camera => {
          if (camera.deviceId !== '') {
            this.cameras.push({
              id: camera.deviceId,
              label: camera.label
            });
          }
        });
        if (this.cameras.length > 0) {
          this.cameraSelected = this.cameras[0].id;
        }
      })
      .catch(err => {
        console.log(err.name + ": " + err.message);
      });
    }
  },
  beforeMount() {
    this.isMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);
    //this.getVideoDevices()
  },
  created() {
    if (this.exampleTest && this.exampleTest !== '') {
      if (this.isMobile == false) {
        this.getVideoDevices()
        this.openCamera()
      } else {
        this.openCameraMobile()
      }
    } else {
      this.openCameraMobile()
    }
  },
  beforeDestroy() {
    this.turnOffCamera()
  }
};
</script>
<style scoped>
.test-1 {
  display: contents !important;
}
.test-2 {
  display: none !important;
}
/* media query 1000px */
@media (max-width: 1250px) {
  .test-1 {
    display: none !important;
  }
  .test-2 {
    display: contents !important;
  }
}

.video-container {
  position: relative;
  overflow: hidden;
}

.face-indicator {
  /* background-color: rgba(255, 255, 255, .5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 60%;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 2; */

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 60%;
  border: 2px solid #938f8f;
  border-radius: 50%;
  pointer-events: none;
  background: radial-gradient(circle, transparent 50%, rgba(0,0,0,0.5) 100%);
  /* box-shadow: 0 0 0 9999px rgba(0,0,0,0.5); */ 
  /* box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.807);  */
  box-shadow: 0 0 0 9999px #212734; 
  z-index: 2;
}

.video-container-doc {
  position: relative;
  overflow: hidden;
}

@media 
(min-width: 1200px),
(min-width: 992px) and (max-width: 1199.98px)
{
  .face-indicator {
    width: 6rem;
  }
}
@media
(min-width: 768px) and (max-width: 991.98px) {
  .face-indicator {
    width: 6rem;
  }
}
@media 
(min-width: 576px) and (max-width: 767.98px),
(max-width: 575.98px) {
  .face-indicator {
    width: 8rem;
  }
}

.doc-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #938f8f;
  border-radius: 5%;
  pointer-events: none;
  /* background: radial-gradient(square, transparent 50%, rgba(0,0,0,0.5) 100%); */
  /* box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.842); */
  /* box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.807);  */
  box-shadow: 0 0 0 9999px #212734ef;
  /* z-index: 2; */
  width: 16rem;
}

@media 
(min-width: 1200px),
(min-width: 992px) and (max-width: 1199.98px)
{
  .doc-indicator {
    height: 65%;
  }
}
@media
(min-width: 768px) and (max-width: 991.98px) {
  .doc-indicator {
    height: 55%;
  }
}
@media 
(min-width: 576px) and (max-width: 767.98px),
(max-width: 575.98px) {
  .doc-indicator {
    height: 50%;
  }
}
</style>
