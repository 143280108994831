let employeeList = [
    {
        "accountEnable": "True",
        "code": "1221531",
        "document": "1115078423",
        "fullName": "Johan Adrian Vasquez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:15:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1444560",
        "document": "91267689",
        "fullName": "Fernando Archila Duarte",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1458615",
        "document": "91477298",
        "fullName": "Nestor Raul Florez Calderon",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1479569",
        "document": "13718657",
        "fullName": "Cesar Augusto Moreno Duque",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1484135",
        "document": "91488996",
        "fullName": "Gabriel Rueda Ortiz",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1342085",
        "document": "7571302",
        "fullName": "Levis Antonio Alvarez Gulloso",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1376450",
        "document": "1070960462",
        "fullName": "Harold Dahyan Rojas Martinez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:10:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1343979",
        "document": "1073152768",
        "fullName": "Jose Alfredo Buitrago Garcia",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1386900",
        "document": "80654431",
        "fullName": "Jose Alexander Sarmiento Forero",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000041",
        "document": "12995167",
        "fullName": "Ignacio Serafin Romo Vergara",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:09:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000159",
        "document": "93371077",
        "fullName": "Roberto Enrique Sanchez Carrasco",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000030",
        "document": "71618371",
        "fullName": "Carlos Ernesto Cardona Santa",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000066",
        "document": "93375287",
        "fullName": "Jose Guillermo Barragan Sierra",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000063",
        "document": "19461656",
        "fullName": "Julio Enrique Gomez Torres",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000034",
        "document": "21422021",
        "fullName": "Lylia Palacio Ruiz",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:09:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000161",
        "document": "5210332",
        "fullName": "Jairo Arturo Lopez Pasaje",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000006",
        "document": "80017517",
        "fullName": "Oscar Dario Santos Gonzalez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:09:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000148",
        "document": "91525026",
        "fullName": "Yesid Norberto Jerez Jerez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000151",
        "document": "65770856",
        "fullName": "Martha Isabel Campos Sánchez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000135",
        "document": "14799171",
        "fullName": "Alberto Cuervo Jimenez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000062",
        "document": "74428284",
        "fullName": "Mauricio Ramirez Aguirre",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000057",
        "document": "79406097",
        "fullName": "Jose Robinson Carreño Vargas",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000261",
        "document": "71628314",
        "fullName": "Mauricio Alberto Campillo Orozco",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Presidente Ceo",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000297",
        "document": "75063263",
        "fullName": "Cesar Augusto Arias Lopez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Gerente Gral Divis Premezc Y Materias",
        "uen": "Negocio Mip",
        "process": "Mip",
        "area": "Mip",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1127983",
        "document": "32104027",
        "fullName": "Yoly Angelica Perez Perez",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1118355",
        "document": "43727052",
        "fullName": "Beatriz Eugenia Hernadez Gomez",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Analista De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1429701",
        "document": "63488579",
        "fullName": "Erika Offelman Robles Diaz",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Coordinador De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1195400",
        "document": "1036604323",
        "fullName": "Eymy Yurany Vargas Rendon",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000227",
        "document": "43607967",
        "fullName": "Claudia Norela Osorio Zuñiga",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Coordinador De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1205315",
        "document": "29287491",
        "fullName": "Dora Ines Campos Kanney",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116637",
        "document": "1152195765",
        "fullName": "Andres David Higuita Orrego",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1134449",
        "document": "8010639",
        "fullName": "Sergio Ignacio Ruiz Arroyave",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "9044828",
        "document": "29549635",
        "fullName": "Olivia Gil P.",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103809",
        "document": "43438181",
        "fullName": "Luz Marina Agudelo M.",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Coordinador De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116041",
        "document": "43799355",
        "fullName": "Yudi Elena Gallego Marin",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Coordinador De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000201",
        "document": "14890203",
        "fullName": "Ricardo Leon Acevedo Lopez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Regional De Calidad",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:08:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000080",
        "document": "10139735",
        "fullName": "Juan Carlos Rivera Duque",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000275",
        "document": "79539983",
        "fullName": "Julio Ernesto Pedraza Vargas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Nutricionista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Nutricionistas",
        "fechaIngreso": "5/24/2017 2:08:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215230",
        "document": "38873186",
        "fullName": "Victoria Eugenia Garcia C.",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Jefe De Almacen",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000383",
        "document": "31415961",
        "fullName": "Monica Valencia Carvajal",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Coordinador Regional De Calidad",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:08:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000467",
        "document": "46366813",
        "fullName": "Maria del Pilar Vargas R.",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Gerente Control De Calidad",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000477",
        "document": "14896047",
        "fullName": "Mario Alfonso Ruiz Valencia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Director (a) Comercial Junior",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000216",
        "document": "30397626",
        "fullName": "Lina Constanza Veloza Pineda",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:08:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1233010",
        "document": "31640262",
        "fullName": "Adriana Patricia Rodriguez Sanchez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Cajera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Caja",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000376",
        "document": "38867564",
        "fullName": "Myriam Rengifo Fajardo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Secretaria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291055",
        "document": "14621326",
        "fullName": "Luis Felipe Tavera Hurtado",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Administrador Planta",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:08:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000448",
        "document": "14894642",
        "fullName": "Danilo Moreno Mendoza",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador De Sistemas",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Infraestructura",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2215005",
        "document": "38878971",
        "fullName": "Ana Rita Perez Vergara",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador De Plantas Asesoradas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1212590",
        "document": "14889823",
        "fullName": "Luis German Estrada Narvaez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Regional De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000274",
        "document": "79487164",
        "fullName": "Orlando Lopez Fonseca",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Nutricionista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Nutricionistas",
        "fechaIngreso": "5/24/2017 2:08:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000021",
        "document": "16790009",
        "fullName": "Gabriel Fernando Garcia Garcia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Director De Mascotas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000196",
        "document": "12748069",
        "fullName": "Daniel Fernando Ruiz Mosquera",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Director De Mascotas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:09:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000288",
        "document": "94477146",
        "fullName": "Miguel Alberto Jimenez Sanchez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000218",
        "document": "31640975",
        "fullName": "Sara Maria Ruiz Valencia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Comercial",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000020",
        "document": "16368325",
        "fullName": "Edinson Hernandez Poveda",
        "company": "Transgraneles",
        "regional": "Buga",
        "jobTitle": "Director Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000223",
        "document": "42775983",
        "fullName": "Gloria Cecilia Arenas Mejia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Vicepresidente(a) De Talento Humano",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "5/24/2017 2:08:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103365",
        "document": "21532841",
        "fullName": "Natalia Andrea Alvarez Mesa",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Compensaciones",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Compensaciones",
        "fechaIngreso": "5/24/2017 2:12:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000486",
        "document": "71378080",
        "fullName": "Ivan Dario Hincapie Londono",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coord De Servicios De Infraestructura",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Infraestructura",
        "fechaIngreso": "5/24/2017 2:15:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2234000",
        "document": "94391767",
        "fullName": "Mauricio Palomino Izquierdo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador De Plantas Asesoradas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000258",
        "document": "71421801",
        "fullName": "Johny Alexander Arredondo Mejía",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Activos Fijos",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "5/24/2017 2:11:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000276",
        "document": "79555862",
        "fullName": "Juan Carlos Gomez Parra",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director Reg Logistica De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000412",
        "document": "9738027",
        "fullName": "Diego Alejandro Montaño Garcia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:09:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000260",
        "document": "71611354",
        "fullName": "Bayron Nicolas Salazar Rodriguez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Control De Inventarios",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1162302",
        "document": "1020450763",
        "fullName": "Andres Felipe Giraldo Ramirez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1178321",
        "document": "71365280",
        "fullName": "Hernan Dario Muñoz Zapata",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1132915",
        "document": "98640669",
        "fullName": "Oscar Enrique Rodriguez Echavarria",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000354",
        "document": "8355930",
        "fullName": "Ivan Dario Montilla Muelle",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador Regional De Calidad",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1122831",
        "document": "71376460",
        "fullName": "Wilson Gonzalo Larrea Fernandez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1183205",
        "document": "71367869",
        "fullName": "Mario Andres Perez R.",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "9080970",
        "document": "43682340",
        "fullName": "Liliana Suarez M.",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1225095",
        "document": "1116246724",
        "fullName": "Monica Morales Marmolejo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1293000",
        "document": "14893837",
        "fullName": "Arles Villafane Cardona",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Aux Logist De Inventa De Pcto Terminado",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1458800",
        "document": "91178273",
        "fullName": "Luis Arturo Florez Ramirez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:15:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2413100",
        "document": "13689581",
        "fullName": "Alirio Agudelo Vargas",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Plantas Asesoradas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:15:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1412400",
        "document": "91291695",
        "fullName": "Erwing Cristianshen Ferrer Ortega",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Plantas Asesoradas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:15:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2422019",
        "document": "49664019",
        "fullName": "Nadya Ingrid Matta Briñez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Plantas Asesoradas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:15:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000388",
        "document": "91041439",
        "fullName": "Aladino Caceres Rodriguez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Coordinador De Plantas Asesoradas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:15:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1127980",
        "document": "32226103",
        "fullName": "Gloria Enid Perez P.",
        "company": "Solla",
        "regional": "Donm",
        "jobTitle": "Coordinador (a) Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1123437",
        "document": "32564825",
        "fullName": "Liliana Patricia Marulanda Chavarria",
        "company": "Solla",
        "regional": "Donm",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1368512",
        "document": "52984782",
        "fullName": "Diana Marcela Maldonado Rivera",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Gestor De Pedidos",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Gestión De Pedidos",
        "fechaIngreso": "5/24/2017 2:10:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007677",
        "document": "75088493",
        "fullName": "Camilo Andres Hernandez B.",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Administrador Planta",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Operaciones",
        "area": "Operaciones",
        "fechaIngreso": "5/24/2017 2:09:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000289",
        "document": "98523543",
        "fullName": "Omar Mesa Restrepo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Administrador Planta De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000320",
        "document": "19465662",
        "fullName": "Enrique Galiano Marin",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Vicepresid De Produccion Y Operaciones",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "5/24/2017 2:08:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "4000084",
        "document": "24589410",
        "fullName": "Obeida Rendon Vargas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Administrador Planta",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:08:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1109635",
        "document": "98544537",
        "fullName": "Mauricio Correa Correa",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Operaciones",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Operaciones",
        "area": "Operaciones",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1341950",
        "document": "79510589",
        "fullName": "Jait Acero",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:10:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1342000",
        "document": "18929530",
        "fullName": "Edier  Alvarado Jimenez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:10:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "4000048",
        "document": "98536457",
        "fullName": "Rene Ramiro Romero R.",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Quimico",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Análisis Químico",
        "fechaIngreso": "5/24/2017 2:09:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000348",
        "document": "39210383",
        "fullName": "Andrea Vanegas Cordoba",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coord De Investi Y Control Ambiental",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Operaciones",
        "area": "Operaciones",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000233",
        "document": "45757965",
        "fullName": "Alejandra Marcela Osorio Cogollo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador Regional De Calidad",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000403",
        "document": "73582274",
        "fullName": "Steve Jose Harvey Valencia",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Administrador(a) De Planta",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:08:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1144000",
        "document": "71211211",
        "fullName": "James Esneid Arboleda A.",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador De Almacen",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:08:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000277",
        "document": "79659098",
        "fullName": "Libardo Quiñones Segura",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Nutricion Monogastricos",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Monogástricos",
        "fechaIngreso": "5/24/2017 2:08:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000235",
        "document": "52435867",
        "fullName": "Alexandra Karina Amorocho Garcia",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Director Nutrición Acuicultura",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Rumiantes",
        "fechaIngreso": "5/24/2017 2:08:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000031",
        "document": "71393889",
        "fullName": "Jose David Escobar Ochoa",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Nutricionista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Nutricionistas",
        "fechaIngreso": "5/24/2017 2:11:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000278",
        "document": "79799291",
        "fullName": "Jorge Eliecer Galvis Gonzalez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Nutricionista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Nutricionistas",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000183",
        "document": "1037583383",
        "fullName": "Juan Camilo Duque Saldarriaga",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Asistente De Investigacion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Nutricionistas",
        "fechaIngreso": "5/24/2017 2:11:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000265",
        "document": "71660962",
        "fullName": "Jorge Alberto Patiño Jimenez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director Tecnico De Premezclas",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Premezclas",
        "fechaIngreso": "5/24/2017 2:10:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "9013701",
        "document": "51973297",
        "fullName": "Rocio Neira R.",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente De Formulacion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Monogástricos",
        "fechaIngreso": "5/24/2017 2:08:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000478",
        "document": "43922334",
        "fullName": "Catalina Restrepo Arias",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Nutricionista De Premezclas",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Premezclas",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000415",
        "document": "43102263",
        "fullName": "Sandra Patricia Pulgarin Mora",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Contador General Y De Impuestos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000331",
        "document": "43877298",
        "fullName": "Angela Maria Montoya Perez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director Juridico",
        "uen": "Corporativo Secretaría Gral Y Jurídica",
        "process": "Secretaría General Y Jurídica",
        "area": "Secretaría General Y Jurídica",
        "fechaIngreso": "5/24/2017 2:09:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000085",
        "document": "15923341",
        "fullName": "Julian Rodrigo Trejos Largo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Direct Zona Centro Division Balanceados",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:09:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2107270",
        "document": "43525869",
        "fullName": "Claudia Patricia Callejas Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000268",
        "document": "71686501",
        "fullName": "Pablo Aguirre Martinez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Vicepresidente Tecnico Y De Desarrollo",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000253",
        "document": "70567383",
        "fullName": "Juan Alberto Tobon Jaramillo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Vicepresidente De Compras Y Logística",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000326",
        "document": "98556170",
        "fullName": "Robinson Tabares Alzate",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Gerente De Operaciones",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000299",
        "document": "75086949",
        "fullName": "Andres Felipe Gonzalez Galvis",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Administrador Planta De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000169",
        "document": "10274022",
        "fullName": "Jose German Salazar Valencia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Porcicultu Divis Industrial",
        "uen": "Negocio Mip",
        "process": "Comercial Porcicultura",
        "area": "Comercial Porcicultura",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000179",
        "document": "10256450",
        "fullName": "Fernando Jaramillo Mejia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Avicultura Divis Balanceados",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000228",
        "document": "43626976",
        "fullName": "Maria Paulina Jimenez Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Vicepresidente De Mercadeo Corporativo",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000290",
        "document": "98524656",
        "fullName": "Israel Osorio Atehortua",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auditor Corporativo",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000073",
        "document": "37720774",
        "fullName": "Liseth Carolina Quintero Vargas",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Direct Zona Oriente Premezc Y Mat Primas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1122846",
        "document": "71315108",
        "fullName": "Carlos Andres Londoño Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Ti",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Tecnología",
        "fechaIngreso": "5/24/2017 2:16:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000298",
        "document": "75067200",
        "fullName": "Pedro Andres Parra Orjuela",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Vicepresidente De Consumo",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "5/24/2017 2:09:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000071",
        "document": "43532421",
        "fullName": "Isabel Cristina Correa Correa",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Direct Zona Ant Y Costa Divis De Premezc",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:08:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000204",
        "document": "15379528",
        "fullName": "Diego Mauricio Noreña Bernal",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Gerente Compras Nacionales Mat Primas",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras Nacionales",
        "fechaIngreso": "5/24/2017 2:08:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000250",
        "document": "70556056",
        "fullName": "Alfonso Vieira Gutierrez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Secretario General",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "5/24/2017 2:08:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000209",
        "document": "16687538",
        "fullName": "Juan Carlos Ayala V.",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Estrategia",
        "uen": "Corporativo Estrategia Y D.n.n",
        "process": "Estrategia",
        "area": "Estrategia",
        "fechaIngreso": "5/24/2017 2:08:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000317",
        "document": "43583307",
        "fullName": "Luz Mariela Urrea Velasquez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director Planeacion Financiera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "5/24/2017 2:08:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000257",
        "document": "71394831",
        "fullName": "Alejandro Velez Baena",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Administrador Operaciones Agropecuarias",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:09:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000328",
        "document": "71732609",
        "fullName": "Carlos Esteban Gallon Villegas",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Gerente De Logistica Y Trasnporte",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "5/24/2017 2:09:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000213",
        "document": "19462635",
        "fullName": "Pedro Enrique Sarmiento Blanco",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director Acuicultura Divis Balanceados",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:10:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000232",
        "document": "43867696",
        "fullName": "Luisa Fernanda Rodriguez Cano",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Gerente De Compras Internacionales",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Internacionales",
        "area": "Compras Internacionales",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000237",
        "document": "52996130",
        "fullName": "Ana Karina Garrido Villareal",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Asistente De Negocios Internacionales",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Negocios Internacionales",
        "area": "Negocios Internacionales",
        "fechaIngreso": "5/24/2017 2:10:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000324",
        "document": "71677424",
        "fullName": "Cesar Ivan Perez Rodriguez",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Coordinador Nacional De Operaciones",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000120",
        "document": "1111755158",
        "fullName": "Jose Luciano Vellaisac Riascos",
        "company": "Transgraneles",
        "regional": "Buen",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000077",
        "document": "14590859",
        "fullName": "Jose Luis Cruz Rivera",
        "company": "Transgraneles",
        "regional": "Buen",
        "jobTitle": "Coordinador De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000343",
        "document": "71785048",
        "fullName": "Carlos Mario Gutierrez Arango",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Analista De Operaciones",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000380",
        "document": "1121416256",
        "fullName": "Wilton Andres Acosta Tabares",
        "company": "Transgraneles",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000342",
        "document": "1037607760",
        "fullName": "Sandra Milena Amaya Henao",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Analista De Operaciones",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:09:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1139960",
        "document": "43456310",
        "fullName": "Gloria Dinency Zuluaga Perez",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Coordinador (a) De Condiciones",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Coordinación De Condiciones",
        "fechaIngreso": "5/24/2017 2:08:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000349",
        "document": "43547392",
        "fullName": "Diana Maria Patiño Botero",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador Regional De Talento Humano",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/24/2017 2:08:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000293",
        "document": "98703881",
        "fullName": "Lazaro Andres Ortega Benjumea",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asesor Tecnico De Operaciones",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Operaciones",
        "area": "Operaciones",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000295",
        "document": "71779583",
        "fullName": "Juan Carlos Gaviria Gaviria",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coord Tecnico Operacion Agropecuaria",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:11:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000229",
        "document": "43630041",
        "fullName": "Lina Claudia Garcia Rios",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Comercio Exterior",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Internacionales",
        "area": "Comercio Exterior",
        "fechaIngreso": "5/24/2017 2:08:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000411",
        "document": "71729912",
        "fullName": "Juan Roberto Ruiz Mejia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Planificador Pt",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Planeación De Producto Terminado",
        "fechaIngreso": "5/24/2017 2:08:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2116150",
        "document": "71724785",
        "fullName": "Mauricio Garcia Jimenez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Comercio Exterior",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Internacionales",
        "area": "Comercio Exterior",
        "fechaIngreso": "5/24/2017 2:08:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1327401",
        "document": "80255456",
        "fullName": "Freddy Alexander Pulido Fuentes",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000012",
        "document": "21620488",
        "fullName": "Bibiana Maria Bayer Salazar",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Coordinador Logistico De Genetica",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:08:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2423900",
        "document": "63350475",
        "fullName": "Doris Merchan Arguello",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Asistente Comercial",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:08:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000387",
        "document": "63479059",
        "fullName": "Alba Consuelo Ordonez Rueda",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Asistente Comercial",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:08:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1354320",
        "document": "3190159",
        "fullName": "Bayardo Castañeda Pulido",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador De Plantas Asesoradas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:15:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000378",
        "document": "1115080235",
        "fullName": "Mariana Galvis Baltan",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Gestor De Pedidos",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Gestión De Pedidos",
        "fechaIngreso": "5/24/2017 2:08:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1324005",
        "document": "52492048",
        "fullName": "Deyert Astrith Montañez Palacios",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Comercial",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000389",
        "document": "32259422",
        "fullName": "Mary Luz Carmona Rios",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000251",
        "document": "70559934",
        "fullName": "Carlos Emilio Saldarriaga Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Porcicultura De La División Balanceados",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000068",
        "document": "1036598424",
        "fullName": "Monica Marcela Ramirez Velez",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Asistente Administrativa",
        "uen": "Corporativo Transgraneles",
        "process": "Corporativo Transgraneles",
        "area": "Corporativo Transgraneles",
        "fechaIngreso": "5/24/2017 2:08:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103355",
        "document": "42762442",
        "fullName": "BC Mabel Acebedo Jaramillo (Retirada)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007683",
        "document": "98387047",
        "fullName": "Jesus Armando Diaz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Logistica",
        "uen": "Corporativo Compras Y Logística",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116628",
        "document": "1017218352",
        "fullName": "Juliana Gonzalez Gomez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Planeación Financiera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1122867",
        "document": "1039454606",
        "fullName": "BC Luisa Fernanda Lopera Tabares",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador(a) De Planeación Financiera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "5/24/2017 2:11:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1129223",
        "document": "1152439006",
        "fullName": "Luisa Fernanda Posada Gonzalez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador(a) Planeacion Financierapla",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "5/24/2017 2:11:03 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1118353",
        "document": "1128448088",
        "fullName": "Saul Eduardo Hernandez Cantero",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Rentabilidad",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103403",
        "document": "1017201234",
        "fullName": "Cindy Katherine Arango López",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Costos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "5/24/2017 2:11:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000238",
        "document": "63517886",
        "fullName": "Yaneth Patricia Delgado Claros",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Coordinador (a) Logistico Mt",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1412505",
        "document": "91236090",
        "fullName": "Juan Carlos Florez Sanabria",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:09:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000180",
        "document": "10258813",
        "fullName": "Jorge Hernan Duque Noreña",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Avicultura Divis Industrial",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:10:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1305043",
        "document": "80495419",
        "fullName": "Carlos Javier Ayala Cabrera",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador Regional Cedis",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "5/24/2017 2:08:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000393",
        "document": "80723622",
        "fullName": "Luis Alfonso Mora Bernal",
        "company": "Transgraneles",
        "regional": "Mosq",
        "jobTitle": "Coordinador De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000399",
        "document": "52024545",
        "fullName": "Maria Yomaira Garnica Neira",
        "company": "Agrinal",
        "regional": "Mosq",
        "jobTitle": "Jefe Recursos Humanos Y Sst",
        "uen": "Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/24/2017 2:08:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000248",
        "document": "70161335",
        "fullName": "Otoniel Giraldo R.",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:08:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000447",
        "document": "9726592",
        "fullName": "John Alexander Clavijo Tautiva",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Planificacion Mp",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000340",
        "document": "1094879317",
        "fullName": "Juan Sebastian Serna Mesa",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Sop",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Puertos Y Transporte",
        "fechaIngreso": "5/24/2017 2:09:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1290200",
        "document": "16363588",
        "fullName": "Rigoberto  Serna Molina",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1263101",
        "document": "94472464",
        "fullName": "Pablo Andres Porras Soto",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259101",
        "document": "94477653",
        "fullName": "Jose Guillermo Escobar Valencia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1234214",
        "document": "14318631",
        "fullName": "Javier Enrique Serrano Acevedo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:10:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1273580",
        "document": "6199683",
        "fullName": "William Mauricio Luna Rojas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1255350",
        "document": "14888465",
        "fullName": "Fernando Walter Cifuentes Pinzon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1275130",
        "document": "94480182",
        "fullName": "Mario German Moreno Cardona",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1108483",
        "document": "70730915",
        "fullName": "Javier Alonso Cardona Cardona",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Lider Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:10:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1108651",
        "document": "42791941",
        "fullName": "Alexandra Maria Castaño Henao",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Datos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:10:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070730",
        "document": "45472174",
        "fullName": "Sara Patricia Salas Uviedo",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Asistente De Trafico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepción Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:08:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000141",
        "document": "8030897",
        "fullName": "Diego Alexander Mejia Mejia",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:09:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000015",
        "document": "63322964",
        "fullName": "Martha Lidia Camargo Acevedo",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1227990",
        "document": "14895083",
        "fullName": "Victor Alonso Palomino Gonzalez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración Planta Mascotas",
        "fechaIngreso": "5/24/2017 2:08:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291500",
        "document": "94474771",
        "fullName": "Juan Pablo Valdes Herrada",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración Planta Mascotas",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000418",
        "document": "94483161",
        "fullName": "Johnathan Andres Peña Ramirez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:11:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1257500",
        "document": "94473517",
        "fullName": "Mario Fernando Duque Delgado",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1251577",
        "document": "1115067258",
        "fullName": "Bryant Alejandro Cardenas Salcedo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1205301",
        "document": "94476864",
        "fullName": "Ronald Cardona Lopez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:11:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000112",
        "document": "1020452450",
        "fullName": "Maria Alejandra Vasquez Lopez",
        "company": "Transgraneles",
        "regional": "Bell",
        "jobTitle": "Coordinador De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000008",
        "document": "71715270",
        "fullName": "Diego Alberto Cuartas Marin",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Trafico",
        "uen": "Corporativo Transgraneles",
        "process": "Corporativo Transgraneles",
        "area": "Corporativo Transgraneles",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1129217",
        "document": "98489751",
        "fullName": "Arley Guillermo Ramirez Posada",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador (a) Logistica De Plantas",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:09:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000321",
        "document": "8164121",
        "fullName": "Fernando Eliecer Garcia Arango",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Inventarios",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "5/24/2017 2:15:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000459",
        "document": "1116433417",
        "fullName": "Aymer Augusto Pereira Bueno",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Logistico De Transporte",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1126373",
        "document": "1036622694",
        "fullName": "Jenny Marcela Ortiz C.",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compensaciones",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Compensaciones",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116563",
        "document": "1146434320",
        "fullName": "Paula Andrea Gomez Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Compensaciones",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Compensaciones",
        "fechaIngreso": "5/24/2017 2:10:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1366502",
        "document": "1070955397",
        "fullName": "Eider Antonio Guerrero Guerrero",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador De Operación Logistica",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "4000110",
        "document": "29676406",
        "fullName": "Viviana Ximena Rivas Tafurt",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:09:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000222",
        "document": "38874976",
        "fullName": "Elizabeth Lasso Quintero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:09:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116143",
        "document": "1041150118",
        "fullName": "Maria Alejandra Garcia Tabares",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Talento Humano",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Formación Y Dllo",
        "fechaIngreso": "5/24/2017 2:09:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1108537",
        "document": "15338343",
        "fullName": "Guillermo Antonio Castañeda Murillo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:09:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000470",
        "document": "30288397",
        "fullName": "Diana Patricia Rodriguez Martinez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:15:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1129209",
        "document": "15405720",
        "fullName": "Ruben Dario Pineda Hernandez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Aplicaciones",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Aplicaciones",
        "fechaIngreso": "5/24/2017 2:10:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000294",
        "document": "71778315",
        "fullName": "Juan David Arango",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Aplicaciones",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Aplicaciones",
        "fechaIngreso": "5/24/2017 2:09:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000446",
        "document": "98624574",
        "fullName": "Gabriel Fernando Osorno Osorio",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:08:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000473",
        "document": "41945359",
        "fullName": "Sandra Milena Martinez Valenzuela",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000471",
        "document": "71656470",
        "fullName": "Fernando Martin Arcila Rodriguez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:15:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1108534",
        "document": "71294809",
        "fullName": "BC Juan Camilo Cano Cortes (Retirado)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Aplicaciones",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Aplicaciones",
        "fechaIngreso": "5/24/2017 2:10:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000199",
        "document": "14323213",
        "fullName": "Jose Gregorio Rengifo Molano",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador (a) Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000303",
        "document": "79189133",
        "fullName": "Jose Fernando Becerra Barrera",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002001",
        "document": "1116266101",
        "fullName": "Manuela Agudelo Giraldo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Gestor De Pedidos",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Gestión De Pedidos",
        "fechaIngreso": "5/24/2017 2:11:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000065",
        "document": "93379644",
        "fullName": "Fernando Londoño Jaramillo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000040",
        "document": "10125911",
        "fullName": "Carlos Arturo Orrego Correa",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:08:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000164",
        "document": "75081055",
        "fullName": "Juan Diego Hincapie Hincapie",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Direct Zona Ant Y Costa Divis De Premezc",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000064",
        "document": "65795423",
        "fullName": "Giselle Imelda Patiño Patiño",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000053",
        "document": "31945558",
        "fullName": "Janeth Milena Arango Moreno",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Director Zona Occidente Division Balanceados",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:09:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000175",
        "document": "1053782765",
        "fullName": "Carlos Daniel Patiño Garcia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:10:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000368",
        "document": "1098648125",
        "fullName": "Claudia Juliana Chacon Peña",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:10:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000123",
        "document": "1026147125",
        "fullName": "Yudi Alexandra Londoño Henao",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Corporativo Transgraneles",
        "process": "Corporativo Transgraneles",
        "area": "Corporativo Transgraneles",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000003",
        "document": "79626549",
        "fullName": "Jaime Augusto Rodriguez Lara",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:08:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000484",
        "document": "79188163",
        "fullName": "Fermin Sandoval Ramirez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1202404",
        "document": "1116258205",
        "fullName": "Andrea Karina Aristizabal Gil",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:11:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1274013",
        "document": "1112407714",
        "fullName": "Erwin Alfredo Matallana Ramirez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:12:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1233993",
        "document": "94472118",
        "fullName": "Luis James Sanchez Soto",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:12:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000451",
        "document": "6446354",
        "fullName": "Carlos Alberto Molina Villada",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1216000",
        "document": "94473437",
        "fullName": "Edwar Alexander Niño Santamaria",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Logistica",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291001",
        "document": "14897344",
        "fullName": "Oscar Alfredo Saavedra Gallego",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador De Operacion Logistica",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116040",
        "document": "43104954",
        "fullName": "Monica Maria Gallego Marin",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Servicio Al Cliente",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:16:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1131453",
        "document": "22029586",
        "fullName": "Martha Lucia Restrepo Arcila",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Gestor De Pedidos",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Gestión De Pedidos",
        "fechaIngreso": "5/24/2017 2:11:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0317",
        "document": "1039421621",
        "fullName": "Carlos Andres Valencia Rios",
        "company": "Temporales",
        "regional": "Itag",
        "jobTitle": "Accion Plus",
        "uen": "-",
        "process": "",
        "area": "Medellin",
        "fechaIngreso": "5/24/2017 2:11:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1274023",
        "document": "1115080014",
        "fullName": "Juan Camilo Martinez Blandon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1262475",
        "document": "31640265",
        "fullName": "Maria Clodet Garcia Duque",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1212580",
        "document": "14891820",
        "fullName": "Jorge Eliecer Escobar Calvo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Lider De Calidad Puerto Buenaventura",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1255137",
        "document": "1115078451",
        "fullName": "Julian Andres Gonzalez Calderon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:10:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1223013",
        "document": "1006337216",
        "fullName": "Gustavo Adolfo Londoño Gutierrez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1247105",
        "document": "1085278249",
        "fullName": "Nathalia Mercedes Benavides Ruano",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283511",
        "document": "1085910689",
        "fullName": "Jose Manuel Quintero Cardona",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215140",
        "document": "1114058887",
        "fullName": "Luisa Fernanda Gonzalez Bedoya",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:09:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291504",
        "document": "1116237614",
        "fullName": "Tammy Elizabeth Vargas Chocue",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000267",
        "document": "71665989",
        "fullName": "Carlos Mario Monsalve Naranjo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Suministros Y Servicios",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000417",
        "document": "43273469",
        "fullName": "Maria Cristina Munera Osorio",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coord Nacional Compras De Materia Prima",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras Nacionales",
        "fechaIngreso": "5/24/2017 2:09:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1105553",
        "document": "1037622211",
        "fullName": "Luis Miguel Bustamante Zapata",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Compras De Empaques",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras Material De Empaque",
        "fechaIngreso": "5/24/2017 2:11:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070186",
        "document": "50923992",
        "fullName": "Ana Carolina Molina H",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Coordinador Regional Cedis",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1316031",
        "document": "1032397742",
        "fullName": "Sergio Gonzalez Bohorquez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "9080996",
        "document": "14885659",
        "fullName": "Hansen Alberto Gonzalez R.",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Mejoramiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Operaciones",
        "area": "Operaciones",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1376500",
        "document": "1073508334",
        "fullName": "Pedro Romero Niño",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1366870",
        "document": "1073153497",
        "fullName": "Diego Armando Hurtado Perez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1310778",
        "document": "1094271388",
        "fullName": "Mario Alonso Paba Paez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Coordinador De Calidad",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:12:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1386438",
        "document": "1073153302",
        "fullName": "Leonardo Ruiz Duran",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000444",
        "document": "79892250",
        "fullName": "Luis Fernando Parra D.",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000072",
        "document": "79362600",
        "fullName": "Mauricio Ramirez Chaustre",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Direct Zona Centro Premez Y Mat Primas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:15:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000420",
        "document": "52159903",
        "fullName": "Sandra Patricia Cifuentes Castro",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Materias Primas",
        "fechaIngreso": "5/24/2017 2:08:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000042",
        "document": "7227508",
        "fullName": "Edgar Orlando Melo Torres",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Direct Zona Oriente Division Balanceados",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:09:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1137903",
        "document": "43280433",
        "fullName": "Juliana Velez Gutierrez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinadora De Compras Internacionales",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Internacionales",
        "area": "Compras Internacionales",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000203",
        "document": "15346889",
        "fullName": "Jaime Alonso Gil Escobar",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Transporte Y Almacenamiento",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Puertos Y Transporte",
        "fechaIngreso": "5/24/2017 2:08:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000052",
        "document": "79232770",
        "fullName": "Juan Camilo Rodriguez Herrera",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:10:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103423",
        "document": "1128390263",
        "fullName": "Ana Maria Alzate Posada",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Planeación Financiera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "5/24/2017 2:09:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1137904",
        "document": "42827269",
        "fullName": "Camila Andrea Velez Puerta",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Logistica",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Planeación De Inventarios",
        "fechaIngreso": "5/24/2017 2:12:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000041",
        "document": "15326861",
        "fullName": "Hugo Alberto Munera Preciado",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1131200",
        "document": "21428964",
        "fullName": "Francy Eliana Rios Caro",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Servicio Al Cliente",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Centro De Servicios",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000163",
        "document": "33366891",
        "fullName": "Alexandra Rodriguez Sanchez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:12:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000103",
        "document": "51985670",
        "fullName": "Diana Astrid Estrada Carrillo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:12:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000335",
        "document": "1020750859",
        "fullName": "David Eduardo Lasso Gomez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:12:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000397",
        "document": "24337317",
        "fullName": "Nelly Veronica Apraez Guarin",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:12:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000308",
        "document": "43876160",
        "fullName": "BC Isabel Cristina Parra Montoya (Shared)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Asistente De Presidencia",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "5/24/2017 2:10:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000055",
        "document": "79878400",
        "fullName": "Oscar Miguel Triana Duran",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000133",
        "document": "79592635",
        "fullName": "Juan Carlos Rangel C.",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:09:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000165",
        "document": "5823290",
        "fullName": "Jhon Fredy Cardenas Garcia",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:09:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000083",
        "document": "71222741",
        "fullName": "Mauricio Monsalve Gomez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:09:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000145",
        "document": "80545044",
        "fullName": "Juan Carlos Castro Cortes",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:09:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000193",
        "document": "1152435933",
        "fullName": "Alejandra Mendez Mejia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Activación",
        "fechaIngreso": "5/24/2017 2:09:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000402",
        "document": "43978487",
        "fullName": "Ana Carolina Palacio",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Trade",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Activación",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2425300",
        "document": "91490179",
        "fullName": "Edgar Norberto Peña Cortes",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Plantas Asesoradas",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1405015",
        "document": "91470714",
        "fullName": "Juan Domingo Ardila Sanchez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1406750",
        "document": "5736402",
        "fullName": "Isaias Bohorquez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002560",
        "document": "1143387261",
        "fullName": "Eliana Yuranis Brito Cabarcas",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepción Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:10:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1360800",
        "document": "80657721",
        "fullName": "Yeison Leonardo Franco Moreno",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:11:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1390901",
        "document": "11051798",
        "fullName": "Roberto Carlos Vergara Salgado",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:11:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1383143",
        "document": "7312389",
        "fullName": "Nestor Alfonso Pinilla Villamil",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:11:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000339",
        "document": "70082328",
        "fullName": "Luis Hernando Lotero Castañeda",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Desarr Y Fidelizac De Client",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Activación",
        "fechaIngreso": "5/24/2017 2:09:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071333",
        "document": "9296080",
        "fullName": "Robert Mauricio Lora Puello",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000430",
        "document": "79747146",
        "fullName": "Jaime Andres Delgado Monrroy",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Tecnico Junior",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000485",
        "document": "1128397337",
        "fullName": "Diego Alejandro Montoya Gil",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103357",
        "document": "1152208777",
        "fullName": "BC Sandra Milena Acevedo Munera (Shared)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000406",
        "document": "18506925",
        "fullName": "Fernando Adolfo Hoyos R.",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000464",
        "document": "71292604",
        "fullName": "Wilson Vasco Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Impuestos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000405",
        "document": "43909519",
        "fullName": "Viviana Valencia Roldan",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Impuestos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103363",
        "document": "1036636942",
        "fullName": "BC Wendy Carolina Agudelo Agudelo (Shared)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Impuestos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1131498",
        "document": "1037622188",
        "fullName": "Jenny Lorena Rendon Osorio",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116636",
        "document": "98614120",
        "fullName": "William Ornedis Guzman Jimenez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1274043",
        "document": "14898123",
        "fullName": "Erwin Javier Moncada",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1262488",
        "document": "14894244",
        "fullName": "Pablo Cesar Garcia Granobles",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:10:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1195261",
        "document": "98632322",
        "fullName": "Carlos Wbeymar Usma Mejia",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:08:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000350",
        "document": "98578085",
        "fullName": "Alexander Nieto Cardona",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Director De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:08:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000352",
        "document": "71876760",
        "fullName": "Luis Anibal Sanchez Zuleta",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Administrador Planta De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:08:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1158000",
        "document": "71142230",
        "fullName": "Carlos Alberto Echeverri Bedoya",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:08:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1195270",
        "document": "70328147",
        "fullName": "Gustavo Alejandro Valencia Zapata",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:08:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000254",
        "document": "71220797",
        "fullName": "Jorge Dorian Suarez Rua",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Auditoria",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "5/24/2017 2:09:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0188",
        "document": "1095791091",
        "fullName": "Maria Fernanda Bueno Castellanos",
        "company": "Temporales",
        "regional": "Giron",
        "jobTitle": "Laboratorista",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:11:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1497000",
        "document": "91354056",
        "fullName": "Hernando Botia Vera",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1429555",
        "document": "91428937",
        "fullName": "Roquelin Quijano Acevedo",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000285",
        "document": "91221710",
        "fullName": "Feisal Rances Garzon Barrera",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Asistente Tecnico",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000440",
        "document": "15447349",
        "fullName": "Juan Gabriel Castaño Vergara",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1134550",
        "document": "71279765",
        "fullName": "John Ledinson Sanchez Rendon",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Coordinador (a) Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000344",
        "document": "1020407764",
        "fullName": "Jenny Alejandra Jimenez Garcia",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Ejecutivo(a) Comercial",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:11:03 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000122",
        "document": "1036663218",
        "fullName": "Sebastian Castaño Garcia",
        "company": "Transgraneles",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000097",
        "document": "94481681",
        "fullName": "Diego Alexander Arce Acevedo",
        "company": "Transgraneles",
        "regional": "Buga",
        "jobTitle": "Ejecutivo(a) Comercial",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:10:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000118",
        "document": "1036606514",
        "fullName": "Veronica Andrea Vanegas Betancur",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:11:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0234",
        "document": "77163836",
        "fullName": "Mauro Alexander Orjuela Cañizalez",
        "company": "Temporales",
        "regional": "Mosq",
        "jobTitle": "Coordinador Cedi Neiva",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:10:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1406402",
        "document": "91178501",
        "fullName": "Joaquin Camacho Aza",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1404829",
        "document": "1094265080",
        "fullName": "Fabian Angarita Vasquez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:12:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000438",
        "document": "1037583349",
        "fullName": "Sara Luisa Betancur Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Procesos",
        "area": "Procesos",
        "fechaIngreso": "5/24/2017 2:11:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000436",
        "document": "94472975",
        "fullName": "Diego Fernando Rodriguez Guerrero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración Planta Mascotas",
        "fechaIngreso": "5/24/2017 2:10:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000458",
        "document": "80351387",
        "fullName": "Armando Rodriguez Afanador",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:09:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000249",
        "document": "70554710",
        "fullName": "Henry Arturo Colorado Colorado",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asistente Tecnico",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:11:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1249590",
        "document": "14651363",
        "fullName": "Victor Andres Betancour Gil",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:11:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1251108",
        "document": "31641737",
        "fullName": "Paola Andrea Rios Escobar",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000356",
        "document": "98564853",
        "fullName": "Libardo Albeiro Lopez Alzate",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000358",
        "document": "98519955",
        "fullName": "Luis Eduardo Velasquez Velasquez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000269",
        "document": "71728534",
        "fullName": "Luis Alejandro Botero Mira",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000360",
        "document": "71383061",
        "fullName": "Diego Mauricio Sanchez Parra",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1144832",
        "document": "98570152",
        "fullName": "Edison Robert Arenas Loaiza",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000362",
        "document": "17338935",
        "fullName": "Mauricio Trillos Sanchez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000359",
        "document": "70325861",
        "fullName": "Gerson De Jesus Ruiz Castrillon",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000279",
        "document": "80577388",
        "fullName": "Francisco M. Guerrero Corredor",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:09:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1416495",
        "document": "1098663660",
        "fullName": "Johan Sebastian Gomez Piamonte",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Gestor De Pedidos",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1469899",
        "document": "91489882",
        "fullName": "Jairo Alexander Mantilla Sanchez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1483190",
        "document": "1098723022",
        "fullName": "Miguel Angel Patiño Laguado",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1417400",
        "document": "91288759",
        "fullName": "William Lopez Esparza",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Aux Logist De Inventa De Pcto Terminado",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1377500",
        "document": "1073502427",
        "fullName": "Hernan Camilo Muñoz Suquila",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador (a) Logistica De Plantas",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:09:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1455555",
        "document": "37548426",
        "fullName": "Ruby Liliana Solano Zambrano",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Almacen De Suministros",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:08:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000236",
        "document": "1044500555",
        "fullName": "Deisy Johana Perez Muñoz",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Auxiliar Logistico Operacion Pecuaria",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:12:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1154200",
        "document": "71215180",
        "fullName": "Robinson Arley Castro Cardona",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1190423",
        "document": "71221087",
        "fullName": "John Arley Salazar Salazar",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:11:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1356021",
        "document": "1070949459",
        "fullName": "Daniel Yobanny Diaz Gaitan",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:15:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1361350",
        "document": "80008975",
        "fullName": "Luis Carlos Garzon Angel",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:12:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000466",
        "document": "74020639",
        "fullName": "Raul Yesid Enciso Diaz",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000241",
        "document": "6479246",
        "fullName": "Walter  Galvis Giraldo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:09:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283102",
        "document": "6321966",
        "fullName": "Fabian Fernando Plaza Castaño",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador De Operacion Logistica",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:12:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1273101",
        "document": "94473778",
        "fullName": "Javier Alberto Londoño Morales",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:12:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1226191",
        "document": "1115066266",
        "fullName": "Julian  Orozco Morato",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:12:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1231230",
        "document": "94483120",
        "fullName": "John Einer Rengifo Rosada",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:11:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1458555",
        "document": "1097304098",
        "fullName": "Joselito Espinosa Ortega",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070437",
        "document": "73556753",
        "fullName": "Jorge Luis Almeida Torres",
        "company": "Agrinal",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Gestión De Pedidos",
        "fechaIngreso": "5/24/2017 2:11:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1284415",
        "document": "79652453",
        "fullName": "Jhon Wilson Ramos Rincon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1233991",
        "document": "1115083209",
        "fullName": "Julian Stiven Sanchez Cuitiva",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1294000",
        "document": "16401520",
        "fullName": "Jesus Edilson Waltero Cardona",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1271080",
        "document": "14883631",
        "fullName": "Jose Ramiro Ladino Soto",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1252851",
        "document": "94479826",
        "fullName": "Julio Eduardo Castano Taborda",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1220053",
        "document": "1115084751",
        "fullName": "Marcos Lozada Bejarano",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1236003",
        "document": "94471702",
        "fullName": "Alcibiades Valencia Lopez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1293105",
        "document": "14892682",
        "fullName": "John Jair Yate Sandoval",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1205328",
        "document": "94266437",
        "fullName": "Willinton Cordoba Lucero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1225006",
        "document": "1115065926",
        "fullName": "Ruben Muriel Molina",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Almacen Repuestos Y S",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215076",
        "document": "6537066",
        "fullName": "Luis Fernando Libreros R.",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1273692",
        "document": "14571466",
        "fullName": "Estiven  Marmolejo Cartagena",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1110000",
        "document": "98542287",
        "fullName": "Carlos Emilio Escobar Escobar",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:15:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1308200",
        "document": "1073151769",
        "fullName": "Juan Camilo Chavarro Castro",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2116140",
        "document": "71719820",
        "fullName": "Juan Carlos Garcia Cardenas",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1231200",
        "document": "38879747",
        "fullName": "Paula Andrea Ramirez Salinas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000194",
        "document": "12556199",
        "fullName": "Nelson Benavides Moncada",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Logistico De Puertos",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Puertos Y Transporte",
        "fechaIngreso": "5/24/2017 2:09:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1451001",
        "document": "91249030",
        "fullName": "Norberto Jesus Cardenas C.",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:09:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000234",
        "document": "52095660",
        "fullName": "Maribel Vanegas Morales",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Secretaria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:09:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2433608",
        "document": "28078498",
        "fullName": "Ana Belen Rangel Sanabria",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1227955",
        "document": "29226935",
        "fullName": "Milena Padilla Quintero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Secretaria",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Puertos Y Transporte",
        "fechaIngreso": "5/24/2017 2:08:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2107151",
        "document": "57433820",
        "fullName": "Maria Benedetti N.",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Secretaria",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Puertos Y Transporte",
        "fechaIngreso": "5/24/2017 2:08:55 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0320",
        "document": "1075268212",
        "fullName": "Jhon Edison Mendez Vargas",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Dar Ayuda",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/24/2017 2:12:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215321",
        "document": "1114456754",
        "fullName": "Jose Luis Daza Urbano",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración Planta Mascotas",
        "fechaIngreso": "5/24/2017 2:10:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1202402",
        "document": "1115068567",
        "fullName": "Jenny Marcela Arismendi Gallego",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Talento Humano",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/24/2017 2:10:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1491602",
        "document": "13928125",
        "fullName": "Juan De Jesus Sanchez S.",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1416511",
        "document": "91505726",
        "fullName": "Harvey Gonzalez Gonzalez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:10:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1271016",
        "document": "1116255763",
        "fullName": "Diana Marcela Cordoba Celis",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215338",
        "document": "1116270748",
        "fullName": "Juliana Dominguez Ocampo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000208",
        "document": "16669027",
        "fullName": "Juan Jose Jaramillo J.",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Nutricion Monogastricos",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Monogástricos",
        "fechaIngreso": "6/16/2017 5:27:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116139",
        "document": "1128272958",
        "fullName": "Viviana Garcia Quiceno",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Impuestos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "6/28/2017 6:00:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1109631",
        "document": "1017251616",
        "fullName": "Angie Paola Correa Velez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Impuestos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "7/4/2017 3:50:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1131497",
        "document": "98466796",
        "fullName": "Edwin Alfonso Restrepo Cardona",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compensaciones",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Compensaciones",
        "fechaIngreso": "7/11/2017 5:42:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000472",
        "document": "70326671",
        "fullName": "Juan Santiago Sierra Sierra",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Director Zona Norte Division Balanceados",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "7/28/2017 4:47:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1273103",
        "document": "14884986",
        "fullName": "Jairo Loaiza Muñoz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000462",
        "document": "1037611038",
        "fullName": "Francisco Javier Quintero Rendon",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador(a) Analitica De Datos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "5/24/2017 2:15:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1224001",
        "document": "29229308",
        "fullName": "Jirleanch Patricia Moreno Ibarra",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Puertos Y Transporte",
        "fechaIngreso": "5/24/2017 2:13:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103812",
        "document": "3474453",
        "fullName": "Juan Fernando Aguilar Barrientos",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Aplicaciones",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Aplicaciones",
        "fechaIngreso": "8/16/2017 3:23:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103354",
        "document": "1128468272",
        "fullName": "Jakeline Acevedo Correa",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Logistico De Puertos",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007491",
        "document": "1017156104",
        "fullName": "Bibiana Cristina Usuga Alvarez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Cad",
        "uen": "Corporativo Secretaría Gral Y Jurídica",
        "process": "Secretaría General Y Jurídica",
        "area": "Cad",
        "fechaIngreso": "5/24/2017 2:15:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000475",
        "document": "1048016890",
        "fullName": "Santiago Garzon Sepulveda",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Director Administrativo",
        "uen": "Corporativo Transgraneles",
        "process": "Corporativo Transgraneles",
        "area": "Corporativo Transgraneles",
        "fechaIngreso": "5/24/2017 2:10:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1155500",
        "document": "98570723",
        "fullName": "William Enrique Chalarca Montoya",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Cad",
        "uen": "Corporativo Secretaría Gral Y Jurídica",
        "process": "Secretaría General Y Jurídica",
        "area": "Cad",
        "fechaIngreso": "5/24/2017 2:11:03 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116118",
        "document": "71693457",
        "fullName": "John Fabio Galvis Osorio",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Pago De Proveedores",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Tesoreria",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116520",
        "document": "71703302",
        "fullName": "Jorge Wilson Gomez Catano",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Cad",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:12:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000014",
        "document": "70413572",
        "fullName": "Carlos Eugenio Restrepo Gonzalez",
        "company": "Solla",
        "regional": "Entr",
        "jobTitle": "Coordinador Granjas Porcicolas",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:12:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1335100",
        "document": "34550829",
        "fullName": "Maria Eugenia Walteros Puerta",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1107200",
        "document": "71361535",
        "fullName": "Jorge Alexis Calderon Galvis",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador Granjas Porcicolas",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1178701",
        "document": "71876730",
        "fullName": "Luis Alberto Ocampo Sanchez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Almacen De Suministros",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:11:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1274006",
        "document": "1115068443",
        "fullName": "Maria Eugenia Martinez Serna",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración Planta Mascotas",
        "fechaIngreso": "5/24/2017 2:10:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1334100",
        "document": "52180091",
        "fullName": "Odry Lizzette Saboya",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1108676",
        "document": "71754537",
        "fullName": "Jhon Alexander Castaño Vargas",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:11:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "9044989",
        "document": "10546813",
        "fullName": "Henry Loaiza Zapata",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Logistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000385",
        "document": "63333285",
        "fullName": "Claudia Patricia Montagut Ortega",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Secretaria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000214",
        "document": "19483789",
        "fullName": "Juan Pablo Peralta Rincon",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:09:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1227950",
        "document": "38864522",
        "fullName": "Carmen Elena Palacios Salcedo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Secretaria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000452",
        "document": "1073503720",
        "fullName": "Carlos Arturo Torres Baquero",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador De Almacen",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:09:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "S1205318",
        "document": "1115074285",
        "fullName": "Diana Carolina Valderrama G.",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Talento Humano",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1416490",
        "document": "37549805",
        "fullName": "Sandra Milena Gomez Pacheco",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Comercial",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1458616",
        "document": "63544184",
        "fullName": "Yuri Tatiana Florez Garavito",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Talento Humano",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/24/2017 2:09:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "9015230",
        "document": "52175626",
        "fullName": "Belen Castiblanco Pena",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143560",
        "document": "16055748",
        "fullName": "Junior Arnoldo Alzate Ramirez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:09:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143000",
        "document": "1041146621",
        "fullName": "Manuel De Jesus Adarve Perez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:09:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1325151",
        "document": "35527263",
        "fullName": "Yolanda Murcia Ovalle",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Almacen De Suministros",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:09:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000345",
        "document": "44003337",
        "fullName": "Carolina  Mesa Pineda",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Asistente Gestión Tecnologica",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Investigación Y Desarrollo",
        "fechaIngreso": "5/24/2017 2:11:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000347",
        "document": "1128272636",
        "fullName": "Oliver Restrepo Rojas",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Asistente De Investigacion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Investigación Y Desarrollo",
        "fechaIngreso": "5/24/2017 2:11:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1383170",
        "document": "80360714",
        "fullName": "Edgar Bernardo Pinzon Rincon",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:15:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1183198",
        "document": "70433082",
        "fullName": "Elkin Fabian Perez Gomez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:11:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1833001",
        "document": "94471199",
        "fullName": "Didier Fernando Paredes Quintero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración Planta Mascotas",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000196",
        "document": "15429844",
        "fullName": "Jhon Jairo Giraldo Gomez",
        "company": "Solla",
        "regional": "Rion",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Avícola",
        "fechaIngreso": "5/24/2017 2:15:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1366835",
        "document": "11449733",
        "fullName": "Wilber Andres Hernandez Mahecha",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:14:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1193101",
        "document": "1020394054",
        "fullName": "Juan Camilo Tamayo Garcia",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1377900",
        "document": "7689405",
        "fullName": "Jorge Orlando Ortiz Vargas",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1194100",
        "document": "70851290",
        "fullName": "Cesar Augusto Toro Correa",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1377600",
        "document": "11367477",
        "fullName": "Jhon Wilfer Naranjo Becerra",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:09:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1275000",
        "document": "14890770",
        "fullName": "Jose Agobardo Montalvo Toro",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:12:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1223506",
        "document": "1114456454",
        "fullName": "Kelin Johana Melo Vargas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1342020",
        "document": "80577576",
        "fullName": "Wilman Albino Agudelo Agudelo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1159810",
        "document": "71614031",
        "fullName": "Lorenzo Antonio Florez Vergara",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1255351",
        "document": "1115062363",
        "fullName": "Omar Olmedo Cifuentes Bocanegra",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1180100",
        "document": "71657672",
        "fullName": "Juan Carlos Ospina Osorio",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1150950",
        "document": "15336330",
        "fullName": "Manuel Jose Botero Henao",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1262480",
        "document": "1115066874",
        "fullName": "Jhon Wilther Garcia Quique",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:12:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1153900",
        "document": "98573840",
        "fullName": "Ruben Dario Castaño Yepes",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:11:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1183250",
        "document": "15510259",
        "fullName": "Oswaldo De Jesus Pineda Arias",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1138240",
        "document": "71720489",
        "fullName": "Wilmar De Jesus Villegas Gomez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1485000",
        "document": "91511960",
        "fullName": "Oscar Javier Rangel Remolina",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000365",
        "document": "43559223",
        "fullName": "Damaris Ruby Serna Duque",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador (a) Cad",
        "uen": "Corporativo Secretaría Gral Y Jurídica",
        "process": "Secretaría General Y Jurídica",
        "area": "Cad",
        "fechaIngreso": "5/24/2017 2:11:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1242605",
        "document": "94474670",
        "fullName": "Carlos Romulo Arana Quintero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:10:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1122851",
        "document": "3408332",
        "fullName": "Carlos Mario Londoño Vasquez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:11:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1377888",
        "document": "80656793",
        "fullName": "Fabio Nelson Orjuela Umaña",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1159150",
        "document": "8464970",
        "fullName": "Dilson Eduvian Estrada Duque",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1292200",
        "document": "14895411",
        "fullName": "Waldo Velez Marin",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259520",
        "document": "94478707",
        "fullName": "Jorge Hernan Bejarano Angel",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1262485",
        "document": "5837324",
        "fullName": "Luis Eduardo Garcia Galindo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:10:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1175751",
        "document": "98517931",
        "fullName": "Fulbio De Jesus Merino Cano",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000115",
        "document": "3634524",
        "fullName": "Genaro De Jesus Orrego Toro",
        "company": "Solla",
        "regional": "Rion",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Centro De Investigación Canina",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1383133",
        "document": "80577397",
        "fullName": "Jhon Freddy Panqueba Padilla",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:14:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1132900",
        "document": "15382703",
        "fullName": "Leon Jairo Rodriguez Correa",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1152000",
        "document": "70434445",
        "fullName": "Jose Alexander Carvajal Correa",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1383145",
        "document": "79837598",
        "fullName": "Fredy Antonio Pinzon Archila",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000193",
        "document": "15430385",
        "fullName": "Carlos Mario Sanchez Rendon",
        "company": "Solla",
        "regional": "Rion",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Avícola",
        "fechaIngreso": "5/24/2017 2:15:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1361500",
        "document": "81754429",
        "fullName": "Jhonn Freyman Garzon Vallejo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:09:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000311",
        "document": "39303555",
        "fullName": "Martha Ines Goez Usuga",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Recepcionista",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1178299",
        "document": "71936041",
        "fullName": "Carlos Arturo Mosquera Asprilla",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283050",
        "document": "94476215",
        "fullName": "John Wagner Perez Abella",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1292511",
        "document": "94480323",
        "fullName": "Hugo Edinson Villada Acevedo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Movimiento De Materias Primas",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:14:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000341",
        "document": "8103964",
        "fullName": "Luis Bernardo Garcia Mona",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Porcicultura",
        "area": "Comercial Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1190850",
        "document": "71609558",
        "fullName": "Jose Aicardo Sepulveda Arias",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1122832",
        "document": "37841331",
        "fullName": "Lida Vibiana Lizarazo Medina",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Coordinador Granjas Porcicolas",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000351",
        "document": "43842532",
        "fullName": "Sandra Eunice Restrepo Calle",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Salud Ocupacional",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/24/2017 2:09:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1464096",
        "document": "91542242",
        "fullName": "Carlos Hernandez Carvajal",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1377105",
        "document": "80495269",
        "fullName": "David Moreno Avendaño",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000461",
        "document": "1115080771",
        "fullName": "German Maya Rodriguez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración Planta Mascotas",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283607",
        "document": "94477072",
        "fullName": "Raul Damian Ramirez Bustamante",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1263100",
        "document": "1115074698",
        "fullName": "Andres Felipe Potes Alegrias",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1247102",
        "document": "1115074066",
        "fullName": "Martha Liliana Arredondo Hernandez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Almacen Repuestos Y S",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000280",
        "document": "8057912",
        "fullName": "Jorge Ivan Vasquez Gonzalez",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Coordinador (a) De Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:11:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1275433",
        "document": "94478922",
        "fullName": "Oscar Alexander Ossa Muñoz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:14:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1271017",
        "document": "1110558287",
        "fullName": "Jhon Edinson Cordoba Chinome",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1268429",
        "document": "6537818",
        "fullName": "Jose Hermes Henao Camacho",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000429",
        "document": "71173480",
        "fullName": "Gustavo Restrepo Zuleta",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1185752",
        "document": "98539666",
        "fullName": "Luis Carlos Restrepo Bustamante",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1160003",
        "document": "71335745",
        "fullName": "Juan Pablo Garces Rendon",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1165448",
        "document": "71659368",
        "fullName": "Gildardo De Jesus Gutierrez Cano",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1188600",
        "document": "70515311",
        "fullName": "Fabio Antonio Rivera Lopez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1150100",
        "document": "98538034",
        "fullName": "Javier Giovanni Bonilla Florez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1256302",
        "document": "94478571",
        "fullName": "Wilmar Corrales Romero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1491600",
        "document": "91253542",
        "fullName": "Luis Emilio Sanchez Robles",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1272455",
        "document": "14894299",
        "fullName": "Hadder Gallego Agudelo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1273015",
        "document": "1115072559",
        "fullName": "Jhon Sebastian Lopez Tigreros",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:10:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1262466",
        "document": "94478828",
        "fullName": "Jose Dany Garzon Rodriguez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1492502",
        "document": "91178298",
        "fullName": "Carlos Arnaldo Sarmiento Rueda",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1354410",
        "document": "1022323561",
        "fullName": "Diego Fernando Cortes Ronceria",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:14:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1390888",
        "document": "11367509",
        "fullName": "Wilson  Vanegas Romero",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:14:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291200",
        "document": "1115069383",
        "fullName": "Jayson Salazar Diaz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:14:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1390950",
        "document": "1070948834",
        "fullName": "Harrison Mauricio Zapata Robayo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1464099",
        "document": "1097303190",
        "fullName": "Eymer Hernandez D.",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1273104",
        "document": "14897681",
        "fullName": "Yaley Loaiza Bautista",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1233999",
        "document": "6320419",
        "fullName": "William  Salcedo Lopez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291509",
        "document": "1115080878",
        "fullName": "Angly Julieth Velez Puentes",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1327402",
        "document": "80655227",
        "fullName": "German Eduardo Quintero Ballen",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1220801",
        "document": "6321115",
        "fullName": "Jose William Lizcano Gomez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración Planta Mascotas",
        "fechaIngreso": "5/24/2017 2:13:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1153901",
        "document": "15385321",
        "fullName": "Uberley Castro Castro",
        "company": "Solla",
        "regional": "Rion",
        "jobTitle": "Mayordomo",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Avícola",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1390889",
        "document": "11449093",
        "fullName": "Henry Mauricio Vargas Florez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1316001",
        "document": "1073509842",
        "fullName": "Edna Sorany Gutierrez Rodriguez (Shared)",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Gestor De Pedidos",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Gestión De Pedidos",
        "fechaIngreso": "5/24/2017 2:11:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1251578",
        "document": "94475805",
        "fullName": "Carlos Julio Candamil Cobo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1386899",
        "document": "80222545",
        "fullName": "Jeisson Sarmiento Caicedo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1449699",
        "document": "91530717",
        "fullName": "Jairo Andres Bueno Cañon",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1484089",
        "document": "1101340122",
        "fullName": "Orlando Rojas Rodriguez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143998",
        "document": "70510984",
        "fullName": "Javier Alonso Arango Acevedo",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1249992",
        "document": "6390237",
        "fullName": "Gian Carlo Burgos Campiño",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:12:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1275417",
        "document": "94287005",
        "fullName": "Omar Jonny Osorio Alarcon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:14:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000479",
        "document": "1128414459",
        "fullName": "Ana Jael Villa Monsalve",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:13:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1132966",
        "document": "1017153491",
        "fullName": "Anny Jhulieth Franco Valencia",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar Logistico Operacion Pecuaria",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1123711",
        "document": "1020469855",
        "fullName": "Juan Esteban Molina Viloria",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Impulsador Profesional",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:13:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259102",
        "document": "94357639",
        "fullName": "Fernando Andres Fajardo Leal",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1231236",
        "document": "1116266070",
        "fullName": "Lisset Tatiana Rendon Bambague",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Control Ambiental",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Operaciones",
        "area": "Operaciones",
        "fechaIngreso": "5/24/2017 2:13:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1268702",
        "document": "1115067976",
        "fullName": "Jhonatan Andres Hernandez Arias",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1177901",
        "document": "15371400",
        "fullName": "Wbeimar Arturo Monsalve Macias",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1265020",
        "document": "6199891",
        "fullName": "Hernan William Montoya Tamayo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:14:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1187001",
        "document": "1036602835",
        "fullName": "John David Rico Cano",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1316037",
        "document": "1077321051",
        "fullName": "Diego Hernando Gonzalez Ramirez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:14:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1104483",
        "document": "1148697992",
        "fullName": "Janderley Atehortua Gutierrez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Control Ambiental",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Operaciones",
        "area": "Operaciones",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1132916",
        "document": "1035870903",
        "fullName": "Pablo Antonio Rodriguez Marquez",
        "company": "Solla",
        "regional": "Rion",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Centro De Investigación Canina",
        "fechaIngreso": "5/24/2017 2:13:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1226002",
        "document": "94479299",
        "fullName": "Hector Diego Navarro Micolta",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000422",
        "document": "32298039",
        "fullName": "Ana Maria Castaño Tamayo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Trade Proteina",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Activación",
        "fechaIngreso": "5/24/2017 2:12:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1205402",
        "document": "1114059625",
        "fullName": "Jose Wilber Cardona Pelaez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1268530",
        "document": "1083466844",
        "fullName": "Edwin Andres Hoyos Londoño",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1262457",
        "document": "18608441",
        "fullName": "Victor Hugo Galvez Benitez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1483196",
        "document": "1098696932",
        "fullName": "Luis Carlos Perea Garcia",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1342036",
        "document": "11365366",
        "fullName": "Jhon Edwin Alonso",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/25/2017 5:39:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1225022",
        "document": "1113637037",
        "fullName": "Hector Fabio Mueses Burgos",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "6/26/2017 5:51:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1221537",
        "document": "1116260314",
        "fullName": "Oswaldo Velez Alayon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "6/27/2017 9:24:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259526",
        "document": "1114062713",
        "fullName": "Luis David Bermudez Muñoz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "7/6/2017 5:55:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1268434",
        "document": "1115079577",
        "fullName": "Carlos Alberto Guzman Escobar",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Almacenamiento De Mp Mascotas",
        "fechaIngreso": "6/27/2017 9:24:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1247008",
        "document": "1115079806",
        "fullName": "Oscar Danilo Barbosa Osorio",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "6/27/2017 9:24:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1457006",
        "document": "37863711",
        "fullName": "Maritza Diaz Rueda",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Coordinador Regional De Talento Humano",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "8/22/2017 6:11:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116523",
        "document": "1020463432",
        "fullName": "Jenifer Gomez Londoño",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador (a) De Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Avícola",
        "fechaIngreso": "8/8/2017 9:25:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1231241",
        "document": "1115087644",
        "fullName": "Robinson Rangel Roldan",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "8/23/2017 5:28:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000059",
        "document": "80577280",
        "fullName": "Andres Edgardo Bravo Bernal",
        "company": "Transgraneles",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000426",
        "document": "79284127",
        "fullName": "Diego Mauricio Bernal Rodriguez",
        "company": "Transgraneles",
        "regional": "Cart",
        "jobTitle": "Jefe De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000384",
        "document": "60380567",
        "fullName": "Claudia Mireya Torres Bautista",
        "company": "Transgraneles",
        "regional": "Cucu",
        "jobTitle": "Jefe De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000423",
        "document": "66980718",
        "fullName": "Claudia Patricia Fernandez Delgado",
        "company": "Transgraneles",
        "regional": "Sanm",
        "jobTitle": "Jefe De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000449",
        "document": "72346109",
        "fullName": "Hector Millan Duarte",
        "company": "Transgraneles",
        "regional": "Barr",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000425",
        "document": "79465971",
        "fullName": "Jose Luis Villa Gomez",
        "company": "Transgraneles",
        "regional": "Barr",
        "jobTitle": "Jefe De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000116",
        "document": "36697400",
        "fullName": "Ana Elisa Danies C.",
        "company": "Transgraneles",
        "regional": "Sanm",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:08:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000136",
        "document": "1113042305",
        "fullName": "Sebastian Calero Jimenez",
        "company": "Transgraneles",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "8/22/2017 6:44:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071329",
        "document": "9287653",
        "fullName": "Luis Enrique Puello Quintana",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071371",
        "document": "1026579944",
        "fullName": "Lina Marcela Cardenas Carvajal",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:12:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070193",
        "document": "1128053318",
        "fullName": "Kewin Ruiz Manjarres",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Almacen",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:15:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071368",
        "document": "1047427352",
        "fullName": "Ray Ricardo Zambrano Gonzalez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:12:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071777",
        "document": "9296971",
        "fullName": "Cesar Eduardo Guerra Moreno",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:12:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0291",
        "document": "1098710510",
        "fullName": "Eliana  Gómez Zambrano",
        "company": "Temporales",
        "regional": "Giron",
        "jobTitle": "Temporal Dar Ayuda",
        "uen": "-",
        "process": "",
        "area": "Auxiliar De Oficina",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000230",
        "document": "43723156",
        "fullName": "Clara Milena Rojas Toro",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Porcicultura",
        "area": "Comercial Porcicultura",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000211",
        "document": "19426851",
        "fullName": "Nestor Fernando Avila Ramirez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "5/24/2017 2:09:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000392",
        "document": "1019046728",
        "fullName": "Diana Alexandra Puentes Gomez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Porcicultura",
        "area": "Comercial Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000131",
        "document": "3572477",
        "fullName": "Luis Angel Gonzalez Arango",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1251898",
        "document": "1115069893",
        "fullName": "Julian Fernando Caicedo Alzate",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Dosificación Y Mezcla Mascotas",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283600",
        "document": "4437920",
        "fullName": "Yower Osvaldo Ramirez Franco",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:11:03 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1293010",
        "document": "94475995",
        "fullName": "John Wahyner Silva Velasquez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "5/24/2017 2:14:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1266350",
        "document": "16891789",
        "fullName": "Robinson Gaviria",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1354370",
        "document": "80428348",
        "fullName": "Jairo  Castro Castro",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1144830",
        "document": "71226789",
        "fullName": "Sergio Yamu Ardila Ordoñez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1153880",
        "document": "71992034",
        "fullName": "Jorge Ivan Castaño Ossa",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116847",
        "document": "71992561",
        "fullName": "Jaime Alberto Muñoz Martinez",
        "company": "Solla",
        "regional": "Lace",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1191020",
        "document": "71876212",
        "fullName": "Elkin De Jesus Serna Palacio",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Mensajero Conductor",
        "uen": "Corporativo Secretaría Gral Y Jurídica",
        "process": "Secretaría General Y Jurídica",
        "area": "Cad",
        "fechaIngreso": "5/24/2017 2:13:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1247005",
        "document": "94477697",
        "fullName": "Jhon Gerley Azcarate Loaiza",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1435555",
        "document": "1095919242",
        "fullName": "Nelson Yair Romero Herreño",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1385466",
        "document": "80430762",
        "fullName": "Juan Hector Riaño Malpica",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1481000",
        "document": "5672460",
        "fullName": "Marco Fidel Niño Rubio",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291502",
        "document": "14891117",
        "fullName": "Luis Efren Valdes Pizarro",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Mascotas",
        "fechaIngreso": "5/24/2017 2:14:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1274050",
        "document": "6445565",
        "fullName": "Juan Carlos Mendoza Lizarazo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1268201",
        "document": "14893351",
        "fullName": "Oscar Antonio Giron Chavez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:11:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1415011",
        "document": "1098699207",
        "fullName": "Oscar Dario Guzman Patiño",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1273690",
        "document": "14894518",
        "fullName": "Helio Fabio Marmolejo Bueno",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1258030",
        "document": "14892228",
        "fullName": "Hernan Alonso Grajales Davila",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1289010",
        "document": "94473554",
        "fullName": "Jhon Janer Sandoval Plaza",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "5/24/2017 2:14:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1469502",
        "document": "91179003",
        "fullName": "Simon Martinez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259400",
        "document": "6544707",
        "fullName": "Orlando Florez Hoyos",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración Planta Mascotas",
        "fechaIngreso": "5/24/2017 2:11:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000043",
        "document": "70193199",
        "fullName": "Alejandro Antonio Zapata Alvarez",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1199000",
        "document": "71610684",
        "fullName": "Oscar Alfonso Zapata Rivillas",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1245412",
        "document": "1115070216",
        "fullName": "Angel Johanny Olivares F.",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1262465",
        "document": "6188968",
        "fullName": "Wilber Orlando Garcia Rivera",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Dosificación Y Mezcla Mascotas",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1342200",
        "document": "9690565",
        "fullName": "Juan Manuel Aragon Garcia",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1458599",
        "document": "91175488",
        "fullName": "Luis Alberto Espinosa Villamizar",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1383138",
        "document": "80382915",
        "fullName": "Hernando  Patiño Paez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1190427",
        "document": "1044100077",
        "fullName": "Julian Esteban Saldarriaga Gaviria",
        "company": "Solla",
        "regional": "Barb",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291700",
        "document": "14890663",
        "fullName": "Jose Luis Velez Arias",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1275090",
        "document": "14899693",
        "fullName": "Haumer Herney Morales Leal",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1196700",
        "document": "70286350",
        "fullName": "Jose Alvaro Vergara Echeverry",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1274018",
        "document": "14899263",
        "fullName": "Leonardo Fabio Marmolejo Vacca",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/24/2017 2:14:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1277010",
        "document": "14899810",
        "fullName": "Alexander Cortes Reyes",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1276000",
        "document": "94481402",
        "fullName": "Bryan Steven Mora Betancourt",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000118",
        "document": "71172778",
        "fullName": "Hugo Hernan Lopez Avendaño",
        "company": "Solla",
        "regional": "Gome",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1345800",
        "document": "11438567",
        "fullName": "Hector Efren Carrillo Pardo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1290551",
        "document": "14888617",
        "fullName": "Francisco Javier Soto Soto",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1479961",
        "document": "91153064",
        "fullName": "Gabriel David Niño Mantilla",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000233",
        "document": "8464507",
        "fullName": "Andrey Rodolfo Perez Castañeda",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000101",
        "document": "70138994",
        "fullName": "Javier Alonso Foronda Serna",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283516",
        "document": "6194116",
        "fullName": "Luis Alfredo Quintana Cuadros",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder 3",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1151050",
        "document": "70139130",
        "fullName": "Edison De Jesus Bustamante Velilla",
        "company": "Solla",
        "regional": "Donm",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2201800",
        "document": "38875930",
        "fullName": "Maria Victoria Arrechea Melo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Aseo Y Cafeteria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2107180",
        "document": "42980999",
        "fullName": "Ellen Del Socorrro Buritica Londoño",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Aseo Y Cafeteria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1413001",
        "document": "63341907",
        "fullName": "Doris Stella Galvis Sierra",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Aseo Y Cafeteria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000480",
        "document": "11436358",
        "fullName": "Nelson Fernando Palacios Palacios",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1491500",
        "document": "91475527",
        "fullName": "Eduardo Otalvaro Sanchez Martinez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1231100",
        "document": "14895895",
        "fullName": "Carlos Humberto Rivas Ramirez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1245320",
        "document": "14899842",
        "fullName": "Jose Omar Carvajal Montilla",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Mascotas",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1268410",
        "document": "14892831",
        "fullName": "Leonardo Gonzalez Gil",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1225430",
        "document": "94472248",
        "fullName": "Carlos Alberto Osorio Arias",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283687",
        "document": "1115065790",
        "fullName": "Jonathan  Marin Pareja",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Dosificación Y Mezcla Mascotas",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1155600",
        "document": "3573962",
        "fullName": "Javier Orlando Chavarria Rojas",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000021",
        "document": "98468945",
        "fullName": "William Fernando Garcia Madrigal",
        "company": "Solla",
        "regional": "Entr",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1366886",
        "document": "92449635",
        "fullName": "Policarpo Julio Blanco",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259505",
        "document": "94475958",
        "fullName": "Oscar Julian Escandon Tochoy",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Mascotas",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1463250",
        "document": "91042065",
        "fullName": "Omar Gelves Guerrero",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1251901",
        "document": "94471213",
        "fullName": "Carlos Alberto Calderon Hernandez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143555",
        "document": "71753331",
        "fullName": "Jose Gabriel Aguirre Rengifo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1275432",
        "document": "1115065598",
        "fullName": "Fredy Mauricio Ortiz Alvarado",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:14:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1342090",
        "document": "80654217",
        "fullName": "Juan Carlos Amaya Lara",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:09:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1179000",
        "document": "8046070",
        "fullName": "Pedro Angel Osorio Saavedra",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1184150",
        "document": "98472771",
        "fullName": "Francisco Javier Pulgarin Gomez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1247030",
        "document": "94478582",
        "fullName": "Julian Alberto Barahona Medina",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1242205",
        "document": "14893287",
        "fullName": "James Acevedo Florez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:10:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1199050",
        "document": "98464174",
        "fullName": "Egidio De Jesus Zea Ospina",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1354365",
        "document": "11435334",
        "fullName": "Victor Emilio Castillo Bernal",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1242611",
        "document": "94480203",
        "fullName": "Robinson Arias Tigreros",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "5/24/2017 2:13:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1467050",
        "document": "91297594",
        "fullName": "Miguel Angel Laguado Garzon",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1290552",
        "document": "94475269",
        "fullName": "Javier Augusto Soto Mora",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Mascotas",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1172492",
        "document": "71536336",
        "fullName": "Luis Fernando Londoño R.",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1290201",
        "document": "1115063675",
        "fullName": "Ronald Alexis Serna Ramos",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1249506",
        "document": "14898045",
        "fullName": "Carlos Eduardo Bermudez Gomez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:13:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143100",
        "document": "8466353",
        "fullName": "Sergio Andres Adarve Ramirez",
        "company": "Solla",
        "regional": "Barb",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1172480",
        "document": "70194898",
        "fullName": "Elkin Fernando Londoño Londoño",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1183196",
        "document": "71606550",
        "fullName": "Heriberto Perez Gonzalez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1459000",
        "document": "91184181",
        "fullName": "Mauricio  Florez Sandoval",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291086",
        "document": "14897556",
        "fullName": "Carlos Alberto Taborda Herrera",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder 3",
        "fechaIngreso": "5/24/2017 2:14:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1155919",
        "document": "15616894",
        "fullName": "Carlos Correa Lopez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283686",
        "document": "1115074323",
        "fullName": "Luis Alejandro Marin Rodriguez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1492802",
        "document": "91242855",
        "fullName": "Jorge  Vargas",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1231220",
        "document": "94471012",
        "fullName": "Jhon Jairo Reina Rojas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291083",
        "document": "94473232",
        "fullName": "Albeiro Torres Villegas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143553",
        "document": "98500964",
        "fullName": "Jaime Humberto Alvarez Hernandez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1469521",
        "document": "91176193",
        "fullName": "Jairo Monoga",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000304",
        "document": "79299426",
        "fullName": "Hector Julio Cifuentes Barreto",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Mensajero Conductor",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1416510",
        "document": "91215196",
        "fullName": "Nicolas Gomez Ruiz",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Mensajero Conductor",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1282210",
        "document": "94476819",
        "fullName": "Wilson Fernando Valero Ramirez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283003",
        "document": "1114453557",
        "fullName": "Larry David Plaza Murillo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Mascotas",
        "fechaIngreso": "5/24/2017 2:14:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1175814",
        "document": "1045048720",
        "fullName": "Andres Felipe Moncada Montoya",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:11:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1484090",
        "document": "13742740",
        "fullName": "Oscar Javier Roa Pachon",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215011",
        "document": "16865870",
        "fullName": "Jose Fabian Nieto Valencia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "5/24/2017 2:13:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1480200",
        "document": "1095909876",
        "fullName": "Carlos Andres Ovalle Carrillo",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1492820",
        "document": "1102548992",
        "fullName": "Ronald Jeyson Vargas Delgado",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1480130",
        "document": "1098686619",
        "fullName": "Edwin Olarte Hernandez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1153800",
        "document": "71992814",
        "fullName": "Duvan Antonio Castaño Martinez",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1233026",
        "document": "1114455290",
        "fullName": "Miguel Angel Rosero Ballesteros",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1484110",
        "document": "1098750541",
        "fullName": "Diego Armando Rojas Valencia",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1387790",
        "document": "11366953",
        "fullName": "Jose Alexander Tibaquira Malagon",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215013",
        "document": "94477904",
        "fullName": "Alexander Ospina Llanos",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración Planta Mascotas",
        "fechaIngreso": "5/24/2017 2:13:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1201206",
        "document": "94474442",
        "fullName": "Jhon Edwin Ramos Pineda",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215324",
        "document": "6322596",
        "fullName": "Jhon Fredy Dominguez Mora",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143551",
        "document": "71602144",
        "fullName": "Elkin De Jesus Alvarez Restrepo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1342081",
        "document": "11255902",
        "fullName": "Hector Daniel Amaya Alarcon",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1249983",
        "document": "1115075545",
        "fullName": "Jonathan Buitrago Ochoa",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1227801",
        "document": "6322094",
        "fullName": "Oscar Hernan Quintero Marin",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1271086",
        "document": "94471394",
        "fullName": "Carlos Andres Lenis Lozano",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1293011",
        "document": "1115064865",
        "fullName": "Andres Felipe Solis Soto",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1249592",
        "document": "1114452476",
        "fullName": "Andres Hernando Bolivar Bolaños",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1289012",
        "document": "1115067081",
        "fullName": "Nicolay Alexander Santos Villareal",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283512",
        "document": "94479007",
        "fullName": "Damian Alberto Quintero Cobo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1233960",
        "document": "6445946",
        "fullName": "Jose Wilmer Sanchez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Mascotas",
        "fechaIngreso": "5/24/2017 2:13:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1262489",
        "document": "1115074750",
        "fullName": "Luis Gerardo Garcia Pizarro",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1255400",
        "document": "94475914",
        "fullName": "Carlos Enrique Cobo Cobo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1386780",
        "document": "79189746",
        "fullName": "Edgar Humberto Sanchez Amaya",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1247006",
        "document": "6538107",
        "fullName": "Carlos Andres Barco Delgado",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1242606",
        "document": "1115067305",
        "fullName": "Luis Alberto Arango Garcia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1170000",
        "document": "1038212276",
        "fullName": "Elkin Johany Marin Morales",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1245413",
        "document": "1115076516",
        "fullName": "Hector Fabian Ordoñez Collazos",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1417508",
        "document": "88270341",
        "fullName": "Jose Luis Montaño Pinzon",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143530",
        "document": "70327713",
        "fullName": "Yovany Alberto Alvarez Mesa",
        "company": "Solla",
        "regional": "Lace",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1131400",
        "document": "98601086",
        "fullName": "Jorge Arley Rendon Correa",
        "company": "Solla",
        "regional": "Donm",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1228003",
        "document": "1115076499",
        "fullName": "Cristhian Eduardo Paz Valdes",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1165460",
        "document": "8150330",
        "fullName": "Jose de Jesus Gutierrez Yarce",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1251910",
        "document": "94480477",
        "fullName": "Bryan Dario Calderon Reyes",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Almacenamiento De Mp Mascotas",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1201502",
        "document": "94472355",
        "fullName": "Jorge Hernan Alferez Hernandez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "5/24/2017 2:15:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1483600",
        "document": "1096950378",
        "fullName": "Fridman Alexander Quintero Arias",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1361080",
        "document": "1049632025",
        "fullName": "Ezequiel Andres Garcia Bernal",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1274053",
        "document": "6446022",
        "fullName": "Jorge Humberto Mendoza Bedoya",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "5/24/2017 2:14:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1274021",
        "document": "94477393",
        "fullName": "Diego Fernando Martinez Muñoz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:14:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1274101",
        "document": "1148441854",
        "fullName": "Julian Andres Mazorra Diaz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Dosificación Y Mezcla Mascotas",
        "fechaIngreso": "5/24/2017 2:14:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1366831",
        "document": "1073239696",
        "fullName": "Andres Marcelo Hernandez Rubiano",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:14:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1247032",
        "document": "80133170",
        "fullName": "Francisco Javier Barona Zuleta",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1225009",
        "document": "94478630",
        "fullName": "Albert Naranjo Pizarro",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Almacenamiento De Mp Mascotas",
        "fechaIngreso": "5/24/2017 2:13:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283517",
        "document": "94483147",
        "fullName": "Omar Enrique Quiceno Villegas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259522",
        "document": "1114733196",
        "fullName": "Jorge Luis Belalcazar Carvajal",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1469951",
        "document": "1097303988",
        "fullName": "Olver Andres Mendoza Jaimes",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116519",
        "document": "8085493",
        "fullName": "Carlos Mario Goez Durango",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Mensajero",
        "uen": "Corporativo Secretaría Gral Y Jurídica",
        "process": "Secretaría General Y Jurídica",
        "area": "Cad",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1251579",
        "document": "94481269",
        "fullName": "Edwar Leandro Cano Castañeda",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215328",
        "document": "1115070710",
        "fullName": "Sergio Hernan Dominguez Marin",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:15:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1268413",
        "document": "97447285",
        "fullName": "Yhon Gelver Gonzalez Garzon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1242608",
        "document": "94476825",
        "fullName": "Edwar Andres Arias Buitrago",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1377890",
        "document": "1079262189",
        "fullName": "Wilder Esneider Olaya Castillo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1188679",
        "document": "1035832460",
        "fullName": "Juan David Rojas Sanchez",
        "company": "Solla",
        "regional": "Donm",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1268431",
        "document": "1115075390",
        "fullName": "Adolfo Leon Guzman Peña",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1123575",
        "document": "70978755",
        "fullName": "Carlos Mario Mesa Restrepo",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1308199",
        "document": "1061536774",
        "fullName": "Didier Fernando Chate Lulico",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:14:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103428",
        "document": "15327075",
        "fullName": "Bernardo Alexander Arango Medina",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1491604",
        "document": "1095940887",
        "fullName": "Edier Alberto Sanmiguel Duran",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291092",
        "document": "1114453988",
        "fullName": "Carlos Fernando Tabares Zapata",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder 3",
        "fechaIngreso": "5/24/2017 2:14:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1368451",
        "document": "1070960427",
        "fullName": "Diego Fernando Martinez Ramirez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1201506",
        "document": "1115079289",
        "fullName": "Andres Felipe Alvarez Balanta",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1406751",
        "document": "1095952460",
        "fullName": "Snaider Roggeiro Amador Gomez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116850",
        "document": "1035867289",
        "fullName": "Cristian Ferney Muñoz Tamayo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1485002",
        "document": "1091059801",
        "fullName": "Deimar Javier Rivera Valencia",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291513",
        "document": "6320961",
        "fullName": "Ferman Velez Plaza",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:14:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1205351",
        "document": "1112882668",
        "fullName": "Rodrigo Cuartas Obando",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1221534",
        "document": "14651838",
        "fullName": "Fabio Nelson Velasquez Guacheta",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Dosificación Y Mezcla Mascotas",
        "fechaIngreso": "5/24/2017 2:13:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1245322",
        "document": "1115076423",
        "fullName": "John Andersson Carvajal Palomino",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1268432",
        "document": "1045047959",
        "fullName": "David Henao Restrepo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1282212",
        "document": "94482401",
        "fullName": "Jorge Alejandro Varela",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1126554",
        "document": "1007102477",
        "fullName": "Willigton Pareja",
        "company": "Solla",
        "regional": "Lace",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1265021",
        "document": "1115079519",
        "fullName": "Juan Camilo Montejo Ruiz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:15:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1417510",
        "document": "78751119",
        "fullName": "Daimer Enrique Muentes Sandon",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1165402",
        "document": "1000453365",
        "fullName": "Anderson Gomez Caro",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1343903",
        "document": "1101456851",
        "fullName": "Yader Berrio Reyes",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/28/2017 6:00:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1166942",
        "document": "98540924",
        "fullName": "Camilo Andres Higuita Cano",
        "company": "Solla",
        "regional": "Lace",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "8/1/2017 1:56:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8072148",
        "document": "45473206",
        "fullName": "Magalys Gutierrez Mendez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Aseo Y Cafeteria",
        "uen": "Talento Humano",
        "process": "Talento Humano",
        "area": "Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:15:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0095",
        "document": "1116252616",
        "fullName": "Carlos Eduardo Jaramillo Salazar",
        "company": "Temporales",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "-",
        "process": "Asistente Transgraneles",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0227",
        "document": "1085248047",
        "fullName": "Jorge Andres Delgado Timaran",
        "company": "Temporales",
        "regional": "Pasto",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0165",
        "document": "",
        "fullName": "Dario Alexander Velez Corredor",
        "company": "Temporales",
        "regional": "Mosquera",
        "jobTitle": "-",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0192",
        "document": "1115082562",
        "fullName": "William Fernando Osorio Amaya",
        "company": "Temporales",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0163",
        "document": "98594218",
        "fullName": "Elder Libardo Nieto Cuervo",
        "company": "Temporales",
        "regional": "Giron",
        "jobTitle": "Tecnico Junior",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:11:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0282",
        "document": "12749832",
        "fullName": "Jose Edison Rivera Rosero",
        "company": "Temporales",
        "regional": "Past",
        "jobTitle": "Servicios Temporales",
        "uen": "-",
        "process": "",
        "area": "Auxiliar De Produccion",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0172",
        "document": "16934429",
        "fullName": "Jorge Jimenez Chavez",
        "company": "Temporales",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0283",
        "document": "1085254958",
        "fullName": "Nelson Edilberto Cordoba Tela",
        "company": "Temporales",
        "regional": "Past",
        "jobTitle": "Servicios Temporales",
        "uen": "-",
        "process": "",
        "area": "Auxiliar De Produccion",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00131",
        "document": "1087959595",
        "fullName": "Jaider Lener Chicaiza Cumbal",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0280",
        "document": "12747637",
        "fullName": "William Alonso Sanchez Zambrano",
        "company": "Temporales",
        "regional": "Past",
        "jobTitle": "Servicios Temporales",
        "uen": "-",
        "process": "",
        "area": "Auxiliar De Produccion",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0314",
        "document": "1087418123",
        "fullName": "Heyder Silvio Narvaez Potosi",
        "company": "Temporales",
        "regional": "Past",
        "jobTitle": "Proservis",
        "uen": "-",
        "process": "",
        "area": "Pasto",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0013",
        "document": "87061067",
        "fullName": "Armando Ignacio Chaña Quenguan",
        "company": "Temporales",
        "regional": "pasto",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "-",
        "process": "",
        "area": "Bodeguero",
        "fechaIngreso": "5/24/2017 2:15:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0007",
        "document": "1116264864",
        "fullName": "Dayhana Andrea Villegas Mejia",
        "company": "Temporales",
        "regional": "Buga",
        "jobTitle": "Dar Ayuda",
        "uen": "-",
        "process": "",
        "area": "Buga",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0016",
        "document": "79973870",
        "fullName": "John Jairo Rodriguez",
        "company": "Temporales",
        "regional": "Past",
        "jobTitle": "Auxiliar De Bodega",
        "uen": "-",
        "process": "",
        "area": "Operario De Montacarga",
        "fechaIngreso": "5/24/2017 2:15:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1105559",
        "document": "43275774",
        "fullName": "Deicy Alejandra Marin Bedoya",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Datos Maestros",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006829",
        "document": "31940304",
        "fullName": "Martha Ludivia Davila Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Secretaria De Gerencia",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "9/30/2017 1:15:55 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006658",
        "document": "1144149923",
        "fullName": "Yenny Lorena Marin Ceballos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Inventario Gral",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "9/30/2017 1:16:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006560",
        "document": "1107078183",
        "fullName": "Carlos Mario Muriel Gutierrez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Transporte Y Sacrificio",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/30/2017 1:16:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00386",
        "document": "29123048",
        "fullName": "Susana Picon Calderon",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Canastillas",
        "uen": "Gerencia Ventas",
        "process": "Administrativo",
        "area": "Administrativo",
        "fechaIngreso": "9/30/2017 1:16:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006553",
        "document": "29351938",
        "fullName": "Jenifer Mosquera Morales",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Asistente Puntos De Venta",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "9/30/2017 1:16:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006485",
        "document": "19457769",
        "fullName": "Santiago Romero Renjifo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Director Comercial Autoservicios",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "9/30/2017 1:16:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006527",
        "document": "75143426",
        "fullName": "Jorge Miranda Carmona",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Director De Fabrica Aba",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/30/2017 1:17:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006358",
        "document": "31992963",
        "fullName": "Marisol Sarria",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "9/30/2017 1:16:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006409",
        "document": "14899996",
        "fullName": "Alexander Velasquez Mendoza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider De Mantenimiento Aba",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/30/2017 1:17:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006614",
        "document": "31628123",
        "fullName": "Maria Del Pilar Isaza Castaño",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "9/30/2017 1:15:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1107302",
        "document": "52389912",
        "fullName": "BC Martha Juliana Coca Garzon (Shared)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Gerente De Tesoreria",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Tesoreria",
        "fechaIngreso": "10/3/2017 6:01:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001013",
        "document": "91345611",
        "fullName": "Henry Solares Badillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Bodega",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/7/2017 9:00:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000322",
        "document": "37943825",
        "fullName": "Mary Luz Orostegui Osses",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/7/2017 9:00:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000045",
        "document": "5677743",
        "fullName": "Jhon Jairo Salazar",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Vacunador",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/7/2017 9:01:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001225",
        "document": "74370503",
        "fullName": "Jaime Alberto Zabala Torres",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "10/7/2017 9:01:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000273",
        "document": "91152558",
        "fullName": "Argemiro Alfonso Rincon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/7/2017 9:05:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000866",
        "document": "1101520130",
        "fullName": "Carlos Enrique Anaya Vargas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/7/2017 9:06:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000847",
        "document": "1042213518",
        "fullName": "Erika Marcela Ortega Alarcon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/7/2017 9:06:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000670",
        "document": "18929578",
        "fullName": "Walfran Jimenez Pacheco",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/7/2017 9:07:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001488",
        "document": "1062909508",
        "fullName": "Jaiber Yamil Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/7/2017 9:08:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000026",
        "document": "5595891",
        "fullName": "Javier Ortiz Lizarazo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/7/2017 9:08:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000315",
        "document": "91227392",
        "fullName": "Julian Rios Granados",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Adminis.de La Bd E Ing. De Telecomu",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Departamento De Informatica",
        "fechaIngreso": "10/7/2017 9:41:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001330",
        "document": "37710904",
        "fullName": "Judith Emerita Jimenez Martinez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asistente Produccion Pecuaria",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/7/2017 9:41:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000008",
        "document": "2740020",
        "fullName": "Harold Wilson Jimenez Avendano",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Ingeniero Soporte Y Desarrollo",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Departamento De Informatica",
        "fechaIngreso": "10/7/2017 9:41:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001168",
        "document": "63523723",
        "fullName": "BC Claudia Patricia Alvarez Torres (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coor.contabilidad Cuentas Por Pagar",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 9:42:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000009",
        "document": "3151618",
        "fullName": "Nelson Enrique Cely Guerrero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Director Operaciones",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "10/7/2017 9:42:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000432",
        "document": "1098609957",
        "fullName": "Wayron Franco Villamizar",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Jefe Compras",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Compras",
        "fechaIngreso": "10/7/2017 9:42:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000506",
        "document": "1095790333",
        "fullName": "Claudia Yoana Ochoa Jaimes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Costos",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Planeacion Financiera",
        "fechaIngreso": "10/7/2017 9:42:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000431",
        "document": "1098609663",
        "fullName": "William Rodriguez Jimenez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Jefe Inventarios",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "10/7/2017 10:24:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001538",
        "document": "1090386783",
        "fullName": "Yajaira Diaz Vargas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "10/7/2017 10:29:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001026",
        "document": "91348162",
        "fullName": "Naun Bohorquez Rojas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/9/2017 7:52:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000016",
        "document": "3963826",
        "fullName": "Luis Fernando Cantillo Urbina",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/9/2017 7:52:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000805",
        "document": "1007909622",
        "fullName": "Jaider Duran Rodriguez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/9/2017 7:52:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001727",
        "document": "91471813",
        "fullName": "Mauricio Rodriguez Mendez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/9/2017 7:52:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000289",
        "document": "91175893",
        "fullName": "Julio Enrique Rueda Ramirez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/9/2017 7:52:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000300",
        "document": "91184871",
        "fullName": "Jose Francisco Zarate Gelvez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/9/2017 7:52:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001364",
        "document": "1095936353",
        "fullName": "Hernan Dario Perez Amado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/9/2017 7:53:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001006",
        "document": "91343421",
        "fullName": "Alirio Suarez Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:52:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000641",
        "document": "13740630",
        "fullName": "Fredy Acuna Fonseca",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:52:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000470",
        "document": "1098649277",
        "fullName": "Jhon Edison Hernandez Duran",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000293",
        "document": "91181215",
        "fullName": "Norberto Rueda Barragan",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000977",
        "document": "91275352",
        "fullName": "Cristian Rueda Plata",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001647",
        "document": "1099368294",
        "fullName": "Yonatan Andres Afanador Pereira",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000861",
        "document": "1100958007",
        "fullName": "Robinson Javier Arciniegas Triana",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001633",
        "document": "1099364190",
        "fullName": "Fredy Bautista Rojas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001638",
        "document": "1099366498",
        "fullName": "Ivan Dario Afanador Pereira",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001640",
        "document": "1099366800",
        "fullName": "Edwin Almeyda Caicedo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001637",
        "document": "1099365845",
        "fullName": "Javier Mayorga Lopez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001726",
        "document": "91471580",
        "fullName": "Manuel Garcia Prada",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000473",
        "document": "1092351986",
        "fullName": "Luis Arturo Melendez Gomez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001628",
        "document": "1099362016",
        "fullName": "Wilson Eduardo Lopez Acosta",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000601",
        "document": "13539213",
        "fullName": "Jose Angel Diaz Jerez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000591",
        "document": "13536581",
        "fullName": "Luis Alberto Diaz Jerez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000604",
        "document": "13539674",
        "fullName": "Oscar Dario Lopez Acosta",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001630",
        "document": "1099362693",
        "fullName": "Juan Antonio Jurado Gonzalez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001514",
        "document": "1067940565",
        "fullName": "Ferney Jose Perez Lobo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000958",
        "document": "91242080",
        "fullName": "Jose Nelson Rueda Rueda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000622",
        "document": "13637602",
        "fullName": "Libardo Gomez Ayala",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:53:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000064",
        "document": "5751270",
        "fullName": "Libardo Archila Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001180",
        "document": "63540829",
        "fullName": "Eddy Rosana Florez Triana",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:53:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001527",
        "document": "1073994718",
        "fullName": "Ernesto Santos Zarante Altamiranda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001234",
        "document": "77179171",
        "fullName": "Fredy Villegas Quintero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000023",
        "document": "5581522",
        "fullName": "Nairo Gomez Bautista",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:53:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000669",
        "document": "18920720",
        "fullName": "Jaime Alfonso Perez Ascanio",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:53:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1191024",
        "document": "712473",
        "fullName": "Kissys Oriana Silva De Alvarado",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Compras",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Negocios Internacionales",
        "area": "Negocios Internacionales",
        "fechaIngreso": "10/9/2017 7:20:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000594",
        "document": "13537841",
        "fullName": "Wiliam Humberto Calderon Arias",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "10/9/2017 7:53:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000995",
        "document": "91340507",
        "fullName": "Carlos Arturo Rico Remolina",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:53:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001767",
        "document": "91523625",
        "fullName": "Jorge Eleuterio Vega Parra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:53:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001000",
        "document": "91341273",
        "fullName": "Abel Palacios Garnica",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:53:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000988",
        "document": "91293774",
        "fullName": "Yon Sergio Chacon Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "10/9/2017 7:53:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001032",
        "document": "91349012",
        "fullName": "Orlando Duran Santafe",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "10/9/2017 7:53:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000806",
        "document": "1010012480",
        "fullName": "Jhon Jaider Vega Castro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "10/9/2017 7:53:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001012",
        "document": "91345571",
        "fullName": "Jose Delcarmen Leon Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "10/9/2017 7:53:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000286",
        "document": "91160318",
        "fullName": "Emmanuel Palomino Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "10/9/2017 7:53:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000317",
        "document": "91231550",
        "fullName": "Edgar Merardo Ardila Mendoza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000419",
        "document": "63441709",
        "fullName": "Yamile Castellanos Sandoval",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000183",
        "document": "1102381089",
        "fullName": "Jose Luis Diaz Mejia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001535",
        "document": "1085102153",
        "fullName": "Yesid Guardiola Ospino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000952",
        "document": "1102367205",
        "fullName": "Lisseth Natalia Mendez Albarracin",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001152",
        "document": "63475949",
        "fullName": "Alba Luz Melendez Negro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001188",
        "document": "63556025",
        "fullName": "Claudia Johanna Murillo Chaparro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001324",
        "document": "37620192",
        "fullName": "Aura Celina Perez Cespedes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000494",
        "document": "1094574567",
        "fullName": "Jose Yamid Perez Garnica",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000732",
        "document": "1098714775",
        "fullName": "Viviana Ramirez Ramirez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000333",
        "document": "43551161",
        "fullName": "Hilda Serrano Herrera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001163",
        "document": "63507984",
        "fullName": "Florelia Torra Conde",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000646",
        "document": "13746090",
        "fullName": "Angelo Giovanny Vargas Lopez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001231",
        "document": "77105499",
        "fullName": "Alfonso Villalba Ponton",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000401",
        "document": "63339751",
        "fullName": "Jacqueline Vesga Rincon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000745",
        "document": "91532235",
        "fullName": "Jhon Henry Castro Sidedor",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001470",
        "document": "1051660950",
        "fullName": "Luis Fernando Cueto Arteaga",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001488",
        "document": "1062909508",
        "fullName": "Jaiber Yamil Martinez Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001314",
        "document": "37617973",
        "fullName": "Johana Magali Anteliz Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:53:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001055",
        "document": "91354004",
        "fullName": "Gabriel Uribe Velandia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001007",
        "document": "91344095",
        "fullName": "Carlos Arturo Gomez Leon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:53:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000136",
        "document": "1102371423",
        "fullName": "Pedro Antonio Briceno Becerra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:53:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001144",
        "document": "63452602",
        "fullName": "Deisy Carrillo Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:53:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000386",
        "document": "60361578",
        "fullName": "Zuleima Lozano Silva",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:53:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000998",
        "document": "91340957",
        "fullName": "Rodolfo Tarazona Grimaldos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:53:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000951",
        "document": "1102367167",
        "fullName": "Oscar Javier Carreno Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Cargue",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/9/2017 7:53:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000377",
        "document": "52988555",
        "fullName": "Johana Vanegas Pabon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:53:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001232",
        "document": "77130873",
        "fullName": "Hector Raul Villamil Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000133",
        "document": "1102371030",
        "fullName": "Carlos Andres Torrijos Anzola",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001149",
        "document": "63475015",
        "fullName": "Yolanda Rodriguez Caceres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:53:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000645",
        "document": "13745954",
        "fullName": "Pedro Jose Carvajal Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001570",
        "document": "1098737507",
        "fullName": "Sergio Leonardo Duran Ruiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000519",
        "document": "1095808126",
        "fullName": "Jose Luis Vargas Solares",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001079",
        "document": "1103692165",
        "fullName": "Luis Alfonso Villabona Landinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001304",
        "document": "37616462",
        "fullName": "Maria Luisa Hernandez Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001038",
        "document": "91350434",
        "fullName": "Rodolfo Correa Duran",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001299",
        "document": "37558902",
        "fullName": "Grace Elexy Palomino Mantilla",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000922",
        "document": "1102362122",
        "fullName": "Oscar Leonardo Ayala Florido",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000851",
        "document": "1100891844",
        "fullName": "Ana Mabel Angarita Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000895",
        "document": "1102354054",
        "fullName": "John Hernan Vera Usa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000103",
        "document": "13513995",
        "fullName": "Henry Quitian Torres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000797",
        "document": "1005553101",
        "fullName": "Julio Cesar Gonzalez Ardila",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000134",
        "document": "1102371155",
        "fullName": "Jesus Alberto Duran Blanco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:54:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000238",
        "document": "88168755",
        "fullName": "Casimiro Cristancho Ibarra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001491",
        "document": "1063619358",
        "fullName": "Albeiro Garavito Gamboa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001105",
        "document": "1125758765",
        "fullName": "Moises Danilo Blanco Villa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:54:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000939",
        "document": "1102365496",
        "fullName": "Jenny Shirley Barrera Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000902",
        "document": "1102356372",
        "fullName": "Carol Ximena Anaya Barajas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001028",
        "document": "91348318",
        "fullName": "Luis Felipe Beltran Pinzon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:54:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000201",
        "document": "1102385775",
        "fullName": "Jesus Libardo Ortiz Algarin",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/9/2017 7:53:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001238",
        "document": "78712955",
        "fullName": "Cesar Augusto Polo Alarcon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001751",
        "document": "91499962",
        "fullName": "Armando Monroy Ladino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001706",
        "document": "91457138",
        "fullName": "Israel Ortiz Arenales",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000963",
        "document": "91256998",
        "fullName": "Pedro Alonso Granados Segura",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000423",
        "document": "63444674",
        "fullName": "Nelly Sanchez Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000268",
        "document": "91109923",
        "fullName": "Abel Rojas Munoz",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/9/2017 7:54:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000411",
        "document": "63432762",
        "fullName": "Sonia Ayala Quintero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001302",
        "document": "37616235",
        "fullName": "Eleonora Vargas Mejia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001564",
        "document": "1098729330",
        "fullName": "Maria Fernanda Gomez Lievano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001081",
        "document": "1104127499",
        "fullName": "Ellexy Manuel Leguia Ortiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001307",
        "document": "37617039",
        "fullName": "Yamile Martinez Oviedo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001318",
        "document": "37619216",
        "fullName": "Rosalba Lizarazo Garza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001183",
        "document": "63548280",
        "fullName": "Doris Viviana Marquez Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000911",
        "document": "1102358697",
        "fullName": "Fredy Alexander Nino Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000445",
        "document": "1098623339",
        "fullName": "Leidy Yamile Parra Buitrago",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001555",
        "document": "1090983327",
        "fullName": "Jesus Anibal Pacheco Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001291",
        "document": "37543194",
        "fullName": "Gloria Ines Nino Grimaldos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001309",
        "document": "37617437",
        "fullName": "Blanca Cecilia Garcia Salamanca",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000276",
        "document": "91155643",
        "fullName": "Lwbing Blanco Munoz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001331",
        "document": "37724934",
        "fullName": "Ana Mercedes Vega Florez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000408",
        "document": "63363375",
        "fullName": "Deiby Villamizar Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000094",
        "document": "12565423",
        "fullName": "Leonidas Uribe Espinosa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "10/9/2017 7:54:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000030",
        "document": "5611392",
        "fullName": "Fabian Enrique Carreno Avendano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001258",
        "document": "28359566",
        "fullName": "Lucely Amparo Carvajal Sierra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000294",
        "document": "91181524",
        "fullName": "Mauricio Morantes Caballero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001005",
        "document": "91342993",
        "fullName": "Juan Delacruz Jaimes Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000459",
        "document": "1098639673",
        "fullName": "Sandra Milena Parada Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001155",
        "document": "63488755",
        "fullName": "Nanci Smith Navarro Pineda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000778",
        "document": "1005147212",
        "fullName": "Jhon Alexander Suarez Santander",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000381",
        "document": "57449411",
        "fullName": "Ana Victoria Pallares Pena",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001341",
        "document": "37841890",
        "fullName": "Yeny Rocio Carrillo Arias",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000421",
        "document": "63442760",
        "fullName": "Marisol Castellanos Sandoval",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001244",
        "document": "28155879",
        "fullName": "Martha Yasmira Vasquez Plata",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000420",
        "document": "63441829",
        "fullName": "Maritza Guido Rivera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001054",
        "document": "91353913",
        "fullName": "Jhon Jairo Badillo Carvajal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000108",
        "document": "1102367654",
        "fullName": "Leonor Delgado Tami",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001305",
        "document": "37616823",
        "fullName": "Sandra Milena Chanaga Patino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001018",
        "document": "91346572",
        "fullName": "Ronal Jaimes Vargas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001060",
        "document": "91354777",
        "fullName": "Nelson Moreno Cruz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000461",
        "document": "1098641147",
        "fullName": "Richard Mauricio Manosalva Solano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001290",
        "document": "37542164",
        "fullName": "Lina Maria Jimenez Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000284",
        "document": "91160050",
        "fullName": "Joselin Gutierrez Quinonez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000233",
        "document": "85470707",
        "fullName": "Luis Emilio Munoz Vanegas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000642",
        "document": "13743756",
        "fullName": "Jose Leonardo Heredia Morales",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000310",
        "document": "91218948",
        "fullName": "Cristobal Pabon Lizarazo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000616",
        "document": "13569371",
        "fullName": "Renso Enrique Sena Jerez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001346",
        "document": "37878218",
        "fullName": "Carmelina Salcedo Alvarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001154",
        "document": "63486949",
        "fullName": "Nancy Patricia Rios Sabata",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001329",
        "document": "37688377",
        "fullName": "Lilia Mercedes Zabaleta Pacheco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001030",
        "document": "91348603",
        "fullName": "Gustavo Alvarez Duarte",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001151",
        "document": "63475148",
        "fullName": "Azucena Sepulveda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Servicios Generales",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "10/9/2017 7:54:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001292",
        "document": "37543674",
        "fullName": "Belsy Leon Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000275",
        "document": "91155389",
        "fullName": "Luis Ernesto Bueno Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001310",
        "document": "37617523",
        "fullName": "Sandra Milena Higuera Arenales",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000656",
        "document": "13863069",
        "fullName": "Juan Carlos Jaimes Ojeda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001308",
        "document": "37617280",
        "fullName": "Nancy Santos Caceres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000677",
        "document": "27034868",
        "fullName": "Luz Esthela Palomino Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001668",
        "document": "91355237",
        "fullName": "Fredy Alberto Anaya Castellanos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:54:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000888",
        "document": "1102352622",
        "fullName": "Lina Maria Roa Palomino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001150",
        "document": "63475069",
        "fullName": "Fidelina Morgado Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000020",
        "document": "5030849",
        "fullName": "Calixto Romero Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:54:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001239",
        "document": "79186466",
        "fullName": "Gabriel Alvarado Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:54:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001746",
        "document": "91491672",
        "fullName": "Alberto Moreno Arciniegas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:54:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001275",
        "document": "35514044",
        "fullName": "Gladys Arenas Ramirez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:54:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000131",
        "document": "1102370866",
        "fullName": "Adriana Mercedes Ojeda Jerez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:54:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001248",
        "document": "28218458",
        "fullName": "Claudia Milena Gomez Chaparro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:54:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000403",
        "document": "63347026",
        "fullName": "Maria Isabel Matajira Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:54:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001335",
        "document": "37747016",
        "fullName": "Yadira Ines Fontecha Parada",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/9/2017 7:54:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000612",
        "document": "13544076",
        "fullName": "Jorge Andres Albarracin Mendoza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/7/2017 9:00:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000119",
        "document": "1095926128",
        "fullName": "Mayra Alejandra Vera Baron",
        "company": "Transgraneles",
        "regional": "Giro",
        "jobTitle": "Coordinador De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000050",
        "document": "5689454",
        "fullName": "BC Wilson Quintero Leon (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Reproductoras",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 9:00:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001147",
        "document": "63465005",
        "fullName": "Celia Florez Gonzalez",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Administrador Tecnico Granjas Reproductoras",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/7/2017 9:06:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1103432",
        "document": "1094909680",
        "fullName": "Simon David Arevalo Ospina",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asistente De Operaciones Agropecuarias",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "10/18/2017 6:01:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1249995",
        "document": "94475947",
        "fullName": "Juan Carlos Caceres Robledo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215012",
        "document": "1130602216",
        "fullName": "Jimmy Alejandro Nupan Trochez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0055",
        "document": "43185965",
        "fullName": "Leidy Bibiana Gaviria Hernandez",
        "company": "Temporales",
        "regional": "Itag",
        "jobTitle": "Auxiliar Datos Maestros",
        "uen": "-",
        "process": "",
        "area": "Auxiliar Datos Maestros",
        "fechaIngreso": "10/24/2017 7:11:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1231223",
        "document": "94153421",
        "fullName": "German Andres Ricardo Orozco",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "8/12/2017 5:39:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001577",
        "document": "1098744289",
        "fullName": "Catalina Porras Garcia",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Director Zona",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "10/14/2017 3:39:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000061",
        "document": "5725757",
        "fullName": "Cesar Gomez Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/7/2017 9:41:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1245415",
        "document": "1115073454",
        "fullName": "Roland Rodrigo Ordoñez Gallego",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000140",
        "document": "1045710884",
        "fullName": "Alberto Mario Ariza Blanco",
        "company": "Transgraneles",
        "regional": "Sanm",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "11/2/2017 5:46:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007682",
        "document": "1085256135",
        "fullName": "Jose Oscar Pupiales",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Compras Y Logística",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:12:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007681",
        "document": "13070053",
        "fullName": "Jorge Hernando Benavides Caceres",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Operaciones",
        "area": "Operaciones",
        "fechaIngreso": "5/24/2017 2:12:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1406453",
        "document": "91515958",
        "fullName": "Oscar Hernando Castro Alvarez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/7/2017 4:38:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1467054",
        "document": "1095942241",
        "fullName": "Anderson Lopez Carvajal",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/7/2017 5:07:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1263104",
        "document": "1116260740",
        "fullName": "Conrado Antonio Puerta Martinez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "10/24/2017 3:08:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002454",
        "document": "1037635537",
        "fullName": "Paola Katherine Londoño Silva",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Financiero",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "11/7/2017 5:38:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000277",
        "document": "91157209",
        "fullName": "Nelson Javier Guarin Carvajal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0080",
        "document": "94424688",
        "fullName": "Jose Antonio Cortes Galvis",
        "company": "Temporales",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "-",
        "process": "",
        "area": "Produccion",
        "fechaIngreso": "11/8/2017 5:34:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003919",
        "document": "1017202144",
        "fullName": "Yaneth Johana Gonzalez Cardona",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Costos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "11/8/2017 9:08:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1180101",
        "document": "1039024328",
        "fullName": "Brayan Stiven Palacio Gallego",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071130",
        "document": "73102961",
        "fullName": "Wilson Enrique Cabarcas Figueroa",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Molinero",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071579",
        "document": "73128962",
        "fullName": "Antonio Alzamora Julio",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070266",
        "document": "72041551",
        "fullName": "Alexander Jose Camacho Zambrano",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Jefe Administrativo",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:09:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0306",
        "document": "87069004",
        "fullName": "Carlos Fernando Timana Noguera",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Proservis",
        "uen": "-",
        "process": "",
        "area": "Auxiliar Producción",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071367",
        "document": "73102232",
        "fullName": "Marceliano Elias Velasco Zuniga",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Empacador / Cosedor",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070798",
        "document": "7381767",
        "fullName": "Jorge Luis Galeano R",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Bascula",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepción Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:13:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071578",
        "document": "80200412",
        "fullName": "Jaime Agamez Ibarra",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Bascula",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepción Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000141",
        "document": "1152217759",
        "fullName": "Christian David Londoño Escobar",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Datos Maestros",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "11/8/2017 5:44:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070212",
        "document": "73128430",
        "fullName": "Arismel Gonzalez Blanco",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "4000138",
        "document": "6646766",
        "fullName": "Leonardo Barrios Gallego",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador Regional De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:12:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000432",
        "document": "45549315",
        "fullName": "Karen Patricia Morelos Nuñez",
        "company": "Transgraneles",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071405",
        "document": "18000370",
        "fullName": "Roberto Cano Miranda",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:09:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071474",
        "document": "78321567",
        "fullName": "Hernan Elias Rivero Medrano",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Jefe De Produccion Ii",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:09:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1166950",
        "document": "98704264",
        "fullName": "Jhon Arley Herrera Betancur",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000399",
        "document": "63334700",
        "fullName": "Vilma Yored Figueroa Hernandez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Servicios Generales",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Bienestar Laboral",
        "fechaIngreso": "10/14/2017 3:37:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000102",
        "document": "13510819",
        "fullName": "Henry Rodrigo Martinez Duarte",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Servicios Generales",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "10/14/2017 3:37:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001169",
        "document": "63524224",
        "fullName": "Maria Jimena Ferrer Suarez",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:38:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000986",
        "document": "91290604",
        "fullName": "Jorge Eliecer Calderon Gomez",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001452",
        "document": "1098602823",
        "fullName": "Diana Carolina Silva Munoz",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:39:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001139",
        "document": "63447230",
        "fullName": "Otilia Torrado",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:37:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001034",
        "document": "91349228",
        "fullName": "Jose Fernando Diaz Monsalve",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:39:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000511",
        "document": "1095797214",
        "fullName": "Viviana Andrea Mantilla Maldonado",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:38:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000631",
        "document": "13716186",
        "fullName": "Humberto Lozano Torres",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:39:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000456",
        "document": "1098634291",
        "fullName": "Jairo Mauricio Arias Blanco",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:39:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001493",
        "document": "1064837645",
        "fullName": "Jessica Viviana Sanchez Perez",
        "company": "Distraves",
        "regional": "Aguc",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:39:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001287",
        "document": "37520508",
        "fullName": "Edilma Quintero Parra",
        "company": "Distraves",
        "regional": "Bari",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:37:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001202",
        "document": "71193627",
        "fullName": "Bernandino Miranda Jimenez",
        "company": "Distraves",
        "regional": "Puer",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000843",
        "document": "1039682989",
        "fullName": "rec-mapatino6575",
        "company": "Distraves",
        "regional": "Puer",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000335",
        "document": "43652675",
        "fullName": "Maria Eugenia Aristizabal Cuadros",
        "company": "Distraves",
        "regional": "Puer",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:37:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001397",
        "document": "1096215221",
        "fullName": "Rogelio Serna Cardona",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000615",
        "document": "13569038",
        "fullName": "Edgar Nunez Reyes",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:39:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000685",
        "document": "1098652358",
        "fullName": "Fabio Andres Perez Uribe",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:37:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000255",
        "document": "91044245",
        "fullName": "Wilson Enrique Lozada Rueda",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001300",
        "document": "37559259",
        "fullName": "Beatriz Vega Galvis",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000012",
        "document": "3188339",
        "fullName": "Jose Wilson Sosa",
        "company": "Distraves",
        "regional": "Zipa",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000326",
        "document": "39536902",
        "fullName": "Maria Deise Ramirez Tejedor",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "10/14/2017 3:37:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001529",
        "document": "1078776832",
        "fullName": "Julian Mauricio Corona Aya",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000088",
        "document": "11280720",
        "fullName": "Jose Libardo Garces Sanchez",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000013",
        "document": "3199954",
        "fullName": "Oswaldo Bulla Fonque",
        "company": "Distraves",
        "regional": "Chia",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000071",
        "document": "7222498",
        "fullName": "Pompeyo Albarracin Lizarazo",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001484",
        "document": "1057588861",
        "fullName": "David Leonardo Mora Beltran",
        "company": "Distraves",
        "regional": "Soga",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:39:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000352",
        "document": "46666188",
        "fullName": "Faridy Zein Gomez",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:37:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000350",
        "document": "46663543",
        "fullName": "Ludin Amanda Pedraza Ochoa",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "10/14/2017 3:37:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001223",
        "document": "74130118",
        "fullName": "Nelson Monroy Combariza",
        "company": "Distraves",
        "regional": "Tunj",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "10/14/2017 3:37:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000348",
        "document": "46457129",
        "fullName": "Clara Ines Moreno",
        "company": "Distraves",
        "regional": "Tunj",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/14/2017 3:39:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001273",
        "document": "32865852",
        "fullName": "Mercedes Isabel Omana Peluffo",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "10/14/2017 3:37:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000844",
        "document": "1039685313",
        "fullName": "Luis Alfredo Daza Velasquez",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000338",
        "document": "45689621",
        "fullName": "Nadia Isabel Soto Barrios",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "10/14/2017 3:38:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001228",
        "document": "77031649",
        "fullName": "Jose Delcarmen Razgo Garabis",
        "company": "Distraves",
        "regional": "Vall",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:39:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000028",
        "document": "5606644",
        "fullName": "Carlos Humberto Ochoa",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/14/2017 3:37:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001166",
        "document": "63518503",
        "fullName": "Myreya Rondon Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Vacunador",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000365",
        "document": "52461186",
        "fullName": "Yoneida Diaz Garavito",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/14/2017 3:37:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001674",
        "document": "91356112",
        "fullName": "Oscar Ivan Delgado Cifuentes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Maquinas",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/14/2017 3:37:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000598",
        "document": "13538651",
        "fullName": "Miguel Jaimes Baez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:38:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000996",
        "document": "91340573",
        "fullName": "Javier Martinez Manosalva",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Servicios Generales",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001249",
        "document": "28259407",
        "fullName": "Marlene Duran Afanador",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/14/2017 3:38:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001182",
        "document": "63546493",
        "fullName": "Yamile Gomez Garces",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/14/2017 3:38:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000517",
        "document": "1095806541",
        "fullName": "Luis Miguel Caicedo Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/14/2017 3:38:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001315",
        "document": "37618021",
        "fullName": "Blanca Lucila Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/14/2017 3:37:55 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001312",
        "document": "37617655",
        "fullName": "Esperanza Hernandez Valderrama",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/14/2017 3:39:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000636",
        "document": "13721530",
        "fullName": "Nelson Enrique Vera Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001280",
        "document": "37170450",
        "fullName": "Nelly Perez Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000422",
        "document": "63443663",
        "fullName": "Esperanza Mantilla Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/14/2017 3:37:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000891",
        "document": "1102352908",
        "fullName": "Jazmin Garcia Patino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/14/2017 3:39:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000500",
        "document": "1095726192",
        "fullName": "Breyner Orlando Carreno Beltran",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Operativa",
        "fechaIngreso": "10/14/2017 3:39:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000513",
        "document": "1095802225",
        "fullName": "Luis Carlos Sarmiento Silva",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/14/2017 3:38:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001175",
        "document": "63536267",
        "fullName": "Maribel Chacon Mateus",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/14/2017 3:38:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001593",
        "document": "1098766499",
        "fullName": "Andres David Campos Nino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/14/2017 3:39:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001002",
        "document": "91342229",
        "fullName": "Jose Guillermo Lozano Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/14/2017 3:38:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000292",
        "document": "91180912",
        "fullName": "Javier Dario Calderon Solano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001682",
        "document": "91428535",
        "fullName": "Carlos Arturo Paez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/14/2017 3:37:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000755",
        "document": "91542103",
        "fullName": "Jairo Augusto Pinto Chacon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/14/2017 3:37:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001677",
        "document": "91356476",
        "fullName": "Daniel Florez Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/14/2017 3:39:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001384",
        "document": "1095944499",
        "fullName": "Miguel Angel Rueda Prada",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/14/2017 3:38:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000751",
        "document": "91536865",
        "fullName": "Edgardo Julian Caro Pico",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:39:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001056",
        "document": "91354470",
        "fullName": "Pedro Julian Carvajal Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000080",
        "document": "8771858",
        "fullName": "Pedro Agustin Arguello Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001008",
        "document": "91344293",
        "fullName": "Eduardo Jaimes Quintero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000644",
        "document": "13745803",
        "fullName": "Didyer Florez Arias",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/14/2017 3:39:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000536",
        "document": "1095819187",
        "fullName": "Edwin Stick Florez Ramos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:39:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000918",
        "document": "1102361553",
        "fullName": "Alvaro Javier Garcia Basto",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Fletes",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000959",
        "document": "91243416",
        "fullName": "Juan Carlos Galvis Mendoza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000479",
        "document": "1093754106",
        "fullName": "Miguel Fabian Hernandez",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Supervisor Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000673",
        "document": "19592138",
        "fullName": "Samuel Hernandez Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001497",
        "document": "1065241820",
        "fullName": "Brayan Mantilla Alvarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:39:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000749",
        "document": "91533288",
        "fullName": "Alexander Pico Porto",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:39:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001051",
        "document": "91353402",
        "fullName": "Daniel Velasco Olarte",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001213",
        "document": "72210246",
        "fullName": "Alvaro Antonio Alvarez Villalba",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000213",
        "document": "79511732",
        "fullName": "Jose Alfredo Marin",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000184",
        "document": "1102381301",
        "fullName": "Jossi Esteban Mantilla Jimenez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Planeador Distribucion",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000973",
        "document": "91272126",
        "fullName": "Nelson Eduardo Alvarez Sierra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000312",
        "document": "91222513",
        "fullName": "Jose Manuel Gamarra Trujillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002085",
        "document": "1032477505",
        "fullName": "Dinna Maria Angulo Carpeta",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Gestor De Pedidos",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Gestión De Pedidos",
        "fechaIngreso": "5/24/2017 2:15:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1274027",
        "document": "6200277",
        "fullName": "Danny Martinez Henao",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "9/26/2017 5:56:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071395",
        "document": "92505278",
        "fullName": "Juan Carlos Contreras Sierra",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Pelletizador",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071326",
        "document": "73093938",
        "fullName": "Enrique Hernandez Arenas",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Pelletizador",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070279",
        "document": "73095275",
        "fullName": "Oscar De Jesus Ballestas De Avila",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Pelletizador",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071370",
        "document": "73154080",
        "fullName": "Albeiro Caraballo Camargo",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Empacador / Cosedor",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070187",
        "document": "73093496",
        "fullName": "Ezequiel Ramos Jimenez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Operador Planta Extruder",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071327",
        "document": "73112978",
        "fullName": "Russin Enrique Machacon Bolaño",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepción Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:15:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070189",
        "document": "45535884",
        "fullName": "Cecilia Patricia Ramirez Martinez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Bodeguero",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070191",
        "document": "73352350",
        "fullName": "Rafael Antonio Rodriguez Gonzalez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071331",
        "document": "12447012",
        "fullName": "Fabian Antonio Pertuz De La Hoz",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Bodeguero",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:15:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071332",
        "document": "73160814",
        "fullName": "Alexander De Jesus Leon Herrera",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071336",
        "document": "1012361300",
        "fullName": "Wilber Alfonso Nuñez Mendez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:15:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071406",
        "document": "7957096",
        "fullName": "Adolfo Enrique Canencia Peña",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Oficios Varios",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071373",
        "document": "1101441178",
        "fullName": "Juan Carlos Villero Blanco",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:08:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1249595",
        "document": "94476435",
        "fullName": "Juan Alejandro Betancourt Martinez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:12:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000378",
        "document": "53039846",
        "fullName": "Yizza Lisbeth Carrero Sierra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Jefe Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "10/7/2017 9:05:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000965",
        "document": "91259497",
        "fullName": "Carlos Alberto Rivera",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Proceso",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 8:59:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002628",
        "document": "1128419572",
        "fullName": "Diana Rosso Sanchez",
        "company": "Transgraneles",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "12/15/2017 2:31:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000471",
        "document": "1098649605",
        "fullName": "Ana Maria Manrique Fierro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Jefe De Laboratorio",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "10/7/2017 9:05:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000551",
        "document": "1095829534",
        "fullName": "Daniel Felipe Osma Ramirez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Asistente Fletes",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "10/7/2017 9:07:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001110",
        "document": "1127354095",
        "fullName": "Jose Antonio Romero Herrera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Jefe Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/7/2017 9:42:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001192",
        "document": "63558968",
        "fullName": "BC Johana Sandoval Delgado (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 9:05:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000962",
        "document": "91255996",
        "fullName": "Wilson Meza Rincon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Gerente Comercial",
        "uen": "Comercial",
        "process": "Direccion Uen",
        "area": "Direccion Uen",
        "fechaIngreso": "10/7/2017 10:29:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0278",
        "document": "1085247468",
        "fullName": "Miller Andres Eraso Parra",
        "company": "Temporales",
        "regional": "buga",
        "jobTitle": "Solla",
        "uen": "-",
        "process": "",
        "area": "Auxiliar De Produccion",
        "fechaIngreso": "5/24/2017 2:15:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291201",
        "document": "1115066342",
        "fullName": "William Salazar Posada",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Mascotas",
        "fechaIngreso": "11/24/2017 4:45:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002004",
        "document": "1056785651",
        "fullName": "Andres Alonso Orozco Gomez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/9/2018 1:56:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000760",
        "document": "93397712",
        "fullName": "Jose Albeiro Moreno Olano",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Despachos",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/7/2017 9:05:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001171",
        "document": "63528621",
        "fullName": "BC Carolina Margarita Cardenas Gil (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Asuntos Laborales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 9:05:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001741",
        "document": "91486544",
        "fullName": "Henry Orduz Caballero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Jefe Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/7/2017 9:41:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000726",
        "document": "1098705277",
        "fullName": "Mayra Alejandra Cadena Escobar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Lider Nacional Ambiental Y Sostenibilidad",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "10/7/2017 9:42:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000384",
        "document": "60322528",
        "fullName": "Claudia Josefa Estupinan Rodriguez",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Coordinador Zona",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "10/7/2017 8:59:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001739",
        "document": "91480702",
        "fullName": "BC Sergio Nocua Correa (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Gerente Produccion Pecuaria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 10:33:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000385",
        "document": "60356608",
        "fullName": "Norma Yobana Patino Badillo",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Jefe Ventas",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "10/7/2017 9:42:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000424",
        "document": "63444962",
        "fullName": "Seila Dejesus Rodriguez Soto",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Jefe Planta Procesados",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/7/2017 9:41:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000444",
        "document": "1098621421",
        "fullName": "BC Kelly Yojana Florez Pabon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Director Otros Canales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 10:29:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116201",
        "document": "1128404761",
        "fullName": "Ana Milena Giraldo Gaviria",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Pago De Proveedores",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Tesoreria",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000938",
        "document": "1102365444",
        "fullName": "Diego Hernandez Ordonez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/7/2017 9:06:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002034",
        "document": "70196946",
        "fullName": "Oscar David Munera Bedoya",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director De Investigacion Y Desarrollo",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Investigación Y Desarrollo",
        "fechaIngreso": "1/18/2018 2:12:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1132914",
        "document": "1039456354",
        "fullName": "Alexis Mauricio Rodriguez Aguirre",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "9/13/2017 8:29:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0113",
        "document": "1088652840",
        "fullName": "Nilson Danilo Puetate Cuatin",
        "company": "Temporales",
        "regional": "Past",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/25/2018 8:59:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0114",
        "document": "1115082563",
        "fullName": "Ramiro Arturo Velasquez Martinez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/24/2018 4:23:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00118",
        "document": "12992376",
        "fullName": "Alejandro Ernesto Tello",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/24/2017 2:09:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000508",
        "document": "1095792026",
        "fullName": "Jose Didier Pabon Bautista",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Jefe Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/7/2017 9:01:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002084",
        "document": "1020414944",
        "fullName": "Leydi Johanna Quirama Rojas",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "2/2/2018 12:52:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002460",
        "document": "1099373760",
        "fullName": "Karen Dayanna Gualdron Gualdron",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Costos",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Planeacion Financiera",
        "fechaIngreso": "10/7/2017 9:07:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001463",
        "document": "1049610399",
        "fullName": "BC Damaris Lorena Zambrano Jimenez (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Pollo De Engorde",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 8:59:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001835",
        "document": "1095796528",
        "fullName": "Erick Alfonso Ariza Montenegro",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "1/2/2018 2:25:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000070",
        "document": "7218882",
        "fullName": "Norberto Hipolito Perez Mateus",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/7/2017 9:00:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001681",
        "document": "91391300",
        "fullName": "Camilo Torres Calderon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/7/2017 9:00:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000905",
        "document": "1102357196",
        "fullName": "Victor Alfonso Mantilla Gomez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/7/2017 8:59:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0286",
        "document": "1085276844",
        "fullName": "William Alveiro Rodriguez Riascos",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Proservis Temporales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000038",
        "document": "5671535",
        "fullName": "Pablo Antonio Ramirez Bautista",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/14/2017 3:37:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002122",
        "document": "6445488",
        "fullName": "Carlos Arturo Perez Orrego",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "2/9/2018 3:20:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002124",
        "document": "1113036784",
        "fullName": "Camilo Ernesto Acosta Betacour",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "2/9/2018 3:20:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001148",
        "document": "63474533",
        "fullName": "Gloria Stella Rojas Uribe",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/14/2017 3:37:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001518",
        "document": "1068814426",
        "fullName": "Jaime Enrique Hernandez Rios",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/14/2017 3:39:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001895",
        "document": "1140416777",
        "fullName": "Edier Humberto Leal Ramos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/26/2017 5:17:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000101",
        "document": "13497168",
        "fullName": "Daniel Gelvez Pinilla",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/14/2017 3:37:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001173",
        "document": "63532683",
        "fullName": "BC Maria Teresa Gonzalez Carvajal (Retirado)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Director Corporativo Proteína",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 9:41:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001352",
        "document": "1095928818",
        "fullName": "Santiago Olarte Tirado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001714",
        "document": "91464551",
        "fullName": "Alirio Montanez Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000044",
        "document": "5677732",
        "fullName": "Hernando Poveda Almeida",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001830",
        "document": "1073982890",
        "fullName": "Arbey Albeiro Altamiranda Zarate",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/26/2017 6:47:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001387",
        "document": "1095947754",
        "fullName": "Angel Esteban Ramirez Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/14/2017 3:39:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001186",
        "document": "63553032",
        "fullName": "Dora Ines Rojas Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/14/2017 3:37:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001281",
        "document": "37399583",
        "fullName": "Maria Lorena Mora Santafe",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/14/2017 3:38:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001044",
        "document": "91352126",
        "fullName": "Omar Alfonso Sanabria Parra",
        "company": "Distraves",
        "regional": "Elpl",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002076",
        "document": "1095910590",
        "fullName": "Oscar Javier Rodriguez Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "2/1/2018 5:16:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001834",
        "document": "1095701410",
        "fullName": "Emanuel Eduardo Rodriguez Silva",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "1/2/2018 2:25:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002082",
        "document": "91003305",
        "fullName": "Manuel  Martinez Santos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/14/2018 9:43:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002089",
        "document": "4277879",
        "fullName": "Victor Alirio Perez Gomez",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/14/2018 8:43:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001289",
        "document": "37542161",
        "fullName": "Emilsen Sandoval Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/14/2017 3:39:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003265",
        "document": "1006452513",
        "fullName": "Wilmar Alexis Ramirez Arenas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Gestor De Pedidos",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Gestión De Pedidos",
        "fechaIngreso": "2/16/2018 7:21:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002086",
        "document": "80413923",
        "fullName": "Luis Fernando Bustamante Varon",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "2/8/2018 10:14:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001259",
        "document": "28403915",
        "fullName": "Maria Eugenia Acevedo Cardenas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Inventarios",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "10/7/2017 9:01:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1273010",
        "document": "14898982",
        "fullName": "Diego Julian Londoño Giraldo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:12:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000537",
        "document": "1095820871",
        "fullName": "Abundio Celis Munoz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Comercial",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "10/7/2017 9:01:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001179",
        "document": "63540426",
        "fullName": "Claudia Rocio Caicedo Gomez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Nomina",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "10/7/2017 9:42:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001684",
        "document": "91441193",
        "fullName": "Mario Alberto Zapata Oquendo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Asistente Logistica",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "10/7/2017 8:59:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001115",
        "document": "1128228445",
        "fullName": "Gustavo Adolfo Parada Quintero",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Supervisor Full Filler",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/22/2018 6:47:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0248",
        "document": "73141715",
        "fullName": "Jose Joaquin Padilla Agamez",
        "company": "Temporales",
        "regional": "Cartagena",
        "jobTitle": "Auxiliar Producción",
        "uen": "-",
        "process": "",
        "area": "Produccion",
        "fechaIngreso": "5/24/2017 2:08:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8070797",
        "document": "85436277",
        "fullName": "Dilson De Las Aguas Meza",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Operador Planta Extruder",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "8071372",
        "document": "9146226",
        "fullName": "Juan Carlos Pautt Renteria",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepción Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2017 2:15:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9019",
        "document": "01234563",
        "fullName": "Porteria Agrinal Cartagena",
        "company": "Solla",
        "regional": "",
        "jobTitle": "Porteria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/24/2017 2:10:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000603",
        "document": "13539667",
        "fullName": "Alexander Fernandez Rodriguez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000786",
        "document": "1005271441",
        "fullName": "Jenny Milena Marin Amorocho",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/7/2017 9:07:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001021",
        "document": "91347062",
        "fullName": "Jose Luis Arias Camargo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/14/2017 3:37:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001609",
        "document": "1098789008",
        "fullName": "Justo Andrey Celis Rodriguez",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Coordinador Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001670",
        "document": "91355832",
        "fullName": "William Mendoza Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/7/2017 9:00:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000590",
        "document": "13535901",
        "fullName": "Oscar Palomino",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001112",
        "document": "1127595807",
        "fullName": "Cristian Diaz Pinzon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Inventarios",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "10/7/2017 9:06:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000577",
        "document": "1095923648",
        "fullName": "Carlos Andres Arevalo Vela",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Sistemas",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Departamento De Informatica",
        "fechaIngreso": "10/7/2017 9:01:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001683",
        "document": "91434487",
        "fullName": "Henry Uribe Rojas",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000090",
        "document": "12457869",
        "fullName": "Eliseo Silva Estebez",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001804",
        "document": "91346967",
        "fullName": "Casiano Silva Carvajal",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Coordinador Inventarios",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/22/2017 8:03:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001372",
        "document": "1095937777",
        "fullName": "Manuel Fernando Mantilla Rueda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000279",
        "document": "91158796",
        "fullName": "Adolfo Caicedo Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Tuneles",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/7/2017 9:08:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000449",
        "document": "1098628447",
        "fullName": "Javier Fernando Ortiz Franco",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000402",
        "document": "63340131",
        "fullName": "Candelaria Gomez Diaz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Contabilidad",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Contabilidad",
        "fechaIngreso": "10/7/2017 8:59:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000467",
        "document": "1098647910",
        "fullName": "Jefferson Fabian Coronado Amado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/7/2017 8:59:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000661",
        "document": "13927202",
        "fullName": "Alfonso Ortiz Velandia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000528",
        "document": "1095812862",
        "fullName": "Leidy Paola Rueda Pereira",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Tesoreria",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Tesoreria Y Riesgos",
        "fechaIngreso": "10/7/2017 9:41:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001688",
        "document": "91451117",
        "fullName": "Jesus Rodriguez Florez",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000072",
        "document": "7225045",
        "fullName": "Jesus Benigno Cucunuba Rojas",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001211",
        "document": "72173470",
        "fullName": "Angel Miguel Buenahora Angarita",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001731",
        "document": "91473885",
        "fullName": "Fabio Alvaro Borrero Galvan",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/7/2017 9:05:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000960",
        "document": "91250874",
        "fullName": "Nelson Florez Carvajal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Cargue",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/7/2017 9:00:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000923",
        "document": "1102362143",
        "fullName": "Grace Lorena Castillo Mendoza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "10/7/2017 9:06:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001805",
        "document": "91347937",
        "fullName": "Jhon Jairo Rivera Guillen",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/11/2017 9:57:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000297",
        "document": "91183363",
        "fullName": "Luis Fernando Luque",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:38:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000659",
        "document": "13872861",
        "fullName": "Wilfer Alexander Sandoval Gallo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Bascula",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "10/14/2017 3:37:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000629",
        "document": "13700712",
        "fullName": "Luis Hernan Montanez Gallo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:38:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000006",
        "document": "2159055",
        "fullName": "Norberto Noriega Sarmiento",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:38:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001340",
        "document": "37841740",
        "fullName": "Johana Rincon Rojas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Contabilidad",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Contabilidad",
        "fechaIngreso": "10/7/2017 9:07:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001769",
        "document": "91524781",
        "fullName": "Carlos Hernando Sierra Alvarez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Bascula",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "10/14/2017 3:39:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000870",
        "document": "1101596483",
        "fullName": "Monica Jaimes Medina",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Jefe Planta Post-proceso",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/7/2017 9:01:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000822",
        "document": "1020770675",
        "fullName": "Erika Katherine Rodriguez Caceres",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Supervisor Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:39:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001777",
        "document": "13538835",
        "fullName": "Abel Diaz Romero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/26/2017 3:47:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001172",
        "document": "63528916",
        "fullName": "Liz Karolain Plata Chacon",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Coordinador Administrativo Comercial",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "10/14/2017 3:38:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000215",
        "document": "79690674",
        "fullName": "Abelardo Solano Fuentes",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000970",
        "document": "91268562",
        "fullName": "Luis Aurelio Poches Corzo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/7/2017 9:00:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001224",
        "document": "74301752",
        "fullName": "Orlando Acero Rios",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:52:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000048",
        "document": "5678472",
        "fullName": "Eduardo Lizarazo Poveda",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000698",
        "document": "1098674117",
        "fullName": "Marcela Rocio Blanco Pena",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Reproductoras",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:38:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001698",
        "document": "91452145",
        "fullName": "Hernando Medina Mendoza",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:37:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001691",
        "document": "91451545",
        "fullName": "Blas Rincon Ramirez",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:55 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000623",
        "document": "13637872",
        "fullName": "Mauricio Velandia Almeyda",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001744",
        "document": "91489219",
        "fullName": "Amadeo Murillo Duarte",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/9/2017 7:52:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000852",
        "document": "1100893827",
        "fullName": "Arley Tavera Salazar",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000653",
        "document": "13845003",
        "fullName": "Guillermo Antonio Samur Nassar",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Gerente General",
        "uen": "Gerencia General",
        "process": "Gerencia General",
        "area": "Gerencia General",
        "fechaIngreso": "10/7/2017 10:29:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001721",
        "document": "91467637",
        "fullName": "Eliecer Sierra Ardila",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Sistemas",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Departamento De Informatica",
        "fechaIngreso": "10/7/2017 9:07:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000288",
        "document": "91175229",
        "fullName": "Teodoro Rangel",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001033",
        "document": "91349021",
        "fullName": "Pedro Antonio Vargas Mejia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/7/2017 9:00:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001050",
        "document": "91353062",
        "fullName": "Edwin Ramiro Hernandez Gomez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Vacunador",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:39:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000589",
        "document": "13525994",
        "fullName": "Jesus Antonio Cruz Cruz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:39:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000994",
        "document": "91340280",
        "fullName": "Alvaro Ramirez Bautista",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001678",
        "document": "91356521",
        "fullName": "Rafael Antonio Nino Reyes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000559",
        "document": "1095908268",
        "fullName": "Yuveimar Diaz Rodriguez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Maquinas",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/14/2017 3:38:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000997",
        "document": "91340784",
        "fullName": "Paulino Pinzon Murallas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001422",
        "document": "1098220558",
        "fullName": "Oscar Humberto Jaimes Rincon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/7/2017 9:05:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000022",
        "document": "5534789",
        "fullName": "Sergio Martin Bochaga Parra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001855",
        "document": "1098709552",
        "fullName": "Diego Alejandro Sarmiento Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/26/2017 6:17:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000407",
        "document": "63357423",
        "fullName": "Sonia Esperanza Mogollon Arenas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Control Interno P.d.v",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 9:42:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000040",
        "document": "5672248",
        "fullName": "Basilio Landinez Joya",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000007",
        "document": "2198710",
        "fullName": "Timoteo Jerez Chanaga",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001709",
        "document": "91458474",
        "fullName": "Marco Tulio Alvarado Santos",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001023",
        "document": "91347355",
        "fullName": "William Viviescas Vargas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000846",
        "document": "1041257486",
        "fullName": "Alben Jose Gaspar Fuentes",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001710",
        "document": "91458702",
        "fullName": "Orlando Bueno Castellanos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:38:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001533",
        "document": "1085034108",
        "fullName": "Jizzete Tatiana Fontalvo Martinez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Materias Primas",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/7/2017 8:59:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000469",
        "document": "1098648387",
        "fullName": "Leydi Marcela Ochoa Ortega",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/7/2017 9:06:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000535",
        "document": "1095818173",
        "fullName": "Elmer Alfredo Caballero Barajas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/14/2017 3:39:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001337",
        "document": "37832797",
        "fullName": "Marlene Rojas De Jerez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000989",
        "document": "91296681",
        "fullName": "Jorge Enrique Jaimes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/14/2017 3:37:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000291",
        "document": "91179233",
        "fullName": "Javier Serrano Peña",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asistente Compras",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Compras",
        "fechaIngreso": "10/9/2017 1:04:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001720",
        "document": "91467254",
        "fullName": "Mauricio Florez Munoz",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000264",
        "document": "91076499",
        "fullName": "Roberto Rodriguez Ruiz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000125",
        "document": "1102369432",
        "fullName": "Carlos Humberto Tamayo Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001269",
        "document": "32513914",
        "fullName": "Margarita Maria Lopera Salazar",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Coordinador Institucional",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "10/7/2017 9:00:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000595",
        "document": "13538230",
        "fullName": "Jose David Murcia Rios",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001795",
        "document": "71217062",
        "fullName": "Jhonny Amderson Urrego Moncada",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/22/2017 8:33:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001001",
        "document": "91341589",
        "fullName": "Alirio Acuna Villamizar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000681",
        "document": "28089744",
        "fullName": "Hilda Pena Cabezas",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001161",
        "document": "63505109",
        "fullName": "Marilu Vega Araque",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/7/2017 9:00:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000252",
        "document": "91004498",
        "fullName": "Sebastian Melgarejo Carreno",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000690",
        "document": "1098663473",
        "fullName": "Joohn Alexander Ortiz Nino",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000047",
        "document": "5678085",
        "fullName": "Martin Rondon Uribe",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001453",
        "document": "1098602913",
        "fullName": "Reinaldo Diaz Santos",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/14/2017 3:37:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001336",
        "document": "37748587",
        "fullName": "Jackeline Mantilla Poveda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Vendedor Pecuaria",
        "uen": "Comercial",
        "process": "Direccion Uen",
        "area": "Direccion Uen",
        "fechaIngreso": "10/7/2017 9:06:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001339",
        "document": "37840510",
        "fullName": "Johanna Bautista Villamizar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/14/2017 3:39:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000153",
        "document": "1102374984",
        "fullName": "Fermin Pedroza Chaparro",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000515",
        "document": "1095803123",
        "fullName": "Efrain Orellan Orellan",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000875",
        "document": "1101683783",
        "fullName": "Elquin Alonzo Velasquez Pinzon",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001579",
        "document": "1098747281",
        "fullName": "Edward Leonardo Pabon Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Sistemas",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Departamento De Informatica",
        "fechaIngreso": "10/7/2017 9:06:55 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001283",
        "document": "37514522",
        "fullName": "Martha Isabel Alvarez Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Gerente Produccion Industrial",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "10/7/2017 10:29:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000254",
        "document": "91043517",
        "fullName": "Pedro Antonio Vasquez Rueda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Maquinas",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001697",
        "document": "91452102",
        "fullName": "Pedro Cabrera Pedraza",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001693",
        "document": "91451832",
        "fullName": "Felix Jesus Barragan Ortiz",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001162",
        "document": "63506625",
        "fullName": "Claudia Patricia Cadena Santos",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Gestion Documental",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Centro De Gestion Documental",
        "fechaIngreso": "10/7/2017 8:59:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000624",
        "document": "13637907",
        "fullName": "Alexander Gomez Rondon",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000584",
        "document": "13516392",
        "fullName": "Leonardo Lizarazo Saavedra",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:39:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001705",
        "document": "91452638",
        "fullName": "Fernando Esteban Prada Parra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:37:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000976",
        "document": "91274296",
        "fullName": "Jose Diaz Escobar",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000573",
        "document": "1095920102",
        "fullName": "Wilder Alexis Pico Gomez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Mensajero",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Centro De Gestion Documental",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001102",
        "document": "1122396694",
        "fullName": "Saul Forero Montanez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000269",
        "document": "91110171",
        "fullName": "Hector Angarita Castellanos",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000984",
        "document": "91288574",
        "fullName": "Sergio Duran Navas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:52:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001431",
        "document": "1098356248",
        "fullName": "Diego Armando Ortiz Ortiz",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000759",
        "document": "93128934",
        "fullName": "Hugo Francisco Ortega Lozano",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Incubadora",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 1:05:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000691",
        "document": "1098665558",
        "fullName": "Nasly Nayibi Castro Gualdron",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Tesoreria",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Tesoreria Y Riesgos",
        "fechaIngreso": "10/7/2017 8:59:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001426",
        "document": "1098261979",
        "fullName": "Laudy Dayana Rondon Ardila",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Sistemas",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Departamento De Informatica",
        "fechaIngreso": "10/7/2017 9:05:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002393",
        "document": "37618867",
        "fullName": "Marisela Quesada Malagon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Contabilidad",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Contabilidad",
        "fechaIngreso": "10/7/2017 9:07:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001338",
        "document": "37840359",
        "fullName": "Johanna Duarte Bueno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Bodega",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/7/2017 9:01:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001420",
        "document": "1098101510",
        "fullName": "Luis Eduardo Fonseca Ayala",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Cargue",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000643",
        "document": "13744407",
        "fullName": "Jorge Alexander Velasco Cordero",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000274",
        "document": "91153275",
        "fullName": "Sergio Rodriguez Parra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/14/2017 3:37:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001119",
        "document": "1129525472",
        "fullName": "BC Deicy Patricia Florez Lopez (Shared)",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 9:01:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000874",
        "document": "1101682836",
        "fullName": "Miguel Angel Florez Santos",
        "company": "Distraves",
        "regional": "Bari",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001229",
        "document": "77092398",
        "fullName": "Jaime Alberto Florez Ojeda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000117",
        "document": "1102368293",
        "fullName": "BC Jeyson Fabian Solano Avila (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Lider De Transformacion Carnica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 9:00:22 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000337",
        "document": "45539259",
        "fullName": "Rosa Cristina Bello Hernandez",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Supervisor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001247",
        "document": "28215184",
        "fullName": "Gladys Mirella Suarez Ramirez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Servicios Generales",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/14/2017 3:39:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001659",
        "document": "1099549152",
        "fullName": "Magdiel Gonzalez Herreno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000409",
        "document": "63368906",
        "fullName": "Marisol Rodriguez Quintero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001071",
        "document": "1102719752",
        "fullName": "BC Diana Carolina Martinez Ardila (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Sst E Higiene",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/14/2017 3:39:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000654",
        "document": "13853012",
        "fullName": "Aldemar Bernal Escobar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:39:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000438",
        "document": "1098616111",
        "fullName": "BC Nathalia Andrea Hernandez Rueda (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Procesos",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 10:29:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000579",
        "document": "13515380",
        "fullName": "Ulpiano Olave Alquichire",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001016",
        "document": "91346178",
        "fullName": "Mauricio Ramirez Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Super.inventarios Y Operac.logistic",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/7/2017 9:41:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000901",
        "document": "1102355839",
        "fullName": "Sergio Calderon Mantilla",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/7/2017 9:05:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001692",
        "document": "91451763",
        "fullName": "Eliseo Reyes Pinto",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:38:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000032",
        "document": "5622283",
        "fullName": "Emiliano Castro Gomez",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Supervisor Operativo Granjas Reproductoras",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001696",
        "document": "91452070",
        "fullName": "Celmiro Ortiz Velasquez",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:39:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000583",
        "document": "13516209",
        "fullName": "Armando Duarte Calderon",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001745",
        "document": "91490611",
        "fullName": "Douglas Enrique Quintero Gomez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Soporte Pos",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Departamento De Informatica",
        "fechaIngreso": "10/7/2017 9:05:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000062",
        "document": "5746673",
        "fullName": "Gerardo Camacho Prada",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000979",
        "document": "91279822",
        "fullName": "Pedro Vicente Carrillo Carrillo",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000041",
        "document": "5672347",
        "fullName": "Tulio Espinosa Santos",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:38:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001261",
        "document": "28496694",
        "fullName": "Amelia Leon Diaz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000548",
        "document": "1095825800",
        "fullName": "Deiby Harley Sanchez Serrano",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Vacunador",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:39:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001159",
        "document": "63502713",
        "fullName": "Sandra Milena Mendez Criado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001367",
        "document": "1095936666",
        "fullName": "Yerson Mauricio Sanabria Patino",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001009",
        "document": "91344366",
        "fullName": "Luis Alberto Vargas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/14/2017 3:37:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001747",
        "document": "91493333",
        "fullName": "Ruben Rueda Contreras",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Maquinas",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002062",
        "document": "1104094374",
        "fullName": "Jaime Luis Sepulveda Yepez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "2/1/2018 10:08:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000549",
        "document": "1095826142",
        "fullName": "Harold Yesid Barreto Carrillo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista De Infraestructura Ti",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Departamento De Informatica",
        "fechaIngreso": "10/7/2017 9:08:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000507",
        "document": "1095791460",
        "fullName": "Mayra Barrera Blanco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Logistica Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/7/2017 9:01:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001789",
        "document": "44153008",
        "fullName": "Sandra Milena Santiago Segura",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Asistente Regional",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "12/26/2017 9:18:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001707",
        "document": "91457475",
        "fullName": "Rodolfo Sierra Caceres",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001568",
        "document": "1098733772",
        "fullName": "Ricardo Castilla Saravia",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000957",
        "document": "91241687",
        "fullName": "Miguel Angel Alvarez Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Bodega",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/7/2017 9:00:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001694",
        "document": "91451888",
        "fullName": "Raul Eslava Rincon",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001043",
        "document": "91351516",
        "fullName": "Miguel Francisco Diaz Acuna",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/9/2017 7:53:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001690",
        "document": "91451461",
        "fullName": "Gustavo Aparicio Pimiento",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000702",
        "document": "1098676762",
        "fullName": "Alexander Ortiz Pabon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Comercial",
        "process": "Direccion Canal",
        "area": "Direccion Canal",
        "fechaIngreso": "10/7/2017 9:00:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001857",
        "document": "1098737456",
        "fullName": "Jhon Eduard Escalante Pardo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Inventarios",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/26/2017 6:17:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001467",
        "document": "1050944748",
        "fullName": "Rafael Enrique Morales Torres",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Supervisor Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2017 3:38:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001883",
        "document": "1102387085",
        "fullName": "Laura Marcela Franco Pineda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asistente Contabilidad",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Contabilidad",
        "fechaIngreso": "10/7/2017 9:07:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000731",
        "document": "1098712777",
        "fullName": "Ingrid Xiomara Castro Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/7/2017 9:06:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000434",
        "document": "1098611184",
        "fullName": "Ronaldo Yesid Albarran Velasquez",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "10/14/2017 3:38:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000301",
        "document": "91185060",
        "fullName": "Abel Alexander Villamizar Anaya",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Maquinas",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/14/2017 3:38:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000935",
        "document": "1102365323",
        "fullName": "Leydi Andrea Hernandez Calderon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001541",
        "document": "1090421083",
        "fullName": "Andersson Favian Ortega Rivera",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Inventarios",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "10/14/2017 3:39:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000261",
        "document": "91075047",
        "fullName": "Jesus Badillo Ortiz",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:39:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000655",
        "document": "13862516",
        "fullName": "Sabino Jerez Jaimes",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:38:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000747",
        "document": "91533182",
        "fullName": "Robinson Rueda Estevez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Jefe Planta Harinas",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "10/7/2017 9:07:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001716",
        "document": "91464799",
        "fullName": "Carlos Alberto Forero Gomez",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000068",
        "document": "5796928",
        "fullName": "Luis Enrique Zarate Nunez",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000974",
        "document": "91272523",
        "fullName": "Israel Quintero Aparicio",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001020",
        "document": "91346903",
        "fullName": "Carlos Palacios Garnica",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000620",
        "document": "13636176",
        "fullName": "Nelson Andres Afanador Carreno",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:37:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000039",
        "document": "5671697",
        "fullName": "Marco Tulio Sarmiento Almeida",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:38:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001700",
        "document": "91452200",
        "fullName": "Benedicto Sequeda Murillo",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000625",
        "document": "13638114",
        "fullName": "Luis Arturo Gomez Ayala",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:37:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000416",
        "document": "63441203",
        "fullName": "Leonor Lopez Santos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000248",
        "document": "88311144",
        "fullName": "Diego Fernando Esteban Rodriguez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios_semillero",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:39:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000561",
        "document": "1095912666",
        "fullName": "Carlos Andres Rodriguez Aparicio",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001015",
        "document": "91345959",
        "fullName": "Daniel Poveda Pena",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2017 3:37:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001550",
        "document": "1090466954",
        "fullName": "Luisa Fernanda Ardila Nuncira",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Auditoria Interna",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "12/26/2017 4:17:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000285",
        "document": "91160247",
        "fullName": "Edisson Fernando Baez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001368",
        "document": "1095936875",
        "fullName": "Erwin Fabian Sepulveda Pabon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000320",
        "document": "37928740",
        "fullName": "Esperanza Rojas Rueda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001167",
        "document": "63523666",
        "fullName": "Luz Nidia Arce Patino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/9/2017 7:54:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000711",
        "document": "1098683057",
        "fullName": "Yurladi Katherine Jaimes Ayala",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asistente Impuestos",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Contabilidad",
        "fechaIngreso": "10/7/2017 9:06:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000630",
        "document": "13715139",
        "fullName": "Agustin Munoz Macias",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/14/2017 3:38:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000564",
        "document": "1095913190",
        "fullName": "Andres Augusto Alvarado Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Calidad Industrial",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "10/7/2017 9:00:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000447",
        "document": "1098624670",
        "fullName": "Oscar Fabian Bautista Contreras",
        "company": "Distraves",
        "regional": "Mata",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000621",
        "document": "13636552",
        "fullName": "Edwing Velandia Almeida",
        "company": "Distraves",
        "regional": "Bari",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:38:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000856",
        "document": "1100950464",
        "fullName": "Fidel Monsalve Reyes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2017 3:39:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001757",
        "document": "91510660",
        "fullName": "Fernando Layton Castro",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Inventarios",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "10/7/2017 9:08:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000426",
        "document": "1098604367",
        "fullName": "Julio Edison Jaimes Carvajal",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Jefe Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "10/12/2017 4:39:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000427",
        "document": "1098604781",
        "fullName": "Ruben Dario Hernandez Figueredo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/9/2017 7:53:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001582",
        "document": "1098752811",
        "fullName": "BC Said Julian Fernandez Gomez (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Juridico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2017 9:08:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000522",
        "document": "1095811047",
        "fullName": "Maria Alejandra Carrero Uribe",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:54:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000390",
        "document": "63303862",
        "fullName": "Nubby Lisett Higuera Dagovett",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Gestion Documental",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Centro De Gestion Documental",
        "fechaIngreso": "10/7/2017 9:06:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000933",
        "document": "1102364936",
        "fullName": "Liliana Marcela Gonzalez Ortiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Administrativo Logistica",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "10/7/2017 9:05:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000908",
        "document": "1102357848",
        "fullName": "Edwing Pineda Cogua",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/7/2017 9:01:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001444",
        "document": "1098358098",
        "fullName": "Luis Alfonso Gomez Rangel",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion_entrenamiento",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/9/2017 7:53:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0341",
        "document": "1017196955",
        "fullName": "Dirley  Rios Bran",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Practicante Salud Ocupacional",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/27/2018 7:54:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002160",
        "document": "18419492",
        "fullName": "Jhon Freddy Cifuentes Espinosa",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "2/27/2018 4:22:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000900",
        "document": "1102355726",
        "fullName": "Jose Luis Martinez Castillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/5/2018 7:59:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001713",
        "document": "91464385",
        "fullName": "Campo Elias Lopez Barajas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/5/2018 10:00:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001814",
        "document": "91532089",
        "fullName": "Numar Ramon Martinez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "1/2/2018 2:25:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002133",
        "document": "5782539",
        "fullName": "Giovanni  Rojas Rey",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "3/6/2018 10:37:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002047",
        "document": "12595790",
        "fullName": "Libardo Manuel Chiquillo Guardela",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "3/12/2018 2:31:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000910",
        "document": "1102358046",
        "fullName": "Jerson Bueno Niño",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/13/2018 9:50:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000855",
        "document": "1100896092",
        "fullName": "Olinto Rueda Santamaria",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Vacunador",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/13/2018 9:50:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000679",
        "document": "27978683",
        "fullName": "Leonor Rodriguez Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/14/2018 8:30:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002228",
        "document": "1118020072",
        "fullName": "Arles Lopez Asencio",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "3/16/2018 2:36:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002223",
        "document": "91079053",
        "fullName": "Camilo Alfredo Lopez Hernandez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "3/20/2018 2:36:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002219",
        "document": "91522618",
        "fullName": "Salustiano  Luna Robles",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "3/20/2018 2:36:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001815",
        "document": "91532502",
        "fullName": "Ivan Dario Garcia Celis",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/20/2018 4:06:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002005",
        "document": "1116254377",
        "fullName": "Bayron Jose Valencia Pedroza",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "1/9/2018 2:56:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002252",
        "document": "32863411",
        "fullName": "Diana Isabel Mercado Guerrero",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Director Zona",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "3/27/2018 2:02:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002247",
        "document": "11002632",
        "fullName": "Evin De Jesus Llorente Hernandez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "3/22/2018 1:55:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002057",
        "document": "94476760",
        "fullName": "Edgar Andres Noguera Atoy",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "1/19/2018 8:01:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002261",
        "document": "16804282",
        "fullName": "Oscar Eduardo Arias Orozco",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "4/4/2018 8:54:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001806",
        "document": "91350365",
        "fullName": "Jose Alberto Silva Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/5/2018 5:16:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002270",
        "document": "43878808",
        "fullName": "Francela Alexandra Piedrahita Bermudez",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "4/3/2018 5:03:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1292514",
        "document": "94476007",
        "fullName": "Julio Alberto Vidal Chicaiza",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/24/2017 5:15:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002277",
        "document": "1140854968",
        "fullName": "Roseyher  Lora Picon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Director Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "4/10/2018 4:38:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002042",
        "document": "1098757801",
        "fullName": "Miguel Angel Rueda Jaimes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Jefe Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "4/11/2018 8:28:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002301",
        "document": "80655269",
        "fullName": "Camilo Andres Niño Bachiller",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "4/12/2018 11:37:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0024",
        "document": "1085315493",
        "fullName": "Christian David Pastas Andrade",
        "company": "Temporales",
        "regional": "Past",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "-",
        "process": "",
        "area": "Bodeguero",
        "fechaIngreso": "6/8/2017 12:35:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002302",
        "document": "38569564",
        "fullName": "Aliah Fayzoury Velez Norato",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "4/17/2018 6:43:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002315",
        "document": "98642454",
        "fullName": "BC Wilmer Hoyos Ramirez (Retirado)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Conectividad",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Conectividad",
        "fechaIngreso": "4/18/2018 3:05:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1251917",
        "document": "1115075271",
        "fullName": "Gustavo Adolfo Caicedo Hurtado",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "11/24/2017 4:45:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002164",
        "document": "1053787641",
        "fullName": "Adriana Rocio Teheran Bravo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "3/7/2018 6:57:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002317",
        "document": "1098694050",
        "fullName": "BC Erika Rocio Sotelo Parra (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asistente Tesoreria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/19/2018 6:39:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002326",
        "document": "91540419",
        "fullName": "John Freddy Silva Angarita",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Coordinador (a) Logistica De Plantas",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "4/20/2018 11:50:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000073",
        "document": "32161028",
        "fullName": "Deisy Nataly Adarve Perez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Facturacion Transporte",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Puertos Y Transporte",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002194",
        "document": "1095807043",
        "fullName": "Omar  Patiño Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/3/2018 2:28:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002280",
        "document": "1098682436",
        "fullName": "Sergio Alveiro Rincon Grimaldos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/3/2018 3:28:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002271",
        "document": "1102391613",
        "fullName": "Fernando  Velandia Rayon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/3/2018 3:58:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002268",
        "document": "1127064810",
        "fullName": "Maikel Adderly Espinel Moreno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Despachos",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/3/2018 7:59:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002156",
        "document": "91351105",
        "fullName": "Isidro  Gualdron Chaparro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "5/3/2018 7:59:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1123577",
        "document": "1039622304",
        "fullName": "Juan David Mira García",
        "company": "Solla",
        "regional": "Entr",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "9/13/2017 8:29:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1172802",
        "document": "1040044006",
        "fullName": "Antonio Lopez Muñoz",
        "company": "Solla",
        "regional": "Lace",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002371",
        "document": "38671281",
        "fullName": "Gloria Amparo Trujillo Zabala",
        "company": "Transgraneles",
        "regional": "Buga",
        "jobTitle": "Coordinador In House El Galpon",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/9/2018 1:52:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002318",
        "document": "1102720705",
        "fullName": "Jesus Alberto Mayorga Corzo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/9/2018 3:53:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002403",
        "document": "51900451",
        "fullName": "Claudia Patricia Orozco Rodriguez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/9/2018 1:52:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000825",
        "document": "1024467541",
        "fullName": "Pablo Andres Hernandez Garcia",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Sistemas",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Departamento De Informatica",
        "fechaIngreso": "5/9/2018 4:36:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002353",
        "document": "1098261135",
        "fullName": "Cindy Vannesa Gutierrez Sierra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2018 7:27:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1268435",
        "document": "1115071931",
        "fullName": "Andres Felipe Henao Bolivar",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/24/2017 4:45:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002159",
        "document": "1127064588",
        "fullName": "Sergio Alexander Sanchez Baron",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2018 7:58:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1343850",
        "document": "1070968148",
        "fullName": "Diego Fabian Balaguera Pinzon",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "5/24/2017 2:14:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006773",
        "document": "76042446",
        "fullName": "Jhon Jorge Carrillo Collazos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Jefe De Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "5/17/2018 11:39:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006770",
        "document": "1112626547",
        "fullName": "Nathalia Cardona Vinasco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "5/18/2018 3:40:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002414",
        "document": "80897226",
        "fullName": "John Ferney Martin Castrillon",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/16/2018 8:13:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283513",
        "document": "1114062048",
        "fullName": "Luilly Steven Quintero Garzon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "11/24/2017 4:45:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002367",
        "document": "1104014194",
        "fullName": "Fabian Arturo Lobo Carpio",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "5/24/2018 3:09:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002257",
        "document": "13872799",
        "fullName": "Jorge Ivan Caballero Vargas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/24/2018 3:39:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002467",
        "document": "1111812376",
        "fullName": "Pablo  Montaño Angulo",
        "company": "Transgraneles",
        "regional": "Buen",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/24/2018 4:39:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008911",
        "document": "1115074337",
        "fullName": "Erika Ramos Gonzalez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2018 5:03:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006809",
        "document": "94511623",
        "fullName": "Fabio Antonio Cruz Cordoba",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/29/2018 8:00:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002025",
        "document": "1128441877",
        "fullName": "Daniel Estiven Duque Lopez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Mensajero Conductor",
        "uen": "Corporativo Secretaría Gral Y Jurídica",
        "process": "Secretaría General Y Jurídica",
        "area": "Cad",
        "fechaIngreso": "1/17/2018 10:29:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002452",
        "document": "80803709",
        "fullName": "Felix Armando Perilla Contreras",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador Regional De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/16/2018 8:55:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1265001",
        "document": "1115066606",
        "fullName": "Jhon Eduardo Montoya Rodriguez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9018",
        "document": "01234562",
        "fullName": "Porteria Solla Buga",
        "company": "Solla",
        "regional": "",
        "jobTitle": "Porteria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/4/2017 2:06:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002099",
        "document": "1102364465",
        "fullName": "Mauricio  Niño Tarazona",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "6/6/2018 3:40:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002153",
        "document": "1102367225",
        "fullName": "Nayro Alonso Florez Morales",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "6/6/2018 5:10:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1215064",
        "document": "1115083750",
        "fullName": "Yeison Andres Gutierrez Betancourt",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/24/2017 4:45:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006545",
        "document": "67014080",
        "fullName": "Lucelly Mosquera Angulo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider De Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "6/8/2018 7:51:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002519",
        "document": "1100957620",
        "fullName": "Agustin Duran Duran",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Inventarios",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "6/13/2018 2:51:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002494",
        "document": "1090364156",
        "fullName": "Marlyn Johanna Aponte Gelves",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "6/14/2018 7:52:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002471",
        "document": "91250082",
        "fullName": "Luis Guillermo Ortiz Duran",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/22/2018 6:51:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002585",
        "document": "1069479328",
        "fullName": "Fabio Andres Vergara Castaño",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Tecnico Junior",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "6/20/2018 8:25:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002558",
        "document": "1073520368",
        "fullName": "Juan David Useche Bolaños",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Fumigador",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "6/25/2018 1:19:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000058",
        "document": "98562338",
        "fullName": "Elkin Dario Grisales Franco",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Mensajero",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Servicio Compartidos",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006371",
        "document": "94041482",
        "fullName": "BC Carlos Eduardo Tamayo Culman (Shared)",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider De Compras",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Compras",
        "fechaIngreso": "6/27/2018 11:52:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007493",
        "document": "43979826",
        "fullName": "Maria Eugenia Garcia Guerra",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Cad",
        "uen": "Corporativo Secretaría Gral Y Jurídica",
        "process": "Secretaría General Y Jurídica",
        "area": "Cad",
        "fechaIngreso": "7/3/2018 2:11:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002801",
        "document": "1017221440",
        "fullName": "Stefania España Velez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Suministros Y Servicios",
        "fechaIngreso": "7/4/2018 7:06:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002420",
        "document": "1113302540",
        "fullName": "Francisco Javier Tabares Vasquez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/28/2018 6:59:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003276",
        "document": "1099211880",
        "fullName": "Fernando Peña Ruiz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Comercial",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "7/6/2018 10:22:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002237",
        "document": "1005272590",
        "fullName": "Oscar Javier Prada Martinez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Almacenista",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "7/7/2018 4:55:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002570",
        "document": "1127340239",
        "fullName": "Dioney Alexander Diaz Amorocho",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/7/2018 5:25:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002456",
        "document": "1102383199",
        "fullName": "Angie Natalia Vargas Mantilla",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion_entrenamiento",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/10/2018 8:27:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00140",
        "document": "1085251500",
        "fullName": "Luis Carlos Quenguan",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/7/2018 4:16:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002365",
        "document": "1098818140",
        "fullName": "Silvia Fernanda Suarez Manrique",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "7/11/2018 10:05:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002370",
        "document": "15682741",
        "fullName": "Alex  Ruiz Martinez",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "7/12/2018 5:01:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002206",
        "document": "19710447",
        "fullName": "Lenis  Galvan Davila",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/12/2018 5:01:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002572",
        "document": "1095830923",
        "fullName": "Karen Lizeth Carreño Barrera",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "7/12/2018 5:01:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002322",
        "document": "1099622403",
        "fullName": "Jose Ruben Rojas Rojas",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/12/2018 5:01:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002458",
        "document": "1127923144",
        "fullName": "Luis Javier Aguilar Guerrero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Maquinas",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "7/12/2018 7:59:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002461",
        "document": "1101521030",
        "fullName": "Omar Yesid Diaz Pinzon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "7/12/2018 8:29:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002006",
        "document": "1096032941",
        "fullName": "Manuel Jose Agredo Guevara",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "3/13/2018 6:05:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002281",
        "document": "1101520181",
        "fullName": "Orlando  Pinzon Serrano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/12/2018 8:59:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002323",
        "document": "1098787657",
        "fullName": "BC Brayan Javier Ardila Contreras (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Nomina",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/12/2018 9:29:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002389",
        "document": "91079380",
        "fullName": "Oscar Mauricio Silva Galvis",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/12/2018 9:59:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002128",
        "document": "1095701935",
        "fullName": "Andres Ricardo Ardila Bayona",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "7/12/2018 9:59:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002576",
        "document": "77166890",
        "fullName": "John Carlos Sepulveda Sepulveda",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "7/13/2018 1:20:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002568",
        "document": "91538338",
        "fullName": "Edwin  Peña Castañeda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "7/13/2018 1:20:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003488",
        "document": "1128433887",
        "fullName": "Noreymys  Vargas Rivera",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Asistente Comercial",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "7/12/2018 9:29:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002623",
        "document": "73350506",
        "fullName": "Jose Felix Martinez Marrugo",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Aux De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "7/19/2018 8:12:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002624",
        "document": "1128058782",
        "fullName": "Fabian  Camelo Martinez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Aux De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "7/19/2018 8:12:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002093",
        "document": "91356246",
        "fullName": "Fredy Jaimes Murallas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/19/2018 9:42:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002258",
        "document": "91160968",
        "fullName": "Argenis Ariza Castro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Cargue",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/19/2018 10:12:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002080",
        "document": "1102720699",
        "fullName": "Alexsander Niño Sabata",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/19/2018 10:12:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002752",
        "document": "1098744101",
        "fullName": "Miguel Angel Santos Santos",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Puntos De Venta",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "7/23/2018 1:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259104",
        "document": "1115070310",
        "fullName": "Luis Eduardo Florez Collazo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder 3",
        "fechaIngreso": "11/24/2017 4:45:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002686",
        "document": "1116251979",
        "fullName": "Hector Fabio Arias Romero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "7/24/2018 3:16:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002634",
        "document": "1115086337",
        "fullName": "Luis Antonio Dominguez Villegas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "7/24/2018 3:46:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002635",
        "document": "1115075637",
        "fullName": "Harrinson Eduardo Gonzalez Morales",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "7/24/2018 4:13:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002630",
        "document": "1004711970",
        "fullName": "Julio Arturo Rengifo Salas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "7/24/2018 4:13:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002422",
        "document": "1115090913",
        "fullName": "Alvaro Alejandro Zipa Orozco",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/28/2018 6:59:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002426",
        "document": "6445815",
        "fullName": "Jesus Hernan Rodriguez Rivera",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/28/2018 7:29:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1178726",
        "document": "1039699938",
        "fullName": "Freyder Mauricio Ortega Zapata",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Datos Maestros",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1190428",
        "document": "1035876601",
        "fullName": "Carlos Alberto Salgado Berna",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "6/3/2017 5:53:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002616",
        "document": "1096233018",
        "fullName": "Laura Marcela Pinzon Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Logistica Rotante",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "7/27/2018 9:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259552",
        "document": "1115075009",
        "fullName": "Victor Andres Fonseca Palomino",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002663",
        "document": "27978419",
        "fullName": "Celmira  Mantilla Rincon",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 5:13:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002656",
        "document": "37514866",
        "fullName": "Beatriz  Barragan Ortiz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 5:13:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002668",
        "document": "28224854",
        "fullName": "Eva  Jurado Leon",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 6:47:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002647",
        "document": "1035417417",
        "fullName": "Emilce  Becerra Pinto",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/2/2018 6:47:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002651",
        "document": "63493349",
        "fullName": "Orfelina  Quecho Vargas",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 7:14:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002665",
        "document": "27978148",
        "fullName": "Maria Engracia Almeida Muñoz",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 7:14:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002657",
        "document": "63518878",
        "fullName": "Martha Yaneth Rondon Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 7:14:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002667",
        "document": "28089859",
        "fullName": "Herminia  Bayona Rodriguez",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 7:14:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002664",
        "document": "27978111",
        "fullName": "Olinda  Cabrera Pedraza",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 7:14:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002654",
        "document": "52888423",
        "fullName": "Kerly Joanna Velandia",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 7:14:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002662",
        "document": "27978765",
        "fullName": "Gloria  Baron Silva",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 7:14:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002661",
        "document": "1098356470",
        "fullName": "Ofelia  Castro Pimiento",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/2/2018 7:14:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002710",
        "document": "91466010",
        "fullName": "Alvaro Sandoval Porras",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "8/6/2018 7:58:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002730",
        "document": "1098130909",
        "fullName": "Luis Armando Calderon Cuadros",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "8/8/2018 7:34:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002727",
        "document": "1024480537",
        "fullName": "Cristian Alexander Garcia Rubio",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Domiciliario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/9/2018 4:07:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002747",
        "document": "1096228612",
        "fullName": "Cindy Katherine Soler Alarcon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Vinculación Y Seguridad Social",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "8/13/2018 2:49:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002809",
        "document": "43915894",
        "fullName": "Angee Catalina Tangarife Serna",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "8/14/2018 4:22:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002626",
        "document": "1053830688",
        "fullName": "Natalia Andrea Velasquez Quintero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "7/19/2018 8:12:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002731",
        "document": "1114451763",
        "fullName": "Luz Elida Carabali Guancha",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Salud Ocupacional",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "8/16/2018 3:59:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002428",
        "document": "1115085148",
        "fullName": "Andres Felipe Meza Martinez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/28/2018 7:59:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002419",
        "document": "75147007",
        "fullName": "Jorge Ivan Marin Chavez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/28/2018 6:59:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002759",
        "document": "1098631976",
        "fullName": "Carlos Orlando Sanabria Ortiz",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "8/21/2018 9:54:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002740",
        "document": "1017189859",
        "fullName": "Edgar Mauricio Montoya Mazo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "8/23/2018 4:58:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002429",
        "document": "1114063730",
        "fullName": "Camilo Jose Monroy Rojas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "5/28/2018 7:59:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002749",
        "document": "1007379561",
        "fullName": "Cristhian Andres Rosas Rodriguez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "8/23/2018 8:47:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002423",
        "document": "1115084525",
        "fullName": "Cristian David Escobar Perez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/28/2018 4:29:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002436",
        "document": "1112879393",
        "fullName": "Diego Fernando Villegas Giraldo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "5/28/2018 7:59:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002441",
        "document": "94474756",
        "fullName": "Darwin Ancizar Velasco Lopez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/28/2018 7:59:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002778",
        "document": "1076323615",
        "fullName": "Herman Horney Romaña Murillo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Telecomunicaciones",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Conectividad",
        "fechaIngreso": "8/27/2018 5:57:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0364",
        "document": "1042061077",
        "fullName": "Eliana Alejandra Zapata Cardona",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliara De Cartera",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/19/2017 5:47:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002799",
        "document": "1102389333",
        "fullName": "Asdrubal Garnica Alvarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/4/2018 3:54:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002761",
        "document": "79135694",
        "fullName": "Jorge Hernando Murcia Murcia",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "9/4/2018 10:27:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003040",
        "document": "1098697752",
        "fullName": "Liliana Mayerly Mariño Becerra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "9/6/2018 7:33:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002791",
        "document": "1098795814",
        "fullName": "Ivan  Ramirez Marin",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "9/7/2018 2:36:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006579",
        "document": "1115081487",
        "fullName": "Maryuri Orozco Atheortua",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/12/2018 4:46:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002818",
        "document": "1101758932",
        "fullName": "Brayan David Duarte",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Coordinador Abastecimiento",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/12/2018 6:54:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002425",
        "document": "1115065464",
        "fullName": "Diego Fernando Gonzalez Morales",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "5/28/2018 7:29:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006813",
        "document": "76297438",
        "fullName": "Victor Hugo Cruz Narvaez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Inventarios",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "9/20/2018 4:59:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1166949",
        "document": "1000453408",
        "fullName": "Ramiro De Jesus Hernandez Vargas",
        "company": "Solla",
        "regional": "Betu",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "9/8/2017 5:35:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002899",
        "document": "37513855",
        "fullName": "Laura Maria Bedoya Cipagauta",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Director Talento Humano",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "9/27/2018 1:08:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0368",
        "document": "1105671130",
        "fullName": "Juan Fernando Cardoso Bautista",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar Cedi",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/20/2018 1:38:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003030",
        "document": "1049631261",
        "fullName": "Diana Carolina Medina Urian",
        "company": "Distraves",
        "regional": "Tunj",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "9/29/2018 2:55:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1444600",
        "document": "1098702305",
        "fullName": "Robinson Damian Ayala Duran",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:11:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003232",
        "document": "1098791444",
        "fullName": "Cristian Sneyder Becerra Gutierrez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Contabilidad",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Contabilidad",
        "fechaIngreso": "10/4/2018 8:43:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003254",
        "document": "1102371521",
        "fullName": "Luisa Fernanda Garcia Basto",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Jefe Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "10/11/2018 7:32:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003028",
        "document": "85462333",
        "fullName": "Manuel Ramon Padilla Rangel",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "10/12/2018 2:05:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002941",
        "document": "1098745178",
        "fullName": "Hugo Jonathan Pita Angarita",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Mantenimiento",
        "uen": "Comercial",
        "process": "Direccion Uen",
        "area": "Direccion Uen",
        "fechaIngreso": "10/16/2018 4:42:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006454",
        "document": "1151953121",
        "fullName": "Fredy Mauricio Rengifo Mera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider De Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "10/18/2018 7:25:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002933",
        "document": "1020467546",
        "fullName": "Andres Felipe Mira Zapata",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "10/22/2018 1:19:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002926",
        "document": "91354248",
        "fullName": "Alexander  Lancheros Torres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/22/2018 1:19:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002927",
        "document": "13538216",
        "fullName": "Javier Gonzalo Arciniegas Salamanca",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/22/2018 1:48:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002889",
        "document": "37531516",
        "fullName": "Viviana  Rivero Bayona",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/22/2018 2:19:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002873",
        "document": "80114350",
        "fullName": "Ricardo Andres Paiba Barrera",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/22/2018 2:19:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002907",
        "document": "13536102",
        "fullName": "Rogelio Manuel Tovar Martinez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/22/2018 2:19:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002937",
        "document": "88179963",
        "fullName": "Luis Eduardo Ibarra Ortiz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "10/22/2018 2:19:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002908",
        "document": "1100964963",
        "fullName": "Paula Andrea Gonzalez Peña",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asistente Produccion Pecuaria",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/22/2018 2:19:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002886",
        "document": "91474487",
        "fullName": "Oscar Mauricio Figueroa Amador",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/22/2018 2:19:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002954",
        "document": "1128454695",
        "fullName": "Natalia Andrea Buitrago Gomez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Importaciones",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Internacionales",
        "area": "Comercio Exterior",
        "fechaIngreso": "10/25/2018 8:22:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002981",
        "document": "1102351937",
        "fullName": "Diego Armando Medina Mantilla",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/29/2018 9:20:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002982",
        "document": "91347466",
        "fullName": "Jose Juan Duran Larrota",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/29/2018 9:20:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002976",
        "document": "91525238",
        "fullName": "Rafael Leonardo Ardila",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/29/2018 9:50:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0336",
        "document": "1020408454",
        "fullName": "Viviana Andrea Herrera Betancur",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Analista",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/31/2018 9:10:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006718",
        "document": "94317260",
        "fullName": "Mauricio Gomez Dominguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Director Logistico",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "11/2/2018 2:02:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002995",
        "document": "1151943009",
        "fullName": "Vanessa  De La Cruz Gutierrez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Jefe Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "11/9/2018 7:02:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006836",
        "document": "1113642759",
        "fullName": "Alvaro Javier Dominguez Ramirez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "11/13/2018 7:09:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003270",
        "document": "1098682380",
        "fullName": "Brighith Lisseth Ayala Castellanos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Procesos",
        "uen": "Gerencia General",
        "process": "Gerencia General",
        "area": "Gerencia General",
        "fechaIngreso": "11/13/2018 8:15:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003253",
        "document": "63525201",
        "fullName": "Diana Yaneth Echeverria Guerrero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "11/15/2018 2:32:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003026",
        "document": "1098358493",
        "fullName": "Jose Daniel Aza Niño",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "11/19/2018 12:59:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003032",
        "document": "8431813",
        "fullName": "Yulio Alirio Arboleda Castrillon",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador(a) Aplicaciones",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Aplicaciones",
        "fechaIngreso": "11/20/2018 3:01:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003034",
        "document": "1036619065",
        "fullName": "Cindy Natalia Bravo Bedoya",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Servicio Al Cliente",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Centro De Servicios",
        "fechaIngreso": "11/20/2018 3:01:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006754",
        "document": "1144157920",
        "fullName": "Cristian David Gutierrez Velasquez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador Logistico",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "11/22/2018 3:07:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003038",
        "document": "1232396119",
        "fullName": "Ricardo Alfonso Ballesteros Lopez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Lider De Transformacion Carnica",
        "uen": "Produccion",
        "process": "Porcionados",
        "area": "Porcionados",
        "fechaIngreso": "11/22/2018 3:07:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003041",
        "document": "1127357702",
        "fullName": "Yasmin Yurley Rincon Suna",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "11/22/2018 3:07:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003039",
        "document": "1098718389",
        "fullName": "BC Amanda Teresa Uribe (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/22/2018 3:07:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002652",
        "document": "37897546",
        "fullName": "Matilde Ballesteros Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "11/22/2018 4:07:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1258034",
        "document": "1115073876",
        "fullName": "Fabian Andres Grajales Ceballos",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/24/2017 4:45:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003051",
        "document": "1053322985",
        "fullName": "Juan Gabriel Castellanos Velandia",
        "company": "Distraves",
        "regional": "Chiq",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "11/27/2018 2:31:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003055",
        "document": "74084348",
        "fullName": "Sergio Martin Suarez Gaucha",
        "company": "Distraves",
        "regional": "Tunj",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "11/27/2018 2:31:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003060",
        "document": "1102357036",
        "fullName": "Jonathan Mauricio Villamizar Medina",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/27/2018 2:31:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002896",
        "document": "1098200449",
        "fullName": "Brayan Stiven Rueda Mora",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/3/2018 7:41:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006365",
        "document": "1118293602",
        "fullName": "Luis Guillermo Soto Capote",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Inventario Gral",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "11/28/2018 11:52:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003082",
        "document": "1102350153",
        "fullName": "Victor Alfonso Ramirez Diaz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/3/2018 5:02:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003084",
        "document": "1098358673",
        "fullName": "Karen Lizeth Avellaneda Rojas",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "12/3/2018 5:02:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003089",
        "document": "1098602847",
        "fullName": "Luis Carlos Florez Paez",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/3/2018 5:02:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003138",
        "document": "1040748741",
        "fullName": "BC Angelica Barrientos Merchant (Shared)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Auditoria",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "12/11/2018 7:44:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003135",
        "document": "1102382390",
        "fullName": "Brayan Yesid Arismendi Serrano",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Planeador De La Demanda",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "12/11/2018 9:14:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003087",
        "document": "1098675134",
        "fullName": "Gabriel Ernesto Rueda Carreño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/12/2018 10:47:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003124",
        "document": "1096618103",
        "fullName": "Ismael  Murillo Diaz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "12/12/2018 10:47:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003125",
        "document": "91536246",
        "fullName": "Yesid Laureano Mantilla Calderon",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/12/2018 10:47:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003147",
        "document": "1098688434",
        "fullName": "Hefzi Katherine Sepulveda Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Direccion Produccion Industrial",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "12/13/2018 10:20:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003149",
        "document": "1101596329",
        "fullName": "Ana Elda Gomez Torres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/14/2018 2:52:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003157",
        "document": "1096953666",
        "fullName": "Rosa Amelia Castro Mendez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/14/2018 3:35:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0370",
        "document": "115084260",
        "fullName": "Alvaro Jose Cossio Cano",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/11/2018 2:00:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001584",
        "document": "1098756827",
        "fullName": "Karol Geraldine Niño Rueda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Compras",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Compras",
        "fechaIngreso": "12/20/2018 4:40:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003203",
        "document": "1096244060",
        "fullName": "Andres Felipe Angulo Lopez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "12/28/2018 4:02:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00086",
        "document": "1115094012",
        "fullName": "Luis Fernando Salgado Bedoya",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/19/2018 3:06:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006370",
        "document": "1113523840",
        "fullName": "Catherine Viviana Tacan Pascuaza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Tesoreria",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Tesoreria Y Riesgos",
        "fechaIngreso": "1/2/2019 5:54:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003180",
        "document": "43203066",
        "fullName": "Sindy Magally Celis",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Secretaria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "12/21/2018 1:13:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006354",
        "document": "1113522332",
        "fullName": "Jhon Edison Sanchez Lopez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Post Proceso",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/11/2019 7:11:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006505",
        "document": "31320284",
        "fullName": "BC Nasly Villarreal Cardenas (Shared)",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Director Administrativo Y Financiero",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Direccion Administracion Y Financiera",
        "fechaIngreso": "1/14/2019 6:40:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003273",
        "document": "1052970665",
        "fullName": "Yeimis Paola Torres Arrieta",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "1/14/2019 8:12:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003228",
        "document": "1095812158",
        "fullName": "Jesus Alberto Gonzalez Plata",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/15/2019 7:05:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003239",
        "document": "1093774834",
        "fullName": "Luis Eduardo Bochaga Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "1/15/2019 7:05:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003261",
        "document": "37619923",
        "fullName": "Liliana Marcela Rueda Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/15/2019 7:05:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003216",
        "document": "91354007",
        "fullName": "Fredy Alonso Rodriguez Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/15/2019 8:16:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0372",
        "document": "1152186251",
        "fullName": "Carlos Augusto Monsalve Lopez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar Contable",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/18/2019 12:46:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002930",
        "document": "1098723537",
        "fullName": "Ana Lucia Silva Pardo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/18/2019 5:01:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002442",
        "document": "94482994",
        "fullName": "Marvin Alexis Ramos Hernandez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/28/2018 7:59:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003282",
        "document": "1015278080",
        "fullName": "Andres Yecid Ospina Ruiz",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/21/2019 7:32:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003285",
        "document": "1038360230",
        "fullName": "John Estiven Alvarez Alvarez",
        "company": "Solla",
        "regional": "Entr",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "1/21/2019 7:32:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003295",
        "document": "1113656321",
        "fullName": "Daniela  Caicedo Molina",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "1/29/2019 2:48:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002404",
        "document": "1036395484",
        "fullName": "Maria Clara Arango Duque",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Promocion Y Activacion",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Activación",
        "fechaIngreso": "5/10/2018 7:27:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003312",
        "document": "37619908",
        "fullName": "Nardis Fernanda Diaz Quintero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/31/2019 4:32:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003310",
        "document": "1102355461",
        "fullName": "Deysi Nayibe Becerra Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/31/2019 4:32:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003294",
        "document": "13874111",
        "fullName": "Rafael  Angel Moreno",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "1/31/2019 4:32:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1275097",
        "document": "1115068713",
        "fullName": "Juan Camilo Morales Quintero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "9/28/2017 7:57:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006668",
        "document": "16724947",
        "fullName": "Aldemar Martinez Ramirez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "2/5/2019 12:39:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003322",
        "document": "37747327",
        "fullName": "Adriana Gomez Parra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Nomina",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "2/5/2019 1:45:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002678",
        "document": "27978166",
        "fullName": "Cristina Ortiz Velasquez",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/5/2019 9:16:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002669",
        "document": "37893624",
        "fullName": "Florinda Rueda Conde",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/5/2019 9:16:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003597",
        "document": "1098705602",
        "fullName": "Leidy Andrea Aceros Chaparro",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Nomina",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "2/6/2019 1:14:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002690",
        "document": "1073517524",
        "fullName": "Ana Lizeth Rodriguez Peña",
        "company": "Transgraneles",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "7/31/2018 9:37:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003219",
        "document": "91533410",
        "fullName": "Jahir Andres Rincon Plata",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/6/2019 7:57:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002826",
        "document": "91452558",
        "fullName": "Daniel Suarez Murillo",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/8/2019 3:10:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003367",
        "document": "32885794",
        "fullName": "Gloriana  Osorio Vergara",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "2/11/2019 5:14:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003370",
        "document": "1045695342",
        "fullName": "David Daniel Cairoza De Moya",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "2/11/2019 5:14:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003327",
        "document": "13538865",
        "fullName": "Jose Alejandro Duran Romero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/11/2019 8:44:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006274",
        "document": "33354561",
        "fullName": "Liliana Patricia Aguas Mendoza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "2/12/2019 2:49:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003333",
        "document": "1098759859",
        "fullName": "Julio Andres Jimenez Contreras",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/12/2019 2:46:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003402",
        "document": "1095814285",
        "fullName": "Karen Viviana Piñeres Avila",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Formacion, Desarrollo Y Bienestar",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/14/2019 2:22:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003409",
        "document": "91326859",
        "fullName": "Saul Enrique Jimenez Reyes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "2/18/2019 10:05:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003384",
        "document": "94483229",
        "fullName": "Cristian Andres Garcia Roldan",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/15/2019 3:25:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003396",
        "document": "1115062202",
        "fullName": "Alexander  Barona Reyes",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "2/15/2019 3:25:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003392",
        "document": "1115070297",
        "fullName": "Edison Fernando Gonzalez Moya",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "2/15/2019 3:25:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003391",
        "document": "1115066028",
        "fullName": "Jhonatan  Arboleda Cortez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/15/2019 3:25:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1377110",
        "document": "1073176378",
        "fullName": "Jose Elkin Moreno Gonzalez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "2/13/2018 1:39:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001863",
        "document": "1098777550",
        "fullName": "BC Norma Isabel Guerrero Contreras (Retirada)",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider Ambiental",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "2/19/2019 3:31:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003380",
        "document": "94476032",
        "fullName": "Jarrison  Ruiz Mendoza",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Dosificación Y Mezcla Mascotas",
        "fechaIngreso": "2/15/2019 3:25:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003394",
        "document": "1114061722",
        "fullName": "Cristhian Mauricio Pineda Figueroa",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/15/2019 3:25:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002309",
        "document": "1017248238",
        "fullName": "Juan Fernando Gomez Valencia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Costos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "4/19/2018 2:30:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001774",
        "document": "5690496",
        "fullName": "Guillermo Cuevas Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/25/2019 2:13:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003452",
        "document": "1015406565",
        "fullName": "BC Michael Darnyi Arias Molina (Shared)",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Supervisor Puntos De Venta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/26/2019 1:05:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003451",
        "document": "1101596387",
        "fullName": "Alecsi  Caceres Caceres",
        "company": "Distraves",
        "regional": "Elpl",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/26/2019 2:35:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003462",
        "document": "28351663",
        "fullName": "Ana Milena Rojas Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/26/2019 2:35:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002008",
        "document": "91273682",
        "fullName": "German  Ramirez Castro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "3/16/2018 6:55:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001080",
        "document": "1103692172",
        "fullName": "Luis Enrique Villabona Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/27/2019 2:07:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006666",
        "document": "1065615391",
        "fullName": "Juan David Martinez Colmenares",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Director De Produccion",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "2/28/2019 2:15:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1124745",
        "document": "1082843079",
        "fullName": "Angel Alberto Sierra Salgado",
        "company": "Solla",
        "regional": "Sanm",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/25/2017 5:39:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116849",
        "document": "1044504511",
        "fullName": "Jorge Luis Muñoz Posada",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Ganadería",
        "fechaIngreso": "5/24/2017 2:13:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9014",
        "document": "01234567",
        "fullName": "Porteria Solla Giron",
        "company": "Solla",
        "regional": "",
        "jobTitle": "Porteria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/24/2017 1:47:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1469950",
        "document": "1095803954",
        "fullName": "Gerson Davian Mendivelso Betancourt",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:11:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1247001",
        "document": "14885344",
        "fullName": "Orlando  Azcarate Garcia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0025",
        "document": "91346020",
        "fullName": "Rodolfo Chanaga S.",
        "company": "Temporales",
        "regional": "Giron",
        "jobTitle": "Auxiliar De Aseo Y Cafetería",
        "uen": "-",
        "process": "Aginal Cartera",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002434",
        "document": "1115077410",
        "fullName": "Jairo Andres Cuellar Bueno",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "5/28/2018 4:59:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1195241",
        "document": "71740847",
        "fullName": "Roberto Herbey Urrego Barrientos",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0228",
        "document": "87061032",
        "fullName": "Mario Andres Diaz Chachinoy",
        "company": "Temporales",
        "regional": "Pasto",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1383137",
        "document": "1073235032",
        "fullName": "Nelson Jesus Parra Herrera",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1166940",
        "document": "71877183",
        "fullName": "Rodimiro De Jesus Higuita Alcaraz",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002285",
        "document": "42687496",
        "fullName": "Liliana Maria Del Socorro Uribe Sepulveda",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Aseo Y Cafeteria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "4/10/2018 2:21:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0030",
        "document": "32070028",
        "fullName": "Pricila  Goez L",
        "company": "Temporales",
        "regional": "Itag",
        "jobTitle": "Ventas",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143450",
        "document": "10888586",
        "fullName": "Julio Cesar Alian Ortega",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Ganadería",
        "fechaIngreso": "5/24/2017 2:13:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1172243",
        "document": "1048601893",
        "fullName": "Francisco Javier Llerena Benavides",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "11/27/2017 5:55:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000051",
        "document": "70138378",
        "fullName": "Omar De Jesus Muñoz Muñeton",
        "company": "Solla",
        "regional": "Barb",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002934",
        "document": "1102380936",
        "fullName": "Helbert German Suarez Gualdron",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/22/2018 1:19:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0269",
        "document": "1115076706",
        "fullName": "Bryan Duque Corredor",
        "company": "Temporales",
        "regional": "Buga",
        "jobTitle": "Auxiliar Producción",
        "uen": "-",
        "process": "",
        "area": "Produccion",
        "fechaIngreso": "5/24/2017 2:15:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1172801",
        "document": "71493446",
        "fullName": "Jose Gabriel Maldonado Vergara",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259551",
        "document": "94473430",
        "fullName": "Antonio Jose Franco Restrepo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Dosificación Y Mezcla Mascotas",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0351",
        "document": "1085316052",
        "fullName": "David Eduardo Erazo Tapia",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/7/2018 4:45:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "S1137906",
        "document": "12020310",
        "fullName": "Harold Velez Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Impuestos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/24/2017 2:11:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1172481",
        "document": "75033699",
        "fullName": "Javier Enrique Londoño Perez",
        "company": "Solla",
        "regional": "Gome",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0165",
        "document": "4281668",
        "fullName": "Dairo Alexander Velasquez Corredor",
        "company": "Temporales",
        "regional": "Mosquera",
        "jobTitle": "-",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1188050",
        "document": "1015277533",
        "fullName": "Oscar Daniel Rico Rico",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:11:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1196597",
        "document": "1035850172",
        "fullName": "Carlos Mario Valencia Valencia",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003416",
        "document": "11445441",
        "fullName": "Ismer  Pedrozo Centeno",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "2/15/2019 3:25:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0114",
        "document": "1115082563",
        "fullName": "Ivan Mauricio Hernandez Perez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/26/2018 9:01:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291002",
        "document": "94481317",
        "fullName": "Carlos Arturo Saenz Arenas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder 3",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1185741",
        "document": "71271575",
        "fullName": "David Restrepo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1118364",
        "document": "43183675",
        "fullName": "Mary Luz Jaramillo Pasos",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "10/23/2017 10:58:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0173",
        "document": "",
        "fullName": "Lina Maria Cardenas Barbosa",
        "company": "Temporales",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1193100",
        "document": "71877076",
        "fullName": "Luis Fernando Tamayo Cadavid",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1406753",
        "document": "1095910376",
        "fullName": "Andres Fabianny Acacio Quintero",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Plantas Asesoradas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "9/27/2017 5:38:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143562",
        "document": "1020396502",
        "fullName": "Fabian Esteban Agudelo Tamayo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0061",
        "document": "13871411",
        "fullName": "Julian Andres Osuna Mantilla",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Portero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/24/2017 2:15:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1417509",
        "document": "1095950005",
        "fullName": "Cristhian Andres Moreno Villamizar",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1291515",
        "document": "1114451390",
        "fullName": "Jorge Luis Velez Mora",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "5/24/2017 2:14:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1305049",
        "document": "1070961678",
        "fullName": "Anguie Xiomara Amaya H.",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Supernumerario",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "5/24/2017 2:10:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002199",
        "document": "13638232",
        "fullName": "Edgar Omar Gomez Gomez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "4/17/2018 2:29:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0117",
        "document": "",
        "fullName": "Carlos David Echeverry Correa",
        "company": "Temporales",
        "regional": "Giron",
        "jobTitle": "Tecnico Junior / Balanceados G",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1262491",
        "document": "1115082584",
        "fullName": "Andres Felipe Garcia Bejarano",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1178725",
        "document": "1054551210",
        "fullName": "Jesus Alberto Orozco",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0353",
        "document": "1085307627",
        "fullName": "Wilmer Alexis Florez Botina",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/7/2018 4:45:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1190422",
        "document": "71715781",
        "fullName": "Alfonso Salcedo Agudelo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002235",
        "document": "1053828400",
        "fullName": "Mariana  Medina Arango",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "3/16/2018 5:25:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1284510",
        "document": "14888159",
        "fullName": "Jaime Augusto Rendon Arias",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000234",
        "document": "3599678",
        "fullName": "Omar Andres Gutierrez Tabares",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Ganadería",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0118",
        "document": "",
        "fullName": "Lucila Prada Sanabria",
        "company": "Temporales",
        "regional": "Giron",
        "jobTitle": "Auxiliar De Aseo Y Cafeteria",
        "uen": "-",
        "process": "Auxiliar Aseo Y Cafetería",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "2423819",
        "document": "91492819",
        "fullName": "Roman Gabriel Mendez Ardila",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Mensajero Conductor",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000187",
        "document": "1113037303",
        "fullName": "Luis Orlando Berrio Diaz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:10:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283004",
        "document": "1115065852",
        "fullName": "Braulio Alexis Plaza Trochez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "5000150",
        "document": "3556434",
        "fullName": "Oscar David Gonzalez Ramirez",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Ganadería",
        "fechaIngreso": "5/24/2017 2:15:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0194",
        "document": "1020418739",
        "fullName": "Kely Tatiana Diaz Espinal",
        "company": "Temporales",
        "regional": "Itag",
        "jobTitle": "Auxiliar Administrativa",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1188630",
        "document": "8151540",
        "fullName": "Jhon Jairo Rivillas Betancur",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003423",
        "document": "1070948111",
        "fullName": "Adonai  Castañeda Hernandez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/15/2019 3:25:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9015",
        "document": "01234568",
        "fullName": "Porteria Solla Bello Norte",
        "company": "Solla",
        "regional": "",
        "jobTitle": "Porteria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/5/2017 3:55:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0409",
        "document": "1070991376",
        "fullName": "Maria Camila Amaya Hernandez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar Temporal",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/18/2018 1:03:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0195",
        "document": "1085255451",
        "fullName": "Diego Armando Juajinoy Argoty",
        "company": "Temporales",
        "regional": "Buga",
        "jobTitle": "Peletizado",
        "uen": "-",
        "process": "",
        "area": "Produccion",
        "fechaIngreso": "5/24/2017 2:15:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0356",
        "document": "1114450368",
        "fullName": "Cristian Gerardo Castro Martinez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/8/2018 7:07:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1172240",
        "document": "70726985",
        "fullName": "John Fredy Jurado Grisales",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002856",
        "document": "15265391",
        "fullName": "Juan Pablo Lopez Martinez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "9/26/2018 9:36:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00350",
        "document": "98399935",
        "fullName": "Nelson Fabian Rosero Peña",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/7/2018 4:45:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1386802",
        "document": "93011424",
        "fullName": "Jesus David Sanchez Vallejo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0120",
        "document": "",
        "fullName": "Luger Enrrique Collazos Araque",
        "company": "Temporales",
        "regional": "Giron",
        "jobTitle": "Bodeguero",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1284400",
        "document": "14890274",
        "fullName": "Alexis Ramirez Diaz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1292513",
        "document": "1115070186",
        "fullName": "Cristhian David Villada Castro",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/24/2017 5:15:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0166",
        "document": "79318104",
        "fullName": "Manuel Jose Delgado ramirez",
        "company": "Temporales",
        "regional": "Mosquera",
        "jobTitle": "-",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0183",
        "document": "39456540",
        "fullName": "Lina Marcela Salazar Marin",
        "company": "Temporales",
        "regional": "Itag",
        "jobTitle": "Impulsador Profesional",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1333442",
        "document": "1070964240",
        "fullName": "Julian Smith Segura Mican",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:14:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1148850",
        "document": "98580706",
        "fullName": "Rafael Mario Betancur Gomez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000202",
        "document": "14890591",
        "fullName": "German  Gallego Alzate",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "5/24/2017 2:15:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0129",
        "document": "12345678",
        "fullName": "Porteria Solla Itagui",
        "company": "Solla",
        "regional": "",
        "jobTitle": "Portero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/24/2017 2:15:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9017",
        "document": "01234561",
        "fullName": "Porteria Solla Mosquera",
        "company": "Solla",
        "regional": "",
        "jobTitle": "Porteria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/5/2017 8:51:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002858",
        "document": "10821156",
        "fullName": "Oscar Emilio Barrios Caraballo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "9/26/2018 9:06:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0174",
        "document": "1116255194",
        "fullName": "Maria Camila Restrepo Jaramillo",
        "company": "Temporales",
        "regional": "Mosquera",
        "jobTitle": "Impulsador Profesional",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002431",
        "document": "94481979",
        "fullName": "Miguel Eduardo Alvarez Rey",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "5/28/2018 7:59:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1118351",
        "document": "98521748",
        "fullName": "Carlos Mario Hernandez Colorado",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002836",
        "document": "1026138808",
        "fullName": "Jeison Gabriel Vargas Agudelo",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "10/5/2018 12:45:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1165400",
        "document": "1015276698",
        "fullName": "Jhon Fredy Gomez Usma",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0072",
        "document": "",
        "fullName": "Heberk Alberto Rojas",
        "company": "Temporales",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "-",
        "process": "Auxiliar De Produccion",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9016",
        "document": "01234569",
        "fullName": "Porteria Solla Bello Sur",
        "company": "Solla",
        "regional": "",
        "jobTitle": "Porteria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/5/2017 4:02:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1383172",
        "document": "1073427476",
        "fullName": "Fredy Alonso Ramos Sanchez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1175812",
        "document": "70221136",
        "fullName": "Diego Edison Moncada Rico",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "5/24/2017 2:13:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1231101",
        "document": "6321033",
        "fullName": "Jhon Eider Rivera Arias",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "5/24/2017 2:15:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0037",
        "document": "75092599",
        "fullName": "Jhon Jairo Corredor Potosi",
        "company": "Temporales",
        "regional": "Itag",
        "jobTitle": "Técnico De Ventas",
        "uen": "-",
        "process": "Técnico De Ventas",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00121",
        "document": "1085332045",
        "fullName": "Anderson  Delgado Delgado",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/7/2018 4:45:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1159250",
        "document": "8039766",
        "fullName": "Fredys Omar Florez Madrigal",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1196800",
        "document": "1044505173",
        "fullName": "Jesus Esteban Zapata Zapata",
        "company": "Solla",
        "regional": "Gome",
        "jobTitle": "Asistente Operativo De Granjas",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1193800",
        "document": "71491653",
        "fullName": "Alvaro De Jesus Tejada Lopez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1196703",
        "document": "1125474951",
        "fullName": "Andres Felipe Vidales Vasquez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:15:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1249594",
        "document": "1115084361",
        "fullName": "Carlos Andres Bolaños Corrales",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:10:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1225020",
        "document": "94474832",
        "fullName": "Jorge Eliecer Muñoz Robledo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1390893",
        "document": "1073427655",
        "fullName": "Elkin Johann Velandia Diaz",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "9/26/2017 5:56:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1259550",
        "document": "14883545",
        "fullName": "Orlando Franco Isaza",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00128",
        "document": "1085282699",
        "fullName": "Francisco Javier Chincha Paz",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/7/2018 4:45:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0076",
        "document": "16077964",
        "fullName": "Ivan Fernando Valencia O",
        "company": "Temporales",
        "regional": "Mosquera",
        "jobTitle": "Representante De Ventas",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0238",
        "document": "1075277736",
        "fullName": "Nelson Andres Ruiz Urueña",
        "company": "Temporales",
        "regional": "Mosq",
        "jobTitle": "Aux. Centro Distribucion",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0075",
        "document": "1005368273",
        "fullName": "Mattiw Alexander Holguin Tabares",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/6/2017 2:15:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1175652",
        "document": "1037592348",
        "fullName": "Orvey De Jesus Mejia Rojas",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/24/2017 2:13:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1175810",
        "document": "71877570",
        "fullName": "Luis Fernando Molina Palacio",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1143556",
        "document": "1128424252",
        "fullName": "Omar Alberto Agudelo Lopez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0098",
        "document": "",
        "fullName": "Humberto  Linares C.",
        "company": "Temporales",
        "regional": "Mosquera",
        "jobTitle": "Bodega Honda",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:11:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1196596",
        "document": "71951325",
        "fullName": "Herney De Jesus Valencia Quintero",
        "company": "Solla",
        "regional": "Donm",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "6/20/2017 4:30:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002961",
        "document": "6320261",
        "fullName": "Hector Fabio Libreros Lenis",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "10/29/2018 2:01:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002516",
        "document": "98633366",
        "fullName": "Andres Felipe Moreno Jimenez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/8/2018 2:36:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1104492",
        "document": "70354127",
        "fullName": "Arquimedes  Bedoya Rivas",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/24/2017 2:13:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1157001",
        "document": "71421079",
        "fullName": "Diego De Jesus Deossa Amaya",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:12:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1116622",
        "document": "8152919",
        "fullName": "Nicolas Alirio Gonzalez Builes",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/24/2017 2:13:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0168",
        "document": "1073158868",
        "fullName": "Omar Ferney Rodriguez Rialo",
        "company": "Temporales",
        "regional": "Mosquera",
        "jobTitle": "-",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1289025",
        "document": "94475671",
        "fullName": "Manuel Andres Sarrias Moncada",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:14:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002227",
        "document": "1016100288",
        "fullName": "Diego Alejandro Morales Campos",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar De Estadistica",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "3/16/2018 2:36:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1383142",
        "document": "1070975300",
        "fullName": "Erick Damian Perez Piraneque",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/25/2018 1:50:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "6000460",
        "document": "72297881",
        "fullName": "Sergio David Ariza Annicchiarico",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "5/24/2017 2:15:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003422",
        "document": "81754342",
        "fullName": "Sergio Andres Lozano Suarez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/14/2019 2:22:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0167",
        "document": "1073159515",
        "fullName": "Yudi Carolina Jimenez Valles",
        "company": "Temporales",
        "regional": "Mosquera",
        "jobTitle": "-",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1249593",
        "document": "1115079034",
        "fullName": "Victor Alfonso Betancourt Perez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0241",
        "document": "1115075512",
        "fullName": "Wilder Escobar Diaz",
        "company": "Temporales",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "-",
        "process": "",
        "area": "Produccion",
        "fechaIngreso": "5/24/2017 2:15:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0133",
        "document": "",
        "fullName": "Zenaida Becerra Moncada",
        "company": "Temporales",
        "regional": "Giron",
        "jobTitle": "Auxiliar Cartera Giron",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0304",
        "document": "94482138",
        "fullName": "Víctor Alonso Parra Agudelo",
        "company": "Temporales",
        "regional": "Bell",
        "jobTitle": "Temporal",
        "uen": "-",
        "process": "",
        "area": "Operador Bascula",
        "fechaIngreso": "5/24/2017 2:15:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002514",
        "document": "1035873682",
        "fullName": "William David Rios Castro",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/22/2018 4:22:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003478",
        "document": "29120652",
        "fullName": "Yorlady  Bedoya Heredia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "2/26/2019 1:05:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1247007",
        "document": "94482045",
        "fullName": "Wilson Balanta Wilches",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder 3",
        "fechaIngreso": "5/24/2017 2:13:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0089",
        "document": "1128467054",
        "fullName": "Zehir Albany Arboleda R.",
        "company": "Temporales",
        "regional": "Bell",
        "jobTitle": "Auxiliar Despachos",
        "uen": "-",
        "process": "Auxiliar Despachos",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1205400",
        "document": "94473539",
        "fullName": "Uber Orlando Cardona Palomino",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/24/2017 2:13:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0176",
        "document": "92529527",
        "fullName": "Samir  Mercado Tapia",
        "company": "Temporales",
        "regional": "Giron",
        "jobTitle": "Tecnico Junior Linea Sales",
        "uen": "-",
        "process": "",
        "area": "Administracion",
        "fechaIngreso": "5/24/2017 2:15:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003489",
        "document": "1017181625",
        "fullName": "Daniel Fernando Franco Martinez",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "3/6/2019 11:54:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003465",
        "document": "1102370680",
        "fullName": "Eliseth Katerinne Rincon Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/6/2019 9:45:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003493",
        "document": "1098777800",
        "fullName": "Juan Carlos Mancilla Arias",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "3/7/2019 4:49:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003447",
        "document": "1102378677",
        "fullName": "Jhonatan Augusto Delgado Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Cargue",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/7/2019 4:49:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003474",
        "document": "1065820050",
        "fullName": "Reinel Javier Gutierrez Lopez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/7/2019 5:19:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003459",
        "document": "1093751029",
        "fullName": "Victor Alfonso Bernal Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/7/2019 5:19:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003504",
        "document": "1115268010",
        "fullName": "Julio Alfredo Paez Castaño",
        "company": "Solla",
        "regional": "Gome",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "3/8/2019 12:51:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003506",
        "document": "1098775361",
        "fullName": "Luis Fabian Muñoz Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "3/12/2019 4:23:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003507",
        "document": "1090534770",
        "fullName": "Yelitza Isamar Cardenas Campos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/12/2019 4:23:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003525",
        "document": "1096219835",
        "fullName": "Kevin Andres Atehortua Tellez",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/14/2019 4:58:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003538",
        "document": "24337292",
        "fullName": "Lina Marcela Cardona Alvarez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Impulsador Profesional",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "3/18/2019 12:09:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001895",
        "document": "1140416777",
        "fullName": "Eider Humberto Leal Ramos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/18/2019 4:39:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003358",
        "document": "1099894892",
        "fullName": "Hender Reynaldo Ramirez Barrera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/18/2019 4:39:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003539",
        "document": "1036679101",
        "fullName": "Daniel  Gonzalez Torres",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/19/2019 4:14:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006390",
        "document": "1114059681",
        "fullName": "Manuel Fernando Valencia Llano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider De Almacén",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/19/2019 4:29:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003562",
        "document": "94476298",
        "fullName": "Mauricio Andres Garces Arevalo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "3/27/2019 1:20:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003596",
        "document": "1000570759",
        "fullName": "Sorany Mayaly Vasquez Morales",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Corporativo Transgraneles",
        "process": "Corporativo Transgraneles",
        "area": "Corporativo Transgraneles",
        "fechaIngreso": "4/4/2019 3:36:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002584",
        "document": "37841893",
        "fullName": "Claudia Arenas Barrera",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "4/5/2019 1:39:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003581",
        "document": "1098617368",
        "fullName": "Ivan Mauricio Corredor Castellanos",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Jefe Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "4/5/2019 3:09:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0385",
        "document": "1085309404",
        "fullName": "Jairo Alexander Paz Villareal",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/9/2019 8:42:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005894",
        "document": "1128464359",
        "fullName": "Diana Maritza Tamayo Londoño",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Bacteriologa",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "4/15/2019 1:39:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003601",
        "document": "1095926161",
        "fullName": "Darling Julieth Ribero Duarte",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2019 9:23:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002983",
        "document": "91044567",
        "fullName": "Jorge Enrique Martinez Diaz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "4/16/2019 12:42:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003642",
        "document": "1098721731",
        "fullName": "Jhon Edinson Parada Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Almacenista",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/24/2019 7:42:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003658",
        "document": "1098357056",
        "fullName": "Cristian Ferney Marin Rueda",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "4/26/2019 7:39:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003670",
        "document": "22534550",
        "fullName": "Delcy Maria Carrillo Aguas",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "4/29/2019 9:48:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003675",
        "document": "1118557636",
        "fullName": "Marco Andres Angel Garcia",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/3/2019 9:57:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003679",
        "document": "1099368071",
        "fullName": "Wilson  Riaño Osorio",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "5/6/2019 8:35:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003667",
        "document": "1095789605",
        "fullName": "Daniel Carrillo Rondon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Digitador Facturacion",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/6/2019 9:35:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003681",
        "document": "37559841",
        "fullName": "BC Sandra Juliana Pinilla Garcia (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Materias Primas",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/8/2019 7:48:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1220057",
        "document": "1115087335",
        "fullName": "Juan Camilo Lozano Collantes",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/13/2019 1:43:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003704",
        "document": "1102379265",
        "fullName": "Cesar Augusto Ruiz Villabona",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/13/2019 2:42:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003696",
        "document": "55162999",
        "fullName": "Flor Denys Cruz Montes",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico Institucional",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "5/14/2019 1:43:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003694",
        "document": "1099372357",
        "fullName": "Neyder Armando Gil Ramirez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "5/14/2019 2:43:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1418604",
        "document": "1098702524",
        "fullName": "Anderson Ortiz Esparza",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/14/2019 10:10:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000017",
        "document": "4167526",
        "fullName": "Julio Alejandro Mojica Romero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Veterinario",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "5/15/2019 7:43:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006699",
        "document": "1234188746",
        "fullName": "Alejandro Franco Bahena",
        "company": "Spg",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo Granjas",
        "uen": "",
        "process": "",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/20/2019 7:15:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003743",
        "document": "1098658145",
        "fullName": "Diana  Obando Restrepo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Seleccion Y Contratacion",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/21/2019 2:15:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003755",
        "document": "1098686913",
        "fullName": "Diego Fernando Jimenez Ortega",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Juridico Laboral",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/22/2019 12:28:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003750",
        "document": "63531615",
        "fullName": "Yamile  Garcia Santos",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Director Uen Delecta",
        "uen": "Comercial",
        "process": "Direccion Uen",
        "area": "Direccion Uen",
        "fechaIngreso": "5/22/2019 12:58:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003758",
        "document": "9297556",
        "fullName": "Jose Maria Pacheco Perez",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "5/22/2019 1:28:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003737",
        "document": "1093140167",
        "fullName": "Anibal  Moncada Carrillo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "5/22/2019 1:28:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003748",
        "document": "1102381679",
        "fullName": "Luis Esneyder Navarro Arias",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "5/22/2019 1:28:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003759",
        "document": "1096955187",
        "fullName": "Miguel Gustavo Antolinez Jimenez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Bascula",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "5/23/2019 5:01:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003763",
        "document": "1102719463",
        "fullName": "Jensy Alexander Pinilla Naranjo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/23/2019 5:01:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003778",
        "document": "1102389332",
        "fullName": "Luis Carlos Morales Villamizar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "5/29/2019 2:07:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006556",
        "document": "1024507260",
        "fullName": "Cesar Augusto Muñoz Ortiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Logistica",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/4/2019 12:49:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006582",
        "document": "1144184182",
        "fullName": "Jhon Henry Ortiz Villegas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Logistica",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/4/2019 12:50:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006428",
        "document": "94042295",
        "fullName": "Luis Carlos Pinto Waltero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Logistica",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/4/2019 12:51:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003784",
        "document": "1020724592",
        "fullName": "Deny Johana Martinez Cifuentes",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Impulsador Profesional",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "6/4/2019 1:24:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003786",
        "document": "98584722",
        "fullName": "Juan Carlos Lopez Garcia",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Gerente Procesados",
        "uen": "Comercial",
        "process": "Direccion Uen",
        "area": "Direccion Uen",
        "fechaIngreso": "6/4/2019 5:36:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002251",
        "document": "13542482",
        "fullName": "Ludwing Enrique Rincon Angarita",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "6/5/2019 7:10:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001588",
        "document": "1098760300",
        "fullName": "Lina Marcela Velasquez Mantilla",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Impuestos",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Contabilidad",
        "fechaIngreso": "6/6/2019 1:38:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003800",
        "document": "1115091469",
        "fullName": "Luis Alejandro Bejarano Cardenas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/7/2019 11:42:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003802",
        "document": "1115077087",
        "fullName": "Mario German Bedoya Colmenares",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/7/2019 12:12:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003797",
        "document": "1112956052",
        "fullName": "Yecica  Carmona Cordoba",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "6/7/2019 12:12:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003796",
        "document": "27978965",
        "fullName": "Sandra Milena Moreno Caballero",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "6/10/2019 1:50:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003585",
        "document": "91003929",
        "fullName": "Omar  Blanco Forero",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/15/2019 10:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003831",
        "document": "1104134718",
        "fullName": "Kiara Alejandra Escobar Solano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "6/13/2019 4:16:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003837",
        "document": "1102371811",
        "fullName": "Oscar Fernando Hernandez Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "6/13/2019 8:17:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003844",
        "document": "1102387626",
        "fullName": "Yurley Tatiana Arias Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/13/2019 8:17:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003833",
        "document": "1095925436",
        "fullName": "Nestor Ricardo Nuñez Villamarin",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/13/2019 8:17:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003847",
        "document": "1005109291",
        "fullName": "Greisy Dayanna Rey Barrios",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asistente Autoservicios",
        "uen": "Comercial",
        "process": "Direccion Canal",
        "area": "Direccion Canal",
        "fechaIngreso": "6/13/2019 8:17:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003717",
        "document": "1102387166",
        "fullName": "Jose Eduardo Guevara Contreras",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Planta De Harinas",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "6/17/2019 9:30:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003726",
        "document": "1095842972",
        "fullName": "Jose Antonio Diaz Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "6/17/2019 10:00:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003856",
        "document": "1115077658",
        "fullName": "Natalia  Bedoya Barrios",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Nacional De Procesos",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Procesos",
        "area": "Procesos",
        "fechaIngreso": "6/18/2019 12:04:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001286",
        "document": "37520472",
        "fullName": "Yolanda Quinonez Pereira",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "6/20/2019 2:39:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006417",
        "document": "94304478",
        "fullName": "Nilton Cesar Pena Naranjo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "6/21/2019 12:02:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006299",
        "document": "80209062",
        "fullName": "Nelson Enrrique Arias Contreras",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "6/25/2019 12:47:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006507",
        "document": "11311324",
        "fullName": "Libardo Eulices Viveros Daza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "6/25/2019 12:49:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003864",
        "document": "98511839",
        "fullName": "Omar Andres Serna Serna",
        "company": "Solla",
        "regional": "Betu",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "6/25/2019 1:10:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003863",
        "document": "21530336",
        "fullName": "Olga Bibiana Roldan Suescun",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Auxiliar Logistico Operacion Pecuaria",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "6/25/2019 1:10:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003866",
        "document": "1005540545",
        "fullName": "Harlen Yessenia Mendez Albarracin",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/25/2019 4:10:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003862",
        "document": "1102360547",
        "fullName": "Mauricio Alexander Torres Alonso",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Planta De Harinas",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "6/26/2019 9:03:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003888",
        "document": "1732476",
        "fullName": "Jose Antonio Rojas Puello",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "7/5/2019 1:34:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003916",
        "document": "13540703",
        "fullName": "Edinson Armando Morales Ferreria",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "7/8/2019 3:21:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003918",
        "document": "1007680442",
        "fullName": "Manuel Enrique Galindo Moreno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "7/8/2019 3:21:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003840",
        "document": "1095833272",
        "fullName": "Javier Carvajal Herrera",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/15/2019 2:39:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006671",
        "document": "1143859194",
        "fullName": "Maryi Liseth Martinez Tabares",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Procesos Productivos",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "7/21/2019 2:42:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003947",
        "document": "1053846290",
        "fullName": "Jonathan  Rios Ramirez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Mip",
        "area": "Comercial Mip",
        "fechaIngreso": "7/21/2019 2:28:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003948",
        "document": "1015423952",
        "fullName": "Jenny Paola Florez Bautista",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "7/21/2019 2:28:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003961",
        "document": "1053812271",
        "fullName": "Hernan Dario Ortegon Villanueva",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Tecnico Junior",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "7/21/2019 2:28:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003989",
        "document": "13536264",
        "fullName": "Sebastian  Jurado Prada",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/26/2019 12:51:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004011",
        "document": "1102380157",
        "fullName": "Leidy Karina Jaimes Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/26/2019 1:20:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006506",
        "document": "29287459",
        "fullName": "Beatriz Elena Villejas Patiño",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/26/2019 10:13:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003982",
        "document": "1036964485",
        "fullName": "Darien Camilo Garcia Cardona",
        "company": "Distraves",
        "regional": "Rion",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "7/29/2019 2:28:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004033",
        "document": "1007360554",
        "fullName": "Yoenis Paola Perez Viana",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "7/30/2019 1:52:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006333",
        "document": "1060876102",
        "fullName": "Eiver Fabian Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Control Del Riesgo",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "7/31/2019 2:08:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006515",
        "document": "94319525",
        "fullName": "Alberto Jose Melo Ortiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Control Del Riesgo",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "7/31/2019 2:09:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006372",
        "document": "1113522975",
        "fullName": "Jhon Edison Taramuel Alpata",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/31/2019 2:08:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006396",
        "document": "1111769587",
        "fullName": "Olmer David Varela Montaño",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Logistica",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "7/31/2019 2:08:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00293",
        "document": "31529275",
        "fullName": "Elizabeth  Labrada Caldas",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "7/31/2019 2:09:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006571",
        "document": "1144056091",
        "fullName": "Erik Brandon Ocampo Giraldo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador Inventario Gral",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "7/31/2019 2:08:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006410",
        "document": "6391403",
        "fullName": "Edwing  Velasquez Mendosa",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Mecanico",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "7/31/2019 2:09:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00253",
        "document": "14896870",
        "fullName": "Hugo  Molano",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Produccion",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "7/31/2019 2:08:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006575",
        "document": "1130946070",
        "fullName": "Jefferson  Olave",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Túneles Y Filete",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/31/2019 2:08:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006704",
        "document": "1115074253",
        "fullName": "Cristian  Garcia Millan",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/31/2019 2:08:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00322",
        "document": "94269345",
        "fullName": "Leonardo  Orozco",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Tecnico De Mantenimiento",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "7/31/2019 2:09:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006401",
        "document": "1113519936",
        "fullName": "Katherine  Vasquez Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/31/2019 2:08:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006789",
        "document": "6256992",
        "fullName": "Luis  Chito",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "7/31/2019 2:09:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006812",
        "document": "1127942024",
        "fullName": "Jose Luis Cruz Dominguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Post Proceso",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/31/2019 2:08:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006331",
        "document": "1085277216",
        "fullName": "Keylin Jimena Burbano Cabrera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Cajero",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "7/31/2019 2:08:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006756",
        "document": "94480078",
        "fullName": "Victor Hernan Henao Villegas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Mecanico",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "7/31/2019 2:09:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006551",
        "document": "1144054354",
        "fullName": "Milton Andres Mosquera Velasco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Devoluciones",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "7/31/2019 2:08:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006795",
        "document": "94268522",
        "fullName": "Falfan Ebler Congolino",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "7/31/2019 2:09:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00166",
        "document": "1007839430",
        "fullName": "Nathalia Caicedo Sandoval",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Fletes",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "7/31/2019 2:08:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006479",
        "document": "1114451967",
        "fullName": "Edwin Rojas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Planta Aba",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/31/2019 2:08:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006804",
        "document": "94482803",
        "fullName": "Cristian Andres Cortes Loaiza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Planta Aba",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/31/2019 2:09:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00250",
        "document": "14882547",
        "fullName": "Heber Humberto Bocanegra",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auditor De Seguridad",
        "uen": "Gerencia Administrativo",
        "process": "Administrativo",
        "area": "Administrativo",
        "fechaIngreso": "7/31/2019 2:08:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006834",
        "document": "14888491",
        "fullName": "Norvey  Domingues Escobar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Planta Aba",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/31/2019 2:08:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00280",
        "document": "29136754",
        "fullName": "Martha Isabel Echeverry Gonzalez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "7/31/2019 2:09:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006588",
        "document": "94469630",
        "fullName": "Carlos Yeferson Pareja Campo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "7/31/2019 2:09:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006480",
        "document": "1114453079",
        "fullName": "Jorge Leonardo Rojas Estrada",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Ingeniero Electrico",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "7/31/2019 2:08:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006328",
        "document": "38683935",
        "fullName": "Yoli Maria Borrero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Facturacion",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "8/2/2019 11:33:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004038",
        "document": "1044100261",
        "fullName": "Julian Alejandro Castrillon Rios",
        "company": "Solla",
        "regional": "Gome",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "8/5/2019 11:57:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006282",
        "document": "1067469231",
        "fullName": "Andrea Johana Andrade Bolaños",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Procesos Productivos",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "8/5/2019 7:55:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1383134",
        "document": "11367085",
        "fullName": "Edwin Parada Galindo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "8/6/2019 11:30:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004052",
        "document": "1038766857",
        "fullName": "German Dario Estrada Bonilla",
        "company": "Solla",
        "regional": "Betu",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "8/8/2019 12:15:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004050",
        "document": "1020453636",
        "fullName": "Jhonatan Ernesto Bernal Sanchez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador De Operación Logistica",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "8/8/2019 12:15:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006618",
        "document": "1144198829",
        "fullName": "Samuel Steven Jimenez Suarez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "8/9/2019 3:32:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006839",
        "document": "1094929637",
        "fullName": "Guillermo Alejandro Dumes Ortiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider De Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "8/9/2019 3:37:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004063",
        "document": "14899734",
        "fullName": "Jose Julian Rivera Gonzalez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "8/13/2019 12:21:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004054",
        "document": "1120504665",
        "fullName": "Maria Angelica Leon Cano",
        "company": "Distraves",
        "regional": "Zipa",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "8/14/2019 2:41:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004066",
        "document": "1062912574",
        "fullName": "Yobany Javier Valega Hernandez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "8/15/2019 2:05:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004085",
        "document": "37557122",
        "fullName": "Sandra Maritza Valencia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "8/15/2019 2:05:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004049",
        "document": "5654086",
        "fullName": "Pedro Antonio Saavedra Corzo",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "8/15/2019 2:05:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004104",
        "document": "1107081603",
        "fullName": "Luis Fernando Bergaño Villano",
        "company": "Transgraneles",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "8/23/2019 11:49:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004102",
        "document": "1039024143",
        "fullName": "Santiago  Velasquez Raigoza",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "8/23/2019 11:49:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004113",
        "document": "1023750223",
        "fullName": "Luis Alfredo Vargas Saldarriaga",
        "company": "Solla",
        "regional": "Donm",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "8/27/2019 1:47:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004123",
        "document": "1101683545",
        "fullName": "Alfonso  Muñoz Avendaño",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "8/29/2019 11:31:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004125",
        "document": "1098681871",
        "fullName": "BC Julieth Paola Suarez Rincon (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Analista Laboratorio",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/29/2019 2:31:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006429",
        "document": "1144040306",
        "fullName": "Andres Felipe Polania Molina",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Informacion Comercial",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "9/4/2019 8:38:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006271",
        "document": "1002919774",
        "fullName": "Angela Maria Acosta Vivas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Procesos Productivos",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/5/2019 7:31:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004160",
        "document": "1020434709",
        "fullName": "Jorge Alexander Duque Zapata",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "9/11/2019 11:41:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004155",
        "document": "1096512939",
        "fullName": "Luz Dary Sierra Beltran",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2019 5:12:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004141",
        "document": "98559897",
        "fullName": "Wilmar Adrian Garcia Arias",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "9/16/2019 5:12:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004163",
        "document": "1019012516",
        "fullName": "Dumar Arley Franco Herrera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2019 5:12:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004187",
        "document": "1005539598",
        "fullName": "Yurley Dayana Almeida Peña",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/24/2019 3:20:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004199",
        "document": "1095921006",
        "fullName": "John Fredy Vanegas Delgado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/26/2019 4:18:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004201",
        "document": "1102390231",
        "fullName": "Eliodoro  Ramirez Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/26/2019 4:18:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000752",
        "document": "91536875",
        "fullName": "Anderson Gonzalez Ortiz",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/30/2019 7:29:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004243",
        "document": "46378587",
        "fullName": "Dora Ines Vargas Martinez",
        "company": "Distraves",
        "regional": "Soga",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/10/2019 4:18:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004251",
        "document": "1095952188",
        "fullName": "Gersson David Cristancho Angarita",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/10/2019 10:19:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004269",
        "document": "1100948804",
        "fullName": "Jesus Maria Bayona Aparicio",
        "company": "Distraves",
        "regional": "giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/11/2019 4:51:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004270",
        "document": "1102359846",
        "fullName": "Evenllynne Alicia Pinto Blanco",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/11/2019 4:51:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004272",
        "document": "1098717510",
        "fullName": "Ivan Mauricio Mayorga Mogotocoro",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "10/11/2019 8:52:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004278",
        "document": "1090480874",
        "fullName": "Jhon Carlos Peña Correa",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "10/11/2019 8:52:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004282",
        "document": "1098625926",
        "fullName": "BC Camilo Adolfo Mantilla Celis (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Jefe Cartera",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/16/2019 8:40:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004288",
        "document": "5729082",
        "fullName": "Andres Alfonso Guarin Caceres",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/21/2019 2:35:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004293",
        "document": "1044502156",
        "fullName": "Andres Felipe Pino Arboleda",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "10/21/2019 4:11:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004286",
        "document": "1018350326",
        "fullName": "Angie Mabel Santa Marin",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "10/23/2019 9:36:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004289",
        "document": "13958810",
        "fullName": "Plinio Alberto Valbuena Ariza",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Ambiental Y Biosegurida",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/23/2019 10:36:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004319",
        "document": "1036639720",
        "fullName": "Diego Alejandro Urrego Jimenez",
        "company": "Distraves",
        "regional": "Itag",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/28/2019 3:20:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004325",
        "document": "91541932",
        "fullName": "Jesus Norberto Hernandez Lagos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/29/2019 4:53:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004338",
        "document": "1152464613",
        "fullName": "Sara Valentina Mera Gonzalez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Compensaciones",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Compensaciones",
        "fechaIngreso": "11/5/2019 1:15:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004342",
        "document": "94477664",
        "fullName": "Geovanny Enrique Cobo Sanchez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "11/6/2019 8:16:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006760",
        "document": "1087558316",
        "fullName": "Francisco Javier Hernandez Lopez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "11/13/2019 11:49:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006437",
        "document": "66871078",
        "fullName": "Viviana Pulgarin Barrera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider Canal Directo Y Puntos De Venta",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "11/13/2019 12:10:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004366",
        "document": "1102377870",
        "fullName": "Gerson Armando Acuña Chaparro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "11/13/2019 3:38:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004372",
        "document": "1098809140",
        "fullName": "Danny Alfonso Antolinez Rodriguez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "11/13/2019 3:38:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004363",
        "document": "1102374638",
        "fullName": "Jorge Eliecer Bueno Pimiento",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/13/2019 3:38:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004364",
        "document": "1030598758",
        "fullName": "Tatiana Alejandra Pedraza Forero",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "11/15/2019 4:44:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004395",
        "document": "37511327",
        "fullName": "Maria Victoria Reatiga Esparza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Full Filler",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "11/22/2019 6:57:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004403",
        "document": "94479764",
        "fullName": "Hector Fabio Sanchez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "11/26/2019 12:38:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004420",
        "document": "1098658916",
        "fullName": "David  Puentes Castillo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Planta Producción",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "11/28/2019 2:12:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004437",
        "document": "91539240",
        "fullName": "Edison  Ortiz Pabon",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "12/9/2019 8:31:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004357",
        "document": "1098768318",
        "fullName": "Ana Maria Rojas Florez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/10/2019 1:32:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001639",
        "document": "1099366726",
        "fullName": "Edinson Riano Osorio",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/11/2019 4:39:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004452",
        "document": "91237082",
        "fullName": "Marlon Alfredo Perez Lopez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Director Mantenimiento Proteina",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "12/12/2019 5:12:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004476",
        "document": "1007988235",
        "fullName": "Diosemiro  Abril Rojas",
        "company": "Distraves",
        "regional": "giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/17/2019 1:26:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004480",
        "document": "91535423",
        "fullName": "Jose Alfredo Mattos Barrera",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/17/2019 2:26:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006774",
        "document": "1114388165",
        "fullName": "Jefersson Carvajal Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/18/2019 12:00:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004444",
        "document": "1095924479",
        "fullName": "Edwin Fernando Laguado Vera",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "12/18/2019 1:51:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004445",
        "document": "1098811443",
        "fullName": "Duglas Jose Lozano Ramirez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "12/18/2019 1:51:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004429",
        "document": "1096200940",
        "fullName": "Samuel Ricardo Fuentes Gelvis",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "12/18/2019 1:51:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0414",
        "document": "1115062479",
        "fullName": "Luis Enrique Marin Rojas",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/18/2019 2:51:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007336",
        "document": "1003139951",
        "fullName": "Loly Luz Lopez Lopez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras Nacionales",
        "fechaIngreso": "12/18/2019 3:01:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004516",
        "document": "1096512909",
        "fullName": "Jose Luis Muñoz Chaparro",
        "company": "Distraves",
        "regional": "Sang",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/19/2019 3:51:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004502",
        "document": "1128048350",
        "fullName": "Yira Patricia Carrasquilla Madrid",
        "company": "Distraves",
        "regional": "Vall",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "12/19/2019 3:51:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004503",
        "document": "1020782974",
        "fullName": "BC Stephani Julieth Gomez Medina (Shared)",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/19/2019 3:51:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004509",
        "document": "1098757725",
        "fullName": "Liliana  Mantilla Maldonado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Gestion Documental",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Centro De Gestion Documental",
        "fechaIngreso": "12/26/2019 1:59:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003086",
        "document": "1016027267",
        "fullName": "Ana Milena Duarte Pereira",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Bascula",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "1/3/2020 2:41:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004538",
        "document": "1115078270",
        "fullName": "Jhon Stivens Soto Serna",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "1/7/2020 4:50:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004540",
        "document": "1086224636",
        "fullName": "Jimmy Brayan De La Cruz Guerrero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/7/2020 5:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004537",
        "document": "94473701",
        "fullName": "Heriberto  Plaza Buitrago",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/7/2020 5:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003140",
        "document": "1113595553",
        "fullName": "Diego Fernando Franco Correa",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "1/7/2020 5:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006459",
        "document": "1144094794",
        "fullName": "Andres Felipe Reyes Moreno",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "1/10/2020 9:10:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004556",
        "document": "1098609646",
        "fullName": "Pedro Alexander Contreras Rojas",
        "company": "Transgraneles",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "1/13/2020 1:35:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004558",
        "document": "1104134148",
        "fullName": "Libardo  Amaya Barrera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/13/2020 2:52:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004566",
        "document": "80791252",
        "fullName": "Carlos Alberto Cubillos Goyeneche",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "1/14/2020 3:25:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004557",
        "document": "13871962",
        "fullName": "BC Leonardo Ordoñez Roa (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Jefe Mantenimiento",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/14/2020 3:55:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006697",
        "document": "1130593847",
        "fullName": "Leonardo Fabio Fernandez Velez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "1/21/2020 9:58:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004584",
        "document": "1000310796",
        "fullName": "Yonatan  Oliveros Moncada",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/23/2020 7:52:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004608",
        "document": "1082945730",
        "fullName": "Yumileys Patricia Marquez Plata",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Coordinador Abastecimiento",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/24/2020 1:23:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006270",
        "document": "1143931380",
        "fullName": "Erick Steven Acevedo Lopez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Colgado Y Eviscerado",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/25/2020 1:49:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006677",
        "document": "80230910",
        "fullName": "Ever Mauricio Mejia Marmolejo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Inventarios",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/25/2020 2:01:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006436",
        "document": "126334605",
        "fullName": "Dulce Karina Puentes Fuentes",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Digitador",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/25/2020 2:33:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004623",
        "document": "1004824332",
        "fullName": "Daime  Vargas Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Inventarios",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/27/2020 1:32:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008898",
        "document": "71376963",
        "fullName": "Cesar Augusto Ospina Ruiz",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Soporte Tecnico",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Infraestructura",
        "fechaIngreso": "1/27/2020 4:02:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004609",
        "document": "1036638245",
        "fullName": "Yonathan Stefeen Alzate Castro",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Salud Ocupacional",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Salud Ocupacional",
        "fechaIngreso": "1/27/2020 5:02:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006311",
        "document": "31581453",
        "fullName": "Beiba Johana Barrera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Facturacion",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "1/27/2020 7:37:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004583",
        "document": "71799826",
        "fullName": "Edgardo Alejandro Restrepo Guerra",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "1/27/2020 9:02:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0344",
        "document": "1115078591",
        "fullName": "Ricardo  Buitrago Santamaria",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/28/2020 4:05:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0338",
        "document": "1115090680",
        "fullName": "Diego Fernando Paz",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Porteria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/28/2020 4:05:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0387",
        "document": "1007849666",
        "fullName": "Juan Arley Perea Herrera",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Porteria Spg",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/28/2020 4:05:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0343",
        "document": "1115081767",
        "fullName": "Alexis  Echavarría",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Portero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/28/2020 4:05:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9013",
        "document": "1114456362",
        "fullName": "Diego Farid Potes Mahecha",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Portero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/28/2020 4:05:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0337",
        "document": "14882547",
        "fullName": "Heber  Bucanero",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Porteria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/28/2020 4:05:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9001",
        "document": "1115090266",
        "fullName": "Miguel Angel Vargas Solano",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/28/2020 4:05:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004667",
        "document": "1005549252",
        "fullName": "Liliana  Diaz Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "1/29/2020 8:41:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004663",
        "document": "13108263",
        "fullName": "Jose Abel Castro Velasque",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "1/29/2020 8:41:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004657",
        "document": "1003335934",
        "fullName": "Duban Felipe Cardenas Obeso",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/29/2020 8:41:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004658",
        "document": "1095956036",
        "fullName": "Edinson Jesus Arteaga Chaparro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "1/29/2020 8:41:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004643",
        "document": "1073511134",
        "fullName": "Wilson Alexander Eslava Niño",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "1/31/2020 8:54:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000981",
        "document": "91284491",
        "fullName": "Jesus David Uribe Villamizar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Cargue",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/5/2020 1:30:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004672",
        "document": "1143986159",
        "fullName": "Luz Karime Florez Vargas",
        "company": "Transgraneles",
        "regional": "Buga",
        "jobTitle": "Coordinador De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "2/7/2020 12:07:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006281",
        "document": "29678549",
        "fullName": "Sully Lorena Amaya Manzano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador Transporte De Alimentos",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/14/2020 6:49:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004738",
        "document": "1099375983",
        "fullName": "Jefferson Olegario Rico Gamboa",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "2/14/2020 9:31:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004722",
        "document": "1098754370",
        "fullName": "Kevin Esteban Uribe Romero",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "2/14/2020 9:31:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004718",
        "document": "1098750734",
        "fullName": "Juan Sebastian Camacho Archila",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/14/2020 9:31:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004721",
        "document": "1073986131",
        "fullName": "Eliseo Segundo Montes Guzman",
        "company": "Distraves",
        "regional": "Elpl",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/14/2020 9:31:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005768",
        "document": "1098614978",
        "fullName": "BC Carol Viviana Barrera Valencia (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Nomina",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/17/2020 1:09:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004740",
        "document": "1070952270",
        "fullName": "Wilmer Antonio Amaya Martinez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/18/2020 12:13:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004736",
        "document": "1065625770",
        "fullName": "Leider Jose Montero Maestre",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/18/2020 12:13:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006739",
        "document": "16785818",
        "fullName": "Reinaldo Antonio Grisales Ramirez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Sistemas",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Departamento De Informatica",
        "fechaIngreso": "2/19/2020 1:46:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007573",
        "document": "1110480267",
        "fullName": "Jamir Sthevenson Ramirez Leiton",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Coordinador (a) Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "2/20/2020 2:18:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0420",
        "document": "1110475895",
        "fullName": "Carlos Andres Rodriguez Velasquez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Coordinador Cedi",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/21/2020 11:51:22 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004678",
        "document": "63544608",
        "fullName": "Lucelly Moreno Guerrero",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "2/21/2020 9:52:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004725",
        "document": "1093773251",
        "fullName": "Junior Alexis Cruz Tarazona",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "2/21/2020 10:22:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004756",
        "document": "1019039758",
        "fullName": "Monica Lizeth Zapa Mariño",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Impulsador Profesional",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "2/25/2020 12:04:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004786",
        "document": "6321417",
        "fullName": "Orlando Andres Velasco Argote",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/26/2020 11:37:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0423",
        "document": "1020474295",
        "fullName": "Yeferson Gomes Morales",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Agente De Mesa De Ayuda",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/26/2020 12:11:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004772",
        "document": "91352967",
        "fullName": "Carlos Andres Cuadros Candela",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "2/26/2020 1:07:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004766",
        "document": "63562405",
        "fullName": "Claudia Patricia Baron Delgado",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "2/26/2020 1:07:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004776",
        "document": "1099371255",
        "fullName": "Andres Felipe Landinez Galvan",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/26/2020 1:07:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004781",
        "document": "1102576075",
        "fullName": "Raul Antonio Acuña Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/26/2020 1:38:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004763",
        "document": "1098825382",
        "fullName": "Jorge Luis Bracho Castro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "2/26/2020 1:38:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003810",
        "document": "1102388997",
        "fullName": "Katerinne Suarez Blanco",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Materias Primas",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "2/28/2020 5:45:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0424",
        "document": "1115077540",
        "fullName": "Juan David Giraldo",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Soporte En Sitio",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/2/2020 8:26:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005259",
        "document": "1038140094",
        "fullName": "Angelica  Rendon Barrios",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "3/4/2020 7:31:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004797",
        "document": "1152185561",
        "fullName": "Liz Alexandra Calderon Calderon",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Comercial Porcicultura",
        "area": "Comercial Porcicultura",
        "fechaIngreso": "3/5/2020 1:01:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000639",
        "document": "13724408",
        "fullName": "Julian Enrique Gonzalez Tibamoso",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/6/2020 2:34:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004832",
        "document": "52752106",
        "fullName": "Ana Carolina Cuervo Velandia",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "3/11/2020 1:22:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004829",
        "document": "32781348",
        "fullName": "Sandra Milena Berdejo Mora",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "3/11/2020 1:52:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004833",
        "document": "1102369519",
        "fullName": "Erika Paola Fonseca Garzon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/11/2020 1:52:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004817",
        "document": "1102718706",
        "fullName": "Oswaldo  Diaz Toledo",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "3/11/2020 1:52:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006590",
        "document": "6229015",
        "fullName": "William  Pareja",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/12/2020 8:13:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006408",
        "document": "1088241323",
        "fullName": "Yeny Alexandra Velasquez Herrera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "3/12/2020 8:13:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007036",
        "document": "1115085011",
        "fullName": "Sebastian  Lozada",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "3/12/2020 8:13:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006832",
        "document": "34606556",
        "fullName": "Sandra Patricia Diaz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "3/12/2020 8:13:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006841",
        "document": "1114402282",
        "fullName": "Luz Mary Echavarria Tonusco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Atencion Al Cliente",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "3/12/2020 8:13:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00203",
        "document": "1114212081",
        "fullName": "Yeison  Bedoya",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "3/12/2020 8:12:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006512",
        "document": "1130592897",
        "fullName": "Pablo Cesar Zambrano Soto",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Devoluciones",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/12/2020 8:13:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006424",
        "document": "66877715",
        "fullName": "Adriana  Perez Quintana",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Archivo",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "3/12/2020 8:13:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006581",
        "document": "6294619",
        "fullName": "Juber  Mendoza Ortiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/12/2020 8:13:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006752",
        "document": "66947318",
        "fullName": "Dora Ines Gutierrez Jaramillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "3/12/2020 8:13:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0008",
        "document": "91159942",
        "fullName": "Gustavo Adolfo Aguilar Uribe",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxuliar de Calidad",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/16/2020 12:58:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004861",
        "document": "1098651282",
        "fullName": "Arelis  Trujillo Quintero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "3/18/2020 4:12:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004858",
        "document": "1193380893",
        "fullName": "Andres David Sarria Villadiego",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/19/2020 2:17:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004859",
        "document": "1044504169",
        "fullName": "Rafael Adolfo Mesa Vallejo",
        "company": "Solla",
        "regional": "Donm",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "3/19/2020 2:17:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004854",
        "document": "80656504",
        "fullName": "Orlando  Rodriguez Melo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/19/2020 2:17:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004857",
        "document": "1015278455",
        "fullName": "Jhon Alberto Restrepo Quintana",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "3/19/2020 2:17:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004847",
        "document": "1114456461",
        "fullName": "Wilver David Vasquez Saldarriaga",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/19/2020 2:17:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004866",
        "document": "91487234",
        "fullName": "Delio Camilo Albarracin Luna",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Vacunador",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/19/2020 2:17:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004883",
        "document": "1102384683",
        "fullName": "Karen Dayana Rodriguez Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/21/2020 4:15:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006827",
        "document": "1130597782",
        "fullName": "Duvan Alexander Davila Carvajal",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "3/22/2020 10:49:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004039",
        "document": "1095817377",
        "fullName": "Cristian Daniel Florez Villamizar",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "3/24/2020 9:24:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006700",
        "document": "10258069",
        "fullName": "Carlos Uriel Gallego Gallo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/2/2020 7:18:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004906",
        "document": "38362990",
        "fullName": "Jury Johanna Varela Ruiz",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Director Zona",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "4/4/2020 5:23:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004908",
        "document": "1026139561",
        "fullName": "Raul Alejandro Betancur Angel",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador (a) De Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Ganadería",
        "fechaIngreso": "4/7/2020 12:34:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004912",
        "document": "1091659421",
        "fullName": "Jose Luis Rincon Illera",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/13/2020 1:15:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004924",
        "document": "1102548970",
        "fullName": "Jhon Fernando Gonzalez Diaz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "4/13/2020 1:15:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004911",
        "document": "1005539650",
        "fullName": "Diego Andres Paez Adarme",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Digitador Facturacion",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/13/2020 1:15:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004927",
        "document": "91047572",
        "fullName": "Jose Ferney Pinzon Becerra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/14/2020 3:47:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004094",
        "document": "1116244591",
        "fullName": "Yuri Maryeli Gordillo Leon",
        "company": "Transgraneles",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "8/22/2019 11:57:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004821",
        "document": "1095952369",
        "fullName": "Fabian Andres Rodriguez Delgado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/28/2020 3:58:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004825",
        "document": "1101596688",
        "fullName": "Alexander Martinez Carvajal",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/28/2020 3:58:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004844",
        "document": "1098782369",
        "fullName": "Edinson Castro Jerez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "4/28/2020 3:58:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0016",
        "document": "1033339717",
        "fullName": "Maria Del Carmen Restrepo Correa",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Administrador Punto de Venta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/6/2020 9:38:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0017",
        "document": "1033339717",
        "fullName": "Jacke Alfredo Cotes Ballestas",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/7/2020 2:09:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004195",
        "document": "1098811438",
        "fullName": "Leidy Liliana Nieto Ramirez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "5/8/2020 3:41:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004958",
        "document": "1042435085",
        "fullName": "Havid Del Carmen Gonzalez Dominguez",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/11/2020 1:17:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004972",
        "document": "1073246025",
        "fullName": "Omar Erney Lopez Amado",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/13/2020 12:52:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006612",
        "document": "94455763",
        "fullName": "Carlos Enrique Ipia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Oficios Varios",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "5/13/2020 2:21:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006638",
        "document": "1130644112",
        "fullName": "Cindy Carolina Lopez Arboleda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador De Nomina",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "5/14/2020 2:25:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003934",
        "document": "1098798374",
        "fullName": "Angie Katerine Arias Arellano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/14/2020 4:23:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006519",
        "document": "94260156",
        "fullName": "Oscar Jairo Meneses Garcia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Digitador",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/20/2020 1:10:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0024",
        "document": "1019033949",
        "fullName": "Jonathan Moyano Murcia",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Supervisor Cedi",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/20/2020 7:41:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004980",
        "document": "1101520417",
        "fullName": "Carmen Omaira Almeida Archila",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Profesional En Formacion",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/21/2020 12:49:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004986",
        "document": "1128428691",
        "fullName": "Julian David Osorio Bolivar",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/21/2020 1:12:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006586",
        "document": "1151954486",
        "fullName": "Deify Vanessa Palomino Real",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Seleccion",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "5/25/2020 5:37:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006802",
        "document": "66955107",
        "fullName": "Tommy Johanna Cortes Achinte",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider De Talento Humano",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "5/25/2020 6:06:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005005",
        "document": "73352793",
        "fullName": "Manuel Del Cristo Perez Marrugo",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/28/2020 1:19:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003615",
        "document": "1001587215",
        "fullName": "Melisa Fernandez Echeverri",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Seguridad Social",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Compensaciones",
        "fechaIngreso": "6/2/2020 2:08:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005019",
        "document": "1098800885",
        "fullName": "Angie Lorena Sarmiento Oliveros",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/4/2020 3:08:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005013",
        "document": "1102375357",
        "fullName": "Nelson Eduardo Tami Mendoza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/4/2020 3:08:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005011",
        "document": "37617854",
        "fullName": "Lilian Consuelo Duarte Calderon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "6/4/2020 3:08:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "7000125",
        "document": "1115086008",
        "fullName": "Mayra Alejandra Rodriguez Viera",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "6/4/2020 4:55:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0028",
        "document": "1058038586",
        "fullName": "Ibeth Zuleima Cuta Acevedo",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Administrador Punto de Venta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/4/2020 8:25:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003827",
        "document": "1152221883",
        "fullName": "Katherine Avendaño Valencia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Planeación Financiera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "6/10/2020 5:26:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005036",
        "document": "1017244749",
        "fullName": "David Hernando Villa Ruiz",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Tesoreria",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Tesoreria",
        "fechaIngreso": "6/11/2020 4:31:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006569",
        "document": "1144065992",
        "fullName": "BC Cristian Obando Valero (Shared)",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador Ssta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/12/2020 9:34:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005063",
        "document": "66835609",
        "fullName": "BC Gloria Gomez Calle (Shared)",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Regional De Talento Humano",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "6/30/2020 9:34:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005061",
        "document": "94483088",
        "fullName": "Julian Andres Murillo Calero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "7/1/2020 1:57:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005065",
        "document": "8064662",
        "fullName": "Juan Camilo Lopez Buitrago",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador Regional Cedis",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "7/2/2020 9:03:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005072",
        "document": "1042432853",
        "fullName": "Camilo Andres Hurtado Ballestas",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Supervisor Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/7/2020 3:18:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005082",
        "document": "1128407563",
        "fullName": "Jhon Fabio De Jesus Correa Tobon",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "7/8/2020 2:41:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00108",
        "document": "6322277",
        "fullName": "Marco Aurelio Cabrera Castillo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/9/2020 8:04:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005099",
        "document": "86013644",
        "fullName": "Jhon Fredy Piñeros Frisneda",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "7/10/2020 5:04:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005106",
        "document": "91078059",
        "fullName": "Ismael Enrique Ruiz Forero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Servicios Generales",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "7/13/2020 4:11:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005105",
        "document": "1098789918",
        "fullName": "Giordin Andres Perez Jerez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "7/13/2020 4:11:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005108",
        "document": "1095800944",
        "fullName": "Neiber Liliana Quesada Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "7/13/2020 4:11:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005167",
        "document": "91537101",
        "fullName": "Jorge Andres Rueda Serrano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Gerente Logistica",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "7/22/2020 9:56:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005118",
        "document": "73008256",
        "fullName": "Hector Manuel Fernandez Ramirez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "7/23/2020 1:28:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1361300",
        "document": "2969969",
        "fullName": "Luis Hernando Garcia Medina",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "7/23/2020 5:58:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004959",
        "document": "1098711643",
        "fullName": "Jefferson Dario Torres Bonilla",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "7/23/2020 7:58:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005090",
        "document": "13680383",
        "fullName": "Milton Niño Poveda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/23/2020 8:58:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005049",
        "document": "1098637529",
        "fullName": "Luz Marina Mariño Ortiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "7/29/2020 2:48:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005132",
        "document": "1095954145",
        "fullName": "Jonathan Andres Ardila Figueroa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "7/29/2020 2:48:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0048",
        "document": "1095814930",
        "fullName": "Maria Fernanda Gomez Silva",
        "company": "Temporales",
        "regional": "Giro",
        "jobTitle": "Auxiliar Comercial",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/29/2020 3:18:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006719",
        "document": "1006663196",
        "fullName": "Robinson Andres Gomes Guerrero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/30/2020 12:19:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006826",
        "document": "1082157004",
        "fullName": "Brayan Steyner Curaca Papamija",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Cedi Pitalito",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/30/2020 12:19:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005158",
        "document": "63529322",
        "fullName": "Yasmin Amparo Galvis Gaona",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Director Zona",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "8/4/2020 8:23:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005116",
        "document": "1114451814",
        "fullName": "Carlos Andres Lenis Valenzuela",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "8/4/2020 8:53:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005156",
        "document": "1036654977",
        "fullName": "Daniela Ospina Guisao",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Salud Ocupacional",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Salud Ocupacional",
        "fechaIngreso": "8/5/2020 2:42:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005163",
        "document": "1065833675",
        "fullName": "Jose Alejandro Luque Rodriguez",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/6/2020 4:50:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006755",
        "document": "1113786232",
        "fullName": "Simon Haeusler Jimenez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Auditoria De Riesgos",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "8/6/2020 9:50:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0429",
        "document": "1007634017",
        "fullName": "Valeria  Marin Perez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Practicante Talento Humano",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/9/2020 2:26:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005169",
        "document": "63451075",
        "fullName": "Sandra Patricia Oviedo Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "8/10/2020 7:26:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0036",
        "document": "1069498355",
        "fullName": "Laura Vanessa Palomino Nieto",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista de Laboratorio",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/11/2020 7:27:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005180",
        "document": "1007501161",
        "fullName": "Mauricio  Posso Monsalve",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Asistente Operativo De Granjas",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "8/13/2020 12:59:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006558",
        "document": "1144069670",
        "fullName": "Ronaldo Andres Muñoz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Ruteo",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "8/20/2020 1:45:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006161",
        "document": "1036620657",
        "fullName": "Viviana Ortiz Gonzalez",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "8/21/2020 2:17:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006488",
        "document": "1144083653",
        "fullName": "Nathalia Ruiz Palacios",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "8/24/2020 8:09:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005211",
        "document": "1005538899",
        "fullName": "Michael Steven Forero Linares",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "8/25/2020 2:10:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002627",
        "document": "1152225763",
        "fullName": "Alvaro Diego Sanchez Rodas",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "8/25/2020 9:10:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005214",
        "document": "1049643084",
        "fullName": "BC Yudy Andrea Fuquen Urian (Shared)",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Supervisor Puntos De Venta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/26/2020 2:41:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9020",
        "document": "",
        "fullName": "Porteria Peatonal Solla Buga",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/26/2020 7:11:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006388",
        "document": "10487647",
        "fullName": "Elver Valencia Delgado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/7/2020 2:30:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006748",
        "document": "7249841",
        "fullName": "Carlos Arturo Guerrero Galeano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/7/2020 2:30:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006744",
        "document": "6395355",
        "fullName": "Hernan Gue Hurtado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/7/2020 5:00:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006736",
        "document": "1112099235",
        "fullName": "Jhon Jairo Granada",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/9/2020 9:03:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X430",
        "document": "1004691684",
        "fullName": "Erick Steben Reina Velasquez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar Bascula",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2020 10:05:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001421",
        "document": "1098220423",
        "fullName": "Anggie Mayerly Espinosa Barajas",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "9/10/2020 10:58:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006539",
        "document": "1113676317",
        "fullName": "John Jairo Morales Guarin",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Ssta",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "9/11/2020 3:27:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005247",
        "document": "1098652024",
        "fullName": "Luis Miguel Dulcey Gomez",
        "company": "Distraves",
        "regional": "Elpl",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/11/2020 3:27:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0042",
        "document": "1098632534",
        "fullName": "Deyber Fabian Mantilla Serrano",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Domiciliario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/11/2020 7:50:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005237",
        "document": "1098763171",
        "fullName": "Jefferson Alexander Suarez Quiñones",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Nacional Sst",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Salud Y Seguridad En El Trabajo",
        "fechaIngreso": "9/12/2020 3:51:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005254",
        "document": "1193469885",
        "fullName": "Ronaldo Luis Vergara Palomino",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "9/14/2020 11:54:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005246",
        "document": "1112300398",
        "fullName": "Carlos Holmes Santacruz Vanegas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Fumigador",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Ped",
        "fechaIngreso": "9/22/2020 12:27:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005262",
        "document": "1020436739",
        "fullName": "Mayid Ignacio Morales Muñoz",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "9/22/2020 12:57:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005258",
        "document": "1001500247",
        "fullName": "Fredy Alejandro Castrillon Sucerquia",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "9/22/2020 12:57:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005245",
        "document": "1115068276",
        "fullName": "Jhon Edward Arevalo Calvo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "9/22/2020 12:57:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006357",
        "document": "1113624850",
        "fullName": "Omar Orlando Sanclemente Collazos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador Planta Industrial",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "9/28/2020 4:05:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005283",
        "document": "1102549175",
        "fullName": "Zuley Andrea Quintero Leon",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "9/28/2020 6:00:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005295",
        "document": "12496167",
        "fullName": "Pablo Emilio Muñoz Sanchez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/28/2020 6:00:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005309",
        "document": "63518919",
        "fullName": "Nancy  Rincon Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "9/28/2020 6:00:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005297",
        "document": "13177663",
        "fullName": "Mauricio  Quintana Guerrero",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/28/2020 6:00:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005393",
        "document": "1007735415",
        "fullName": "Karen Sofia Pinto Almeida",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/29/2020 5:14:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005256",
        "document": "1005345845",
        "fullName": "Angelica Sandoval Cacua",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Nomina",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "9/30/2020 8:46:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001539",
        "document": "1090407414",
        "fullName": "Demnis Maria Herrera Tarazona",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "9/30/2020 8:46:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004436",
        "document": "1039474320",
        "fullName": "Camila Andrea Campillo Diaz",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "10/1/2020 10:13:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00296",
        "document": "34516891",
        "fullName": "Lirbe Muñoz Cardenas",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar Comercial",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/2/2020 9:26:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005319",
        "document": "1044120634",
        "fullName": "David Fernando Chavarria Agudelo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "10/5/2020 12:59:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005322",
        "document": "1037622191",
        "fullName": "BC Paula Andrea Beltran Arenas (Shared)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Suministros Y Servicios",
        "fechaIngreso": "10/5/2020 2:59:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005323",
        "document": "1115074457",
        "fullName": "Monica Tatiana Castillo Montenegro",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "10/6/2020 1:01:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004175",
        "document": "1098406926",
        "fullName": "Mauricio Maldonado Madero",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/6/2020 5:01:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00538",
        "document": "1042417860",
        "fullName": "Manyinis Judith Ricardo Perez",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Mercaderista",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/9/2020 7:05:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005326",
        "document": "6200349",
        "fullName": "Carlos Fernando Rojas Cabal",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "10/14/2020 9:11:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005362",
        "document": "5746406",
        "fullName": "Jose Gregorio Orozco Maldonado",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/14/2020 10:41:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005342",
        "document": "1007773597",
        "fullName": "Jhon Edinson Pacheco Rincon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/14/2020 10:41:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005353",
        "document": "1098740314",
        "fullName": "Luis Alberto Afanador Sanchez",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/14/2020 10:41:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005360",
        "document": "1098358088",
        "fullName": "Fredy Yesid Ramirez Galvis",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "10/14/2020 10:41:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005370",
        "document": "45753947",
        "fullName": "Indira  Correa Martinez",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "10/20/2020 1:41:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00151",
        "document": "1005542620",
        "fullName": "Michael Yair Delgado Jaimes",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/20/2020 3:41:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002314",
        "document": "1101993068",
        "fullName": "Gabriela  Suarez Meza",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/22/2020 1:44:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005384",
        "document": "1002387514",
        "fullName": "Adel Alfredo Badillo Mejia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/22/2020 1:44:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005392",
        "document": "1065573099",
        "fullName": "Carlos Rafael Moreno Oñate",
        "company": "Distraves",
        "regional": "Vall",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/27/2020 1:19:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003724",
        "document": "91004503",
        "fullName": "Carlos Ariel Parra Celis",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/27/2020 2:49:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001842",
        "document": "1095945675",
        "fullName": "Yojan David Carreño Muñoz",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/27/2020 3:19:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005413",
        "document": "1102351657",
        "fullName": "Maria Ines Carvajal Carvajal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "11/3/2020 1:32:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006327",
        "document": "1144191232",
        "fullName": "Juan Felipe Borrero Candela",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Practicante",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/4/2020 2:03:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006619",
        "document": "1130645819",
        "fullName": "Mileidy Jurado Cartagena",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "11/10/2020 8:52:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005433",
        "document": "91160027",
        "fullName": "Oscar Raul Saenz Amador",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/10/2020 8:52:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005303",
        "document": "1098799684",
        "fullName": "Rusbell Antonio Rodriguez Rodriguez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/10/2020 8:52:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005438",
        "document": "37860727",
        "fullName": "BC Claudia Patricia Pabon Villabona (Retirado)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Call Center",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/11/2020 1:13:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005448",
        "document": "74379857",
        "fullName": "Wilson Yecid Sanabria Velasquez",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Coordinador Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "11/12/2020 2:15:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005450",
        "document": "1037621638",
        "fullName": "Paula Andrea Jurado Montoya",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "11/12/2020 2:15:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005475",
        "document": "1056075248",
        "fullName": "Edwin Armando Baron Huertas",
        "company": "Distraves",
        "regional": "Tunj",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "11/13/2020 12:16:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005455",
        "document": "1097850548",
        "fullName": "BC Maria Fernanda Silva Rojas (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Comercial",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/13/2020 12:46:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005485",
        "document": "1095920417",
        "fullName": "Luis Carlos Mantilla Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/18/2020 2:23:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1444601",
        "document": "1098782529",
        "fullName": "John Edinson Bayona Ropero",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/19/2020 2:24:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005510",
        "document": "43118792",
        "fullName": "BC Adriana Janeth Gomez Zapata (Retirado)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador (a) Nacional De Formación Y Desarrollo",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Formación Y Dllo",
        "fechaIngreso": "11/24/2020 3:25:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005492",
        "document": "13745930",
        "fullName": "Ramiro Rojas Arias",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/24/2020 7:25:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005407",
        "document": "1102385814",
        "fullName": "Daniel Jaimes Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/24/2020 7:25:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005473",
        "document": "88285239",
        "fullName": "Reinel Perez Ballesteros",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/24/2020 7:55:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00264",
        "document": "29105267",
        "fullName": "Sonia Yasmir Delgado Gutierrez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Gerencia Administrativo",
        "process": "Adminisrativo",
        "area": "Adminisrativo",
        "fechaIngreso": "11/25/2020 7:09:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005532",
        "document": "50882881",
        "fullName": "Lucelys  Bohorquez Pestana",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/26/2020 1:10:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005529",
        "document": "1045678587",
        "fullName": "Wilmer De Jesus Mendoza Tafur",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Digitador Facturacion",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "11/26/2020 1:10:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005526",
        "document": "79636127",
        "fullName": "Jaime Hernan Revelo Solano",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Supervisor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "11/26/2020 1:10:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006691",
        "document": "1143952087",
        "fullName": "Andrea Estefania Estupiñan Cortes",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "11/30/2020 2:46:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005548",
        "document": "1007351140",
        "fullName": "Eder Luis Rivas Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/1/2020 3:17:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005503",
        "document": "1005188834",
        "fullName": "BC Melany Paola Zuluaga Amariz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Compras",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/2/2020 1:18:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006806",
        "document": "1094664049",
        "fullName": "Jhair Humberto Cote Valencia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Maquila",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/2/2020 2:48:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005561",
        "document": "1040181311",
        "fullName": "Daniel Muñoz Roman",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Auditoria",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "12/2/2020 9:49:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004651",
        "document": "1102368631",
        "fullName": "Iveth Slendy Herrera Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/2/2020 10:19:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005563",
        "document": "70327008",
        "fullName": "Cesar Augusto Herrera Bolivar",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "12/3/2020 2:50:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005566",
        "document": "1001808747",
        "fullName": "Jhonatan Andres Bedoya Cifuentes",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "12/3/2020 2:50:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006392",
        "document": "66730649",
        "fullName": "Alba Maria Valencia Moreno",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor De Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "12/4/2020 1:13:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006502",
        "document": "1092914755",
        "fullName": "Wilmar Andres Villada Rivera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Cajero",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "12/4/2020 1:43:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005571",
        "document": "1102386093",
        "fullName": "Pedro Daniel Tapia Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Bascula",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "12/10/2020 1:57:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005577",
        "document": "1100969007",
        "fullName": "Nathalia  Bueno Barrera",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/11/2020 3:58:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005572",
        "document": "1098789675",
        "fullName": "Tania Jurley Moreno",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "12/11/2020 4:28:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005582",
        "document": "1022099810",
        "fullName": "Paula Andrea Rivera Silva",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/11/2020 4:28:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005595",
        "document": "1100965483",
        "fullName": "Paula Andrea Martinez Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "12/11/2020 4:28:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005644",
        "document": "1005155204",
        "fullName": "Yeinson Alexander Aguillon Collantes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/15/2020 3:43:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006108",
        "document": "1092392825",
        "fullName": "Wendy Vanesa Pereira Duran",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/15/2020 4:43:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006572",
        "document": "29685615",
        "fullName": "Sandra Viviana Ocampo Hernandez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/15/2020 10:07:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005618",
        "document": "1232893685",
        "fullName": "Jean Carlos Alexander Bayona Latorre",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/16/2020 1:15:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005625",
        "document": "1098749020",
        "fullName": "Karen Lizeth Quintero",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "12/16/2020 1:15:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005620",
        "document": "1010155871",
        "fullName": "Anyi Katherin Picon Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/16/2020 1:15:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005541",
        "document": "1092345454",
        "fullName": "Sadys Rincon Rios",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/17/2020 8:59:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006740",
        "document": "1113532581",
        "fullName": "Neidy Sorelly Guamanga Perez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Practicante",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/17/2020 9:59:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005631",
        "document": "71227898",
        "fullName": "Jorge Armando Villa Monsalve",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "12/17/2020 11:29:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006363",
        "document": "1143877824",
        "fullName": "Bryan Solarte Sanchez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Planeador De Operaciones",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "12/18/2020 7:15:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005666",
        "document": "1100962443",
        "fullName": "Jesus Alberto Mosquera Leon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/21/2020 2:50:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005667",
        "document": "1102378560",
        "fullName": "German Andres Calderon Cuadros",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/21/2020 2:50:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005655",
        "document": "13539523",
        "fullName": "Diomedes  Orellan Orellan",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/21/2020 2:50:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01186",
        "document": "1232888355",
        "fullName": "Lizeth Tatiana Barrera Jaimes",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/22/2020 2:28:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005681",
        "document": "1014249735",
        "fullName": "Blanca Durley Sanchez Avila",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Analista De Auditoria",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "12/23/2020 4:07:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005478",
        "document": "1095824245",
        "fullName": "Sergio Carmargo Ruiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/28/2020 4:45:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005279",
        "document": "1129576940",
        "fullName": "Deinner Javier Borja Vizcaino",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/28/2020 4:45:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005487",
        "document": "1102365607",
        "fullName": "Sandra Janneth Hernandez Campos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/30/2020 12:17:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005486",
        "document": "1095798137",
        "fullName": "David Julian Herrera bernal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/30/2020 12:17:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005483",
        "document": "1005163325",
        "fullName": "Genny Katherine Duran Sandoval",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/30/2020 12:17:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005519",
        "document": "1097304196",
        "fullName": "Freiman Villamizar Blanco",
        "company": "Distraves",
        "regional": "Elpl",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/30/2020 12:47:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01202",
        "document": "1102372092",
        "fullName": "Laura Natali Correa",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operativo Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/30/2020 4:48:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005693",
        "document": "13746940",
        "fullName": "Cesar Adolfo Sierra Suarez",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/30/2020 5:33:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005538",
        "document": "1098714880",
        "fullName": "Jonathan Martinez Zafra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/4/2021 1:37:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005678",
        "document": "1102775043",
        "fullName": "Julian Vargas Vega",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "1/4/2021 3:07:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005542",
        "document": "1063481590",
        "fullName": "Darwin Jose Quiñonez Medina",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/4/2021 4:10:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005712",
        "document": "1098808007",
        "fullName": "BC Kevin Arley Perez Camacho (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Comercial",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/4/2021 7:37:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005674",
        "document": "1148441903",
        "fullName": "Pablo Andres Hurtado Imbachi",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "1/6/2021 2:41:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005670",
        "document": "1115071392",
        "fullName": "Jeisson  Gaviria Sanclemente",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/6/2021 2:41:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005671",
        "document": "1007379752",
        "fullName": "Jhon Eder Aguirre Granobles",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Almacenamiento De Mp Mascotas",
        "fechaIngreso": "1/6/2021 2:41:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005672",
        "document": "1115063111",
        "fullName": "John Edinson Acevedo Marmolejo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/6/2021 2:41:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005682",
        "document": "1069872020",
        "fullName": "Jose Manuel Avila Mahecha",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/6/2021 2:41:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004930",
        "document": "1073178139",
        "fullName": "Elkin Alexander Guerrero Robay",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "1/8/2021 12:58:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007679",
        "document": "1075283661",
        "fullName": "Johan Stiven Narvaez Vargas",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "1/10/2021 11:55:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005692",
        "document": "1152461231",
        "fullName": "Daniel Ignacio Penagos Caro",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Suministros Y Servicios",
        "fechaIngreso": "1/10/2021 11:55:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005732",
        "document": "1099622489",
        "fullName": "Walther Mauricio Toloza Florez",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "1/13/2021 1:00:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005717",
        "document": "1005076388",
        "fullName": "Victor Alfonso Sepulveda Navarro",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "1/13/2021 1:00:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005700",
        "document": "1115081296",
        "fullName": "Jhon Andersson Sanchez Dominguez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "1/13/2021 3:00:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005696",
        "document": "1114063246",
        "fullName": "Jhoniver  Ortega Palacios",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "1/14/2021 8:02:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005697",
        "document": "94482276",
        "fullName": "Juan Fernando Torres Azcarate",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "1/14/2021 8:02:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0243",
        "document": "1085341109",
        "fullName": "Edwin Janio Miramag Potosi",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Distribucion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/20/2021 8:45:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006106",
        "document": "1102351423",
        "fullName": "Julian Alberto Vargas Duarte",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "1/22/2021 3:46:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006613",
        "document": "1112230077",
        "fullName": "Robison Andres Irua Rivera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Digitador",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/22/2021 4:16:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004967",
        "document": "1095827976",
        "fullName": "Liseth Karina Espinosa Villabona",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Jefe Planta Beneficio",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/25/2021 1:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005743",
        "document": "1053852869",
        "fullName": "Mario Andres Sanchez Florez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "1/26/2021 1:23:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005737",
        "document": "6322275",
        "fullName": "Carlos Alberto Ulloa Rodriguez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "1/26/2021 1:23:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005742",
        "document": "1002085353",
        "fullName": "Luis Alberto Tuberquia Espinosa",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "1/26/2021 1:23:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005755",
        "document": "1005449137",
        "fullName": "Leidy Paola Hurtado Vega",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "1/27/2021 12:54:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005758",
        "document": "1098773988",
        "fullName": "Nestor Augusto Castillo Santana",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "1/27/2021 12:54:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005534",
        "document": "1095811545",
        "fullName": "Juan Carlos Parra Villamizar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Ambiental",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "2/2/2021 12:04:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005782",
        "document": "80147036",
        "fullName": "Ivan Giovanni Tinjaca Gonzalez",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Domiciliario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/2/2021 12:04:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005778",
        "document": "1102369093",
        "fullName": "Yenny Paola Palomino Leon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/2/2021 12:34:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007645",
        "document": "1115074609",
        "fullName": "Jose Albert Rojas Gonzalez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "2/2/2021 5:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0026",
        "document": "1115074171",
        "fullName": "Diego German Gallego Bedoya",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/2/2021 5:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006878",
        "document": "1005538827",
        "fullName": "Julian Andres Sanchez Urrego",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/3/2021 2:36:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007086",
        "document": "85474411",
        "fullName": "Fray Luis Martinez Venera",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/3/2021 3:06:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005605",
        "document": "1102387728",
        "fullName": "BC Jaime Andres Bohorquez Parra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Compras",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/5/2021 3:09:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005793",
        "document": "38363407",
        "fullName": "Olga Lucia Herran Ramirez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Asesor Tecn De Clientes De Porcicul",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "2/9/2021 1:16:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005788",
        "document": "1099377118",
        "fullName": "Miguel Albeiro Jaimes Fuentes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/10/2021 12:18:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005819",
        "document": "1098774065",
        "fullName": "Miguel Angel Rondon Angarita",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico Institucional",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "2/11/2021 2:50:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005820",
        "document": "1005543129",
        "fullName": "Maryori Tatiana Contreras Aparicio",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Contabilidad",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Contabilidad",
        "fechaIngreso": "2/11/2021 2:50:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005826",
        "document": "1005542493",
        "fullName": "Ana Maria Perez Grimaldos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/11/2021 2:50:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005840",
        "document": "91046243",
        "fullName": "Jaime Andres Suarez Alvarez",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/16/2021 3:58:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006496",
        "document": "1113669252",
        "fullName": "Santiago Jose Velez Jaramillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Facturacion",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "2/17/2021 7:38:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0130",
        "document": "1115064650",
        "fullName": "Angie Estephanie Sanchez Rios",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/22/2021 3:58:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005858",
        "document": "1001575780",
        "fullName": "Johan Manuel Vargas Vergara",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/24/2021 12:01:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005893",
        "document": "43639594",
        "fullName": "Eliana Ospina Builes",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinadora Sst",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Salud Ocupacional",
        "fechaIngreso": "3/9/2021 7:05:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006717",
        "document": "94421440",
        "fullName": "Jehinher Farid Gomez Cabrera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Trafico",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/10/2021 9:41:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005902",
        "document": "1005653700",
        "fullName": "Jhonatan Steven Alvear Guzman",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/12/2021 2:15:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9041",
        "document": "73569285",
        "fullName": "Edinson  Romero Guerrero",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9038",
        "document": "1102865394",
        "fullName": "Aider Torres Torres Oviedo",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9053",
        "document": "73232365",
        "fullName": "Jose Joaquin Rodelo Bustillo",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9049",
        "document": "1128324247",
        "fullName": "Juan Alberto Lopez Garcia",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9031",
        "document": "1143414402",
        "fullName": "Daniel De Jesus Ocampo Laverde",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9036",
        "document": "1047377380",
        "fullName": "Juan Carlos Segura Simancas",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9057",
        "document": "1047444216",
        "fullName": "Edwin  Ibañez Lopez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9048",
        "document": "73353669",
        "fullName": "Jaison Herrera Moreno",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9032",
        "document": "73351467",
        "fullName": "Jose Luis Perez Castro",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9051",
        "document": "73148976",
        "fullName": "Alfonso  Narvaez Romero",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9028",
        "document": "1143370090",
        "fullName": "Juan Pablo Lemos Orozco",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9026",
        "document": "73352613",
        "fullName": "Franklin Eduardo Gordon Castro",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9056",
        "document": "1043298177",
        "fullName": "Fabio Andres Martinez Urango",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9054",
        "document": "1048603329",
        "fullName": "Jose Luis Rodriguez Garces",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9034",
        "document": "73215323",
        "fullName": "Daniel  Ramos Soto",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9021",
        "document": "9287247",
        "fullName": "Amauri Gabriel Arnedo Alvarez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9045",
        "document": "73270000",
        "fullName": "Fredy Jose Batista Ospino",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9046",
        "document": "1047405764",
        "fullName": "Dany Damian Chico Acosta",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9037",
        "document": "73352960",
        "fullName": "Jose Francisco Tejedor Ospino",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9044",
        "document": "1051447950",
        "fullName": "Daimar Ariza Herrera",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005198",
        "document": "1005271537",
        "fullName": "Joser Sneider Rueda Amaya",
        "company": "Transgraneles",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9033",
        "document": "73353135",
        "fullName": "Alvaro Perez Osuna",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9030",
        "document": "73352460",
        "fullName": "Daniel Antonio Marrugo Madera",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9052",
        "document": "1126248270",
        "fullName": "Deivis Antonio Rico Rizo",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9029",
        "document": "73352643",
        "fullName": "Juan Bautista Meza Cervantes",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9022",
        "document": "1047481221",
        "fullName": "Larry Eduardo Acosta Montero",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9023",
        "document": "7958086",
        "fullName": "Zegrith  Ballestas Caballero",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9043",
        "document": "1047425021",
        "fullName": "Remberto  Alcazar Durango",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9050",
        "document": "1101440232",
        "fullName": "Orlis  Martinez Chiquillo",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009864",
        "document": "51964741",
        "fullName": "Rubiela  Rojas Cabezas",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Aseo Y Cafeteria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9040",
        "document": "73132248",
        "fullName": "Oscar  Iriarte Palma",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9035",
        "document": "73186547",
        "fullName": "Santiago Enrique Rivas Perez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9024",
        "document": "1051447027",
        "fullName": "Pedro Manuel Barrios Garcia",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9055",
        "document": "1072248027",
        "fullName": "William  Rojas Navarro",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9027",
        "document": "73351621",
        "fullName": "Rafael Enrique Herrera Castro",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9025",
        "document": "1047373056",
        "fullName": "Yuver  Blanco Moguea",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/15/2021 2:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005919",
        "document": "1101154273",
        "fullName": "Pedro Fernando Rueda Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Ambiental Y Sostenibilidad",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/18/2021 9:46:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006998",
        "document": "1102377727",
        "fullName": "Monica Julieth Ovideo Grimaldos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/18/2021 10:16:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005921",
        "document": "52634268",
        "fullName": "Ana Elsa Parra Duran",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "3/19/2021 1:13:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006013",
        "document": "1102383467",
        "fullName": "Jhon Freddy Tarazona Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/19/2021 4:33:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006440",
        "document": "16794842",
        "fullName": "Jorge Alberto Quintero Cifuentes",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "3/19/2021 10:34:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005936",
        "document": "37440051",
        "fullName": "Gloria Ines Granados Ordoñez",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "3/24/2021 3:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005042",
        "document": "1193260986",
        "fullName": "BC Estefania  Henao Alvarez (Shared)",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Compensaciones",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Compensaciones",
        "fechaIngreso": "3/25/2021 1:56:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006411",
        "document": "8028065",
        "fullName": "Fabio Nelson Velasquez Ortiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/25/2021 2:26:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005939",
        "document": "1098262295",
        "fullName": "Willinton Ferney Poveda Mantilla",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "3/25/2021 7:57:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005945",
        "document": "1143164617",
        "fullName": "Jorge Mario Barcasnegra Cuentas",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/25/2021 7:57:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005950",
        "document": "1094663500",
        "fullName": "Jesus Alberto Ochoa Rojas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/25/2021 7:57:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005960",
        "document": "1102354009",
        "fullName": "Jose Gregorio Hernandez Mejia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/26/2021 3:45:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006869",
        "document": "1101520591",
        "fullName": "Angela Emilce Diaz Carvajal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/26/2021 8:15:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007654",
        "document": "91531664",
        "fullName": "Oscar Eduardo Cartagena Duran",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/31/2021 1:18:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00588",
        "document": "1102375227",
        "fullName": "Jasbleidi Yuliana Florez Duarte",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/31/2021 1:48:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00834",
        "document": "1102377767",
        "fullName": "Maria Fernanda Jerez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/31/2021 9:18:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005972",
        "document": "1098614497",
        "fullName": "Carlos Miguel Daza Parra",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "4/5/2021 12:57:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006661",
        "document": "1116158026",
        "fullName": "Lina Maria Marin Trochez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Ssta",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "4/8/2021 1:03:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005987",
        "document": "27281483",
        "fullName": "Leidy Yohana Burbano Gomez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Tecnico Junior",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "4/9/2021 2:05:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005993",
        "document": "1090503601",
        "fullName": "Marlon Antonio Carvajal Lazaro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/9/2021 2:05:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004965",
        "document": "1006230551",
        "fullName": "Lizeth Mirledy Gallego Vargas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Supernumerario",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "4/14/2021 1:13:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006011",
        "document": "1096219196",
        "fullName": "Saul  Cuadros Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/16/2021 3:28:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007354",
        "document": "1097096256",
        "fullName": "Jhojan Gonzalo Gomez Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/16/2021 7:58:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006167",
        "document": "1127614647",
        "fullName": "Juan Carlos Buelvas Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/16/2021 9:59:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0132",
        "document": "1020466636",
        "fullName": "Vanessa  Ruiz Rojas",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Laboratorista",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/19/2021 4:04:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006044",
        "document": "1018431454",
        "fullName": "Leidy Katherine Vanegas Montiel",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/27/2021 3:00:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006056",
        "document": "1116613578",
        "fullName": "Durley Andrea Barrera Florez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/27/2021 3:00:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006047",
        "document": "1102354250",
        "fullName": "Wilmar  Rios Quintero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2021 3:30:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006063",
        "document": "1018491832",
        "fullName": "Melanie Jurado Orduña",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "4/28/2021 5:02:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006061",
        "document": "80280980",
        "fullName": "Jose Alberto Zabala Parra",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "4/29/2021 4:15:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006057",
        "document": "1093753184",
        "fullName": "Miguel Angel Carreño Cornejo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/30/2021 12:17:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0179",
        "document": "1035851207",
        "fullName": "Carlos Alejandro Correa Castrillon",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/30/2021 1:47:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0160",
        "document": "1005684176",
        "fullName": "Luis Alberto Perez",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/30/2021 1:47:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007483",
        "document": "1066526587",
        "fullName": "Angel Emiro Vega Castellon",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "4/30/2021 1:47:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006004",
        "document": "1115074930",
        "fullName": "Carlos Alberto Reyes Piedrahita",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/30/2021 4:17:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006003",
        "document": "1116262101",
        "fullName": "Luis Miguel Piedrahita Paredes",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/30/2021 4:17:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006001",
        "document": "1115092586",
        "fullName": "Daniel Isaac Ballesteros Castañeda",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/30/2021 4:17:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005990",
        "document": "1143982809",
        "fullName": "Esteban  Arboleda Sarasty",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "4/30/2021 4:17:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005997",
        "document": "14799150",
        "fullName": "Deivy  Florez Torres",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder 3",
        "fechaIngreso": "4/30/2021 4:17:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005999",
        "document": "1115091098",
        "fullName": "Jhon Hary Restrepo Castillo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "4/30/2021 4:17:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006030",
        "document": "1038360539",
        "fullName": "Juan Camilo Parra Avendaño",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/30/2021 4:17:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006071",
        "document": "1118530367",
        "fullName": "Jose Abdul Rios Osorio",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "5/4/2021 3:39:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006070",
        "document": "1007987294",
        "fullName": "Daniel De Jesus Redondo Urueta",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/4/2021 3:39:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006086",
        "document": "1101697743",
        "fullName": "Andres Enrique Arguello Brito",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/7/2021 5:04:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007639",
        "document": "1099368250",
        "fullName": "Ruben Dario Carreño Ramirez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/7/2021 7:04:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007647",
        "document": "1007715506",
        "fullName": "Juan Carlos Gomez Caballero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/7/2021 7:04:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004389",
        "document": "1102371980",
        "fullName": "BC Sandra Milena Gomez Plata (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asistente Gerencia",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/11/2021 2:52:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006105",
        "document": "1102366178",
        "fullName": "Gilberto  Bueno Arguello",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/11/2021 5:22:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005193",
        "document": "1083039212",
        "fullName": "Yuri Katherine Tarazona Chavez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "5/13/2021 3:52:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006117",
        "document": "1098750388",
        "fullName": "Daniel Harif Rodriguez Joya",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "5/13/2021 4:52:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006094",
        "document": "1096953580",
        "fullName": "Miguel Antonio Florez Garcia",
        "company": "Distraves",
        "regional": "Bari",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "5/13/2021 4:52:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006140",
        "document": "1193541183",
        "fullName": "Valentina  Bayona Jaimes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/24/2021 3:36:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006144",
        "document": "1097333193",
        "fullName": "Yineth Fernanda Olaya Sedano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Produccion Post-proceso",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/25/2021 1:16:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006134",
        "document": "27815339",
        "fullName": "Nidia Quintero Bayona",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/25/2021 2:01:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0180",
        "document": "1238938050",
        "fullName": "Manuel Jose Correa Escudero",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/25/2021 2:01:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006840",
        "document": "1073237235",
        "fullName": "Luis Carlos Duque Ortega",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/26/2021 1:33:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007429",
        "document": "1235042391",
        "fullName": "Ana Victoria Sanjuan Batista",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Coordinador(a) Sst",
        "uen": "Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/31/2021 1:18:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006121",
        "document": "1095940331",
        "fullName": "Carlos Andres Romero Garcia",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/31/2021 8:51:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006162",
        "document": "1090394910",
        "fullName": "Andrea Alexandra Prada Forero",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Administrador Punto De Venta - Entrenamiento",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/1/2021 2:22:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006169",
        "document": "1127593043",
        "fullName": "Haddy Carolina Trujillo Triviño",
        "company": "Distraves",
        "regional": "Chia",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "6/1/2021 2:52:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006183",
        "document": "1152446019",
        "fullName": "Luz Adriana Restrepo Mejia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Procesos",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Procesos",
        "area": "Procesos",
        "fechaIngreso": "6/3/2021 12:58:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003956",
        "document": "1193530972",
        "fullName": "Jesus Manuel Sanchez Mercado",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "6/16/2021 3:40:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006218",
        "document": "1095909317",
        "fullName": "Oscar Ivan Rueda Quintero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Comercial - Entrenamiento",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2021 4:10:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007428",
        "document": "1050949310",
        "fullName": "Maura Paola Ramos Julio",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Secretaria Ii",
        "uen": "Talento Humano",
        "process": "Talento Humano",
        "area": "Servicios Compartidos",
        "fechaIngreso": "6/17/2021 8:49:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006230",
        "document": "1102549775",
        "fullName": "Maria Fernanda Macias Amaya",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "6/21/2021 2:46:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006232",
        "document": "73205117",
        "fullName": "Jony Romero Anaya",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/22/2021 12:50:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006228",
        "document": "1116274957",
        "fullName": "Marco Jared Cuellar Salazar",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/22/2021 1:21:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005136",
        "document": "1098800844",
        "fullName": "Johan Sebastian Castellanos Leon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "6/24/2021 3:03:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005427",
        "document": "1096235661",
        "fullName": "Sneyder  Herazo Zarate",
        "company": "Temporales",
        "regional": "DPDTE",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/29/2021 8:26:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00617",
        "document": "1102362199",
        "fullName": "Edwing Paez Carreño",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/6/2021 8:07:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007297",
        "document": "1098759570",
        "fullName": "Eimar Fernando Cepeda Hernandez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "7/6/2021 9:07:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008731",
        "document": "91517758",
        "fullName": "Sedfredy Estevez Lopez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/6/2021 9:07:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011044",
        "document": "1101686267",
        "fullName": "David Fernando Ordoñez Valor",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "7/6/2021 9:07:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01033",
        "document": "1098783975",
        "fullName": "Darly Katherin Avellaneda Montealegre",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/6/2021 10:38:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006863",
        "document": "1033657504",
        "fullName": "Cristian Hernandez Higuita",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "7/7/2021 12:41:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008269",
        "document": "1113041533",
        "fullName": "Luis fernando Sanchez Caicedo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "7/7/2021 9:43:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007783",
        "document": "91003461",
        "fullName": "Carlos Humberto Leon Marin",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/7/2021 9:43:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0381",
        "document": "1017217861",
        "fullName": "Daniela  Rojas Buitrago",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar Laft",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/7/2021 9:43:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008076",
        "document": "1115064725",
        "fullName": "Jonny David Ramirez Parra",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "7/7/2021 9:43:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9059",
        "document": "14395235",
        "fullName": "Alexander  Palomino Palacios",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/7/2021 9:43:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9061",
        "document": "94471073",
        "fullName": "Camilo Arturo Zuñiga Henao",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/7/2021 9:43:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008271",
        "document": "1114454293",
        "fullName": "Carlos Dario Piedrahita Perez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "7/7/2021 9:43:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006267",
        "document": "91350266",
        "fullName": "Eduardo  Santos Macareo",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/8/2021 2:45:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006258",
        "document": "1098821894",
        "fullName": "BC Dayana Marcela Bonnet Hernandez (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Comercial",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/8/2021 2:45:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006241",
        "document": "1095459063",
        "fullName": "Edison  Orozco Gallo",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/8/2021 2:45:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006235",
        "document": "13539592",
        "fullName": "Carlos Alberto Sanmiguel Delgado",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "7/8/2021 2:45:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006209",
        "document": "1095917863",
        "fullName": "Olga Cecilia Jaimes Pinto",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "7/8/2021 3:15:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006252",
        "document": "1005549282",
        "fullName": "Smith Lorena Naranjo Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "7/8/2021 3:15:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006208",
        "document": "1102362873",
        "fullName": "Franqui Yesid Romero Mantilla",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/8/2021 3:15:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000142",
        "document": "1102373334",
        "fullName": "Cristian Ramirez Rivera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "7/8/2021 3:45:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007916",
        "document": "1005539004",
        "fullName": "Yenid Marcela Ramirez Rivera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "7/8/2021 3:45:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01279",
        "document": "1102354162",
        "fullName": "Yurley Rocio Sandoval Rangel",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Plata",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/8/2021 6:15:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007210",
        "document": "1095813205",
        "fullName": "Martha Milena Vasquez Mogollon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "7/8/2021 6:45:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007001",
        "document": "1102389474",
        "fullName": "Jesus David Peña Araque",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/8/2021 7:45:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9064",
        "document": "8706106",
        "fullName": "Felipe  Agudelo Restrepo",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/9/2021 12:47:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006882",
        "document": "1005369753",
        "fullName": "Camilo Andrey Sanabria Pinzon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "7/12/2021 12:46:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006924",
        "document": "91046843",
        "fullName": "Juan Gabriel Gallo Melgarejo",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "7/13/2021 1:18:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006910",
        "document": "91446993",
        "fullName": "Pablo Antonio Vasquez Guzman",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/13/2021 1:18:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006911",
        "document": "1067714073",
        "fullName": "Dionys  Rangel Pinzon",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/13/2021 1:18:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006919",
        "document": "1007334532",
        "fullName": "Carlos Mario Perez Pacheco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/13/2021 1:18:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006930",
        "document": "1073174833",
        "fullName": "Luis Alejandro Murillo Cubillos",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "7/16/2021 1:24:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006921",
        "document": "1095825475",
        "fullName": "Camilo Andres Puerto Rodriguez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "7/16/2021 1:24:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006932",
        "document": "1069872793",
        "fullName": "Johan Arley Olaya Guerra",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "7/16/2021 1:24:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006981",
        "document": "1102379769",
        "fullName": "Julieth Esmeralda Albarracin Osorio",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/27/2021 7:13:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006675",
        "document": "1113540537",
        "fullName": "Lina Marcela Mazuera Martinez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Inventarios",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/28/2021 11:16:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006992",
        "document": "1102368807",
        "fullName": "Zayda Gisell Herrera Arias",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/29/2021 1:17:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006986",
        "document": "1083469246",
        "fullName": "Lian Antonio Carbono Campo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "7/29/2021 1:17:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006988",
        "document": "1095458339",
        "fullName": "Juan Carlos Celis Solano",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/29/2021 1:17:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006991",
        "document": "1102383677",
        "fullName": "Jeferson Alexis Pedraza Guarin",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/29/2021 1:17:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006977",
        "document": "91474488",
        "fullName": "Richar Diaz Vargas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "7/30/2021 9:19:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007016",
        "document": "1020481671",
        "fullName": "Yelizeth  Builes Henao",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "8/3/2021 12:26:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007009",
        "document": "1005287997",
        "fullName": "Julieth Vanesa Ardila Perez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/3/2021 12:26:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007017",
        "document": "8162760",
        "fullName": "Jorge Armando Alvarez Muñoz",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "8/3/2021 12:26:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00129",
        "document": "1085289888",
        "fullName": "Ivan Dario Jojoa Luna",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/5/2021 1:05:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9067",
        "document": "1085332835",
        "fullName": "Freddy Albeiro Narvaez Rojas",
        "company": "Temporales",
        "regional": "buga",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/5/2021 1:35:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006406",
        "document": "94501050",
        "fullName": "Omar Fernando Velasco Soto",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Tecnico De Bascula",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "8/6/2021 1:06:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00135",
        "document": "1085279299",
        "fullName": "Jonny Alexander Delgado Mora",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/6/2021 7:37:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00117",
        "document": "1085292432",
        "fullName": "Andres Felipe Nasamues Ceballos",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/6/2021 8:07:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0226",
        "document": "185320125",
        "fullName": "Erik Jonathan Timara Carlosama",
        "company": "Temporales",
        "regional": "buga",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/6/2021 8:07:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0205",
        "document": "1114456976",
        "fullName": "Juan Carlos Bolaños",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/6/2021 8:07:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006969",
        "document": "18646402",
        "fullName": "Javier Alejandro Gonzalez Campos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Tecnico Mecanico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/10/2021 12:44:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007048",
        "document": "43871401",
        "fullName": "Angelica  Sanchez Zuluaga",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Asistente De Presidencia",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "8/10/2021 12:59:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00355",
        "document": "1004410301",
        "fullName": "William Hernan Lopez Mena",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/10/2021 12:59:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007045",
        "document": "1056930911",
        "fullName": "Carlos Ferney Acuña Becerra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "8/10/2021 1:29:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007055",
        "document": "1102380587",
        "fullName": "Yeison Jahir Camacho Velasco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "8/11/2021 4:53:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007060",
        "document": "1102364779",
        "fullName": "Carlos Andres Rondon Moreno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "8/11/2021 4:53:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007052",
        "document": "1098826672",
        "fullName": "Maria Jose Arenas Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "8/11/2021 4:53:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007068",
        "document": "91045031",
        "fullName": "Nestor Villamizar Marquez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "8/13/2021 4:48:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007066",
        "document": "1005539284",
        "fullName": "Diego Andres Ortiz Poveda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "8/13/2021 4:48:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006604",
        "document": "1007431808",
        "fullName": "Yary Johanna Hurtado Cordoba",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Digitador",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/13/2021 7:47:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006304",
        "document": "1113539599",
        "fullName": "Juan Esteban Asprilla Hurtado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Procesos Productivos",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "8/13/2021 8:12:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00116",
        "document": "1016102387",
        "fullName": "Brayan Andres Zambrano Botina",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/18/2021 12:05:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007092",
        "document": "1039470962",
        "fullName": "Santiago Villa Uribe",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "8/18/2021 4:06:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007090",
        "document": "1005411327",
        "fullName": "Carlos Julio Zambrano Diaz",
        "company": "Distraves",
        "regional": "Elpl",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "8/23/2021 2:25:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007098",
        "document": "1113536617",
        "fullName": "Pedro Nel Ortiz Pasuy",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "8/23/2021 5:44:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005653",
        "document": "1098813340",
        "fullName": "Silvia Juliana Monsalve Gutierrez",
        "company": "Temporal",
        "regional": "Giro",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/23/2021 8:18:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007102",
        "document": "1095833209",
        "fullName": "Jhon Edwar Parejo Rapalino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "8/24/2021 1:19:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007034",
        "document": "1095948610",
        "fullName": "Dailer  Sandoval Silva",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "8/24/2021 1:49:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007114",
        "document": "1102353930",
        "fullName": "Robinson Javier Florez Morales",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "8/25/2021 2:22:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007103",
        "document": "1037072744",
        "fullName": "Lina Yarixa Agudelo Buritica",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "8/25/2021 2:22:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007119",
        "document": "1102369467",
        "fullName": "Alexis Pinto Carreño",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "8/26/2021 1:54:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007135",
        "document": "1098675473",
        "fullName": "Lenny Farley Galvis Chaparro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/2/2021 4:45:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007145",
        "document": "1102376123",
        "fullName": "Andres Felipe Ramirez Londoño",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/2/2021 4:45:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007157",
        "document": "1099373770",
        "fullName": "Laura Juliana Gonzalez Orozco",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Profesional En Formacion",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/2/2021 4:45:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007140",
        "document": "1102388442",
        "fullName": "Jhon Dairon Carreño Monsalve",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/2/2021 4:45:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007147",
        "document": "1098823982",
        "fullName": "Paula Andrea Rey Ramirez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Liquidador Cartera",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "9/2/2021 4:45:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007163",
        "document": "1020103420",
        "fullName": "Manuela Montoya Vanegas",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Corporativo Transgraneles",
        "process": "Corporativo Transgraneles",
        "area": "Corporativo Transgraneles",
        "fechaIngreso": "9/6/2021 12:57:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007161",
        "document": "1143396466",
        "fullName": "Richard Andres Turizo Pinilla",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Suministros Y Servicios",
        "fechaIngreso": "9/6/2021 1:27:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007097",
        "document": "1058461079",
        "fullName": "Cristian David Lopez Martinez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "9/6/2021 3:27:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00037",
        "document": "1001774492",
        "fullName": "Sara Valentina Giron Londoño",
        "company": "Transgraneles-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Administrativa",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/6/2021 9:58:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00124",
        "document": "1085315757",
        "fullName": "Felipe Alexis Carlosama Quistial",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/6/2021 11:29:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007229",
        "document": "1005542284",
        "fullName": "Jaiber Gustavo Castellanos Monsalve",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/7/2021 4:42:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004356",
        "document": "1005563985",
        "fullName": "Maria Amaya Moreno",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/7/2021 7:13:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007288",
        "document": "1005039003",
        "fullName": "Cristofer Andres Herrera Andrade",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/7/2021 8:12:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010351",
        "document": "1102393037",
        "fullName": "Maryury Yuleisi Jerez Chanaga",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/7/2021 8:12:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007601",
        "document": "1102369565",
        "fullName": "Erika Nayibeth Quiñonez Conde",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/8/2021 4:53:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005706",
        "document": "1098783025",
        "fullName": "Jeider Andres Torres Bohorquez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/8/2021 5:24:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007175",
        "document": "1113677089",
        "fullName": "Erika Vanessa Paez Chacon",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Practicante",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/8/2021 10:38:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007173",
        "document": "6803232",
        "fullName": "Liderman  Cediel Vargas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "9/8/2021 10:38:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007437",
        "document": "1095919048",
        "fullName": "Yolanda Moreno Aza",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "9/9/2021 3:10:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007737",
        "document": "13871034",
        "fullName": "Gustavo Rojas Moreno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/9/2021 9:11:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007353",
        "document": "1102365734",
        "fullName": "Carlos Armando Palacios Parra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/9/2021 9:11:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01137",
        "document": "1005176363",
        "fullName": "Javier Esmeral Rodriguez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2021 9:41:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0037",
        "document": "1232890814",
        "fullName": "Duvan Alfonso Arias Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico de Mantenimineto",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/13/2021 5:23:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007199",
        "document": "1102390887",
        "fullName": "Cristian Fernando Alvarez Tarazona",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/13/2021 5:23:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007194",
        "document": "37748987",
        "fullName": "Claudia Milena Porras Plata",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Ejecutiva Comercial Retail",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/13/2021 5:23:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007205",
        "document": "11448355",
        "fullName": "Victor Leonardo Sanchez Sanchez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "9/13/2021 5:53:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007180",
        "document": "1020448345",
        "fullName": "Gustavo Adolfo Rojo Zea",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "9/13/2021 5:53:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007042",
        "document": "1042764238",
        "fullName": "Miguel Angel Betancur Patiño",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Auxiliar De Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Ganadería",
        "fechaIngreso": "9/13/2021 5:53:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007039",
        "document": "1007643527",
        "fullName": "Maria Jose Bedoya Estrada",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Asistente Comercial",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "9/13/2021 5:53:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007123",
        "document": "1073506895",
        "fullName": "John Alexander Cantor Juyo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "9/13/2021 5:53:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007054",
        "document": "1004913195",
        "fullName": "Jesus Moises Silva Sanguino",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "9/13/2021 5:53:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006289",
        "document": "808483204031990",
        "fullName": "Denny David Angulo Perez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador De Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/14/2021 4:26:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007207",
        "document": "1129501762",
        "fullName": "Brenda Del Carmen Real Diaz",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "9/14/2021 4:56:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007213",
        "document": "1193116396",
        "fullName": "Aldair  Acelas Rincon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/14/2021 4:56:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00608",
        "document": "1007735388",
        "fullName": "Maicol Estiben Martinez Ramirez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/14/2021 4:56:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006798",
        "document": "1113512037",
        "fullName": "Yuliani Andrea Cordoba Cardenas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Procesos Productivos",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006780",
        "document": "16536607",
        "fullName": "Wilman Wiesner Castillo Cortes",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006483",
        "document": "1115083846",
        "fullName": "Yeimi Vanessa Roldan Mora",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006599",
        "document": "94475263",
        "fullName": "Wildenman  Herrera Cifuentes",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Consola",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006855",
        "document": "1112223033",
        "fullName": "Wilson  Banguera Hurtado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006550",
        "document": "16832675",
        "fullName": "Willan  Mosquera Ulabares",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006602",
        "document": "6445065",
        "fullName": "William Antonio Hidrobo Parra",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006595",
        "document": "12281450",
        "fullName": "Oscar  Paz Grueso",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Patiero",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006857",
        "document": "1005784474",
        "fullName": "Yesid David Bravo Tafurt",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Tecnico Mecanico",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/16/2021 3:03:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006350",
        "document": "66877875",
        "fullName": "Yobany  Carabali Dinas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006670",
        "document": "1113655837",
        "fullName": "Yamileth  Martinez Ruiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006903",
        "document": "1113656055",
        "fullName": "Paola Andrea Charry Hernandez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006608",
        "document": "71933230",
        "fullName": "Ricardo  Ibarguen Mendoza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006503",
        "document": "1130654878",
        "fullName": "Paula Eugenia Villafane Cosme",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Servicios Generales",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006965",
        "document": "829550709091980",
        "fullName": "Pedro Gerardo Rivero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006295",
        "document": "1113523827",
        "fullName": "Rodrigo  Arboleda Hurtado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006833",
        "document": "4957457",
        "fullName": "Samir  Diaz Victoria",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario Planta Ptar",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006296",
        "document": "16915374",
        "fullName": "Ray Leonard Arboleda Solis",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006552",
        "document": "6223421",
        "fullName": "Rolando  Mosquera Viveros",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006565",
        "document": "18144548",
        "fullName": "Renato  Niaza Guasiruma",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006861",
        "document": "1113536484",
        "fullName": "Sebastian  Zarama Pizarro",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006292",
        "document": "66969876",
        "fullName": "Sandra Milena Arara Campo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006510",
        "document": "1193339684",
        "fullName": "Santiago  Wallens Urrea",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006732",
        "document": "1143981775",
        "fullName": "Robin Andres Gonzalez Sanchez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006482",
        "document": "1087007126",
        "fullName": "Segundo Alfredo Rojas Mueses",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006395",
        "document": "1007352339",
        "fullName": "Sebastiab Enrique Varela Barona",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006708",
        "document": "1112467823",
        "fullName": "Robin Orlando Garcia Sandoval",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006315",
        "document": "29520134",
        "fullName": "Rosalbina  Beltran Moreno",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006286",
        "document": "1086725220",
        "fullName": "Sandra Yessenia Angulo Hurtado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006905",
        "document": "1115092207",
        "fullName": "Sebastian  Cortes Hernandez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006386",
        "document": "94298866",
        "fullName": "Robinson  Usa Garcia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006316",
        "document": "1113536016",
        "fullName": "Valeria  Benitez Granados",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006269",
        "document": "15261699",
        "fullName": "Sigifredo  Acevedo Caro",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Control Del Riesgo",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006318",
        "document": "1112482370",
        "fullName": "Maicol Steven Bermudez Pillimue",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006279",
        "document": "1088281104",
        "fullName": "Victor Alfonso Alcaraz Grajales",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006297",
        "document": "1054917547",
        "fullName": "Wilder Leandro Arenas Zapata",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006317",
        "document": "83044797",
        "fullName": "Sneider  Bermeo Medina",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006323",
        "document": "1113656743",
        "fullName": "Victor Alfonso Bolanoss Erazo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006291",
        "document": "38878490",
        "fullName": "Luz Dary Arango Lotero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006526",
        "document": "1111779246",
        "fullName": "Manuel Eduardo Mir Valverde",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006696",
        "document": "1113536764",
        "fullName": "Victor Andres Fernandez Lopez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006353",
        "document": "1113526443",
        "fullName": "Stefania  Sanchez Andrea",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006968",
        "document": "1006362869",
        "fullName": "Victor Manuel Diaz Valencia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006288",
        "document": "29349563",
        "fullName": "Luz Aida Angulo Panameno",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006811",
        "document": "1127944662",
        "fullName": "Luz Deybis Cruz Dominguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006775",
        "document": "6333243",
        "fullName": "Manuel Jose Casaran Viveros",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006640",
        "document": "87473062",
        "fullName": "Tito Hernan Lopez Burbano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006610",
        "document": "94466130",
        "fullName": "Victor Salusito Idrobo Cruz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Caldera",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006722",
        "document": "94268544",
        "fullName": "Luvian  Gomez Riveros",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Conductor",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006828",
        "document": "66969930",
        "fullName": "Mabel Sorelly Davila Martinez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006566",
        "document": "66968104",
        "fullName": "Luz Elena Nieto Idrobo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006805",
        "document": "29349784",
        "fullName": "Marlin Xiomara Cosme Candelo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006692",
        "document": "1079180990",
        "fullName": "Maria Teresa Fajardo Palomino",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006302",
        "document": "16715388",
        "fullName": "Miguel Angel Arredondo Escobar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006402",
        "document": "6325576",
        "fullName": "Norber  Vasquez Corral",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006825",
        "document": "1006362958",
        "fullName": "Nancy Lorena Culchac Pistala",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006801",
        "document": "29756864",
        "fullName": "Maria Bellanid Correa Ramirez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006542",
        "document": "1113530039",
        "fullName": "Nelly Cristina Moreno Angulo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006504",
        "document": "1006372244",
        "fullName": "Miguel Angel Villalobos Rengifo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006399",
        "document": "96340923",
        "fullName": "Noe  Vargas Rojas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006859",
        "document": "1113538498",
        "fullName": "Michael  Leon Acevedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006799",
        "document": "29683171",
        "fullName": "Maria Elena Cordoba Silva",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006303",
        "document": "6228909",
        "fullName": "Nelson Ovidio Arteaga Vallejos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Mensajero Cobrador",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006477",
        "document": "1116158081",
        "fullName": "Miguel Enrique Rodriguez Rivera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006414",
        "document": "10499662",
        "fullName": "Mario Henry Pazu Vargas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006685",
        "document": "1113530141",
        "fullName": "Marisol  Escobar Larrahondo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006561",
        "document": "29510420",
        "fullName": "Maria Liliana Murillo Moreno",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006522",
        "document": "1151957479",
        "fullName": "Milton Andres Mesa Agredo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario Planta Agua Potable",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006705",
        "document": "1113535021",
        "fullName": "Michael Steven Garcia Miramag",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006734",
        "document": "29347678",
        "fullName": "Maritza  Gordon Marroquin",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007221",
        "document": "1083913029",
        "fullName": "Natalia  Becerra Muñoz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006320",
        "document": "1059900670",
        "fullName": "Miguel Andres Bermudez Torres",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta De Hielo",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006476",
        "document": "1113525381",
        "fullName": "Miguel Angel Rodriguez Pino",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006791",
        "document": "1122133695",
        "fullName": "Juan Sebastian Colorado Aranda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Conductor",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006689",
        "document": "16824499",
        "fullName": "Omar Enrique Escobar Sarria",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006312",
        "document": "1193073966",
        "fullName": "Oscar Daniel Bastidas Ceballos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006559",
        "document": "1113695174",
        "fullName": "Juan Diego Munoz Valencia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006441",
        "document": "6213324",
        "fullName": "Julio Ernesto Quintero Galeano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006383",
        "document": "1193573009",
        "fullName": "Omar  Uragama Pipicay",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006777",
        "document": "14700553",
        "fullName": "Julian Alexander Castiblanco Tascon",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006967",
        "document": "1114457305",
        "fullName": "Oscar Eduardo Castro Berrio",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006767",
        "document": "1115085273",
        "fullName": "Juan Gabriel Cardona Cardona",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006971",
        "document": "1115091263",
        "fullName": "Orlando  Barreto Vanegas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006684",
        "document": "10541761",
        "fullName": "Julio Neftaly Escobar Gutierrez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006423",
        "document": "6274086",
        "fullName": "Orlando De Jesus Perez Ortiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006674",
        "document": "1006103161",
        "fullName": "Juan Jose Mazuera Amaya",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006434",
        "document": "1006286845",
        "fullName": "Juan Camilo Prado Valdez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006456",
        "document": "25529199",
        "fullName": "Julieta  Renteria Ordonez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006663",
        "document": "1113678887",
        "fullName": "Karen Patricia Marquez Riascos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006298",
        "document": "1107085188",
        "fullName": "Juan Carlos Arias Arango",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006466",
        "document": "76344566",
        "fullName": "Luis Fernando Rivera Barvaez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006629",
        "document": "926090813021993",
        "fullName": "Kheismer  Linarez Crespo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006287",
        "document": "1006286612",
        "fullName": "Lina Fernanda Angulo Longa",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006824",
        "document": "1127948479",
        "fullName": "Luis Guillermo Cuesta Saa",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006963",
        "document": "1113541932",
        "fullName": "Kevin Andres Sandoval Mosquera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006794",
        "document": "1113524405",
        "fullName": "Larry  Colorado Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006964",
        "document": "1130950147",
        "fullName": "Luis Eduardo Cuellar Valenzuela",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006641",
        "document": "1113514601",
        "fullName": "Lina Marcela Lopez Castillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006716",
        "document": "94042003",
        "fullName": "Luis Eduardo Gomez Arias",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006336",
        "document": "1114873752",
        "fullName": "Kevin Daniel Caicedo Ortiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006607",
        "document": "16480027",
        "fullName": "Luis Enrique Ibarguen Mendoza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006324",
        "document": "1113519485",
        "fullName": "Leydi Yoana Bonilla Balanta",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006397",
        "document": "94277218",
        "fullName": "Luis Antonio Varela Tenorio",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006854",
        "document": "1113534685",
        "fullName": "Leonardo  Mateus Vargas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Inventarios",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006536",
        "document": "1012428711",
        "fullName": "Kevin Eduardo Montoya Villanueva",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006321",
        "document": "1144211682",
        "fullName": "Luis Camilo Betancourt Dorado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006830",
        "document": "6115103",
        "fullName": "Leonardo  Diaz Franco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006413",
        "document": "66878031",
        "fullName": "Liliana  Velazco Sandra",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006275",
        "document": "94482660",
        "fullName": "Jose Julian Agudelo Salazar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006788",
        "document": "1118471021",
        "fullName": "Jonattan Alexis Chaux Ordonez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007187",
        "document": "1193114732",
        "fullName": "Johan Alejandro Becerra Martinez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006509",
        "document": "6405923",
        "fullName": "Joao Artur Viveros Pastes",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Electrico Granjas",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006564",
        "document": "14802379",
        "fullName": "Jose Aymer Naspiran Marquez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006649",
        "document": "16824367",
        "fullName": "Jose Leider Lucumi Ambuila",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007044",
        "document": "1035641746",
        "fullName": "Jose Alfredo Galeano Hernandez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006709",
        "document": "1116445405",
        "fullName": "Jorge Luis Garcia Tabares",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006947",
        "document": "1112230679",
        "fullName": "Jose Armando Arboleda Rivas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007033",
        "document": "1006247110",
        "fullName": "Jose Bernardo Magon Gonzalez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006769",
        "document": "9910436",
        "fullName": "Jose Alexander Cardona Tapasco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006593",
        "document": "78292770",
        "fullName": "Jorge Luis Paternina Martinez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006343",
        "document": "87432133",
        "fullName": "Jose Juan Canticus Pai",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007029",
        "document": "94539374",
        "fullName": "Jorge Esneider Lopez Bermudez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006822",
        "document": "1113701598",
        "fullName": "Jonaikel Antonio Cuesta Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006726",
        "document": "1114822086",
        "fullName": "Jonathan  Gonzalez Gaviria",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006653",
        "document": "936861322051980",
        "fullName": "Jonathan Eduardo Lutzardo Mejia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006648",
        "document": "14651111",
        "fullName": "Hernes  Lucumi Ambuila",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00259",
        "document": "16771546",
        "fullName": "Jose Leonardo Naranjo Jaramillo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Domiciliario",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006341",
        "document": "16822981",
        "fullName": "Jose Uriel Campo Artunduaga",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006642",
        "document": "1039290045",
        "fullName": "Jacob  Lopez Gomez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006772",
        "document": "16832662",
        "fullName": "Helmer  Carreno Chaguendo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006750",
        "document": "1006216902",
        "fullName": "Jose Liberato Guevara Bermudez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Pollinaza",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006403",
        "document": "1080262847",
        "fullName": "Juan Andres Vega Victoria",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006300",
        "document": "6221688",
        "fullName": "Humberto  Arrechea Moreno",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006693",
        "document": "815437103121992",
        "fullName": "Henry Junior Farias Torres",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006680",
        "document": "5204932",
        "fullName": "Heriberto Andres Enriquez Lopez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006819",
        "document": "1112227997",
        "fullName": "Jose Martiniano Cuero Quintero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Pollinaza",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006792",
        "document": "1112475582",
        "fullName": "Jose Miguel Colorado Carabali",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006816",
        "document": "1007679827",
        "fullName": "Juan Camilo Cuellar Vargas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006356",
        "document": "821423620051977",
        "fullName": "Jose Luis Sanchez Vergara",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Cuartos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006728",
        "document": "1112226520",
        "fullName": "Hernan Mauricio Gonzalez Gomez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007074",
        "document": "1113530614",
        "fullName": "Jose Wilson Camilo Sandoval",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006339",
        "document": "16840558",
        "fullName": "Israel  Camelo Obando",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Conductor",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007095",
        "document": "14895567",
        "fullName": "Hervis  Nuñez Quevedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006904",
        "document": "1113521764",
        "fullName": "Jose Orlando Avila Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006378",
        "document": "80364591",
        "fullName": "Jair  Toro Munoz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006783",
        "document": "6536910",
        "fullName": "Jairo Humberto Celis Veira",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006460",
        "document": "1064490152",
        "fullName": "Jaider Andres Riascos Ordonez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006803",
        "document": "1060359745",
        "fullName": "Jannin Mabel Cortes Leon",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor De Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006301",
        "document": "1003153305",
        "fullName": "Jhon Freddy Arrechea Nunez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Pollinaza",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006511",
        "document": "1114893290",
        "fullName": "Jeferson Aldiber Yule Ramos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006538",
        "document": "14878797",
        "fullName": "Jairo  Morales Escobar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006405",
        "document": "14279859",
        "fullName": "Jesus Yamil Velasco Manunga",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006779",
        "document": "1151937094",
        "fullName": "Jefri Manuel Castillo Corredor",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007027",
        "document": "1113535233",
        "fullName": "Jairo Andres Canon Nieva",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006587",
        "document": "6404135",
        "fullName": "Jesus Antonio Paredes Dominguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006851",
        "document": "1193259714",
        "fullName": "Jheysil Nicole Upegui Rodas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006546",
        "document": "1113697389",
        "fullName": "Jan Carlos Mosquera Arboleda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006821",
        "document": "1112225889",
        "fullName": "Jhon Alexis Cuero Segura",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006374",
        "document": "1114839889",
        "fullName": "Jaime Andres Tenorio Aranda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006573",
        "document": "76289058",
        "fullName": "Jaime  Ocoro Mosquera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006814",
        "document": "1193030179",
        "fullName": "Jhon Alexander Cruz Vivas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Digitador",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006497",
        "document": "1193509410",
        "fullName": "Javier  Velez Montano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006385",
        "document": "66877517",
        "fullName": "Jacqueline  Urrego Moreno",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Almacén",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006891",
        "document": "94456212",
        "fullName": "Jhonn  Mina Valencia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007168",
        "document": "1114454428",
        "fullName": "Jhon Fredy Largo Orrego",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006567",
        "document": "16897555",
        "fullName": "Edinson  Noscue Jhon",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006695",
        "document": "10721163",
        "fullName": "Jhon Jairo Fernandez Daza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006768",
        "document": "94061619",
        "fullName": "Edgar Humberto Cardona Cruz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006627",
        "document": "1114838825",
        "fullName": "Jhon Jairo Leon Chicue",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007094",
        "document": "1113788130",
        "fullName": "Jhonathan Arlex Garcia Agudelo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006793",
        "document": "29346720",
        "fullName": "Elizabeth  Colorado Ledesma",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006810",
        "document": "1127942244",
        "fullName": "Elizabeth  Cruz Dominguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006686",
        "document": "6333203",
        "fullName": "Edwin Nolberto Escobar Ledesma",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006523",
        "document": "16826699",
        "fullName": "Ernesto  Micolta",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006724",
        "document": "1089801043",
        "fullName": "Elkin Alexander Gonzales Sinisterra",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006474",
        "document": "94468574",
        "fullName": "Erminso  Rodriguez Minotta",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006467",
        "document": "1113534833",
        "fullName": "Estiben Andres Rivera Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006334",
        "document": "6221399",
        "fullName": "Efrain Orduy Caicedo Gomez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006438",
        "document": "1058726048",
        "fullName": "Ezio Bernardo Puni Collazos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006702",
        "document": "1112460982",
        "fullName": "Fabian  Garces Troches",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006563",
        "document": "1112484474",
        "fullName": "Geison  Narvaez Jimenez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Pollinaza",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006541",
        "document": "1005874688",
        "fullName": "Guillermo  Moreno Acevedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Tecnico Mecanico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006416",
        "document": "1113532185",
        "fullName": "Harold Andres Pecillo Santacruz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006763",
        "document": "10496071",
        "fullName": "Hector Fabio Caracas Carvajal",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X9065",
        "document": "87061068",
        "fullName": "Felipe  Agudeloo Restrepo",
        "company": "Temporales",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006759",
        "document": "1143932709",
        "fullName": "Hector Fabio Hernandez Hernandez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006727",
        "document": "94519731",
        "fullName": "Gustavo Adolfo Gonzalez Gil",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006820",
        "document": "1112227715",
        "fullName": "Fernando  Cuero Segura",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006394",
        "document": "1113686776",
        "fullName": "Gina Marcela Valencia Velasquez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Practicante",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007172",
        "document": "1006287357",
        "fullName": "Hector Fabian Marin Salgar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Nomina",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006352",
        "document": "6226955",
        "fullName": "Helberth  Sanchez Aguirre",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006337",
        "document": "94042445",
        "fullName": "Camilo Alfredo Caicedo Otero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006537",
        "document": "94304951",
        "fullName": "Gonzalo  Montoya Zambrano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Conductor",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006426",
        "document": "725445107011989",
        "fullName": "Gustavo Antonio Perez Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Consola",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006277",
        "document": "14607274",
        "fullName": "Carlos Alberto Aguirre Pulgarin",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006729",
        "document": "14650631",
        "fullName": "Heison  Gonzalez Lucumi",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006625",
        "document": "94470507",
        "fullName": "Hebert Andres Lenis Izquierdo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Oficios Varios",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006676",
        "document": "94476167",
        "fullName": "Gustavo  Mejia Marin",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006664",
        "document": "16863672",
        "fullName": "Hector Fabian Martinez Aranda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006847",
        "document": "12240177",
        "fullName": "Cesar  Zuniga Julio",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006580",
        "document": "1006364963",
        "fullName": "Carlos Andres Ortiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006465",
        "document": "1192767636",
        "fullName": "Cruz Aner Rivas Zuniga",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006449",
        "document": "1130672534",
        "fullName": "Carlos Alberto Ramirez Payan",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007079",
        "document": "1114458090",
        "fullName": "Carlos Alfonso Zapata Monedero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006657",
        "document": "1006247358",
        "fullName": "Dabian Edilson Marin Ceballos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006431",
        "document": "1113673514",
        "fullName": "Cristian Andres Ponce Buenaventura",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Conductor",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006974",
        "document": "1013340792",
        "fullName": "Carlos Andres Blandon Cordoba",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006367",
        "document": "1046273096",
        "fullName": "Daimer Alberto Suarez Herrera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007096",
        "document": "1113618431",
        "fullName": "Deisy  Sepulveda Mosquera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006313",
        "document": "1113693572",
        "fullName": "David Alejandro Bautista Quintero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006557",
        "document": "1114339820",
        "fullName": "Constantino  Munoz Rivera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006890",
        "document": "18612771",
        "fullName": "Daniel Antonio Taborda Atehortua",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006713",
        "document": "63538092",
        "fullName": "Derlia De Jesus Gil Sepulveda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006852",
        "document": "1007194048",
        "fullName": "Diego Alejandro Llanos Aquite",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006656",
        "document": "1060237347",
        "fullName": "Alvaro Jose Manquillo Pizo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006290",
        "document": "1143925519",
        "fullName": "Alejandro  Angulo Velasco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Digitador",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006598",
        "document": "1113525628",
        "fullName": "Diana Alexandra Hernandez Viveros",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006574",
        "document": "66876155",
        "fullName": "Ana Leonor Ojeda Tavera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006278",
        "document": "66768758",
        "fullName": "Alexandra  Ahumada Burbano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006617",
        "document": "1112475947",
        "fullName": "Alex Yovan Jimenez Garcia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Tecnico Mecanico",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006525",
        "document": "1073683152",
        "fullName": "Alexander  Mina Escobar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006972",
        "document": "94256242",
        "fullName": "Duber De Jesus Burgos Diaz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006682",
        "document": "94467642",
        "fullName": "Alexander  Escobar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006322",
        "document": "1110479201",
        "fullName": "Dianey  Betancourth Sepulveda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006377",
        "document": "16845455",
        "fullName": "Alfredo  Toro Jose",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta De Hielo",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007038",
        "document": "94040540",
        "fullName": "Diego Fernando Tamayo Figueroa",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006359",
        "document": "16895984",
        "fullName": "Diego Fernando Segura Castro",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007077",
        "document": "1045252000",
        "fullName": "Alexander  Garces Santiago",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "9/16/2021 3:03:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006306",
        "document": "66966567",
        "fullName": "Ana Ruby Avendano Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006615",
        "document": "1116447358",
        "fullName": "Ana Yamile Jaramillo Dosman",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Digitador",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006948",
        "document": "1113525326",
        "fullName": "Andres Alejandro Luligo Chalapud",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006349",
        "document": "66876604",
        "fullName": "Ana Zulema Carabali Dinas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006419",
        "document": "1007735724",
        "fullName": "Anyela Dahyana Penafiel Rosero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006906",
        "document": "1114059598",
        "fullName": "Andres Adolfo Martinez Diaz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006400",
        "document": "1143964821",
        "fullName": "Arles  Varona David",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006528",
        "document": "1107534767",
        "fullName": "Ariel  Miranda Ospino",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006707",
        "document": "6548954",
        "fullName": "Arley  Garcia Sanchez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Soldador",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006621",
        "document": "1062308912",
        "fullName": "Andres Felipe Lalinde Ariza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006730",
        "document": "6413045",
        "fullName": "Antonio  Gonzalez Marco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Pollinaza",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006368",
        "document": "16802280",
        "fullName": "Ariz Fernando Suaza Henao",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Mensajero Cobrador",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006751",
        "document": "94551239",
        "fullName": "Arley  Guevara Vasquez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Tecnico De Refrigeracion",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007076",
        "document": "1007431828",
        "fullName": "Anyi Tatiana Valencia Cambindo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006643",
        "document": "1004546582",
        "fullName": "Andres Hernan Lopez Higido",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006624",
        "document": "16834705",
        "fullName": "Ariel  Lasso Obregon",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006421",
        "document": "1114812452",
        "fullName": "Angel  Perdomo Miguel",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006628",
        "document": "16832704",
        "fullName": "Antonio  Lerma Alvaro",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Pollinaza",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006737",
        "document": "66967110",
        "fullName": "Adriana  Granada Castillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/16/2021 3:03:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006605",
        "document": "16847592",
        "fullName": "Armando  Hurtado Jorge",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006520",
        "document": "10488871",
        "fullName": "Alber  Meneses Jose",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006508",
        "document": "1114954303",
        "fullName": "Armando  Viveros Hugo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006966",
        "document": "16829224",
        "fullName": "Abel  Clavijo Diaz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/16/2021 3:03:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006577",
        "document": "1061601093",
        "fullName": "Ader Raul Ordonez Florez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta De Hielo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/16/2021 3:03:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006725",
        "document": "1005875930",
        "fullName": "Adrian Adolfo Gonzalez Cobo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/16/2021 3:03:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006530",
        "document": "94297568",
        "fullName": "Alberto  Monroy",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/16/2021 3:03:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007227",
        "document": "1102390961",
        "fullName": "Carlos Andres Vera Palomino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/20/2021 1:09:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004343",
        "document": "1006491807",
        "fullName": "Laura Viviana Montoya Otalvaro",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "9/20/2021 10:36:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007230",
        "document": "1063616196",
        "fullName": "Jose Dario Sepulveda Villany",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/22/2021 2:04:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007245",
        "document": "1102390776",
        "fullName": "Brayan Yesid Rodriguez Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Canastas",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/27/2021 1:51:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007247",
        "document": "80512985",
        "fullName": "John Gabriel Chaparro Macias",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "9/27/2021 1:51:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00125",
        "document": "1095827121",
        "fullName": "Jorge Andres Carvajal Robles",
        "company": "Distraves-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Mantenimiento",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/27/2021 1:51:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007262",
        "document": "1098771001",
        "fullName": "Nury Marcela Jerez Prada",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Formacion, Desarrollo Y Bienestar",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Formacion Y Desarrollo",
        "fechaIngreso": "9/28/2021 3:07:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007270",
        "document": "1102392661",
        "fullName": "Jose Alexander Castro Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/29/2021 1:09:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007264",
        "document": "1099374626",
        "fullName": "William Ortiz Picon",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/29/2021 1:09:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007282",
        "document": "1005280663",
        "fullName": "Robert Duvan Acevedo Herrera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/1/2021 7:44:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007309",
        "document": "1095813990",
        "fullName": "Jeison Orlando Parada Caballero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/1/2021 7:44:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007311",
        "document": "5692400",
        "fullName": "Marcelino  Pinzon Barajas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/1/2021 7:44:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007521",
        "document": "1098637426",
        "fullName": "Adonay Dominguez Lopez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/4/2021 3:50:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007321",
        "document": "1095936231",
        "fullName": "Samuel David Gonzalez Vanegas",
        "company": "Transgraneles",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "10/8/2021 12:51:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007313",
        "document": "1113525214",
        "fullName": "Vladimir  Joyas Samboni",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "10/11/2021 2:46:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007335",
        "document": "43839794",
        "fullName": "Margarita Maria Restrepo Molina",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Suministros Y Servicios",
        "fechaIngreso": "10/11/2021 9:16:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007322",
        "document": "1007715565",
        "fullName": "Jheyson Andres Cañas Chuscano",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Comercial",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "10/12/2021 7:48:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007326",
        "document": "1005149425",
        "fullName": "Paula Andrea Caceres Quintero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/12/2021 7:48:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007327",
        "document": "1007917844",
        "fullName": "Yurley Andrea Arias Martinez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/12/2021 7:48:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007277",
        "document": "94469236",
        "fullName": "Jaime Antonio Mamian Gomez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/12/2021 8:48:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007329",
        "document": "94478182",
        "fullName": "Alberto  Pineda Aristizabal",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "10/12/2021 8:48:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007238",
        "document": "1113531248",
        "fullName": "Jhonatan Stiven Mosquera Carabali",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/12/2021 8:48:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007278",
        "document": "1114454376",
        "fullName": "Ingrid Liced Velez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/12/2021 8:48:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007244",
        "document": "1007396783",
        "fullName": "Victor Alonso Cobo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/12/2021 8:48:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007533",
        "document": "1053808545",
        "fullName": "Angela Yulieth Giraldo Rojas",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Suministros Y Servicios",
        "fechaIngreso": "10/14/2021 3:37:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007359",
        "document": "10288846",
        "fullName": "Jorge Ivan Velez Diez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/14/2021 3:48:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007349",
        "document": "1098732558",
        "fullName": "Leidy Dayanna Ramirez Valderrama",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Impulsador Profesional",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "10/18/2021 2:17:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007383",
        "document": "1005654049",
        "fullName": "Maria Del Carmen Villegas Acevedo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/20/2021 12:57:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007389",
        "document": "1095945945",
        "fullName": "Maria Fernanda Calderon Meza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/21/2021 12:55:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007385",
        "document": "1005541541",
        "fullName": "Juan Camilo Delgado Ropero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/21/2021 12:55:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007379",
        "document": "1001360354",
        "fullName": "Sergio Gomez Gaviria",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/21/2021 1:25:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00643",
        "document": "37746938",
        "fullName": "Marisol Velandia Galvis",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/21/2021 8:33:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007400",
        "document": "1099369334",
        "fullName": "Edinson Augusto Oviedo Martinez",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/26/2021 3:41:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007398",
        "document": "1099370372",
        "fullName": "Cristian Orlando Delgado Lozada",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/26/2021 3:41:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007348",
        "document": "1096242659",
        "fullName": "Cristian David Garcia Agudelo",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "10/27/2021 2:18:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007404",
        "document": "1099369674",
        "fullName": "Luz Marina Machuca Pita",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/27/2021 4:38:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007408",
        "document": "1005321152",
        "fullName": "Karol Andrea Verano Piña",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Analista Inventarios Y Operaciones",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "10/27/2021 4:38:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007415",
        "document": "1005449306",
        "fullName": "Cristian Jaimes Beltran",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/27/2021 4:38:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005706",
        "document": "1098783025",
        "fullName": "Jaider Andres Torres Bohorquez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/28/2021 8:10:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007461",
        "document": "1017215158",
        "fullName": "Yudy Alejandra Ruiz Holguin",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Transgraneles",
        "process": "Corporativo Transgraneles",
        "area": "Corporativo Transgraneles",
        "fechaIngreso": "11/3/2021 11:32:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007457",
        "document": "1053857462",
        "fullName": "Sara Camila Gutierrez Garcia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Medico Veterinario",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "11/3/2021 12:15:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007456",
        "document": "1102368606",
        "fullName": "Gerson Andres Rincon Pinzon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/3/2021 2:16:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007447",
        "document": "1116642341",
        "fullName": "Edwin Gilberto Angel Zanabria",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "11/3/2021 2:16:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007441",
        "document": "1071431081",
        "fullName": "Jamer  Altamiranda Vargas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "11/3/2021 2:46:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007433",
        "document": "1098607487",
        "fullName": "Ruben  Leguizamo Barreto",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "11/3/2021 2:46:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007439",
        "document": "1005540171",
        "fullName": "Patricia  Palomino Palomino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/3/2021 2:46:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007416",
        "document": "1099375293",
        "fullName": "Jorge Armando Delgado Jurado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "11/3/2021 4:46:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007468",
        "document": "1026145771",
        "fullName": "Wilmar  Castañeda Soto",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Datos De Avicultura",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Nutrición",
        "area": "Avicultura",
        "fechaIngreso": "11/4/2021 2:55:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007355",
        "document": "1095949441",
        "fullName": "Ricardo  Cordero Jaimes",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/5/2021 4:12:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007477",
        "document": "1073705622",
        "fullName": "BC Angie Paola Escobar Saba (Shared)",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Auxiliar Talento Humano",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/8/2021 1:55:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007474",
        "document": "1036685370",
        "fullName": "Sebastian  Alvarez Jaramillo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Supernumerario",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Tesoreria",
        "fechaIngreso": "11/8/2021 1:55:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006960",
        "document": "63539513",
        "fullName": "Jadis Patricia Martinez Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/8/2021 8:55:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006959",
        "document": "1102383558",
        "fullName": "Ingrid Mayerly Jerez Ramirez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/8/2021 9:25:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007482",
        "document": "1005549139",
        "fullName": "Juan David Pelayo Torres",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "11/9/2021 1:57:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007494",
        "document": "1102371401",
        "fullName": "Nelson Ricardo Leon Mendoza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "11/10/2021 2:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007500",
        "document": "1096952670",
        "fullName": "Edinson Libardo Uribe Galvis",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "11/10/2021 2:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007506",
        "document": "60449692",
        "fullName": "Ana Maria Jimenez Zapata",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Oficial De Cumplimiento",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "11/17/2021 3:10:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007511",
        "document": "1097305754",
        "fullName": "Jose David Jaimes Ortega",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/18/2021 2:03:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007512",
        "document": "13539190",
        "fullName": "Efrain Suarez Hernandez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/18/2021 2:03:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007495",
        "document": "31588627",
        "fullName": "Edith Paola Riofrio Rojas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "11/24/2021 12:50:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007526",
        "document": "1083922777",
        "fullName": "Karen Marcela Ortiz Montealegre",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Importaciones",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Internacionales",
        "area": "Comercio Exterior",
        "fechaIngreso": "11/24/2021 12:50:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00551",
        "document": "1095952807",
        "fullName": "Leidy Tatiana Vanegas Sanchez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Liquidador De Cartera",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/26/2021 8:09:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007527",
        "document": "1098764557",
        "fullName": "Leidy Tatiana Barajas Zambrano",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Jefe Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "11/26/2021 8:09:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007528",
        "document": "1098824416",
        "fullName": "BC Lizeth Natalia Sierra Castellanos (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/26/2021 8:09:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008077",
        "document": "1116267652",
        "fullName": "Angelica Johanna Gutierrez Oviedo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Talento Humano",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "11/29/2021 5:03:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007585",
        "document": "1144168069",
        "fullName": "Christian Fernando Rosero Gonzalez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "11/29/2021 5:03:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007565",
        "document": "9467245",
        "fullName": "Diego Alejandro Tarazona Cobaria",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/29/2021 7:43:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007556",
        "document": "1007536710",
        "fullName": "Jose Luis Sepulveda Ortiz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Planeacion Financiera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Planeacion Financiera",
        "fechaIngreso": "11/29/2021 7:55:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007568",
        "document": "1102380422",
        "fullName": "Luis Alberto Acosta Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/29/2021 8:31:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007549",
        "document": "1017231975",
        "fullName": "Braian Camilo Marquez Hernandez",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/29/2021 8:31:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007566",
        "document": "1102381797",
        "fullName": "Juan David Otalvarez Leon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/29/2021 8:31:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007563",
        "document": "1005479190",
        "fullName": "Elizabeth  Valencia Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/29/2021 8:31:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007553",
        "document": "1102386451",
        "fullName": "Silvia Patricia Palomino Uribe",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/29/2021 9:03:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007564",
        "document": "91161109",
        "fullName": "Oscar Mauricio Davila Solano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/29/2021 9:03:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007514",
        "document": "1102716137",
        "fullName": "Victor Alberto Vanegas Alvarez",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/29/2021 9:03:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00013",
        "document": "10999707",
        "fullName": "Felipe Carlos Delgado Macea",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Coordinador Del Cedi",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/29/2021 9:14:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007587",
        "document": "1005025667",
        "fullName": "Juan Pablo Torres Contreras",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "12/1/2021 5:23:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007598",
        "document": "1005540718",
        "fullName": "Maria Jose Barraza Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/1/2021 5:23:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007589",
        "document": "1099368131",
        "fullName": "Jesus Alexander Diaz Camacho",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/2/2021 1:25:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007604",
        "document": "13276073",
        "fullName": "Asdrubal  Hernandez Pumarejo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/2/2021 1:25:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005741",
        "document": "1094247277",
        "fullName": "Sain Bernardo Torres Hoyos",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Inventarios",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "12/2/2021 2:26:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007644",
        "document": "1066095062",
        "fullName": "Angei Raquel Camacho Urdaneta",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/6/2021 5:09:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006731",
        "document": "5001772128",
        "fullName": "Roberto Enrique Gonzalez Matheus",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/6/2021 8:40:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007575",
        "document": "93235329",
        "fullName": "John Edwin Urueña Martinez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador (a) Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "12/6/2021 9:39:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007503",
        "document": "1144065880",
        "fullName": "Ricardo Leon Branch Bermudez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Tecnico Mecanico",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "12/7/2021 3:43:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007427",
        "document": "1107096906",
        "fullName": "Juan Pablo Rosero Martinez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador Pdv Supernumerario",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "12/7/2021 3:43:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007620",
        "document": "1143989084",
        "fullName": "Brigitte Lorena Montilla Rivera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "12/7/2021 3:43:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007425",
        "document": "1112472664",
        "fullName": "Christian David Ortega Semanate",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/7/2021 3:43:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007623",
        "document": "1113521365",
        "fullName": "Ceron Arboleda Richard",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/7/2021 3:43:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007614",
        "document": "1049646179",
        "fullName": "Edgar Eduardo Rincon Florez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Medico Veterinario",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "12/7/2021 3:43:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007459",
        "document": "1084228549",
        "fullName": "Brayan David Guerrero Carlosama",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "12/7/2021 3:43:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007357",
        "document": "1085299161",
        "fullName": "Beatriz Elena Matabanchoy Betancourth",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/7/2021 3:43:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007612",
        "document": "1036660188",
        "fullName": "Sebastian  Montoya Penagos",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "12/7/2021 7:56:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007529",
        "document": "1102381510",
        "fullName": "Mariela  Barragan Diaz",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Recepcionista",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "12/7/2021 7:56:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007611",
        "document": "1017178031",
        "fullName": "Luisa Maria Muñoz Ayala",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "12/7/2021 7:56:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007680",
        "document": "1086223653",
        "fullName": "John Hanz Santacruz Santacruz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Operaciones",
        "area": "Operaciones",
        "fechaIngreso": "12/13/2021 8:19:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007637",
        "document": "1100972020",
        "fullName": "Ayra Valentina Chaparro Quintero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Comercial",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "12/13/2021 8:19:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007650",
        "document": "1098787469",
        "fullName": "Andrey Yezid Sanabria Toloza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/13/2021 8:19:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007652",
        "document": "1102391869",
        "fullName": "Ivan Dario Salazar Tirado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/13/2021 8:19:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007642",
        "document": "1007358603",
        "fullName": "Ismael  Robles Ortiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/13/2021 8:19:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007635",
        "document": "1098694407",
        "fullName": "Jose Yeison Moreno Valencia",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/13/2021 8:19:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007667",
        "document": "1113625926",
        "fullName": "Eliana Andrea Hernandez Castañeda",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Impulsador Profesional",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "12/14/2021 12:46:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007648",
        "document": "1092671213",
        "fullName": "Derso Ballesteros Nuñez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/15/2021 2:00:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007690",
        "document": "1134179351",
        "fullName": "Sergio Duban Archila Sanchez",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/18/2021 4:42:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005501",
        "document": "1037949034",
        "fullName": "Johan Rene Zapata Montoya",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "12/20/2021 1:21:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007704",
        "document": "1003594947",
        "fullName": "Keyvi Andres Altamiranda Vargas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/20/2021 2:21:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007695",
        "document": "1049023556",
        "fullName": "Luz Elena Velasquez Villasmil",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/20/2021 2:21:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007630",
        "document": "1115083009",
        "fullName": "Edgar Yesid Moreno Betancourt",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "12/20/2021 10:07:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007668",
        "document": "1085333709",
        "fullName": "Silvia Vanesa Villota Pantoja",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Impulsador Profesional",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "12/20/2021 10:07:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005037",
        "document": "1115079710",
        "fullName": "Jesus Alberto Tocanchon Pacheco",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "12/20/2021 10:07:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007663",
        "document": "1115078963",
        "fullName": "Andres Felipe Tarazona Herrera",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "12/20/2021 10:07:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007643",
        "document": "1114062707",
        "fullName": "Sebastian  Rojas Romero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Mascotas",
        "fechaIngreso": "12/20/2021 10:51:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007712",
        "document": "1100393463",
        "fullName": "Abel Jose Rojas Castillo",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/21/2021 12:32:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007689",
        "document": "1093222538",
        "fullName": "Carlos Daniel Vargas Bermudez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Procesos",
        "area": "Procesos",
        "fechaIngreso": "12/21/2021 5:01:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007717",
        "document": "1002461938",
        "fullName": "Juan Jose Diaz Ochoa",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/21/2021 8:01:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007744",
        "document": "1102721056",
        "fullName": "Ivan  Toloza Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/22/2021 4:35:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007730",
        "document": "37618576",
        "fullName": "Maria Elena Rojas Rincon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/22/2021 4:35:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007747",
        "document": "1098790996",
        "fullName": "Robinson Humberto Ramirez Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Bodega",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/23/2021 4:08:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007746",
        "document": "63453286",
        "fullName": "Maria Isabel Palomino Roa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/23/2021 4:08:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007745",
        "document": "1005540020",
        "fullName": "Jesus Manuel Martinez Arenas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/23/2021 4:08:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007738",
        "document": "1102363753",
        "fullName": "Sergio Rodriguez Contreras",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/23/2021 5:08:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00210",
        "document": "1114833665",
        "fullName": "Diana Milena Cardona Portilla",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar Logistico",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "12/24/2021 4:31:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007710",
        "document": "1113692212",
        "fullName": "Laura Vanessa Hurtado Ocmapo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Enfriamiento",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/24/2021 5:04:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007755",
        "document": "1116263368",
        "fullName": "Gilberto  Millan Medina",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "12/29/2021 2:28:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003734",
        "document": "46455160",
        "fullName": "Nubia Rocio Alfonso Estepa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "12/30/2021 5:02:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007814",
        "document": "71773531",
        "fullName": "Alejandro Gomez Jaramillo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Vicepresidente Financiero Y De Ti",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "1/3/2022 12:38:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X0044",
        "document": "1116277876",
        "fullName": "Karol Liceth Bedoya Cardona",
        "company": "Temporales",
        "regional": "",
        "jobTitle": "Auxiliar Sst",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/3/2022 9:08:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007770",
        "document": "72302276",
        "fullName": "Sergio Alonso Pantoja Molina",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Representante De Ventas",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "1/5/2022 1:39:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007678",
        "document": "1026598796",
        "fullName": "Johan Mateo Chara Callejas",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "1/5/2022 2:52:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007670",
        "document": "1070980897",
        "fullName": "John Alexander Riaño Castañeda",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/5/2022 2:58:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007756",
        "document": "1115067519",
        "fullName": "Miguel Angel Ceron Sanchez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "1/5/2022 3:29:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007754",
        "document": "1116158742",
        "fullName": "Luis Felipe Fernandez Polanco",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "1/5/2022 3:29:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007787",
        "document": "1050398146",
        "fullName": "Wilder  Soto Cordero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/11/2022 12:59:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006160",
        "document": "1102377212",
        "fullName": "Yesica Ayala Pimiento",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Contabilidad",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/11/2022 7:30:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007797",
        "document": "1052394325",
        "fullName": "Andres Felipe Madrid Simbaqueba",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Supervisor Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/12/2022 1:02:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007811",
        "document": "88192924",
        "fullName": "Alvaro  Estevez Porras",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "1/12/2022 1:02:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007801",
        "document": "1098628155",
        "fullName": "Yimar De Jesus Osma Solano",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "1/12/2022 1:33:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007808",
        "document": "1004823906",
        "fullName": "Edwin  Lasso Torrado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "1/12/2022 1:33:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002162",
        "document": "1099371522",
        "fullName": "Dainer Joaquin Mendez Ardila",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/12/2022 4:33:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00353",
        "document": "1085251683",
        "fullName": "Oscar Giovany Chañac Molina",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/12/2022 7:33:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00183",
        "document": "1112468263",
        "fullName": "Nelsy Patricia Burbano Epe",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "1/13/2022 4:06:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00094",
        "document": "1007213002",
        "fullName": "Sandra Milena Montoya Londoño",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/17/2022 1:26:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00647",
        "document": "1101321516",
        "fullName": "Hernando  Zambrano Jimenez",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/17/2022 8:57:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007829",
        "document": "1026149069",
        "fullName": "Juan Camilo Uribe Chica",
        "company": "Solla",
        "regional": "Rion",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Centro De Investigación Canina",
        "fechaIngreso": "1/18/2022 2:59:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01008",
        "document": "1007429290",
        "fullName": "Andres Felipe Becerra Ramirez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Nomina",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/18/2022 3:29:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007854",
        "document": "1052405914",
        "fullName": "Angie Marcela Puentes Carvajal",
        "company": "Distraves",
        "regional": "Soga",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "1/18/2022 3:59:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007831",
        "document": "1005448631",
        "fullName": "Nicolas  Ortega Buitrago",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "1/18/2022 3:59:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007844",
        "document": "1233899864",
        "fullName": "Ingrid Johanna Dangond Ferrucho",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Vinculación Y Seguridad Social",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "1/18/2022 3:59:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007840",
        "document": "1099368725",
        "fullName": "Estefani  Gonzalez Garces",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/18/2022 3:59:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00648",
        "document": "1095944335",
        "fullName": "Luisa Fernanda Roa Angarita",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Compras",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/18/2022 10:20:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007828",
        "document": "1089196730",
        "fullName": "Fredy Jeferson Telag Garreta",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Suministros Y Servicios",
        "fechaIngreso": "1/20/2022 1:07:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007819",
        "document": "1007972924",
        "fullName": "Jose Junior Berrio Martinez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/20/2022 1:07:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010269",
        "document": "1102396203",
        "fullName": "Ismael Emilio Suescun Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Bodega",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "1/20/2022 7:26:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007884",
        "document": "1007624907",
        "fullName": "John Cristian Hernandez Castellon",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/25/2022 5:05:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007877",
        "document": "1131504061",
        "fullName": "Anderson Gutierrez Suarez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "1/26/2022 12:53:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007893",
        "document": "63519145",
        "fullName": "Ana Milena Solano Serrano",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "1/26/2022 12:53:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007874",
        "document": "52986518",
        "fullName": "Judy Lizeth Alarcon Pineda",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "1/26/2022 12:53:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007799",
        "document": "16925241",
        "fullName": "Francisco David Cadena Ceron",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "1/31/2022 3:17:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007861",
        "document": "94421611",
        "fullName": "Jose Reinel Londoño Velasco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "1/31/2022 3:17:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007660",
        "document": "1192747403",
        "fullName": "Jerson Arley Lasprilla Reyes",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/31/2022 3:17:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007659",
        "document": "1114888888",
        "fullName": "Orlando David Garay Ocampo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta De Hielo",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/31/2022 3:17:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007824",
        "document": "1010025005",
        "fullName": "Jhon Edinson Hurtado Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/31/2022 3:17:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007858",
        "document": "4613491",
        "fullName": "Jose Leonardo Villada Ramirez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "1/31/2022 3:17:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007810",
        "document": "1113517925",
        "fullName": "Jorge Luis Quintana Mondragon",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/31/2022 3:17:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007817",
        "document": "1113541324",
        "fullName": "Jhonnier Steiner Montes Correa",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "1/31/2022 3:17:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007672",
        "document": "6221481",
        "fullName": "Jorge Oswaldo Canizales",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/31/2022 3:17:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007661",
        "document": "1113513804",
        "fullName": "Fabian Andres Sarasty Guerrero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/31/2022 3:17:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007768",
        "document": "1113515935",
        "fullName": "Alexander  Victoria Riascos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/31/2022 3:17:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00891",
        "document": "1193386028",
        "fullName": "BC Maria Paula Calvo Ortiz (Shared)",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Administrativa",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/31/2022 3:47:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007872",
        "document": "63539152",
        "fullName": "Adriana Lucia Saavedra Fernandez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Director Otros Canales",
        "uen": "Comercial",
        "process": "Direccion Canal",
        "area": "Direccion Canal",
        "fechaIngreso": "1/31/2022 3:47:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007892",
        "document": "91184107",
        "fullName": "Holguer Mauricio Castro Marquez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/31/2022 3:47:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007880",
        "document": "19790856",
        "fullName": "Edwin De Jesus Reyes Zabaleta",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/31/2022 3:47:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00933",
        "document": "1005326566",
        "fullName": "Silvia Nathalia Peña Paez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Lider Empresarios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/31/2022 4:17:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00718",
        "document": "1005334990",
        "fullName": "Aida Marcela Valencia Bautista",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Tesoreria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/1/2022 5:18:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001530",
        "document": "1081000567",
        "fullName": "Angel David Torres Peña",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "2/2/2022 7:53:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003509",
        "document": "1098618845",
        "fullName": "Eliecer Perez Lizcano",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/7/2022 8:32:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007923",
        "document": "1101321299",
        "fullName": "Sergio Carvajal Marin",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Profesional En Formacion",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/7/2022 8:32:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007863",
        "document": "34323771",
        "fullName": "Doracelly Guevara Guerrero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Cajero",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "2/8/2022 12:54:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007927",
        "document": "1095946538",
        "fullName": "Brandon Yesid Pico Gomez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "2/9/2022 3:12:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007951",
        "document": "1047418487",
        "fullName": "Windy Paola Orozco Atencio",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "2/9/2022 8:43:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00937",
        "document": "1005464940",
        "fullName": "Brayan Sneider Barbosa Osorio",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Mantenimiento Básculas",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2022 7:23:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007995",
        "document": "1095802305",
        "fullName": "Pedro  Poveda Niño",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "2/10/2022 7:53:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007924",
        "document": "1102362783",
        "fullName": "Luis Alberto Leon Lozano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/10/2022 7:53:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008008",
        "document": "1098672827",
        "fullName": "Yudy Andrea Carreño Llanes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/10/2022 7:53:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007962",
        "document": "13724459",
        "fullName": "Omar Rodrigo Becerra Dueñas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/10/2022 7:53:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008000",
        "document": "1102389946",
        "fullName": "Yuri Mayerli Naranjo Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "2/10/2022 7:53:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007997",
        "document": "1152197174",
        "fullName": "Tatiana  Miranda Cuartas",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "2/10/2022 7:53:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007940",
        "document": "1234339050",
        "fullName": "Nelson Fabian Avila Contreras",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/10/2022 7:53:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007917",
        "document": "1005541595",
        "fullName": "Jhon Freddy Alvarez Chaparro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/10/2022 7:53:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007984",
        "document": "1093924867",
        "fullName": "Hamer Anibal Suescun Carrascal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/10/2022 7:53:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008010",
        "document": "1085168760",
        "fullName": "Hernando  Moya Florian",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "2/10/2022 7:53:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008003",
        "document": "1005564011",
        "fullName": "Lizeth Yurany Pinto Carrizalez",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/11/2022 3:26:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007942",
        "document": "1007132417",
        "fullName": "Jose Luis Mendoza Surmay",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/11/2022 3:26:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008029",
        "document": "1098724433",
        "fullName": "Luz Mery Muñoz Sierra",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "2/15/2022 1:04:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008030",
        "document": "1095922203",
        "fullName": "Ciro Alfonso Gomez Cobos",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/15/2022 1:04:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009669",
        "document": "43999413",
        "fullName": "Mariluz Arango Florez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Costos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "2/15/2022 8:05:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008026",
        "document": "94501060",
        "fullName": "Jorge Alberto Hernandez Osorio",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Director Comercial",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "2/15/2022 8:44:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004192",
        "document": "1128458174",
        "fullName": "Duban Ferney Giraldo Gallo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Cartera",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Cartera",
        "area": "Cartera",
        "fechaIngreso": "2/17/2022 1:20:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008063",
        "document": "1005553247",
        "fullName": "Yerli Paola Gomez Lopez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/17/2022 2:51:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008053",
        "document": "1050924053",
        "fullName": "Kenner  Rios Florez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/17/2022 2:51:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008028",
        "document": "1002321463",
        "fullName": "Luis Angel Sanmartin Padilla",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/18/2022 12:20:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008073",
        "document": "1017257826",
        "fullName": "Oscar Ivan Molina Castrillon",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "2/18/2022 12:20:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008037",
        "document": "1113532440",
        "fullName": "Andres Felipe Ortega Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/18/2022 12:20:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008084",
        "document": "55303100",
        "fullName": "Ledis Maria Montero Utria",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/22/2022 1:31:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008079",
        "document": "1095828616",
        "fullName": "Karol Daniela Castillo Mosquera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Gestion Documental",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Centro De Gestion Documental",
        "fechaIngreso": "2/22/2022 1:31:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008036",
        "document": "1113697566",
        "fullName": "Katlyn Linette Pulido Peñaranda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Calidad",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "2/22/2022 4:32:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008096",
        "document": "1005541310",
        "fullName": "Cristian Andres Orduz Santos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "2/22/2022 8:03:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008095",
        "document": "37618236",
        "fullName": "Ledy Johana Delgado Tasco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/22/2022 8:03:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008074",
        "document": "1113648493",
        "fullName": "Wilmer Johan Alegria Salazaar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Jefe De Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "2/23/2022 2:35:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008102",
        "document": "1099372392",
        "fullName": "Zorayda  Herrera Parada",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "2/23/2022 10:37:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008119",
        "document": "1098803219",
        "fullName": "BC Karen Melissa Pedroza Fernandez (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/23/2022 10:37:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008104",
        "document": "91079656",
        "fullName": "Yhon Alexander Ribero Duarte",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Vacunador",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/23/2022 10:37:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008126",
        "document": "1095829466",
        "fullName": "BC Karol Lizeth Gualdron Benitez (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/23/2022 10:37:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008101",
        "document": "1103692131",
        "fullName": "Angel Miro Rojas Sandoval",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/24/2022 7:14:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008076",
        "document": "1115064725",
        "fullName": "Jonny David Ramirez Parra",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "2/25/2022 12:43:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008090",
        "document": "91522366",
        "fullName": "Arley Adolfo Ayala Garrido",
        "company": "Transgraneles",
        "regional": "Giro",
        "jobTitle": "Coordinador Distribución Urbana",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "2/25/2022 1:13:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008078",
        "document": "1114063320",
        "fullName": "Lina Vanessa Ruiz Chacon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Aseo Y Cafeteria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "2/25/2022 1:43:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005730",
        "document": "1003203740",
        "fullName": "Leidy Yurena Tobon Bueno",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Salud Ocupacional",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Salud Ocupacional",
        "fechaIngreso": "2/26/2022 7:47:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00018",
        "document": "57296749",
        "fullName": "Ana Maria Martinez Martinez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/28/2022 12:53:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008134",
        "document": "1005462244",
        "fullName": "Deiby Danilo Ardila Ortega",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/1/2022 7:25:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008143",
        "document": "1050781021",
        "fullName": "Jesus  Crespo Arrieta",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "3/1/2022 7:25:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008139",
        "document": "1096242639",
        "fullName": "Dubant Ronaldo Quiñonez Rangel",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/1/2022 7:25:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008149",
        "document": "1020480586",
        "fullName": "Valeria  Henao Gomez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "3/2/2022 10:03:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01006",
        "document": "1000089767",
        "fullName": "Dayana Muñoz Rave",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Laft",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/3/2022 12:06:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005956",
        "document": "1122124583",
        "fullName": "Evelyn Ailyn Triana Espitia",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Recepcionista",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "3/3/2022 12:36:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008140",
        "document": "1099362705",
        "fullName": "Rolando Ricon Duran",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/4/2022 12:39:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008141",
        "document": "1005110186",
        "fullName": "Edward Arbey Hernandez Cobos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/4/2022 12:39:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008155",
        "document": "1143942818",
        "fullName": "Rigoberto  Valencia Montenegro",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Inventario Gral",
        "uen": "Gerencia General",
        "process": "Control De Inventarios",
        "area": "Control De Inventarios",
        "fechaIngreso": "3/4/2022 1:09:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00716",
        "document": "1098780940",
        "fullName": "Gabriel Mauricio Garcia Urrea",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geografico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/7/2022 9:29:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008184",
        "document": "1112468860",
        "fullName": "Cristian Camilo Rosero Villota",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Comercial",
        "process": "Direccion Canal",
        "area": "Direccion Canal",
        "fechaIngreso": "3/9/2022 9:18:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005343",
        "document": "1062809910",
        "fullName": "Yenis  Sanjuan Duran",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/10/2022 3:20:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008203",
        "document": "1074958654",
        "fullName": "Jesus  Maldonado Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/10/2022 9:12:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008191",
        "document": "1005237272",
        "fullName": "Sergio David Cantillo Vides",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/10/2022 9:12:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008192",
        "document": "1004823838",
        "fullName": "Anyi Paola Gonzalez Serrano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/10/2022 9:12:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008187",
        "document": "1005160742",
        "fullName": "Rosa Maria Suarez Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/10/2022 9:12:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008205",
        "document": "1099369502",
        "fullName": "Hernando  Rey Oviedo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/11/2022 12:46:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008213",
        "document": "1095840738",
        "fullName": "Karen Julieth Villamizar Florez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "3/11/2022 12:46:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008549",
        "document": "1022941396",
        "fullName": "Deycy Johana Guzman Jimenez",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "3/11/2022 1:46:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003684",
        "document": "1005309729",
        "fullName": "Johnson Ivan Yesid Rincon Balaguera",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "3/11/2022 3:16:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008195",
        "document": "1007971268",
        "fullName": "Monica Navarro Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/11/2022 3:16:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1335050",
        "document": "1070964164",
        "fullName": "Leydi Tatiana Vivas Chaparro",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Salud Ocupacional",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Salud Ocupacional",
        "fechaIngreso": "3/14/2022 3:27:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008424",
        "document": "1070964310",
        "fullName": "Andrea Estefania Urrea Patiño",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "3/14/2022 8:28:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008224",
        "document": "1113539003",
        "fullName": "Angie Lorena Velasco Omen",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Nomina",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "3/15/2022 4:19:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008225",
        "document": "91242927",
        "fullName": "Julian Guillermo Nieto Puyana",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Gerente General",
        "uen": "Gerencia General",
        "process": "Gerencia General",
        "area": "Gerencia General",
        "fechaIngreso": "3/16/2022 12:29:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00038",
        "document": "1004410353",
        "fullName": "Harold Hernan Cuaspud Sapuyes",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Operario De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/17/2022 1:33:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008237",
        "document": "37577971",
        "fullName": "Erika Liliana Velasquez Rivera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/17/2022 1:33:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008229",
        "document": "1093762865",
        "fullName": "Yerson Fabian Quitian Osorio",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Analista Auditoria Interna",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "3/17/2022 1:33:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008239",
        "document": "1100972552",
        "fullName": "Yan Carlos Uribe Salazar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/17/2022 1:33:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008242",
        "document": "1098735404",
        "fullName": "Richard  Machado Alarcon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/17/2022 1:33:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008235",
        "document": "1058842176",
        "fullName": "Edis Fernando Martinez Cardona",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Domiciliario",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "3/17/2022 1:33:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008234",
        "document": "1050920912",
        "fullName": "Diego De Jesus Hernandez Arevalo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "3/17/2022 1:33:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008226",
        "document": "18524230",
        "fullName": "Matias  Rivera Carmona",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Ventas",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "3/17/2022 7:56:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008232",
        "document": "67025119",
        "fullName": "Jane  Maya Garcia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador Ssta",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "3/17/2022 7:56:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008241",
        "document": "1102391953",
        "fullName": "Yonatan Stiven Martinez Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/17/2022 8:26:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008240",
        "document": "1005540823",
        "fullName": "Julian Alberto Ortiz Algarin",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/17/2022 8:26:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005039",
        "document": "1098808311",
        "fullName": "Maria Fernanda Bohorquez Alfonso",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Financiero",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "3/18/2022 3:04:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008262",
        "document": "1040746216",
        "fullName": "Frank Sebastian Orozco Gallo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "3/23/2022 1:20:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008263",
        "document": "70730731",
        "fullName": "Carlos Arlex Garcia Cortes",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "3/23/2022 7:30:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008249",
        "document": "32110374",
        "fullName": "Luz Neddy Arango Ospina",
        "company": "Solla",
        "regional": "Rion",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Centro De Investigación Canina",
        "fechaIngreso": "3/23/2022 7:58:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008274",
        "document": "1102635872",
        "fullName": "Paul Jose Calderon Quintero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/24/2022 2:01:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008287",
        "document": "1014300743",
        "fullName": "Juan Sebastian Cuervo Fierro",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/24/2022 2:01:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008289",
        "document": "1005541176",
        "fullName": "Johann Martin Lizarazo Aparicio",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "3/24/2022 2:01:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008278",
        "document": "1095816966",
        "fullName": "Dorali  Ulcue",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/24/2022 2:01:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008282",
        "document": "1002363904",
        "fullName": "Celso Yovanis Max Vasquez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/24/2022 2:01:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008300",
        "document": "1082996533",
        "fullName": "Laura Andrea Lizcano Berruecos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/25/2022 3:31:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008299",
        "document": "1095818224",
        "fullName": "Maritza  Rangel Jimenez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/25/2022 3:31:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008307",
        "document": "1005282475",
        "fullName": "Juan David Duarte Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/25/2022 3:58:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008317",
        "document": "1102386437",
        "fullName": "Jesus Dario Bustamante Santos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/25/2022 3:58:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008246",
        "document": "1056302473",
        "fullName": "Adriana Milena Cardona Morales",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "3/28/2022 4:16:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008250",
        "document": "15272235",
        "fullName": "Alonso Alberto Tabares Martinez",
        "company": "Solla",
        "regional": "Lace",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "3/28/2022 4:16:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008328",
        "document": "1007569764",
        "fullName": "Luis Fernando Reyes Suarez",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "3/29/2022 1:21:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008327",
        "document": "1005157160",
        "fullName": "Carlos Eliecer Monroy Gutierrez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "3/29/2022 1:21:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008310",
        "document": "1102382260",
        "fullName": "Jorge Duvan Carreño Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/29/2022 2:51:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008301",
        "document": "1098701532",
        "fullName": "Charles Andres Rodriguez Malagon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/29/2022 2:51:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008322",
        "document": "1152206574",
        "fullName": "Jefferson Gomez Carvajal",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "3/29/2022 2:51:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008313",
        "document": "1090403714",
        "fullName": "Arisahit Rodriguez Cardozo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/29/2022 2:51:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008359",
        "document": "1066086015",
        "fullName": "Angela Maria Lobo Alvarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "4/1/2022 1:03:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008344",
        "document": "1042450483",
        "fullName": "John Alexander Sanmiguel Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/1/2022 1:03:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008353",
        "document": "1005464254",
        "fullName": "Andrey Julian Solano Sierra",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/1/2022 1:03:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008355",
        "document": "1005602384",
        "fullName": "Johan Sebastian Vera Moreno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "4/1/2022 1:03:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008358",
        "document": "1005060134",
        "fullName": "Yamir Omar Ramires Mendez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/1/2022 1:33:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008825",
        "document": "1096206222",
        "fullName": "Sergio Andres Romero Reyes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/7/2022 4:40:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00068",
        "document": "1115076442",
        "fullName": "Fabio Andres Mendoza Pineda",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/8/2022 1:12:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00072",
        "document": "1088308462",
        "fullName": "Daniel Andres Grajales Ocampo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/8/2022 1:42:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00084",
        "document": "1115071346",
        "fullName": "Henry Alberto Rojas Muños",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/8/2022 1:42:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00106",
        "document": "1115084064",
        "fullName": "Joan Andrey Morales Lopez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/8/2022 1:42:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00143",
        "document": "1047506748",
        "fullName": "Cristian Andres Mendez Narvaez",
        "company": "Agrinal-activos",
        "regional": "",
        "jobTitle": "Fumigacion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/8/2022 4:42:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00364",
        "document": "1093787983",
        "fullName": "Carlos Eduardo Galviz Leon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/11/2022 11:05:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008412",
        "document": "1095837445",
        "fullName": "Daniel Libardo Sanguino Camacho",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/11/2022 11:05:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00238",
        "document": "8432742",
        "fullName": "Carlos Andres Villegas Rivera",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/11/2022 11:05:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008428",
        "document": "1007740687",
        "fullName": "Cristian Yesid Celis Gutierrez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2022 11:05:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008425",
        "document": "1023909933",
        "fullName": "Andres Felipe Lozano Rojas",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2022 11:05:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00239",
        "document": "1005562969",
        "fullName": "Cristian Andres Millan Nova",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/11/2022 11:05:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008396",
        "document": "1095840064",
        "fullName": "Edinson Fabian Lozada Ortega",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/11/2022 11:35:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00251",
        "document": "1002725712",
        "fullName": "Gustavo Adolfo Riaño Chaparro",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario E Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/11/2022 11:35:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008423",
        "document": "91185618",
        "fullName": "BC John Jairo Galvis Rojas (Retirado)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Call Center",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/11/2022 11:35:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008390",
        "document": "1098805782",
        "fullName": "Marlon Johan Hernandez Vanegas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/11/2022 11:35:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00360",
        "document": "1005541713",
        "fullName": "Gerson Bladimir Lopez Moreno",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/11/2022 11:35:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008426",
        "document": "1064713721",
        "fullName": "Ramon Dario Reyes Martinez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2022 11:35:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007084",
        "document": "1102389065",
        "fullName": "Carlos Daniel Rangel Gaitan",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "4/11/2022 11:35:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00376",
        "document": "1102354969",
        "fullName": "Loida Fernanda Gomez Miranda",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/11/2022 11:35:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00278",
        "document": "1096199302",
        "fullName": "Juan Carlos Chacon Tellez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/12/2022 6:32:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008430",
        "document": "91494545",
        "fullName": "Alexander Rueda Toro",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2022 6:32:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00042",
        "document": "1102371172",
        "fullName": "Erika Bermudez Caballero",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/12/2022 6:32:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008161",
        "document": "1192893390",
        "fullName": "Andres Felipe Franco Arango",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/12/2022 7:06:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008380",
        "document": "1114887182",
        "fullName": "Yederzon  Morales Trujillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/12/2022 7:34:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008167",
        "document": "1000372823",
        "fullName": "Diego  Caro Villamarin",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "4/12/2022 7:34:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008020",
        "document": "1062286959",
        "fullName": "Janer Alexander Salazar Bojorge",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/12/2022 7:34:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008168",
        "document": "1006493491",
        "fullName": "Jean Carlos Arango Realpe",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "4/12/2022 7:34:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008382",
        "document": "1234193608",
        "fullName": "Jhonatan Steven Ibarra Angulo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2022 7:34:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008011",
        "document": "1115080792",
        "fullName": "Guelvert  Moncada Hernandez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "4/12/2022 7:34:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008157",
        "document": "94040276",
        "fullName": "Freidy Eduardo Illera Arce",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2022 7:34:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008371",
        "document": "5495652",
        "fullName": "Juan Jose De Jesus Contreras Latouf",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008325",
        "document": "1077437748",
        "fullName": "Maira Alejandra Cordoba Hernandez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008377",
        "document": "1006170160",
        "fullName": "Harold Nelfer Llanos Trujillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008033",
        "document": "94043942",
        "fullName": "Edwin  Ceron Gonzalez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008019",
        "document": "1114881693",
        "fullName": "Jhoan Sebastian Calderon Carvajal",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008436",
        "document": "1113526315",
        "fullName": "Joan Pier Osorio Castañeda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008160",
        "document": "1067466762",
        "fullName": "Darlin Yaneth Gonzalez Guaza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008024",
        "document": "1003372413",
        "fullName": "Derlin  Larrahondo Ocoro",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008013",
        "document": "1113540877",
        "fullName": "Duvan Alexis Caicedo Zuñiga",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008437",
        "document": "1006325300",
        "fullName": "Juan Camilo Camacho",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008283",
        "document": "1004035292",
        "fullName": "Luis Miguel Perdomo Celis",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/12/2022 7:34:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008441",
        "document": "31308093",
        "fullName": "Lady  Vargas Serna",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coodinador (a) De Procesos",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Direccion Administracion Y Financiera",
        "fechaIngreso": "4/12/2022 8:02:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008434",
        "document": "1113537792",
        "fullName": "Paola Andrea Lopez Ruiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "4/12/2022 8:02:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008260",
        "document": "1116235847",
        "fullName": "Johnny Alberto Lopez Castañeda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/12/2022 8:02:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008338",
        "document": "1081700505",
        "fullName": "Darley  Leon Collazos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2022 8:02:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008384",
        "document": "94551820",
        "fullName": "Juan Carlos Gomez Valencia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2022 8:02:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007911",
        "document": "1089004232",
        "fullName": "Prisiliano  Valarezo Vivas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/12/2022 8:02:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006683",
        "document": "10484989",
        "fullName": "Adolfo Escobar Gurrute",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/12/2022 8:32:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006749",
        "document": "1113518934",
        "fullName": "Alexander Guevara Bermudez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/12/2022 8:32:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006844",
        "document": "145379775",
        "fullName": "Josber Oswaldo Zelpa Malpica",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/12/2022 8:32:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006478",
        "document": "94469579",
        "fullName": "Ezequiel Rodriguez Sinisterra",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Cuartos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2022 8:32:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006543",
        "document": "94041366",
        "fullName": "Jhon Jainer Moreno Lucumi",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/12/2022 9:02:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006970",
        "document": "1006232680",
        "fullName": "Harry Carabali Quiñones",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/12/2022 9:32:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006652",
        "document": "1062290697",
        "fullName": "Henry Lucumi Viafara",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor De Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/12/2022 9:32:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008442",
        "document": "1037657553",
        "fullName": "Yuliana  Calle Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "4/12/2022 10:26:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008330",
        "document": "1049930790",
        "fullName": "Nairon Andres Ricardo Julio",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/13/2022 1:29:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006389",
        "document": "76277151",
        "fullName": "Eduar Valencia Hurtado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/18/2022 7:26:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00276",
        "document": "1005335708",
        "fullName": "Neyder Stiven Infante Martinez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/19/2022 9:29:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008445",
        "document": "1044503844",
        "fullName": "Brayan Stiven Gomez Molina",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/20/2022 1:07:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008443",
        "document": "1098760176",
        "fullName": "Jhon Aristides Pabon Obregon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Sst E Higiene",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Salud Y Seguridad En El Trabajo",
        "fechaIngreso": "4/20/2022 2:07:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00392",
        "document": "1005542004",
        "fullName": "Ronal Jair Roa Suarez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/20/2022 2:07:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01129",
        "document": "1005280405",
        "fullName": "Silvia Juliana Cabanzo Massey",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Admnistrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/20/2022 2:07:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00146",
        "document": "1005540409",
        "fullName": "Michael Edilson Bautista Parra",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/20/2022 2:07:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008373",
        "document": "1000762556",
        "fullName": "Harold Estiven Lopez Foronda",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/20/2022 8:54:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008228",
        "document": "5038389",
        "fullName": "Omar Eliece Pulgar Naveda",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/20/2022 8:54:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008215",
        "document": "1102234232",
        "fullName": "Ruddy Javier Muñoz Monterroza",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/20/2022 8:54:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00212",
        "document": "1010071848",
        "fullName": "Sandy Nayibe Florez Delgado",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/21/2022 5:08:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00261",
        "document": "63547633",
        "fullName": "Islene  Suarez Martinez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/21/2022 5:08:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00265",
        "document": "1005338239",
        "fullName": "Jhon Alexander Hortua Paez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/21/2022 5:08:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007737",
        "document": "13871034",
        "fullName": "Gustavo Moreno Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/22/2022 9:42:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008151",
        "document": "1037544796",
        "fullName": "Edwin Alberto Perez Velasquez",
        "company": "Solla",
        "regional": "Barb",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/25/2022 5:17:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008162",
        "document": "1047994181",
        "fullName": "Jose Alexander Marin Gomez",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/25/2022 5:17:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00058",
        "document": "1115082397",
        "fullName": "Aicardo Jose Dominguez Aguirre",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxilair De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/25/2022 5:17:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01143",
        "document": "1232889946",
        "fullName": "Maria Isabel Alvarez Jaimes",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Calidad",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2022 2:18:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002795",
        "document": "1095702072",
        "fullName": "William  Angarita Marin",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "4/26/2022 2:54:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008153",
        "document": "1025760169",
        "fullName": "Juan Esteban Bedoya Merino",
        "company": "Solla",
        "regional": "Gome",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/27/2022 4:55:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008493",
        "document": "1152206356",
        "fullName": "Janio Luis Cardozo Prada",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "4/27/2022 8:03:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008509",
        "document": "1095941071",
        "fullName": "Jonathan Alberto Meza Ospina",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/27/2022 8:03:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008494",
        "document": "1070971637",
        "fullName": "Juan Sebastian Suarez Escobar",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/27/2022 8:03:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008510",
        "document": "1065880682",
        "fullName": "Fabio Nelso Carballo Lugo",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/27/2022 8:03:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008496",
        "document": "1014201022",
        "fullName": "Priscila  Molina Jimenez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "4/27/2022 8:03:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008513",
        "document": "1005240608",
        "fullName": "Joan Sebastian Castillo Buitrago",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "4/27/2022 8:03:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008514",
        "document": "1005257755",
        "fullName": "Oscar Daniel Duque Rivera",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "4/27/2022 8:03:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008836",
        "document": "1098718280",
        "fullName": "Yuli Vanessa Fuentes Quijano",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/27/2022 8:33:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008488",
        "document": "98778667",
        "fullName": "Fabio Arturo Cardona Cardona",
        "company": "Solla",
        "regional": "Barb",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/27/2022 9:02:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008378",
        "document": "1047964624",
        "fullName": "Aldemar  Marin Martinez",
        "company": "Solla",
        "regional": "Gome",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/27/2022 9:02:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00431",
        "document": "1005305999",
        "fullName": "Kevin Andres Bastos Reatiga",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2022 9:35:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000777",
        "document": "1005145753",
        "fullName": "Luis Carlos Perea Rivera",
        "company": "Distraves",
        "regional": "Bari",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/27/2022 9:35:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00395",
        "document": "1095825067",
        "fullName": "Ismenia Matilde Ardila Villamizar",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2022 9:35:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00403",
        "document": "1005663103",
        "fullName": "Carlos Mario Carrasco Vilora",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2022 9:35:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008541",
        "document": "1098628653",
        "fullName": "Ronal Favian Hernandez Saavedra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "4/27/2022 9:35:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00245",
        "document": "1005541650",
        "fullName": "Elias Daniel Mejia Grisales",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2022 9:35:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00461",
        "document": "1101521018",
        "fullName": "Luis Fernando Niño Acelas",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/28/2022 4:05:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00380",
        "document": "1005074243",
        "fullName": "Slendy Andrea Quintana Quintero",
        "company": "Distraves-activos",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/28/2022 4:05:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00373",
        "document": "1192762373",
        "fullName": "Ingrid Natalia Ramirez Uribe",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/28/2022 4:05:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00244",
        "document": "1096952859",
        "fullName": "Eduar Yesid Moreno Mendez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/28/2022 4:05:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008498",
        "document": "1112759269",
        "fullName": "John Edwin Malagon",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/28/2022 10:25:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008543",
        "document": "1098642166",
        "fullName": "Angela Marcela Niño Diaz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "4/28/2022 10:25:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008492",
        "document": "1006287290",
        "fullName": "Frankin Steven Cardenas Gonsalez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/28/2022 10:25:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008503",
        "document": "91184168",
        "fullName": "Juan Gabriel Rincon Peña",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Supervisor Full Filler",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/28/2022 10:25:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008502",
        "document": "1076334071",
        "fullName": "Jose Gustavo Gonzalez Medina",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/28/2022 10:25:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008508",
        "document": "2233843",
        "fullName": "Josnel Jose Donaire Tovar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/28/2022 10:25:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008499",
        "document": "1087006849",
        "fullName": "Janeth Dolores Rojas Mueses",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/28/2022 10:25:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008639",
        "document": "1035442684",
        "fullName": "Valentina Garcia Cataño",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Datos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "4/29/2022 3:10:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008618",
        "document": "1005553163",
        "fullName": "Jorgin David Sandoval Mendez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/2/2022 7:18:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008588",
        "document": "43991896",
        "fullName": "Laura Natalia Hernandez Oquendo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/2/2022 7:18:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008607",
        "document": "1052220288",
        "fullName": "David Alexander Castañeda Cañas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/2/2022 7:18:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008597",
        "document": "1098770610",
        "fullName": "Karen Lizeth Uribe Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/2/2022 7:18:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008601",
        "document": "5746603",
        "fullName": "Jose Nelson Garnica Pinto",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/2/2022 7:18:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008600",
        "document": "63558842",
        "fullName": "Maria Isabel Carrillo Arias",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/2/2022 7:18:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008659",
        "document": "37843429",
        "fullName": "Johanna Patrcia Romero Espejo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/3/2022 4:18:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008628",
        "document": "1005541731",
        "fullName": "Adriana Lucia Rivera Sepulveda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/3/2022 4:18:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008626",
        "document": "1005539961",
        "fullName": "Jherson Arley Castellanos Monsalve",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/3/2022 4:18:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008620",
        "document": "1102367712",
        "fullName": "Silvia Natalia Remolina Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/3/2022 4:18:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008650",
        "document": "1005289433",
        "fullName": "Juan Sebastian Celis Caceres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/3/2022 4:18:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008667",
        "document": "1099372032",
        "fullName": "Cesar Mauricio Peña Romero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/3/2022 4:18:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008653",
        "document": "1005411604",
        "fullName": "Edison Romero Ruiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/3/2022 4:18:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008668",
        "document": "1005323685",
        "fullName": "Jhon Stiven Garces Cañas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/3/2022 4:18:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008648",
        "document": "1098739730",
        "fullName": "Bryan Yesid Plata Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/3/2022 4:18:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00454",
        "document": "1005479797",
        "fullName": "Tatiana Yadira Aparicio Diaz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/3/2022 4:18:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00430",
        "document": "1102354812",
        "fullName": "Diana Maria Rueda Barajas",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/3/2022 4:18:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008633",
        "document": "1152715250",
        "fullName": "Sebastian  Mira Patiño",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/3/2022 4:49:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008643",
        "document": "1001144042",
        "fullName": "Vanessa  Gil Velasquez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Impuestos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "5/3/2022 4:49:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008674",
        "document": "1096062843",
        "fullName": "Andres Camilo Bautista Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "5/3/2022 7:21:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008672",
        "document": "1005346067",
        "fullName": "Andres Fabian Ortiz Gonzalez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "5/3/2022 7:22:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008671",
        "document": "1095840979",
        "fullName": "Angie Tatiana Gonzalez Duarte",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/3/2022 7:22:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007093",
        "document": "1000874898",
        "fullName": "Isabel  Oliveros Cartagena",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Suministros Y Servicios",
        "fechaIngreso": "5/4/2022 1:20:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008586",
        "document": "1128446771",
        "fullName": "Paula Andrea Muñoz Gutierrez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Pagos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Tesoreria",
        "fechaIngreso": "5/4/2022 1:38:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008678",
        "document": "1096618867",
        "fullName": "Weimer Andrey Gonzalez Ariza",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Tecnico Junior",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/5/2022 7:07:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008676",
        "document": "1118563890",
        "fullName": "Sebastian Andres Vargas Silva",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Coordinador Zona",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "5/5/2022 10:33:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008679",
        "document": "1006462372",
        "fullName": "Laura Yiceth Rodriguez Arias",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "5/6/2022 4:37:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008684",
        "document": "1007844429",
        "fullName": "Heyner Andres Sinisterra Murillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/6/2022 7:19:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008688",
        "document": "1006396824",
        "fullName": "Kevin Andres Mosquera Garcia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/6/2022 7:19:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00482",
        "document": "91512247",
        "fullName": "Julian Andres Mantilla Diaz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Bascula",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/6/2022 7:47:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008695",
        "document": "1113530831",
        "fullName": "Yadira  Navas Viafara",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/7/2022 5:49:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007085",
        "document": "1104136969",
        "fullName": "Ana Maria Badillo Dominguez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "5/9/2022 4:56:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008548",
        "document": "1098620614",
        "fullName": "Jherson Gilberto Diaz Grass",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "5/9/2022 4:56:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01153",
        "document": "1005366510",
        "fullName": "Leidy Tatiana Prieto Ayala",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Mantenimiento",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/9/2022 7:01:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008718",
        "document": "1102349411",
        "fullName": "Andres Felipe Almeida Ardila",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2022 4:00:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008712",
        "document": "1098801812",
        "fullName": "BC Daniela Loraine Pico Leon (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/10/2022 4:00:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008713",
        "document": "1095946585",
        "fullName": "Jhon Nycole Garnica Olarte",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/10/2022 5:00:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008729",
        "document": "1006192289",
        "fullName": "Hanner Sebastian Lopez Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "5/10/2022 6:00:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008703",
        "document": "1040749730",
        "fullName": "Felipe Ospina Valencia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Auditoria",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "5/10/2022 2:24:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008737",
        "document": "1065912552",
        "fullName": "Jhon Freddy Peñuela Contreras",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/10/2022 7:00:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008740",
        "document": "1115082814",
        "fullName": "Stefania  Zapata Piedrahita",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "5/11/2022 5:57:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00515",
        "document": "2000004033",
        "fullName": "Jhoan Manuel Rueda Quintero",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/11/2022 2:59:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002639",
        "document": "1099874628",
        "fullName": "Marilyn Jhoana Serrano Monsalve",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Analista Laboratorio",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "5/13/2022 8:26:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008768",
        "document": "1124017102",
        "fullName": "Andreina Adriana Pushaina Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/14/2022 6:51:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008766",
        "document": "1005565294",
        "fullName": "Jerly Zarith Perez Tapias",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/14/2022 6:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008758",
        "document": "1004095260",
        "fullName": "Andy Steve Ramos Rios",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/14/2022 6:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008763",
        "document": "1004501556",
        "fullName": "Luis Hernando Lobo Velasquez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/14/2022 6:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008772",
        "document": "1102371126",
        "fullName": "Yefferson Alexander Ortiz Ramirez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "5/16/2022 7:06:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008771",
        "document": "1037654951",
        "fullName": "Andrea  Arrubla Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras Internacionales.",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Internacionales",
        "area": "Compras Internacionales",
        "fechaIngreso": "5/17/2022 7:18:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008619",
        "document": "1005541354",
        "fullName": "Yeison Quintero Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/18/2022 8:54:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008615",
        "document": "91004555",
        "fullName": "Gonzalo Ramirez Patiño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/18/2022 8:54:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008759",
        "document": "1095726378",
        "fullName": "Juan Carlos Rodriguez Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "5/18/2022 8:54:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00544",
        "document": "63553101",
        "fullName": "BC Diana Marcela Rojas Caceres (Shared)",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar De Nomina",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/19/2022 3:57:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008787",
        "document": "1144163073",
        "fullName": "Jaime Andres Bernal Vasquez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Almacén",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "5/19/2022 9:58:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008792",
        "document": "1104805385",
        "fullName": "Maria Alejandra Pulgarin Quiñonez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/20/2022 6:00:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008794",
        "document": "1113529334",
        "fullName": "Valeria  Torijano Londoño",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/20/2022 6:00:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008795",
        "document": "1002917076",
        "fullName": "Milton Jhoel Rodriguez Mosquera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/20/2022 6:00:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00338",
        "document": "1102378103",
        "fullName": "Juliana Andrea Torres Sanchez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/23/2022 5:12:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00409",
        "document": "1005540393",
        "fullName": "Juan Sebastian Ramirez Suarez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/23/2022 5:12:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00545",
        "document": "1102376180",
        "fullName": "Yurley Catherine Gomez Zarate",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar De Compensación",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/23/2022 5:12:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008801",
        "document": "1039290541",
        "fullName": "Andres Felipe Avendaño David",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/23/2022 7:16:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01229",
        "document": "1098760361",
        "fullName": "Leidy Paola Herrera Pinto",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/24/2022 3:47:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008807",
        "document": "1113525550",
        "fullName": "Jhon Eduard Villa Burgos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/24/2022 3:47:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008811",
        "document": "1016088186",
        "fullName": "Rodrigo Alejandro Arenas Salamanca",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Tecnico Junior",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "5/24/2022 4:47:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008817",
        "document": "1025140027",
        "fullName": "Juan Manuel Pinto Paez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "5/24/2022 7:14:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008800",
        "document": "1144089780",
        "fullName": "BC Yamila  Gasca Pelaez (Retirado)",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Ssta",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "5/24/2022 9:39:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008846",
        "document": "1102385420",
        "fullName": "Edwing Fernando Velasco Arenas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "5/25/2022 3:43:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008856",
        "document": "1005542744",
        "fullName": "Darlyn Dayana Castro Torres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/25/2022 3:43:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008859",
        "document": "1005372946",
        "fullName": "Michael Andretty Garcia Gelvez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/25/2022 7:16:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008858",
        "document": "1098697077",
        "fullName": "Mayeth Karina Sanchez Remolina",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Compensaciones",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/26/2022 2:45:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008824",
        "document": "1065885669",
        "fullName": "Wilfran Ariel Quintero Manco",
        "company": "Distraves",
        "regional": "Aguc",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/26/2022 7:20:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008670",
        "document": "1102382808",
        "fullName": "Andres Felipe Lizarazo Poveda",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "5/27/2022 10:49:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008773",
        "document": "1002182812",
        "fullName": "Jacklin Nailea Mendoza Uribe",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "5/27/2022 10:49:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00710",
        "document": "1193527779",
        "fullName": "Carlos Andres Agudelo Fierro",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/27/2022 10:49:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008756",
        "document": "1005539612",
        "fullName": "Jose Luis Calderon Ramirez",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/27/2022 10:49:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008747",
        "document": "1002101378",
        "fullName": "Marlon Andres Rojas Pacheco",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/27/2022 10:49:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008720",
        "document": "1102349954",
        "fullName": "Inel Angela Mejia Ospino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "5/27/2022 10:49:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00583",
        "document": "1104133618",
        "fullName": "Jean Karlo Caro Montero",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/29/2022 3:56:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008868",
        "document": "37285480",
        "fullName": "Ana Belen Serrano Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/29/2022 3:56:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008873",
        "document": "1143357856",
        "fullName": "Heydi Del Carmen Herrera Castro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/31/2022 7:08:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01176",
        "document": "1100892061",
        "fullName": "Judy Katherine Hernandez Mendoza",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Nomina",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/1/2022 4:05:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008890",
        "document": "1004913236",
        "fullName": "Cristian Daniel Delgado Paez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "6/1/2022 4:05:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008880",
        "document": "1007667775",
        "fullName": "Jonathan  Torrado Castro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "6/1/2022 4:05:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00154",
        "document": "1115083884",
        "fullName": "Diana Lorena Agudelo Gutierrez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/1/2022 4:33:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008711",
        "document": "1102354975",
        "fullName": "Heriberto Pinzon Suarez",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "6/1/2022 9:22:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008896",
        "document": "37550917",
        "fullName": "BC Deisi Yaneth Roldan Rueda (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Nomina",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/2/2022 1:53:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008886",
        "document": "1099375715",
        "fullName": "Wildemar  Ardila Perez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "6/2/2022 2:23:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008882",
        "document": "1098644240",
        "fullName": "German  Pita Gomez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "6/2/2022 2:23:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001168",
        "document": "63523723",
        "fullName": "Claudia Patricia Alvarez Torres",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "6/3/2022 4:59:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008908",
        "document": "1192789843",
        "fullName": "Sebastian  Torres Castro",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/3/2022 7:02:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008910",
        "document": "42827776",
        "fullName": "Carolina Maria Jimenez Betancur",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Director(a) Tesoreria",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Tesoreria",
        "fechaIngreso": "6/3/2022 8:27:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008922",
        "document": "1006331693",
        "fullName": "Bryan Steven Silva Perez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "6/6/2022 7:01:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008928",
        "document": "1007715115",
        "fullName": "Nicole Dayana Solano Sora",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "6/7/2022 4:00:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00524",
        "document": "1052410789",
        "fullName": "Danna Manuela Gomez Eslava",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geografico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/7/2022 4:00:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008934",
        "document": "1115085735",
        "fullName": "Jean Wladirmir Torres Agudelo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/7/2022 7:03:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008941",
        "document": "1098629726",
        "fullName": "Jhon Freddy Sierra Porras",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/8/2022 3:55:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008939",
        "document": "1003159092",
        "fullName": "Owen Michell Montiel Salcedo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/8/2022 4:54:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008904",
        "document": "1118311194",
        "fullName": "Carlos Munoz Popayan",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Planeador De La Demanda",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "6/8/2022 3:24:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008960",
        "document": "88269829",
        "fullName": "Cristhian Fabian Rincon Gualdron",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "6/9/2022 3:59:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008954",
        "document": "1113526106",
        "fullName": "Luz Angela Cuasapud Lazo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "6/9/2022 5:57:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008966",
        "document": "1193098140",
        "fullName": "Leidy Juliana Orozco Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/9/2022 7:03:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008975",
        "document": "1096235805",
        "fullName": "Erika Tatiana Chitiva",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/10/2022 4:02:11 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009863",
        "document": "65746683",
        "fullName": "Martha Viviana Bonilla Conde",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Venta Directa",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "6/10/2022 3:02:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008994",
        "document": "1102392034",
        "fullName": "Juan Sebastian Guio Almeida",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/10/2022 7:08:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008999",
        "document": "91044412",
        "fullName": "William  Sanchez Ronderos",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "6/10/2022 7:08:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00689",
        "document": "1115090064",
        "fullName": "Leidy Paola Zapata Mejia",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/10/2022 9:21:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008964",
        "document": "1098655551",
        "fullName": "Maria Alexandra Herrera Serrano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Coordinador Talento Humano",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "6/10/2022 9:51:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008963",
        "document": "1098765860",
        "fullName": "Juan David Ramirez Landinez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "6/10/2022 9:51:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009001",
        "document": "1018492426",
        "fullName": "Laura Juliana Guzman Clavijo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "6/11/2022 4:54:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009003",
        "document": "1095787005",
        "fullName": "Liliana  Mantilla Camargo",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/13/2022 7:01:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007416",
        "document": "1099375293",
        "fullName": "Juan Armando Jurado Delgado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "6/14/2022 9:29:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004143",
        "document": "1192925028",
        "fullName": "Maria Alejandra Hernandez Ortiz",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Aprendiz Sena",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/15/2022 2:31:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00148",
        "document": "1005176219",
        "fullName": "Sergio Andres Palacios Ramos",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 2:01:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00442",
        "document": "37578458",
        "fullName": "Diana Patiño Mendoza",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 2:32:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008482",
        "document": "91355799",
        "fullName": "Luis Fredy Garcia Martinez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "6/16/2022 2:32:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008744",
        "document": "1102549896",
        "fullName": "Duvan Diaz Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "6/16/2022 4:32:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008743",
        "document": "1099373461",
        "fullName": "Jhon Fredy Ortiz Caballero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "6/16/2022 5:02:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008990",
        "document": "1100890242",
        "fullName": "Eliecer Jaimes Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "6/16/2022 5:32:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00492",
        "document": "1033793903",
        "fullName": "Andres Felipe Ruiz Quintero",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Domiciliario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 7:07:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00215",
        "document": "91522036",
        "fullName": "Alexei Rueda Ramirez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 7:07:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00274",
        "document": "1005259660",
        "fullName": "Jhonalder Martinez Cardozo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 8:02:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008916",
        "document": "1059446554",
        "fullName": "Alexander Montaño Torres",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "6/16/2022 8:02:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00368",
        "document": "1005541935",
        "fullName": "Edgar Yesid Sanchez Castellanos",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 8:02:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008914",
        "document": "1115080064",
        "fullName": "Cristian Andres Tejada Valdes",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/16/2022 8:02:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00476",
        "document": "1102388768",
        "fullName": "Paula Andrea Hernandez Florez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 8:02:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00477",
        "document": "1005539029",
        "fullName": "Erica Adriana Hernandez Mantilla",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 8:02:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008920",
        "document": "1115084447",
        "fullName": "Carlos Alberto Arroyave Marmolejo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "6/16/2022 8:02:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008917",
        "document": "1113657405",
        "fullName": "Juan Camilo Toro Perez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/16/2022 8:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008913",
        "document": "1115077766",
        "fullName": "Jose Luis Marin Pulecio",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "6/16/2022 8:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00480",
        "document": "91468724",
        "fullName": "Jose Antonio Leal Aguillon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 8:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008912",
        "document": "94482084",
        "fullName": "Oscar Julian Perez Perez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/16/2022 8:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008925",
        "document": "94480803",
        "fullName": "Luis Gabriel Moreno Giron",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "6/16/2022 8:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008935",
        "document": "1114456365",
        "fullName": "Jose Daniel Florez Sanchez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "6/16/2022 8:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008933",
        "document": "1115078562",
        "fullName": "Julian Esteban Cañas Castaño",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Mascotas",
        "fechaIngreso": "6/16/2022 8:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00408",
        "document": "1102373465",
        "fullName": "Miriam Garcia Torres",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 8:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008931",
        "document": "1115086336",
        "fullName": "Juan Sebastian Menjura Martinez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "6/16/2022 8:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00422",
        "document": "1102372243",
        "fullName": "Diana Marcela Mendez Larotta",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 8:25:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00427",
        "document": "1098783348",
        "fullName": "Paula Andrea Vargas Mora",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/16/2022 8:55:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009037",
        "document": "1113539644",
        "fullName": "Yurani Andrea Garreta Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/21/2022 7:06:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009043",
        "document": "1001034395",
        "fullName": "Lored Daiana Giraldo Alvarez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Contratacion",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Compensaciones",
        "fechaIngreso": "6/22/2022 7:09:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00690",
        "document": "1024497886",
        "fullName": "Oscar Andres Badillo Cortes",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/22/2022 9:04:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009047",
        "document": "1095831262",
        "fullName": "Andres Felipe Rodriguez Blanco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "6/23/2022 7:14:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009038",
        "document": "1096241365",
        "fullName": "Brayan Ferney Suarez Solis",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Sst E Higiene",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Salud Y Seguridad En El Trabajo",
        "fechaIngreso": "6/23/2022 9:39:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009070",
        "document": "1004348210",
        "fullName": "Cristian Camilo Candanoza Olivo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/24/2022 6:45:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009067",
        "document": "1006583645",
        "fullName": "Sebastian  Murgas Correa",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/24/2022 7:17:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009078",
        "document": "1193551506",
        "fullName": "Nathaly  Daraviña Osorio",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/25/2022 4:46:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009079",
        "document": "1006230060",
        "fullName": "Juan Camilo Pineda Giraldo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/25/2022 4:46:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00693",
        "document": "1123513928",
        "fullName": "Juan Camilo Arciniegas Montoya",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/28/2022 4:23:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009088",
        "document": "1005474928",
        "fullName": "Brayan Alexis Ramirez Rincon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "6/28/2022 6:58:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009075",
        "document": "1216967244",
        "fullName": "Luis Guillermo Pino Martinez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Proceso",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "6/30/2022 1:28:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00778",
        "document": "1098771352",
        "fullName": "Mari Yurley Perez Moreno",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Lider Empresarios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/30/2022 7:32:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00827",
        "document": "1122678253",
        "fullName": "Yefferson Leonardo Duarte Cano",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/30/2022 7:32:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009068",
        "document": "1143169196",
        "fullName": "Maria Camila Padilla Padilla",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "6/30/2022 7:32:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009094",
        "document": "1152211281",
        "fullName": "Jorge Mario Ortega Cuello",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Cumplimiento",
        "uen": "Corporativo Presidencia",
        "process": "Presidencia",
        "area": "Presidencia",
        "fechaIngreso": "7/1/2022 6:57:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009124",
        "document": "82363257",
        "fullName": "Luis Norberto Ramirez Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/5/2022 7:07:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009115",
        "document": "71750424",
        "fullName": "Andres Esteban Lara Ospina",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "7/5/2022 10:07:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009131",
        "document": "1059814457",
        "fullName": "John Rafael Suaza Ocampo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "7/6/2022 4:42:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009133",
        "document": "1113535523",
        "fullName": "Paula Ximena Cobo Mercado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/6/2022 6:09:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008947",
        "document": "1095913825",
        "fullName": "Miguel Angel Solano Rodriguez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/6/2022 2:39:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008967",
        "document": "1005549237",
        "fullName": "Brayan Felipe Pinzon Duran",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "7/6/2022 2:39:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008962",
        "document": "1099372784",
        "fullName": "Alfredo  Pacheco Gafaro",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "7/6/2022 2:39:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009045",
        "document": "1098797371",
        "fullName": "Luis Yesid Moreno Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/6/2022 2:39:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00798",
        "document": "1090396588",
        "fullName": "Darwin Jhoan Barrera Arevalo",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geográfico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/6/2022 4:07:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009137",
        "document": "98559453",
        "fullName": "Cesar Augusto Bedoya Gómez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Bi",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "7/6/2022 4:07:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009138",
        "document": "1216719317",
        "fullName": "Milena  Herrera Santos",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Auditoria",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "7/6/2022 7:13:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009140",
        "document": "1044503100",
        "fullName": "Deicy Yuliana Aristizabal Medina",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "7/6/2022 7:13:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009142",
        "document": "1082856336",
        "fullName": "Jeison Camilo Losada Figueroa",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "7/7/2022 4:45:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009136",
        "document": "1152467690",
        "fullName": "Julian Adolfo Medina Gonzalez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Suministros Y Servicios",
        "fechaIngreso": "7/7/2022 2:13:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00808",
        "document": "1128442596",
        "fullName": "Wilmar Andres Patiño Mazo",
        "company": "Solla-arus",
        "regional": "",
        "jobTitle": "Soporte De Sitio",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/7/2022 6:47:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009144",
        "document": "1090493749",
        "fullName": "Dumar Alirio Ariza Cano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "7/7/2022 6:47:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009139",
        "document": "1053800807",
        "fullName": "Sergio Duvan Gallego Parra",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Analista Planificacion",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Mercadeo",
        "area": "Planeación De La Demanda",
        "fechaIngreso": "7/7/2022 7:31:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009150",
        "document": "1007197573",
        "fullName": "Brayan Daniel Murillo Serrano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "7/8/2022 4:04:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009161",
        "document": "1005485087",
        "fullName": "BC Carlos Ronaldo Ravelo Agudelo (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/8/2022 7:10:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009160",
        "document": "1113526483",
        "fullName": "Geisy Yuliet Martinez Velasco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/8/2022 7:10:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00113",
        "document": "1114062691",
        "fullName": "Sebastian Bejarano Restrepo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Axiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/8/2022 8:57:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009170",
        "document": "1144179167",
        "fullName": "Leidy Laura Castillo Beltran",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Practicante",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "7/11/2022 7:13:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009135",
        "document": "1037608869",
        "fullName": "Samuel  Maya Henao",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Tecnico Junior",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "7/11/2022 8:57:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009143",
        "document": "1039446268",
        "fullName": "Daicy Johana Echeverri Vergara",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Tecnico Junior",
        "uen": "Negocio Balanceados",
        "process": "Balanceados",
        "area": "Balanceados",
        "fechaIngreso": "7/11/2022 9:15:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009184",
        "document": "1001870195",
        "fullName": "Jimena Patricia Aguilar Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "7/12/2022 3:48:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009171",
        "document": "1007555226",
        "fullName": "Johan Stiven Ariza Lagos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/12/2022 3:48:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009189",
        "document": "1098755628",
        "fullName": "Camila Andrea Bautista Badillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Auxiliar Invima",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "7/12/2022 3:48:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005596",
        "document": "1037658766",
        "fullName": "Lucia Calle Jaramillo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador (a) De Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Centro De Investigación Canina",
        "fechaIngreso": "7/12/2022 8:38:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009192",
        "document": "1096949885",
        "fullName": "Jansey Johana Ortiz Baron",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Cartera",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Cartera",
        "fechaIngreso": "7/13/2022 8:28:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009193",
        "document": "1040733480",
        "fullName": "Juan David Echavarria Acosta",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Supervisor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "7/13/2022 8:28:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009204",
        "document": "1113528818",
        "fullName": "Kely Jhojana Perez Marmolejo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/14/2022 7:07:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009215",
        "document": "1005372389",
        "fullName": "Tibby Alexandra Jaimes Arenas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "7/18/2022 6:46:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009213",
        "document": "1039702647",
        "fullName": "Jesus Duban Leal Araque",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "7/18/2022 6:46:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009207",
        "document": "1098773114",
        "fullName": "Alirio  Noriega Morales",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "7/18/2022 6:46:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009239",
        "document": "1005322779",
        "fullName": "Jainer Wilson Damir Sanabria Jimenez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "7/19/2022 3:48:22 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009224",
        "document": "1102374589",
        "fullName": "Lizeth Katherinne Hernandez Torres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "7/19/2022 3:48:22 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009231",
        "document": "1036643809",
        "fullName": "Santiago  Parra Hincapie",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista Bi",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "7/19/2022 4:48:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009235",
        "document": "1020422569",
        "fullName": "Laura Ximena Perez Martinez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "7/19/2022 4:48:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009227",
        "document": "1096062422",
        "fullName": "Yennireth Lizbette Villarreal Saavedra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Digitador Sap",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "7/21/2022 2:31:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009809",
        "document": "1152692751",
        "fullName": "Laura Estefania Gomez Roldan",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "7/22/2022 8:00:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009258",
        "document": "1005540531",
        "fullName": "BC Adriana Lucia Paipa Dueñas (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/23/2022 4:03:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009257",
        "document": "1065238158",
        "fullName": "Cesar  Badillo Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "7/23/2022 4:03:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009286",
        "document": "1005330046",
        "fullName": "Carlos Steven Ardila Calderon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "7/26/2022 3:46:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009283",
        "document": "1098816081",
        "fullName": "Diego Alejandro Bermudez Ortiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "7/26/2022 3:46:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009299",
        "document": "1095942039",
        "fullName": "Kleider Enrique Carmona Caballero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/26/2022 6:52:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009296",
        "document": "1003689076",
        "fullName": "Sebastian Dubuat Rincon Rodriguez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "7/26/2022 6:52:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00539",
        "document": "1102397121",
        "fullName": "Esleider Jose Rodriguez Cuento",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Limpieza Y Desifencion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "7/26/2022 6:52:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009307",
        "document": "1065913353",
        "fullName": "Jean Carlos Noriega Vargas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "7/27/2022 3:50:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009274",
        "document": "1115082815",
        "fullName": "Sebastián Bejarano Ospina",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "7/27/2022 2:29:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009264",
        "document": "77097277",
        "fullName": "Miguel Angel Gomez Polo",
        "company": "Distraves",
        "regional": "Vall",
        "jobTitle": "Supervisor Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/27/2022 3:30:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009241",
        "document": "1014258698",
        "fullName": "Andrea Isabel Quiroga",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Coordinador Inventarios",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/27/2022 3:30:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009309",
        "document": "1117348041",
        "fullName": "Mariana  Bejarano Bejarano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "7/27/2022 7:07:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009310",
        "document": "1033341795",
        "fullName": "Jhony  Macea Solano",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Supervisor Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/27/2022 8:30:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009288",
        "document": "1098797694",
        "fullName": "Luis Fernando Albarracin Arias",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Auditoria Interna",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "7/27/2022 8:30:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009039",
        "document": "3482655",
        "fullName": "Hernan Dario Cano Correa",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario De Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "7/27/2022 10:19:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009338",
        "document": "13536137",
        "fullName": "Roso  Jurado Prada",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/30/2022 3:59:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009351",
        "document": "1099373479",
        "fullName": "Alex Guillermo Gamboa Alvarez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "7/30/2022 3:59:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009320",
        "document": "1102380732",
        "fullName": "Mayerly Liliana Cardenas Sierra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "7/30/2022 3:59:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009359",
        "document": "1099734233",
        "fullName": "Jhonathan David Chacon Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "7/30/2022 7:04:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009354",
        "document": "1128056400",
        "fullName": "Nury Johanny Coello Noriega",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "7/30/2022 7:04:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009367",
        "document": "1102385205",
        "fullName": "Angy Katerine Pinto Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "7/30/2022 7:04:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1283107",
        "document": "1115079581",
        "fullName": "Carlos Andres Pulgarin Quiroga",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "8/1/2022 8:20:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009394",
        "document": "91522886",
        "fullName": "Danny Fabricio Chavez Ruiz",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Coordinador Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Administración De Planta",
        "fechaIngreso": "8/3/2022 7:01:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009401",
        "document": "1005337610",
        "fullName": "Zahira Natalia Cardenas Jaimes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "8/4/2022 7:05:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009400",
        "document": "1040510798",
        "fullName": "Joel  Zurita Arrieta",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "8/4/2022 7:05:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009402",
        "document": "10966052",
        "fullName": "Luis Hernan Hernandez Martinez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Fumigador",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Ped",
        "fechaIngreso": "8/4/2022 7:05:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006387",
        "document": "1144168592",
        "fullName": "Jennifer Vaca Meneses",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Auditoria",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "8/4/2022 7:30:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009391",
        "document": "1056787081",
        "fullName": "Luis Miguel Cadavid Velasquez",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Porcicultura",
        "fechaIngreso": "8/4/2022 7:45:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009268",
        "document": "1115087442",
        "fullName": "Carlos Alberto Ramos Marquez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "8/4/2022 7:59:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009265",
        "document": "1115082742",
        "fullName": "Daniel Sebastian Payoma Bravo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "8/4/2022 7:59:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009269",
        "document": "1006337390",
        "fullName": "Jose Manuel Molina Henao",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "8/4/2022 7:59:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009416",
        "document": "30394036",
        "fullName": "Alexandra  Botero Trejos",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Impuestos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "8/8/2022 6:55:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009433",
        "document": "1045718559",
        "fullName": "Katherine Paola Posada Palomino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/9/2022 3:54:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009428",
        "document": "1017218363",
        "fullName": "Natalia Andrea Hernandez Becerra",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Recepcionista",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "8/9/2022 4:54:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009431",
        "document": "1034777447",
        "fullName": "Laura Tatiana Castro Martinez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "8/9/2022 4:54:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009448",
        "document": "1089001295",
        "fullName": "David  Perlaza Sinisterra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/9/2022 6:59:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009443",
        "document": "91509905",
        "fullName": "Alexander  Nopsa Niño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/9/2022 6:59:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009435",
        "document": "1102381533",
        "fullName": "Laura Vanessa Duarte Ramirez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/9/2022 6:59:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009465",
        "document": "1193566937",
        "fullName": "BC Michelle Carolina Mora Ardila (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/10/2022 4:03:03 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009459",
        "document": "1102363226",
        "fullName": "Yurley  Rangel Peña",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/10/2022 4:03:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009479",
        "document": "5046293",
        "fullName": "Ervis  Perozo Pirela",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Fumigador",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "8/10/2022 4:35:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009165",
        "document": "1144140551",
        "fullName": "Jennifer  Del Mar Vidal",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/10/2022 1:22:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009387",
        "document": "1036946674",
        "fullName": "Manuel Esteban Ramirez Garcia",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "8/10/2022 2:00:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01499",
        "document": "1005331011",
        "fullName": "Nailyn Lizbeth Amaya Sanchez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/10/2022 6:44:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009492",
        "document": "9296744",
        "fullName": "Rafael Enrique Velasquez Cuello",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "8/11/2022 4:44:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009486",
        "document": "1006427499",
        "fullName": "Isabela  Ayala Baena",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "8/11/2022 5:43:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009311",
        "document": "1043006588",
        "fullName": "Yajaira Castro Charris",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "8/11/2022 1:44:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009468",
        "document": "1098200474",
        "fullName": "Mauricio  Useche Useche",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/12/2022 2:48:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009450",
        "document": "1100966516",
        "fullName": "Jhon Fredy Barragan Ortiz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/12/2022 2:48:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009331",
        "document": "91464497",
        "fullName": "Rodolfo  Guerrero Duran",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios_semillero",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "8/12/2022 2:48:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009346",
        "document": "1099555284",
        "fullName": "Brandon Steven Peña Suarez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "8/12/2022 2:48:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009315",
        "document": "9161665",
        "fullName": "Omar  Carpio Seija",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "8/12/2022 2:48:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009187",
        "document": "1095934391",
        "fullName": "Isnardo Alberto Gomez Garcia",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "8/12/2022 2:48:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009461",
        "document": "1014210680",
        "fullName": "Monica  Afanador Duran",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/12/2022 2:48:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009344",
        "document": "1005548958",
        "fullName": "Juan Camilo Villabona Figueroa",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "8/12/2022 2:48:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009481",
        "document": "1112472059",
        "fullName": "Cindy Carolina Hoyos Manrique",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Pedidos",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "8/12/2022 9:21:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009498",
        "document": "1098780270",
        "fullName": "Brandon Johann Rey Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "8/18/2022 7:02:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009497",
        "document": "66913379",
        "fullName": "Angela Sirley Isaza Puentes",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "8/18/2022 7:02:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009507",
        "document": "1017281261",
        "fullName": "Yordy Jose Barraza Valero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Digitador",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/18/2022 7:02:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009515",
        "document": "1095832701",
        "fullName": "Cesar Sneyder Pinilla Pardo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "8/19/2022 4:00:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009513",
        "document": "1102387307",
        "fullName": "Carlos Humberto Escobar Galvan",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "8/19/2022 4:01:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009511",
        "document": "1143117123",
        "fullName": "Cristian Andres Florez Montaña",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/19/2022 4:01:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009518",
        "document": "1005480387",
        "fullName": "Karen Viviana Gonzalez Ortiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/19/2022 6:51:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009509",
        "document": "1214747302",
        "fullName": "Yeraldin  Mejia Rave",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Asistente De Vp Compras Y Logística",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Internacionales",
        "area": "Compras Internacionales",
        "fechaIngreso": "8/19/2022 8:49:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009535",
        "document": "1090407761",
        "fullName": "Jose Luis Baron Jauregui",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "8/20/2022 3:50:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009538",
        "document": "1096215298",
        "fullName": "Jose Reinel Gomez Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "8/22/2022 7:01:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009537",
        "document": "1005272832",
        "fullName": "Dany Miley Arciniegas Pardo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/22/2022 7:01:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006980",
        "document": "1000774997",
        "fullName": "Jhon Junior Piña Acosta",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "8/23/2022 4:23:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009385",
        "document": "1102388698",
        "fullName": "Milena Pineda Becerra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "8/23/2022 4:53:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009547",
        "document": "1005565154",
        "fullName": "John Alexander Pico Cardenas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "8/23/2022 6:55:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009544",
        "document": "1005542745",
        "fullName": "Juan Esteban Delgado Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "8/23/2022 6:55:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009542",
        "document": "1102383159",
        "fullName": "Daniel Oswaldo Pabon Esparza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "8/23/2022 6:55:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009552",
        "document": "1005156477",
        "fullName": "Yurley Daniela Oviedo Buitrago",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "8/24/2022 3:42:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00946",
        "document": "1004991831",
        "fullName": "Monica Leon Mora",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Compensaciones",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/24/2022 1:53:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009561",
        "document": "1003259065",
        "fullName": "Omar Andres Chinchilla Leon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/24/2022 6:46:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009573",
        "document": "1063286334",
        "fullName": "Wilmar Jose Mestra Suarez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "8/24/2022 7:16:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009553",
        "document": "1020418778",
        "fullName": "Juan Jose Garro Guisao",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "8/24/2022 7:16:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009585",
        "document": "1005289402",
        "fullName": "Gerardo  Marin Aguilar",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "8/25/2022 3:46:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009545",
        "document": "1149189462",
        "fullName": "Francisco Adrian Vivas Orobio",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "8/25/2022 3:31:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00947",
        "document": "1007900667",
        "fullName": "Angie Vanessa Covilla Esparza",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Calidad",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/25/2022 10:08:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009611",
        "document": "1130605672",
        "fullName": "Andres Steven Callejas Valencia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador De Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "8/26/2022 6:08:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009612",
        "document": "1005892950",
        "fullName": "Carlos Arturo Quiñones Cuero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/26/2022 6:08:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00950",
        "document": "73150650",
        "fullName": "David Gustavo Salas De La Hoz",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geográfico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/26/2022 2:08:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009613",
        "document": "79838853",
        "fullName": "Luis Gabriel Villa Herrera",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "8/27/2022 4:35:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009615",
        "document": "1107041631",
        "fullName": "Nathalia  Peña Garcia",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "8/27/2022 4:35:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00928",
        "document": "1005372961",
        "fullName": "Miguel Steven Alvarez Fonseca",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/30/2022 10:35:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00418",
        "document": "17594856",
        "fullName": "Wilmer Alonso Monsalve Patiño",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/30/2022 10:35:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00869",
        "document": "85270527",
        "fullName": "Guillermo Lemus Cantillo",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geografico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "8/30/2022 11:05:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009185",
        "document": "91516779",
        "fullName": "Reynaldo Martinez Antolinez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Servicios Generales",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Bienestar Laboral",
        "fechaIngreso": "8/30/2022 11:35:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009624",
        "document": "1001317108",
        "fullName": "Natalia  Pineda Chavarro",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Auxiliar Talento Humano",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "8/31/2022 3:35:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009620",
        "document": "1096063048",
        "fullName": "Jessica Tatiana Acevedo Sierra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/31/2022 4:06:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009623",
        "document": "1098681733",
        "fullName": "Camilo Andres Jaimes Pinilla",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "8/31/2022 4:06:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009632",
        "document": "1007868277",
        "fullName": "Johan Sebastian Acelas Paredes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/31/2022 7:08:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009642",
        "document": "1098734899",
        "fullName": "Frady Horlay Arguello Fuentes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "8/31/2022 7:08:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009655",
        "document": "1102349120",
        "fullName": "Carlos Sneyder Jaimes Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/1/2022 3:37:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009654",
        "document": "1005539482",
        "fullName": "Pedro Armando Peña Arenas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/1/2022 3:37:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009657",
        "document": "1096188204",
        "fullName": "Martin Jair Arrieta Tafurq",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/1/2022 3:37:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009660",
        "document": "1005653545",
        "fullName": "Osnaider  Guerrero Chavera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/1/2022 3:37:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009648",
        "document": "1065835091",
        "fullName": "Wilmar Enrique Cudriz Lopez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/1/2022 3:37:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007845",
        "document": "1098811952",
        "fullName": "Angie Selena Mendoza Uribe",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/1/2022 4:07:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009656",
        "document": "1098636739",
        "fullName": "Sergio Stivel Carrillo Tobon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Vacunador",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/1/2022 4:07:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009650",
        "document": "1045434851",
        "fullName": "Jeiler  Arenas Valencia",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "9/1/2022 4:07:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009629",
        "document": "1098689432",
        "fullName": "Marizol  Bohorquez Diaz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "9/1/2022 4:07:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009607",
        "document": "1098638597",
        "fullName": "Alba Mercedes Hernandez Lizarazo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "9/1/2022 4:07:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009555",
        "document": "1005309287",
        "fullName": "Victor Josue Ballesteros Carreño",
        "company": "Distraves",
        "regional": "Sang",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/1/2022 4:07:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009557",
        "document": "1000121504",
        "fullName": "Dilan Daniel Pineda Gallo",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/1/2022 4:07:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009670",
        "document": "1144177527",
        "fullName": "Michelle  Guerrero Guerrero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "9/2/2022 5:52:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009672",
        "document": "1113522545",
        "fullName": "Christian  Mina Rivas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/2/2022 5:52:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00541",
        "document": "91356543",
        "fullName": "Alexander - Sarmiento Delgado",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Limpieza Y Desinfeccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 2:52:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00917",
        "document": "1102386971",
        "fullName": "Harlin Dayana Rios Almeida",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 2:52:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00978",
        "document": "74366083",
        "fullName": "Alcibiades Arguello Dominguez",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 2:52:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00847",
        "document": "1098831064",
        "fullName": "Greyberth Leandro Pulido Franco",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 2:52:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00528",
        "document": "1006779586",
        "fullName": "Adan Camilo Orjuela Agudelo",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 2:52:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00582",
        "document": "1007773670",
        "fullName": "Hector Julio Cardenas Diaz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 2:52:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00867",
        "document": "1048941870",
        "fullName": "Darcys Padilla Jimenez",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logisitca",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00810",
        "document": "1005450691",
        "fullName": "Angie Fernanda Chaparro Guevara",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00562",
        "document": "1102361462",
        "fullName": "Carlos Andres Sepulveda Ramirez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00415",
        "document": "1102389006",
        "fullName": "Angie Paola Murillo Monsalve",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00694",
        "document": "1654643",
        "fullName": "Dariany Paola Sandrea Munoz",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00840",
        "document": "1005155375",
        "fullName": "Brayan Sneider Niño Rodriguez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00585",
        "document": "1005542919",
        "fullName": "Carlos Javier Castillo Lancheros",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00974",
        "document": "1102384691",
        "fullName": "Cristian Ferney Villamizar Sarmiento",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Desinfeccion Y Limpieza",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00980",
        "document": "1065833503",
        "fullName": "Anier Ariel Yepes Leones",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00965",
        "document": "1065237654",
        "fullName": "Dario Paez Alvernia",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta Procesados",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00915",
        "document": "1098611073",
        "fullName": "Dana Juliana Quintero Gonzalez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00828",
        "document": "1005234843",
        "fullName": "Carlos Javier Forero Pedraza",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00433",
        "document": "1005151232",
        "fullName": "Breitner Stiven Sanmiguel Suarez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00811",
        "document": "6078882",
        "fullName": "Anny Patricia Aguilar Rojas",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00705",
        "document": "19601063",
        "fullName": "Carlos Yair Venera Molina",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00581",
        "document": "1098775207",
        "fullName": "Celemin Dianeydyth Cañizares",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00894",
        "document": "1005162616",
        "fullName": "Camilo Andres Toloza Galvis",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta Incubadora",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00500",
        "document": "1037608895",
        "fullName": "Carlos Andres Puerta Aguirre",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00924",
        "document": "1094274287",
        "fullName": "Dayana Marcela Vargas Serrano",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00688",
        "document": "1005542106",
        "fullName": "Evelyn Daniela Hernandez Ramirez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00783",
        "document": "1102372253",
        "fullName": "Deisy Yurley Florez Rueda",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00536",
        "document": "1126596786",
        "fullName": "Fabian - Herrera Rodriguez",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00730",
        "document": "1005025339",
        "fullName": "Edgar Villa Arenas",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00752",
        "document": "1007735194",
        "fullName": "Hestor Hugo Criado Correa",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00962",
        "document": "1118565740",
        "fullName": "Edison Brayan Larrota Rodriguez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00822",
        "document": "1098828479",
        "fullName": "Derik Alejandro Bastida Cruz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00044",
        "document": "1126124945",
        "fullName": "Gabriela Alexandra Zafra Viadero",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00958",
        "document": "1005538858",
        "fullName": "Duwan Felipe Esparza Mendez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00982",
        "document": "1005539734",
        "fullName": "Emerson Andres Rueda Maldonado",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00655",
        "document": "1005188960",
        "fullName": "Desired Matute Diaz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00858",
        "document": "1118558498",
        "fullName": "Erick Frank Walteros Montoya",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00964",
        "document": "1098727008",
        "fullName": "Eddison Alberto Otero Vega",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00465",
        "document": "1100974516",
        "fullName": "Edwing - Barragan Mantilla",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00914",
        "document": "1098357945",
        "fullName": "Erika Pimiento Mantilla",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00654",
        "document": "1003526371",
        "fullName": "Geraldine Jimenez Martinez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00925",
        "document": "1047459028",
        "fullName": "Eder Luis Ortiz Viola",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00773",
        "document": "1090501537",
        "fullName": "Gerlis Stiwen Malaver Mora",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00959",
        "document": "1100888229",
        "fullName": "Andres Felipe Hernandez Marin",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00526",
        "document": "1048557151",
        "fullName": "Efrain - Ruizdiaz Venecia",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00487",
        "document": "1102365593",
        "fullName": "Andres Ricardo Pabon Cardenas",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00895",
        "document": "1062910276",
        "fullName": "Eider Arevalo Velasquez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta Incubadora",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00571",
        "document": "1052219563",
        "fullName": "Andro Javier Beleño Quintero",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00555",
        "document": "1095826178",
        "fullName": "Ana Elizabeth Mateus Burbano",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00968",
        "document": "1095819505",
        "fullName": "Angelica Zulay Quintero Mendoza",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar De Bodega",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00519",
        "document": "1099371520",
        "fullName": "Andres Eduardo Marin Mendoza",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/2/2022 9:04:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009579",
        "document": "1007504490",
        "fullName": "Mariano De Jesus Osorio Callejas",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "9/2/2022 10:20:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009580",
        "document": "1044502805",
        "fullName": "Gabriel Jaime Rios Perez",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Ganadería",
        "fechaIngreso": "9/2/2022 10:20:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007952",
        "document": "1005149545",
        "fullName": "Tomas Fernando Gomez Quintero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/5/2022 4:21:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009678",
        "document": "1113527536",
        "fullName": "Carabali Arrechea Martha Alexandra",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/7/2022 5:39:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009686",
        "document": "1015992099",
        "fullName": "Joseph Esteban Amaya Infante",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "9/7/2022 7:04:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009683",
        "document": "1088279853",
        "fullName": "Marco Andres Ramirez Bustamante",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "9/7/2022 7:04:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009690",
        "document": "1113531131",
        "fullName": "Stefani Andrea Flor Fajardo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/7/2022 7:04:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009685",
        "document": "1112477937",
        "fullName": "Ana Julieth Rodriguez Vergara",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "9/7/2022 7:04:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01005",
        "document": "29545266",
        "fullName": "Paola Andrea Castro Ramirez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/7/2022 8:17:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009699",
        "document": "1130637085",
        "fullName": "Fabian  Preciado Rojas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "9/8/2022 5:48:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009695",
        "document": "29351396",
        "fullName": "Viviana  Gomez Barona",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/8/2022 5:48:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009688",
        "document": "1152466145",
        "fullName": "Manuela Valencia Patiño",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Impulsador Profesional",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "9/8/2022 8:34:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009717",
        "document": "1005109881",
        "fullName": "Brignny Alexandra Quintero Becerra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/9/2022 4:06:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00597",
        "document": "1005328884",
        "fullName": "Jaider Eduardo Hortua Paez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 4:05:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00573",
        "document": "984579",
        "fullName": "Javier Antonio Bencomo Santiago",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 4:05:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00976",
        "document": "1233694216",
        "fullName": "Ivan Dario Silva Jaime",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geografico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 4:05:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00607",
        "document": "1095834488",
        "fullName": "Jarvin Sneider Mariño Leal",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 4:05:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00646",
        "document": "1118884659",
        "fullName": "Janeth Carolina Vides Sulbaran",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 4:05:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009708",
        "document": "1005539941",
        "fullName": "Angie Katerine Rodriguez Carvajal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/9/2022 4:05:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00922",
        "document": "1065820982",
        "fullName": "Jair Yesith Solano Ardila",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 4:05:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00913",
        "document": "1005542486",
        "fullName": "Jean Franco Patiño Castellanos",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 4:35:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00652",
        "document": "72006613",
        "fullName": "John Jairo Conrado Cantillo",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00569",
        "document": "1094778127",
        "fullName": "Jorge Luis Arias Diaz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00663",
        "document": "1098691267",
        "fullName": "Jhon Jairo Caicedo Garces",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00470",
        "document": "1003334581",
        "fullName": "Johana Milena Cardenas Obeso",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00600",
        "document": "4669904",
        "fullName": "Jose Ramon Jimenez Lugo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00774",
        "document": "1192915794",
        "fullName": "Jesus David Ramirez Rivera",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00535",
        "document": "1092352088",
        "fullName": "Jhon James Ostos Caballero",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00537",
        "document": "1101384089",
        "fullName": "José Isabel Zabaleta Ortega",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00919",
        "document": "1007137481",
        "fullName": "Jorge Ivan Ripoll Mendoza",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00770",
        "document": "1090475607",
        "fullName": "Jonny Paolo Gamboa Riaño",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00642",
        "document": "1005325548",
        "fullName": "Jonathan Vega Camaron",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00905",
        "document": "1102725995",
        "fullName": "Jesus David Garcia Uribe",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00706",
        "document": "1001022848",
        "fullName": "Jesus Daniel Vidal Denis",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00596",
        "document": "1007669179",
        "fullName": "Jenifer Tatiana Gutierrez Rodriguez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00960",
        "document": "1091134586",
        "fullName": "Jeisson Arley Jaimes Jaimes",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 5:05:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00729",
        "document": "1005324169",
        "fullName": "Kas Hans Sneider Vanegas Cano",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00616",
        "document": "1064839982",
        "fullName": "Jose Reinel Ortiz Gomez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00466",
        "document": "1005447895",
        "fullName": "Laura Katerine Acosta Porras",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00447",
        "document": "1002524510",
        "fullName": "Katerin Natalia Diaz Merchan",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00821",
        "document": "1095794682",
        "fullName": "Leidy Carolina Bastos Tovar",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00443",
        "document": "1005484097",
        "fullName": "Jose Ricardo Vargas Garzon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Plnata",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00910",
        "document": "1098805224",
        "fullName": "Kelly Johanna Medina",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00897",
        "document": "1095957912",
        "fullName": "Juan Camilo Mantilla Fonseca",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta Incubadora",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00598",
        "document": "1005539394",
        "fullName": "Kevin Duvan Jaimes Ramirez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00556",
        "document": "1098815679",
        "fullName": "Karen Viviana Guerrero Castaño",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00554",
        "document": "1005542621",
        "fullName": "Kevin Yesid Delgado Jaimes",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00830",
        "document": "1051287318",
        "fullName": "Julieth Ximena Guerrero Forero",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00955",
        "document": "1037669730",
        "fullName": "Karol Julieth Castro Uribe",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:05:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00441",
        "document": "1098647824",
        "fullName": "Leidy Johana Ortiz Morantes",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00826",
        "document": "1102389407",
        "fullName": "Maria Isabel Diaz Sandoval",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00479",
        "document": "1067030487",
        "fullName": "Luis Mario Julio Pedraza",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00502",
        "document": "1039686400",
        "fullName": "Juan Esteban Rave Acevedo",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Domiciliario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00720",
        "document": "72197120",
        "fullName": "Luis Carlos Jimenez Diaz",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00590",
        "document": "4191776",
        "fullName": "Marcos Eduardo Freites Navas",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00580",
        "document": "1004810030",
        "fullName": "Maria Liliana Cañas Rodriguez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00820",
        "document": "1098746815",
        "fullName": "Leydy Yoana Barco Rodriguez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00702",
        "document": "1044216182",
        "fullName": "Luis Carlos Marquez Batista",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00503",
        "document": "5385458",
        "fullName": "Marcos Jose Colina Garcia",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00439",
        "document": "1102380452",
        "fullName": "Marilyn Monry Quintero",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00833",
        "document": "1102389888",
        "fullName": "Lisbet Alejandra Jaimes Guevara",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00979",
        "document": "1044935236",
        "fullName": "Luis Eduardo Pacheco Pacheco",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logístico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00382",
        "document": "1095839904",
        "fullName": "Marlon Arias",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00845",
        "document": "1096201448",
        "fullName": "Lisbeth Pineda Infante",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00977",
        "document": "1007120231",
        "fullName": "Luis Eduardo Ramos Julio",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logístico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00576",
        "document": "1005539012",
        "fullName": "Maria Fernanda Cadena Florez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00611",
        "document": "1007570433",
        "fullName": "Mauricio Navarro Cudris",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00660",
        "document": "1067030387",
        "fullName": "Luis Humberto Cardenas Chaves",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00557",
        "document": "1003260186",
        "fullName": "Luis Alfonso Zabala Trujillo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/9/2022 8:35:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009737",
        "document": "1098770561",
        "fullName": "Brayan Andres Pelayo Duarte",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "9/10/2022 3:37:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009726",
        "document": "1102386765",
        "fullName": "Harold Jussef Camacho Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/10/2022 3:37:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009742",
        "document": "1107528674",
        "fullName": "Daniela Alejandra Rivera Betancourt",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo Mtto",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/12/2022 7:02:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009739",
        "document": "91355305",
        "fullName": "Isnardo  Solano Carreño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/12/2022 7:02:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009738",
        "document": "1102379461",
        "fullName": "Juan Sebastian Rueda Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/12/2022 7:02:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009753",
        "document": "1062879585",
        "fullName": "Dayana Sandrit Caña Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/12/2022 7:02:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009752",
        "document": "1001969877",
        "fullName": "Cristian Rafael Diaz Manjarrez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "9/12/2022 7:02:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009748",
        "document": "1004911794",
        "fullName": "Brayan Emiro Sanchez Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/12/2022 7:02:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00985",
        "document": "1095946934",
        "fullName": "Juan Sebastian Rodriguez Chacon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Auditoria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 8:00:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00973",
        "document": "1098758574",
        "fullName": "Rafael David Vasquez Giraldo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar De Auditoria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 8:30:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009718",
        "document": "1065624803",
        "fullName": "Rusmaldo Alberto Oñate Molina",
        "company": "Distraves",
        "regional": "Vall",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/12/2022 9:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009735",
        "document": "1007673736",
        "fullName": "Jorge Leonardo Sanabria Sanchez",
        "company": "Distraves",
        "regional": "Elpl",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/12/2022 9:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00906",
        "document": "1102391055",
        "fullName": "Sergio Manuel Giron Ramirez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00631",
        "document": "1102379333",
        "fullName": "Yudith Paola Rivera Barrios",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00656",
        "document": "1102371203",
        "fullName": "Zara Yurley Florez Rojas",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00662",
        "document": "1143587981",
        "fullName": "Shirly Danice Ravelo Herazo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00665",
        "document": "1006737680",
        "fullName": "Yulieth Katherine Castro Barbosa",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00507",
        "document": "53155047",
        "fullName": "Sandra Patricia Morales Parada",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geografico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00699",
        "document": "1098356538",
        "fullName": "Yimy Andrés Niño Avellaneda",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00651",
        "document": "8374650",
        "fullName": "Víctor Manuel Ibañez Harrieta",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logística",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00565",
        "document": "26996824",
        "fullName": "Yurainis Paola Pinedo Argote",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00921",
        "document": "1127054317",
        "fullName": "Yeison Yair Sandoval Leon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00860",
        "document": "1047481949",
        "fullName": "Sofía Isabel Oviedo Moreno",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geografico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00687",
        "document": "1007191373",
        "fullName": "William Alexis Gutierrez Hernandez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00489",
        "document": "1098750624",
        "fullName": "Steven - Quiroga Amado",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00923",
        "document": "1102386582",
        "fullName": "Yenifer Liseth Tasco Naranjo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00911",
        "document": "1095833825",
        "fullName": "William Alexsis Morgado Olarte",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00425",
        "document": "1005338089",
        "fullName": "Zaira Nayibe Suarez Valenzuela",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00695",
        "document": "1002371025",
        "fullName": "Sebastian Escudero Blanco",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00623",
        "document": "1096958931",
        "fullName": "Uber Dario Pinzon Suarez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00899",
        "document": "1005059219",
        "fullName": "Yisel Carolina Botello Vergara",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00951",
        "document": "1098356928",
        "fullName": "Wilmar Barajas Caceres",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00961",
        "document": "1127052729",
        "fullName": "Sebastian Lancheros Albino",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00908",
        "document": "1050543304",
        "fullName": "Yudis Yulieth Guzman Gutierrez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00527",
        "document": "1095943072",
        "fullName": "Oscar Muricio Manrique Pedraza",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00711",
        "document": "43884184",
        "fullName": "Yuli Andrea Restrepo Patiño",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00577",
        "document": "1005150310",
        "fullName": "Orlando Yesid Calderon Jerez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00719",
        "document": "9528462100",
        "fullName": "Nelson Jose Chacin Barboza",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00595",
        "document": "1004823839",
        "fullName": "Yuliana Gonzales Serrano",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00657",
        "document": "1095836969",
        "fullName": "Oscar Enrique Peña Romero",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00437",
        "document": "1192744482",
        "fullName": "Nikol Julihet Sanabria Trujillo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00599",
        "document": "1148459004",
        "fullName": "Michel Oriana Jauregui Paul",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00954",
        "document": "1092356304",
        "fullName": "Samuel David Camargo Sanchez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00819",
        "document": "1098688839",
        "fullName": "Yuli Andrea Agredo Chanaga",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00698",
        "document": "1134914061",
        "fullName": "Natali Acevedo Villamizar",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00615",
        "document": "1005297600",
        "fullName": "Oscar Fabian Ortiz Florez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00751",
        "document": "1098675793",
        "fullName": "Sanabria Castilla Monica Alexandra",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00661",
        "document": "1020065637",
        "fullName": "Nelides Ruiz Diaz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00726",
        "document": "1102373243",
        "fullName": "Paula Andrea Melon Becerra",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00971",
        "document": "1065809164",
        "fullName": "Saul Enrique Rasgo Plata",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00658",
        "document": "1193560921",
        "fullName": "Sebastian Duran Rocco",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/12/2022 9:30:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009765",
        "document": "37615689",
        "fullName": "Nelsy  Galeano Patiño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/13/2022 4:02:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009761",
        "document": "1005411490",
        "fullName": "Libardo  Duran Ruiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/13/2022 4:02:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009766",
        "document": "73212254",
        "fullName": "Roberto Carlos Cuevas Prens",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "9/13/2022 4:02:03 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009776",
        "document": "2114842",
        "fullName": "Eduardo  Cristancho Campos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "9/13/2022 4:02:03 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009760",
        "document": "1006493056",
        "fullName": "Kevin Alejandro Cardenas Gomez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "9/13/2022 7:03:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009729",
        "document": "80881466",
        "fullName": "Laurentino Lopez Castillo",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "9/13/2022 7:03:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009779",
        "document": "1094779739",
        "fullName": "Luis Carlos Contreras Blanco",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "9/13/2022 7:03:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009775",
        "document": "1098697198",
        "fullName": "Maria Xiomara Rojas Bohorquez",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "9/14/2022 1:29:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009773",
        "document": "1098779593",
        "fullName": "Andres Camilo Guevara Amaya",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "9/14/2022 1:29:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009733",
        "document": "1033336941",
        "fullName": "Juan Gabriel Agudelo",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "9/14/2022 8:45:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009731",
        "document": "1007111598",
        "fullName": "Jhojan  Rodriguez Ochoa",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "9/14/2022 8:45:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009796",
        "document": "1152200996",
        "fullName": "Juliana  Lezcano Vasco",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "9/16/2022 7:02:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009798",
        "document": "1152468525",
        "fullName": "Lucero Del Socorro David Arteaga",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Profesional En Formación",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Planeación Financiera",
        "area": "Planeación Financiera",
        "fechaIngreso": "9/16/2022 7:02:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009801",
        "document": "1017245840",
        "fullName": "Carolina Collazos Figueroa",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "9/17/2022 5:01:22 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009802",
        "document": "1095828432",
        "fullName": "Yoniris  Florez Duque",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Corporativo Transgraneles",
        "process": "Corporativo Transgraneles",
        "area": "Corporativo Transgraneles",
        "fechaIngreso": "9/17/2022 5:01:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01009",
        "document": "1045170430",
        "fullName": "Jadith Fernanda Valencia Ibañez",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Asesor Call Center",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/20/2022 3:29:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009804",
        "document": "1118575793",
        "fullName": "Sergio David Amezquita Camargo",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Supervisor Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/20/2022 3:29:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009812",
        "document": "1112880561",
        "fullName": "Diana Marcela Valencia Cadavid",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Auditoria",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "9/21/2022 4:53:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009828",
        "document": "1005624678",
        "fullName": "Andres Felipe Gordillo Franco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/21/2022 6:54:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009835",
        "document": "1192743215",
        "fullName": "Alexander  Parra Camargo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "9/23/2022 3:55:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009833",
        "document": "1102379146",
        "fullName": "Diana Marcela Rondon Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/23/2022 3:55:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009837",
        "document": "1095797416",
        "fullName": "Nicolas Estiben Duran Lagos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "9/23/2022 7:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009839",
        "document": "1062404402",
        "fullName": "Julitza Fernanda Valderrama Ramirez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "9/23/2022 7:04:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009844",
        "document": "1005340607",
        "fullName": "Andres Mauricio Valbuena Leon",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Supervisor Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/24/2022 4:03:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01014",
        "document": "1048995591",
        "fullName": "Hanser Ramiro Diaz Badillo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Auditoría",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/26/2022 1:04:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009854",
        "document": "88271481",
        "fullName": "Jhon Jairo Olivares Olivares",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/26/2022 1:04:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009856",
        "document": "1193302795",
        "fullName": "BC Karen Lorena Bracamonte Sotelo (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/26/2022 7:01:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009862",
        "document": "1112223648",
        "fullName": "Francisco Javier Valencia Vallejo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Tecnico Mecanico",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "9/26/2022 7:01:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009861",
        "document": "39811218",
        "fullName": "Luz Stella Avila Galindo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Aseo Y Cafeteria",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "9/26/2022 7:01:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009868",
        "document": "1113523889",
        "fullName": "Jessica Yajaira Rodriguez Mafla",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/26/2022 7:01:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01010",
        "document": "1115093494",
        "fullName": "Daniela Delgado Villafañe",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/26/2022 7:01:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009870",
        "document": "1003657713",
        "fullName": "Cristian Camilo Rincon",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "9/27/2022 5:01:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009885",
        "document": "1113541040",
        "fullName": "Juan Diego Vera Escobar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "9/27/2022 7:03:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009883",
        "document": "1113524777",
        "fullName": "Diana Paola Castro Martinez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "9/27/2022 7:03:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009859",
        "document": "1140851041",
        "fullName": "Rosellys  Rizzo Romero",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Analista Auditoria Interna",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "9/27/2022 10:00:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00957",
        "document": "1098825999",
        "fullName": "Sarai Daniela Efres Quijano",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Supervisor De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/27/2022 11:00:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00612",
        "document": "1004981752",
        "fullName": "Camilo  Navarro Perez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/27/2022 11:30:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00563",
        "document": "1095814965",
        "fullName": "Daniel Julian Jurado Lozado",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/29/2022 3:49:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00795",
        "document": "1115082518",
        "fullName": "Juan David Ortiz Bejarano",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/30/2022 10:04:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01021",
        "document": "1115081740",
        "fullName": "Alvaro Javier Garcia Gomez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/30/2022 10:04:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01011",
        "document": "1110486582",
        "fullName": "Duvan Ferney Arias Berjan",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/30/2022 10:04:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00991",
        "document": "43808255",
        "fullName": "Adriana Maria Garcia Valencia",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/30/2022 10:16:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00548",
        "document": "1115085583",
        "fullName": "Bryan Seir Orozco Gallego",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "9/30/2022 10:46:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009896",
        "document": "1102353902",
        "fullName": "Omar  Grimaldos Rondon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/1/2022 6:58:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009894",
        "document": "1005541575",
        "fullName": "Cyndy Vanessa Alfaro Anaya",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/1/2022 6:58:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009893",
        "document": "1005539416",
        "fullName": "Leidi Yoana Neira Arenas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/1/2022 6:58:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009897",
        "document": "1102374871",
        "fullName": "Karen Lorena Garces Calderon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/1/2022 6:58:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009907",
        "document": "43877299",
        "fullName": "Liliana Maria Toro Colonia",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Contable",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Contabilidad",
        "area": "Contabilidad",
        "fechaIngreso": "10/3/2022 6:55:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01046",
        "document": "1102381602",
        "fullName": "Stephany Hernandez Jerez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Analista Auditoria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/3/2022 7:53:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009913",
        "document": "1192811965",
        "fullName": "Duban Andres Noriega Garcia",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/5/2022 6:58:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01057",
        "document": "1102370749",
        "fullName": "Deissy Paola Gutierrez Hernandez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01090",
        "document": "1007733563",
        "fullName": "Estefanny Tatiana Reyes Maldonado",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Casino",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01103",
        "document": "1098694676",
        "fullName": "Deiwy Farley Taborda Pabon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01064",
        "document": "1047443536",
        "fullName": "Daniel Eduardo Lozano De Arco",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01028",
        "document": "1102379585",
        "fullName": "Christian Andres Alvarez Calderon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01085",
        "document": "1098805628",
        "fullName": "Duvan Felipe Quintero Gomez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01037",
        "document": "1098610055",
        "fullName": "Alexandra Buelva Orozco",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01111",
        "document": "1000568476",
        "fullName": "Daniel Rodriguez Andrade",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01115",
        "document": "1026155483",
        "fullName": "Andres Mauricio Loaiza Sánchez",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01097",
        "document": "1102371845",
        "fullName": "Adriana Stefania Romero Mateus",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01040",
        "document": "1095831973",
        "fullName": "Ingri Katerine Caballero Florez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01024",
        "document": "1005148345",
        "fullName": "Edwin Bladimir Acosta Camacho",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010133",
        "document": "1036955982",
        "fullName": "Carlos Mario Giraldo Giraldo",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Supervisor Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01100",
        "document": "1102391627",
        "fullName": "Gerly Marcela Silva Campo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01069",
        "document": "1003241068",
        "fullName": "Cenit Navarro Rojas",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 6:58:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009910",
        "document": "36293920",
        "fullName": "Yenny Paola Artunduaga Lugo",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "10/5/2022 7:27:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01102",
        "document": "1005475062",
        "fullName": "Anderson Fabian Suarez Hernandez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Galponero Ofcios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 7:27:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01084",
        "document": "1101074497",
        "fullName": "Elkin Prada Ortiz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 7:27:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01048",
        "document": "1054683329",
        "fullName": "Ingrid Natalia Guayacan Rabelo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Calidad",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 7:27:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009750",
        "document": "1005238280",
        "fullName": "Jose Antonio Pinto Romero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/5/2022 7:56:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009349",
        "document": "1005149543",
        "fullName": "Imanol Gonzalez Ortiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/5/2022 7:56:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009276",
        "document": "1073321214",
        "fullName": "Jesus Antonio Moreno Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/5/2022 7:56:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009551",
        "document": "1007551709",
        "fullName": "Jaider Andres Torres Rico",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/5/2022 7:56:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009587",
        "document": "1090397261",
        "fullName": "Oscar Armando Perez Ortiz",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/5/2022 7:56:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009567",
        "document": "1007333492",
        "fullName": "Jose Diomedes Becerra Becerra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/5/2022 7:56:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009914",
        "document": "1096234986",
        "fullName": "Carlos Alberto Ramirez Agudelo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Cargue Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/5/2022 7:56:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009829",
        "document": "37949302",
        "fullName": "Luz Elena Vargas Niño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Casino",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/5/2022 7:56:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009824",
        "document": "1007432920",
        "fullName": "Fabian Dario Almeida Almeida",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/5/2022 7:56:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009736",
        "document": "1098648365",
        "fullName": "Monica Ojeda Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/5/2022 7:56:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009114",
        "document": "1005323331",
        "fullName": "Andres Felipe Jaimes Chancu",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/5/2022 7:56:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009090",
        "document": "1003379934",
        "fullName": "Karen Dayana Rodriguez Torres",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/5/2022 8:56:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009577",
        "document": "91181965",
        "fullName": "Javier Duarte Jaimes",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/5/2022 8:56:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009062",
        "document": "1099375498",
        "fullName": "William Alonso Rueda Ojeda",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/5/2022 8:56:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009912",
        "document": "1082835496",
        "fullName": "Miguel Angel Lopez Polo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/5/2022 8:56:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009625",
        "document": "83044436",
        "fullName": "John Jairon Rivera Sanchez",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/5/2022 9:13:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009895",
        "document": "91356077",
        "fullName": "Diego Armando Bohorquez Sandoval",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Almacen Y Bodega",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/5/2022 9:13:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009588",
        "document": "91536518",
        "fullName": "Miguel Angel Jimenez Chica",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/5/2022 9:13:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009730",
        "document": "1005553133",
        "fullName": "Cesar Augusto Gomez Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/5/2022 9:13:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009728",
        "document": "37713681",
        "fullName": "Nubia Ortiz Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "10/5/2022 9:30:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009838",
        "document": "1002132050",
        "fullName": "Jhon Jairo Moreno Bustos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/5/2022 9:30:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009758",
        "document": "1098617960",
        "fullName": "Carlos Andres Peñaloza Vasquez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/5/2022 9:30:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00134",
        "document": "1085308473",
        "fullName": "Jesus Edilson Tautas Perez",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/5/2022 9:46:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009891",
        "document": "1098799140",
        "fullName": "Elver Javier Rodriguez Moreno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/6/2022 10:15:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009446",
        "document": "5748276",
        "fullName": "Lirio Arturo Gomez Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/6/2022 10:45:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01313",
        "document": "1001994994",
        "fullName": "Kevin Jose Rios Duarte",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Mantenimiento Pecuaria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/6/2022 10:45:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009937",
        "document": "51984214",
        "fullName": "Diana Mercedes Garcia Morales",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider Canal Directo",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "10/7/2022 6:16:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00852",
        "document": "1005153702",
        "fullName": "Johan Sebastian Sanchez Badillo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 11:46:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00848",
        "document": "1005449483",
        "fullName": "Javier Hernando Rincon Campos",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 11:46:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00851",
        "document": "1095918107",
        "fullName": "Sergio Rodriguez Martinez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 11:46:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00862",
        "document": "1040731794",
        "fullName": "Diego Antonio Muñoz Arce",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logitica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 12:16:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00512",
        "document": "1053790127",
        "fullName": "Juan Sebastian Calderon",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 12:16:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00868",
        "document": "1047460003",
        "fullName": "Yorlando Perez Barrios",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 12:16:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00907",
        "document": "1005272536",
        "fullName": "Diana Marcela Gomez Porras",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 12:16:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00499",
        "document": "1102397026",
        "fullName": "Lisseth Katerine Vargas Martinez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 12:16:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00511",
        "document": "1127939958",
        "fullName": "Mailyn Dahijira Suarez Tochon",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 12:16:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009940",
        "document": "1110290029",
        "fullName": "Juan Esteban Pava Rojas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "10/7/2022 7:06:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009938",
        "document": "71826412",
        "fullName": "Parmenio De Jesus Mesa Correa",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Investigación Y Desarrollo",
        "area": "Granja Experimental Ganadería",
        "fechaIngreso": "10/7/2022 7:06:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009939",
        "document": "1105362753",
        "fullName": "Juan Manuel Dominguez Agudelo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "10/7/2022 7:06:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008739",
        "document": "1052403116",
        "fullName": "Sneyder Arturo Montañez Berdugo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "10/7/2022 7:29:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01117",
        "document": "1143428256",
        "fullName": "Jaime De Jesus Zapata Blanco",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01119",
        "document": "88275833",
        "fullName": "Jackson Alexander Cano Jaimes",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geografico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01120",
        "document": "1049564487",
        "fullName": "Jorge Luis Aleman Contreras",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01083",
        "document": "1007451055",
        "fullName": "Jhon Freddy Poveda Pinilla",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01042",
        "document": "1005369216",
        "fullName": "Jeison Fabian Camargo Panesso",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01035",
        "document": "1102391020",
        "fullName": "Jose Carlos Becerra Sanguino",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01027",
        "document": "91348692",
        "fullName": "Daniel Albornoz Jimenez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01049",
        "document": "1140415562",
        "fullName": "Jennifer Gabriela Diaz Carvajal",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01030",
        "document": "1102357446",
        "fullName": "Jackeline Anaya Torres",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01025",
        "document": "1091969522",
        "fullName": "Jesus Abraham Acosta",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01059",
        "document": "1030546813",
        "fullName": "Jacqueck Michael Jouanolou Escandon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01050",
        "document": "1232890331",
        "fullName": "Jhair Diaz Iza Brayan",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01118",
        "document": "5236939",
        "fullName": "Jose Delgado Gabriel",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01070",
        "document": "1095841978",
        "fullName": "Jonathan Estiben Noguera Parra",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01096",
        "document": "1100220501",
        "fullName": "Jorge Leonardo Romero Florez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:29:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01023",
        "document": "1002446566",
        "fullName": "Luis Javier Aconcha Quiroz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01114",
        "document": "1095949294",
        "fullName": "Nicole Vanesa Acacio Unibio",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Asesor Call Center",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01107",
        "document": "1102379371",
        "fullName": "Miguel Angel Tovar Silvia",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01078",
        "document": "1103713736",
        "fullName": "Juan Carlos Pai",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01026",
        "document": "1003238859",
        "fullName": "Maria Claudia Aguilar Zuñiga",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01051",
        "document": "1005328174",
        "fullName": "Karen Julieth Duran Arias",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01047",
        "document": "13761958",
        "fullName": "Miguel Roberto Corredor Castro",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01104",
        "document": "1098770357",
        "fullName": "Laura Beatriz Tapias Bautista",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Supervisor Granjas Reproductoras",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01034",
        "document": "1048874651",
        "fullName": "Kendry Javier Barrios Angel",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01039",
        "document": "1102368436",
        "fullName": "Nardhy Zareth Caballero Duran",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01082",
        "document": "1099370544",
        "fullName": "Maria Isabel Plata Contrera",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01089",
        "document": "1062880638",
        "fullName": "Luis Eduardo Reyes Garces",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01080",
        "document": "1102377063",
        "fullName": "Laura Vanessa Patiño Rincon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01088",
        "document": "37617004",
        "fullName": "Nora Rocio Rey Rey",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01032",
        "document": "1005330472",
        "fullName": "Martin Ricardo Ascanio Lobo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01113",
        "document": "1003711220",
        "fullName": "Lizeth Camila Moya Rodriguez",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geografico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01108",
        "document": "1007917744",
        "fullName": "Luis Albeiro Velandia Guevara",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01045",
        "document": "1092361155",
        "fullName": "Mayra Alejandra Carrillo Hernandez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/7/2022 8:59:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009948",
        "document": "1007740742",
        "fullName": "Luis Ramon Celis Gutierrez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/8/2022 4:00:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009945",
        "document": "1113532363",
        "fullName": "Francisco Antonio Lenis Lopez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/8/2022 5:59:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009942",
        "document": "1094353119",
        "fullName": "Emerson Fabian Quintero Limas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/8/2022 5:59:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008110",
        "document": "1098410548",
        "fullName": "Omar Ivan Sanabria Rangel",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Profesional En Formacion",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/10/2022 2:31:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009974",
        "document": "1115078522",
        "fullName": "Oliver  Larrahondo Bastidas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "10/10/2022 7:04:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009976",
        "document": "1115083401",
        "fullName": "Andres Mauricio Agudelo Toro",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "10/10/2022 7:04:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009972",
        "document": "94474356",
        "fullName": "Luis Harold Cardona Restrepo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "10/10/2022 7:04:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009977",
        "document": "1005475063",
        "fullName": "Leidy Yuliana Suarez Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/10/2022 7:04:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009965",
        "document": "1098831063",
        "fullName": "Leonardo Jose Pulido Franco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/10/2022 7:04:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009973",
        "document": "1052082218",
        "fullName": "Carlos Mario Montero Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/10/2022 7:04:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009971",
        "document": "1193459182",
        "fullName": "Darly Catalina Cosme Vargas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/10/2022 7:04:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009978",
        "document": "37750702",
        "fullName": "Laury Mileydy Reyes Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/11/2022 4:03:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009992",
        "document": "1025760148",
        "fullName": "Jose Manuel Fernandez Castañeda",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/11/2022 7:06:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009994",
        "document": "11447487",
        "fullName": "Carlos Enrique Pachon Olarte",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/12/2022 4:49:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009993",
        "document": "1071578558",
        "fullName": "Edilberto  Martinez Virguez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/12/2022 4:49:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009995",
        "document": "1019054641",
        "fullName": "Hamilton  Diaz Lugo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/12/2022 4:49:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009996",
        "document": "1144147314",
        "fullName": "Julio Cesar Orozco Saldarriaga",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/12/2022 7:22:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009984",
        "document": "1065905012",
        "fullName": "Uriel David Llain Ropero",
        "company": "Distraves",
        "regional": "Aguc",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/13/2022 1:22:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009944",
        "document": "13872790",
        "fullName": "Oscar Fernando Sierra Carreño",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/13/2022 1:22:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009987",
        "document": "1005156981",
        "fullName": "Jeison Fabian Rangel Palomino",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/13/2022 1:22:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009949",
        "document": "1098358743",
        "fullName": "Cristian Fabian Castro Garces",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/13/2022 1:22:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01060",
        "document": "1095839578",
        "fullName": "Victor Manuel Juanaver Saldaña",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01087",
        "document": "1066348570",
        "fullName": "Yeferson David Reales Garcia",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01101",
        "document": "1102368634",
        "fullName": "Oscar Albeiro Solano Villamizar",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01031",
        "document": "1095940289",
        "fullName": "Yurlevinson Angarita Laguado",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01053",
        "document": "1098787952",
        "fullName": "Ruben Dario Gamez Zabala",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01058",
        "document": "37620221",
        "fullName": "Ruth Jaimes Sanchez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01116",
        "document": "1015436368",
        "fullName": "Oscar Ivan Espitia Pinilla",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Auxiliar Logistica",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01071",
        "document": "1002582537",
        "fullName": "Yenny Lorena Noguera Parra",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01074",
        "document": "1092174415",
        "fullName": "Yurley Karina Ovallos Guerrero",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01066",
        "document": "1005541452",
        "fullName": "Sayra Marcela Moreno Chacon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01052",
        "document": "1003172792",
        "fullName": "Roberto Carlos Duran Mejia",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01063",
        "document": "1100896254",
        "fullName": "Yohana Patricia Lopez Sepulveda",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01079",
        "document": "1095948604",
        "fullName": "Zuli Alejandra Patiño Pinzon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01072",
        "document": "1003335760",
        "fullName": "Tianna Michel Ortega Florian",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009957",
        "document": "1102383064",
        "fullName": "Victor Hugo Rey Bermudez",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/13/2022 1:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010000",
        "document": "1091661450",
        "fullName": "Maria Isabel Tellez Criado",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "10/13/2022 6:49:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010001",
        "document": "1096958139",
        "fullName": "Yesid Fernando Suarez Portilla",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "10/13/2022 6:49:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010003",
        "document": "1114898527",
        "fullName": "Bladimir  Cortes Mosquera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/13/2022 7:19:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010008",
        "document": "1005448727",
        "fullName": "Jeimmy Lucero Forero Rueda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Digitador Sap Cl",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/18/2022 2:25:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007815",
        "document": "1010107767",
        "fullName": "Vivian Julieth Gomez Bolaños",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Bienestar Y Formacion",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "10/18/2022 9:26:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01127",
        "document": "1017163920",
        "fullName": "Juan Camilo Serna Carmona",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/19/2022 2:14:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01126",
        "document": "98707046",
        "fullName": "Lester Armando Ospino Paternina",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/19/2022 2:20:45 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010010",
        "document": "1047493593",
        "fullName": "Haider Vidal Ahumero Perez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/19/2022 3:08:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009813",
        "document": "98469534",
        "fullName": "Jesus Adan Rodriguez Viana",
        "company": "Solla",
        "regional": "Donm",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "10/19/2022 3:20:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01130",
        "document": "1102384091",
        "fullName": "Oscar Mauricio Villamizar",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Liquidador De Cartera",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/20/2022 3:12:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010024",
        "document": "1005106745",
        "fullName": "Oscar Enrique Leon Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/20/2022 6:43:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010016",
        "document": "1102367040",
        "fullName": "Cristian Javier Tarazona Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/20/2022 6:43:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010015",
        "document": "1005331834",
        "fullName": "Nicolas  Espinel Calderon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/20/2022 6:43:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010019",
        "document": "1102349673",
        "fullName": "Santiago  Diaz Rico",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/20/2022 6:43:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010032",
        "document": "1113526591",
        "fullName": "Moises Esteban Romero Ramos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/21/2022 5:43:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005797",
        "document": "1005346612",
        "fullName": "Tatiana Marcela Mateus Zuñiga",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "10/21/2022 3:56:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010052",
        "document": "1114874333",
        "fullName": "Horacio  Galindo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/22/2022 5:59:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010061",
        "document": "1098733262",
        "fullName": "Edward Fernando Ramirez Barcenas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Lider Soporte Ti",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Departamento De Informatica",
        "fechaIngreso": "10/24/2022 12:45:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010062",
        "document": "43272477",
        "fullName": "Sara Eugenia Ballesteros Rios",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Riesgos",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Tesoreria",
        "fechaIngreso": "10/24/2022 6:55:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010038",
        "document": "1007218459",
        "fullName": "BC Laura Sofia Granada Ochoa (Retirada)",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "10/24/2022 6:55:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010040",
        "document": "1007218430",
        "fullName": "Santiago  Dominguez Madrid",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "10/24/2022 6:55:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010042",
        "document": "1093772332",
        "fullName": "Climaco Efren Angarita Carvajal",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Coordinador Cedi",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/24/2022 9:22:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009033",
        "document": "94480009",
        "fullName": "Julian Andres Monsalve Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Ingeniero Electrico",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/24/2022 9:22:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01135",
        "document": "1005345081",
        "fullName": "Nathaly  Rodriguez Ayala",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "10/24/2022 9:22:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010046",
        "document": "1005309717",
        "fullName": "Edin Marcelo Carreño Hernandez",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/24/2022 9:22:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010057",
        "document": "1099365977",
        "fullName": "Raul Fernando Zambrano Ruiz",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/24/2022 9:22:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010048",
        "document": "1005083334",
        "fullName": "Isneider  Guerrero Garcia",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/24/2022 9:22:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010071",
        "document": "1098814020",
        "fullName": "Brenda Lisbeth Duran Mora",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/25/2022 4:06:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010083",
        "document": "1102396572",
        "fullName": "Juan Carlos Sanguino Florez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/25/2022 4:06:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010074",
        "document": "88222272",
        "fullName": "Jairo Alonso Solano Suarez",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/25/2022 4:06:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010104",
        "document": "1003952658",
        "fullName": "Jesus Santiago Valderrama Quimbaya",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/25/2022 7:12:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010102",
        "document": "1007325428",
        "fullName": "Arbey Alexander Reyes Gelvis",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/25/2022 7:12:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010107",
        "document": "1000861874",
        "fullName": "Michael Fernando Ortiz Ospitia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/25/2022 7:12:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010089",
        "document": "1098711239",
        "fullName": "Diana Carolina Rodriguez Orduz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Sst E Higiene",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Salud Y Seguridad En El Trabajo",
        "fechaIngreso": "10/26/2022 12:44:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010113",
        "document": "1005297120",
        "fullName": "Deimar Javier Valderrama Olivos",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/26/2022 12:44:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010022",
        "document": "1102390141",
        "fullName": "Arley Martinez gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "10/26/2022 1:14:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009960",
        "document": "1098723817",
        "fullName": "Adelmo Pinto Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/26/2022 1:14:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010027",
        "document": "1116499847",
        "fullName": "Evert Donet Torres Mejia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/26/2022 1:14:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009954",
        "document": "1101597945",
        "fullName": "Miguel Antonio Martinez Rueda",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/26/2022 1:44:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010044",
        "document": "1102368078",
        "fullName": "Wilson Javier Rueda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/26/2022 1:44:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008245",
        "document": "1035391618",
        "fullName": "Maicol Yesid Cano Cardona",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/26/2022 5:14:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010115",
        "document": "1003535005",
        "fullName": "Laura Valentina Perez Velasquez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "10/26/2022 7:16:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010082",
        "document": "1148441928",
        "fullName": "Yossen Esteban Pizarro Piay",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/26/2022 7:16:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010034",
        "document": "1000443970",
        "fullName": "Mateo Garcia Goez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "10/27/2022 3:30:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010119",
        "document": "1005422748",
        "fullName": "Luis Alberto Herrera Gualdron",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "10/29/2022 3:59:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00254",
        "document": "1641705",
        "fullName": "Juan Alberto Bolaños",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00299",
        "document": "38666062",
        "fullName": "Laura Maria Sierra Miller",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00286",
        "document": "29563468",
        "fullName": "Milva Valencia Quiguanas",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00882",
        "document": "1005705615",
        "fullName": "Leidy Xiomara Caycedo Eraso",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00876",
        "document": "1143926911",
        "fullName": "Lilibeth Liliana Gonzalez Muñoz",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00157",
        "document": "1005833063",
        "fullName": "Manuel Alejandro Rincon Ballesteros",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00175",
        "document": "1061201214",
        "fullName": "Mariela Angulo Cuero",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00288",
        "document": "31446447",
        "fullName": "Mirlady Vasquez Rengifo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00284",
        "document": "29417345",
        "fullName": "Libia Quinayas Rodriguez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00290",
        "document": "31525511",
        "fullName": "Marlene Echeverry Ordoñez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00313",
        "document": "66879735",
        "fullName": "Nidia Astrid Orozco",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00887",
        "document": "1113529192",
        "fullName": "Mauricio Orejuela Ardila",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00804",
        "document": "1143952601",
        "fullName": "Lizeth - Mosquera Aguilar",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00308",
        "document": "66875217",
        "fullName": "Maria Deicy Guaza Carabali",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00326",
        "document": "94373511",
        "fullName": "Lider Ferney Restrepo Rodriguez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00806",
        "document": "1113540323",
        "fullName": "Miguel Angel Carvajal Ortega",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00196",
        "document": "1113539097",
        "fullName": "Maria Fernanda Guzman Ariza",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00365",
        "document": "1144144941",
        "fullName": "Nora Liliana Mendoza Usuga",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00256",
        "document": "16597321",
        "fullName": "Luis Fabian Naranjo Caicedo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00194",
        "document": "1113533444",
        "fullName": "Mayerli Rodriguez Chara",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00328",
        "document": "94466864",
        "fullName": "Luis Fernando Nuñez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00158",
        "document": "1005945017",
        "fullName": "Luisa Fernanda Solarte Balcazar",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00309",
        "document": "66877441",
        "fullName": "Maria Santos Mosquera Valecilla",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00303",
        "document": "4681556",
        "fullName": "Raul Montaño Montaño",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00172",
        "document": "10490360",
        "fullName": "Javier Enrique Lucumi Carabali",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Granja",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00310",
        "document": "66877960",
        "fullName": "Yazmin Caicedo Villalobos",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00189",
        "document": "1113520452",
        "fullName": "Zulay Amparo Dominguez Salazar",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00191",
        "document": "1113524765",
        "fullName": "Vanessa Mina Montaño",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009120",
        "document": "14697854",
        "fullName": "Jesus Orlando Valencia Cabrera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00195",
        "document": "1113534379",
        "fullName": "Yesid Julian Villareal Ceballos",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00721",
        "document": "1007500854",
        "fullName": "Jhon Kener Luango Gilon",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfección",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00878",
        "document": "1114901565",
        "fullName": "Robert Duran Gaviria",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008906",
        "document": "1193224854",
        "fullName": "Isaac David Matute Arriola",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008699",
        "document": "1107070019",
        "fullName": "Jorge Eliecer Izquierdo Amaya",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00193",
        "document": "1113529468",
        "fullName": "Yessica Marcela Saavedra Cruz",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00291",
        "document": "31527452",
        "fullName": "Velquis Baltazar",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00311",
        "document": "66877995",
        "fullName": "Rosa Elvia Astaiza Quevedo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008997",
        "document": "1112219339",
        "fullName": "Juan Gabriel Montilla Jiminez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00294",
        "document": "31579655",
        "fullName": "Sandra Milena Parra Nuñez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Granja",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00285",
        "document": "29508585",
        "fullName": "Yina Paola Aguilar Salcedo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00319",
        "document": "76224677",
        "fullName": "Willinton Carabali Caicedo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00316",
        "document": "66968042",
        "fullName": "Sandra Liliana Pareja Campo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00300",
        "document": "38668127",
        "fullName": "Yuri Alexandra Gonzalez Arboleda",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00298",
        "document": "38612760",
        "fullName": "Yasive Viafara Valor",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00315",
        "document": "66966860",
        "fullName": "Sandra Patricia Higidio Lazo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00171",
        "document": "10472748",
        "fullName": "Simeon Gonzalez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009888",
        "document": "1112489043",
        "fullName": "Yeferson Fernando Serrato Chantre",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Soldador",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009857",
        "document": "1113686007",
        "fullName": "Anyer Alberto Narvaez Mesa",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Electrico Granjas",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009680",
        "document": "1111801605",
        "fullName": "Arturo  Andrade Torres",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta De Hielo",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00305",
        "document": "6445750",
        "fullName": "Hector Favio Barbosa Dominguez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Produccion",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00881",
        "document": "1006306846",
        "fullName": "Darwin Francisco Tenorio Mendoza",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009410",
        "document": "1144087980",
        "fullName": "Arquidio Isaac Zapata Sanchez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario Planta Agua Potable",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00890",
        "document": "1113530462",
        "fullName": "Brayam David Torres Rodriguez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00755",
        "document": "1114883889",
        "fullName": "Jose Eider Cabrera Molina",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Produccion",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00875",
        "document": "1131044614",
        "fullName": "Diana Marcela Aluma Garrido",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009409",
        "document": "4434234",
        "fullName": "Manuel Alberto Gil Ramos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario Planta Ptar",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00304",
        "document": "6228763",
        "fullName": "Cesar William Castro Arce",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00283",
        "document": "29351069",
        "fullName": "Amparo Candelo Tobar",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00359",
        "document": "1002993815",
        "fullName": "Diana Marcela Erazo Fernandez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00281",
        "document": "29347730",
        "fullName": "Cilia Lorena Ceron Gonzalez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009609",
        "document": "1130654365",
        "fullName": "Heberth Alexis Murillo Murillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Tecnico De Refrigeracion",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00888",
        "document": "1112222104",
        "fullName": "Diego Fernando Minotta Landazuri",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00799",
        "document": "1113655388",
        "fullName": "Melderxon Johan Gonzalez Zapata",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Producción",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00192",
        "document": "1113526580",
        "fullName": "Andrea Estefanya Zea Ceballos",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00807",
        "document": "1111759574",
        "fullName": "Diego Luis Moreno Arango",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00312",
        "document": "66878509",
        "fullName": "Darnelly Filigrana",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009952",
        "document": "1088359281",
        "fullName": "Hermes Daniel Araujo Velasquez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Pollinaza",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/31/2022 2:00:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00803",
        "document": "1113524830",
        "fullName": "Jose Luis Rodriguez Hinestroza",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00886",
        "document": "1116270379",
        "fullName": "Jhon Janer Solis Escobar",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00227",
        "document": "1115090744",
        "fullName": "Franki Galeano Taborda",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00221",
        "document": "1115075883",
        "fullName": "Cesar Ivan Amaya Aguirre",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00879",
        "document": "1113529970",
        "fullName": "Jhonny Alexander Guamanga Delgado",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00173",
        "document": "10491608",
        "fullName": "Gelber Andres Viveros Zuñiga",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00885",
        "document": "1113530034",
        "fullName": "Jaime Dalberto Ospina Bonilla",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00208",
        "document": "1114458464",
        "fullName": "Diego Fernando Ocampo Romero",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00282",
        "document": "29347924",
        "fullName": "Eley Lorena Soto Serrano",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00188",
        "document": "1113519300",
        "fullName": "John Breyner Cobo Maquilon",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00226",
        "document": "1115087736",
        "fullName": "Hector Valencia Villada",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00220",
        "document": "1115074879",
        "fullName": "Christian Andres Echavarria Monedero",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Oprativo",
        "area": "Oprativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00180",
        "document": "1070780618",
        "fullName": "Jacke Maggyver Rubiano Briceño",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00289",
        "document": "31525357",
        "fullName": "Jeanel Perez Duque",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00219",
        "document": "1115073416",
        "fullName": "Edward Andres Angarita Paternina",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00292",
        "document": "31529001",
        "fullName": "Elizabeth Galindo Beltran",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00190",
        "document": "1113520699",
        "fullName": "John Faber Andrade Yepes",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00200",
        "document": "1114062258",
        "fullName": "Fabian Andres Florez Londoño",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00202",
        "document": "1114063294",
        "fullName": "Jesus Joel Torres Cifuentes",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00179",
        "document": "1062310553",
        "fullName": "Jhon Danny Cardona Castro",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00249",
        "document": "14651746",
        "fullName": "Jose Edier Hernandez Lucumi",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00205",
        "document": "1114453790",
        "fullName": "Daniel Mauricio Rojas Martinez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00223",
        "document": "1115083133",
        "fullName": "Francisco Antonio Flores Flores",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00330",
        "document": "94472206",
        "fullName": "Campo Elias Palacios Barbosa",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00314",
        "document": "66966400",
        "fullName": "Erika Maria Soto Carvajal",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00297",
        "document": "34620119",
        "fullName": "Flor Edid Arango Borrero",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00211",
        "document": "1115065590",
        "fullName": "Jorge Luis Bautista Cardeño",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00247",
        "document": "1192785612",
        "fullName": "Santiago Salazar Granados",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00756",
        "document": "1192781345",
        "fullName": "Kevin Sebastian Muñoz Niquinas",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Galponero",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00222",
        "document": "1115079369",
        "fullName": "Juan David Valverde Arango",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00209",
        "document": "1114459364",
        "fullName": "Michael Stiven Guarin Salcedo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00161",
        "document": "1006227699",
        "fullName": "Jhoan Esteban Leal Gutierrez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00371",
        "document": "14896320",
        "fullName": "Jorney Rendon Chaverra",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00232",
        "document": "1116251036",
        "fullName": "Norbey Lopez Restrepo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00199",
        "document": "1114059366",
        "fullName": "Jose Miguel Taborda Florez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00229",
        "document": "1115090925",
        "fullName": "Juan Jose Labrada Gutierrez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00201",
        "document": "1114063022",
        "fullName": "Sergio Taborda Florez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00225",
        "document": "1115086037",
        "fullName": "Jhon Alejandro Proaños Barrera",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009606",
        "document": "1112470143",
        "fullName": "John Edward Carabali Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00198",
        "document": "1113659786",
        "fullName": "Omar Stiven Ruiz Acosta",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00224",
        "document": "111508431",
        "fullName": "Jose Milton Perdomo Salas",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00160",
        "document": "1006210908",
        "fullName": "Kevin Alexander Vaca Gutierrez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00230",
        "document": "1115094876",
        "fullName": "Vicente Rolando Ordoñez Vacacela",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00218",
        "document": "1115072121",
        "fullName": "Jhonatan Bejarano Suarez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008686",
        "document": "1112625516",
        "fullName": "Johnatan Alexander Gomez Montoya",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00163",
        "document": "1006336879",
        "fullName": "Oscar Andres Perez Lozano",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00167",
        "document": "1010047503",
        "fullName": "Manuel Jhovany Tovar Calderon",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00228",
        "document": "1115090768",
        "fullName": "Victor Hugo Escobar Flores",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00206",
        "document": "1114454234",
        "fullName": "Jhonatan Julian Valencia Murillo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00329",
        "document": "94471626",
        "fullName": "Jovanny Villafañe Diaz",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00207",
        "document": "1114455044",
        "fullName": "Roberto Antonio Puentes Bedoya",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operario",
        "area": "Operario",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00169",
        "document": "1012358301",
        "fullName": "Yesid Garcia Martinez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00165",
        "document": "1007706931",
        "fullName": "Marcos Daniel Caicedo Alvarez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00333",
        "document": "94475374",
        "fullName": "John Alexander Loaiza Payan",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009506",
        "document": "79928849",
        "fullName": "Rubiel  Villamizar Ramirez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009119",
        "document": "1193220951",
        "fullName": "Jeison David Cabrera Criollo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00182",
        "document": "1089531133",
        "fullName": "Jean Pool Cortes Quiñones",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despacho",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009508",
        "document": "1130673708",
        "fullName": "Eder Luis Algarin Avedaño",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00036",
        "document": "1113695393",
        "fullName": "Brayan Steven Rodriguez Vanegas",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Canastillas",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008722",
        "document": "1039289652",
        "fullName": "Cesar  Lopez Gomez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00258",
        "document": "16723360",
        "fullName": "Jonas Mezu Brand",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despacho",
        "uen": "Gerencia Administrativo",
        "process": "Administrativo",
        "area": "Administrativo",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00235",
        "document": "1130947594",
        "fullName": "Julio Cesar Vidal Caicedo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despacho",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00327",
        "document": "94430741",
        "fullName": "Javier Zuñiga Perlaza",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar Despachos",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008781",
        "document": "1144063823",
        "fullName": "Kevin  Garces Valens",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00197",
        "document": "1113541655",
        "fullName": "Carlos Fernando Camacho Vallecilla",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despacho",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00257",
        "document": "16654675",
        "fullName": "Carlos Alberto Plaza Perez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar Servicios Generales",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00236",
        "document": "1130949711",
        "fullName": "Miguel Antonio Ballesteros Ponton",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despacho",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008820",
        "document": "1002958128",
        "fullName": "Fabian Andres Paz Gaviria",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor De Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00260",
        "document": "16836569",
        "fullName": "Jair Cortes Sandoval",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despacho",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00185",
        "document": "1112487099",
        "fullName": "Hector Fabio Silva Lopez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009933",
        "document": "1193101581",
        "fullName": "Juan Camilo Mendoza Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Inspector Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00233",
        "document": "1118259367",
        "fullName": "Jamilson Steven Ortiz Cardona",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00035",
        "document": "1124219087",
        "fullName": "Arrison Ortiz Baron",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Canastillas",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008701",
        "document": "1114210497",
        "fullName": "Jaider Salvador Jimenez Bueno",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00178",
        "document": "1062302277",
        "fullName": "Rhonal Alberto Arbelaez Reyes",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Domiciliario",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00034",
        "document": "1112490748",
        "fullName": "Jhonny Esteban Duarte Machado",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00155",
        "document": "1002920539",
        "fullName": "Brayan Llante Llante",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00745",
        "document": "1112459120",
        "fullName": "Luis Armando Navas Ponton",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00170",
        "document": "10304065",
        "fullName": "Cristian Herney Flor Alban",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Domiciliario",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00032",
        "document": "16849683",
        "fullName": "Luis Alberto Carabali Orojuela",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00153",
        "document": "1001710433",
        "fullName": "Jhoan Restrepo Cardona",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Domiciliario",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00187",
        "document": "1113309261",
        "fullName": "Yonatan Jaramillo Molina",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Domiciliario",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00317",
        "document": "72279211",
        "fullName": "Pedro Manuel Vergara Torres",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00246",
        "document": "1192779316",
        "fullName": "Daniel Santiago Caicedo Cardenas",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Domiciliario",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00248",
        "document": "1234194892",
        "fullName": "Cristian Mauricio Ortiz Campo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Domiciliario",
        "uen": "Gerencia Ventas",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00204",
        "document": "1114389381",
        "fullName": "Bairon Guillermo Hoyos Viafara",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00321",
        "document": "9203557230",
        "fullName": "Andres Alberto Roman Graterol",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00306",
        "document": "6445939",
        "fullName": "Andres Felipe Palacios Barbosa",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario",
        "uen": "Gerencia Industrial",
        "process": "Operario",
        "area": "Operario",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00156",
        "document": "1005570532",
        "fullName": "Daniel Andres Hinestroza Salcedo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00883",
        "document": "6878397",
        "fullName": "Luis Alejandro Urbano Marquez",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Industrial",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00174",
        "document": "1058977025",
        "fullName": "Naun Riveiro Alvarado Daza",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Domiciliario",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009256",
        "document": "1085686042",
        "fullName": "Pablo Andres Hernandez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor De Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00031",
        "document": "1061021395",
        "fullName": "Diego Fernando Sanchez Angulo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Operario De Alistamiento",
        "uen": "Gerencia Pecuaria",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010130",
        "document": "1007892847",
        "fullName": "Harlie Yesid Puello Barraza",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010137",
        "document": "91467607",
        "fullName": "Luis Antonio Sequeda Ojeda",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010132",
        "document": "1102725117",
        "fullName": "Sergio Alonso Benitez Cantillo",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010135",
        "document": "1102549451",
        "fullName": "Laura Gissela Vera Vesga",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010141",
        "document": "1005564579",
        "fullName": "Arsenio  Ayala Diaz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010139",
        "document": "1102548892",
        "fullName": "Carlos Humberto Gonzalez Torres",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "10/31/2022 2:00:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010151",
        "document": "71526932",
        "fullName": "Juan Sebastian Cataño Molina",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Infraestructura",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Infraestructura",
        "fechaIngreso": "11/1/2022 5:50:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010152",
        "document": "1017240192",
        "fullName": "Alba Cecilia Ceballos Londoño",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Auxiliar Salud Ocupacional",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Salud Ocupacional",
        "fechaIngreso": "11/1/2022 6:51:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010153",
        "document": "31574391",
        "fullName": "Trinidad Jimena Vidal Cortes",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Regional De Talento Humano",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "11/1/2022 6:51:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006755",
        "document": "1113786232",
        "fullName": "Simon  Haeusler Jimenez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Analista De Auditoria",
        "uen": "Corporativo Auditoría Corporativa",
        "process": "Auditoría Corporativa",
        "area": "Auditoría Corporativa",
        "fechaIngreso": "11/1/2022 6:51:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002911",
        "document": "1100892356",
        "fullName": "William  Quiñonez Delgado",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/1/2022 7:52:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000617",
        "document": "13569678",
        "fullName": "Luis Domingo Leon Nino",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/1/2022 7:52:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005636",
        "document": "1099364366",
        "fullName": "Deiby Alexander Gonzalez Ortiz",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/1/2022 7:52:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010072",
        "document": "11205211",
        "fullName": "Edwin Alejandro Calderon Valdes",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/1/2022 7:52:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010085",
        "document": "1115082391",
        "fullName": "Carlos Alberto Zapata Rivera",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "11/1/2022 7:52:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010070",
        "document": "1114120692",
        "fullName": "Victor Alfonso Calle Gil",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "11/1/2022 8:26:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010094",
        "document": "1116435910",
        "fullName": "Hooverney Cardona Cuaran",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/1/2022 8:26:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010096",
        "document": "1144069678",
        "fullName": "Ivan Andres Holguin Marin",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "11/1/2022 8:26:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010076",
        "document": "1115091137",
        "fullName": "Juan Camilo Herrada Lopez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/1/2022 8:56:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010069",
        "document": "1116278605",
        "fullName": "Nicolas Eduardo Castro Espitia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "11/1/2022 8:56:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010078",
        "document": "1115082522",
        "fullName": "Cristian David Pulgarin Ospina",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/1/2022 8:56:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010081",
        "document": "1114454712",
        "fullName": "Luis Eduardo Cañas Sandoval",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "11/1/2022 8:56:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01136",
        "document": "1090467792",
        "fullName": "Jessica Lizbeth Perez Garcia",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Vendedor Geográfico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/1/2022 10:27:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006933",
        "document": "5009558285",
        "fullName": "Angel Yefferson Osorio Daza",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/1/2022 11:26:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010157",
        "document": "98706810",
        "fullName": "Jheyson Stiven Valle Valle",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "11/2/2022 4:35:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010158",
        "document": "1001480272",
        "fullName": "Kelly Jojana Zapata Rojo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Análisis Químico",
        "fechaIngreso": "11/2/2022 4:35:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010159",
        "document": "1037504294",
        "fullName": "Juan Daniel Isaza Marquez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "11/2/2022 4:35:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00873",
        "document": "43323691",
        "fullName": "Alejandra Milena Cano Betancurt",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/2/2022 4:39:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01142",
        "document": "16747483",
        "fullName": "Wilson Hernandez Bejarano",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Vendedor Supernumerario",
        "uen": "Gerencia Ventas",
        "process": "Ventas",
        "area": "Ventas",
        "fechaIngreso": "11/2/2022 8:39:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010064",
        "document": "1114058199",
        "fullName": "Maribel Mejia Ulloa",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "11/2/2022 9:10:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010145",
        "document": "1098823040",
        "fullName": "BC Angie Marcela Martinez Gomez (Shared)",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/2/2022 9:10:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01147",
        "document": "1192922766",
        "fullName": "Andres Giovanny Montaño Caicedo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/2/2022 9:39:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00342",
        "document": "10934188",
        "fullName": "Carlos Augusto Guerra Tapia",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Bodega",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/2/2022 10:10:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00892",
        "document": "1005327133",
        "fullName": "Camilo Andres Uribe Estrada",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/2/2022 10:10:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00120",
        "document": "1085315046",
        "fullName": "Andres Ricardo Cuchala Chachinoy",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/2/2022 10:10:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010160",
        "document": "1113522026",
        "fullName": "Alvaro Fauricio Lenis",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/3/2022 5:49:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010090",
        "document": "1115088540",
        "fullName": "Juan Camilo Lenis Valencia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/3/2022 12:19:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010087",
        "document": "1112883380",
        "fullName": "Johan Sebastian Villa Rayo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/3/2022 12:19:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010075",
        "document": "1115083952",
        "fullName": "Jonathan Reyes Muñoz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/3/2022 12:19:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00099",
        "document": "1115079203",
        "fullName": "Edwar Andres Acevedo Valencia",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxilair De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 1:19:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00992",
        "document": "1112106542",
        "fullName": "Cristhian Camilo Andrade Ortega",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 1:19:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00127",
        "document": "1085346712",
        "fullName": "Eduar Oswaldo Delgado Diaz",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 1:19:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00871",
        "document": "1116255075",
        "fullName": "Cristhian Ardila Pareja",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 1:19:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00123",
        "document": "1085316763",
        "fullName": "Edisson Armando Rosero Potosi",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 1:19:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00122",
        "document": "1085319656",
        "fullName": "Diego Armando Buesaquillo Josa",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 1:19:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00464",
        "document": "1115076368",
        "fullName": "Gentil Humberto Zuñiga Izquierdo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Roduccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 1:49:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00398",
        "document": "1085253541",
        "fullName": "Fausto Edinson Castro Muñoz",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 1:49:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00130",
        "document": "1087960524",
        "fullName": "Ivan Fernando Reyes Caez",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 2:50:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008924",
        "document": "1115071157",
        "fullName": "Johnny Alexander Alvarez Quirama",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "11/3/2022 4:20:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00101",
        "document": "1114390520",
        "fullName": "Jefferson Freyder Burbano Araujo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 4:20:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00742",
        "document": "1115074282",
        "fullName": "Jefersson Exneider Saavedra Garcia",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 4:20:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00133",
        "document": "1193202366",
        "fullName": "Jeisson Camilo Montachez Montilla",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 4:20:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00132",
        "document": "1004410982",
        "fullName": "Jefferson Harvey Narvaez Maigual",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 4:20:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00939",
        "document": "1116276186",
        "fullName": "Jhon Eduar Calero Castaño",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 5:20:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010091",
        "document": "1115065932",
        "fullName": "Jose Andres Ruiz Agudelo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "11/3/2022 7:23:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010092",
        "document": "1116264841",
        "fullName": "Yeferson Humberto Sepulveda Cardona",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "11/3/2022 7:29:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00938",
        "document": "1114059228",
        "fullName": "Jhonatan Benjumea Muñoz",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:29:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01148",
        "document": "1040745382",
        "fullName": "Jeison Steven Arango Agudelo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:29:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01016",
        "document": "1116158883",
        "fullName": "John Freddy Higuera Montero",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:59:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00136",
        "document": "98389023",
        "fullName": "Jose Emilio Lopez Lopez",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Mecanico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:59:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01132",
        "document": "1114061156",
        "fullName": "Jose Yilber Escobar Rodriguez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:59:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00347",
        "document": "1085259749",
        "fullName": "Jose Luis Realpe Vallejo",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:59:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00045",
        "document": "1115091115",
        "fullName": "Jose Alejandro Aguirre Montes",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxilair De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:59:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00348",
        "document": "1085306373",
        "fullName": "Jose Luis De La Cruz Meneses",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxilair De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:59:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00801",
        "document": "1115086643",
        "fullName": "Jonathan Franco Ledesma",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:59:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00549",
        "document": "1115079423",
        "fullName": "John Sebastian Barona Moreno",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:59:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00943",
        "document": "1113041429",
        "fullName": "Jose Jhonatan Rojas Prado",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Prouccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 8:59:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00463",
        "document": "1126245186",
        "fullName": "Lisbeth Del Carmen Diaz Avila",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:29:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00081",
        "document": "1115074140",
        "fullName": "Julian Alberto Insuasty Potes",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:29:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00944",
        "document": "1116160170",
        "fullName": "Luis Miguel Mendez Bermeo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:29:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00138",
        "document": "1085297461",
        "fullName": "Leydi Fernanda Fuentes Tarapues",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Servicio Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:29:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00137",
        "document": "1085342333",
        "fullName": "Luis Carlos Piandoy Criollo",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:29:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01002",
        "document": "1001545559",
        "fullName": "Leidy Piedad Gomez Ramirez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:29:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01145",
        "document": "31658359",
        "fullName": "Leidy Johana Cristancho Murcia",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Aseo Y Cafeteria",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:29:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00082",
        "document": "6322707",
        "fullName": "Luis Alberto Quintero Galeano",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:29:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00139",
        "document": "1233190479",
        "fullName": "Luis Alberto Delgado Achicanoy",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:29:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00996",
        "document": "1114064440",
        "fullName": "Sebastian Serna Marin",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:59:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00352",
        "document": "1085302560",
        "fullName": "Ricardo Agustin Pinchao Buesaquillo",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:59:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00789",
        "document": "1003167973",
        "fullName": "Santiago Barreto Rangel",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:59:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00986",
        "document": "1118300926",
        "fullName": "Miguel Angel Caicedo Meza",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:59:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00351",
        "document": "1085256693",
        "fullName": "Omar David Sinza Aranda",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:59:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01004",
        "document": "1193090296",
        "fullName": "Nicolas Segundo Gamarra Martinez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:59:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00015",
        "document": "21802857",
        "fullName": "Maria Guillermina Campiño Betancur",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:59:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00354",
        "document": "1085247469",
        "fullName": "Miller Andres Erazo Parra",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:59:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00349",
        "document": "1004410944",
        "fullName": "Maickol Yamith Rivera Guancha",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 9:59:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00074",
        "document": "1193510242",
        "fullName": "William David Torres Ramirez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/3/2022 10:29:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010168",
        "document": "1102381668",
        "fullName": "Luis Ernesto Torra Cuadros",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "11/4/2022 4:01:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010173",
        "document": "1003260381",
        "fullName": "Andres David Zabala Trujillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/4/2022 4:01:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010167",
        "document": "1113538873",
        "fullName": "Jose Armando Saavedra Cruz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "11/4/2022 6:00:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001173",
        "document": "63532683",
        "fullName": "Maria Teresa Gonzalez Carvajal",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Directora De Formación Y Desarrollo",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Formación Y Dllo",
        "fechaIngreso": "11/4/2022 12:59:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010095",
        "document": "1115093177",
        "fullName": "Juan Camilo Escobar Muriel",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/4/2022 1:29:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010088",
        "document": "1115084574",
        "fullName": "Juan David Ospina Ureña",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/4/2022 2:20:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010180",
        "document": "1107835888",
        "fullName": "Gino Antonio Cardenas Azain",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/4/2022 7:23:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00358",
        "document": "1004410939",
        "fullName": "Wilmer Daniel Timaran Rosero",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/4/2022 7:51:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00628",
        "document": "1095958225",
        "fullName": "Jason Gabriel Rangel Pabon",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Roduccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/4/2022 8:50:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010182",
        "document": "1095948431",
        "fullName": "Edinson Raul Orozco Moreno",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/8/2022 1:55:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01151",
        "document": "1115093733",
        "fullName": "Ana Maria Ortiz Ramirez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/8/2022 1:55:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010183",
        "document": "1007382980",
        "fullName": "Luis Juvencio Garcia Sierra",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/8/2022 1:55:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010184",
        "document": "1087046323",
        "fullName": "Pablo Andres Lopez Mora",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "11/8/2022 6:58:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00513",
        "document": "1037603887",
        "fullName": "Lizeth Carolina Rendon Marin",
        "company": "Distraves-jiro",
        "regional": "",
        "jobTitle": "Vendedor Geografico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/8/2022 11:10:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010205",
        "document": "1005448124",
        "fullName": "Neider Andrekw Mercado Luna",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/9/2022 7:14:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010204",
        "document": "1098691964",
        "fullName": "Anderson Jose Mesa Ardila",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "11/9/2022 7:14:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010202",
        "document": "40944402",
        "fullName": "Dorca Esther Pedroza Ospina",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "11/9/2022 7:14:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010199",
        "document": "1116160233",
        "fullName": "Andres Felipe Mercado Cortes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/9/2022 7:14:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010193",
        "document": "75072149",
        "fullName": "Jhon Fabio Lopez Gonzales",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Director Pecuaria",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "11/10/2022 7:51:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010214",
        "document": "1098635347",
        "fullName": "Oscar Javier Lopez Archila",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/11/2022 4:56:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010215",
        "document": "1098130145",
        "fullName": "Cristian Eduardo Jaimes Palencia",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "11/11/2022 4:56:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010217",
        "document": "4718111",
        "fullName": "Stephany Paola Delci Castellanos",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "11/11/2022 4:56:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000087",
        "document": "10275880",
        "fullName": "Jaime Eduardo Valencia Ramirez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Gerente Produccion Pecuaria",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "11/11/2022 11:55:39 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010175",
        "document": "43845012",
        "fullName": "Lina Marcela Maya Fernandez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Gerente De Tesoreria",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Tesoreria",
        "fechaIngreso": "11/15/2022 6:47:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010218",
        "document": "1113528151",
        "fullName": "Jefferson  Meneses Sanchez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/17/2022 5:47:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010236",
        "document": "94482976",
        "fullName": "Eduardo Jose Caceres Molina",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Coordinador Regional Cedis",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Logística Y Distribución",
        "fechaIngreso": "11/18/2022 6:52:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010234",
        "document": "1007558612",
        "fullName": "Yuzeth Tatiana Pedroza Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/18/2022 7:00:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010250",
        "document": "1115077055",
        "fullName": "Mario German Medina Valencia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "11/19/2022 4:36:03 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010252",
        "document": "1116160682",
        "fullName": "Kevin Andres Garcia Moreno",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "11/21/2022 7:10:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010257",
        "document": "1102357885",
        "fullName": "Lievano  Barragan Barragan",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/21/2022 7:10:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00859",
        "document": "1095829829",
        "fullName": "Yonh Mario Gonzalez Estupiñan",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/22/2022 2:06:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009469",
        "document": "1095807948",
        "fullName": "Juan Gullermo Romero Mantilla",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "11/22/2022 2:06:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009470",
        "document": "1002101377",
        "fullName": "Robinson Javier Rojas Pacheco",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "11/22/2022 2:06:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010185",
        "document": "1115074955",
        "fullName": "Miguel Angel Zamora Delgado",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "11/22/2022 2:51:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010224",
        "document": "43972624",
        "fullName": "Natalia Andrea Rivera Cortes",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Tesoreria",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Tesoreria",
        "area": "Tesoreria",
        "fechaIngreso": "11/23/2022 6:48:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010208",
        "document": "1101360215",
        "fullName": "Yurley Diaz Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "11/23/2022 7:46:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010138",
        "document": "1005449594",
        "fullName": "Diomedes Diaz Montañez",
        "company": "Distraves",
        "regional": "giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/23/2022 8:16:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010100",
        "document": "1005163885",
        "fullName": "Darlinson Arley Medina Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/23/2022 8:16:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00621",
        "document": "1007735138",
        "fullName": "Jaison David Perez Nuñez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "11/23/2022 8:16:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010209",
        "document": "1005549338",
        "fullName": "Diego Diaz Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "11/23/2022 8:16:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010129",
        "document": "1002681364",
        "fullName": "Andres Fabian Martinez Monroy",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "11/23/2022 8:16:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010276",
        "document": "1047450560",
        "fullName": "Maria Elena Torres Gil",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "11/24/2022 3:48:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010275",
        "document": "1095942716",
        "fullName": "Lisset Dayana Rugeles Romero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "11/24/2022 3:48:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010274",
        "document": "1007917673",
        "fullName": "Aura Maria Diaz Hinestroza",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "11/24/2022 3:48:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010246",
        "document": "1007379630",
        "fullName": "Juan Camilo Villegas Hernandez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "11/24/2022 4:47:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010245",
        "document": "1113041666",
        "fullName": "Julian Andres Marles Caicedo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "11/24/2022 4:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010244",
        "document": "1114061227",
        "fullName": "Miguel Angel Giraldo Nieva",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "11/24/2022 4:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010241",
        "document": "1114454564",
        "fullName": "Andres Felipe Velasco Valencia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "11/24/2022 4:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010248",
        "document": "1114061205",
        "fullName": "Oscar Eduardo Betancourt Sanchez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "11/24/2022 4:47:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010239",
        "document": "1116160701",
        "fullName": "Cristian Camilo Salazar Zuleta",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "11/24/2022 4:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010238",
        "document": "1116270040",
        "fullName": "Jesus Javier Perez Hernandez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "11/24/2022 4:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010242",
        "document": "1006371551",
        "fullName": "Brayan  Lopez Palacios",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "11/24/2022 4:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010240",
        "document": "1115076653",
        "fullName": "Cristhian David Martinez Gasca",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "11/24/2022 4:47:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010277",
        "document": "1125762094",
        "fullName": "Milder Jose Galindo Ribera",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/24/2022 1:47:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010253",
        "document": "1115087287",
        "fullName": "Jhon Handerson Del Risco Ledezma",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Movimiento De Materias Primas",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "11/24/2022 2:13:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010247",
        "document": "1115083419",
        "fullName": "Yilmar Andrey Gutierrez Salazar",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/24/2022 3:01:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010249",
        "document": "1006228136",
        "fullName": "Jefersson Andrey Suaza Castañeda",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "11/24/2022 3:20:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010267",
        "document": "1007453012",
        "fullName": "Esteban  Olave Ortiz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Seleccion",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "11/24/2022 4:38:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010280",
        "document": "1005541769",
        "fullName": "Alfredo Jose Sanchez Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "11/24/2022 6:44:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010282",
        "document": "1004463891",
        "fullName": "Andres Jose Montes Anicharico",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "11/24/2022 6:44:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007982",
        "document": "1098810656",
        "fullName": "Hayko Alexis Macias Meza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "11/24/2022 7:43:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010288",
        "document": "1098630837",
        "fullName": "Sergio Mario Riberos Montero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Planta Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "11/24/2022 7:43:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004153",
        "document": "1192761143",
        "fullName": "Carlos Daniel Almeida Sierra",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/24/2022 8:43:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000029",
        "document": "5611347",
        "fullName": "Manuel Reatiga Anaya",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "11/24/2022 9:13:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010305",
        "document": "1050039735",
        "fullName": "Joaquin Pablo Gonzalez Salinas",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "11/25/2022 1:43:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010314",
        "document": "1102775022",
        "fullName": "Dilson Jose Vega Guerrero",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/25/2022 7:19:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010315",
        "document": "1005449371",
        "fullName": "Anderson Arley Luna Rodriguez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "11/25/2022 7:19:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001448",
        "document": "1098386280",
        "fullName": "Oscar Alejandro Niño Munoz",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "11/25/2022 8:44:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010297",
        "document": "1036627551",
        "fullName": "Elcy Yaqueline Morales Toro",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "11/28/2022 12:58:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010212",
        "document": "1111769959",
        "fullName": "Einer Murgueitio Gamboa",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "11/28/2022 8:25:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010213",
        "document": "1144157795",
        "fullName": "Jose Carlos Ocoro Payan",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "11/28/2022 8:25:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010320",
        "document": "1005110127",
        "fullName": "Karen Julieth Galvis Buenos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/1/2022 3:43:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010322",
        "document": "1102380234",
        "fullName": "Jose Eduardo Jerez Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "12/1/2022 3:43:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001821",
        "document": "1007189007",
        "fullName": "Jose Eduin Salazar Moreno",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/1/2022 1:42:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010352",
        "document": "52542953",
        "fullName": "Katerine Angelica Arango Pulido",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "12/1/2022 6:56:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010312",
        "document": "1005829102",
        "fullName": "Bladimir  Cardona Mendoza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Nomina",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "12/2/2022 3:44:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010313",
        "document": "1144195382",
        "fullName": "Jeniffer Andrea Tamayo Colorado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista Ssta",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "12/2/2022 3:44:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009583",
        "document": "1095832682",
        "fullName": "Diego Andres Sandoval Peña",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "12/2/2022 8:51:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010358",
        "document": "1007439457",
        "fullName": "Eriberto  Paba Vasquez",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/5/2022 1:24:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010365",
        "document": "1006362835",
        "fullName": "Jenny Andrea Galindez Daza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "12/5/2022 7:08:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010364",
        "document": "1143960942",
        "fullName": "Luis Miguel Larrahondo Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/5/2022 7:08:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010385",
        "document": "1042214541",
        "fullName": "Esneider  Mejia Mejia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/6/2022 7:09:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010375",
        "document": "1052943656",
        "fullName": "Juan Carlos Saravia Fuerte",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "12/6/2022 7:09:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010386",
        "document": "1005542111",
        "fullName": "Angel Yesith Moreno Velandia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "12/6/2022 7:09:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010278",
        "document": "1096207104",
        "fullName": "BC Alexander Ruiz Ramirez (Shared)",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Call Center",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/7/2022 5:07:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010362",
        "document": "1102385381",
        "fullName": "Luis Alberto Basto Medina",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "12/7/2022 5:07:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00963",
        "document": "1098748426",
        "fullName": "Milton Eduardo Martinez Herrera",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/7/2022 5:07:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010302",
        "document": "15488971",
        "fullName": "Cesar Augusto Madrid",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "12/7/2022 5:07:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010272",
        "document": "1092387536",
        "fullName": "Leticia Sanchez Zapata",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/7/2022 5:07:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010378",
        "document": "1098689046",
        "fullName": "John Freddy Gonzalez Araque",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/7/2022 5:07:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010327",
        "document": "1095828400",
        "fullName": "Camilo Andres Florez Cantillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/7/2022 5:38:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010273",
        "document": "1007665706",
        "fullName": "Francy Julieth Perez Vesga",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/7/2022 5:38:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010390",
        "document": "1111758840",
        "fullName": "Yuli Estefany Gamboa Alegria",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "12/8/2022 4:39:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010391",
        "document": "1006230421",
        "fullName": "Valeria  Escobar Caicedo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "12/8/2022 4:39:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010392",
        "document": "1112388533",
        "fullName": "Maryury  Montenegro Burgos",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "12/8/2022 4:39:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010393",
        "document": "1112956986",
        "fullName": "Juan Carlos Arce Serna",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "12/8/2022 4:39:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010401",
        "document": "1116284105",
        "fullName": "Jorge Esteban Ospina Lopez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "12/9/2022 7:11:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010396",
        "document": "29346699",
        "fullName": "Kelly Yohanna Vente Mosquera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/9/2022 7:11:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010370",
        "document": "94073100",
        "fullName": "Cesar Eduardo Useche Tobar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Coordinador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/9/2022 10:18:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010389",
        "document": "1053847447",
        "fullName": "Elberth Andrey Ariza Galvez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador Junior",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/9/2022 10:28:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000082",
        "document": "8829161",
        "fullName": "Wilson Quintero Lima",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/10/2022 12:28:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002588",
        "document": "80350142",
        "fullName": "Edwin Alberto Campos Bermudez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/10/2022 12:28:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001656",
        "document": "1099372050",
        "fullName": "Edwin Javier Perez Blanco",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/10/2022 12:28:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009851",
        "document": "1005344114",
        "fullName": "Jonathan Samir Caballero Rojas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "12/10/2022 12:28:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010405",
        "document": "91517228",
        "fullName": "Fabio Alexander Ramirez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/10/2022 3:59:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010402",
        "document": "1095932373",
        "fullName": "Diana Marcela Velasco Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/10/2022 3:59:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010410",
        "document": "1093784479",
        "fullName": "Eric David Ortiz Rubio",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/10/2022 3:59:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010419",
        "document": "13516547",
        "fullName": "Jorge Eliecer Bohorquez Cano",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/10/2022 3:59:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010418",
        "document": "1099734806",
        "fullName": "Maria Paula Mendoza Caballero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/10/2022 3:59:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010406",
        "document": "1116858750",
        "fullName": "Liliana Carolina Barajas Silva",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/10/2022 3:59:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010416",
        "document": "1102366540",
        "fullName": "Yerson Julian Sanabria Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/10/2022 3:59:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010422",
        "document": "1118570773",
        "fullName": "Mercedes  Herreño Patiño",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Logistica De Salida",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "12/10/2022 4:59:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010216",
        "document": "1042999861",
        "fullName": "Fermin Eduardo Cervantes Alvarez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Plantas Asesoradas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "12/12/2022 1:15:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010399",
        "document": "1115065772",
        "fullName": "Jhon Janbersson Aristizabal Correa",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "12/12/2022 2:45:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01586",
        "document": "1095955252",
        "fullName": "Cristian David Galvis Pedraza",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Comercial",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/12/2022 6:48:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010439",
        "document": "1005177132",
        "fullName": "Christian David Villanueva Montero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/13/2022 3:47:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010441",
        "document": "1096959981",
        "fullName": "Delman Yesid Zambrano Galeano",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/13/2022 3:47:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010440",
        "document": "1005109413",
        "fullName": "BC Angy Valeria Jaimes Hernandez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/13/2022 3:47:41 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010388",
        "document": "1151955289",
        "fullName": "Said  Namen Towers",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Auditoria",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "12/14/2022 3:16:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006622",
        "document": "1113536700",
        "fullName": "Leidy Jhoanna Larrea Sanchez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Analista De Informacion",
        "uen": "Gerencia General",
        "process": "Gerencia General",
        "area": "Gerencia General",
        "fechaIngreso": "12/14/2022 9:26:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009785",
        "document": "1003250856",
        "fullName": "Darwin Andrey Sanchez Prado",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "12/15/2022 9:43:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009784",
        "document": "1098763072",
        "fullName": "Ender Remolina Siuarez",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "12/15/2022 9:43:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010451",
        "document": "1005565465",
        "fullName": "Camila Andrea Pineda Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/16/2022 3:45:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01373",
        "document": "1005364011",
        "fullName": "Wilmar  Cuervo Montoya",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/16/2022 3:45:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010449",
        "document": "1065823077",
        "fullName": "Yennys Maria Diaz Musa",
        "company": "Distraves",
        "regional": "Vall",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "12/16/2022 2:14:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010426",
        "document": "1102719026",
        "fullName": "Jose Raul Rangel Ramirez",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/16/2022 2:14:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010417",
        "document": "1120658680",
        "fullName": "Jesus Manuel Arcon Cardenas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/16/2022 2:14:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010427",
        "document": "1127618874",
        "fullName": "Rudy Enrique Vera Vega",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/16/2022 2:14:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010443",
        "document": "1073692433",
        "fullName": "Jefferson Andres Orellanos Gutierrez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "12/16/2022 2:14:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010421",
        "document": "91479843",
        "fullName": "Pedro Jose Ramirez Riaño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/16/2022 2:14:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010430",
        "document": "1098804486",
        "fullName": "Cesar Giovanny Avellaneda Mejia",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/16/2022 2:14:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010423",
        "document": "1099553890",
        "fullName": "Luis Deivinson Suarez Perez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/16/2022 2:14:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010460",
        "document": "1098811646",
        "fullName": "Daniel Fernando Afanador Benavides",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/16/2022 6:45:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009860",
        "document": "1049318263",
        "fullName": "Jose Isabel Romero Mercado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "12/16/2022 8:44:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00760",
        "document": "1095803430",
        "fullName": "Noe  Garcia Ortiz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/16/2022 8:44:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01201",
        "document": "1102720758",
        "fullName": "Jhonatan Ferney Cordero Sarmiento",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/16/2022 9:46:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01177",
        "document": "1101520340",
        "fullName": "Monica Yurley Acelas Cristancho",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/16/2022 9:46:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01272",
        "document": "1102386953",
        "fullName": "Yeison Francisco Reyes Chaparro",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/16/2022 9:46:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01249",
        "document": "1005449241",
        "fullName": "Erika Liseth Mendoza Suarez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/16/2022 10:16:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01215",
        "document": "1102397341",
        "fullName": "Jerson Estiven Garnica Galvis",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/16/2022 10:16:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01269",
        "document": "1005484036",
        "fullName": "Joan Sebastian Quiroga Rueda",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Oeprario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/16/2022 10:16:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01383",
        "document": "1098611121",
        "fullName": "Wuilfranhay  Duran Herrera",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/17/2022 3:48:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01439",
        "document": "1102387503",
        "fullName": "Samuel David Picon Godoy",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta Limpieza Y Desinfeccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/17/2022 3:48:25 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010448",
        "document": "1098736120",
        "fullName": "Laura Marcela Gonzalez Quintero",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "12/19/2022 1:35:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010462",
        "document": "1140867865",
        "fullName": "Lina Maria Paez Martinez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Nacionales",
        "area": "Compras De Materias Primas",
        "fechaIngreso": "12/19/2022 2:22:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010472",
        "document": "1038064186",
        "fullName": "Mateo  Giraldo Cano",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "12/19/2022 6:55:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010484",
        "document": "1102349350",
        "fullName": "Brayan Estiven Leon Veloza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/20/2022 3:54:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010482",
        "document": "1102357816",
        "fullName": "Leydi Johana Corredor Castillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/20/2022 3:54:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010480",
        "document": "1128444186",
        "fullName": "Dana Elizabeth Restrepo Gonzalez",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "12/20/2022 4:54:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010481",
        "document": "1193239432",
        "fullName": "Victor Manuel Sinisterra Salazar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "12/20/2022 5:53:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004597",
        "document": "1102382448",
        "fullName": "Leydy Johana Garces Cañas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "12/20/2022 1:53:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010485",
        "document": "1046692342",
        "fullName": "Jose Luis Pimiento Guette",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/20/2022 1:53:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010447",
        "document": "13538010",
        "fullName": "Orlando Silva Reyes",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/20/2022 2:23:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010444",
        "document": "1098673342",
        "fullName": "Javier Eduardo Jaimes Florez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "12/20/2022 2:23:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010429",
        "document": "1098785083",
        "fullName": "Elkin Yesid Villamizar Remolina",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "12/20/2022 2:23:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010490",
        "document": "1005160823",
        "fullName": "Lilia Fernanda Forero Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/20/2022 6:43:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010488",
        "document": "1001297619",
        "fullName": "Daniel Alejandro Cerquera Jerez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/20/2022 6:43:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010495",
        "document": "1005654218",
        "fullName": "Sandy Gissela Ruth Pardo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "12/20/2022 6:43:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01591",
        "document": "1007384548",
        "fullName": "Juliana Ivonne Fontecha Angarita",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Administrativa",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/20/2022 6:43:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010499",
        "document": "1005338093",
        "fullName": "Catalina  Fuentes Duarte",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/20/2022 6:43:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010492",
        "document": "1098687544",
        "fullName": "Wanda Katherine Ruiz Chaves",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/20/2022 6:43:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010498",
        "document": "1102369880",
        "fullName": "Saady Jose Botello Vergara",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/20/2022 6:43:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01385",
        "document": "1102385732",
        "fullName": "Karina  Figueroa Blanco",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/20/2022 6:43:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010520",
        "document": "1054681757",
        "fullName": "Juan David Flechas Ramos",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/21/2022 3:44:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010513",
        "document": "1007913085",
        "fullName": "Karen Yelissa Perez Garnica",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/21/2022 3:44:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010501",
        "document": "1102715130",
        "fullName": "Lorena Andrea Abreo Marmolejo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/21/2022 3:44:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010518",
        "document": "91348210",
        "fullName": "Pedro Jesus Osorio Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/21/2022 3:44:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010530",
        "document": "1006211022",
        "fullName": "Natalia  Cobo Soto",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010535",
        "document": "1094778293",
        "fullName": "Yury Carolina Paez Paez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010531",
        "document": "1010077569",
        "fullName": "Esteban  Lozano Meneses",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010534",
        "document": "63531540",
        "fullName": "Beatriz Helena Pinzon Guerrero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010525",
        "document": "1095835327",
        "fullName": "Julian Eduardo Celis Alvarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010533",
        "document": "1006439012",
        "fullName": "Maria Isabel Santiago Vanegas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010528",
        "document": "1005162877",
        "fullName": "Maria Camila Neira Parada",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010524",
        "document": "1098641081",
        "fullName": "Yerli Yohana Tarazona Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010529",
        "document": "1192785761",
        "fullName": "Jasneidith  Castro Uribe",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010537",
        "document": "1005302174",
        "fullName": "Leydy Caterine Sepulveda Sepulveda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010532",
        "document": "1098799259",
        "fullName": "Edward Alfonso Rivera Ojeda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01420",
        "document": "1098690090",
        "fullName": "Cielo Rocio Mateus Amado",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/21/2022 8:29:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010544",
        "document": "1095957901",
        "fullName": "Karen Yuliana Caicedo Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/22/2022 3:58:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010549",
        "document": "80912920",
        "fullName": "Franyerson  Mina Escobar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/22/2022 5:58:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010567",
        "document": "1192893604",
        "fullName": "Brayan  Fernandez Martinez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "12/22/2022 7:04:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010557",
        "document": "1102388682",
        "fullName": "Yeremy Stevep Quintero Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/22/2022 7:04:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010565",
        "document": "1005542026",
        "fullName": "Johan Sebastian Suarez Leon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/22/2022 7:04:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010555",
        "document": "1102350730",
        "fullName": "Ludy Dayana Naranjo Perez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/22/2022 7:04:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010554",
        "document": "1096959131",
        "fullName": "Julieth Karina Sequeda Estupiñan",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "12/22/2022 7:04:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010551",
        "document": "1014199321",
        "fullName": "Juan Carlos Poveda Uyaban",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "12/22/2022 7:04:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010556",
        "document": "1073250758",
        "fullName": "Edwin Sneider Palacio Arismendy",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "12/22/2022 7:04:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01394",
        "document": "1149454867",
        "fullName": "Jose Gregorio Guerrero Moncada",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/23/2022 4:04:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010586",
        "document": "1095835857",
        "fullName": "Daniel Eduardo Navarrete Jimenez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/23/2022 4:04:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010571",
        "document": "1007547261",
        "fullName": "Juan Sebastian Velasquez Ospina",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "12/23/2022 6:03:22 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010588",
        "document": "1098646703",
        "fullName": "Diego Armando Esteban Pabon",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/23/2022 2:03:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010509",
        "document": "32935533",
        "fullName": "Angelica Maria Arnedo Puentes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/23/2022 2:03:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010583",
        "document": "1005461183",
        "fullName": "Anderson  Melgarejo Celis",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "12/23/2022 2:03:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010589",
        "document": "1073426051",
        "fullName": "Karen Daniela Melo Gomez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "12/23/2022 7:05:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011854",
        "document": "94289453",
        "fullName": "Carlos Mario Rodriguez Nieto",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "12/23/2022 7:34:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01350",
        "document": "1234340303",
        "fullName": "Pedro Antonio Arias Carrasquilla",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/24/2022 4:05:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010610",
        "document": "1102371049",
        "fullName": "Gersson Andres Ayala Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta_entrenamiento",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/24/2022 4:05:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010609",
        "document": "1005443833",
        "fullName": "Claudia Vanesa Mayorga Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/24/2022 4:05:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01428",
        "document": "1090487628",
        "fullName": "Daniel  Ortega Castillo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/24/2022 4:05:27 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010600",
        "document": "1095934219",
        "fullName": "Yordhi Leonardo Otalora Alfonso",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/26/2022 1:36:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010611",
        "document": "1115915628",
        "fullName": "Yerson Jair Fuentes Alonso",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "12/26/2022 1:36:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010607",
        "document": "1005464941",
        "fullName": "Neyla Lizeth Ortiz Ferreira",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "12/26/2022 1:36:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010613",
        "document": "1095789055",
        "fullName": "Daniel Fernando Dotor Acacio",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "12/26/2022 7:09:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010615",
        "document": "1017263902",
        "fullName": "Valeria  Cossio Acevedo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Comunicaciones",
        "uen": "Corporativo Consumo Y Mercadeo",
        "process": "Negocios Internacionales",
        "area": "Negocios Internacionales",
        "fechaIngreso": "12/27/2022 4:38:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006714",
        "document": "94540786",
        "fullName": "Yeison Javier Giraldo Parra",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Inventarios",
        "uen": "Cadena De Suministro",
        "process": "Logistica Administrativa",
        "area": "Logistica Administrativa",
        "fechaIngreso": "12/27/2022 7:18:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004474",
        "document": "1102360592",
        "fullName": "Laura Katerine Merchan Ortiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "12/27/2022 8:47:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010474",
        "document": "1040325011",
        "fullName": "Leidy marcela Correa avendaño",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "12/27/2022 9:19:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010581",
        "document": "1035439710",
        "fullName": "John Alejandro Hernandez Carvajal",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "12/28/2022 3:19:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01331",
        "document": "1127063086",
        "fullName": "Marilyn Yorliany Paez Moreno",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Liquidador Cartera",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/28/2022 9:46:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010547",
        "document": "1005151682",
        "fullName": "Johan Alexander Villamizar Ariza",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/28/2022 11:16:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010584",
        "document": "91181654",
        "fullName": "Juan Carlos Gomez Vega",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/30/2022 3:54:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01077",
        "document": "1091132825",
        "fullName": "Arley Paez Gelvez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/30/2022 3:54:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010522",
        "document": "1005346493",
        "fullName": "Juan David Carreño Correa",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/30/2022 3:54:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01436",
        "document": "1003336003",
        "fullName": "Reynel Perez Contreras",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/30/2022 3:54:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010512",
        "document": "1005542693",
        "fullName": "Diego Andres Niño Pabon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "12/30/2022 4:24:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010510",
        "document": "1095842581",
        "fullName": "Carlos Alexander Delgado Grimaldos",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "12/30/2022 4:24:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01446",
        "document": "1095799372",
        "fullName": "Jheison Andres Reyes Bautista",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/30/2022 4:24:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010493",
        "document": "1007193763",
        "fullName": "Jesus David Contreras Pinto",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "12/30/2022 4:24:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010576",
        "document": "1095907945",
        "fullName": "Victor Manuel Suarez Sierra",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "12/30/2022 4:24:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01414",
        "document": "1098616755",
        "fullName": "Michael Martinez Cubides",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "12/30/2022 4:24:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010552",
        "document": "80656695",
        "fullName": "Jaime Alexander Castillo Galindo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "1/2/2023 9:27:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010612",
        "document": "1234338198",
        "fullName": "Jesus David Jimenez Ardila",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/2/2023 9:46:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010621",
        "document": "1085689052",
        "fullName": "Yeny Marcela Perez Alvarez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Practicante",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "1/5/2023 5:55:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010620",
        "document": "34316069",
        "fullName": "Claudia Lorena Muñoz Quira",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "1/5/2023 5:55:20 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010626",
        "document": "1001226532",
        "fullName": "Laura Marcela Julio Suarez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "1/5/2023 6:58:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010623",
        "document": "1076325202",
        "fullName": "Graciela  Garabata Garcia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/5/2023 6:58:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010624",
        "document": "1113520611",
        "fullName": "Stefannya  Calderon Candelo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/5/2023 6:58:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010639",
        "document": "1020719134",
        "fullName": "Paula Vanessa Galeano Castillo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "1/7/2023 4:52:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010643",
        "document": "1039472866",
        "fullName": "Liceth Dayana Gomez Bedoya",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010660",
        "document": "1070585345",
        "fullName": "Adriana Paola Sanchez Castro",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010662",
        "document": "1004439501",
        "fullName": "Jenier Dayan Insuasty Urbano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010646",
        "document": "1232389781",
        "fullName": "Darwin Diomar Sandrea Chacon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010644",
        "document": "1127667794",
        "fullName": "Juana Elvira Macias Rangel",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010659",
        "document": "91356643",
        "fullName": "Hernando  Palomino Caballero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010653",
        "document": "1006352476",
        "fullName": "Luz Neyi Palomino Hurtado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010664",
        "document": "1005210173",
        "fullName": "Santiago  Pineda Solano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010657",
        "document": "1005288982",
        "fullName": "Vieris Tatiana Beltran Porto",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010663",
        "document": "1005542677",
        "fullName": "Yuris Tatiana Parra Castillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010656",
        "document": "1102379981",
        "fullName": "Edinson Ernesto Ayala Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "1/10/2023 7:09:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010679",
        "document": "1102349856",
        "fullName": "Bladimir  Moreno Cristancho",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "1/11/2023 3:39:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010692",
        "document": "1098637801",
        "fullName": "Yury Jaidy Tasco Giraldo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/11/2023 6:46:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010688",
        "document": "1098765127",
        "fullName": "Andres Eduardo Estupiñan Maya",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Sst E Higiene",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Salud Y Seguridad En El Trabajo",
        "fechaIngreso": "1/11/2023 10:26:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010703",
        "document": "1095837253",
        "fullName": "Elkin David Berrio Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "1/12/2023 4:05:26 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010697",
        "document": "1113653915",
        "fullName": "Nicolas  Rodriguez Montes",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Practicante",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "1/12/2023 6:04:22 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010642",
        "document": "1017216554",
        "fullName": "Juan Pablo Valencia Gomez",
        "company": "Transgraneles",
        "regional": "Itag",
        "jobTitle": "Estudiante En Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "1/13/2023 2:28:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010372",
        "document": "1128479052",
        "fullName": "Carlos Augusto Yepes Ardila",
        "company": "Solla",
        "regional": "Entr",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "1/13/2023 2:41:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010585",
        "document": "1004684889",
        "fullName": "Jose Giovani Marin Castaño",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "1/13/2023 2:41:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01055",
        "document": "1049654134",
        "fullName": "Marily  Godoy Leon",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Supervisor Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/16/2023 2:34:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008839",
        "document": "1118573532",
        "fullName": "Ever Humberto Guanaro Piraban",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/16/2023 2:38:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01479",
        "document": "1060879406",
        "fullName": "Maydi Yulieth Urrea Urrea",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Aprendiz",
        "uen": "Gerencia Administrativo",
        "process": "Pasante Calidad",
        "area": "Pasante Calidad",
        "fechaIngreso": "1/16/2023 2:52:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010712",
        "document": "1098794734",
        "fullName": "Jakson Stiven Roa Siabato",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/16/2023 6:56:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010704",
        "document": "1095954127",
        "fullName": "Mayer Andrea Arguello Cordero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/16/2023 6:56:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010710",
        "document": "1102349468",
        "fullName": "Leidy Tatiana Mantilla Barrios",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/16/2023 6:56:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010706",
        "document": "1005152545",
        "fullName": "Luz Stella Triana Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/16/2023 6:56:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010714",
        "document": "1098757616",
        "fullName": "Ivan Andres Rios Jimenez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "1/17/2023 3:55:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010665",
        "document": "63540150",
        "fullName": "Diana Yamile Gutierrez Mancilla",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "1/17/2023 8:59:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007520",
        "document": "1095836290",
        "fullName": "Karen Dayana Rueda Bautista",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "1/17/2023 9:06:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01472",
        "document": "1007673714",
        "fullName": "Karol Geraldine Vera Rozo",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar De Talento Humano",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/17/2023 9:19:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010635",
        "document": "94480582",
        "fullName": "Jose Julian Dominguez Aguirre",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/17/2023 9:49:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010633",
        "document": "1115065263",
        "fullName": "Fredy Correa Quitian",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "1/17/2023 10:19:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010638",
        "document": "1115079176",
        "fullName": "Lukas David Torres Millas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "1/17/2023 10:19:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010719",
        "document": "1005371536",
        "fullName": "Narly Vanessa Bautista Badillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "1/18/2023 3:51:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010726",
        "document": "1115086397",
        "fullName": "Jean Carlo Salgado Sanchez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "1/19/2023 5:52:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010721",
        "document": "1112968325",
        "fullName": "Ivan Rene Agudelo Orozco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "1/19/2023 5:52:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010724",
        "document": "94475611",
        "fullName": "Leonardo Fabio Millan Becerra",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "1/19/2023 5:52:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010723",
        "document": "1115070837",
        "fullName": "Jhon Jairo Rivera Viafara",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "1/19/2023 5:52:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009770",
        "document": "1148219899",
        "fullName": "Wiston Alberto Lora Carrillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/19/2023 7:11:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010732",
        "document": "1118539646",
        "fullName": "Aura Edith Gualdron Mendoza",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "1/20/2023 3:54:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008936",
        "document": "1114453959",
        "fullName": "Jonathan Medina Porras",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "1/20/2023 2:53:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010720",
        "document": "1005784665",
        "fullName": "Stephani  Vargas Daza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Administrativo Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "1/20/2023 4:37:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010745",
        "document": "1017245338",
        "fullName": "Edison Daniel Marin Luna",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/20/2023 7:11:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010749",
        "document": "1115082854",
        "fullName": "Brian Joseph Dominguez Tabares",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "1/20/2023 7:11:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010740",
        "document": "1050555838",
        "fullName": "Edinson Fabian Suarez Vera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/20/2023 7:11:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010731",
        "document": "91488192",
        "fullName": "Oscar Julian Navarro Poveda",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "1/20/2023 9:29:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01342",
        "document": "1006371791",
        "fullName": "Maykol Stevens Marin Solis",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/21/2023 5:00:44 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010694",
        "document": "1128272328",
        "fullName": "Claudia Carolina Agudelo Arroyave",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Supervisor Puntos De Venta",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "1/23/2023 3:02:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010655",
        "document": "1102549015",
        "fullName": "Uriel Andres Moreno Ortiz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "1/23/2023 3:02:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010672",
        "document": "1099364022",
        "fullName": "Alexander  Pedraza Herrera",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "1/23/2023 3:02:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010680",
        "document": "1005549153",
        "fullName": "Julian Arnulfo Fajardo Rincon",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "1/23/2023 3:02:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010675",
        "document": "1099364348",
        "fullName": "Fredy Antonio Vargas Quintero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "1/23/2023 3:02:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010650",
        "document": "1005450667",
        "fullName": "Daniel Andres Jaimes Ferreira",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "1/23/2023 3:02:43 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006263",
        "document": "1095804482",
        "fullName": "Luis Eduardo Pedraza Garcia",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "1/23/2023 3:32:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01423",
        "document": "8828075",
        "fullName": "Oscar David Miranda Correa",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/23/2023 7:05:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010776",
        "document": "1007447085",
        "fullName": "Jhoana Carolina Escobar Zea",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/23/2023 7:05:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010765",
        "document": "1002162629",
        "fullName": "Yulferiner Julian Viloria Alvarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "1/23/2023 7:05:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010787",
        "document": "1098793230",
        "fullName": "Ruben Dario Garces Manosalva",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "1/24/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010789",
        "document": "1007784684",
        "fullName": "Fabian Eduardo Sarmiento Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "1/24/2023 4:05:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010786",
        "document": "1037647863",
        "fullName": "Camila  Angarita Suarez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "1/24/2023 4:35:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010793",
        "document": "1062907921",
        "fullName": "Isaias Manuel Padilla Marquez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "1/24/2023 7:07:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010792",
        "document": "1005330461",
        "fullName": "Ludwing  Sandoval Vasquez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "1/24/2023 7:07:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010795",
        "document": "1057572810",
        "fullName": "Marco Eduardo Archila Cepeda",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/24/2023 7:07:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004728",
        "document": "91538538",
        "fullName": "Jorge Armando Chivata Martinez",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/24/2023 9:33:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010800",
        "document": "1098802699",
        "fullName": "Wilfredo  Vera Villamizar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/25/2023 4:06:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010807",
        "document": "1012450470",
        "fullName": "Nicolas  Mejia Arbelaez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "1/25/2023 4:06:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010805",
        "document": "1005543374",
        "fullName": "Wilmer  Avila Rojas",
        "company": "Distraves",
        "regional": "pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "1/25/2023 4:06:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010804",
        "document": "1102383917",
        "fullName": "Elkin Alexis Santamaria Bueno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "1/25/2023 4:06:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010803",
        "document": "13285445",
        "fullName": "Alexander  Tarazona Urbina",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/25/2023 4:06:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010802",
        "document": "1102549531",
        "fullName": "Omar  Serrano Sinuco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/25/2023 4:06:12 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01154",
        "document": "1114058019",
        "fullName": "Mary Yorley Suarez Guerrero",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/25/2023 2:36:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010825",
        "document": "1113701104",
        "fullName": "Alberto Alexander Ramos Oliva",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/25/2023 7:10:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010824",
        "document": "1006108571",
        "fullName": "Henry Alejandro Morales Velasco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/25/2023 7:10:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010823",
        "document": "1144171504",
        "fullName": "Andres Felipe Holguin Manrique",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/25/2023 7:10:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010832",
        "document": "1005129415",
        "fullName": "Yonson  Gallo Fuentes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "1/26/2023 3:38:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010830",
        "document": "1098824689",
        "fullName": "Angela Andrea Gallardo Sarmiento",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "1/26/2023 3:38:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010844",
        "document": "1193143906",
        "fullName": "Nathalia  Ayala Diaz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "1/26/2023 3:38:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010829",
        "document": "1102375416",
        "fullName": "Brayam  Blanco Ferias",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "1/26/2023 3:38:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010837",
        "document": "1098794880",
        "fullName": "Pablo Cesar Florez Santana",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "1/26/2023 3:38:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010849",
        "document": "1033790013",
        "fullName": "Jimy  Alvarado Alvarado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "1/26/2023 3:38:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010842",
        "document": "1062913261",
        "fullName": "Duvan  Ramirez Sanchez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "1/26/2023 3:38:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010846",
        "document": "1192925317",
        "fullName": "Cintia Daniela Gomez Tarazona",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "1/26/2023 3:38:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010834",
        "document": "1005372271",
        "fullName": "Yurley Natalia Zarate Tarazona",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "1/26/2023 3:38:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010813",
        "document": "1005149916",
        "fullName": "Andres Felipe Gonzalez Gonzalez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "1/26/2023 2:07:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01483",
        "document": "1102369706",
        "fullName": "Anjy Daniela Ortega Barragan",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Profesional En Formación",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "1/26/2023 3:07:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010859",
        "document": "91527479",
        "fullName": "Victor Saul Perez Castillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "1/26/2023 7:04:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010866",
        "document": "1122819658",
        "fullName": "Daniel Enrique Andar Romero",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Estudiante En Practica",
        "uen": "Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "1/28/2023 4:51:07 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010869",
        "document": "1005541974",
        "fullName": "Andres Felipe Lasso Tarazona",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/31/2023 6:58:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010884",
        "document": "1091133862",
        "fullName": "Frank Jeinson Rodriguez Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "1/31/2023 6:58:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010878",
        "document": "1095951963",
        "fullName": "Jean Carlo Cala Millan",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "1/31/2023 6:58:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010883",
        "document": "1005346137",
        "fullName": "Javier Andres Narvaez Anaya",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "1/31/2023 6:58:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010876",
        "document": "1100220410",
        "fullName": "Henry Armando Lopez Pinto",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/31/2023 6:58:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010881",
        "document": "1098604588",
        "fullName": "Ivan Alberto Almeida Muñoz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "1/31/2023 6:58:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010871",
        "document": "1062878367",
        "fullName": "Xilena  Caña Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/31/2023 6:58:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010870",
        "document": "49668975",
        "fullName": "Arelys  Ballena Duarte",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "1/31/2023 6:58:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010885",
        "document": "1095824450",
        "fullName": "Edson Romario Andrade Moreno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "2/1/2023 3:47:40 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01440",
        "document": "1005451213",
        "fullName": "Carlos Daniel Pinzon Landinez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Conductor Incubadora",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/1/2023 2:16:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01452",
        "document": "91485853",
        "fullName": "Miguel Angel Rojas Solano",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Conductor",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/1/2023 2:46:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000100",
        "document": "13285380",
        "fullName": "Humberto Leon Suarez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/1/2023 3:16:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010863",
        "document": "72262953",
        "fullName": "Jaair Jose Florez Guzman",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/1/2023 3:16:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010841",
        "document": "1098781754",
        "fullName": "Ruben Elias Vega Cantillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "2/1/2023 3:16:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00092",
        "document": "1010159721",
        "fullName": "Yenci Carolina Muñoz Moreno",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/1/2023 6:29:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010892",
        "document": "1022872",
        "fullName": "Jose Manuel Albornoz Jaramillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/2/2023 5:49:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010815",
        "document": "1096200919",
        "fullName": "Ismael  Martinez Cristo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "2/2/2023 4:19:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010902",
        "document": "71399102",
        "fullName": "Jorge Humberto Gomez Arboleda",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/2/2023 6:53:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010898",
        "document": "1144038473",
        "fullName": "Luz Elvira Cuaspud",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Practicante",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "2/2/2023 7:21:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010855",
        "document": "1098356254",
        "fullName": "Maria Fernanda Bautista Caballero",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010809",
        "document": "13539555",
        "fullName": "Luis Alfredo Suarez Hernandez",
        "company": "Distraves",
        "regional": "Elpl",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010851",
        "document": "1095822397",
        "fullName": "Jerson Jesus Barrera Nieves",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010877",
        "document": "1098671466",
        "fullName": "Edwin Eduardo Villamizar Barragan",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010812",
        "document": "1098806766",
        "fullName": "Yurley Tatiana Prada Mariño",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010835",
        "document": "1095811629",
        "fullName": "Andrea Paola Camargo Mancilla",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010811",
        "document": "1099376122",
        "fullName": "Javier Alonso Manrique Rey",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010889",
        "document": "37724662",
        "fullName": "Diana Lucia Calderon Charris",
        "company": "Distraves",
        "regional": "cart",
        "jobTitle": "Vendedor Geografico_entrenamiento",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010854",
        "document": "1007679354",
        "fullName": "Lilia Rubiela Barajas Figueroa",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010879",
        "document": "1005346049",
        "fullName": "Yeison Felipe Celis Gutierrez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010810",
        "document": "1097302223",
        "fullName": "Ivan  Ortega Parada",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010860",
        "document": "1099374371",
        "fullName": "Wilson Manuel Carreño Correa",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010888",
        "document": "1100888133",
        "fullName": "Luis Eduardo Florez Forero",
        "company": "Distraves",
        "regional": "Elpl",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010880",
        "document": "1098833892",
        "fullName": "Cairimar Mariam Rueda Uzcategui",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010797",
        "document": "1098356879",
        "fullName": "Elber Julian Avellaneda Beltran",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010862",
        "document": "1067864018",
        "fullName": "Asael David Almanza Aguilar",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/3/2023 1:20:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010894",
        "document": "1098759961",
        "fullName": "Andrea Carolina Gomez Arenas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asistente Gerencia",
        "uen": "Gerencia General",
        "process": "Gerencia General",
        "area": "Gerencia General",
        "fechaIngreso": "2/3/2023 4:43:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010907",
        "document": "1076903615",
        "fullName": "Valentina  Varela Calderon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "2/4/2023 4:54:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000493",
        "document": "1094573421",
        "fullName": "Diomar Ortiz Gomez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/6/2023 2:55:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01349",
        "document": "1095921898",
        "fullName": "Saul Humberto Alvarez Hernandez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Domiciliario",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/6/2023 3:25:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010908",
        "document": "1098634368",
        "fullName": "Diana Marcela Garcia Patiño",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "2/6/2023 6:44:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010909",
        "document": "1005460677",
        "fullName": "Lesly Valeria Ruiz Forero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/6/2023 6:44:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01076",
        "document": "1005334942",
        "fullName": "German Steven Silva Pedraza",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Tecnico De Mantenimiento",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/6/2023 7:15:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007721",
        "document": "1095839609",
        "fullName": "Baby Alexandra Gil Parra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Planeador Distribucion",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/6/2023 7:15:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010904",
        "document": "1000442847",
        "fullName": "Juan Esteban Muñoz Marin",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar De Almacen",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "2/6/2023 7:52:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010915",
        "document": "1094780754",
        "fullName": "Nelson Ivan Paez Paez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/7/2023 4:04:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010921",
        "document": "1006230103",
        "fullName": "Maria Isabel Silva Gonzalez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "2/7/2023 4:34:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010912",
        "document": "1006227879",
        "fullName": "Maria Alejandra Herrada Escobar",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "2/7/2023 4:34:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010918",
        "document": "1006050809",
        "fullName": "Yuliana Andrea Marin Escobar",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "2/7/2023 4:34:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010916",
        "document": "5024391",
        "fullName": "Antoni Arturo Egidio Lugo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/7/2023 6:03:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010927",
        "document": "1031800611",
        "fullName": "Angie Viviana Martinez Cardenas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/7/2023 7:06:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010924",
        "document": "37618237",
        "fullName": "Diocelina  Rincon Sierra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/7/2023 7:06:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010932",
        "document": "1101207518",
        "fullName": "Miguel Angel Acero Corzo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/7/2023 7:06:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010939",
        "document": "1192739523",
        "fullName": "Duvan Andres Rivera Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "2/8/2023 4:05:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010940",
        "document": "94481752",
        "fullName": "Jhon Deivy Fory Ramirez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder 3",
        "fechaIngreso": "2/8/2023 4:35:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010949",
        "document": "1010220858",
        "fullName": "Julieth Stefany Avila Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/8/2023 6:50:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010950",
        "document": "1115077788",
        "fullName": "Esteban  Ocampo Murillo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "2/8/2023 6:50:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010952",
        "document": "1107057872",
        "fullName": "Victor Alfonso Ramos Calle",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Extruder Acuicultura",
        "fechaIngreso": "2/8/2023 6:50:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010947",
        "document": "1122396618",
        "fullName": "Dayanis Paola Mejia Torres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/8/2023 6:50:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010954",
        "document": "1115069868",
        "fullName": "Paulino Andres Escobar Ocampo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/8/2023 6:50:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010946",
        "document": "1005372444",
        "fullName": "Laura Viviana Hernandez Rojas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/8/2023 6:50:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010959",
        "document": "1006867251",
        "fullName": "Anderson  Barragan Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/9/2023 3:49:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010965",
        "document": "1005307494",
        "fullName": "Carlos Humberto Picon Godoy",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/9/2023 3:49:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010964",
        "document": "1098708444",
        "fullName": "Jefferson Reynaldo Sanabria Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/9/2023 3:49:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010957",
        "document": "1116249120",
        "fullName": "Leder Herney Barbosa Varon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "2/9/2023 4:49:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010970",
        "document": "1115064450",
        "fullName": "Julian Andres Saavedra Gonzalez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "2/9/2023 6:51:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010969",
        "document": "1043960455",
        "fullName": "Alianis Sheyen Chacon Alonso",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/9/2023 6:51:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010968",
        "document": "1005083669",
        "fullName": "Julian Andres Camaron Quiñones",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/9/2023 6:51:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010994",
        "document": "1005539297",
        "fullName": "Yesica Fernanda Gamboa Velandia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "2/10/2023 3:50:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010977",
        "document": "1006456095",
        "fullName": "Jaiver Fabian Segovia Mogollon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/10/2023 3:50:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010988",
        "document": "1102387156",
        "fullName": "Jessica Paola Roa Jurado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/10/2023 3:50:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010993",
        "document": "1102391403",
        "fullName": "Cristofer Fernando Jimenez Garces",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/10/2023 3:50:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010987",
        "document": "1102386373",
        "fullName": "Walter Damian Vasquez Reina",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/10/2023 3:50:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010989",
        "document": "1005233975",
        "fullName": "Wilfran Damian Jaimes Pita",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/10/2023 3:50:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010984",
        "document": "1065582960",
        "fullName": "Camilo Jose Vidal Granados",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/10/2023 3:50:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010980",
        "document": "1005180300",
        "fullName": "Lina Marcela Alvarino Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/10/2023 3:50:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010979",
        "document": "1098626558",
        "fullName": "Edith  Guitierrez Moreno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/10/2023 3:50:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010998",
        "document": "1115862477",
        "fullName": "Jessica Melissa Alvarado Quijano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/10/2023 3:50:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01181",
        "document": "1005455536",
        "fullName": "Erika Janeth Alvarez Ortiz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 3:50:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010630",
        "document": "1116254586",
        "fullName": "Hernan Dario Ocampo Rendon",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "2/10/2023 4:54:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01015",
        "document": "1115091049",
        "fullName": "Oscar Eduardo Rendon Diaz",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 4:54:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011008",
        "document": "1024463662",
        "fullName": "Julian Camilo Medina",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "2/10/2023 6:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011010",
        "document": "80210007",
        "fullName": "Miguel Angel Maldonado Valencia",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "2/10/2023 6:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00814",
        "document": "1115085814",
        "fullName": "Juan Jose Rosas Rendon",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 7:27:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00993",
        "document": "1115094059",
        "fullName": "Jhon Alejandro Diaz Alzate",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 7:27:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01333",
        "document": "1115084055",
        "fullName": "Daniel Mauricio Bermudez Blandon",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 7:27:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00945",
        "document": "94482949",
        "fullName": "Luis Alberto Sanclemente Soto",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 7:55:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00942",
        "document": "1114730923",
        "fullName": "Samuel Andres Murillo Lopez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 7:55:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01337",
        "document": "1114064373",
        "fullName": "Juan David Sabogal Cordoba",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 7:55:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00794",
        "document": "1115089689",
        "fullName": "Alejandro Marin Acevedo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 7:55:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01123",
        "document": "1114062422",
        "fullName": "Jhony Fabian Quiroz Castellan",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 8:25:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01017",
        "document": "1115075902",
        "fullName": "Juan David Perea Bocanegra",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 8:25:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01122",
        "document": "1115091998",
        "fullName": "Victor Manuel Serna Perea",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 8:25:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01340",
        "document": "1115087215",
        "fullName": "Javier Antonio Correa Giraldo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 8:55:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00817",
        "document": "1116158610",
        "fullName": "Fabian Escobar Paredes",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Prouccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 8:55:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01133",
        "document": "1114454242",
        "fullName": "Andres Felipe Arevalo Lopez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 8:55:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00872",
        "document": "1115081907",
        "fullName": "Mauricio Fernando Rendon Lozano",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 8:55:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01146",
        "document": "1112298795",
        "fullName": "Robinson Jaramillo Lopez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/10/2023 8:55:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011015",
        "document": "1102381212",
        "fullName": "Sergio Armando Marin Ruiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/11/2023 3:57:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011013",
        "document": "1095956630",
        "fullName": "Edilberto  Arrieta Cueva",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "2/11/2023 3:57:08 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009214",
        "document": "1098768947",
        "fullName": "Jhonatan Desiderio Rivera Florez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Liquidador Cartera",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "2/13/2023 2:46:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010634",
        "document": "1005874447",
        "fullName": "Luis Angel Zamorano Saavedra",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "2/13/2023 2:46:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011020",
        "document": "1102380369",
        "fullName": "Jose Vicente Jerez Orduz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/13/2023 6:50:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011031",
        "document": "1095580790",
        "fullName": "Darly Tatiana Mejia Fernandez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/13/2023 6:50:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011046",
        "document": "13539722",
        "fullName": "Edgar Ivan Poveda Sarmiento",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/13/2023 6:50:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011029",
        "document": "1005543490",
        "fullName": "Leidy Tatiana Buitrago Abril",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/13/2023 6:50:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011041",
        "document": "1007148454",
        "fullName": "Ivan Felipe Riascos Montezuma",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/13/2023 6:50:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011026",
        "document": "1005152187",
        "fullName": "Duvan Ferney Castro Ortega",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/13/2023 6:50:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011063",
        "document": "1052411842",
        "fullName": "David Orlando Zorro Ayala",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/14/2023 3:49:56 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011054",
        "document": "1063616285",
        "fullName": "Francisco  Duarte Leon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "2/14/2023 3:49:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011073",
        "document": "1114839856",
        "fullName": "Dayana  Ojeda Velez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "2/14/2023 5:48:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011074",
        "document": "1113531058",
        "fullName": "Sandra Lorena Vivas Vargas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/14/2023 5:48:48 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011039",
        "document": "1102371112",
        "fullName": "Manuel Ricardo Plata Esparza",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Supervisor Granjas Reproductoras",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011060",
        "document": "1095943567",
        "fullName": "Luis Alejandro Carrillo Santos",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Contabilidad",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Contabilidad",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011005",
        "document": "1102379874",
        "fullName": "Angie Carolina Robles Beleño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010942",
        "document": "1004878260",
        "fullName": "Joel Esteban Galvis Tami",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Tuneles",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011049",
        "document": "91445169",
        "fullName": "Carlos Eduardo Saenz Luna",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011047",
        "document": "1007643757",
        "fullName": "Jose Armando Alvarado Jaimes",
        "company": "Distraves",
        "regional": "Sang",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011058",
        "document": "1091081912",
        "fullName": "Gil Antonio Sanchez Balmaceda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01463",
        "document": "1095795174",
        "fullName": "Gabriela Sierra Julio",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011042",
        "document": "1005309769",
        "fullName": "Edwing Armando Bautista Caballero",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011055",
        "document": "63493110",
        "fullName": "Luz Marina Nuñez Diaz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011033",
        "document": "1233346379",
        "fullName": "David  Solera Chaves",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011067",
        "document": "1066525842",
        "fullName": "Gianmiguel  Muñoz Corpos",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/14/2023 1:18:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011007",
        "document": "1095817560",
        "fullName": "Yesica Sterth Barbosa Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Veterinario",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "2/14/2023 1:48:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011065",
        "document": "1098358028",
        "fullName": "Sandra Juliana Picon Godoy",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/14/2023 1:48:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011025",
        "document": "1148458453",
        "fullName": "Marco Antonio Montañez Villamizar",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/14/2023 1:48:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006381",
        "document": "94480244",
        "fullName": "Jaime Alberto Torres",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Almacenista",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "2/14/2023 4:19:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011081",
        "document": "1151937479",
        "fullName": "Christian David Hoyos Cordoba",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Practicante",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "2/14/2023 7:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011078",
        "document": "1006103266",
        "fullName": "Edwin Fernando Marin Piedrahita",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/14/2023 7:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011076",
        "document": "1007447071",
        "fullName": "Yisela  Sarasty Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/14/2023 7:21:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010840",
        "document": "1098785548",
        "fullName": "Johan Fabian Garcia Leguizamon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "2/15/2023 2:20:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00987",
        "document": "1192779061",
        "fullName": "Juan David Sanchez Pareja",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/15/2023 4:50:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01345",
        "document": "1115080549",
        "fullName": "Luis Miguel Ospina Loaiza",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/15/2023 4:50:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01134",
        "document": "1006372097",
        "fullName": "Santiago Ballesteros Sanchez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/15/2023 7:22:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01336",
        "document": "94479350",
        "fullName": "Ronald Andres Arzayus Gil",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/15/2023 8:20:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00802",
        "document": "1114061893",
        "fullName": "Rodrigo Londoño Aguilar",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/15/2023 8:20:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01131",
        "document": "1193083944",
        "fullName": "Valentina Holguin Arce",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/15/2023 9:50:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010921",
        "document": "1006230103",
        "fullName": "Maria Alejandra Silva Gonzalez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "2/15/2023 9:50:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00797",
        "document": "1114459805",
        "fullName": "Carlos Daniel Reina Patiño",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/15/2023 9:50:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010905",
        "document": "1115071841",
        "fullName": "Julian Andres Londono Restrepo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Salud Ocupacional",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "2/16/2023 4:51:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011084",
        "document": "80732742",
        "fullName": "Olman Fernando Salguero Romero",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "2/16/2023 6:55:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011086",
        "document": "1017222881",
        "fullName": "Kelly Alejandra Ochoa Villegas",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Diseñador(a) Grafico(a)",
        "uen": "Negocio Mascotas",
        "process": "Mercadeo",
        "area": "Comunicaciones",
        "fechaIngreso": "2/16/2023 6:55:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005629",
        "document": "1102390451",
        "fullName": "Luz Adriana Dulcey Soto",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/17/2023 3:22:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011088",
        "document": "1113688225",
        "fullName": "Daniel Felipe Bejarano Garcia",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Coordinador (a) De Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "2/17/2023 6:55:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01409",
        "document": "1098625358",
        "fullName": "Santiago Luengas Martinez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta Incubadora",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/17/2023 9:23:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011097",
        "document": "1005237748",
        "fullName": "Yulieth Dayana Rondon Moreno",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/18/2023 3:55:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011092",
        "document": "1066082775",
        "fullName": "Yaritza  Espinosa Blanco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/18/2023 3:55:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011095",
        "document": "39178456",
        "fullName": "Andrea Milena Ulloa Barrientos",
        "company": "Distraves",
        "regional": "Itag",
        "jobTitle": "Supervisor Puntos De Venta",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "2/20/2023 12:55:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008142",
        "document": "1098696856",
        "fullName": "Jhon Anderson Niño Garcia",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Tesoreria",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Financiera",
        "area": "Tesoreria Y Riesgos",
        "fechaIngreso": "2/20/2023 1:25:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011104",
        "document": "1040736522",
        "fullName": "Zoraida Maria Gomez Zapata",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "2/20/2023 6:57:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011099",
        "document": "1005307427",
        "fullName": "Diego Armando Gomez Olave",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/20/2023 6:57:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011098",
        "document": "1098700728",
        "fullName": "Luisa Fernanda Quiñones Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/20/2023 6:57:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011101",
        "document": "1051738803",
        "fullName": "Jesus David Arrieta Benavides",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "2/20/2023 6:57:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011111",
        "document": "1092347199",
        "fullName": "Emmanuel Jose Barbosa Barbosa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/21/2023 3:49:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011109",
        "document": "1102388358",
        "fullName": "Sergio Eduardo Ayala Mantilla",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/21/2023 3:49:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011108",
        "document": "1102369402",
        "fullName": "David  Uribe Lizcano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/21/2023 3:49:46 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00855",
        "document": "1005538893",
        "fullName": "Darlinson Steben Triana Santos",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/21/2023 11:18:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005547",
        "document": "1102382854",
        "fullName": "Wuendy Juliana Diaz Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/21/2023 11:18:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011009",
        "document": "80658326",
        "fullName": "Jhon Jairo Diaz Gomez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Electromecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "2/21/2023 7:23:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011116",
        "document": "1096214496",
        "fullName": "Johan Sebastian Videz Paredes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/22/2023 3:54:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011122",
        "document": "1007917860",
        "fullName": "Paola Andrea Rodriguez Rosas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/22/2023 3:54:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011118",
        "document": "1095836722",
        "fullName": "Andrey Danilo Tarazona Caceres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/22/2023 3:55:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010785",
        "document": "1143230765",
        "fullName": "Richy Junior Jimenez Baños",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "2/22/2023 4:20:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011137",
        "document": "1007375118",
        "fullName": "Elver Julian Torres Castillo",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/22/2023 6:53:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011124",
        "document": "1005411562",
        "fullName": "Dayana Estefania Lopez Pinto",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/22/2023 6:53:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011133",
        "document": "1005305521",
        "fullName": "Yury Estefany Jerez Tami",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/22/2023 6:53:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011141",
        "document": "1005543099",
        "fullName": "Angie Paola Hernandez Villamizar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/22/2023 6:53:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011140",
        "document": "1005334932",
        "fullName": "Sirley Tatiana Beltran Cordero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/22/2023 6:53:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011130",
        "document": "1104132456",
        "fullName": "Lus Dary Montero Crespo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/22/2023 6:53:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011123",
        "document": "1007888226",
        "fullName": "Brayan Andres Gonzalez Medina",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/22/2023 7:21:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008804",
        "document": "1099373000",
        "fullName": "Miguel Alberto Florez Jacome",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "2/22/2023 9:20:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011144",
        "document": "1102349181",
        "fullName": "Karen Lizeth Lancheros Ramirez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/23/2023 3:52:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011147",
        "document": "1114876419",
        "fullName": "Lina Vanessa Vidal Acevedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/23/2023 5:51:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008222",
        "document": "1073426131",
        "fullName": "Juana Valentina Aponte Cortes",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Recepcionista",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "2/23/2023 3:20:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011107",
        "document": "13569531",
        "fullName": "Ricardo Andres Almario Mecon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "2/23/2023 9:42:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011145",
        "document": "1012431284",
        "fullName": "Yisel Carolina Fontalvo Vasquez",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "2/23/2023 9:42:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011138",
        "document": "1007375954",
        "fullName": "Luis Alexander Montoya Girado",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/23/2023 9:42:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00823",
        "document": "1094165261",
        "fullName": "Giovanny Alexander Cordoba Hurtado",
        "company": "Distraves-gente Util",
        "regional": "pied",
        "jobTitle": "Operario Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "2/24/2023 9:44:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011183",
        "document": "1020777823",
        "fullName": "Brayan Olbel Mantilla Suarez",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011168",
        "document": "1048992121",
        "fullName": "Yefer Youssef Cuesta Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011153",
        "document": "1005107818",
        "fullName": "Oswaldo  Correa Cristancho",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011160",
        "document": "1102374607",
        "fullName": "Elkin Yesid Jurado Fonseca",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011164",
        "document": "1098286227",
        "fullName": "Javier Andres Contreras Peñaloza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011180",
        "document": "1102350028",
        "fullName": "Stifany Dayanna Sandoval Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011159",
        "document": "91524321",
        "fullName": "Samuel Dario Florez Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011163",
        "document": "1102365922",
        "fullName": "Luis Armando Conde",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011179",
        "document": "1005623379",
        "fullName": "Riquelmer Ivan Toro Serna",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011178",
        "document": "37579224",
        "fullName": "Ana Teresa Sachica Galvan",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011157",
        "document": "1094346702",
        "fullName": "Erika  Aparicio Rincon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011162",
        "document": "1102365938",
        "fullName": "Cesar Augusto Jimenez Calderon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011176",
        "document": "1002235251",
        "fullName": "Luis Angel Serna Barragan",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011155",
        "document": "1102382490",
        "fullName": "Elizabeth  Laguado Calderon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011161",
        "document": "1102379896",
        "fullName": "Andres Felipe Lancheros Dominguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011170",
        "document": "1005083622",
        "fullName": "Jefferson  Vega Esteban",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "2/25/2023 3:46:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011165",
        "document": "1193486886",
        "fullName": "Camilo Andres Higuita Cortes",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "2/27/2023 11:17:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011182",
        "document": "1005309295",
        "fullName": "Marlene  Arenales Moreno",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "2/27/2023 1:47:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011171",
        "document": "1005309298",
        "fullName": "Fabio Andres Arenales Moreno",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "2/27/2023 1:47:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011192",
        "document": "1047448898",
        "fullName": "Jose Alberto Angulo",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Coordinador De Mantenimiento",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "2/27/2023 6:50:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011068",
        "document": "94478869",
        "fullName": "Jose Arley Montoya",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "2/28/2023 9:39:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011200",
        "document": "1005187029",
        "fullName": "Brayan De Jesus Galvis Paredes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/1/2023 3:41:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011193",
        "document": "1005322087",
        "fullName": "Fabian Alexis Serrano Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/1/2023 3:41:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011194",
        "document": "1102389032",
        "fullName": "Dadian Camilo Sanmartin Taborda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/1/2023 3:41:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011199",
        "document": "1098640928",
        "fullName": "Ludy Maritza Tirado Blanco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/1/2023 3:41:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011197",
        "document": "1095826230",
        "fullName": "Ivon Katherine Quintero Castro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/1/2023 3:41:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00813",
        "document": "1114063977",
        "fullName": "Brandon Stiven Esparza Figuer",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Roduccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/1/2023 4:51:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011204",
        "document": "1017193384",
        "fullName": "Tatiana Uribe Gonzalez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Servicios Y Procesos De Ti",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Servicios",
        "fechaIngreso": "3/1/2023 6:50:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011209",
        "document": "1062327987",
        "fullName": "Karen Melissa Mera Pechene",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Profesionales En Formacion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Procesos",
        "area": "Procesos",
        "fechaIngreso": "3/1/2023 6:50:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011211",
        "document": "1002839796",
        "fullName": "Leiver  Obregon Hinestroza",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/2/2023 5:48:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005175",
        "document": "1098734240",
        "fullName": "Luis Alberto Contreras Sandoval",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/2/2023 3:48:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009314",
        "document": "1099376792",
        "fullName": "Carlos Alberto Archila De Leon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/2/2023 4:18:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011203",
        "document": "1140895307",
        "fullName": "Charles Hollman Mora Molina",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Analista Auditoria Interna",
        "uen": "Gerencia General",
        "process": "Auditoria",
        "area": "Auditoria",
        "fechaIngreso": "3/2/2023 4:18:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011202",
        "document": "1102549294",
        "fullName": "Omar Fernando Duarte Medina",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "3/2/2023 4:18:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011205",
        "document": "63551341",
        "fullName": "Martha Yanneth Leon Diaz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "3/2/2023 4:18:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011201",
        "document": "1129519791",
        "fullName": "Karina Del Rosario Polo Moreno",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "3/2/2023 4:18:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011215",
        "document": "1214717355",
        "fullName": "Juan Camilo Bustamante Henao",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "3/2/2023 4:51:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01314",
        "document": "1005331733",
        "fullName": "Maykoll Stiven Landazabal Lizarazo",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/2/2023 8:52:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10006155",
        "document": "1095953683",
        "fullName": "Christian Jose Ochoa Ardila",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "3/2/2023 8:52:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011150",
        "document": "1002322030",
        "fullName": "Yormandy Rodriguez Barrera",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/2/2023 9:52:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01216",
        "document": "1102382993",
        "fullName": "Luz Angela Gomez Arias",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/3/2023 12:44:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005886",
        "document": "1005411324",
        "fullName": "Hector Leonardo Rincon Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/3/2023 2:44:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011219",
        "document": "1115068079",
        "fullName": "Andres Gerardo Sanabria Giron",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "3/6/2023 2:08:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01206",
        "document": "1064116506",
        "fullName": "Sandra Yuranis De Leon Jimenez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/6/2023 4:08:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010903",
        "document": "1047995685",
        "fullName": "Francisco Javier Gonzalez Salazar",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "3/6/2023 4:08:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010900",
        "document": "71279189",
        "fullName": "Giovanny Antonio Morales Tobon",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "3/6/2023 4:08:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011224",
        "document": "16893412",
        "fullName": "John Jairo Noscue Restrepo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "3/6/2023 7:11:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011223",
        "document": "1006371250",
        "fullName": "Valeria  Valderrama Sepulveda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "3/6/2023 7:11:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011220",
        "document": "1037667606",
        "fullName": "Valentina  Durango Villegas",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "3/6/2023 7:11:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011222",
        "document": "1115093065",
        "fullName": "Valentina  Escobar Briceño",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "3/6/2023 7:11:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011221",
        "document": "14897460",
        "fullName": "Gustavo  Jimenez Vasquez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/6/2023 7:11:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000756",
        "document": "91542399",
        "fullName": "Andres Felipe Mendoza Villareal",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Pollo De Engorde",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/6/2023 10:08:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011225",
        "document": "1115064584",
        "fullName": "Angie Natalia Rivera Rodriguez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "3/7/2023 4:40:21 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010819",
        "document": "1065575779",
        "fullName": "Sandra Yuranis De Leon Osma",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/7/2023 2:09:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011228",
        "document": "1005384437",
        "fullName": "Erika Dayana Prada Amaya",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Liquidador Cartera",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "3/7/2023 7:14:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009462",
        "document": "1067032355",
        "fullName": "Milexy Alejandra Guerra Castro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/7/2023 9:11:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01195",
        "document": "1102373577",
        "fullName": "Andres Eduardo Cardenas Benitez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/8/2023 2:43:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01429",
        "document": "1232891143",
        "fullName": "Jhon Alexander Osma Quiroz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta Aba",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/8/2023 3:13:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01197",
        "document": "1005037170",
        "fullName": "Edwin Daniel Carrillo Cardenas",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/8/2023 3:13:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01391",
        "document": "1096062005",
        "fullName": "Ingrid Lorena Garnica Melendez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario De Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/8/2023 3:13:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01231",
        "document": "1005541131",
        "fullName": "Yuri Sofia Jimenez Rodriguez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/8/2023 3:13:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01513",
        "document": "1115071139",
        "fullName": "Juan David Castro Barbetty",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/8/2023 4:39:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011240",
        "document": "1005543216",
        "fullName": "Brayan Styven Zuluaga Rivera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/8/2023 7:13:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011243",
        "document": "13724852",
        "fullName": "Jaime Javier Romero Leon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/8/2023 7:13:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011244",
        "document": "1005331328",
        "fullName": "Maira Fernanda Jaimes Noriega",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/8/2023 7:13:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011234",
        "document": "1098696642",
        "fullName": "Yeison Oleimar Hernandez Lizarazo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/8/2023 7:13:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011226",
        "document": "1112148108",
        "fullName": "Manuela Sanchez Cardona",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "3/8/2023 9:43:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01224",
        "document": "63532320",
        "fullName": "Gloria Patricia Gonzalez Osma",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/8/2023 10:45:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011264",
        "document": "1193140195",
        "fullName": "Andres Felipe Rueda Corzo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/9/2023 3:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011261",
        "document": "1082835498",
        "fullName": "Michel  Lopez Polo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/9/2023 3:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011251",
        "document": "1044932566",
        "fullName": "Luis David Lagares Montes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/9/2023 3:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011259",
        "document": "1102390860",
        "fullName": "Maria Alejandra Hoyos Londoño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/9/2023 3:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011249",
        "document": "1102384103",
        "fullName": "Jean Carlos Grimaldo Mena",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/9/2023 3:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011247",
        "document": "1005553304",
        "fullName": "Erick Fabian Alvarez Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/9/2023 3:47:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011246",
        "document": "1020498696",
        "fullName": "Aleanny Mariela Sanchez Arrieta",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/9/2023 3:47:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011260",
        "document": "2932758",
        "fullName": "Santos Jose Perez Guedez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/9/2023 5:46:36 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011274",
        "document": "1102382048",
        "fullName": "Deisy Johana Gomez Cardenas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/9/2023 6:50:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011269",
        "document": "1102383895",
        "fullName": "Camila Andrea Salas Salas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/9/2023 6:50:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011270",
        "document": "1102387961",
        "fullName": "Maria Fernanda Jimenez Orduz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/9/2023 6:50:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011271",
        "document": "1095836529",
        "fullName": "Maria Doralba Duran Castillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/9/2023 6:50:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011286",
        "document": "1095580321",
        "fullName": "Sirley Tatiana Blanco Orozco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/10/2023 4:04:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011290",
        "document": "1102391447",
        "fullName": "Marly Ariana Romero Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/10/2023 4:04:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011280",
        "document": "1102369588",
        "fullName": "Robinson Francisco Pinilla Robles",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/10/2023 4:04:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011279",
        "document": "1127928145",
        "fullName": "Keylis Yoandra Mora Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/10/2023 4:04:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011289",
        "document": "1099375820",
        "fullName": "Anderson Damian Blanco Alvarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/10/2023 4:04:10 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011238",
        "document": "1090500840",
        "fullName": "Yenifer Andrea Ramirez Garcia",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/10/2023 4:32:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011306",
        "document": "1113790697",
        "fullName": "Jose Gabriel Ramirez Aguirre",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "3/10/2023 7:05:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011300",
        "document": "1062904080",
        "fullName": "Leonardo Rafael Rapalino Sajonero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "3/10/2023 7:05:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011296",
        "document": "1102383944",
        "fullName": "Maria Fernanda Barrera Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/10/2023 7:05:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011291",
        "document": "1116810239",
        "fullName": "Karen Yisely Uribe Duarte",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/10/2023 7:05:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011312",
        "document": "1102376468",
        "fullName": "Jesus Alberto Chaparro Pinzon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/10/2023 7:05:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011317",
        "document": "1102801306",
        "fullName": "Jose Esteban Viloria Carrascal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/10/2023 7:05:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011310",
        "document": "1005651115",
        "fullName": "Adriana Del Rosario Navarro Palencia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/10/2023 7:05:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011315",
        "document": "1007640333",
        "fullName": "Yeinis Patricia Ramos Ruz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/10/2023 7:05:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011319",
        "document": "1006859885",
        "fullName": "Daniel David Jimenez Peñaloza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta De Harinas",
        "area": "Pta Harinas",
        "fechaIngreso": "3/11/2023 3:58:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011326",
        "document": "1005541501",
        "fullName": "Yerfinson  Jaimes Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/11/2023 3:58:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011331",
        "document": "1102348925",
        "fullName": "Wendy Natalia Caceres Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/11/2023 3:58:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011330",
        "document": "1102385601",
        "fullName": "Erika Patricia Silva Pinzon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/11/2023 3:58:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011324",
        "document": "1007735127",
        "fullName": "Paula Valentina Joya Rueda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/11/2023 3:58:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011334",
        "document": "7177762",
        "fullName": "Helber Alexander Cardenas Pinzon",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "3/11/2023 3:58:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011333",
        "document": "1099213629",
        "fullName": "Jeimmy  Aguilar Mosquera",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "3/11/2023 3:58:09 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011338",
        "document": "1101385667",
        "fullName": "Javier Alfonso Quintero Hernandez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/13/2023 7:03:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011337",
        "document": "1014978053",
        "fullName": "Kevin Alexis Mejia Aguilar",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/13/2023 7:03:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011339",
        "document": "1069852831",
        "fullName": "Edibaldo  Cardenas Beltran",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/13/2023 7:03:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011340",
        "document": "1016026618",
        "fullName": "Marco Antonio Perilla Rivera",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "3/13/2023 7:03:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011350",
        "document": "1090488815",
        "fullName": "Stefania  Aparicio Rincon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/14/2023 4:01:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011348",
        "document": "1030706212",
        "fullName": "Juan David Cuayal Niño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/14/2023 4:01:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011346",
        "document": "1005339403",
        "fullName": "Laura Marcela Ruiz Moncada",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/14/2023 4:01:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011353",
        "document": "1007791006",
        "fullName": "Jhon Freddy Torres Cardenas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/14/2023 4:01:15 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011354",
        "document": "6885654",
        "fullName": "Ronald Jose Gonzalez Fernandez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/14/2023 7:03:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011358",
        "document": "1050956795",
        "fullName": "Marlon Alfredo Elles Camacho",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/14/2023 7:03:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011364",
        "document": "78109732",
        "fullName": "Manuel Enrique Ortega Regino",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/14/2023 7:03:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011360",
        "document": "1114887627",
        "fullName": "Angie Yhoanna Mina Quiñones",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/14/2023 7:03:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011355",
        "document": "1007500930",
        "fullName": "Diego Andres Quintero Diaz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/14/2023 7:03:41 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011365",
        "document": "1143396205",
        "fullName": "Keyder  Simancas Alvarez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/14/2023 7:03:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011370",
        "document": "1048608411",
        "fullName": "Yosi Esteban Bello De Horta",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/14/2023 7:03:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011371",
        "document": "1007255317",
        "fullName": "Daniel David Franco Gutierrez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Aprendiz Sena",
        "uen": "Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "3/14/2023 7:03:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011366",
        "document": "1114453432",
        "fullName": "Alejandro  Moreno Salcedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "3/15/2023 7:05:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011372",
        "document": "1115086384",
        "fullName": "Marisol Rojas Caceres",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "3/16/2023 3:40:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011377",
        "document": "1007234335",
        "fullName": "Valentina  Echeverri Zapata",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "3/17/2023 4:42:55 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X00940",
        "document": "1113634527",
        "fullName": "Christian Javier Echeverri Serrano",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/17/2023 4:33:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01344",
        "document": "1115080957",
        "fullName": "Luis Freddy Arana Mejia",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/17/2023 4:33:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011375",
        "document": "1115082123",
        "fullName": "Ingrid Yohanna Zapata Bocanegra",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "3/17/2023 4:37:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10000078",
        "document": "8356218",
        "fullName": "Carlos Arturo Campillo Restrepo",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/17/2023 7:38:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011294",
        "document": "1102373028",
        "fullName": "Jesus Armando Rojas Perez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/17/2023 7:38:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011297",
        "document": "1098638820",
        "fullName": "Angelica Calderon Navarro",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Ejecutiva Comercial Retail",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "3/17/2023 7:38:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "110853400",
        "document": "71294807",
        "fullName": "Juan Carlos Cano Torres",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Aplicaciones",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Aplicaciones",
        "fechaIngreso": "3/17/2023 8:07:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "110853408",
        "document": "71294803",
        "fullName": "Juan Conrado Cano Tabarez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Aplicaciones",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Aplicaciones",
        "fechaIngreso": "3/17/2023 8:07:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011373",
        "document": "1098713489",
        "fullName": "Yirley Gisela Martinez Hernandez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Juridico",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Juridico",
        "fechaIngreso": "3/17/2023 9:07:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011256",
        "document": "1143995018",
        "fullName": "Johan Sebastian Franco Morales",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Tecnico De Refrigeracion",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "3/21/2023 1:12:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011382",
        "document": "1098781863",
        "fullName": "Juan Carlos Miranda Meneses",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Sst E Higiene",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Salud Y Seguridad En El Trabajo",
        "fechaIngreso": "3/22/2023 5:07:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011384",
        "document": "1095940399",
        "fullName": "Jaime Andres Obregon Ortega",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/22/2023 7:11:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011387",
        "document": "1005540289",
        "fullName": "Emerson Arley Carrillo Pico",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/23/2023 3:39:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011386",
        "document": "1098658987",
        "fullName": "Francy Milena Peña Caceres",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/23/2023 3:39:34 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011212",
        "document": "1143859534",
        "fullName": "Lina Marcela Pizo Leon",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "3/23/2023 3:39:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011389",
        "document": "1056768418",
        "fullName": "Carlos Daniel Peinado Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/23/2023 7:13:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011369",
        "document": "11033392",
        "fullName": "Carlos Alberto Suarez Almario",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/24/2023 4:41:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010632",
        "document": "1115091049",
        "fullName": "Oscar Eduardo Rendon Diaz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/24/2023 4:41:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011342",
        "document": "1032388465",
        "fullName": "Deiby Anderson Quintero Molina",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Electromecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "3/24/2023 4:41:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011120",
        "document": "1095941030",
        "fullName": "Cesar Augusto Martinez Garcia",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/24/2023 4:41:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011378",
        "document": "1115078231",
        "fullName": "Christian Bahuer Correa Manzano",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "3/24/2023 4:41:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010563",
        "document": "1115080957",
        "fullName": "Luis Fredy Arana Mejia",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Movimiento De Materias Primas",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "3/24/2023 4:41:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010967",
        "document": "1114063977",
        "fullName": "Brandon Stiven Esparza Figueroa",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/24/2023 4:41:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010778",
        "document": "1115081907",
        "fullName": "Mauricio Fernando Rendon Lozano",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "3/24/2023 4:41:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010754",
        "document": "1115094059",
        "fullName": "John Alejandro Diaz Alzate",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "3/24/2023 4:41:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010755",
        "document": "1114062422",
        "fullName": "Jhony Fabian Quiroz Castellanos",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "3/24/2023 4:41:47 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011154",
        "document": "94043261",
        "fullName": "Juan Manuel Cortes Garcia",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "3/24/2023 1:58:37 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011412",
        "document": "1062875423",
        "fullName": "Luis Antonio Pacheco Lozano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/25/2023 4:04:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011401",
        "document": "1005373063",
        "fullName": "Daniel Alfonso Nariño Velasco",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "3/25/2023 4:04:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011411",
        "document": "1098170094",
        "fullName": "Jeferson  Galeano Ruiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/25/2023 4:04:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011409",
        "document": "1005543042",
        "fullName": "Carlos Duvan Bueno Alvarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/25/2023 4:04:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011406",
        "document": "1015403010",
        "fullName": "Mayra Rocio Florez Caro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/25/2023 4:04:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011405",
        "document": "1092361133",
        "fullName": "Eiraima Fraterine Sanchez Medina",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/25/2023 4:04:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011419",
        "document": "1100894485",
        "fullName": "Yojan Alexis Leguizamon Niño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/25/2023 7:07:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011417",
        "document": "1102348299",
        "fullName": "Doralba  Santana Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/25/2023 7:07:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011415",
        "document": "1095834011",
        "fullName": "Maria Camila Vargas Ojeda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Auxiliar Invima",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "3/25/2023 7:07:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011422",
        "document": "1000517811",
        "fullName": "Paola Tatiana Olarte Nova",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "3/27/2023 4:37:49 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011436",
        "document": "1005327713",
        "fullName": "Juan Sebastian Diaz Ruiz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/27/2023 7:10:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011376",
        "document": "13742309",
        "fullName": "Wilfernando  Angarita Suarez",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "3/27/2023 7:10:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011424",
        "document": "1113531507",
        "fullName": "Kevin Alexander Cepeda Rivera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/27/2023 7:10:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10002962",
        "document": "94472215",
        "fullName": "Milciades Efrain Sevillano Cardenas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "3/27/2023 7:10:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011383",
        "document": "52264205",
        "fullName": "Paula Andrea Castañeda Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Líder Aseguramiento Calidad",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "3/27/2023 7:39:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011427",
        "document": "1144059910",
        "fullName": "Hernando Enrique Valencia Paja",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "3/27/2023 10:37:44 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011458",
        "document": "1005155634",
        "fullName": "Paula Andrea Prada Santander",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/28/2023 3:39:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011447",
        "document": "1005329602",
        "fullName": "Angie Natalia Esteban Fajardo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/28/2023 3:39:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011457",
        "document": "1098752035",
        "fullName": "Tatiana Lizeth Moreno Pulido",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/28/2023 3:39:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011456",
        "document": "1004811331",
        "fullName": "Daniel Andres Baez Becerra",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/28/2023 3:39:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011439",
        "document": "1102386952",
        "fullName": "Johan Sebastian Barragan Mogollon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/28/2023 3:39:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011453",
        "document": "1096185448",
        "fullName": "Yenifer Paola Forero Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/28/2023 3:39:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011460",
        "document": "1097780637",
        "fullName": "Angiee Natalya Melon Barajas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/28/2023 3:39:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011452",
        "document": "1065843027",
        "fullName": "Jann Carlo Conde Toro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/28/2023 3:39:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011450",
        "document": "1098710067",
        "fullName": "Jose Ruben Blanco Arias",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/28/2023 3:39:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011449",
        "document": "1090532429",
        "fullName": "Emerson Daniel Jaimes Camargo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/28/2023 3:39:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01525",
        "document": "1007440964",
        "fullName": "Jenny Alejandra Gonzalez Henao",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Talento Humano",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "3/28/2023 2:28:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011468",
        "document": "1026254406",
        "fullName": "Miguel Alfonso Millan Severiche",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/28/2023 7:04:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011475",
        "document": "1072593245",
        "fullName": "Jhoan Camilo Marroquin Avendaño",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "3/28/2023 7:04:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011470",
        "document": "1005271654",
        "fullName": "Esteban Alonso Gomez Hernandez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "3/28/2023 7:04:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011463",
        "document": "91347960",
        "fullName": "Sergio Augusto Pimiento",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/28/2023 7:04:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011462",
        "document": "1005297535",
        "fullName": "Daniela  Zambrano Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/28/2023 7:04:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011477",
        "document": "1005163558",
        "fullName": "Pedro Pablo Rodriguez Gonzalez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/29/2023 4:02:03 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011484",
        "document": "1095925859",
        "fullName": "Christiam Gerardo Caballero Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Auxiliar Invima",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "3/29/2023 4:02:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011485",
        "document": "1005236929",
        "fullName": "Jaider Manuel Ayala Fajardo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "3/29/2023 4:02:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011487",
        "document": "1007474429",
        "fullName": "Marcela  Iturriago Paba",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Aprendiz Sena",
        "uen": "Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "3/29/2023 7:04:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004839",
        "document": "1099364625",
        "fullName": "John Fredy Rivero Guerrero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "3/30/2023 7:06:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009668",
        "document": "1102373406",
        "fullName": "Raul Caballero Niño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "3/30/2023 7:06:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011441",
        "document": "1098609583",
        "fullName": "Jary Alexandra Gonzalez Quiñonez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "3/30/2023 7:06:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011206",
        "document": "1005272816",
        "fullName": "Fabian David Perez Cala",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Bodega",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "3/30/2023 7:06:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011167",
        "document": "1007450972",
        "fullName": "Juliana Andrea Garcia Blanco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/31/2023 1:03:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011167",
        "document": "1007450972",
        "fullName": "Sebastian Sanabria Calderon",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/31/2023 1:03:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011504",
        "document": "1096255378",
        "fullName": "Guillermo Andres Osma Betancourt",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/31/2023 7:06:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011502",
        "document": "1007669350",
        "fullName": "Deyby Ricardo Florez Espinosa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/31/2023 7:06:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011500",
        "document": "1098792671",
        "fullName": "Jennifer Paola Vargas Vega",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "3/31/2023 7:06:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011499",
        "document": "1096062125",
        "fullName": "Santiago  Almeida Duarte",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "3/31/2023 7:06:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011498",
        "document": "1098829016",
        "fullName": "Estefany Carolina Baron Villamizar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "3/31/2023 7:06:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011495",
        "document": "1033706447",
        "fullName": "Sandra Marcela Arroyave Ruiz",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "3/31/2023 7:06:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011497",
        "document": "1005312852",
        "fullName": "Ines Rocio Leon Hernandez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "3/31/2023 7:06:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011490",
        "document": "1002995340",
        "fullName": "Juan Sebastian Meriño Cera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "3/31/2023 7:06:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011489",
        "document": "1192809691",
        "fullName": "Hermes Estiven Morales Amaya",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "3/31/2023 7:06:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011511",
        "document": "1095829482",
        "fullName": "Paula Lizeth Guarin Villamizar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "4/1/2023 4:05:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011505",
        "document": "1098809718",
        "fullName": "Ever Manuel Perez Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/1/2023 4:05:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011509",
        "document": "1098700137",
        "fullName": "Manuel Norberto Florez Delgado",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/1/2023 4:05:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011510",
        "document": "1014281334",
        "fullName": "Lizeth Alejandra Jurado Hernandez",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Auxiliar Administrativo",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "4/1/2023 4:05:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011508",
        "document": "1193046371",
        "fullName": "Jhon Michael Sneyder Celis Vargas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "4/1/2023 4:05:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011507",
        "document": "28381651",
        "fullName": "Veronica  Pinzon Garnica",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/1/2023 4:05:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011506",
        "document": "1100897275",
        "fullName": "Yeimi Tatiana Leon Olago",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "4/1/2023 4:05:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011513",
        "document": "1115066542",
        "fullName": "Freddy  Olivares Rojas",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "4/1/2023 7:07:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011478",
        "document": "21791633",
        "fullName": "Angela Maria Alcaraz Lopez",
        "company": "Distraves",
        "regional": "Itag",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Direccion Canal",
        "area": "Direccion Canal",
        "fechaIngreso": "4/3/2023 3:25:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011444",
        "document": "1098780463",
        "fullName": "Duvan Leonardo Perez Arciniegas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Planeador Distribucion",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "4/3/2023 3:25:04 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001582",
        "document": "1098752811",
        "fullName": "Said Julian Fernandez Gomez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Abogado(a)",
        "uen": "Corporativo Secretaría Gral Y Jurídica",
        "process": "Secretaría General Y Jurídica",
        "area": "Secretaría General Y Jurídica",
        "fechaIngreso": "4/3/2023 8:13:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01501",
        "document": "71770851",
        "fullName": "Octavio Ospina Loaiza",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Producción",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/3/2023 9:13:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011512",
        "document": "1114064341",
        "fullName": "Nestor Mauricio Betancour Bedoya",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Analista De Microbiologia",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "4/3/2023 9:47:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011285",
        "document": "8157568",
        "fullName": "William Albeiro Patiño Taborda",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/3/2023 10:17:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011218",
        "document": "1109386434",
        "fullName": "John Fernando Cespedes Peñuela",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/3/2023 10:17:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011217",
        "document": "1042150385",
        "fullName": "Jose Luis Luna Sanchez",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/3/2023 10:17:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011394",
        "document": "1038360754",
        "fullName": "Dairo Alberto Correa Perez",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/3/2023 10:17:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011216",
        "document": "1038361583",
        "fullName": "Juan David Pelaez Cifuentes",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/3/2023 10:17:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011519",
        "document": "1192790270",
        "fullName": "Sebastian  Cuenu Llanos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/4/2023 5:48:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011515",
        "document": "1143940661",
        "fullName": "Hernan David Jaramillo Buritica",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/4/2023 5:48:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011514",
        "document": "1045525922",
        "fullName": "Samir Andres Montillo Perdomo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/4/2023 5:48:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011472",
        "document": "1152437257",
        "fullName": "Alejandra Duque Gonzalez",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "4/4/2023 1:48:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011529",
        "document": "1152706224",
        "fullName": "Wilder  Martinez De La Ossa",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "4/4/2023 7:01:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011526",
        "document": "14297225",
        "fullName": "John Alejandro Lozano Toscano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "4/4/2023 7:27:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011524",
        "document": "1192801695",
        "fullName": "Samuel Enrique Aguirre Rengifo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "4/4/2023 7:27:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011523",
        "document": "1192724963",
        "fullName": "Andres Felipe Villa Hurtado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "4/4/2023 7:27:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011522",
        "document": "1193223403",
        "fullName": "Santiago  Valencia Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/4/2023 7:27:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011535",
        "document": "5203558",
        "fullName": "Jose Manuel Mellado Trujillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/5/2023 6:58:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011531",
        "document": "1107060772",
        "fullName": "Kevin Alexander Ferrin",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/5/2023 6:58:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011525",
        "document": "1114886542",
        "fullName": "Maira Julieth Borja Caicedo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Lider Ambiental",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "4/5/2023 10:26:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011538",
        "document": "4974595",
        "fullName": "Kevin Alejandro Gonzalez Guedez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "4/6/2023 5:56:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005612",
        "document": "1056802708",
        "fullName": "Diego Alexander Rodriguez Ramirez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Electromecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "4/10/2023 9:22:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10003559",
        "document": "1010162703",
        "fullName": "Benjamin Enrique Rincon Olave",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "4/10/2023 10:22:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011553",
        "document": "1098793801",
        "fullName": "Karen Patricia Nuñez Vargas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/11/2023 3:55:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011551",
        "document": "1095932692",
        "fullName": "Fabian Andres Calderon Ordoñez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/11/2023 3:55:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011546",
        "document": "1102394129",
        "fullName": "Greys Paola Aislant Vanegas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/11/2023 3:55:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011550",
        "document": "1095816362",
        "fullName": "Juan Carlos Valbuena Silva",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "4/11/2023 3:55:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011545",
        "document": "1001049436",
        "fullName": "Michael Stiven Quiroga Lopez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 3:55:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011548",
        "document": "1005540875",
        "fullName": "Juan Diego Espinoza Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/11/2023 3:55:38 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011082",
        "document": "1113516105",
        "fullName": "Mauricio  Ortiz Jaramillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010479",
        "document": "94470353",
        "fullName": "Ricardo Cesar Arce Calderon",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010699",
        "document": "1072446913",
        "fullName": "Oscar Mauricio Avendaño Torres",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010914",
        "document": "1112968683",
        "fullName": "Sebastian  Herrera Marulanda",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010475",
        "document": "1080935723",
        "fullName": "Nicolas Felipe Parra Cano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011379",
        "document": "1061783851",
        "fullName": "Jhonathan Alexis Sanchez Mosquera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Administrador De Granjas",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010268",
        "document": "1060240456",
        "fullName": "Eyder Mauricio Sanchez Maca",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011536",
        "document": "1002960688",
        "fullName": "Nicolas  Reyes Ospina",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor De Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011214",
        "document": "76351735",
        "fullName": "Luis Norbey Velasco Mañunga",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011537",
        "document": "1144032034",
        "fullName": "Pablo Edilmer Guazaquillo Ordoñez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Pollinaza",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010161",
        "document": "1114896457",
        "fullName": "Juan Pablo Hurtado Preciado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta De Hielo",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010683",
        "document": "1112231514",
        "fullName": "Pablo Emilio Gomez Camacho",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010891",
        "document": "1113531614",
        "fullName": "Jose Antonio Ortiz Nuñez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01506",
        "document": "1056772265",
        "fullName": "Yefferson Alexander Gutierrez Ospina",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Brasero",
        "uen": "Gerencia Industrial",
        "process": "Operativo",
        "area": "Operativo",
        "fechaIngreso": "4/11/2023 7:53:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011399",
        "document": "1102359975",
        "fullName": "Andres  Tamara Osma",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010666",
        "document": "1002608822",
        "fullName": "Duver Andres Burgos Gutierrez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011408",
        "document": "1084579000",
        "fullName": "Miguel Andres Villamizar Quintero",
        "company": "Distraves",
        "regional": "Elpl",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010266",
        "document": "1114457666",
        "fullName": "Luis Arturo Aguirre Matacea",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010368",
        "document": "16847485",
        "fullName": "Fabio Nelson Filigrana Salazar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010684",
        "document": "1087794590",
        "fullName": "Luis Carlos Ordoñez Palomino",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011343",
        "document": "1099376518",
        "fullName": "Ivan Dario Saavedra Parra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011173",
        "document": "22584231",
        "fullName": "Magda Alejandra Rueda Rueda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011169",
        "document": "1005155880",
        "fullName": "Juan Sebastian Jaimes Niño",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010890",
        "document": "1113539113",
        "fullName": "Sebastian  Sarria Rivera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011316",
        "document": "1005345301",
        "fullName": "Yeison Alfonso Regueros Regueros",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010223",
        "document": "1061428867",
        "fullName": "Jhon Alexander Conde Romero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005959",
        "document": "1102381210",
        "fullName": "Karen Jasneury Mendez Buitrago",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011305",
        "document": "91535954",
        "fullName": "Luis  Sarmiento Bernal",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011380",
        "document": "1112483087",
        "fullName": "Yonatan  Guaspud Becca",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Atrape",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010782",
        "document": "1113528770",
        "fullName": "Jhonier Alexis Moreno Catuche",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario Planta Agua Potable",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011323",
        "document": "1055836940",
        "fullName": "Juan Pablo Pineda Trujillo",
        "company": "Distraves",
        "regional": "Itag",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010978",
        "document": "1192800525",
        "fullName": "Luis Alberto Ramirez Mendoza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011321",
        "document": "1098617084",
        "fullName": "Oscar Orlando Vera Prada",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010685",
        "document": "10198326",
        "fullName": "Jose Celimo Medina Mera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Galponero Casetero",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010806",
        "document": "1099376544",
        "fullName": "Luis Alberto Gomez Rey",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010729",
        "document": "1005711922",
        "fullName": "Brayan Santiago Vasquez Ayala",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar Electrico Granjas",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010936",
        "document": "1085544070",
        "fullName": "Yuver Andres Congolino Lerma",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010179",
        "document": "1113689026",
        "fullName": "David Alejandro Reyes Oliveros",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010225",
        "document": "1113531317",
        "fullName": "Diego Fabian Mosquera Olano",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Ruta",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011019",
        "document": "91513466",
        "fullName": "Hector Sandoval Duran",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011351",
        "document": "1127598359",
        "fullName": "Arley Antonio Duarte Vanegas",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010798",
        "document": "1010127450",
        "fullName": "Miguel Angel Pinzon Pinzon",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011257",
        "document": "1065896330",
        "fullName": "Jose Luis Santana Quintero",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011318",
        "document": "1193301665",
        "fullName": "Jose Fernando Vesga Ortiz",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011404",
        "document": "1102719466",
        "fullName": "Oscar Mauricio Sarmiento Ramos",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011345",
        "document": "1100892584",
        "fullName": "Jose Manuel Romero Ramirez",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011481",
        "document": "1065576347",
        "fullName": "Ana Karina Mendez Jordan",
        "company": "Distraves",
        "regional": "Vall",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011056",
        "document": "1098356842",
        "fullName": "Wilmer Moreno Gomez",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011298",
        "document": "1103471297",
        "fullName": "Duvan Felipe Amado Pardo",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011464",
        "document": "1100949433",
        "fullName": "Maria Ximena Moreno Avellaneda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010887",
        "document": "1097306934",
        "fullName": "Aleisy Jaimes Ortega",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011181",
        "document": "1098356636",
        "fullName": "Mary Luz Jaimes Anaya",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011466",
        "document": "1091074440",
        "fullName": "Brayan Jose Quintero Monsalve",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011434",
        "document": "1098739644",
        "fullName": "Brayan  Meneses Rojas",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/11/2023 7:53:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01207",
        "document": "1100893493",
        "fullName": "David Andres Duarte Ochoa",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/11/2023 9:53:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011399",
        "document": "1102359975",
        "fullName": "Jefferson Diaz Varela",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "4/11/2023 10:23:56 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011569",
        "document": "1000347402",
        "fullName": "Michael Andrey Ospina Upegui",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2023 3:57:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011563",
        "document": "1091132226",
        "fullName": "Andres Ivan Villamizar Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "4/12/2023 3:57:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011559",
        "document": "77132050",
        "fullName": "Luis Jose Rojas Rincon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/12/2023 3:57:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011561",
        "document": "1234338519",
        "fullName": "Luis Alberto Tabares Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/12/2023 3:57:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011576",
        "document": "1007593267",
        "fullName": "Diego Andres Miranda Nuñez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "4/12/2023 3:57:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011560",
        "document": "1093752278",
        "fullName": "Humberto Alexander Cuadros Ortega",
        "company": "Distraves",
        "regional": "Cucu",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2023 3:57:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011557",
        "document": "1095935864",
        "fullName": "Oscar Andres Sierra Celis",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/12/2023 3:57:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011570",
        "document": "1051734097",
        "fullName": "Rene De Jesus Rodriguez Ortega",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/12/2023 3:57:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011556",
        "document": "1005179089",
        "fullName": "Elkin Sebastian Vergel Ulloa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/12/2023 3:57:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011566",
        "document": "1007536426",
        "fullName": "Cristian Jesus Gomez Castellanos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2023 3:57:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011554",
        "document": "1143167079",
        "fullName": "Andrea Paola Colon De Leon",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Direccion Canal",
        "area": "Direccion Canal",
        "fechaIngreso": "4/12/2023 11:24:35 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011581",
        "document": "1092940948",
        "fullName": "Dayron Jarhel Sarkis Monsalve",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/12/2023 6:58:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011580",
        "document": "1005188910",
        "fullName": "Andres Felipe Ortiz Diaz",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Almacen De Materia Prima",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "4/12/2023 6:58:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011579",
        "document": "1000130365",
        "fullName": "Brayan Camilo Pirajan Tovar",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "4/12/2023 6:58:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011589",
        "document": "1067962285",
        "fullName": "Daniela Isabel Berrio Barragan",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "4/12/2023 6:58:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011584",
        "document": "1006217526",
        "fullName": "Veronica  Alvarez Mateus",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/12/2023 6:58:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011592",
        "document": "1005329784",
        "fullName": "Johan Sebastian Toscano Cobos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/12/2023 6:58:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011586",
        "document": "13745563",
        "fullName": "Wilman  Lopez Pineda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2023 6:58:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011567",
        "document": "1065863014",
        "fullName": "Angel Dario Palencia Camargo",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Supervisor Full Filler",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2023 7:54:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011574",
        "document": "71142027",
        "fullName": "Hector Fabio Blandon Lopez",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/12/2023 7:54:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011573",
        "document": "1003042209",
        "fullName": "Ever  Santiago Peñaranda",
        "company": "Distraves",
        "regional": "Pinc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "4/12/2023 7:54:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011591",
        "document": "1098356825",
        "fullName": "Claudia  Arenales Moreno",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/12/2023 7:54:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011604",
        "document": "1193512548",
        "fullName": "Yeison Andres Arenis Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/13/2023 3:57:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011601",
        "document": "1102377404",
        "fullName": "Fabian Johanny Rojas Chaparro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/13/2023 3:57:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011599",
        "document": "1102349469",
        "fullName": "Mary Luz Vargas Duran",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/13/2023 3:57:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011598",
        "document": "1003155191",
        "fullName": "Luz Milagro Rico Obeso",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/13/2023 3:57:54 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011593",
        "document": "1005287970",
        "fullName": "Andres Felipe Rincon Burgos",
        "company": "Transgraneles",
        "regional": "Giro",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "4/13/2023 4:56:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011542",
        "document": "1037656174",
        "fullName": "Maria Paulina Cardona Florez",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Impulsador Profesional",
        "uen": "Negocio Mascotas",
        "process": "Mascotas",
        "area": "Mascotas",
        "fechaIngreso": "4/14/2023 3:26:55 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011618",
        "document": "1005371532",
        "fullName": "Delcy Yuliana Garcia Rivera",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/19/2023 4:00:37 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011614",
        "document": "1193189747",
        "fullName": "Matilde  Moreno Gonzalez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "4/19/2023 4:59:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011606",
        "document": "1006033536",
        "fullName": "Andres Felipe Filigrama Rodriguez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "4/19/2023 4:59:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011608",
        "document": "1216724276",
        "fullName": "Cristian Camilo Echeverry Gomez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "4/19/2023 4:59:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011609",
        "document": "1017927347",
        "fullName": "Miguel Angel Muñoz Duarte",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "4/19/2023 4:59:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011610",
        "document": "1001605361",
        "fullName": "Diego Alejandro Ramirez Vargas",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "4/19/2023 4:59:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011613",
        "document": "1001846305",
        "fullName": "Fraimar  Cuesta Quejada",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "4/19/2023 4:59:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011621",
        "document": "1112148786",
        "fullName": "Maria Camila Moreno Rodriguez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "4/19/2023 5:58:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011620",
        "document": "1115091146",
        "fullName": "Lina Marcela Aricapa Torres",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "4/19/2023 5:58:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011617",
        "document": "1042997608",
        "fullName": "Blanca Rocio Alvarez Coronado",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "4/19/2023 11:28:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01542",
        "document": "1004338317",
        "fullName": "Anderson Arley Gelpud Guaquez",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/19/2023 11:28:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011623",
        "document": "1005154735",
        "fullName": "Nikolle Daniela Manrique Carvajal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/19/2023 7:04:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011629",
        "document": "74243858",
        "fullName": "Francisco Liver Peña Peña",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/20/2023 4:03:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011626",
        "document": "1101209726",
        "fullName": "Diego Andres Cuervo Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "4/20/2023 4:03:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011633",
        "document": "1013605771",
        "fullName": "Cindy Johanna Lozano Montañez",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/20/2023 7:05:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011637",
        "document": "1077841852",
        "fullName": "Natalia  Facundo Villanueva",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/20/2023 7:05:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011636",
        "document": "1097490042",
        "fullName": "Luz Angelica Jelkh Tapias",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/20/2023 7:05:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011635",
        "document": "1098260255",
        "fullName": "Adriana Lucia Carrizalez Reyes",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/20/2023 7:05:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011644",
        "document": "1102365655",
        "fullName": "Sergio Andres Florez Lopez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/21/2023 4:04:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011641",
        "document": "1098691877",
        "fullName": "Victor Julio Torres Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/21/2023 4:04:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011639",
        "document": "1005151089",
        "fullName": "Luvy Nayely Castillo Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/21/2023 4:04:28 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011615",
        "document": "1115082106",
        "fullName": "Carlos Andres Arias Ortiz",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "4/21/2023 7:07:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011656",
        "document": "1061541348",
        "fullName": "Joan Steven Cortes Cajas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "4/21/2023 7:07:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011654",
        "document": "1113675477",
        "fullName": "Wilfran Herley Pava Orobio",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/21/2023 7:07:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011655",
        "document": "91536802",
        "fullName": "Jhon Henrry Moreno Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/21/2023 7:07:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011649",
        "document": "1102382141",
        "fullName": "Jesmy Sherley Cuadros Marin",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/21/2023 7:07:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011646",
        "document": "1151940783",
        "fullName": "Yeison  Filigrana Mosquera",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/21/2023 7:07:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011666",
        "document": "1005540747",
        "fullName": "Oscar Eduardo Vera Lopez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/22/2023 4:07:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011665",
        "document": "1099734356",
        "fullName": "Marlon Stiven Pabon Jacome",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/22/2023 4:07:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011663",
        "document": "1007429336",
        "fullName": "Franky Duban Naranjo Viviesca",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/22/2023 4:07:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011659",
        "document": "1005309866",
        "fullName": "Darwin Riquelver Valero Barragan",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/22/2023 4:07:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008932",
        "document": "1114816416",
        "fullName": "Jorge Hernan Salcedo Restrepo",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "4/22/2023 4:37:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011677",
        "document": "1005157771",
        "fullName": "Nestor Javier Rabelo Alba",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/22/2023 7:08:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011669",
        "document": "1099377125",
        "fullName": "Derly Julieth Martinez Roncancio",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/22/2023 7:08:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011672",
        "document": "1007769652",
        "fullName": "Jhoann Hernando Mejia Galvis",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/22/2023 7:08:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011676",
        "document": "1098769453",
        "fullName": "Walther  Camacho Torres",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/24/2023 1:06:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011667",
        "document": "91506904",
        "fullName": "Luis Carlos Rosas Blanco",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Analista Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/24/2023 1:06:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011662",
        "document": "1102550473",
        "fullName": "Jenny Carolina Mantilla Olave",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "4/24/2023 1:06:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011648",
        "document": "1193530989",
        "fullName": "Jose Luis Cuero Vidal",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Limpieza Y Desinfeccion",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/24/2023 1:36:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011465",
        "document": "1098808315",
        "fullName": "Omar Yesid Ardila Lizarazo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "4/24/2023 3:06:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011683",
        "document": "18974717",
        "fullName": "Simon Elias Alvear Castro",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "4/24/2023 7:10:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011682",
        "document": "1102388600",
        "fullName": "Jorge Daniel Farelo Ortega",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/24/2023 7:10:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011679",
        "document": "1096538694",
        "fullName": "Yeferson Mauricio Pereira Cala",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "4/24/2023 7:10:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010794",
        "document": "1118574388",
        "fullName": "Juan Camilo Perez Barrera",
        "company": "Distraves",
        "regional": "Yopa",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "4/24/2023 9:13:28 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011685",
        "document": "1121873544",
        "fullName": "Karen Liceth Herrera Agredo",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/25/2023 3:46:16 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01523",
        "document": "1000617698",
        "fullName": "Yuli Andrea Varela Rodriguez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/25/2023 5:06:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011689",
        "document": "1002578850",
        "fullName": "Luis Alfredo Caña Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/25/2023 7:10:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009491",
        "document": "1002059421",
        "fullName": "Ariel Junior Herrera Alvarez",
        "company": "Agrinal",
        "regional": "Cart",
        "jobTitle": "Auxiliar Facturacion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "4/25/2023 9:06:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01347",
        "document": "11938334",
        "fullName": "Boris Esneiber Angel Moreno",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/25/2023 10:00:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01537",
        "document": "1143366970",
        "fullName": "Carlos Jose Melendez Moreno",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Planta Asesorada",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/25/2023 10:02:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01481",
        "document": "1116277912",
        "fullName": "David Angulo Medina",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2023 4:03:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011690",
        "document": "1096954508",
        "fullName": "Deisy Paola Correa Cardenas",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "4/26/2023 7:07:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011695",
        "document": "1007795927",
        "fullName": "Mario Alexis Quiñones Castro",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "4/26/2023 7:07:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011692",
        "document": "1113513170",
        "fullName": "Edwin Arturo Ortega Guacha",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/26/2023 7:07:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01500",
        "document": "1119948860",
        "fullName": "Wendy Tatiana Herrera Rivera",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2023 8:33:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01511",
        "document": "1115071204",
        "fullName": "Diana Maria Valdes Potes",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2023 9:03:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01524",
        "document": "14795270",
        "fullName": "Edwin Fabian Rico Rendon",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2023 9:03:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01341",
        "document": "1114452027",
        "fullName": "Duvan Schneider Alonso Palacios",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2023 9:03:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01541",
        "document": "1100971849",
        "fullName": "Diego Alexander Barrera Blanco",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2023 9:03:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01526",
        "document": "1096220893",
        "fullName": "Isabel Cristina Castrillon Montoya",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2023 9:33:13 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01332",
        "document": "1115075498",
        "fullName": "Julian Andres Gonzalez Gonzalez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2023 10:03:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01540",
        "document": "1095299609",
        "fullName": "Juan Sebastian Mendez Anaya",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2023 10:03:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010791",
        "document": "1039761884",
        "fullName": "Jhon Alexander Araque Sanmartin",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/26/2023 10:03:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01213",
        "document": "13277165",
        "fullName": "Johnathan Garces Ardila",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Galponero Oficos Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/26/2023 11:03:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011696",
        "document": "94475982",
        "fullName": "Fabian Andres Martinez Zambrano",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "4/27/2023 7:07:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011697",
        "document": "1115077321",
        "fullName": "Kevin Andres Restrepo Colorado",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Electricista",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "4/27/2023 7:07:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01512",
        "document": "31658458",
        "fullName": "Erika Caicedo Hernandez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 9:04:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01538",
        "document": "1232892899",
        "fullName": "Jeferson Pabon Suarez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 9:34:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01480",
        "document": "10494521",
        "fullName": "Jairo Leon Duque Caro",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 9:34:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01539",
        "document": "1116819463",
        "fullName": "Jorge Eliecer Murillo Contreras",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 9:34:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01476",
        "document": "1101598627",
        "fullName": "Gerson David Carvajal Jaimes",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 9:34:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01555",
        "document": "1071350326",
        "fullName": "Jorge Andres Acosta Hoyos",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Bodega",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 9:34:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01315",
        "document": "1007383019",
        "fullName": "Jesus David Garcia Celis",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccon",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 9:34:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01335",
        "document": "1116258342",
        "fullName": "Manuel Alejandro Vargas Duran",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 10:04:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01554",
        "document": "1024498235",
        "fullName": "Leidy Marcela Herrera Rico",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 10:04:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01527",
        "document": "39457021",
        "fullName": "Nataly Arbelaez Sepulveda",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 10:04:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01556",
        "document": "1088035480",
        "fullName": "Kevin Andres Jaramillo Lopez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Operario De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 10:04:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011611",
        "document": "1001508815",
        "fullName": "Julian Alejandro Oquendo Zamarra",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "4/27/2023 10:04:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01543",
        "document": "1017142110",
        "fullName": "Maribel Atehortua",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 10:04:14 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01553",
        "document": "1038361271",
        "fullName": "Viviana Andrea Osorio Berrio",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Servicios Generales",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 10:34:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01552",
        "document": "1115076555",
        "fullName": "Victor Manuel Perez Corrales",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Operario De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "4/27/2023 10:34:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004044",
        "document": "1095948805",
        "fullName": "Laura Melissa Guerrero Delgado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/28/2023 4:35:03 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011661",
        "document": "1102384126",
        "fullName": "Cesar Augusto Mantilla Santos",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/28/2023 5:35:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011643",
        "document": "1005324411",
        "fullName": "Paula Andrea Esparza Parra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/28/2023 5:35:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011670",
        "document": "1098760780",
        "fullName": "Yina Paola Jimenez Plata",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "4/28/2023 6:05:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011688",
        "document": "1098713049",
        "fullName": "Andrea Juliana Hernandez Suarez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Director Comercial Rotante",
        "uen": "Comercial",
        "process": "Direccion Uen",
        "area": "Direccion Uen",
        "fechaIngreso": "4/28/2023 6:05:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011714",
        "document": "1100891818",
        "fullName": "Hector Gabriel Romero Ramirez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/29/2023 4:06:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011711",
        "document": "1127341332",
        "fullName": "Jose Gabriel Chacon Caicedo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "4/29/2023 4:06:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011704",
        "document": "1102351667",
        "fullName": "Miguel Angel Monsalve Morales",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "4/29/2023 4:06:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011708",
        "document": "1100891887",
        "fullName": "Orlando  Rodriguez Lopez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "4/29/2023 4:06:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011709",
        "document": "94482392",
        "fullName": "Harrison  Castro Bermudez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Logística De Entrada Materias Primas",
        "fechaIngreso": "4/29/2023 7:08:58 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011719",
        "document": "1005297832",
        "fullName": "Juan Esteban Pradilla Aceros",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/30/2023 4:07:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011720",
        "document": "1005279954",
        "fullName": "Santiago  Rojas Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "4/30/2023 4:07:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011724",
        "document": "1001686263",
        "fullName": "Oswaldo Andres Vasco Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/1/2023 7:11:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011722",
        "document": "1095816866",
        "fullName": "Zenaida  Moncada Mora",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/1/2023 7:11:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011721",
        "document": "1100893378",
        "fullName": "Julio Ernesto Vasquez Correal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/1/2023 7:11:00 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011728",
        "document": "1095954684",
        "fullName": "Wilson Andres Pedraza Aparicio",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/2/2023 3:40:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011736",
        "document": "1098830709",
        "fullName": "Andersson Esleiter Echavarria Ulloa",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/2/2023 3:40:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011731",
        "document": "1102351629",
        "fullName": "Diego Fernando Capera Poveda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/2/2023 3:40:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011730",
        "document": "1083032125",
        "fullName": "Luis Santiago Falcon Muñoz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/2/2023 3:40:57 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011727",
        "document": "1082874276",
        "fullName": "Deyfred Ricardo Alvarado Vasco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/2/2023 3:40:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011726",
        "document": "1098764836",
        "fullName": "Jhon William Esparza Fontalvo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "5/2/2023 3:40:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011725",
        "document": "1092176002",
        "fullName": "Juan David Rocha Fuentes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/2/2023 3:40:58 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01507",
        "document": "1234340555",
        "fullName": "Kevin Ferney Gonzalez Mora",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/2/2023 4:25:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01334",
        "document": "94482493",
        "fullName": "Jose Luis Bayona Tabares",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/2/2023 4:25:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01346",
        "document": "1088358201",
        "fullName": "Santiago Cuero Quiceno",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/2/2023 4:25:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01478",
        "document": "1232890285",
        "fullName": "Robinson Stid Rodriguez Carreño",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/2/2023 4:25:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011710",
        "document": "1030610534",
        "fullName": "Winston Andrey Ossa Barragan",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/2/2023 7:00:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011742",
        "document": "1042065846",
        "fullName": "Jhoan Sebastian Rendon Soto",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "5/2/2023 7:00:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011745",
        "document": "1099622411",
        "fullName": "Yoly Dayana Calvache Toloza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/2/2023 7:00:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011660",
        "document": "1095825694",
        "fullName": "Erick Alfonso Ramirez Suarez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "5/2/2023 7:55:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011702",
        "document": "1005479154",
        "fullName": "Jeysson Adrian Fuentes Landazabal",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/2/2023 7:55:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01263",
        "document": "87433422",
        "fullName": "Jose Antonio Perea Quiñonez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/2/2023 8:25:46 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011717",
        "document": "1005345134",
        "fullName": "Giovanny Sleyder Quiñonez Gil",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "5/3/2023 1:26:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011707",
        "document": "1091079368",
        "fullName": "Diomar Alonso Carrascal Quintero",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "5/3/2023 1:26:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011744",
        "document": "1099363494",
        "fullName": "Ronald Mauricio Blanco Fuentes",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "5/3/2023 1:26:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011718",
        "document": "1005136552",
        "fullName": "Cristian Ferney Gonzalez Diaz",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/3/2023 1:26:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1293201",
        "document": "1115083852",
        "fullName": "Andres Felipe Ossa Gañan",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/3/2023 4:28:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01012",
        "document": "1115080128",
        "fullName": "Wilson Javier Ortiz Zuluaga",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Aux De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/3/2023 5:28:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009575",
        "document": "1005478923",
        "fullName": "Glenddy Geraldyn Jerez Florez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Calidad",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/3/2023 7:29:31 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007843",
        "document": "1002455001",
        "fullName": "Cristian Ferley Baez Estupiñan",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Operativa",
        "fechaIngreso": "5/3/2023 8:58:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011746",
        "document": "1018226550",
        "fullName": "David Esneider Correa Mesa",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/4/2023 5:00:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011747",
        "document": "1005676761",
        "fullName": "Jhon Fredy Rios Castillo",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/4/2023 5:00:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01559",
        "document": "1006492438",
        "fullName": "Diego Fernando Guiza Tascon",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Operario De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/4/2023 2:39:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011749",
        "document": "1216721836",
        "fullName": "Manuela  Alvarez Arango",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Analista De Compras",
        "uen": "Corporativo Compras Y Logística",
        "process": "Compras Internacionales",
        "area": "Compras Internacionales",
        "fechaIngreso": "5/4/2023 7:13:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011750",
        "document": "3482167",
        "fullName": "Juan Esteban Posada Ochoa",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Supervisor Geografico",
        "uen": "Comercial",
        "process": "Zona Antioquia",
        "area": "Zona Antioquia",
        "fechaIngreso": "5/4/2023 9:10:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011753",
        "document": "1151940307",
        "fullName": "Alvaro Jose Cardona Correa",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/5/2023 5:44:55 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011765",
        "document": "1193319852",
        "fullName": "Yerson Jose Castro Cortina",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/5/2023 6:46:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011755",
        "document": "1002594997",
        "fullName": "Yenni Alejandra Velez Tonuzco",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "5/5/2023 7:18:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011766",
        "document": "4745189",
        "fullName": "Jose Daniel Rodriguez Vasquez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/5/2023 7:18:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011712",
        "document": "94482392",
        "fullName": "Orlando  Rodriguez Herrera",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/5/2023 7:18:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011763",
        "document": "1112105764",
        "fullName": "Yhonatan David Corrales Morales",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "5/5/2023 7:18:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011760",
        "document": "1113518840",
        "fullName": "Johan Felipe Guzman Gonzalez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/5/2023 7:18:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011757",
        "document": "6210382",
        "fullName": "Lenin Vladimir Bracamonte Ramos",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/5/2023 7:18:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011767",
        "document": "1114064600",
        "fullName": "Andres Felipe Cano Triana",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/6/2023 7:06:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011768",
        "document": "16864281",
        "fullName": "Vladimir  Montilla Fernandez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/6/2023 7:06:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011770",
        "document": "1115087459",
        "fullName": "Carlos Jose Perez Lenis",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Movimiento De Materias Primas",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "5/6/2023 7:06:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01561",
        "document": "1113521666",
        "fullName": "Yesica Liliana Rodriguez Campo",
        "company": "Spg-dar Ayuda",
        "regional": "Cand",
        "jobTitle": "Pasante",
        "uen": "Gerencia Administrativo",
        "process": "Administrativo",
        "area": "Administrativo",
        "fechaIngreso": "5/8/2023 3:04:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011773",
        "document": "1000440657",
        "fullName": "Laura Gimena Borja Ospina",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "5/8/2023 7:07:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011772",
        "document": "1088344784",
        "fullName": "Valentina  Gomez Florez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "5/8/2023 7:07:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011775",
        "document": "1000777620",
        "fullName": "Olmer Alejandro Padilla Jimenez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/9/2023 4:07:24 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011776",
        "document": "1151447953",
        "fullName": "Solanyi Milena Holguin Vargas",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "5/9/2023 4:38:02 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010334",
        "document": "1005151487",
        "fullName": "Natalia Duran Archila",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Profesional En Formacion",
        "uen": "Produccion",
        "process": "Gerencia Industrial",
        "area": "Gerencia Industrial",
        "fechaIngreso": "5/9/2023 4:06:09 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011780",
        "document": "1000445229",
        "fullName": "Brahian David Rios Ramirez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "5/9/2023 7:09:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011787",
        "document": "1095814425",
        "fullName": "David Edgardo Nuñez Rosales",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2023 3:38:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011784",
        "document": "88256314",
        "fullName": "Salvador  Campos Contreras",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2023 3:38:18 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011789",
        "document": "1102372753",
        "fullName": "Sergio Alonso Gomez Soto",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/10/2023 3:38:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011788",
        "document": "1128149976",
        "fullName": "Eider  Navarro Morales",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/10/2023 3:38:19 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011790",
        "document": "31583114",
        "fullName": "Carol Denis Rojas Cardona",
        "company": "Transgraneles",
        "regional": "Buen",
        "jobTitle": "Auxiliar De Oficina",
        "uen": "Negocio - Operación Transporte",
        "process": "Operación Transporte",
        "area": "Operación Transporte",
        "fechaIngreso": "5/10/2023 4:39:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011585",
        "document": "1007766771",
        "fullName": "Juan David Peña Cotinchara",
        "company": "Distraves",
        "regional": "Chiq",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/10/2023 4:08:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010911",
        "document": "1100896139",
        "fullName": "Carlos Alberto Rojas Galindo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2023 4:08:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011642",
        "document": "1005053677",
        "fullName": "Jimmy Fabian Hernandez Davila",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/10/2023 4:38:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011501",
        "document": "1194397800",
        "fullName": "Wilson Perez Zuñiga",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/10/2023 4:38:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011413",
        "document": "1102370588",
        "fullName": "Cesar Augusto Mantilla Garza",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "5/10/2023 4:38:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011410",
        "document": "88132270",
        "fullName": "Jairo Omar Gomez Corzo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Pollo Vivo",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/10/2023 4:38:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011493",
        "document": "1067590501",
        "fullName": "Brayan Alexis Rodriguez Torres",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "5/10/2023 5:08:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010986",
        "document": "1005541712",
        "fullName": "Daniel Gonzalez Sandoval",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "5/10/2023 5:08:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010955",
        "document": "1095927930",
        "fullName": "Carlos Andres Perez Aceros",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2023 5:08:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011684",
        "document": "1064722645",
        "fullName": "Alvaro Duarte Vega",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/10/2023 5:08:22 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011799",
        "document": "1004811026",
        "fullName": "Hayder Jose Martinez Blanco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2023 7:11:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011794",
        "document": "1113536937",
        "fullName": "Luis Miguel Vargas Vanegas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/10/2023 7:11:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011739",
        "document": "1094168975",
        "fullName": "Junior Rincon Ceron",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/10/2023 7:11:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011793",
        "document": "1002089057",
        "fullName": "Cristian Camilo Paez Rivas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/10/2023 7:11:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011438",
        "document": "1007917993",
        "fullName": "Cristian Andres Rodriguez Toro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2023 7:11:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011435",
        "document": "1101596715",
        "fullName": "Argenis Caceres Sierra",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios_semillero",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/10/2023 7:40:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011451",
        "document": "1005328824",
        "fullName": "Jose Andres Lizarazo Tarazona",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/10/2023 7:40:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01400",
        "document": "1116855363",
        "fullName": "Adrian Jaimes Jimenez",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/10/2023 7:40:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011777",
        "document": "63555195",
        "fullName": "Ana Deisy Yarid Guarin Bernal",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Administrador Punto De Venta",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "5/10/2023 7:40:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011590",
        "document": "28359195",
        "fullName": "Aminta Hernandez Jaimes",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/10/2023 8:08:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011583",
        "document": "1095838072",
        "fullName": "Sebastian Sanchez Sequeda",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/10/2023 8:08:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011624",
        "document": "1102351976",
        "fullName": "Jose Gregorio Lozano Rangel",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/10/2023 8:08:32 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011578",
        "document": "1005345177",
        "fullName": "Yeiner Ortega Fonseca",
        "company": "Distraves",
        "regional": "Flor",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "5/10/2023 8:38:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011568",
        "document": "1232890962",
        "fullName": "Jerson Andres Ochoa De La Cruz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2023 8:38:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01398",
        "document": "1005036647",
        "fullName": "Julian Antonio Hernandez Ortiz",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Auxiliar Logistico",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/10/2023 8:38:33 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011090",
        "document": "1090436372",
        "fullName": "Jhon Alexander Alvarez Buenaver",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/10/2023 9:08:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011715",
        "document": "1098765388",
        "fullName": "Maria Alejandra Rondon Duarte",
        "company": "Distraves",
        "regional": "Buca",
        "jobTitle": "Vendedor Mostrador",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "5/10/2023 9:08:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011706",
        "document": "1091074439",
        "fullName": "Yeferson Arley Quintero Monsalve",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "5/10/2023 9:38:35 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011822",
        "document": "1096954800",
        "fullName": "Jahir Edgardo Pinzon Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/11/2023 3:40:31 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011803",
        "document": "1094371064",
        "fullName": "Daniel Fernando Acevedo Sanchez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/11/2023 3:40:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011821",
        "document": "1098634866",
        "fullName": "Anderson Camilo Quiroga Gomez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/11/2023 3:40:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011802",
        "document": "1102379395",
        "fullName": "Jose Marcelo Mayorga Campo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/11/2023 3:40:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011819",
        "document": "1098794612",
        "fullName": "Jorge Eliecer Morales Ferreira",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Y/0 Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Planta Incubadora",
        "area": "Planta Incubadora",
        "fechaIngreso": "5/11/2023 3:40:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011801",
        "document": "1102390282",
        "fullName": "Anderson Jair Burgos Blanco",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/11/2023 3:40:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011812",
        "document": "1005160919",
        "fullName": "Neyder Steven Rojas Ayala",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/11/2023 3:40:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011800",
        "document": "1102371539",
        "fullName": "Elkin Fabian Rodriguez Romero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/11/2023 3:40:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011807",
        "document": "1091966260",
        "fullName": "Yilbert David Perez Basto",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/11/2023 3:40:32 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011810",
        "document": "1115092503",
        "fullName": "Angel Gabriel Montoya Gallego",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/11/2023 4:40:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011815",
        "document": "1045234787",
        "fullName": "Leider De Jesus Galindo Garcia",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/11/2023 1:39:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011804",
        "document": "1098787211",
        "fullName": "Wilmar Andres Guerrero Contreras",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Sanidad",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/11/2023 1:39:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011811",
        "document": "1051590182",
        "fullName": "Kenia Vanesa Herrera Salamanca",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Norte",
        "area": "Zona Norte",
        "fechaIngreso": "5/11/2023 1:39:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011813",
        "document": "1017269728",
        "fullName": "Victor Manuel Argumedo Sampayo",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/11/2023 1:39:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011820",
        "document": "1044605496",
        "fullName": "Stiven David Zapata Gutierrez",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/11/2023 1:39:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011816",
        "document": "1033341217",
        "fullName": "Juan Camilo Bermudez Bedoya",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/11/2023 1:39:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010265",
        "document": "1060869311",
        "fullName": "Nasly Isabel Acosta Vivas",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Digitador",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/11/2023 2:39:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009389",
        "document": "1113541779",
        "fullName": "Adrian Camilo Tusarma Polo",
        "company": "Galponsas",
        "regional": "cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/11/2023 4:41:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011774",
        "document": "1193474940",
        "fullName": "Daniel  Angulo Congo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Técnico Electricista",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "5/11/2023 5:11:18 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011826",
        "document": "1095941008",
        "fullName": "Faber Marcelly Torres Vargas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/11/2023 6:43:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011824",
        "document": "1102389298",
        "fullName": "Anlly Xiomare Alvarado Vasquez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/11/2023 6:43:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011827",
        "document": "1005345801",
        "fullName": "Yan Carlos Jerez Santamaria",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/11/2023 6:43:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011831",
        "document": "1007969113",
        "fullName": "Jesus Daniel Sabalza Licona",
        "company": "Distraves",
        "regional": "Cart",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/11/2023 6:43:12 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011782",
        "document": "1044986561",
        "fullName": "Brahian Stiven Correa Balvuena",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/11/2023 7:14:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011783",
        "document": "1039699222",
        "fullName": "Daniela  Rios Zapata",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/11/2023 7:14:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011771",
        "document": "1063178143",
        "fullName": "Jair David Rudiño Sanchez",
        "company": "Solla",
        "regional": "Ita2",
        "jobTitle": "Auxiliar Centro De Distribucion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/11/2023 7:14:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011781",
        "document": "1001813193",
        "fullName": "Jover Danilo Hernandez Torres",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "5/11/2023 7:14:38 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004067",
        "document": "1005372117",
        "fullName": "Jose Alfonso Burgos Prada",
        "company": "Distraves",
        "regional": "Sang",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "5/11/2023 9:32:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10001435",
        "document": "1098357252",
        "fullName": "Uriel Guevara Soto",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "5/11/2023 9:32:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01266",
        "document": "1098814841",
        "fullName": "Angie Lorena Puerto Maldonado",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/11/2023 9:32:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007062",
        "document": "1005309871",
        "fullName": "Juan Carlos Rojas Balaguera",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/11/2023 9:32:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011832",
        "document": "1102385483",
        "fullName": "Miguel Angel Rincon Niño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/12/2023 4:04:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011842",
        "document": "1192757522",
        "fullName": "Alejandro  Vargas Martinez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/12/2023 7:07:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011839",
        "document": "4919007",
        "fullName": "Carlos Daniel Gonzalez Flores",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/12/2023 7:07:16 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011843",
        "document": "1004945475",
        "fullName": "Bleimar Jose Alvernia Sanchez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/12/2023 7:07:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011845",
        "document": "1019988208",
        "fullName": "Nathalia  Colorado Martinez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "5/12/2023 7:07:17 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011836",
        "document": "1103714399",
        "fullName": "Santiago  Triana Ardila",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Coordinador Tecnico En Automatizacion",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/12/2023 10:03:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011846",
        "document": "1069154090",
        "fullName": "Xiomara Liceth Caceres Ramirez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "5/13/2023 4:36:33 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01562",
        "document": "1022360213",
        "fullName": "Zury Yorledys Gil Peña",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Vendedor Mostrador",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/15/2023 5:38:57 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011833",
        "document": "1096063234",
        "fullName": "Victor Alfonso Vera Sequeda",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/16/2023 4:39:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011829",
        "document": "1100950320",
        "fullName": "Lorange Yulieth Quintero Marin",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/16/2023 4:39:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011828",
        "document": "1005153649",
        "fullName": "Estefany  Bayona Sequeda",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "5/16/2023 4:39:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011835",
        "document": "1095953512",
        "fullName": "Ferney Yesid Villamizar Florez",
        "company": "Distraves",
        "regional": "Zapa",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Reproductoras",
        "area": "Reproductoras",
        "fechaIngreso": "5/16/2023 4:39:51 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011848",
        "document": "1036637957",
        "fullName": "Duvan Alberto Vasquez Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Conectividad",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Conectividad",
        "fechaIngreso": "5/16/2023 5:09:52 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011847",
        "document": "1022143560",
        "fullName": "Deibyn Ferley Arango Alzate",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "5/16/2023 7:10:48 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011849",
        "document": "1005109737",
        "fullName": "Gerson Sneider Pinto Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/17/2023 3:39:22 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011851",
        "document": "1006210172",
        "fullName": "Juan Sebastian Rodriguez Obando",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/17/2023 7:12:05 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011852",
        "document": "1113540288",
        "fullName": "Brayan Alejandro Pecillo Trochez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/18/2023 5:40:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011859",
        "document": "1094899267",
        "fullName": "Alexander Steven Pareja Fajardo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/18/2023 5:40:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011858",
        "document": "1237440225",
        "fullName": "Adrian Alejandro Moreno",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/18/2023 5:40:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011856",
        "document": "1088005696",
        "fullName": "Jefferson  Rivera Delgado",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/18/2023 5:40:30 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011860",
        "document": "1112967420",
        "fullName": "Fabian Reyenl Sepulveda Carvajal",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Mecanico",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "5/18/2023 7:14:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01568",
        "document": "1115080567",
        "fullName": "Jennifer Ospitia Quintero",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/19/2023 3:12:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10004245",
        "document": "1077967578",
        "fullName": "Ricardo  Leon Amaya",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/23/2023 3:30:29 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011864",
        "document": "1110476500",
        "fullName": "Morely Tatiana Arias Morales",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Occidente",
        "area": "Zona Occidente",
        "fechaIngreso": "5/23/2023 7:04:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011861",
        "document": "1115071503",
        "fullName": "Sebastian  Aragon Cano",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "5/23/2023 7:04:20 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011866",
        "document": "1121969284",
        "fullName": "Flor Angela Romero Guzman",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor Punto De Venta",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "5/23/2023 7:31:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011850",
        "document": "1115068537",
        "fullName": "David Antonio Osorio Mora",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Movimiento De Materias Primas",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Recepcion Y Almacenamiento De Mp",
        "fechaIngreso": "5/24/2023 4:33:17 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011869",
        "document": "1006217155",
        "fullName": "Breyner Andres Asprilla",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/24/2023 6:01:43 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011868",
        "document": "1128402632",
        "fullName": "Tatiana  Alvarez Botero",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Auxiliar Talento Humano",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Talento Humano",
        "fechaIngreso": "5/24/2023 5:31:39 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011876",
        "document": "1102388308",
        "fullName": "Joselito  Jejen Guerrero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "5/24/2023 7:05:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011874",
        "document": "1005324862",
        "fullName": "Nally Tatiana Cortes Cifuentes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Laboratorio",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "5/24/2023 7:05:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011873",
        "document": "1005540164",
        "fullName": "Joan Mauricio Mota Martinez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/24/2023 7:05:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011875",
        "document": "1005321881",
        "fullName": "Duvan Albeiro Reyes Villamizar",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/24/2023 7:05:06 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011881",
        "document": "1005258191",
        "fullName": "Hermes Fabian Ramirez Saldaña",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011892",
        "document": "1005235512",
        "fullName": "Jonathan Sebastian Botia Garces",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011885",
        "document": "1091081963",
        "fullName": "Ronald Jose Rojano Camarillo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011895",
        "document": "1102363847",
        "fullName": "Jorge Armando Santos Almeida",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011891",
        "document": "1005305615",
        "fullName": "Nayeli  Jaimes Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011882",
        "document": "1005541015",
        "fullName": "Jesus Alfonso Serna Mejia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011890",
        "document": "1005541951",
        "fullName": "Juan Francisco Alba Plata",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011887",
        "document": "1102383772",
        "fullName": "Jorge Andres Buitrago Espinoza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011893",
        "document": "91459298",
        "fullName": "Seudiel  Mendoza Jaimes",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011889",
        "document": "1005151448",
        "fullName": "William Alejandro Villamizar Alza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011888",
        "document": "1127064633",
        "fullName": "Lawrent Yohan Flores Hernandez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/25/2023 4:05:04 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011898",
        "document": "1095949850",
        "fullName": "Ivan  Verdugo Arbelaez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "5/25/2023 7:07:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011912",
        "document": "7144683",
        "fullName": "Angel David Thomas Algarin",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/26/2023 4:37:22 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011915",
        "document": "1083467540",
        "fullName": "Rodrigo Stiven Silva Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/26/2023 6:57:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011911",
        "document": "1065613045",
        "fullName": "Juan Camilo Patiño Garcia",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/26/2023 6:57:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011910",
        "document": "1005371100",
        "fullName": "Laura Vanessa Jaimes Moreno",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/26/2023 6:57:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011909",
        "document": "1010009301",
        "fullName": "Olivo  Jerez Sequeda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/26/2023 6:57:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011914",
        "document": "1079262157",
        "fullName": "David Santiago Olaya Castillo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/26/2023 6:57:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011917",
        "document": "1000752425",
        "fullName": "Santiago  Guzman Espinosa",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Fumigador",
        "uen": "Corporativo Compras Y Logística",
        "process": "Logística Y Transporte",
        "area": "Cedis",
        "fechaIngreso": "5/26/2023 6:57:26 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01155",
        "document": "1216723063",
        "fullName": "Valeria Hernandez Vasquez",
        "company": "Transgraneles-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Oficina",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/26/2023 7:06:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011921",
        "document": "1116280941",
        "fullName": "Jessica  Ramirez Velez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "5/27/2023 6:06:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011918",
        "document": "1115083884",
        "fullName": "Diana Lorena Agudelo Gutierrez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "5/27/2023 6:06:00 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01575",
        "document": "1004365467",
        "fullName": "Miguel David Montenegro Figueroa",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar Contable",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "5/30/2023 7:15:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011381",
        "document": "1116158174",
        "fullName": "Jessica Fernanda Osorio Jimenez",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "5/31/2023 4:43:22 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011930",
        "document": "91184689",
        "fullName": "Ronal  Mejia Ayala",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Inspector Cargue",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/31/2023 4:47:23 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011904",
        "document": "1098621675",
        "fullName": "Juan Pablo Rodriguez Garcia",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Supervisor Puntos De Venta",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "5/31/2023 5:17:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011879",
        "document": "1102375178",
        "fullName": "Cindy Johana Uribe Mendoza",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Produccion",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/31/2023 5:17:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011936",
        "document": "1091133558",
        "fullName": "Liceth Katerine Rodriguez Diaz",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011951",
        "document": "1102390035",
        "fullName": "Fabio Andres Suarez Castro",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011942",
        "document": "1007790708",
        "fullName": "Daniel Eliud Ortiz Sandoval",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011935",
        "document": "1005236602",
        "fullName": "Edwin Augusto Palomino Cadena",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011950",
        "document": "1104125953",
        "fullName": "Edgardo Jose Navarro Guzman",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011941",
        "document": "1052414430",
        "fullName": "Lenis Ximena Lopez Abella",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011940",
        "document": "1005563316",
        "fullName": "Cesar Augusto Galeano Vargas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011947",
        "document": "1098640241",
        "fullName": "Paula Nathaly Morales Ramirez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011933",
        "document": "1065827438",
        "fullName": "Jhon Jairo Jose Navarro Rojas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011938",
        "document": "1098747579",
        "fullName": "Gerardo  Remolina Polentino",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011945",
        "document": "1005541502",
        "fullName": "Linda Melissa Ayala Villamizar",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011934",
        "document": "1105674747",
        "fullName": "Zamir Giovanny Cifuentes Pava",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011937",
        "document": "1005372043",
        "fullName": "Camilo  Heredia Estupiñan",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011943",
        "document": "1007784724",
        "fullName": "Sthefany Tatiana Cañas Ariza",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "5/31/2023 7:20:40 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011954",
        "document": "1005541556",
        "fullName": "Marlon Ivan Garnica Barajas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "6/1/2023 3:49:50 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011955",
        "document": "1115070454",
        "fullName": "Luis Alberto Hernandez Soto",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "6/1/2023 6:49:47 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011957",
        "document": "3400256",
        "fullName": "Carlos Augusto Herrera Restrepo",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Especialista Aplicaciones",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Aplicaciones",
        "fechaIngreso": "6/1/2023 6:49:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011959",
        "document": "1000556770",
        "fullName": "Andres  Lugo Bernal",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/1/2023 6:49:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011962",
        "document": "1001017753",
        "fullName": "Susana  Zuluaga Puerta",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/1/2023 6:49:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011964",
        "document": "1083025694",
        "fullName": "Isabella  Gutierrez Lopez",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/1/2023 6:49:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011965",
        "document": "1037660476",
        "fullName": "Julian  Echeverry Toro",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/1/2023 6:49:49 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011963",
        "document": "1144178684",
        "fullName": "Brallan Steven Jimenez Parra",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/1/2023 7:20:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011961",
        "document": "1113650419",
        "fullName": "Andres Felipe Asprilla Moreno",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/1/2023 7:20:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011960",
        "document": "1007500598",
        "fullName": "Brayan Stiven Arrechea Saa",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/1/2023 7:20:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011906",
        "document": "1035832618",
        "fullName": "Ever Argelio Yepes Echavarria",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "6/1/2023 9:40:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011903",
        "document": "1038811120",
        "fullName": "Dubergel  Manco Padilla",
        "company": "Solla",
        "regional": "Gome",
        "jobTitle": "Operario Granja",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "6/1/2023 9:40:07 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01571",
        "document": "1054918868",
        "fullName": "Ruben Dario Salazar Gonzalez",
        "company": "Solla-dar Ayuda",
        "regional": "",
        "jobTitle": "Auxiliar De Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/1/2023 9:46:50 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010112",
        "document": "1098784515",
        "fullName": "Jose Ricardo Plata Quintero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Tecnico Mantenimiento",
        "uen": "Produccion",
        "process": "Mantenimiento",
        "area": "Mantenimiento",
        "fechaIngreso": "6/1/2023 10:16:53 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011969",
        "document": "1113535422",
        "fullName": "Dayana Andrea Panesso Lourido",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia Administrativa Y Financiera",
        "process": "Gerencia Administrativa",
        "area": "Talento Humano",
        "fechaIngreso": "6/2/2023 5:47:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011968",
        "document": "1143875642",
        "fullName": "Juliana  Salamanca Aponte",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Calidad",
        "uen": "Produccion",
        "process": "Direccion Administrativa De Produccion",
        "area": "Direccion Administrativa De Produccion",
        "fechaIngreso": "6/2/2023 5:47:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011944",
        "document": "1099215435",
        "fullName": "Maira Alejandra Pineda Diaz",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "6/2/2023 1:47:42 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011958",
        "document": "1114058602",
        "fullName": "Jose Adolfo Bernal Pineda",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/2/2023 6:49:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011977",
        "document": "1102353169",
        "fullName": "Edinson  Charagua Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/6/2023 3:52:59 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011979",
        "document": "1005874319",
        "fullName": "Andres Felipe Ospina Fajardo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/6/2023 6:54:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011978",
        "document": "1115072059",
        "fullName": "Victor Alfonso Lancheros Botero",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Mecanico",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Mantenimiento Y Metrología",
        "area": "Mantenimiento Y Metrología",
        "fechaIngreso": "6/6/2023 6:54:11 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011988",
        "document": "1087117064",
        "fullName": "Maria Camila Gutierrez Ramos",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "6/7/2023 3:54:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011994",
        "document": "1005257015",
        "fullName": "Angie Daniela Rojas Vera",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "6/7/2023 3:54:23 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011975",
        "document": "1115081845",
        "fullName": "Jair Andres Useche Osorio",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "6/7/2023 4:54:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011986",
        "document": "1112227684",
        "fullName": "Fabian Alexander Soto Sanchez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/7/2023 5:53:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011985",
        "document": "4982216",
        "fullName": "Dervi Lenic Lopez Lecumberre",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/7/2023 5:53:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011984",
        "document": "1114892049",
        "fullName": "Cristian Eduardo Cobo Benavides",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/7/2023 5:53:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011983",
        "document": "1192916515",
        "fullName": "Jairo  Largacha Castillo",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/7/2023 5:53:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011987",
        "document": "1114899483",
        "fullName": "Oscar Eduardo Angrino Rosero",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/7/2023 5:53:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011981",
        "document": "1005979371",
        "fullName": "Jean Carlos Rodriguez Santacruz",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/7/2023 5:53:06 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007751",
        "document": "1193083944",
        "fullName": "Valentina  Holguin Arce",
        "company": "Solla",
        "regional": "Buga",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Empacado Mascotas",
        "fechaIngreso": "6/7/2023 6:56:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011996",
        "document": "1075318130",
        "fullName": "Oscar Andres Valencia Quintero",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "6/7/2023 6:56:10 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011632",
        "document": "1099367308",
        "fullName": "Alexander Quintana Guerrero",
        "company": "Distraves",
        "regional": "Sanv",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "6/7/2023 9:53:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10008286",
        "document": "1102371803",
        "fullName": "Virgilio Rodriguez Villabona",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/7/2023 9:53:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10009520",
        "document": "1005540272",
        "fullName": "Andrey Felipe Luna Forero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/7/2023 9:53:25 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011998",
        "document": "43615866",
        "fullName": "Ana Maria Fernandez Correa",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador De Seleccion Y Bienestar",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Bienestar Y Servicios Compartidos",
        "fechaIngreso": "6/8/2023 4:55:01 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "1153899",
        "document": "1037524142",
        "fullName": "Jaqueline Andrea Ceballos Perez",
        "company": "Solla",
        "regional": "Sant",
        "jobTitle": "Coordinador Granjas Porcicolas",
        "uen": "Negocio Mip",
        "process": "Producción Pecuaria Porcicultura",
        "area": "Producción Pecuaria Porcicultura",
        "fechaIngreso": "6/8/2023 5:24:24 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012000",
        "document": "80003427",
        "fullName": "Andres Felipe Vargas Jimenez",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Asistente Tecnico Ventas",
        "uen": "Negocio Mip",
        "process": "Avicultura",
        "area": "Avicultura",
        "fechaIngreso": "6/8/2023 6:57:19 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012023",
        "document": "1003335572",
        "fullName": "Yonfredi  Miranda Chavez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "6/9/2023 4:28:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012017",
        "document": "1005180757",
        "fullName": "Esther Judith Callejas Ortega",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "6/9/2023 4:28:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012011",
        "document": "1065891973",
        "fullName": "Eneider  Bonilla Chogo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/9/2023 4:28:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012024",
        "document": "1007579560",
        "fullName": "Juan Carlos Vega Silva",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/9/2023 4:28:29 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012005",
        "document": "1049928422",
        "fullName": "Elkin David Feria Castillo",
        "company": "Solla",
        "regional": "Mosq",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/9/2023 4:59:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012015",
        "document": "1088341132",
        "fullName": "Leonardo Jose Velasquez Lozano",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Laboratorista",
        "uen": "Corporativo Técnico Y De I+d",
        "process": "Calidad",
        "area": "Calidad",
        "fechaIngreso": "6/9/2023 4:59:05 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012008",
        "document": "1127600859",
        "fullName": "Randy Alexis Chara Aguilar",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Auxiliar De Despachos",
        "uen": "Cadena De Suministro",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/9/2023 5:58:51 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012006",
        "document": "1412780",
        "fullName": "Darwin Jose Arnaldi Briceño",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Industrial Servicios Compartidos",
        "area": "Industrial Servicios Compartidos",
        "fechaIngreso": "6/9/2023 5:58:52 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012003",
        "document": "1114901298",
        "fullName": "Julian David Acosta Lozada",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Operario De Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/9/2023 5:58:53 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011976",
        "document": "1095932650",
        "fullName": "Leonardo Diaz Quintero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Planeador De Operaciones",
        "uen": "Logistica",
        "process": "Logistica",
        "area": "Logistica Administrativa",
        "fechaIngreso": "6/9/2023 3:28:59 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011901",
        "document": "1192800506",
        "fullName": "Fredy  Hernandez Duarte",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "6/9/2023 3:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011926",
        "document": "1090518312",
        "fullName": "Fabio Andres Villamizar Orozco",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "6/9/2023 3:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011924",
        "document": "1102549238",
        "fullName": "Leydi Jhoanna Moreno Ortiz",
        "company": "Distraves",
        "regional": "Arat",
        "jobTitle": "Bodeguero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "6/9/2023 3:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011899",
        "document": "1085040767",
        "fullName": "Yorman Yair Bandera Lozano",
        "company": "Distraves",
        "regional": "Loss",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "6/9/2023 3:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011908",
        "document": "1043665357",
        "fullName": "Kevin Gabriel Gonzalez Cuello",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Aprendiz Sena",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "6/9/2023 3:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012016",
        "document": "1101388000",
        "fullName": "Julian Jose Correa Zabaleta",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/9/2023 3:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011949",
        "document": "1096188140",
        "fullName": "Luis Francisco Leon Lozano",
        "company": "Distraves",
        "regional": "Barc",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "6/9/2023 3:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012020",
        "document": "1065881638",
        "fullName": "Jhon Fredy Contreras Gutierrez",
        "company": "Distraves",
        "regional": "Lebr",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Granja Engorde",
        "area": "Granja Engorde",
        "fechaIngreso": "6/9/2023 3:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012009",
        "document": "1044625477",
        "fullName": "Javier Antonio Bautista Restrepo",
        "company": "Distraves",
        "regional": "Barr",
        "jobTitle": "Auxiliar Logistica",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/9/2023 3:59:01 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011922",
        "document": "74186326",
        "fullName": "Franklin Aurelio Barrera Cely",
        "company": "Distraves",
        "regional": "Duit",
        "jobTitle": "Coordinador Zona",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "6/9/2023 3:59:02 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012001",
        "document": "1000098875",
        "fullName": "Daniela  Rivera Diaz",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/9/2023 7:02:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012022",
        "document": "1095951461",
        "fullName": "Jorge Andres Cubillos Camargo",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Planta Aba",
        "area": "Planta Aba",
        "fechaIngreso": "6/9/2023 7:02:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012018",
        "document": "1007241112",
        "fullName": "Mariana  Jaramillo Cadavid",
        "company": "Solla",
        "regional": "Bell",
        "jobTitle": "Estudiante Practica",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/9/2023 7:02:27 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01587",
        "document": "1085320125",
        "fullName": "Erick Jhonathan Timaran Carlosama",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/9/2023 7:31:08 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012027",
        "document": "1090518099",
        "fullName": "Nelson Yesid Mogollon Tarazona",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012032",
        "document": "1101696331",
        "fullName": "Yulmer Jose Montaguth Niño",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012037",
        "document": "1006723760",
        "fullName": "Mildy Carina Ospina Elecue",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012044",
        "document": "1005653849",
        "fullName": "Alfredo  Ulloa Manosalva",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012031",
        "document": "1098711707",
        "fullName": "Brayam  Prada Poveda",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012043",
        "document": "1148710320",
        "fullName": "Kevin Habit Leal Camacho",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012030",
        "document": "1098809112",
        "fullName": "Oscar Javier Merchan Reatiga",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012029",
        "document": "1092387033",
        "fullName": "Juan Carlos Moron Villegas",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Procesados",
        "area": "Procesados",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012042",
        "document": "1005331913",
        "fullName": "Brayan Ferney Tausa Lizcano",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012048",
        "document": "1096254306",
        "fullName": "Angie Carolina Valenzuela Osorio",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012028",
        "document": "1005540612",
        "fullName": "Kevin Julian Caballero Carvajal",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012034",
        "document": "1102349018",
        "fullName": "Isaias  Ramirez Rivera",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012038",
        "document": "1005330424",
        "fullName": "Juliana Paola Briceño Cuadros",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01288",
        "document": "1007189747",
        "fullName": "Carlos Saul Vega Vega",
        "company": "Distraves-gente Util",
        "regional": "",
        "jobTitle": "Operario Planta",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/10/2023 4:01:14 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012045",
        "document": "1005542150",
        "fullName": "Nestor Enrique Parra Romero",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012041",
        "document": "1193127890",
        "fullName": "Michael Yesid Rodriguez Galindo",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Beneficio",
        "area": "Beneficio",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012035",
        "document": "1100893914",
        "fullName": "Jhon Fredy Rojas Gutierrez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Operario Planta",
        "uen": "Produccion",
        "process": "Postproceso",
        "area": "Postproceso",
        "fechaIngreso": "6/10/2023 4:01:13 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012049",
        "document": "1005651110",
        "fullName": "Gisela Fernanda Diaz Amaya",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/13/2023 4:41:55 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012050",
        "document": "1030527308",
        "fullName": "Luisa Fernanda Diaz Bautista",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/13/2023 4:41:55 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011966",
        "document": "16943753",
        "fullName": "Carlos Andres Sanchez Gonzalez",
        "company": "Galponsas",
        "regional": "Cand",
        "jobTitle": "Supervisor De Ventas",
        "uen": "Comercial",
        "process": "Dirección Comercial",
        "area": "Dirección Comercial",
        "fechaIngreso": "6/13/2023 1:10:30 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10005425",
        "document": "1098753709",
        "fullName": "Diego Fernando Vasquez Duran",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Aprendiz Universitario",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Aprendices Etapa Lectiva",
        "fechaIngreso": "6/13/2023 3:10:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011999",
        "document": "1102352810",
        "fullName": "Carlos Alberto Romero Rodriguez",
        "company": "Distraves",
        "regional": "Pied",
        "jobTitle": "Supervisor Despachos",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/13/2023 3:10:36 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012051",
        "document": "1007898434",
        "fullName": "Slendy Viviana Arevalo Vega",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Estudiante Sena",
        "uen": "Corporativo Talento Humano",
        "process": "Talento Humano",
        "area": "Aprendices",
        "fechaIngreso": "6/13/2023 7:13:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012054",
        "document": "43609088",
        "fullName": "Paola Andrea Cardona Lopez",
        "company": "Solla",
        "regional": "Itag",
        "jobTitle": "Coordinador Sap",
        "uen": "Corporativo Financiero Y De Ti",
        "process": "Ti",
        "area": "Sap",
        "fechaIngreso": "6/13/2023 7:13:21 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012060",
        "document": "80175930",
        "fullName": "Harold Andres Castro Arrieta",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "6/14/2023 3:42:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012062",
        "document": "1015394116",
        "fullName": "Andres Felipe Arcila Gonzalez",
        "company": "Distraves",
        "regional": "Bogo",
        "jobTitle": "Vendedor Geografico",
        "uen": "Comercial",
        "process": "Zona Centro",
        "area": "Zona Centro",
        "fechaIngreso": "6/14/2023 3:42:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012059",
        "document": "1005340625",
        "fullName": "Carlos Alberto Gualdron Sanabria",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Asesor Call Center",
        "uen": "Comercial",
        "process": "Puntos De Venta",
        "area": "Puntos De Venta",
        "fechaIngreso": "6/14/2023 3:42:42 AM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "X01589",
        "document": "1085329979",
        "fullName": "Duvan Alirio Cuastumal Potosi",
        "company": "Solla-proservis",
        "regional": "",
        "jobTitle": "Auxiliar Produccion",
        "uen": "",
        "process": "",
        "area": "",
        "fechaIngreso": "6/14/2023 2:20:45 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012068",
        "document": "1095794899",
        "fullName": "Cristhian  Ramirez Leon",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Galponero Oficios Varios",
        "uen": "Gerencia Pecuaria",
        "process": "Gerencia Pecuaria",
        "area": "Gerencia Pecuaria",
        "fechaIngreso": "6/14/2023 6:53:54 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10010059",
        "document": "1005553184",
        "fullName": "Heyenverth Stiven Villamizar Delgado",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Comercial",
        "uen": "Comercial",
        "process": "Zona Oriente",
        "area": "Zona Oriente",
        "fechaIngreso": "6/14/2023 8:51:15 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10011798",
        "document": "1017224210",
        "fullName": "Jhon Albert Muñoz Higuita",
        "company": "Distraves",
        "regional": "Mede",
        "jobTitle": "Domiciliario",
        "uen": "Logistica",
        "process": "Cedis",
        "area": "Cedis",
        "fechaIngreso": "6/15/2023 3:52:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10012058",
        "document": "1095939493",
        "fullName": "Yenly Juliet Molina Baez",
        "company": "Distraves",
        "regional": "Giro",
        "jobTitle": "Auxiliar Gestion Documental",
        "uen": "Gerencia General",
        "process": "Talento Humano",
        "area": "Centro De Gestion Documental",
        "fechaIngreso": "6/15/2023 3:52:34 PM +00:00"
    },
    {
        "accountEnable": "True",
        "code": "10007399",
        "document": "1005236817",
        "fullName": "Justin Arley Alvarez Campos",
        "company": "Solla",
        "regional": "Giro",
        "jobTitle": "Auxiliar Produccion",
        "uen": "Corporativo Producción Y Operaciones",
        "process": "Producción",
        "area": "Producción",
        "fechaIngreso": "6/15/2023 8:37:10 PM +00:00"
    }
]

export default { employeeList }