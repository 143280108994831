import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        blackListNotifications: 0,
        restrictedPeopleNotifications: 0,
        parkNotifications: [],
        parkDevices: [],
        visionNotifications: 0,
        registryNotifications: [],
        lunchModule: false,
        restrictedPopup: false,
    },
    mutations: {
        asignParkdevices (state, list) {
            state.parkDevices = list;
        },

        asignBlackListNotifications (state, count) {
            state.blackListNotifications = count;
        },

        asignRestrictedPeopleNotifications (state, count) {
            state.restrictedPeopleNotifications = count;
        },

        asignParkNotifications (state, list) {
            state.parkNotifications = list;
        },

        asignVisionNotifications (state, count) {
            state.visionNotifications = count;
        },

        pushVisionEvent(state, event) {
            state.visionNotifications.push(event)
        },

        removeVisionNotification(state, notificationsToRemove) {
            state.visionNotifications = state.visionNotifications - notificationsToRemove;
        },

        asignRegistryNotifications (state, list) {
            state.registryNotifications = list;
        },

        removeRegistryAsigned(state, index) {
            state.registryNotifications.splice(index, 1)
        },

        asignLunchModuleStatus(state, estado) {
            state.lunchModule = estado;
        },

        asignRestrictedPopupStatus(state, estado) {
            state.restrictedPopup = estado;
        },
    },
    actions: {},
    getters: {
        parkDevices (state) {
            return state.parkDevices;
        },

        blackListNotifications (state) {
            return state.blackListNotifications;
        },

        restrictedPeopleNotifications (state) {
            return state.restrictedPeopleNotifications;
        },

        parkNotificationTotal (state) {
            return state.parkNotifications.length;
        },

        visionNotifications (state) {
            return state.visionNotifications;
        },

        registryNotifications (state) {
            return state.registryNotifications;
        },

        lunchModuleStatus (state) {
            return state.lunchModule;
        },

        restrictedPopupStatus (state) {
            return state.restrictedPopup;
        }
    },
})
