import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './routes';
//import socketio from 'socket.io-client';
//import VueSocketIO from 'vue-socket.io';
import store from './store';

import * as VueGoogleMaps from 'vue2-google-maps'

import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

import '@aws-amplify/ui-components';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Vue.config.ignoredElements = [/amplify-\w*/];

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBmMeFoATSSZa0GqpbEo0hS8Co3sEB2L5g',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    // If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  // autobindAllEvents: false,
 
  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  // installComponents: true,
})

applyPolyfills().then(() => {
  defineCustomElements(window);
});

/* const SocketInstance = socketio.connect('https://devback2.vanguardtech.solutions', {
  cors: {
    origin: '*'
  },
  //transports: ['polling'] // TODO: Posible solucion, falta evaluar la solucion de configuracion de ngnex
}); 

Vue.use(new VueSocketIO({
    debug: true,
    connection: SocketInstance
})); */

Vue.config.productionTip = false;

export const bus = new Vue();

Vue.prototype.$bus = bus;

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
