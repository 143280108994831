<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  data() {
    return {
      intervalChart: "",
    };
  },
  props: {
    label: {
      type: String,
    },
    chartData: {
      type: Object,
    },
  },
  methods: {
    makeChart() {
        const dataLabels = this.chartData.allData.map(t => t.time);
        const dataPM1Values = this.chartData.allData.map(t => t.pm1);
        const dataPM25Values = this.chartData.allData.map(t => t.pm25);
        const dataPM10Values = this.chartData.allData.map(t => t.pm10);

        this.renderChart(
        {          
          labels: dataLabels,
          datasets: [
            {
              label: this.chartData.datasetLabels[0],
              data: dataPM1Values,
              borderColor: "#4FC3F7",
              backgroundColor: "rgb(79,195,247, 0.2)",
              fill: false,
              pointStyle: 'line'
            },
            {
              label: this.chartData.datasetLabels[1],
              data: dataPM25Values,
              borderColor: "#FF3D67",
              backgroundColor: "rgb(79,195,247, 0.2)",
              fill: false,
              pointStyle: 'line'
            },
            {
              label: this.chartData.datasetLabels[2],
              data: dataPM10Values,
              borderColor: "#FFCD56",
              backgroundColor: "rgb(79,195,247, 0.2)",
              fill: false,
              pointStyle: 'line'
            }
          ],
        },
        {
          maintainAspectRatio: false,
          animation: {
            duration: 0, // general animation time
          },
          hover: {
            animationDuration: 0, // duration of animations when hovering an item
          },
          responsiveAnimationDuration: 0,
          responsive: true,
          elements: {
            line: {
              tension: 0.2,
            },
          },
          legend: {
            labels: {
              fontColor: "#363636",
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor:  "#363636",
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  fontColor:  "#363636",
                  labelString:
                    this.label
                },
                ticks: {
                  fontColor: "rgb(204, 204, 204)",
                  beginAtZero: false,
                  callback: function (value) {
                    return value;
                  },
                },
              },
            ],
          },
          title: {
            display: false,
          },
          tooltips: {
            enable: true,
            mode: "single",
            callbacks: {
              title: function (tooltipItems) {
                return "Hora: " + tooltipItems[0].xLabel;
              },
            },
          },
        }
      );
    },

    callDataChartEvery() {
      this.intervalChart = window.setInterval(
        function () {
          this.makeChart();
        }.bind(this),
        3000
      );
    },
  },
  mounted() {
    this.callDataChartEvery();
    this.makeChart();
  },
  beforeDestroy() {
    clearInterval(this.intervalChart);
  },
};
</script>
