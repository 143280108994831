<template>
  <amplify-authenticator>
    <div>
      Logged In!!!!
      <amplify-sign-out></amplify-sign-out>
    </div>
  </amplify-authenticator>
</template>

<script>
import "@aws-amplify/ui-components";
import { AmplifyEventBus } from 'aws-amplify';
export default {
  name: "amplifyAuthTest",
  data: () => ({
  }),
  methods: {
    /* async signUp() {  
      try {
          const { user } = await Auth.signUp({
              username,
              password,
              attributes: {
                  email,          // optional
                  phone_number,   // optional - E.164 number convention
                  // other custom attributes 
              }
          });
          console.log(user);
      } catch (error) {
          console.log('error signing up:', error);
      }
    } */
  },
  created() {
    AmplifyEventBus.$on('authState', info => {
      if (info === 'signIn') {
        console.log("HOLA AUTENTICADO")
      } else {
        console.log("HOLA SIN AUTENTICAR")
      }
    })
  }
}
</script>

<style>
</style>