<template>
  <v-container fluid>
    <div v-if="userRole === 'SUPER'" class="ma-0 pa-0">
      <!-- // * Monitoring Modal -->
      <div class="text-center" v-if="dialogMonitoringDevice == true">
        <v-dialog v-model="dialogMonitoringDevice" width="70%">
          <v-card rounded="xl">
            <v-card-title
              class="
                white--text
                headline
                d-flex
                justify-center
                pb-0
              "
            >
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="my-0 py-0">
                  <div class="text-center">Monitoreo</div>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <div class="text-center">
                    <v-btn
                      class="mx-1"
                      @click="clearMonitoringSearchObj() ; tempTimeSearch = 'm' ; getDeviceStatus(dvcSelected, tempTimeSearch)"
                      rounded
                      small
                      :color="tempTimeSearch == 'm' ? 'grey darken-3' : 'grey darken-1'"
                      dark
                      style="text-transform: lowercase;"
                    >
                      5m
                    </v-btn>
                    <v-btn
                      class="mx-1"
                      @click="clearMonitoringSearchObj() ; tempTimeSearch = 'h' ; getDeviceStatus(dvcSelected, tempTimeSearch)"
                      rounded
                      small
                      :color="tempTimeSearch == 'h' ? 'grey darken-3' : 'grey darken-1'"
                      dark
                      style="text-transform: lowercase;"
                    >
                      1h
                    </v-btn>
                    <v-btn
                      class="mx-1"
                      @click="clearMonitoringSearchObj() ; tempTimeSearch = 'd' ; getDeviceStatus(dvcSelected, tempTimeSearch)"
                      rounded
                      small
                      :color="tempTimeSearch == 'd' ? 'grey darken-3' : 'grey darken-1'"
                      dark
                      style="text-transform: lowercase;"
                    >
                      1d
                    </v-btn>
                    <v-btn
                      class="mx-1"
                      @click="clearMonitoringSearchObj() ; tempTimeSearch = 's' ; getDeviceStatus(dvcSelected, tempTimeSearch)"
                      rounded
                      small
                      :color="tempTimeSearch == 's' ? 'grey darken-3' : 'grey darken-1'"
                      dark
                      style="text-transform: lowercase;"
                    >
                      1w
                    </v-btn>
                    <v-btn
                      class="mx-1"
                      @click="openInterval == true ? clearMonitoringSearchObj() : openInterval = true ; tempTimeSearch = 'interval'"
                      rounded
                      small
                      :color="tempTimeSearch == 'interval' ? 'grey darken-3' : 'grey darken-1'"
                      dark
                      style="text-transform: lowercase;"
                    >
                      <i class="fal fa-calendar-alt"></i>
                    </v-btn>
                  </div>
                </v-col>
                <v-col v-if="openInterval" class="pb-0" cols="12" md="12">
                  <v-row class="ma-0 pa-0 text-center">
                    <v-col cols="12" class="text-center py-0">
                      <label><small>Se recomienda utilizar el filtro especifico para intervalos de tiempo cortos para obtener una visualización mas clara y un mejor performance</small></label>
                    </v-col>
                    <v-col cols="12" md="3" class="pb-0">
                      <v-text-field
                        v-model="monitoringFilter.from"
                        type="date"
                        data-date-format="yyyy-mm-dd"
                        label="Desde"
                        outlined
                        dark
                        color="secondary"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1" class="pb-0 px-0">
                      <v-text-field
                        v-model="monitoringFilter.fromTime"
                        id="startTimeAlarm"
                        type="time"
                        name="startTimeAlarm"
                        outlined
                        dark
                        color="secondary"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="pb-0">
                      <v-text-field
                        v-model="monitoringFilter.to"
                        type="date"
                        data-date-format="yyyy-mm-dd"
                        label="Hasta"
                        outlined
                        dark
                        color="secondary"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1" class="pb-0 px-0">
                      <v-text-field
                        v-model="monitoringFilter.toTime"
                        type="time"
                        id="startTimeAlarm"
                        name="startTimeAlarm"
                        outlined
                        dark
                        color="secondary"
                        hide-details="auto"
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" class="pb-0">
                      <v-select
                        v-model="monitoringFilter.type"
                        :items="chartTypeLabel"
                        label="Visualización en:"
                        outlined
                        dense
                        dark
                        color="secondary"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="1" class="pb-0">
                      <v-btn
                        class="mx-1"
                        color="secondary"
                        small
                        dark
                        @click="customizableSearch(dvcSelected, monitoringFilter)"
                      >
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8">
                  <div>
                    <p class="text-center mb-4">Temperatura Dispositivo</p>
                    <line-chart
                      class="graphic-container"
                      label="Temperatura"
                      :chartData="{
                        shortUnitMeasure: '°C',
                        allData: ethList,
                        valueName: 'conn_date',
                        scaleToShow: scaleToShow,
                        colorHex: '#FFAB00',
                        colorRGB: 'rgba(255, 171, 0, 0.2)'
                      }"
                    ></line-chart>
                  </div><!-- 
                  <div class="text-center">
                      <v-btn
                        class="mx-1"
                        @click="tempTimeSearch = 'm' ; getDeviceStatus(dvcSelected.mac, tempTimeSearch)"
                        rounded
                        small
                        :color="tempTimeSearch == 'm' ? 'grey darken-3' : 'grey darken-1'"
                        dark
                        style="text-transform: lowercase;"
                      >
                        5m
                      </v-btn>
                      <v-btn
                        class="mx-1"
                        @click="tempTimeSearch = 'h' ; getDeviceStatus(dvcSelected.mac, tempTimeSearch)"
                        rounded
                        small
                        :color="tempTimeSearch == 'h' ? 'grey darken-3' : 'grey darken-1'"
                        dark
                        style="text-transform: lowercase;"
                      >
                        1h
                      </v-btn>
                      <v-btn
                        class="mx-1"
                        @click="tempTimeSearch = 'd' ; getDeviceStatus(dvcSelected.mac, tempTimeSearch)"
                        rounded
                        small
                        :color="tempTimeSearch == 'd' ? 'grey darken-3' : 'grey darken-1'"
                        dark
                        style="text-transform: lowercase;"
                      >
                        1d
                      </v-btn>
                      <v-btn
                        class="mx-1"
                        @click="tempTimeSearch = 's' ; getDeviceStatus(dvcSelected.mac, tempTimeSearch)"
                        rounded
                        small
                        :color="tempTimeSearch == 's' ? 'grey darken-3' : 'grey darken-1'"
                        dark
                        style="text-transform: lowercase;"
                      >
                        1s
                      </v-btn>
                    </div> -->
                  <div>
                    <p class="text-center mt-2 mb-4">Conexión</p>
                    <line-chart
                      class="graphic-container"
                      label="Conexión"
                      :chartData="{
                        shortUnitMeasure: 'ms',
                        allData: ethList,
                        valueName: 'conn_date',
                        scaleToShow: scaleToShow,
                        colorHex: '#4FC3F7',
                        colorRGB: 'rgba(79, 195, 247, 0.2)'
                      }"
                    ></line-chart>
                    <!-- <div class="text-center">
                      <v-btn
                        class="mx-1"
                        @click="ethTimeSearch = 'm' ; getDeviceStatus(dvcSelected.mac, ethTimeSearch)"
                        rounded
                        small
                        :color="ethTimeSearch == 'm' ? 'grey darken-3' : 'grey darken-1'"
                        dark
                        style="text-transform: lowercase;"
                      >
                        5m
                      </v-btn>
                      <v-btn
                        class="mx-1"
                        @click="ethTimeSearch = 'h' ; getDeviceStatus(dvcSelected.mac, ethTimeSearch)"
                        rounded
                        small
                        :color="ethTimeSearch == 'h' ? 'grey darken-3' : 'grey darken-1'"
                        dark
                        style="text-transform: lowercase;"
                      >
                        1h
                      </v-btn>
                      <v-btn
                        class="mx-1"
                        @click="ethTimeSearch = 'd' ; getDeviceStatus(dvcSelected.mac, ethTimeSearch)"
                        rounded
                        small
                        :color="ethTimeSearch == 'd' ? 'grey darken-3' : 'grey darken-1'"
                        dark
                        style="text-transform: lowercase;"
                      >
                        1d
                      </v-btn>
                      <v-btn
                        class="mx-1"
                        @click="ethTimeSearch = 's' ; getDeviceStatus(dvcSelected.mac, ethTimeSearch)"
                        rounded
                        small
                        :color="ethTimeSearch == 's' ? 'grey darken-3' : 'grey darken-1'"
                        dark
                        style="text-transform: lowercase;"
                      >
                        1s
                      </v-btn>
                    </div> -->
                  </div>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="text-center">
                    <p class="text-center mb-2">CPU</p>
                    <v-progress-circular
                      :size="150"
                      :width="10"
                      :value="this.ethList && this.ethList.length > 0 && this.ethList[this.ethList.length - 1].cpu_status ? this.ethList[this.ethList.length - 1].cpu_status : 0"
                      color="teal"
                    >
                      <p class="text-h5 cyan--text" >{{this.ethList && this.ethList.length > 0 && this.ethList[this.ethList.length - 1].cpu_status ? this.ethList[this.ethList.length - 1].cpu_status + '%' : '---' }}</p>
                    </v-progress-circular>
                  </div>
                  <div class="text-center mt-10">
                    <p class="text-center mb-2">Memoria</p>
                    <v-progress-circular
                      :size="150"
                      :width="10"
                      :value="this.ethList && this.ethList.length > 0 && this.ethList[this.ethList.length - 1].memory_status ? this.ethList[this.ethList.length - 1].memory_status : 0"
                      color="cyan lighten-3"
                    >
                      <p class="text-h5 cyan--text" >{{this.ethList && this.ethList.length > 0 && this.ethList[this.ethList.length - 1].memory_status ? this.ethList[this.ethList.length - 1].memory_status + '%' : '---' }}</p>
                    </v-progress-circular>
                  </div>
                  <div class="text-center pt-6">
                    <label>Ultima conexión: <strong>{{ dvcSelected.last_connection.substring(0, 10) + " " + dvcSelected.last_connection.substring(11, 16) }}</strong></label>
                    <label>ID Ultima Actualización: <strong>{{dvcSelected.campus != null ? dvcSelected.campus.last_update : 'N/R'}}</strong></label>
                    <label>Estado Cámara: 
                      <strong :class="this.ethList && this.ethList.length > 0 && this.ethList[this.ethList.length - 1].camera_status == true ? 'success--text' : 'red--text'">
                        {{ this.ethList && this.ethList.length > 0 && this.ethList[this.ethList.length - 1].camera_status == true ? "CONECTADA" : "DESCONECTADA" }}
                      </strong>
                    </label>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <!-- // * Create Master Dialog -->
      <div class="text-center" v-if="dialogCreateDevice == true">
        <v-dialog v-model="dialogCreateDevice">
          <v-card>
            <v-card-title
              class="
                white--text
                headline
                d-flex
                justify-center
              "
            >
              CREAR DISPOSITIVO
            </v-card-title>
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newDevice.MAC"
                    label="MAC"
                    outlined
                    dense
                    dark
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newDevice.assetName"
                    label="Nombre"
                    outlined
                    dense
                    dark
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newDevice.city"
                    label="Ciudad"
                    outlined
                    dense
                    dark
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="newDevice.company"
                    :items="companies"
                    item-text="name"
                    item-value="uuid"
                    label="Compañia"
                    outlined
                    dense
                    dark
                    color="secondary"
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newDevice.IP"
                    label="IP"
                    outlined
                    dense
                    dark
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newDevice.vpnIP"
                    label="VPN IP"
                    outlined
                    dense
                    dark
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    color="primary"
                    class="mr-4"
                    small
                    rounded
                    @click="createMaster(newDevice)"
                  >
                    Crear Dispositivo
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <!-- // * Create Slave Dialog -->
      <div class="text-center" v-if="dialogCreateSlave == true">
        <v-dialog v-model="dialogCreateSlave">
          <v-card>
            <v-card-title
              class="
                white--text
                headline
                d-flex
                justify-center
              "
            >
              CREAR ESCLAVO
            </v-card-title>
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newSlave.MAC"
                    label="MAC"
                    outlined
                    dense
                    dark
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="newSlave.masterMac"
                    :items="masters"
                    item-text="assetName"
                    item-value="mac"
                    label="Master"
                    outlined
                    dense
                    dark
                    color="secondary"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="newSlave.vpnIP"
                    label="VPN IP"
                    outlined
                    dense
                    dark
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    color="primary"
                    class="mr-4"
                    small
                    rounded
                    @click="createSlave(newSlave)"
                  >
                    Crear Esclavo
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <!-- // * Create Slaves's Cams -->
      <div class="text-center" v-if="dialogCreateSlaveCam == true">
        <v-dialog v-model="dialogCreateSlaveCam">
          <v-card>
            <v-card-title
              class="
                white--text
                headline
                d-flex
                justify-center
              "
            >
              AGREGAR CÁMARA
            </v-card-title>
            <v-card-text>
              <v-row class="text-center">
                <!-- <v-col cols="12" md="6">
                  <v-select
                    v-model="newCam.slave"
                    :items="slaves"
                    item-text="mac"
                    item-value="assetkey"
                    label="Esclavo"
                    outlined
                    dense
                    dark
                    color="secondary"
                    return-object
                  ></v-select>
                </v-col> -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newCam.name"
                    label="Nombre"
                    outlined
                    dense
                    dark
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newCam.place"
                    label="Lugar"
                    outlined
                    dense
                    dark
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="newCam.rtsp"
                    label="RTSP"
                    outlined
                    dense
                    dark
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    color="primary"
                    class="mr-4"
                    small
                    rounded
                    @click="createSlaveCam(newCam)"
                  >
                    Crear Cámara
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div class="text-center" v-if="dialog == true">
        <v-overlay :opacity="1" :value="dialog">
          <v-dialog v-model="dialog" persistent width="900">
            <v-card>
              <v-card-title
                class="
                  black--text
                  headline
                  grey
                  lighten-2
                  d-flex
                  justify-center
                "
              >
                DISPOSITIVO {{ dvcSelected.mac }}
              </v-card-title>
              <v-card-text>
                <v-row class="text-center">
                  <v-col cols="12" md="4">
                    <p>
                      IP: <strong>{{ dvcSelected.ip }}</strong>
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      Org:
                      <strong>{{
                        dvcSelected.campus_id
                          ? dvcSelected.campus.name
                          : "Sin asignar"
                      }}</strong>
                    </p>
                    <v-select
                      v-if="modifyDvc"
                      v-model="dvcSelected.campus_id"
                      :items="editOrganizations"
                      item-text="name"
                      item-value="uuid"
                      dense
                      label="Organización"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      Tipo:
                      <strong>{{
                        dvcSelected.type_device.toUpperCase()
                      }}</strong>
                    </p>
                    <v-select
                      v-if="modifyDvc"
                      v-model="dvcSelected.type_device"
                      :items="deviceTypes"
                      dense
                      label="Tipo Dispositivo"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      Modo:
                      <strong>{{
                        dvcSelected.mode === "ENTRY" ? "ENTRADA" : "SALIDA"
                      }}</strong>
                    </p>
                    <v-select
                      v-if="modifyDvc"
                      v-model="dvcSelected.mode"
                      :items="modes"
                      item-text="Name"
                      item-value="ID"
                      dense
                      label="Modo"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p>
                      Ultima Conexión:
                      <strong>{{
                        dvcSelected.last_connection
                          ? dvcSelected.last_connection.slice(0, 10) +
                            " " +
                            dvcSelected.last_connection.slice(
                              11,
                              dvcSelected.last_connection.length - 8
                            )
                          : "---"
                      }}</strong>
                    </p>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p>
                      Estado:
                      <v-chip
                        :color="dvcSelected.status === true ? 'green' : 'error'"
                        text-color="white"
                        class="darken-3"
                        small
                      >
                        <small>{{
                          dvcSelected.status === true ? "ON" : "OFF"
                        }}</small>
                      </v-chip>
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      Nombre:
                      <strong>{{
                        dvcSelected.name != null
                          ? dvcSelected.name
                          : "Sin Nombrar"
                      }}</strong>
                    </p>
                    <v-text-field
                      v-if="modifyDvc"
                      label="Nombre"
                      v-model="dvcSelected.name"
                    ></v-text-field>
                  </v-col>
                  <!-- //* ------------------------------------- -->
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      VPN IP:
                      <strong>{{
                        dvcSelected.slave != null && dvcSelected.slave.vpn_ip
                          ? dvcSelected.slave.vpn_ip
                          : "Sin Asignar"
                      }}</strong>
                    </p>
                    <v-text-field
                      v-if="modifyDvc"
                      label="VPN IP"
                      v-model="dvcSelected.slave.vpn_ip"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      Lugar:
                      <strong>{{
                        dvcSelected.cam != null && dvcSelected.cam.place
                          ? dvcSelected.cam.place
                          : "Sin Asignar"
                      }}</strong>
                    </p>
                    <v-text-field
                      v-if="modifyDvc"
                      label="Lugar"
                      v-model="dvcSelected.cam.place"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="d-flex justify-center align-center"
                  >
                    <p v-if="!modifyDvc">
                      RTSP:
                      <strong>{{
                        dvcSelected.cam != null && dvcSelected.cam.rtsp
                          ? dvcSelected.cam.rtsp
                          : "Sin Asignar"
                      }}</strong>
                    </p>
                    <v-text-field
                      v-if="modifyDvc"
                      label="RTSP"
                      v-model="dvcSelected.cam.rtsp"
                    ></v-text-field>
                  </v-col>

                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex justify-center align-center"
                  >
                    <v-btn
                      v-if="!modifyDvc"
                      block
                      color="primary"
                      class="mr-4"
                      @click="modifyDvc = true"
                    >
                      MODIFICAR CAMPOS
                    </v-btn>
                    <v-btn
                      v-if="modifyDvc"
                      block
                      color="success"
                      class="mr-4"
                      @click="updateDevice(dvcSelected)"
                    >
                      ENVIAR CAMBIOS
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex justify-center align-center"
                  >
                    <v-btn
                      block
                      color="error"
                      class="mr-4"
                      @click="closeEditModel()"
                    >
                      CERRAR
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-overlay>
      </div>

      <div class="text-center" v-if="deleteDialog == true">
        <v-dialog v-model="deleteDialog" persistent width="700">
          <v-card>
            <v-card-title
              class="black--text headline grey lighten-2 d-flex justify-center"
            >
              ELIMINAR DISPOSITIVO
            </v-card-title>
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12">
                  <p class="text-subtitle-1">
                    Esta seguro que desea eliminar el dispositivo con MAC:
                    <strong>{{ dvcToDelete.mac }}</strong
                    >?
                  </p>
                  <p class="text-subtitle-1">
                    Si es así por favor confirme la MAC del dispositivo.
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="MAC"
                    v-model="MACVerification"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    block
                    color="primary"
                    class="mr-4"
                    @click="deleteDevice(dvcToDelete, MACVerification)"
                  >
                    ELIMINAR DISPOSITIVO
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    block
                    color="error"
                    class="mr-4"
                    @click="closeDeleteModel()"
                  >
                    CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div class="text-center" v-if="upgradeDialog == true">
        <v-dialog v-model="upgradeDialog" persistent width="700">
          <v-card>
            <v-card-title
              class="black--text headline grey lighten-2 d-flex justify-center"
            >
              ACTUALIZAR FW DISPOSITIVO
            </v-card-title>
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12">
                  <p class="text-subtitle-1 mb-1 pb-1">
                    Esta seguro que desea actualizar el firmware del dispositivo
                    con MAC:
                    <strong>{{ dvcToUpgrade.mac }}</strong
                    >?
                  </p>
                  <p class="text-subtitle-1 mb-1 pb-1">
                    *Una vez se actualice no podra hacer downgrade de versión
                    desde aquí*
                  </p>
                  <p class="text-subtitle-1 mb-1 pb-1">
                    Si desea continuar con la actualización confirme la MAC del
                    equipo, por favor.
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="MAC"
                    v-model="MACVerification"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    block
                    color="primary"
                    class="mr-4"
                    @click="upgradeFW(dvcToUpgrade, MACVerification)"
                  >
                    ACTUALIZAR DISPOSITIVO
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    block
                    color="error"
                    class="mr-4"
                    @click="closeDeleteModel()"
                  >
                    CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div class="text-center" v-if="logsDialog == true">
        <v-dialog v-model="logsDialog" persistent width="700">
          <v-card>
            <v-card-title style="background-color: #212734;"
              class="d-flex justify-center"
            >
              GENERAR LOGS
            </v-card-title>
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12" md="4">
                  <v-menu
                    v-model="datepickerDateLog"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateLogs"
                        label="Fecha de Busqueda"
                        readonly
                        outlined
                        dark
                        color="secondary"
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateLogs"
                      @input="datepickerDateLog = false"
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="start_time"
                    v-model="start_time"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time1"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startTimeLogs"
                        label="Hora desde"
                        prepend-inner-icon="fal fa-clock"
                        readonly
                        outlined
                        dark
                        dense
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="startTimeLogs"
                      scrollable
                      full-width
                      format="24hr"
                      @click:minute="$refs.start_time.save(time1)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="finish_time"
                    v-model="finish_time"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time2"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="finishTimeLogs"
                        label="Hora desde"
                        prepend-inner-icon="fal fa-clock"
                        readonly
                        outlined
                        dark
                        dense
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="finishTimeLogs"
                      scrollable
                      full-width
                      format="24hr"
                      @click:minute="$refs.finish_time.save(time2)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    block
                    color="success"
                    class="mr-4"
                    :loading="loadingLogsBtn" 
                    @click="generateLogsFile(dvcSelected, dateLogs, startTimeLogs, finishTimeLogs)"
                  >
                    Generar Archivo
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    block
                    color="error"
                    class="mr-4"
                    @click="logsDialog = false ; dvcSelected = null"
                  >
                    CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <v-row class="text-center">
        <v-col cols="12">
          <p class="grey--text font-weight-bold text-h3">Dispositivos</p>
          <div class="pa-0 ma-0">
            <v-icon v-if="!mapCard" @click="mapCard = !mapCard" class="grey--text pl-3">mdi-web-off</v-icon>
            <v-icon v-if="!mapCard" class="secondary--text pl-3">mdi-table</v-icon>
            <v-icon v-if="mapCard" class="secondary--text pl-3">mdi-web</v-icon>
            <v-icon v-if="mapCard" @click="mapCard = !mapCard" class="grey--text pl-3">mdi-table-off</v-icon>
          </div>
        </v-col>
      </v-row>
      <!-- <v-row class="px-5 py-0 my-0">
        <v-col cols="12" md="12" class="d-flex justify-end">
          <v-btn
            small
            color="primary"
            @click="dialogCreateDevice = true"
          >
            Crear dispositivo
          </v-btn>
          <v-btn
            small
            color="secondary"
            @click="dialogCreateSlave = true"
            class="ml-3"
          >
            Crear Esclavo
          </v-btn>
          <v-btn
            small
            color="primary"
            @click="dialogCreateSlaveCam = true"
            class="ml-3"
          >
            Crear Cámara Esclavo
          </v-btn>
        </v-col>
      </v-row> -->
      <v-row v-if="!mapCard" class="px-5">
        <v-col cols="12" :md="singleCompany && singleCompany.uuid != '0000' ? 5 : 10">
          <v-select
            v-model="singleCompany"
            :items="allCompanies"
            item-text="name"
            item-value="uuid"
            label="Organización"
            outlined
            dense
            dark
            color="secondary"
            return-object
            @change="changeOrgTrigger"
          ></v-select>
        </v-col>
        <v-col cols="12" md="5" v-if="singleCompany && singleCompany.uuid != '0000'">
          <v-select
            v-model="org"
            :items="singleCompany.Campus"
            item-text="name"
            item-value="uuid"
            label="Punto"
            outlined
            dense
            dark
            color="secondary"
            :disabled="!singleCompany"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            outlined
            block
            small
            dense
            color="secondary"
            class="mt-2"
            @click="getDevices(singleCompany, org)"
          >
            buscar
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!mapCard" class="px-5">
        <v-col cols="12">
          <v-card elevation="5">
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12" v-if="!devices"
                  ><p>Filtre sus dispositivos.</p></v-col
                >
                <v-col cols="12" v-if="devices && devices.length == 0">
                  <p v-if="loading == false">
                    No hay dispositivos que mostrar.
                  </p>
                  <span v-if="loading == true">
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      :indeterminate="loading"
                    >
                    </v-progress-circular>
                      <p class="pt-2 text-subtitle-2">Cargando dispositivos...</p>
                  </span>
                </v-col>
                <v-col cols="12" v-if="devices && devices.length > 0">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Filtrar dispositivos"
                    single-line
                    hide-details
                    class="mb-5"
                  ></v-text-field>
                  <v-layout child-flex>
                    <v-data-table
                      :headers="headers"
                      :items="devices"
                      :search="search"
                      :items-per-page="5"
                      :header-props="{
                        mobile: true,
                      }"
                      :footer-props="{
                        'items-per-page-options': [50],
                      }"
                      class="elevation-1"
                      fixed-header
                      no-results-text="No hay dispositivos con el dato ingresado. "
                    >
                      <template v-slot:[`item.campus.name`]="{ item }">
                        <div v-if="item.campus_id">
                          <span>{{ item.campus.name }}</span>
                        </div>
                        <span v-if="!item.campus_id">Sin asignar</span>
                      </template>
                      <template v-slot:[`item.name`]="{ item }">
                        <span>{{
                          item.name !== null ? item.name : "Sin nombrar"
                        }}</span>
                      </template>
                      <template v-slot:[`item.last_connection`]="{ item }">
                        <v-chip
                          small
                          :color="
                            validateDvcConnection(
                              item.last_connection.slice(0, 10) +
                                ' ' +
                                item.last_connection.slice(
                                  11,
                                  item.last_connection.length - 8
                                )
                            ) === true
                              ? 'green darken-2'
                              : 'error darken-2'
                          "
                          text-color="white"
                          class="darken-1 px-1"
                        >
                        <span class="ml-3">{{
                          item.last_connection
                            ? item.last_connection.slice(0, 10) +
                              " " +
                              item.last_connection.slice(
                                11,
                                item.last_connection.length - 8
                              )
                            : "---"
                        }}</span>
                          <v-icon
                            color="white"
                            size="20"
                            class="text-center"
                          >
                            {{ validateDvcConnection(
                                item.last_connection.slice(0, 10) +
                                  ' ' +
                                  item.last_connection.slice(
                                    11,
                                    item.last_connection.length - 8
                                  )
                              ) ? 'mdi-access-point' : 'mdi-access-point-off'
                            }}
                          </v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:[`item.Status`]="{ item }">
                        <div v-if="item.lastHeartbeats && item.lastHeartbeats.length > 0 && validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8))">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                :color="item.lastHeartbeats[0].camera_status == true ? 'green' : ''"
                                text-color="white"
                                class="mr-2 px-0"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon
                                  class="text-center"
                                  color="white"
                                  size="20"
                                >
                                  {{ item.lastHeartbeats[0].camera_status == true ? 'mdi-camera' : 'mdi-camera-off'}}
                                </v-icon>
                              </v-chip>
                            </template>
                            <span>{{ item.lastHeartbeats[0].camera_status == true ? 'Cámara con conexión' : 'Cámara sin conexión' }}</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                :color="heatDeviceResult(item.lastHeartbeats[0], 'color', item)"
                                text-color="white"
                                class="darken-1 mx-0 px-0"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon
                                  class="text-center"
                                  color="white"
                                  size="20"
                                >
                                  {{ heatDeviceResult(item.lastHeartbeats[0], 'icon', item) }}
                                </v-icon>
                              </v-chip>
                            </template>
                            <span v-if="item.lastHeartbeats[0].temp_status <= 58.9">Temperatura estable: {{ item.lastHeartbeats[0].temp_status }}</span>
                            <span v-if="item.lastHeartbeats[0].temp_status > 59 && item.lastHeartbeats[0].temp_status <= 68.9">Temperatura en aumento: {{ item.lastHeartbeats[0].temp_status }}</span>
                            <span v-if="item.lastHeartbeats[0].temp_status >= 69">Temperatura muy alta: {{ item.lastHeartbeats[0].temp_status }}</span>
                          </v-tooltip>
                        </div>
                        <div v-else>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                text-color="white"
                                class="mr-2 px-0"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon
                                  class="text-center"
                                  color="white"
                                  size="20"
                                >
                                  mdi-camera-off
                                </v-icon>
                              </v-chip>
                            </template>
                            <span>Sin datos para validar</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                text-color="white"
                                class="darken-1 mx-0 px-0"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon
                                  class="text-center"
                                  color="white"
                                  size="20"
                                >
                                  mdi-thermometer
                                </v-icon>
                              </v-chip>
                            </template>
                            <span>Sin datos para validar</span>
                          </v-tooltip>
                        </div>
                      </template>
                      <template v-slot:[`item.options`]="{ item }">
                        <v-icon
                          class="text-center pr-1"
                          color="white"
                          title="Monitoreo"
                          @click="openGate(item)"
                          style="font-size: 18px"
                        >
                          fal fa-door-open
                        </v-icon>
                        <v-icon
                          class="text-center"
                          color="secondary"
                          title="Monitoreo"
                          @click="openMonitoring(item)"
                        >
                          mdi-chart-areaspline
                        </v-icon>
                        <v-icon
                          class="text-center"
                          color="primary"
                          title="Editar"
                          @click="openEditDialog(item)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          class="text-center"
                          color="success"
                          title="Actualizar"
                          @click="openUpgradeDialog(item)"
                        >
                          mdi-update
                        </v-icon>
                        <v-icon
                          class="text-center"
                          color="green"
                          title="Logs"
                          @click="logsDialog = true ; dvcSelected = item"
                        >
                          mdi-file-excel
                        </v-icon>
                        <v-icon
                          class="text-center"
                          color="error"
                          title="eliminar"
                          @click="openDeleteDialog(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="mapCard" class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <devices-map :company_id="'none'"></devices-map>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <div v-if="userRole === 'ADMIN' || userRole === 'MONITORING'" class="ma-0 pa-0">
      <admin-devices></admin-devices>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import AdminDevices from "./admin-devices.vue";
import xlsx from "xlsx";
import { v4 as uuidv4 } from "uuid";
import LineChart from "../components/charts/line-chart.vue";
import DevicesMap from "./utils/devices-map.vue";

export default {
  name: "Devices",
  components: {
    AdminDevices,
    LineChart,
    DevicesMap
  },
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    dialog: false,
    deleteDialog: false,
    dialogCreateDevice: false,
    dialogCreateSlave: false,
    dialogCreateSlaveCam: false,
    dialogMonitoringDevice: false,
    upgradeDialog: false,
    logsDialog: false,
    dvcSelected: null,
    dvcToDelete: null,
    dvcToUpgrade: null,
    MACVerification: "",
    userRole: auth.getRoleName(),
    campus: [],
    editOrganizations: [],
    org: null,
    subOrg: null,
    devices: null,
    intervall: "",
    search: "",
    loading: false,
    modifyDvc: false,
    headers: [
      { text: "MAC", align: "center", value: "mac", sortable: false },
      { text: "IP", align: "center", value: "ip", sortable: true },
      {
        text: "Ubicación",
        align: "center",
        value: "campus.name",
        sortable: true,
      },
      { text: "Nombre", align: "center", value: "name", sortable: false },
      {
        text: "Ultima Conexión",
        align: "center",
        value: "last_connection",
        sortable: true,
      },
      { text: "Estados", align: "center", value: "Status", sortable: false },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    modes: [
      { ID: "ENTRY", Name: "ENTRADA" },
      { ID: "EXIT", Name: "SALIDA" },
    ],
    deviceTypes: ['vsgate', 'vspark', 'vision', 'restaurant'],
    testMode: null,
    dateLogs: "",
    time1: null,
    time2: null,
    datepickerDateLog: false,
    finish_time: false,
    start_time: false,
    startTimeLogs: "",
    finishTimeLogs: "",
    loadingLogsBtn: false,
    companies: [
      {uuid: "06a514f8-20ed-4cd3-9ac8-a72419925a07",	name: "solmarin"},
      {uuid: "097d6152-b587-4277-9a48-15878b0cc432",	name: "bios"},
      {uuid: "0b09e6c6-efd1-4545-a779-38b6796449a3",	name: "digitalblue"},
      {uuid: "0fd42dc4-5536-4e89-8a7d-2a0177d7f991",	name: "deliflor"},
      {uuid: "1cc884ac-1bf3-4556-a6e5-5bf1e8dcf167",	name: "sanvicente"},
      {uuid: "2503a65a-91ab-4661-ab14-df0cfbb27e49",	name: "hoh"},
      {uuid: "359fda80-a889-4efb-b249-37ce89862752",	name: "megacenter"},
      {uuid: "422cdfa9-5712-4733-ac8a-9d3f80283604",	name: "conconcreto"},
      {uuid: "4e4c96b1-69b7-432d-8fd9-ef9460b11a8d",	name: "wolkvox"},
      {uuid: "540476d0-df97-491e-b11b-20e743bc0feb",	name: "campina"},
      {uuid: "5c6990eb-2bd3-44e5-8c10-3014da089386",	name: "contento"},
      {uuid: "6a48d7f5-7af2-4771-8144-c185c3259452",	name: "latinco"},
      {uuid: "88857276-0149-44ac-ae47-6bacd24b1bf4",	name: "vanguard"},
      {uuid: "88f075ea-88da-46b8-8b22-80a96f389d59",	name: "arquitecturayconcreto"},
      {uuid: "9bcbeae3-e789-47b4-bb20-784b3dc3a600",	name: "pactia"},
      {uuid: "aa80deb2-3973-4800-a604-34b3a465c14a",	name: "tahami"},
      {uuid: "bbc39d33-128f-474a-bea7-81e2edabdc5c",	name: "sierragrande"},
      {uuid: "d6b900aa-8aa2-4c32-bc6d-9db5bd3aa645",	name: "dancarlton"},
      {uuid: "dea02cee-7c13-4bfd-9f6d-87b4d80efd17",	name: "solla"},
      {uuid: "e41e4239-95ca-49e4-b41e-4143e6883607",	name: "logika"},
      {uuid: "ef3cf356-34e6-4e63-8357-0d72a154f13d",	name: "demo"},
    ],
    masters: [],
    slaves: [],
    newDevice: {
      MAC: null,
      assetName: null,
      city: null,
      company: null,
      IP: null,
      vpnIP: null
    },
    newSlave: {
      MAC: null,
      masterMac: null,
      vpnIP: null
    },
    newCam: {
      slave: null,
      name: null,
      place: null,
      rtsp: null
    },
    ethList: [],
    ethTimeSearch: "",
    tempTimeSearch: "",
    scaleToShow: 'minute',
    openInterval: false,
    chartTypeLabel: ["Minuto", "Hora", "Día"],
    monitoringFilter: {
      from: null,
      to: null,
      fromTime: null,
      toTime: null,
      type: null
    },
    allCompanies: [],
    singleCompany: null,
    mapCard: true, 
    
    markers: [],
  }),
  methods: {
    openChild(devices, index) {
      devices.map((device) => {
        device.isChildOpen = false
      })
      devices[index].isChildOpen = !devices[index].isChildOpen
    },

    clickPlace(index) {
      this.center = this.markers[index].polygon_location[0] 
      this.markers[index].isOpened = !this.markers[index].isOpened
      this.markers[index].showChildren = !this.markers[index].showChildren
      this.mapZoom = 19
    },

    changeOrgTrigger() {
      this.org = null
      this.singleCompany.Campus.unshift({name: "Todos", uuid: "0000"})
    },

    heatDeviceResult(lastHB, type, item) {
      lastHB.temp_status = Math.round(lastHB.temp_status)
      if (type == 'color' && this.validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8))) {
        let color
        if (lastHB.temp_status <= 58) {
          color = "green"
        } else if (lastHB.temp_status > 58 && lastHB.temp_status <= 68) {
          color = "orange"
        } else {
          color = "red"
        }
        return color
      } 
      
      if (type == 'icon') {
        let icon
        if (lastHB.temp_status <= 58) {
          icon = 'mdi-thermometer-check'
        } else if (lastHB.temp_status > 58 && lastHB.temp_status <= 68) {
          icon = 'mdi-thermometer-chevron-up'
        } else {
          icon = 'mdi-thermometer-alert'
        }
        return icon
      }
    },

    validateDvcConnection(lastConnectionTime) {
      const todaysTime = new Date(
        globals.COdate().slice(0, 10) +
          " " +
          globals.COdate().slice(11, globals.COdate().length - 8)
      );
      lastConnectionTime = new Date(lastConnectionTime);
      let difference = Math.round(
        (todaysTime.getTime() - lastConnectionTime.getTime()) / 60000
      ); // Diferencia entre fechas en minutos

      if (difference > 3) {
        return false;
      } else {
        return true;
      }
    },

    upgradeFW(item, macToVerify) {
      if (item.mac == macToVerify) {
        axios
          .post(globals.APIURL + "vehicle/bcs", {
            topic: item.type_device + "/" + item.mac + "/update",
            message: "0",
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        this.upgradeDialog = false;
        this.dvcToUpgrade = null;
        this.MACVerification = "";
        this.getDevices(this.singleCompany, this.org);
        this.snackbar = true;
        this.snackbarColor = "success";
        this.snackbarText =
          "Se envío señal de actualización de FW al dispositivo de forma exitosa.";
      } else {
        this.snackbar = true;
        this.snackbarColor = "error";
        this.snackbarText =
          "La MAC ingresada no coincide con la del dispositivo que desea actualizar, ingresela nuevamente.";
        this.MACVerification = "";
      }
    },

    openGate(item) {
      axios
        .post(globals.APIURL + "device/open-door", {
          mac: item.mac
        })
        .then((res) => {
          if (res.status == 200 && res.data.message == "success action") {
            this.snackbar = true
            this.snackbarColor = "success"
            this.snackbarText = "Se accionó apertura de puerta"
          } else if (res.status == 200 && res.data.message == "Mac must be filled") {
            this.snackbar = true
            this.snackbarColor = "success"
            this.snackbarText = "Ocurrio un error"
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getOrganizations() {
      axios
        .get(globals.APIURL + "campus/fac")
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.campus.push({ name: "Sin Asignar", uuid: "0000" });
            res.data.forEach((element) => {
              this.editOrganizations.push(element);
              if (element.devices.length > 0) {
                this.campus.push(element);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCompanies() {
      axios
        .get(globals.APIURL + "org/fa")
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.allCompanies.push({name: "Sin Asignar", uuid: "0000", Campus: []})
            res.data.forEach(element => {
              if (!this.allCompanies.some(org => org.name === element.name)) {
                this.allCompanies.push(element)
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDevices(org, campus) {
      this.loading = true;
      this.devices = [];
      let filter = {
        sedes: []
      }

      if (org.uuid == '0000') campus = null;

      if (org && !campus) {
        if (org.uuid != "0000") {
          for (let i = 1; i < org.Campus.length; i++) {
            const element = org.Campus[i];
            
            filter.sedes.push(element.uuid)
          }
        }
      } else {
        if (campus.uuid == "0000") {
          for (let i = 1; i < org.Campus.length; i++) {
            const element = org.Campus[i];
            
            filter.sedes.push(element.uuid)
          }
        } else {
          filter.sedes = [campus.uuid]
        }
      }

      axios
        .post(globals.APIURL + "device/get-admin-devices", filter)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.devices = res.data;
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    updateDevice(device) {
      const dvcUpdateObj = {
        name: device.name,
        mac: device.mac,
        campus_id: device.campus_id,
        mode: device.mode,
        type_device: device.type_device
      };
      const dvcDynUpdateObj = {
        assetkey: device.campus_id,
        assetName: device.name,
        company: auth.getUserEnterpriseName(),
        type: device.type_device == 'vsgate' ? "access" : device.type_device,
        vpn_ip: device.slave.vpn_ip
      }
      device.cam.name = device.name;
      device.cam.mod_date = globals.COdate();
      device.cam.mod_user = auth.getUsername();
      const dvcCamUpdateObj = device.cam;
      axios
        .put(globals.APIURL + "device/ud", {
          device_id: device.uuid,
          version: "2",
          slave_id: device.slave.assetkey,
          update_obj: dvcUpdateObj,
          updateDynObj: dvcDynUpdateObj,
          updateCamObj: dvcCamUpdateObj
        })
        .then((res) => {
          if (res.data == "Device updated successfuly") {
            this.dialog = false;
            this.dvcSelected = null;
            this.modifyDvc = false;
            this.testMode = null;
            this.getDevices(this.singleCompany, this.org);
            this.snackbar = true;
            this.snackbarColor = "success";
            this.snackbarText = "Se modificó el dispositivo con exito.";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteDevice(dvcToDelete, MACVerification) {
      if (dvcToDelete.mac == MACVerification) {
        axios
          .delete(globals.APIURL + "device/dd/" + dvcToDelete.uuid)
          .then((res) => {
            if (res.data == "Device deleted successfuly") {
              this.deleteDialog = false;
              this.dvcToDelete = null;
              this.getDevices(this.singleCompany, this.org);
              this.snackbar = true;
              this.snackbarColor = "success";
              this.snackbarText = "Se eliminó el dispositivo de forma exitosa.";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.snackbar = true;
        this.snackbarColor = "error";
        this.snackbarText =
          "La MAC ingresada no coincide con la del dispositivo a borrar, ingresela nuevamente.";
      }
    },

    openEditDialog(item) {
      this.loadingEdit = true;
      this.dvcSelected = item;
      axios
        .post(globals.APIURL + "device/get-slave-cams", {
          slaveMac: item.mac,
        })
        .then((res) => {
          axios
            .post(globals.APIURL + "device/get-slaves", {
              mac: item.mac,
            })
            .then((response) => {
              if (response.data && response.data.length > 0) {
                this.dvcSelected.slave = response.data[0]
              } else {
                this.dvcSelected.slave = {
                  assetkey: null,
                  mac: item.mac,
                  masterMac: item.mac,
                  vpn_ip: ""
                }
              }
              this.dialog = true;
              this.testMode = item.mode;
            })
            .catch((err) => {
              console.log(err);
            });
          if (res.data && res.data.length > 0) {
            this.dvcSelected.cam = res.data[0]
            this.loadingEdit = false
          } else {
            this.loadingEdit = false
            this.dvcSelected.cam = {
              active: true,
              mod_date: globals.COdate(),
              mod_user: auth.getUsername(),
              name: "",
              place: "",
              rtsp: "",
              slaveMac: item.mac,
              xmap: 0,
              ymap: 0
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.loadingEdit = false
        });
    },

    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.dvcToDelete = item;
    },

    openUpgradeDialog(item) {
      this.upgradeDialog = true;
      this.dvcToUpgrade = item;
    },

    closeEditModel() {
      this.dvcSelected = null;
      this.dialog = false;
      this.modifyDvc = false;
      this.getDevices(this.singleCompany, this.org);
    },

    closeDeleteModel() {
      this.dvcToDelete = null;
      this.dvcToUpgrade = null;
      this.deleteDialog = false;
      this.upgradeDialog = false;
      this.MACVerification = "";
      this.getDevices(this.singleCompany, this.org);
    },

    clearMonitoringSearchObj() {
      this.openInterval = false
      this.monitoringFilter = {
        from: null,
        to: null,
        fromTime: null,
        toTime: null,
        type: null
      }
    },

    customizableSearch(device, obj) {
      if (obj.from && obj.fromTime && obj.to && obj.toTime && obj.type) {
        if (obj.from <= obj.to) {
          //console.log("OBJ DE BUSQUEDA: ", obj)
          let startDate = obj.from + "T" + obj.fromTime + ":00.000Z"
          let finishDate = obj.to + "T" + obj.toTime + ":00.000Z"
          let typeToShow
          switch (obj.type) {
            case "Minuto":
              typeToShow = "minute"
              break;

            case "Hora":
              typeToShow = "hour"
              break;
            default:
              typeToShow = "day"
              break;
          }

          this.scaleToShow = typeToShow;

          axios
            .post(globals.APIURL + "device/get-status-data", {
              mac: device.mac,
              start_date: startDate,
              finish_date: finishDate
            })
            .then((res) => {
              if (res.data && res.data.length > 0) {
                this.ethList = res.data
              }
            })
            .catch((err) => {
              if (err.response.data == 'Device can not get data') {
                this.snackbar = true
                this.snackbarColor = "yellow darken-2"
                this.snackbarText = "No hay datos que mostrar con los valores ingresados."
                this.getDeviceStatus(device, "m")
                console.log(err);
              }
            });
        } else {
          this.snackbar = true
          this.snackbarText = "La fecha inicial no puede ser mayor que la final."
          this.snackbarColor = "red"
        }
      } else {
        this.snackbar = true
        this.snackbarText = "Debe llenar todos los campos del filtro."
        this.snackbarColor = "red"
      }
    },

    openMonitoring(device) {
      let today = new Date(device.last_connection ? device.last_connection : globals.COdate())
      let firstDate = new Date(today.setMinutes(today.getMinutes() - 5)).toISOString();
      axios
        .post(globals.APIURL + "device/get-status-data", {
          mac: device.mac,
          start_date: firstDate,
          finish_date: device.last_connection
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.ethList = res.data
            this.dialogMonitoringDevice = true;
            this.ethTimeSearch = "m";
            this.tempTimeSearch = "m";
            this.dvcSelected = device
          } else {
            this.snackbar = true
            this.snackbarColor = "primary"
            this.snackbarText = "Este dispositivo aun no tiene información para monitorear"
          }
        })
        .catch((err) => {
          if (err.response.data == 'Device can not get data') {
            this.snackbar = true
            this.snackbarColor = "primary"
            this.snackbarText = "Este dispositivo aun no tiene información para monitorear"
            console.log(err);
          }
        });
    },

    getDeviceStatus(device, typeSearch) {
      let startDate;
      let finishDate;
      let today = new Date(device.last_connection ? device.last_connection : globals.COdate())
      finishDate = device.last_connection ? device.last_connection : globals.COdate()
      if (typeSearch == 'm') {
        this.scaleToShow = 'minute';
        let firstDate = new Date(today.setMinutes(today.getMinutes() - 5)).toISOString();
        startDate = firstDate;
      } else if (typeSearch == 'h') {
        this.scaleToShow = 'minute';
        let firstDate = new Date(today.setHours(today.getHours() - 1)).toISOString();
        startDate = firstDate;
      } else if (typeSearch == 'd') {
        this.scaleToShow = 'hour';
        let firstDate = new Date(today.setHours(today.getHours() - 24)).toISOString();
        startDate = firstDate;
      } else if (typeSearch == 's') {
        this.scaleToShow = 'day';
        let firstDate = new Date(today.setDate(today.getDate() - 6)).toISOString();
        startDate = firstDate;
      }
      axios
        .post(globals.APIURL + "device/get-status-data", {
          mac: device.mac,
          start_date: startDate,
          finish_date: finishDate
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.ethList = res.data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    generateLogsFile(device, date, startTime, finishTime) {
      this.loadingLogsBtn = true;
      axios({
        method: "POST",
        url: globals.APIURL + "device/get-devices-logs",
        data: {
          mac: device.mac,
          start_date: date,
          finish_date: date,
          start_time: startTime + ":00",
          finish_time: finishTime + ":59"
        },
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          if (res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];

              element.FECHA = element.date
              element.HORA = element.time
              delete element.uuid
              delete element.mac
              delete element.date
              delete element.time
            }

            let newWB = xlsx.utils.book_new();
            let newWS = xlsx.utils.json_to_sheet(res.data, {
              header: [
                "FECHA",
                "HORA"
              ],
            });

            newWS["A1"].v = "FECHA";
            newWS["B1"].v = "HORA";

            xlsx.utils.book_append_sheet(newWB, newWS, "Logs");

            xlsx.writeFile(
              newWB,
              "Logs dispositivo " + this.dvcSelected.mac + " de " + this.dvcSelected.campus.name + ".xlsx",
              {
                type: "file",
                bookType: "xlsx",
              }
            );
            this.loadingLogsBtn = false;
            this.snackbarText = "Se descargó su reporte de logs.";
            this.snackbarColor = "success";
            this.snackbar = true;
            this.logsDialog = false;
            this.dateLogs = "";
            this.startTimeLogs = "";
            this.finishTimeLogs = "";
            this.dvcSelected = null;
          } else {
            this.loadingLogsBtn = false;
            this.snackbarText = "El dispositivo no tiene logs en la fecha seleccionada";
            this.snackbarColor = "error";
            this.snackbar = true;
            this.dateLogs = "";
            this.startTimeLogs = "";
            this.finishTimeLogs = "";
          }
        }
      }).catch((err) => {
        this.loadingLogsBtn = false;
        if (err.response.data == 'No data to show') {
          this.snackbarText = "El dispositivo no tiene logs en la fecha seleccionada";
          this.snackbarColor = "error";
          this.snackbar = true;
        }
      });
    },

    createMaster(newDev) {
      console.log("MASTER TO CREATE: ", newDev)
      axios
        .post(globals.APIURL + "device/create-master", {
          master: {
            mac: newDev.MAC,
            assetkey: newDev.company.uuid,
            assetName: newDev.assetName,
            city: newDev.city,
            company: newDev.company.name,
            type: "access2",
            ip: newDev.IP,
            vpn_ip: newDev.vpnIP,
          }
        })
        .then((res) => {
          console.log(res)
          if (res.status == 200) {
            console.log("MASTER CREATE: ", res.data)
            this.snackbar = true
            this.snackbarColor = "success"
            this.snackbarText = "Master Creado"
            this.dialogCreateDevice = false
            this.newDevice.MAC = null
            this.newDevice.company = null
            this.newDevice.assetName = null
            this.newDevice.city = null
            this.newDevice.IP = null
            this.newDevice.vpnIP = null
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createSlave(newSlave) {
      console.log("Slave: ", newSlave)
      axios
        .post(globals.APIURL + "device/create-slave", {
          slave: {
            assetkey: uuidv4(),
            mac: newSlave.MAC,
            masterMac: newSlave.masterMac,
            vpn_ip: newSlave.vpnIP,
          }
        })
        .then((res) => {
          if (res.status == 200) {
            console.log("SLAVE CREATE: ", res.data)
            this.snackbar = true
            this.snackbarColor = "success"
            this.snackbarText = "Esclavo Creado"
            this.dialogCreateSlave = false
            this.newSlave.MAC = null
            this.newSlave.masterMac = null
            this.newSlave.vpnIP = null
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createSlaveCam(newSlaveCam) {
      console.log("Slave Cam: ", newSlaveCam)
      axios
        .post(globals.APIURL + "device/create-slave-cam", {
          slave_cam: {
            camkey: uuidv4(),
            slaveMac: newSlaveCam.slave.mac,
            active: true,
            mod_date: globals.COdate(),
            mod_user: auth.getUsername(),
            name: newSlaveCam.name,
            place: newSlaveCam.place,
            rtsp: newSlaveCam.rtsp,
            xmap: 0,
            ymap: 0
          }
        })
        .then((res) => {
          if (res.status == 200) {
            console.log("SLAVE CAM CREATE: ", res.data)
            this.snackbar = true
            this.snackbarColor = "success"
            this.snackbarText = "Camara esclavo creada"
            this.dialogCreateSlaveCam = false
            this.newCam.slaveMac = null
            this.newCam.name = null
            this.newCam.place = null
            this.newCam.rtsp = null
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMasters() {
      axios
        .post(globals.APIURL + "device/get-masters", {
          company_id: auth.get_asset_id(),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            console.log("MASTER LIST: ", res.data)
            this.masters = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSlaves(mac) {
      axios
        .post(globals.APIURL + "device/get-slaves", {
          mac: mac,
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            console.log("SLAVE LIST: ", res.data)
            this.slaves = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSlaveCams(slaveMac) {
      axios
        .post(globals.APIURL + "device/get-slave-cams", {
          slaveMac: slaveMac,
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            console.log("SLAVE CAMS LIST: ", res.data)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    test() {
      axios({
        url: 'https://autorizarusuarioporteria.azurewebsites.net/api/GetEmployeeByDocument?documento=3400256',
        method: 'get',
        headers: {
          'x-functions-key': 'TG8IQoyxo9y98-ZAYjkr_ciR8avedKPZtiskwQpEP5n3AzFun0CTxg=='
        }
      })
      .then(response => {
          console.log(response.data)
      }) 
      .catch(err => {
          console.log(err);
      });
      /* axios({
        url: 'https://autorizarusuarioporteria.azurewebsites.net/api/ActiveEmployees',
        method: 'get',
        headers: {
          'x-functions-key': 'TG8IQoyxo9y98-ZAYjkr_ciR8avedKPZtiskwQpEP5n3AzFun0CTxg=='
        }
      })
      .then(response => {
          console.log(response)
      }) 
      .catch(err => {
          console.log(err);
      }); */
      /* axios({
        url: 'https://autorizarusuarioporteria.azurewebsites.net/api/NewEmployees',
        method: 'get',
        headers: {
          'x-functions-key': 'TG8IQoyxo9y98-ZAYjkr_ciR8avedKPZtiskwQpEP5n3AzFun0CTxg=='
        }
      })
      .then(response => {
          console.log(response)
      }) 
      .catch(err => {
          console.log(err);
      }); */
    },

  },
  mounted() {
    //this.test()
    this.getCompanies()
    this.getOrganizations();
    /* this.getMasters()
    this.getSlaves("1a2b3c4d")
    this.getSlaveCams("b8133294f304")
    this.example() */
  },
  beforeDestroy() {
    clearInterval(this.intervall);
  },
};
</script>
<style>
tr:hover {
  background-color: transparent !important;
}

.graphic-container {
  /* position: relative;
  margin: auto;
  height: 35vh;
  width: 35vw; */
  height: 400px !important; 
  width: 100%  !important; 
  position: 'relative' !important;
}

.map-container {
  position: relative;
  width: 100%;
  height: 800px;
}

.menu-card {
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 10;
}
</style>
