import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: '#004AAD',
        secondary: '#60A5FA',
        //accent: '#82B1FF',
        error: '#F87171',
        red: '#F87171',
        cancel: "#9CA3AF",
        //info: '#2196F3',
        success: '#34d399', //Green
        warning: '#FFC107', //Orange
        backgroundPrimary: "#31394C",
        backgroundSecondary: "#212734",
        backgroundText: "#68789e",
        grey: '#9CA3AF',
      },
    },
  },
});
