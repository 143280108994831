<template>
  <div>
    
    <div class="text-center" v-if="dialogDelete == true">
      <v-dialog v-model="dialogDelete" persistent width="35%">
        <v-card>
          <v-card-title class="d-flex justify-center">
            Eliminar Vehículo
          </v-card-title>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12">
                <p class="my-0 py-0">
                  <small
                    >Está seguro que desea eliminar el vehículo con N° de placa: </small
                  ><strong>{{ vehicleToDelete.plate }}</strong>
                </p>
                <p class="my-0 py-0">
                  <small
                    >Por favor ingrese el número de la placa para validar la
                    eliminación.</small
                  >
                </p>
              </v-col>
              <v-col cols="12" class="my0 py-0">
                <v-text-field
                  v-model="plate_validation"
                  label="Escriba la placa"
                  class="uppertext-field"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my0 py-0">
              <v-col cols="12" md="6" sm="6">
                <v-btn
                  color="error"
                  block
                  :loading="loadingBtns"
                  :disabled="
                    (plate_validation && plate_validation.length > 3) || loadingBtns === false 
                      ? false
                      : true
                  "
                  @click="
                    deleteVehicle(
                      vehicleToDelete,
                      plate_validation.toUpperCase()
                    )
                  "
                >
                  ELIMINAR VEHÍCULO
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-btn
                  block
                  @click="
                    dialogDelete = false;
                    cc_validation = null;
                  "
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogUnlinkAll == true">
      <v-dialog v-model="dialogUnlinkAll" persistent width="35%">
        <v-card>
          <v-card-title class="d-flex justify-center">
            Desvincular Vehículos {{ this.employeeList == false ? 'Visitantes' : 'Recurrentes' }}
          </v-card-title>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12">
                <p class="my-0 py-0">
                  <small
                    >Está seguro que desea desvincular todos los vehículos {{ this.employeeList == false ? 'visitantes' : 'recurrentes' }}: </small
                  >
                </p>
                <p class="my-0 py-0">
                  <small
                    >Por favor escriba <strong>confirmar</strong> en la caja de texto para proceder con la desvinculación.</small
                  >
                </p>
              </v-col>
              <v-col cols="12" class="my0 py-0">
                <v-text-field
                  v-model="unlink_validation"
                  label="Escriba palabra clave"
                  placeholder="confirmar"
                  class="uppertext-field"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my0 py-0">
              <v-col cols="12" md="6" sm="6">
                <v-btn
                  color="error"
                  block
                  :disabled="unlink_validation === ''"
                  @click="unlinkAllVehicles"
                >
                  DESVINCULAR VEHÍCULOS
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-btn
                  block
                  color="secondary"
                  @click="
                    dialogUnlinkAll = false;
                    unlink_validation = '';
                  "
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogCreate == true">
      <v-dialog v-model="dialogCreate" content-class="modalVs" persistent width="600">
        <v-card>
          <v-card-title class="d-flex justify-center text-h6 font-weight-light">
            ASIGNAR NUEVO VEHÍCULO
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="employeeVehiclesCounter === 3 || visitorVehiclesCounter === 3" cols="12" class="d-flex justify-center py-0 my-0">
                <span class="text-subtitle-1 font-weight-medium primary--text" v-if="employeeVehiclesCounter === 3 && visitorVehiclesCounter < 3"><small>** Esta persona ya tiene sus 3 vehículos recurrentes, solo puede asignar vehículos visitantes. **</small></span>
                <span class="text-subtitle-1 font-weight-medium primary--text" v-if="employeeVehiclesCounter < 3 && visitorVehiclesCounter === 3"><small>** Esta persona ya tiene sus 3 vehículos visitantes, solo puede asignar vehículos recurrentes. **</small></span>
              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <v-select
                  v-model="newVehicle.type"
                  :items="vehicleTypes"
                  item-text="Name"
                  item-value="ID"
                  label="Tipo Vehículo"
                  outlined
                  color="secondary"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <v-text-field
                  v-model="newVehicle.plate"
                  label="Placa"
                  class="uppertext-field"
                  outlined
                  color="secondary"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <v-select
                  v-model="newVehicle.type_access"
                  :items="driverTypes"
                  item-text="Name"
                  item-value="ID"
                  label="Uso Vehículo"
                  outlined
                  color="secondary"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <v-text-field
                  v-model="newVehicle.color"
                  label="Color"
                  color="secondary"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <v-autocomplete
                  v-model="newVehicle.brand"
                  :items="brands"
                  label="Marca"
                  color="secondary"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <v-text-field
                  v-model="newVehicle.yearModel"
                  label="Año Modelo"
                  hide-detatils
                  color="secondary"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-btn
                  color="primary"
                  rounded
                  large
                  class="px-4"
                  block
                  :loading="loadingBtns"
                  :disabled="
                    loadingBtns === false 
                      ? false
                      : true
                  "
                  @click="createVehicle(newVehicle)"
                >
                  Guardar Vehículo
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn
                  color="cancel"
                  rounded
                  large
                  outlined
                  class="px-4"
                  block
                  @click="dialogCreate = false"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-snackbar v-model="snackbarCreation" timeout="6000" :color="snackbarColorCreation">
            {{ snackbarTextCreation }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbarCreation = false">
                Cerrar
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-dialog>
    </div>

    <v-row class="text-center">
      <v-col cols="12" md="4" sm="4" class="d-flex justify-center align-center">
        <span class="text-subtitle-1">Documento Conductor: </span>
        <strong class="white--text mx-2">{{ registryData.document_number }}</strong>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="d-flex justify-center align-center">
        <span class="text-subtitle-1">Nombre Conductor: </span>
        <strong class="white--text mx-2">{{
          registryData.first_name +
          " " +
          registryData.first_last_name +
          " " +
          registryData.second_last_name
        }}</strong>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="d-flex justify-center align-center">
        <v-btn
          color="primary"
            rounded
            class="px-4"
          @click="dialogCreate = true"
          :loading="loading"
          :disabled="loading || (employeeVehiclesCounter === 3 && visitorVehiclesCounter === 3)"
        >
          {{
            employeeVehiclesCounter === 3 && visitorVehiclesCounter === 3
              ? "Se alcanzó el limite de vehículos para asignar"
              : "Asignar Vehículo"
          }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <label class="text-h6" :class="employeeList ? 'text-weight-bold white--text' : ''" style="cursor: pointer" @click="employeeList = true">RECURRENTES /</label><label class="text-h6 ml-1" :class="!employeeList ? 'text-weight-bold white--text' : ''" style="cursor: pointer" @click="employeeList = false">VISITANTES</label>
        <!-- // TODO: DESCOMENTAR BOTÓN DE LA DESVINCULACION DE TODOS LOS VEHICULOS CUANDO SE TERMINE LA LOGICA DEL BACKEND. -->
        <v-btn
          color="secondary"
          small
          class="ml-5 px-4"
          @click="dialogUnlinkAll = true ; unlink_validation = ''"
          :disabled="(employeeList == true && vehicles.length == 0) || (employeeList == false && vehiclesVisitors.length == 0)"
        >
          DESVINCULAR TODOS LOS VEHÍCULOS {{ employeeList == false ? 'VISITANTES' : 'RECURRENTES'}}
        </v-btn>
      </v-col>
      <v-row v-if="employeeList == true && vehicles.length > 0" class="ma-0 pa-0">
        <v-col cols="12" md="4" v-for="(vehicle, i) in vehicles" :key="i">
          <v-card>
            <v-card-title class="d-flex justify-center">
              <p class="text-h6 my-0 py-0 secondary--text">Vehículo {{ i + 1 }}</p>
            </v-card-title>
            <v-card-text>
              <v-row class="my-0 py-0">
                <v-col cols="12" class="d-flex justify-center my-0 py-0 mb-3">
                  <v-img
                    :src="getVehicleImg(vehicle.entries[vehicle.entries.length - 1].image[0])"
                    v-if="vehicle.entries && vehicle.entries.length > 0"
                    max-height="150px"
                    max-width="250px"
                    contain
                    lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                    class="border-radius"
                  >
                  </v-img>
                  <p v-if="!vehicle.entries || vehicle.entries.length === 0" class="text-subtitle-1" style="color: #9e9e9e">Aún no hay imagen del vehículo</p>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-3">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Tipo Vehículo:
                    </strong>
                    <span
                      class="text-subtitle-2 font-weight-light white--text"
                      v-for="(item, i) in vehicleTypes"
                      :key="i"
                      >{{ vehicle.type === item.ID ? item.Name : "" }}</span
                    >
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-select
                      v-model="vehicle.type"
                      :items="vehicleTypes"
                      item-text="Name"
                      item-value="ID"
                      label="Tipo Vehículo"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-3">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Placa:
                    </strong>
                    <span class="text-subtitle-2 font-weight-light white--text">{{
                      vehicle.plate.toUpperCase()
                    }}</span>
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-text-field
                      v-model="vehicle.plate"
                      label="Placa"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-3">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Uso Vehículo:
                    </strong>
                    <span
                      class="text-subtitle-2 font-weight-light"
                      >{{ vehicle.type_access === 'VISITANTE' ? vehicle.type_access : 'RECURRENTE' }}</span
                    >
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-select
                      v-model="vehicle.type_access"
                      :items="driverTypes"
                      item-text="Name"
                      item-value="ID"
                      label="Uso Vehículo"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-3">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Color:
                    </strong>
                    <span class="text-subtitle-2 font-weight-light">{{
                      vehicle.color ? vehicle.color : "S/A"
                    }}</span>
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-text-field
                      v-model="vehicle.yearModel"
                      label="Color"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-3">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Marca:
                    </strong>
                    <span class="text-subtitle-2 font-weight-light">{{
                      vehicle.brand ? vehicle.brand : "S/A"
                    }}</span>
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-autocomplete
                      v-model="vehicle.brand"
                      :items="brands"
                      label="Marca"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-autocomplete>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-31">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Año Modelo:
                    </strong>
                    <span class="text-subtitle-2 font-weight-light">{{
                      vehicle.yearModel ? vehicle.yearModel : "S/A"
                    }}</span>
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-text-field
                      v-model="vehicle.yearModel"
                      label="Año Modelo"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="!vehicle.editMode || vehicle.editMode === false">
                <v-col cols="12" md="6">
                  <v-btn
                    color="primary"
                    rounded
                    large
                    block
                    @click="activateEditMode(vehicle, i)"
                  >
                    Editar Vehículo
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    color="error"
                    rounded
                    large
                    block
                    @click="
                      vehicleToDelete = vehicle;
                      dialogDelete = true;
                    "
                  >
                    Eliminar Vehículo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="vehicle.editMode === true">
                <v-col cols="12" md="6">
                  <v-btn
                    color="secondary"
                    rounded
                    large
                    block
                    :loading="loadingBtns"
                    :disabled="
                      loadingBtns === false 
                        ? false
                        : true
                    "
                    @click="updateVehicle(vehicle)"
                  >
                    Guardar Cambios
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    color="error"
                    rounded
                    large
                    block
                    @click="cancelEditMode(vehicle, i)"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="employeeList == true && (!vehicles || vehicles.length === 0)">
        <v-col cols="12" class="text-center">
          <p v-if="loading == false">No tiene vehículos recurrentes asignados</p>
          <span v-if="loading == true">
            <v-progress-circular
              :size="50"
              color="primary"
              :indeterminate="loading"
            >
            </v-progress-circular>
            <p class="pt-2 text-subtitle-2">Cargando vehículos recurrentes...</p>
          </span>
        </v-col>
      </v-row>
      
      <v-row v-if="employeeList == false && vehiclesVisitors.length > 0" class="ma-0 pa-0">
        <v-col cols="12" md="4" v-for="(vehicle, i) in vehiclesVisitors" :key="i">
          <v-card>
            <v-card-title class="d-flex justify-center">
              <p class="text-h6 my-0 py-0 secondary--text">Vehículo {{ i + 1 }}</p>
            </v-card-title>
            <v-card-text>
              <v-row class="my-0 py-0">
                <v-col cols="12" class="d-flex justify-center my-0 py-0 mb-3">
                  <v-img
                    :src="getVehicleImg(vehicle.entries[vehicle.entries.length - 1].image[0])"
                    v-if="vehicle.entries && vehicle.entries.length > 0"
                    max-height="150px"
                    max-width="250px"
                    contain
                    lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                    class="border-radius"
                  >
                  </v-img>
                  <p v-if="!vehicle.entries || vehicle.entries.length === 0" class="text-subtitle-1" style="color: #9e9e9e">Aún no hay imagen del vehículo</p>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-3">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Tipo Vehículo:
                    </strong>
                    <span
                      class="text-subtitle-2 font-weight-light white--text"
                      v-for="(item, i) in vehicleTypes"
                      :key="i"
                      >{{ vehicle.type === item.ID ? item.Name : "" }}</span
                    >
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-select
                      v-model="vehicle.type"
                      :items="vehicleTypes"
                      item-text="Name"
                      item-value="ID"
                      label="Tipo Vehículo"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-3">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Placa:
                    </strong>
                    <span class="text-subtitle-2 font-weight-light white--text">{{
                      vehicle.plate.toUpperCase()
                    }}</span>
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-text-field
                      v-model="vehicle.plate"
                      label="Placa"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-3">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Uso Vehículo:
                    </strong>
                    <span
                      class="text-subtitle-2 font-weight-light"
                      >{{ vehicle.type_access === 'VISITANTE' ? vehicle.type_access : 'RECURRENTE' }}</span
                    >
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-select
                      v-model="vehicle.type_access"
                      :items="driverTypes"
                      item-text="Name"
                      item-value="ID"
                      label="Uso Vehículo"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-select>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-3">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Color:
                    </strong>
                    <span class="text-subtitle-2 font-weight-light">{{
                      vehicle.color ? vehicle.color : "S/A"
                    }}</span>
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-text-field
                      v-model="vehicle.yearModel"
                      label="Color"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-3">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Marca:
                    </strong>
                    <span class="text-subtitle-2 font-weight-light">{{
                      vehicle.brand ? vehicle.brand : "S/A"
                    }}</span>
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-autocomplete
                      v-model="vehicle.brand"
                      :items="brands"
                      label="Marca"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-autocomplete>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-31">
                  <div v-if="!vehicle.editMode">
                    <strong class="text-subtitle-2 font-weight-bold"
                      >Año Modelo:
                    </strong>
                    <span class="text-subtitle-2 font-weight-light">{{
                      vehicle.yearModel ? vehicle.yearModel : "S/A"
                    }}</span>
                  </div>
                  <div v-if="vehicle.editMode">
                    <v-text-field
                      v-model="vehicle.yearModel"
                      label="Año Modelo"
                      outlined
                      dense
                      hide-details
                      dark
                      color="secondary"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="!vehicle.editMode || vehicle.editMode === false">
                <v-col cols="12" md="6">
                  <v-btn
                    color="primary"
                    rounded
                    large
                    block
                    @click="activateEditMode1(vehicle, i)"
                  >
                    Editar Vehículo
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    color="error"
                    rounded
                    large
                    block
                    @click="
                      vehicleToDelete = vehicle;
                      dialogDelete = true;
                    "
                  >
                    Eliminar Vehículo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="vehicle.editMode === true">
                <v-col cols="12" md="6">
                  <v-btn
                    color="secondary"
                    rounded
                    large
                    block
                    :loading="loadingBtns"
                    :disabled="
                      loadingBtns === false 
                        ? false
                        : true
                    "
                    @click="updateVehicle(vehicle)"
                  >
                    Guardar Cambios
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    color="error"
                    rounded
                    large
                    block
                    @click="cancelEditMode1(vehicle, i)"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="employeeList == false && (!vehiclesVisitors || vehiclesVisitors.length === 0)">
        <v-col cols="12" class="text-center">
          <p v-if="loading == false">No tiene vehículos visitantes asignados</p>
          <span v-if="loading == true">
            <v-progress-circular
              :size="50"
              color="primary"
              :indeterminate="loading"
            >
            </v-progress-circular>
            <p class="pt-2 text-subtitle-2">Cargando vehículos visitantes...</p>
          </span>
        </v-col>
      </v-row>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="cancel"
          rounded
          large
          outlined
          class="px-4"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth"

export default {
  name: "ManageVehicles",
  props: {
    registryData: {
      type: Object,
    },
  },
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    snackbarCreation: false,
    snackbarColorCreation: "",
    snackbarTextCreation: "",
    loading: false,
    loadingBtns: false,
    vehicles: [],
    vehiclesVisitors: [],
    visitorVehiclesCounter: 0,
    employeeVehiclesCounter: 0,
    plate_validation: "",
    dialogDelete: false,
    dialogCreate: false,
    vehicleToDelete: {},
    employeeList: true,
    unlink_validation: "",
    dialogUnlinkAll: false,
    newVehicle: {
      plate: "",
      type: "",
      brand: "",
      yearModel: "",
      color: "",
    },
    vehicleTypes: [
      { ID: "VEHICLE", Name: "Automóvil" },
      { ID: "TRUCK", Name: "Camioneta" },
    ],
    driverTypes: [
      { ID: "EMPLEADO", Name: "RECURRENTE" },
      { ID: "VISITANTE", Name: "VISITANTE" },
    ],
    brands: [
      "Acura",
      "Alfa Romeo",
      "Aptera",
      "Aston Martin",
      "Audi",
      "Austin",
      "Bentley",
      "BMW",
      "Bugatti",
      "Buick",
      "Cadillac",
      "Chevrolet",
      "Chrysler",
      "Citroën",
      "Corbin",
      "Daewoo",
      "Daihatsu",
      "Dodge",
      "Eagle",
      "Fairthorpe",
      "Ferrari",
      "FIAT",
      "Fillmore",
      "Foose",
      "Ford",
      "Geo",
      "GMC",
      "Hillman",
      "Holden",
      "Honda",
      "HUMMER",
      "Hyundai",
      "Infiniti",
      "Isuzu",
      "Jaguar",
      "Jeep",
      "Jensen",
      "Kia",
      "Lamborghini",
      "Land Rover",
      "Lexus",
      "Lincoln",
      "Lotus",
      "Maserati",
      "Maybach",
      "Mazda",
      "McLaren",
      "Mercedes-Benz",
      "Mercury",
      "Merkur",
      "MG",
      "MINI",
      "Mitsubishi",
      "Morgan",
      "Nissan",
      "Oldsmobile",
      "Panoz",
      "Peugeot",
      "Plymouth",
      "Pontiac",
      "Porsche",
      "Ram",
      "Rambler",
      "Renault",
      "Rolls-Royce",
      "Saab",
      "Saturn",
      "Scion",
      "Shelby",
      "Smart",
      "Spyker",
      "Spyker",
      "Studebaker",
      "Subaru",
      "Suzuki",
      "Tesla",
      "Toyota",
      "Volkswagen",
      "Volv",
    ],
  }),
  methods: {
    validatePlate(plate) {
      if (
        plate.length === 6 &&
        plate.charAt(0).toUpperCase() !== plate.charAt(0).toLowerCase() &&
        plate.charAt(1).toUpperCase() !== plate.charAt(1).toLowerCase() &&
        plate.charAt(2).toUpperCase() !== plate.charAt(2).toLowerCase() &&
        !isNaN(plate.charAt(3)) &&
        !isNaN(plate.charAt(4)) &&
        !isNaN(plate.charAt(5))
      ) {
        return true;
      } else {
        return false;
      }
    },

    activateEditMode(vehicle, i) {
      this._beforeEditingCache = Object.assign({}, vehicle);
      this.vehicles[i].editMode = true;
      if (this.employeeList == true) {
        this.employeeList = false
        this.employeeList = true
      } else if (this.employeeList == false) {
        this.employeeList = true
        this.employeeList = false
      }
    },

    cancelEditMode(vehicle, i) {
      Object.assign(vehicle, this._beforeEditingCache);
      this.vehicles[i].editMode = false;
      if (this.employeeList == true) {
        this.employeeList = false
        this.employeeList = true
      } else if (this.employeeList == false) {
        this.employeeList = true
        this.employeeList = false
      }
    },

    activateEditMode1(vehicle, i) {
      this._beforeEditingCache = Object.assign({}, vehicle);
      this.vehiclesVisitors[i].editMode = true;
      if (this.employeeList == true) {
        this.employeeList = false
        this.employeeList = true
      } else if (this.employeeList == false) {
        this.employeeList = true
        this.employeeList = false
      }
    },

    cancelEditMode1(vehicle, i) {
      Object.assign(vehicle, this._beforeEditingCache);
      this.vehiclesVisitors[i].editMode = false;
      if (this.employeeList == true) {
        this.employeeList = false
        this.employeeList = true
      } else if (this.employeeList == false) {
        this.employeeList = true
        this.employeeList = false
      }
    },

    getVehicleImg(id) {
      if (id) {
        var formatURL = "";
        if (id) {
          formatURL =
            globals.APIURL +
            "vehicle/entry-visitor/" +
            auth.getUserEnterpriseName() +
            "/" +
            id;
        }
        return formatURL;
      }
    },

    getVehicles(personID) {
      this.loading = true;
      this.vehicles = []
      this.vehiclesVisitors = []
      this.employeeVehiclesCounter = 0
      this.visitorVehiclesCounter = 0
      /*axios
        .post(globals.APIURL + "vehicle/fav", {
          person_id: personID,
        })
        .then((res) => {
          if (res.data) {
            res.data.forEach((element) => {
              element.editMode = false;
              if (element.type_access === 'EMPLEADO') {
                this.employeeVehiclesCounter += 1
              } else {
                this.visitorVehiclesCounter += 1
              }
            });
            this.vehicles = res.data
            this.registryData.vehicles = this.vehicles
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        }); */
        // * GET RECURRENT VEHICLES
        axios
        .post(globals.APIURL + "vehicle/fav", {
          person_id: personID,
          type_access: "EMPLEADO"
        })
        .then((res) => {
          if (res.data) {
            this.visitorVehiclesCounter = res.data.length;
            this.vehicles = res.data
            this.registryData.vehicles = this.vehicles
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
        // * GET VISITOR VEHICLES
        axios
        .post(globals.APIURL + "vehicle/fav", {
          person_id: personID,
          type_access: "VISITANTE"
        })
        .then((res) => {
          if (res.data) {
            this.employeeVehiclesCounter = res.data.length;
            this.vehiclesVisitors = res.data
            this.registryData.vehiclesVisitors = this.vehiclesVisitors
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createVehicle(registry) {
      this.loadingBtns = true
      if (registry.type && registry.plate && registry.type_access) {
        if (this.validatePlate(registry.plate)) {
          if ((this.employeeVehiclesCounter === 3 && registry.type_access === 'EMPLEADO') || (this.visitorVehiclesCounter === 3 && registry.type_access === 'VISITANTE')) {
            (this.employeeVehiclesCounter === 3 && registry.type_access === 'EMPLEADO') ? this.snackbarTextCreation = "Ya tiene el limite de vehículos recurrentes, solo puede asignar visitantes" : "Ya tiene el limite de vehículos visitantes, solo puede asignar recurrentes";
            this.snackbarColorCreation = "red";
            this.snackbarCreation = true;
          } else {
            registry.owner_personId = this.registryData.uuid;
            registry.created_at = globals.COdate();
            registry.plate = registry.plate.toUpperCase();
            if (registry.type_access === 'VISITANTE') {
              registry.status = false;
            } else if (registry.type_access === "EMPLEADO") {
              registry.company_id = auth.getUserEnterpriseID()
              registry.campus_id = auth.getSelectedSede()
            }
            delete registry.editMode;
            axios
              .post(globals.APIURL + "vehicle/cv", registry)
              .then((res) => {
                if (res.data && res.data.response == "Vehicle inserted successfuly") {
                  this.getVehicles(this.registryData.uuid);
                  this.snackbarText = "Se creo su vehículo de manera exitosa.";
                  this.snackbarColor = "green";
                  this.snackbar = true;
                  this.newVehicle = {
                    plate: "",
                    type: "",
                    color: "",
                    brand: "",
                    yearModel: "",
                  };
                  this.dialogCreate = false
                  this.loadingBtns = false
                }
                this.loadingBtns = false
              })
              .catch((err) => {
                  this.loadingBtns = false
                console.log(err);
              });
          }
        } else {
          this.snackbarTextCreation = "La placa debe estar compuesta por solo tres letras y tres números.";
          this.snackbarColorCreation = "red";
          this.snackbarCreation = true;
          this.loadingBtns = false
        }
      } else {
        this.snackbarTextCreation = "El tipo de vehículo, la placa y el uso son obligatorios.";
        this.snackbarColorCreation = "red";
        this.snackbarCreation = true;
        this.loadingBtns = false
      }
    },

    updateVehicle(vehicle) {
      this.loadingBtns = true
      if (vehicle.type && vehicle.plate && vehicle.type_access) {
        if (this.validatePlate(vehicle.plate)) {
          if (vehicle.type_access === 'VISITANTE') {
            vehicle.status = false
          }
          let vehicleToUpdate = {
            plate: vehicle.plate.toUpperCase(),
            type: vehicle.type,
            brand: vehicle.brand,
            yearModel: vehicle.yearModel,
            updated_at: globals.COdate(),
            type_access: vehicle.type_access,
            status: vehicle.status
          };
          axios
            .put(globals.APIURL + "vehicle/ur", {
              vehicle_id: vehicle.uuid,
              update_vehicle_obj: vehicleToUpdate,
              company_id: auth.getUserEnterpriseID(),
              campus_id: auth.getSelectedSede()
            })
            .then((res) => {
              if (res.data && res.data == "Vehicle updated successful") {
                vehicle.editMode = false;
                this.snackbarText =
                  "Se actualizó el vehículo de manera exitosa.";
                this.snackbarColor = "green";
                this.snackbar = true;
                this.loadingBtns = false
              }
              this.loadingBtns = false
            })
            .catch((err) => {
            this.loadingBtns = false
              console.log(err);
            });
        } else {
          this.snackbarText =
            "La placa debe estar compuesta por solo tres letras y tres números.";
          this.snackbarColor = "red";
          this.snackbar = true;
          this.loadingBtns = false
        }
      } else {
        this.snackbarText = "El tipo de vehículo, la placa y el uso no pueden estar vacíos.";
        this.snackbarColor = "red";
        this.snackbar = true;
        this.loadingBtns = false
      }
    },

    deleteVehicle(vehicle, plateValidation) {
        this.loadingBtns = true 
      if (vehicle.plate.toUpperCase() === plateValidation) {
        let body = {}

        body.vehicle_id = vehicle.id;

        if (vehicle.type_access == "EMPLEADO" || vehicle.type_access == "RESIDENTE") {
          body.company_id = auth.getUserEnterpriseID();
          body.campus_id = auth.getSelectedSede()
        }
        axios
          .post(globals.APIURL + "vehicle/dv", body)
          .then((res) => {
            if (res.data && res.data == "Vehicle deleted successfuly") {
              if (vehicle.type_access == "EMPLEADO") {
                for (let i = 0; i < this.vehicles.length; i++) {
                  const el = this.vehicles[i];
                  if (el.plate === vehicle.plate) {
                    this.vehicles.splice(i, 1);
                    if (el.type_access === 'VISITANTE') {
                      this.visitorVehiclesCounter = this.visitorVehiclesCounter - 1
                    } else {
                      this.employeeVehiclesCounter = this.employeeVehiclesCounter - 1
                    }
                  }
                }
              } else {
                for (let i = 0; i < this.vehiclesVisitors.length; i++) {
                  const el = this.vehiclesVisitors[i];
                  if (el.plate === vehicle.plate) {
                    this.vehiclesVisitors.splice(i, 1);
                    if (el.type_access === 'VISITANTE') {
                      this.visitorVehiclesCounter = this.visitorVehiclesCounter - 1
                    } else {
                      this.employeeVehiclesCounter = this.employeeVehiclesCounter - 1
                    }
                  }
                }
              }
              this.dialogDelete = false;
              this.plate_validation = "";
              this.vehicleToDelete = {};
              this.snackbarText = "Se eliminó el vehiculo.";
              this.snackbarColor = "green";
              this.snackbar = true;
              this.loadingBtns = false
            }
            this.loadingBtns = false
          })
          .catch((err) => {
              this.loadingBtns = false
            console.log(err);
          });
      } else {
          this.loadingBtns = false
        this.snackbarText =
          "Debe ingresar la placa correcta para proceder con la eliminación";
        this.snackbarColor = "red";
        this.snackbar = true;
      }
    },

    unlinkAllVehicles() {
      if (this.unlink_validation && this.unlink_validation == 'confirmar') {
        let listVehicles = [];
        if (this.employeeList == true) {
          for (let i = 0; i < this.vehicles.length; i++) {
            const element = this.vehicles[i];
            
            listVehicles.push({uuid: element.uuid})
          }
        } else if (this.employeeList == false) {
          for (let i = 0; i < this.vehiclesVisitors.length; i++) {
            const element = this.vehiclesVisitors[i];
            
            listVehicles.push({uuid: element.uuid})
          }
        }

        axios
          .post(globals.APIURL + "vehicle/unlink-all-vehicles", {
            vehicles: listVehicles,
            company_id: this.employeeList == true ? auth.getUserEnterpriseID() : null,
            campus_id: this.employeeList == true ? auth.getSelectedSede() : null
          })
          .then((res) => {
            if (res.data && res.data == "Vehicles unlinked successfuly") {
              console.log(res.data)
              this.snackbarText = "Se desvincularón los vehículos exitosamente.";
              this.snackbarColor = "green";
              this.snackbar = true;
              this.unlink_validation = ""
              this.dialogUnlinkAll = false;
              this.getVehicles(this.registryData.uuid);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.unlink_validation = ""
        this.snackbarText = "Por favor verifique que la palabra clave sea correcta.";
        this.snackbarColor = "red";
        this.snackbar = true;
      }
    }
  },
  mounted() {
    console.log("PERSONA: ", this.registryData);
    this.getVehicles(this.registryData.uuid);
  },
};
</script>
<style>
.border-left {
  border-left: 2px dotted #9e9e9e;
}
.uppertext-field input {
  text-transform: uppercase;
}
.border-radius {
  border-radius: 10px;
}
</style>
