var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.company_campus)?_c('v-container',{staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('p',{staticClass:"text-center text-h3 font-weight-bold grey--text"},[_vm._v(_vm._s(_vm.company_campus.Campus[0].name))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-card',{staticClass:"full-height pointer",attrs:{"elevation":"24","title":"IR AL DASHBOARD DE ACCESOS."},on:{"click":function($event){return _vm.goTo('access')}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-h4 text-center"},[_vm._v("Módulo Peatonal")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("Ingresos Hoy ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',{staticClass:"primary--text pr-0 mr-0 text-h3"},[_vm._v(_vm._s(_vm.aforo))]),_c('span',{staticClass:"grey--text pl-0 ml-0 text-caption",attrs:{"justify":"end"}},[_vm._v("Usuarios")])])],1)],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("Temps. Altas Hoy ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',{staticClass:"red--text pr-0 mr-0 text-h3"},[_vm._v("2")]),_c('span',{staticClass:"grey--text pl-0 ml-0 text-caption",attrs:{"justify":"end"}},[_vm._v("Usuarios")])])],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('dash-chart',{staticClass:"graphic-container",attrs:{"label":"Ingresos","chartData":{
                              shortUnitMeasure: 'Pers',
                              allData: _vm.people,
                              color: _vm.colorHex,
                          }}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-card',{staticClass:"full-height pointer",attrs:{"elevation":"24","title":"IR AL DASHBOARD DE VEHICULOS"},on:{"click":function($event){return _vm.goTo('vehicle')}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-h4 text-center"},[_vm._v("Módulo Vehícular")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("Empleados ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',{staticClass:"primary--text pr-0 mr-0 text-h3"},[_vm._v("959")]),_c('span',{staticClass:"grey--text pl-0 ml-0 text-caption",attrs:{"justify":"end"}},[_vm._v("Vehículos")])])],1)],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("visitantes ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',{staticClass:"red--text pr-0 mr-0 text-h3"},[_vm._v("0")]),_c('span',{staticClass:"grey--text pl-0 ml-0 text-caption",attrs:{"justify":"end"}},[_vm._v("Vehículos")])])],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('dash-chart',{staticClass:"graphic-container",attrs:{"label":"Ingresos","chartData":{
                              shortUnitMeasure: 'Vehi',
                              allData: _vm.people,
                              color: 'red',
                          }}})],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }