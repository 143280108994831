<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  data() {
    return {
      intervalChart: "",
      dataLabels: Array,
      dataValues: [],
      dataValues2: [],
      maxNumber: null
    };
  },
  props: {
    label: {
      type: String,
    },
    rangeDataTime: {
      type: String
    },
    chartData: {
      type: Object,
    },
  },
  methods: {
    makeChart() {
      let finalObj = {}
      let finalObj2 = {}
      this.chartData.entries.forEach((elemen) => {
        let date
        (this.rangeDataTime === "hour") ? date = elemen.created_time.slice(11, elemen.created_time.length - 11) : date = elemen.created_time.slice(5, 10)
        if (finalObj[date]) {
          finalObj[date] = finalObj[date] + 1;
        } else {
          finalObj[date] = 1;
        }
      })

      this.chartData.exits.forEach((elemen) => {
        let date
        (this.rangeDataTime === "hour") ? date = elemen.created_time.slice(11, elemen.created_time.length - 11) : date = elemen.created_time.slice(5, 10)
        if (finalObj2[date]) {
          finalObj2[date] = finalObj2[date] + 1;
        } else {
          finalObj2[date] = 1;
        }
      })

      let unitedObjs = {...finalObj, ...finalObj2}

      if (this.rangeDataTime === "hour") {
        this.dataLabels = Object.keys(unitedObjs).slice().sort((a,b)=>a-b);
      } else {
        this.dataLabels = Object.keys(unitedObjs).slice().sort();
      }

      this.dataLabels.forEach(element => {
        finalObj[element] = (finalObj[element]) ? finalObj[element] : 0
        finalObj2[element] = (finalObj2[element]) ? finalObj2[element] : 0
        this.dataValues.push(finalObj[element])
        this.dataValues2.push(finalObj2[element])
      });

      if (this.rangeDataTime === 'hour') {
        for (let i = 0; i < this.dataLabels.length; i++) {
          this.dataLabels[i] = this.dataLabels[i] + ":00"
        }  
      }

      let allDataValues = [...this.dataValues, ...this.dataValues2]
      this.maxNumber = Math.max.apply(null, allDataValues)
      // -----------------------------------------------------------------

      this.renderChart(
        {
          labels: this.dataLabels, 
          datasets: [
            {
              data: this.dataValues,
              fill: true,
              fillColor: 'pink',
              backgroundColor: 'rgba(66, 184, 131, 0.03)',
              borderColor: this.chartData.color,
              borderWidth: 2.5,
              borderDash: [],
              borderDashOffset: 0.5,
              pointBackgroundColor: this.chartData.color,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: this.chartData.color,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 3,
            },
            {
              data: this.dataValues2,
              fill: true,
              fillColor: 'pink',
              backgroundColor: 'rgba(66, 184, 131, 0.03)',
              borderColor: '#F87171',
              borderWidth: 2.5,
              borderDash: [],
              borderDashOffset: 0.5,
              pointBackgroundColor: '#F87171',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#F87171',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 3,
            },
          ],
        },
        {
          maintainAspectRatio: false,
          animation: {
            duration: 0, // general animation time
          },
          hover: {
            animationDuration: 0, // duration of animations when hovering an item
          },
          responsiveAnimationDuration: 0,
          responsive: true,
          elements: {
            line: {
              tension: 0.2,
            },
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: "rgba(29,140,248,0.0)",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  suggestedMax: this.maxNumber,
                  padding: 5,
                  fontColor: "rgb(153, 151, 151)",
                  callback: function (value) {
                    return value;
                  },
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: "rgba(0,242,195,0.1)",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 5,
                  fontColor: "rgb(153, 151, 151)"
                },
              },
            ],
          },
          title: {
            display: false,
          },
          tooltips: {
            backgroundColor: "#f5f5f5",
            titleFontColor: "#333",
            bodyFontColor: "#666",
            bodySpacing: 4,
            xPadding: 12,
            intersect: 0,
            position: "nearest",
            mode: "nearest",
            enable: true,
            callbacks: {
              title: function (tooltipItems) {
                return tooltipItems[0].xLabel;
              },
            },
          },
        }
      );
    },

    callDataChartEvery() {
      this.intervalChart = window.setInterval(
        function () {
          this.makeChart();
          this.dataValues = []
          this.dataValues2 = []
        }.bind(this),
        5000
      );
    },
  },
  mounted() {
    this.callDataChartEvery();
    this.makeChart();
  },
  beforeDestroy() {
    clearInterval(this.intervalChart);
  },
};
</script>
