<template>
  <v-container fluid>
    <v-row class="align-center">
      <v-col cols="12" md="3" class="d-flex justify-center">
        <v-select
          v-model="group"
          :items="groupsList"
          item-text="name"
          return-object
          label="Grupo"
          outlined
          dark
          color="secondary"
          hide-details="auto"
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="3" class="d-flex justify-center">
        <v-select
          v-model="year"
          :items="years"
          item-text="y"
          item-disabled="dis"
          label="Año"
          outlined
          dark
          color="secondary"
          hide-details="auto"
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" class="d-flex justify-center">
        <v-select
          v-model="month"
          :items="months"
          item-text="name"
          item-value="id"
          label="Mes"
          outlined
          dark
          color="secondary"
          hide-details="auto"
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" class="d-flex justify-center">
        <v-select
          v-model="type"
          :items="levels"
          item-text="text"
          item-value="id"
          label="Quincena"
          outlined
          dark
          color="secondary"
          hide-details="auto"
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" class="d-flex justify-center">
        <v-btn
          color="primary"
          rounded
          block
          @click="filterData(year, group, month, type)"
        >
          Filtrar <v-icon class="mx-2" dark>fal fa-search</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        v-if="!peopleList || peopleList.length == 0"
        class="d-flex justify-center"
      >
        <p
          v-if="
            loading == false &&
            groupSelected &&
            (!peopleList || peopleList.length === 0)
          "
        >
          No se encontrarón datos.
        </p>
        <p v-if="loading == false && !group && !month && !type">
          Filtre su información
        </p>
        <span
          v-if="loading == true && (!peopleList || peopleList.length === 0)"
          class="text-center"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            :indeterminate="loading"
          >
          </v-progress-circular>
          <p class="pt-2 text-subtitle-2">Cargando Información...</p>
        </span>
      </v-col>
      <v-col
        cols="12"
        v-if="groupSelected && peopleList && peopleList.length > 0"
      >
        <div>
          <v-data-table
            :headers="headers"
            :items="peopleList"
            :search="search"
            class="dtTable"
            :items-per-page="20"
            :header-props="{
              mobile: true,
            }"
            :footer-props="{
              'items-per-page-options': [20],
            }"
            loading-text="Cargando Información..."
            :no-results-text="`No se encontrarón personas por la cedula ingresada (${search})`"
            no-data-text="No hay registros que mostrar"
          >
            <template v-slot:top style="background-color: #272e3d">
              <v-toolbar flat style="background-color: #272e3d">
                <v-toolbar-title>
                  <div class="text-h5 font-weight-bold secondary--text">
                    {{ groupSelected.name }}
                  </div>
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="fal fa-search"
                  label="Filtrar empleados por cedula"
                  dense
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr
                class="pointer"
                @click="selectPerson(year, group, month, item)"
              >
                <td class="text-center">
                  <label class="text-body-2"
                    ><small>{{
                      item.first_name +
                      " " +
                      (item.second_name ? item.second_name + " " : "") +
                      item.first_last_name +
                      " " +
                      item.second_last_name
                    }}</small></label
                  >
                </td>
                <td class="text-center">
                  <label class="text-body-2"
                    ><small>{{ item.document_number }}</small></label
                  >
                </td>
                <td
                  v-for="(th, i) in headers2"
                  :key="i"
                  class="text-center"
                  :style="
                    item.entries[i].entradaSalida.dia === 'Domingo'
                      ? 'background-color: #BBDEFB;'
                      : ''
                  "
                >
                  <label class="text-body-2">
                    <small
                      v-if="
                        item.entries[i].entradaSalida.horasTrabajadas &&
                        item.entries[i].entradaSalida.horasTrabajadas > 0
                      "
                      :style="
                        item.entries[i].entradaSalida.dia === 'Domingo'
                          ? 'color: #414544;'
                          : ''
                      "
                    >
                      {{ item.entries[i].entradaSalida.horasTrabajadas }}
                    </small>
                    <small
                      v-if="
                        item.entries[i].entradaSalida.entrada &&
                        item.entries[i].entradaSalida.salida &&
                        item.entries[i].entradaSalida.horasTrabajadas === 0
                      "
                      :style="
                        item.entries[i].entradaSalida.dia === 'Domingo'
                          ? 'color: #414544;'
                          : ''
                      "
                    >
                      {{ lessThanHour }}
                    </small>
                    <v-tooltip
                      max-width="250"
                      color="#78909C"
                      v-if="
                        !item.entries[i].entradaSalida.horasTrabajadas &&
                        !item.entries[i].entradaSalida.entrada &&
                        !item.entries[i].entradaSalida.salida &&
                        item.entries[i].entradaSalida.permit != null
                      "
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <small
                          v-bind="attrs"
                          v-on="on"
                          class="primary--text text-decoration-underline"
                        >
                          {{
                            dateNow.substring(6, 10) >
                              item.entries[i].entradaSalida.fecha.substring(
                                6,
                                10
                              ) &&
                            item.entries[i].entradaSalida.dia !== "Domingo"
                              ? "AUS"
                              : "---"
                          }}
                        </small>
                      </template>
                      <div>
                        <p class="text-center ma-0 pa-0">
                          <strong>EVENTUALIDAD</strong>
                        </p>
                        <p class="ma-0 pa-0">
                          {{
                            item.entries[i].entradaSalida.permit != null
                              ? item.entries[i].entradaSalida.permit.description
                              : ""
                          }}
                        </p>
                      </div>
                    </v-tooltip>
                    <small
                      v-if="
                        !item.entries[i].entradaSalida.horasTrabajadas &&
                        !item.entries[i].entradaSalida.entrada &&
                        !item.entries[i].entradaSalida.salida &&
                        !item.entries[i].entradaSalida.permit
                      "
                      :style="
                        item.entries[i].entradaSalida.dia === 'Domingo'
                          ? 'color: #414544;'
                          : ''
                      "
                    >
                      {{
                        dateNow.substring(6, 10) >
                          item.entries[i].entradaSalida.fecha.substring(
                            6,
                            10
                          ) && item.entries[i].entradaSalida.dia !== "Domingo"
                          ? "AUS"
                          : "---"
                      }}
                    </small>
                    <small
                      v-if="
                        !item.entries[i].entradaSalida.horasTrabajadas &&
                        !item.entries[i].entradaSalida.entrada &&
                        item.entries[i].entradaSalida.salida
                      "
                      :style="
                        item.entries[i].entradaSalida.dia === 'Domingo'
                          ? 'color: #414544;'
                          : ''
                      "
                    >
                      {{ "E-I" }}
                    </small>
                    <small
                      v-if="
                        !item.entries[i].entradaSalida.horasTrabajadas &&
                        item.entries[i].entradaSalida.entrada &&
                        !item.entries[i].entradaSalida.salida
                      "
                      :style="
                        item.entries[i].entradaSalida.dia === 'Domingo'
                          ? 'color: #414544;'
                          : ''
                      "
                    >
                      {{ "S-I" }}
                    </small>
                  </label>
                </td>
                <td class="text-center">
                  <label
                    class="text-body-2"
                    :class="groupShift === 'day' ? 'shift-day' : 'shift-night'"
                  >
                    <small
                      :class="
                        (groupSelected.overtime &&
                          item.totalWorkedTime >
                            groupSelected.fortnight_working_time) ||
                        item.totalWorkedTime <=
                          groupSelected.fortnight_working_time
                          ? 'success--text'
                          : 'red--text'
                      "
                    >
                      {{ item.totalWorkedTime + daysToApplyAut }}
                    </small>
                    /
                    <small>{{
                      fortnightHours && fortnightHours > 0
                        ? fortnightHours
                        : groupSelected.fortnight_working_time
                    }}</small>
                  </label>
                </td>
                <td class="text-center" style="background-color: #ffcdd2;">
                  <label class="text-body-2"
                    ><small style="color: #414544">{{ item.totalAbsences }}</small></label
                  >
                </td>
                <td class="text-center">
                  <label class="text-body-2"
                    ><small>{{ item.totalExtraHours }}</small></label
                  >
                </td>
                <td class="text-center">
                  <label class="text-body-2"
                    ><small>{{ item.totalNightHours }}</small></label
                  >
                </td>
                <td class="text-center">
                  <label class="text-body-2"
                    ><small>{{ item.totalSundayHours }}</small></label
                  >
                </td>
                <td class="text-center">
                  <label class="text-body-2"
                    ><small>{{ item.totalFestiveHours }}</small></label
                  >
                </td>
                <td class="text-center">
                  <label class="text-body-2"
                    ><small>{{ item.entryButNotExit }}</small></label
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="2" class="d-flex justify-center">
        <v-select
          v-model="typeChartFilter"
          :items="filterTypes"
          item-text="text"
          item-value="id"
          dense
          label="Tipo de filtrado"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="3" class="d-flex justify-center">
        <v-select
          v-if="typeChartFilter"
          v-model="group"
          :items="groupsList"
          item-text="name"
          return-object
          dense
          label="Grupo"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-if="typeChartFilter"
          v-model="month"
          :items="months"
          item-text="name"
          item-value="id"
          dense
          label="Mes"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" class="d-flex justify-center">
        <v-text-field
            v-if="typeChartFilter == '02'"
            v-model="personDocument"
            label="Cédula empleado"
            hide-details
            outlined
            dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2" class="d-flex justify-center">
        <v-btn v-if="typeChartFilter" color="primary" dark block @click="filterChartData(year, group, month, personDocument)">
          Generar Gráfico <v-icon class="mx-1" dark> mdi-chart-bar </v-icon>
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col
        cols="12"
        v-if="!chartDataArray || chartDataArray.length == 0"
        class="d-flex justify-center"
      >
        <p
          v-if="
            loadingChart == false &&
            groupSelected &&
            (!chartDataArray || chartDataArray.length === 0)
          "
        >
          No se encontrarón datos.
        </p>
        <span
          v-if="
            loadingChart == true &&
            (!chartDataArray || chartDataArray.length === 0)
          "
          class="text-center"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            :indeterminate="loadingChart"
          >
          </v-progress-circular>
          <p class="pt-2 text-subtitle-2">Cargando Grafico...</p>
        </span>
      </v-col>

      <v-col cols="12">
        <bar-chart
          v-if="chartDataArray.length > 0"
          style="
            height: 400px !important ;
            width: 100% !important ;
            position: 'relative' !important;
          "
          label="Resumen"
          :chartData="{
            month: headers2,
            arrayData: chartDataArray,
          }"
        ></bar-chart>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import globals from "../../globals";
import auth from "../../services/auth";
// TODO: DESCOMENTAR CUANDO SE HAGA GRAFICOS import BarChart from "./charts/bar.vue"
import BarChart from "./charts/bar.vue";

export default {
  name: "DashReport",
  // TODO: DESCOMENTAR CUANDO SE HAGA GRAFICOS
  components: {
    BarChart,
  },
  props: {
    groupsList: Array,
  },
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    search: "",
    group: null,
    year: null,
    month: null,
    type: null,
    typeChartFilter: null,
    loadingChart: false,
    personDocument: "",
    groupSelected: null,
    peopleList: [],
    dateNow: globals.COdate(),
    loading: false,
    lessThanHour: "< 1",
    groupShift: "",
    headers: [],
    headers1: [
      {
        text: "Nombre",
        align: "center",
        sortable: false,
        value: "nombre",
      },
      {
        text: "Cédula",
        align: "center",
        value: "document_number",
        sortable: false,
      },
    ],
    headers2: [],
    headers3: [
      {
        text: "Horas trabajadas",
        align: "center",
        value: "totalWorkedTime",
        sortable: false,
      },
      {
        text: "Total ausentismos",
        align: "center",
        value: "totalAbsences",
        sortable: false,
      },
      {
        text: "Total horas extra",
        align: "center",
        value: "totalExtraHours",
        sortable: false,
      },
      {
        text: "Total horas ex. nocturnas",
        align: "center",
        value: "totalNightHours",
        sortable: false,
      },
      {
        text: "Total horas dominicales",
        align: "center",
        value: "totalSundayHours",
        sortable: false,
      },
      {
        text: "Total horas Festivas",
        align: "center",
        value: "totalFestiveHours",
        sortable: false,
      },
      {
        text: "Total R-I",
        align: "center",
        value: "entryButNotExit",
        sortable: false,
      },
    ],
    years: [
      { y: "2021", dis: false },
      { y: "2022", dis: false },
      { y: "2023", dis: false },
      { y: "2024", dis: false },
      { y: "2025", dis: false },
    ],
    months: [
      { id: "01", name: "Enero" },
      { id: "02", name: "Febrero" },
      { id: "03", name: "Marzo" },
      { id: "04", name: "Abril" },
      { id: "05", name: "Mayo" },
      { id: "06", name: "Junio" },
      { id: "07", name: "Julio" },
      { id: "08", name: "Agosto" },
      { id: "09", name: "Septiembre" },
      { id: "10", name: "Octubre" },
      { id: "11", name: "Noviembre" },
      { id: "12", name: "Diciembre" },
    ],
    levels: [
      { id: "09", text: "1ra" },
      { id: "18", text: "2da" },
    ],
    filterTypes: [
      { id: "01", text: "Grupo de Trabajo" },
      { id: "02", text: "Persona" },
    ],
    chartDataArray: [],
    fortnightHours: null,
    daysToApplyAut: 0,
  }),
  methods: {
    checkIsYearDisabled() {
      for (let i = 0; i < this.years.length; i++) {
        const element = this.years[i];
        if (element.y > this.dateNow.substring(0, 4)) {
          element.dis = true;
        }
      }
    },

    selectPerson(year, group, month, item) {
      let hoursInDay;
      let datesToMilliseconds;
      if (this.groupShift === "day") {
        datesToMilliseconds =
          new Date("1990-01-01T" + group.finish_hour + ":00") -
          new Date("1990-01-01T" + group.start_hour + ":00");
        hoursInDay = Math.floor((datesToMilliseconds % 86400000) / 3600000); // Horas
      } else if (this.groupShift === "night") {
        datesToMilliseconds =
          new Date("1990-01-02T" + group.finish_hour + ":00") -
          new Date("1990-01-01T" + group.start_hour + ":00");
        hoursInDay = Math.floor((datesToMilliseconds % 86400000) / 3600000); // Horas
      }
      group.eachDayHours = hoursInDay;
      group.shift = this.groupShift;
      this.filterChartData(year, group, month, true, item);
    },

    checkTypeOfShift(start_hour) {
      if (start_hour >= "05:00" && start_hour < "12:00") {
        return "day";
      } else if (
        (start_hour >= "18:00" && start_hour < "23:59") ||
        (start_hour >= "00:00" && start_hour < "05:00")
      ) {
        return "night";
      } else {
        return "day";
      }
    },

    filterData(year, group, month, some) {
      this.groupShift = this.checkTypeOfShift(group.start_hour);
      let hoursInDay;
      let datesToMilliseconds;
      if (this.groupShift === "day") {
        datesToMilliseconds =
          new Date("1990-01-01T" + group.finish_hour + ":00") -
          new Date("1990-01-01T" + group.start_hour + ":00");
        hoursInDay = Math.floor((datesToMilliseconds % 86400000) / 3600000); // Horas
      } else if (this.groupShift === "night") {
        datesToMilliseconds =
          new Date("1990-01-02T" + group.finish_hour + ":00") -
          new Date("1990-01-01T" + group.start_hour + ":00");
        hoursInDay = Math.floor((datesToMilliseconds % 86400000) / 3600000); // Horas
      }
      this.loading = true;
      this.peopleList = [];
      this.groupSelected = null;
      let fecha = year + "-" + month + "-" + some;

      let listPeople = [];
      group.company_person.forEach((element) => {
        listPeople.push({ uuid: element.person_id });
      });
      group.eachDayHours = hoursInDay;
      group.shift = this.groupShift;
      this.filterChartData(year, group, month, false, null);
      axios
        .post(globals.APIURL + "working-group/list-assigned-people-by-date", {
          campus_id: auth.getSelectedSede(),
          company_id: auth.getUserEnterpriseID(),
          people: listPeople,
          fecha: fecha,
          groupSelected: group,
        })
        .then((res) => {
          console.log(res);
          if (res.data && res.data.length > 0) {
            let validateEntriesRandom = Math.floor(
              Math.random() * res.data.length
            );
            if (
              (!res.data[0].entries || res.data[0].entries.length == 0) &&
              (!res.data[res.data.length - 1].entries ||
                res.data[res.data.length - 1].entries.length == 0) &&
              (!res.data[validateEntriesRandom].entries ||
                res.data[validateEntriesRandom].entries.length == 0)
            ) {
              this.groupSelected = group;
              this.peopleList = [];
              this.headers2 = [];
              this.loading = false;
            } else {
              this.validateFortnightTime(
                group,
                hoursInDay,
                res.data[0].entries
              );
              this.groupSelected = group;
              this.peopleList = res.data;
              this.headers2 = [];
              this.populateHeaders(res.data[0].entries);
              this.loading = false;
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    validateFortnightTime(group, hoursInDay, fortnightList) {
      let workingDays = group.work_days.filter((x) => x === true).length;
      let daysToOmit = [];
      let counter = 0;
      let counterOtherDays = 0;
      if (workingDays === 5) daysToOmit = ["Sabado", "Domingo"];
      else if (workingDays === 6) daysToOmit = ["Domingo"];

      for (let i = 0; i < fortnightList.length; i++) {
        const element = fortnightList[i];
        if (!daysToOmit.includes(element.entradaSalida.dia)) {
          counter = counter + 1;
        } else {
          counterOtherDays = counterOtherDays + 1;
        }
      }

      this.daysToApplyAut = counterOtherDays * 8;
      this.fortnightHours = counter * (hoursInDay - 1) + counterOtherDays * 8;
    },

    filterChartData(year, group, month, isPerson, personInfo) {
      this.chartDataArray = [];
      this.loadingChart = true;
      let fecha = year + "-" + month + "-01";

      let list = [];

      if (isPerson) {
        list.push({ uuid: personInfo.uuid });
      } else {
        group.company_person.forEach((element) => {
          list.push({ uuid: element.person_id });
        });
      }

      axios
        .post(globals.APIURL + "working-group/chart-data", {
          campus_id: auth.getSelectedSede(),
          company_id: auth.getUserEnterpriseID(),
          people: list,
          fecha: fecha,
          groupSelected: group,
        })
        .then((res) => {
          console.log(res.data);
          this.chartDataArray = res.data.esto;
          this.loadingChart = false;
        })
        .catch((err) => {
          this.loadingChart = false;
          console.log(err);
        });
    },

    populateHeaders(list) {
      console.log(list);
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        this.headers2.push({
          text: element.entradaSalida.fecha.substring(
            8,
            10
          ) /*  + " " + element.entradaSalida.dia.substring(0, 2) */,
          align: "center",
          value: element.entradaSalida.fecha.substring(5, 10),
          sortable: false,
        });
      }

      this.headers = [...this.headers1, ...this.headers2, ...this.headers3];
    },
  },
  mounted() {
    this.checkIsYearDisabled();
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
.shift-day {
  /* //TODO: Validar si queremos ver el color cuando sea turno diurno */
  border-bottom: 3px solid transparent;
}
.shift-night {
  border-bottom: 3px solid purple;
}
</style>
