<template>
  <v-container fluid class="ma-0 pa-0" style="height: 100%">

    <!-- ----------------------- -->
    <v-overlay opacity="1" :value="manyCompanies">
      <div class="ma-0 pa-0 test-1">
        <v-card dark>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="px-5">
                <p>Por favor seleccione el activo en cual desea hacer el agendamiento.</p>
              </v-col>
              <v-col cols="12" class="px-5">
                <v-select
                  v-model="companySelected"
                  :items="companies"
                  item-text="company.name"
                  :rules="[(v) => !!v || 'El campo es obligatorio.']"
                  label="Activo"
                  dense
                  return-object
                  outlined
                  dark
                  color="secondary"
                ></v-select>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  rounded
                  color="secondary"
                  @click="selectCompany(companySelected)"
                >
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <div class="ma-0 pa-0 test-2">
        <v-row class="mx-3">
          <v-col cols="12">
            <p>Por favor seleccione el activo en cual desea hacer el agendamiento.</p>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="companySelected"
              :items="companies"
              item-text="company.name"
              :rules="[(v) => !!v || 'El campo es obligatorio.']"
              label="Activo"
              dense
              return-object
              outlined
              dark
              color="secondary"
            ></v-select>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              rounded
              color="secondary"
              @click="selectCompany(companySelected)"
            >
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-overlay>
    <!-- ----------------------- -->

    <!-- <div class="text-center" v-if="dialogSchedule == true">
      <v-dialog v-model="dialogSchedule" fullscreen>
        <v-card>
          <v-toolbar color="#212734" elevation="1" dark>
            <v-toolbar-title>Agendar Visita</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialogSchedule = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <schedule-create-flow></schedule-create-flow>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div> -->
    <v-overlay opacity="1" :value="dialogSchedule">
      <schedule-create-flow></schedule-create-flow>
    </v-overlay>

    <div class="text-center" v-if="dialogEditSchedule == true">
      <v-dialog v-model="dialogEditSchedule" fullscreen>
        <v-card>
          <v-toolbar color="#212734" elevation="1" dark>
            <v-toolbar-title>Editar Agendamiento</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialogEditSchedule = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col>
                  <label>La agendación previa de un visitante nos ayudara a mejorar la experiencia de usuario al momento de ingresar al activo.</label>
                </v-col>
              </v-row>
              <v-row class="pt-2">
                <v-col cols="12" class="pb-2 px-2">
                  <label class="text-h6">Información del Visitante</label>
                </v-col>
                <v-col cols="12" md="6" class="py-1 px-2">
                  <v-select
                    v-model="selectedSchedule.document_type"
                    :items="documentTypes"
                    item-text="Title"
                    item-value="Type"
                    :rules="[(v) => !!v || 'El campo es obligatorio.']"
                    label="Tipo Documento"
                    outlined
                    dense
                    dark
                    color="secondary"
                    required
                    disabled
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-1 px-3">
                  <v-text-field
                    v-model="selectedSchedule.document_number"
                    :rules="
                      selectedSchedule.tipoDocumento == 'PASS' ? passRules : ccRules
                    "
                    label="Número Documento"
                    outlined
                    dense
                    dark
                    color="secondary"
                    disabled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-1 px-3">
                  <v-text-field
                    v-model="selectedSchedule.firstname"
                    :rules="namesRule"
                    label="Primer Nombre"
                    outlined
                    dark
                    dense
                    color="secondary"
                    disabled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-1 px-3">
                  <v-text-field
                    v-model="selectedSchedule.surname"
                    label="Primer Apellido"
                    :rules="namesRule"
                    outlined
                    dense
                    dark
                    color="secondary"
                    disabled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-1 px-3"  v-if="selectedSchedule.phone">
                  <v-text-field
                    v-model="selectedSchedule.phone"
                    :rules="phoneRules"
                    label="Celular"
                    outlined
                    dense
                    dark
                    color="secondary"
                    disabled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-1 px-3" v-if="selectedSchedule.email">
                  <v-text-field
                    v-model="selectedSchedule.email"
                    label="Correo Electronico"
                    :rules="emailRules"
                    outlined
                    dense
                    dark
                    color="secondary"
                    disabled
                    required
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="6" class="py-1 px-3">
                  <v-text-field
                    v-model="selectedSchedule.reason"
                    :rules="simpleRule"
                    label="Motivo de la visita."
                    outlined
                    dense
                    dark
                    color="secondary"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-1 px-3">
                  <v-text-field
                    v-model="selectedSchedule.who_visit"
                    label="Persona a quién visita."
                    :rules="simpleRule"
                    outlined
                    dense
                    dark
                    required
                    color="secondary"
                  ></v-text-field>
                </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="12" md="4" class="py-1 px-3 d-flex justify-center">
                  <v-checkbox
                    v-model="selectedSchedule.vehicle"
                    label="Con Automovil"
                    color="secondary"
                    class="ma-0"
                    @change="clearVehicleInfo"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" class="py-1 px-3">
                  <v-text-field
                    v-model="selectedSchedule.plate"
                    label="Placa del Automobil."
                    :rules="plateRules"
                    outlined
                    dense
                    dark
                    color="secondary"
                    :disabled="!selectedSchedule.vehicle"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="py-1 px-3">
                  <v-text-field
                    v-model="selectedSchedule.parking_cell"
                    label="Celda Asignada"
                    outlined
                    dense
                    dark
                    color="secondary"
                    :disabled="!selectedSchedule.vehicle"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-2 px-2">
                  <label class="text-h6">Detalles de la Visita</label>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <v-row class="pa-0 ma-0">
                    <v-col cols="12" md="6" class="my-0 py-0">
                      <label class="text-subtitle-1">Intervalo de fechas</label>
                      <v-dialog
                        ref="dialog"
                        v-model="rangeDateModal"
                        :return-value.sync="rangeDates"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateRangeText"
                            prepend-icon="fal fa-calendar-day"
                            readonly
                            outlined
                            dense
                            height="44"
                            dark
                            class="primaryInput"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="rangeDates"
                          no-title
                          scrollable
                          range
                          dark
                          @input="validateRangeDates"
                          :min="minDate"
                          locale="es-MX"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            rounded
                            large
                            outlined
                            class="px-4 mx-2 secondaryBtn"
                            @click="rangeDateModal = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            rounded
                            color="primary"
                            large
                            class="px-4 mx-2 primaryBtn"
                            @click="selectDates"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="3" class="my-0 py-0">
                      <label class="text-subtitle-1">Hora Entrada</label>
                      <input v-model="hour_entry" type="time">
                    </v-col>
                    <v-col cols="12" md="3" class="my-0 py-0">
                      <label class="text-subtitle-1">Hora Salida</label>
                      <input v-model="hour_exit" type="time">
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12" class="text-center">
                  <v-btn
                    :disabled="!valid"
                    rounded
                    color="error"
                    class="px-4 mx-2 mb-2"
                    :loading="loadingScheduleBtn"
                    @click="selectedSchedule = null ; dialogEditSchedule = false"
                  >
                    CANCELAR
                  </v-btn>
                  <v-btn
                    :disabled="!valid"
                    rounded
                    color="primary"
                    class="px-4 mx-2 mb-2 primaryBtn"
                    :loading="loadingScheduleBtn"
                    @click="updateScheduledVisitor(selectedSchedule)"
                  >
                    ACTUALIZAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div> 

    <div class="text-center" v-if="dialogConfiguration == true">
      <v-dialog v-model="dialogConfiguration" fullscreen>
        <v-card>
          <v-toolbar color="#212734" elevation="1" dark>
            <v-toolbar-title>Perfil</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialogConfiguration = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <person-info></person-info>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-row class="mx-0 px-0">
      <v-col cols="12" md="6" class="d-flex justify-start mx-0 px-0">
        <v-btn color="secondary" text>
          <i class="fal fa-sign-out ml-1" style="font-size: 25px" @click="signOut"></i>
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end mx-0 px-0">
        <v-btn v-if="typeView == 'schedule'" color="secondary" text @click="typeView = 'profile'">
          <i class="fal fa-user mr-1" style="font-size: 13px"></i> Perfil 
        </v-btn>
        <v-btn v-if="typeView == 'profile'" color="secondary" text @click="manyCompanies = true ; companySelected = null">
          <i class="fal fa-calendar-day mr-1" style="font-size: 13px"></i> Agendamiento
        </v-btn>
        <v-btn v-if="typeView == 'schedule'" color="secondary" text @click="dialogSchedule = true">
          <i class="fal fa-plus mr-1" style="font-size: 13px"></i> Agendar
          Visitante
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pt-5 px-3" v-if="typeView == 'schedule'">
      <v-col cols="12" class="d-flex align-center">
        <label class="text-h6 ml-1" :class="isNewList ? 'text-weight-bold white--text' : ''" style="cursor: pointer" @click="isNewList = true">Agendados /</label><label class="text-h6 ml-1" :class="!isNewList ? 'text-weight-bold white--text' : ''" style="cursor: pointer" @click="isNewList = false">Historico</label>
      </v-col>
      <v-row class="ma-0 pa-0" v-if="isNewList">
        <v-col cols="12" class="pa-0 ma-0">
          <v-divider></v-divider>
        </v-col>
        <v-row v-if="scheduleList.length > 0" class="ma-0 pa-0">
          <v-col v-for="(item, i) in scheduleList" :key="i" cols="12" md="3" sm="4">
            <v-card elevation="4" @click="selectEditSchedule(item)">
              <v-card-text>
                <p class="font-weight-black">Invitado: </p>
                <label class="ml-3 font-weight-bold">{{item.document_number + " - " + item.firstname + " " + item.surname}}</label>
                <p class="font-weight-black">Tiempo Hábil de Ingreso: </p>
                <p class="success--text ml-3">{{item.start_date.substr(0, 10)  + " / " + item.start_date.substring(11, 16) + " A " + item.finish_date.substr(0, 10) + " / " + item.finish_date.substring(11, 16)}}</p>
                <!-- <p class="font-weight-black">Motivo Visita: </p>
                <label class="ml-3 font-weight-bold">{{item.reason ? item.reason : "---"}}</label>
                <p class="font-weight-black">A quién visita: </p>
                <label class="ml-3 font-weight-bold">{{item.who_visit ? item.who_visit : "---"}}</label> -->
                <label class="font-weight-black" style="display: grid; grid-template-columns: 1fr 1fr 1fr">
                  <p class="font-weight-black">Automovil</p>
                  <p v-if="item.vehicle" class="font-weight-black">Placa</p>
                  <p v-if="item.vehicle" class="font-weight-black">Celda</p>
                </label>
                <p class="font-weight-black" style="display: grid; grid-template-columns: 1fr 1fr 1fr">
                  <label class="ml-3 font-weight-bold">{{item.vehicle ? "SI" : "NO"}}</label>
                  <label v-if="item.vehicle" class="ml-3 font-weight-bold">{{item.plate}}</label>
                  <label v-if="item.vehicle" class="ml-3 font-weight-bold">{{item.parking_cell}}</label>
                </p>
                <!-- <p class="font-weight-black">Estado Invitación: </p> // TODO: Se comenta mientras se crea una forma de validar si se acepto la invitacion.
                <label class="ml-3 font-weight-bold red--text">
                  <v-icon small color="red">fal fa-times-circle</v-icon>  Sin Aceptar
                </label>
                <label class="ml-3 font-weight-bold success--text">
                  <v-icon small color="success">fal fa-check-circle</v-icon> Aceptada
                </label> -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-col v-if="!scheduleList || scheduleList.length == 0" cols="12" class="mt-10 text-center">
            <i
              class="fal fa-user-clock grey--text ma-4"
              style="font-size: 40px"
            ></i>
            <p class="h5-text grey--text">No hay agendamientos proximos que mostrar.</p>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" v-if="!isNewList">
        <v-col cols="12" class="pa-0 ma-0">
          <v-divider></v-divider>
        </v-col>
        <v-row v-if="scheduleOldList.length > 0" class="ma-0 pa-0">
          <v-col v-for="(item, i) in scheduleOldList" :key="i" cols="12" md="3" sm="4">
            <v-card elevation="4">
              <v-card-text>
                <p class="font-weight-black">Invitado: </p>
                <label class="ml-3 font-weight-bold">{{item.document_number + " - " + item.firstname + " " + item.surname}}</label>
                <p class="font-weight-black">Tiempo Hábil de Ingreso: </p>
                <p class="red--text ml-3">{{item.start_date.substr(0, 10)  + " / " + item.start_date.substring(11, 16) + " A " + item.start_date.substr(0, 10) + " / " + item.finish_date.substring(11, 16)}}</p>
                <!-- <p class="font-weight-black">Motivo Visita: </p>
                <label class="ml-3 font-weight-bold">{{item.reason ? item.reason : "---"}}</label>
                <p class="font-weight-black">A quién visita: </p>
                <label class="ml-3 font-weight-bold">{{item.who_visit ? item.who_visit : "---"}}</label> -->
                <label class="font-weight-black" style="display: grid; grid-template-columns: 1fr 1fr 1fr">
                  <p class="font-weight-black">Automovil</p>
                  <p v-if="item.vehicle" class="font-weight-black">Placa</p>
                  <p v-if="item.vehicle" class="font-weight-black">Celda</p>
                </label>
                <p class="font-weight-black" style="display: grid; grid-template-columns: 1fr 1fr 1fr">
                  <label class="ml-3 font-weight-bold">{{item.vehicle ? "SI" : "NO"}}</label>
                  <label v-if="item.vehicle" class="ml-3 font-weight-bold">{{item.plate}}</label>
                  <label v-if="item.vehicle" class="ml-3 font-weight-bold">{{item.parking_cell}}</label>
                </p>
                <!-- <p class="font-weight-black">Estado Invitación: </p> // TODO: Se comenta mientras se crea una forma de validar si se acepto la invitacion.
                <label class="ml-3 font-weight-bold red--text">
                  <v-icon small color="red">fal fa-times-circle</v-icon>  Sin Aceptar
                </label>
                <label class="ml-3 font-weight-bold success--text">
                  <v-icon small color="success">fal fa-check-circle</v-icon> Aceptada
                </label> -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-col v-if="!scheduleOldList || scheduleOldList.length == 0" cols="12" class="mt-10 text-center">
          <i
            class="fal fa-clipboard-list-check grey--text ma-4"
            style="font-size: 40px"
          ></i>
          <p class="h5-text grey--text">No hay agendamientos cumplidos que mostrar.</p>
        </v-col>
      </v-row>
    </v-row>
    <v-row class="pt-5 px-3" v-if="typeView == 'profile'">
      <v-col cols="12">
        <person-info></person-info>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="10000" :color="snackbarColor" top>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
//import {S3} from "aws-sdk";
import globals from "../../globals";
//import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import auth from "../../services/auth";
import PersonInfo from "./person-info.vue"
import ScheduleCreateFlow from "./schedule-create-flow.vue"
//import backlog from "../services/logs";

export default {
  name: "ScheduleVisitor",
  props: ['session_data'],
  components: {
    PersonInfo,
    ScheduleCreateFlow
  },
  data: () => ({
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    simpleRule: [(v) => !!v || "El campo es obligatorio."],
    namesRule: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        /^[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _]*$/.test(v) ||
        "El texto no debe tener caracteres especiales, tildes o números.",
    ],
    ccRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        (v && v.length >= 6) || "El documento debe tener minimo 6 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El documento solo debe tener números",
    ],
    passRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        (v && v.length >= 7) || "El documento debe tener minimo 7 digitos.",
      (v) =>
        /^[0-9A-Za-z _]*[0-9A-Za-z][0-9A-Za-z _]*$/.test(v) ||
        "El documento no debe tener caracteres especiales o tildes.",
    ],
    plateRules: [
      (v) => (v == "" || v.length == 6) || "La placa debe tener 6 caracteres.",
      (v) => 
        (v == "" || (v.charAt(0).toUpperCase() !== v.charAt(0).toLowerCase() &&
          v.charAt(1).toUpperCase() !== v.charAt(1).toLowerCase() &&
          v.charAt(2).toUpperCase() !== v.charAt(2).toLowerCase() &&
          !isNaN(v.charAt(3)) &&
          !isNaN(v.charAt(4)) &&
          !isNaN(v.charAt(5)))) || "La placa esta mal conformada.",
    ],
    phoneRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        (v && v.length == 10) || "El número celular debe tener 10 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El campo solo permite números",
    ],
    emailRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "El correo electronico no esta formado correctamente",
    ],
    documentTypes: [
      { Type: "CC", Title: "Cédula de Ciudadanía" },
      { Type: "CE", Title: "Cédula de Extranjería" },
      { Type: "TI", Title: "Tarjeta de Identidad" },
      { Type: "PASS", Title: "Pasaporte" },
    ],
    /* scheduleForm: {
      tipoDocumento: "",
      document_number: "",
      reason: "",
      who_visit: "",
      firstname: "",
      surname: "",
      phone: "",
      email: "",
      vehicle: false,
      plate: "",
      parking_cell: ""
    }, */
    dialogSchedule: false,
    dialogEditSchedule: false,
    dialogConfiguration: false,
    loadingScheduleBtn: false,
    selectedSchedule: null,
    rangeDateModal: false,
    rangeDates: [globals.COdate().substr(0, 10), globals.COdate().substr(0, 10)],
    minDate: globals.COdate().substr(0, 10),
    hour_entry: "00:00",
    hour_exit: "23:59",
    scheduleList: [],
    scheduleOldList: [],
    selectedList: "new",
    valid: true,
    isAuth: auth.schedule_loggedin(),
    isNewList: true,
    /* group_permission: "", */
    typeView: 'profile',
    manyCompanies: false,
    companySelected: null,
    companies: [],
  }),
  computed: {
    /* config() {
      return {
        bucketName: "vanguard-id",
        region: process.env.VUE_APP_S3_REGION,
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
        signatureVersion: 'v4',
      }
    },

    S3Client() {
      return new S3(this.config)
    } */
    dateRangeText () {
      return this.rangeDates.join(' ~ ')
    },
  },
  methods: {
    clearVehicleInfo(e) {
      if (e === false) {
        this.scheduleForm.plate = ""
        this.scheduleForm.parking_cell = ""
        if (this.selectedSchedule) {
          this.selectedSchedule.plate = "";
          this.selectedSchedule.parking_cell = "";
        }
      }
    },

    signOut() {
      //this.$router.replace({ name: "signIn" })
      auth.scheduleLogout();
      this.isAuth = false;
      this.$router.push("/oneid");
    },

    selectDates() {
      this.$refs.dialog.save(this.rangeDates)
      this.rangeDateModal = false;
    },

    validateRangeDates(rangeDates) {
      if (rangeDates[0] > rangeDates[1]) {
        this.snackbar = true;
        this.snackbarText = "La fecha final no puede ser menor que la inicial."
        this.snackbarColor = "error"
        this.rangeDates = [rangeDates[0], ]
      }
    },

    selectEditSchedule(item) {
      this.selectedSchedule = item;
      this.rangeDates = [item.start_date.substring(0, 10), item.finish_date.substring(0, 10)]
      this.hour_entry = item.start_date.substring(11, 16);
      this.hour_exit = item.finish_date.substring(11, 16);
      this.dialogEditSchedule = true
    },

    /* // TODO: "MOVED TO COMPONENT" clearCreation() {
      this.rangeDates = [globals.COdate().substr(0, 10), globals.COdate().substr(0, 10)];
      this.minDate = globals.COdate().substr(0, 10);
      this.hour_entry = "00:00";
      this.hour_exit = "23:59";
      this.valid = true;
      this.scheduleForm.tipoDocumento = "";
      this.scheduleForm.document_number = "";
      this.scheduleForm.firstname = "";
      this.scheduleForm.surname = "";
      this.scheduleForm.phone = "";
      this.scheduleForm.email = "";
      this.scheduleForm.reason = "";
      this.scheduleForm.who_visit = "";
      this.dialogSchedule = false;
    }, */

    /* // TODO: "MOVED TO COMPONENT" createScheduledVisitor(data) {
      if (data.vehicle == false) {
        data.plate = ""
        data.parking_cell = ""
      }
      if (this.hour_entry && this.hour_exit && this.hour_entry < this.hour_exit) {
        if ((data.vehicle == false && data.plate == "" && data.parking_cell == "") || (data.vehicle == true && data.plate !== "" && data.parking_cell !== "")) {
          let body = {
            uuid: uuidv4(),
            company_id: auth.get_schedule_company_id(),
            sede_id: auth.get_schedule_campus_id(),
            suborg_id: auth.get_schedule_suborg_id(),
            firstname: data.firstname,
            surname: data.surname,
            document_type: data.tipoDocumento,
            document_number: data.document_number,
            phone: data.phone,
            email: data.email,
            reason: data.reason,
            who_visit: data.who_visit,
            vehicle: data.vehicle,
            plate: data.plate !== "" ? data.plate = data.plate.toUpperCase() : "",
            parking_cell: data.parking_cell !== "" ? data.parking_cell = data.parking_cell.toUpperCase() : "",
            start_date: this.rangeDates[0] + "T" + this.hour_entry + ":00.000Z",
            finish_date: this.rangeDates[1] + "T" + this.hour_exit + ":59.000Z",
            created_at: globals.COdate(),
            created_by: auth.get_schedule_person_id(),
            updated_by: ""
          }

          axios({
            method: "POST",
            url: globals.APIURL + "entry/create-schedule-visitor",
            data: {
              schedule: body,
              company_id: auth.get_schedule_company_id(),
              group_id: this.group_permission,
              suborg_id: auth.get_schedule_suborg_id() 
            },
          })
          .then((res) => {
            if (res.status == 200) {
              this.snackbarText = "Se agendo el visitante correctamente.";
              this.snackbarColor = "success";
              this.snackbar = true;
              this.clearCreation()
              this.getScheduling()
            }
          });
        } else {
          this.snackbarText = "Si el visitante trae automovil es necesario ingresar una placa y asignar una celda de estacionamiento.";
          this.snackbarColor = "error";
          this.snackbar = true;
        }
      } else {
        this.snackbarText = "La hora final no puede ser menor a la inicial.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    }, */
    
    updateScheduledVisitor(item) {
      if (item.vehicle == false) {
        item.plate = ""
        item.parking_cell = ""
      }
      if (this.hour_entry && this.hour_exit && this.hour_entry < this.hour_exit) {
        if ((item.vehicle == false && item.plate == "" && item.parking_cell == "") || (item.vehicle == true && item.plate !== "" && item.parking_cell !== "")) {
          console.log("EDICIÓN: ", item)

          item.start_date = this.rangeDates[0] + "T" + this.hour_entry + ":00.000Z"
          item.finish_date = this.rangeDates[1] + "T" + this.hour_exit + ":59.000Z"
          if (item.plate !== "") item.plate = item.plate.toUpperCase();
          if (item.parking_cell !== "") item.parking_cell = item.parking_cell.toUpperCase();
          item.updated_by = auth.get_schedule_person_id();

          axios({
            method: "POST",
            url: globals.APIURL + "entry/update-schedule-visitor",
            data: item,
          })
          .then((res) => {
            console.log(res)
            if (res.status == 200) {
              this.snackbarText = "Se modificó el agendamiento.";
              this.snackbarColor = "success";
              this.snackbar = true;
              this.getScheduling();
              this.selectedSchedule = null;
              this.dialogEditSchedule = false;
              this.rangeDates = [globals.COdate().substr(0, 10), globals.COdate().substr(0, 10)];
              this.minDate = globals.COdate().substr(0, 10);
              this.hour_entry = "00:00";
              this.hour_exit = "23:59";
            }
          });
        } else {
          this.snackbarText = "Si el visitante trae automovil es necesario ingresar una placa y asignar una celda de estacionamiento.";
          this.snackbarColor = "error";
          this.snackbar = true;
        }
      } else {
        this.snackbarText = "La hora final no puede ser menor a la inicial.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    getOldScheduling() {
      axios({
        method: "POST",
        url: globals.APIURL + "entry/get-schedule-list",
        data: {
          "company_id": auth.get_schedule_company_id(),
          "campus_id": auth.get_schedule_campus_id(),
          "suborg_id": auth.get_schedule_suborg_id(),
          "today": globals.COdate(),
          "type": "old"
        },
      })
      .then((res) => {
        this.scheduleOldList = res.data;
      });
    },

    getScheduling() {
      axios({
        method: "POST",
        url: globals.APIURL + "entry/get-schedule-list",
        data: {
          "company_id": auth.get_schedule_company_id(),
          "campus_id": auth.get_schedule_campus_id(),
          "suborg_id": auth.get_schedule_suborg_id(),
          "today": globals.COdate(),
          "type": "new"
        },
      })
      .then((res) => {
        this.scheduleList = res.data;
      });
    }, 

    /* // TODO: "MOVED TO COMPONENT" getPremissionGroups() {
      axios({
        method: "GET",
        baseURL: globals.APIURL + "permission-group/get-pg/" + auth.get_asset_id(),
      }).then((data) => {
        if (data.data.length > 0) {
          for (let i = 0; i < data.data.length; i++) {
            const element = data.data[i];

            if (element.type_group == 'black_list') {
              data.data.splice(i, 1)
            }
            
            if (element.need_acceptance == true) {
              this.group_permission = element.uuid
            }
          }
        }
      }).catch((err) => {
        console.log(err)
      });
    }, */

    selectCompany(company_person) {
      localStorage.setItem("schedule-company-person-id", company_person.uuid);
      localStorage.setItem("schedule-company-id", company_person.company_id);
      localStorage.setItem("schedule-campus-id", company_person.Campus_company_person[0].campus.uuid);
      localStorage.setItem("schedule-suborg-id", company_person.suborgs_person.length == 0 ? '' : company_person.suborgs_person[0].sub_org.uuid);
      this.typeView = 'schedule'
      this.manyCompanies = false;
      this.getOldScheduling();
      this.getScheduling();

      //this.getPremissionGroups();
    },

    fillCompanyList(personData) {
      for (let i = 0; i < personData.company_person.length; i++) {
        const element = personData.company_person[i];
        
        element.company.name = element.company.name.toUpperCase()
        this.companies.push(element)
      }
    },

    getPersonInfo(person_id) {
      axios({
        method: "POST",
        url: globals.APIURL + "employee/fo",
        data: {
          person_id: person_id,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.fillCompanyList(res.data)
        }
      });
    },
  },
  mounted() {
    auth.logout();
    this.getPersonInfo(auth.get_schedule_person_id())
    this.getOldScheduling()
    this.getScheduling()
    //this.getPremissionGroups()
    this.$bus.$on("closeScheduleOverlay", () => {
      this.dialogSchedule = false;
      if (this.isNewList == true) {
        this.getScheduling();
      } else {
        this.getOldScheduling();
      }
    });
  },
};
</script>
<style scoped>
.test-1 {
  display: contents !important;
}
.test-2 {
  display: none !important;
}
/* media query 1000px */
@media (max-width: 1250px) {
  .test-1 {
    display: none !important;
  }
  .test-2 {
    display: contents !important;
  }
}
</style>