<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">REGISTRO USUARIOS</span>
    </v-card-title>
    <v-card-text v-if="initModule">
      <v-row>
        <v-col cols="12" md="6">
          <v-btn
            color="secondary darken-2"
            class="px-4 mx-2"
            block
            :loading="loadingRegistryCreationBtn"
            @click="initModule = false ; generateLink = true"
          >
            GENERAR LINK
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn
            color="grey darken-3"
            class="px-4 mx-2"
            block
            :loading="loadingRegistryCreationBtn"
            disabled
          >
            REGISTRAR PERSONA
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="generateLink">
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            v-model="role"
            :items="permissionGroupsList"
            item-text="name"
            item-value="uuid"
            label="Rol"
            outlined
            dense
            return-object
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="suborg"
            :items="suborgsList"
            item-text="name"
            item-value="uuid"
            label="Autorizador"
            outlined
            dense
            return-object
          ></v-select>
        </v-col>
        <v-col cols="12" md="3" v-if="enterpriseID == 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17'">
          <v-select
            v-model="sedeType"
            :items="specificSedes"
            item-text="name"
            item-value="uuid"
            label="Empresa"
            outlined
            dense
            return-object
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="datepicker2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                v-model="expirationDatePicker"
                label="Fecha de Expiración"
                prepend-inner-icon="mdi-calendar"
                :rules="[(v) => !!v || 'El campo es obligatorio.']"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                dark
                color="secondary"
                required
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="expirationDatePicker"
                @input="datepicker2 = false"
                :min="minDate"
                locale="es-MX"
            ></v-date-picker>
            </v-menu>
        </v-col>
        <v-col cols="12" :md="enterpriseID != 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17' ? 3 : 12" :class="enterpriseID != 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17' ? 'd-flex justify-center' : ''">
          <v-btn
            color="primary"
            class="px-4 primaryBtn"
            :block="enterpriseID == 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17' ? true : false"
            @click="buildLink(role, suborg, expirationDatePicker, sedeType)"
          >
            Generar Link
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="11">
              <v-text-field
                v-model="registryLink"
                label="Link"
                id="generatedLink"
                disabled
                hide-details
                dense
                filled
                color="secondary"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="d-flex justify-center align-center">
              <v-tooltip
                v-model="showCopied"
                top
              >
                <template v-slot:activator="{ attrs }">
                  <v-btn
                    icon
                    class="primary"
                    :disabled="registryLink == ''"
                    outlined
                    @click="copyLink"
                    v-bind="attrs"
                  >
                    <i class="fal fa-copy" style="font-size: 15px;" color="primary"></i>
                  </v-btn>
                </template>
                <span class="d-flex align-center">
                  <i class="fal fa-check-circle mr-1" style="color: #34D399;"></i>
                  Link Copiado
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="manualRegister">
      <!-- FIRST PANEL -->
      <v-row class="pt-3 d-flex justify-center" v-if="firstPanel">
        <div v-if="selfiePhotoModule1 && !selfiePhotoModule2">
          <v-col cols="12" class="d-flex justify-center">
            <div class="text-center">
              <p class="text-h5 pb-2">Toma de Selfie</p>
              <p class="text-body-2">Retire lentes, sombrero, gorra o mascarilla antes de hacer la toma de la selfie.</p>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <take-photo :typeUse="'selfie'" :imageName="'selfie_' + userUUID" v-on:photo-taken-selfie="validateSelfieNew"></take-photo>
          </v-col>
          <v-col cols="12" class="d-flex justify-center ma-0">
            <v-btn
              x-small
              color="grey darken-3"
              @click="selfiePhotoModule1 = !selfiePhotoModule1 ; selfiePhotoModule2 = !selfiePhotoModule2"
            >
              Cambiar Modo
            </v-btn>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="text-center">
            <v-btn
              rounded
              color="primary"
              class="px-4 mx-2 primaryBtn"
              @click="checkSelfie"
              :loading="loadingSelfieBtn"
              :disabled="!s3SelfieKey"
            >
              VALIDAR SELFIE
            </v-btn>
          </v-col>
        </div>
        <div v-if="selfiePhotoModule2 && !selfiePhotoModule1">
          <v-col cols="12" class="d-flex justify-center">
            <div class="text-center">
              <p class="text-h5 pb-2">Toma de Selfie</p>
              <p class="text-body-2">Retire lentes, sombrero, gorra o mascarilla antes de hacer la toma de la selfie.</p>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-icon v-if="selfieImg == null" style="font-size: 120px;" x-large color="grey" dark> mdi-account-box</v-icon>
            <v-img
              v-if="selfieImg != null"
              :src="selfieImg"
              width="250px"
              height="200px"
              class="white--text align-center"
              
              aspect-ratio="2"
              contain
            >
            </v-img>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              color="success"
              class="white--text"
              small
              @click="clickSelfieInput"
            >
              SUBIR SELFIE
            </v-btn>
            <input
              type="file"
              @change="readSelfieFile"
              style="display: none"
              ref="selfieInput"
              accept="image/*"
            />
          </v-col>
          <v-col cols="12" class="d-flex justify-center ma-0">
            <v-btn
              x-small
              color="grey darken-3"
              @click="selfiePhotoModule1 = !selfiePhotoModule1 ; selfiePhotoModule2 = !selfiePhotoModule2"
            >
              Cambiar Modo
            </v-btn>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="text-center">
            <v-btn
              rounded
              color="primary"
              class="px-4 mx-2 primaryBtn"
              @click="validateSelfie"
              :loading="loadingSelfieBtn"
              :disabled="!selfieImg"
            >
              VALIDAR SELFIE
            </v-btn>
          </v-col>
        </div>

        <!-- <v-col cols="12" class="d-flex justify-center">
          <v-icon v-if="selfieImg == null" style="font-size: 100px;" x-large color="grey" dark> mdi-account-box</v-icon>
          <v-img
            v-if="selfieImg != null"
            :src="selfieImg"
            width="400px"
            height="200px"
            class="white--text align-center"
            
            aspect-ratio="2"
            contain
          >
          </v-img>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            color="secondary"
            class="white--text"
            small
            @click="clickSelfieInput"
          >
            SUBIR SELFIE
            <v-icon class="pl-2" small dark> mdi-camera</v-icon>
          </v-btn>
          <input
            type="file"
            @change="readSelfieFile"
            style="display: none"
            ref="selfieInput"
            accept="image/*"
          />
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" class="text-center">
          <v-btn
            rounded
            color="primary"
            large
            class="px-4 mx-2 primaryBtn"
            @click="validateSelfie"
            :loading="loadingSelfieBtn"
            :disabled="!selfieImg"
          >
            VALIDAR IMAGEN
          </v-btn>
        </v-col> -->
      </v-row>
      <!-- PERSON ALREADY EXIST PANEL -->
      <v-row class="pt-3" v-if="registered_person_panel">
        <!-- <v-col cols="12" class="d-flex justify-center">
          <v-img
            v-if="selfieImg != null"
            :src="selfieImg"
            width="250px"
            height="200px"
            class="white--text align-center"
            
            aspect-ratio="2"
            contain
          >
          </v-img>
        </v-col> -->
        <v-col cols="12" class="d-flex justify-center">
          <p v-if="alreadyRegisteredType == 'all'">Esta persona ya se encuentra registrado(a) en la plataforma.</p>
          <p v-else class="text-center">Esta persona ya se encuentra registrado(a) en OneID, es necesario asignar un grupo de permisos para poder hacer uso del sistema de reconocimiento facial.</p>
        </v-col>
        <v-col v-if="alreadyRegisteredType == 'oneid'" cols="12" class="py-1 px-3">
          <v-select
            v-model="registryRole"
            :items="permissionGroupsList"
            item-text="name"
            item-value="uuid"
            label="Grupo de Permisos"
            outlined
            dense
            return-object
          ></v-select>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" class="text-center">
          <v-btn
            rounded
            :color="alreadyRegisteredType === 'all' ? 'primary' : 'error'"
            class="px-4 mx-2 primaryBtn"
            @click="clearRegistry"
          >
            {{ alreadyRegisteredType === 'all' ? 'VOLVER A INICIO' : 'CANCELAR'}}
          </v-btn>
          <v-btn
            v-if="alreadyRegisteredType === 'oneid'"
            rounded
            color="primary"
            class="px-4 mx-2 primaryBtn"
            :loading="loadingRegistryCreationBtn"
            @click="asignCompanyAndPermissionGroup"
          >
            ASIGNAR PERMISOS
          </v-btn>
        </v-col>
      </v-row>
      <!-- SECOND PANEL -->
      <v-row class="pt-3 d-flex justify-center" v-if="secondPanel && registry.tipoDocumento == ''">
        <v-col cols="12">
          <v-select
            v-model="registry.tipoDocumento"
            :items="documentTypes"
            item-text="Title"
            item-value="Type"
            :rules="[(v) => !!v || 'El campo es obligatorio.']"
            label="Seleccione el tipo de documento con el que hará su registro"
            outlined
            dense
            dark
            color="secondary"
            required
          ></v-select>
        </v-col>
      </v-row>
      <!-- SECOND PANEL IMG DOCUMENT -->
      <v-row class="pt-3 d-flex justify-center" v-if="secondPanel && registry.tipoDocumento != ''">
        <div v-if="frontDocumentPhotoModule1 && !frontDocumentPhotoModule2">
          <v-col cols="12" class="d-flex justify-center">
            <div class="text-center">
              <p class="text-h5 pb-2">Documento de Identidad Lado Frontal</p>
              <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
            </div>
          </v-col>
          <v-col v-if="secondPanel && registry.tipoDocumento != ''" cols="12" class="d-flex justify-center">
            <take-photo :typeUse="'frontDoc'" :imageName="'front-doc_' + userUUID" v-on:photo-taken-front="validateFrontPicNew"></take-photo>
          </v-col>
          <v-col cols="12" class="d-flex justify-center ma-0">
            <v-btn
              small
              color="grey darken-3"
              @click="frontDocumentPhotoModule1 = !frontDocumentPhotoModule1 ; frontDocumentPhotoModule2 = !frontDocumentPhotoModule2"
            >
              Cambiar Modo
            </v-btn>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="text-center">
            <!-- <v-btn
              rounded
              color="grey"
              class="px-4 mx-2 primaryBtn"
              @click="secondPanel = false ; firstPanel = true"
            >
              ATRAS
            </v-btn> -->
            <v-btn
              rounded
              color="primary"
              class="px-4 mx-2 primaryBtn"
              @click="checkFrontDoc"
              :loading="loadingFrontDocBtn"
              :disabled="!s3FrontDocKey"
            >
              VALIDAR FOTO FRONTAL
            </v-btn>
          </v-col>
        </div>
        <div v-if="frontDocumentPhotoModule2 && !frontDocumentPhotoModule1">
          <v-col cols="12" class="d-flex justify-center">
            <div class="text-center">
              <p class="text-h5 pb-2">Documento de Identidad Lado Frontal</p>
              <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              v-if="frontDocImg == null"
              src="../assets/doc2-white.png"
              width="300px"
              height="150px"
              class="white--text"
              aspect-ratio="2"
              contain
              >
            </v-img>
            <v-img
              v-if="frontDocImg != null"
              :src="frontDocImg"
              width="250px"
              height="200px"
              class="white--text align-center"
              
              aspect-ratio="2"
              contain
              >
            </v-img>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              color="success"
              class="white--text"
              small
              @click="clickFrontDocInput"
            >
              SUBIR DOCUMENTO LADO FRONTAL
            </v-btn>
            <input
              type="file"
              @change="readFrontDocFile"
              style="display: none"
              ref="frontDocInput"
              accept="image/*"
            />
          </v-col>
          <v-col cols="12" class="d-flex justify-center ma-0">
            <v-btn
              small
              color="grey darken-3"
              @click="frontDocumentPhotoModule1 = !frontDocumentPhotoModule1 ; frontDocumentPhotoModule2 = !frontDocumentPhotoModule2"
            >
              Cambiar Modo
            </v-btn>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="text-center">
            <!-- <v-btn
              rounded
              color="grey"
              large
              class="px-4 mx-2 primaryBtn"
              @click="secondPanel = false ; firstPanel = true"
            >
              ATRAS
            </v-btn> -->
            <v-btn
              rounded
              color="primary"
              class="px-4 mx-2 primaryBtn"
              @click="validateFrontDocumentImg"
              :loading="loadingFrontDocBtn"
              :disabled="!frontDocImg"
            >
              VALIDAR FOTO FRONTAL
            </v-btn>
          </v-col>
        </div>
      </v-row>
      <!-- INTERMEDIARY PANEL -->
      <v-row class="pt-3 d-flex justify-center" v-if="intermediaryPanel">
        <v-col cols="12" class="text-center">
        </v-col>
      </v-row>
      <!-- THIRD PANEL -->
      <v-row class="pt-3 d-flex justify-center" v-if="thirdPanel">
        <div v-if="backDocumentPhotoModule1 && !backDocumentPhotoModule2">
          <v-col cols="12" class="d-flex justify-center">
            <div class="text-center">
              <p class="text-h5 pb-2">Documento de Identidad Lado Posterior</p>
              <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
            </div>
          </v-col>
          <v-col v-if="thirdPanel" cols="12" class="d-flex justify-center">
            <take-photo :typeUse="'backDoc'" :imageName="'back-doc_' + userUUID" v-on:photo-taken-back="validateBackNew"></take-photo>
          </v-col>
          <v-col cols="12" class="d-flex justify-center ma-0">
            <v-btn
              small
              color="grey darken-3"
              @click="backDocumentPhotoModule1 = !backDocumentPhotoModule1 ; backDocumentPhotoModule2 = !backDocumentPhotoModule2"
            >
              Cambiar Modo
            </v-btn>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="text-center">
            <!-- <v-btn
              rounded
              color="grey"
              class="px-4 mx-2 primaryBtn"
              @click="thirdPanel = false ; secondPanel = true"
            >
              ATRAS
            </v-btn> -->
            <v-btn
              rounded
              color="primary"
              class="px-4 mx-2 primaryBtn"
              @click="checkBackDoc"
              :loading="loadingBackDocBtn"
              :disabled="!s3BackDocKey"
            >
              VALIDAR POSTERIOR
            </v-btn>
          </v-col>
        </div>
        <div v-if="backDocumentPhotoModule2 && !backDocumentPhotoModule1">
          <v-col cols="12" class="d-flex justify-center">
            <div class="text-center">
              <p class="text-h5 pb-2">Documento de Identidad Lado Posterior</p>
              <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              v-if="backDocImg == null"
              src="../assets/doc-white.png"
              width="300px"
              height="150px"
              class="white--text"
              aspect-ratio="2"
              contain
            >
            </v-img>
            <v-img
              v-if="backDocImg != null"
              :src="backDocImg"
              width="250px"
              height="200px"
              class="white--text align-center"
              
              aspect-ratio="2"
              contain
            >
            </v-img>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              color="success"
              class="white--text"
              small
              @click="clickBackDocInput"
            >
            SUBIR DOCUMENTO LADO POSTERIOR
            </v-btn>
            <input
              type="file"
              @change="readBackDocFile"
              style="display: none"
              ref="backDocInput"
              accept="image/*"
            />
          </v-col>
          <v-col cols="12" class="d-flex justify-center ma-0">
            <v-btn
              small
              color="grey darken-3"
              @click="backDocumentPhotoModule1 = !backDocumentPhotoModule1 ; backDocumentPhotoModule2 = !backDocumentPhotoModule2"
            >
              Cambiar Modo
            </v-btn>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="text-center">
            <!-- <v-btn
              rounded
              color="grey"
              large
              class="px-4 mx-2 primaryBtn"
              @click="secondPanel = false ; firstPanel = true"
            >
              ATRAS
            </v-btn> -->
            <v-btn
              rounded
              color="primary"
              class="px-4 mx-2 primaryBtn"
              @click="validateBackDocumentImg"
              :loading="loadingBackDocBtn"
              :disabled="!backDocImg"
            >
              VALIDAR FOTO POSTERIOR
            </v-btn>
          </v-col>
        </div>          
      </v-row>
      <!-- FOURTH PANEL -->
      <v-form v-if="fourthPanel" ref="form" v-model="valid" lazy-validation>
        <v-row class="pt-3" v-if="fourthPanel">
          <v-col cols="12" md="12" class="py-1 px-3">
            <p v-if="registry.tipoDocumento == 'CC'" class="mb-2 grey--text">* Valide los datos que se extrajeron del documento y en caso de no ser correctos modifiquelos por favor.</p>
            <p class="mb-2 grey--text">* Todos los campos son obligatorios.</p>
            <p class="mb-2 grey--text">* Es importante que ingrese su numero celular y su correo de manera correcta.</p>
          </v-col>
          <v-col cols="12" md="6" class="py-1 px-3">
            <v-text-field
              v-model="registry.id"
              :rules="registry.tipoDocumento == 'PASS' ? passRules : ccRules"
              label="Número Documento"
              outlined
              dense
              dark
              color="secondary"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-1 px-3">
            <v-menu
              v-model="datepicker1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="expeditionDatePicker"
                  label="Fecha de expedición"
                  prepend-inner-icon="mdi-calendar"
                  :rules="[(v) => !!v || 'El campo es obligatorio.']"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  dark
                  color="secondary"
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="expeditionDatePicker"
                @input="datepicker1 = false"
                locale="es-MX"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3" class="py-1 px-3">
            <v-text-field
              v-model="registry.firstname"
              :rules="namesRule"
              label="Primer Nombre"
              outlined
              dark
              dense
              color="secondary"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="py-1 px-3">
            <v-text-field
              v-model="registry.secondname"
              label="Segundo Nombre"
              :rules="secondNameRule"
              outlined
              dense
              dark
              color="secondary"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="py-1 px-3">
            <v-text-field
              v-model="registry.surname1"
              :rules="namesRule"
              label="Primer Apellido"
              outlined
              dense
              dark
              color="secondary"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="py-1 px-3">
            <v-text-field
              v-model="registry.surname2"
              label="Segundo Apellido"
              :rules="namesRule"
              outlined
              dense
              dark
              color="secondary"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="py-1 px-3">
            <v-select
              v-model="registryRole"
              :items="permissionGroupsList"
              item-text="name"
              item-value="uuid"
              label="Grupo de Permisos"
              outlined
              dense
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" class="py-1 px-3">
            <v-text-field
              v-model="registry.phone"
              :rules="phoneRules"
              label="Celular"
              outlined
              dense
              dark
              color="secondary"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="py-1 px-3">
            <v-text-field
              v-model="registry.email"
              label="Correo Electronico"
              :rules="emailRules"
              outlined
              dense
              dark
              required
              color="secondary"
            ></v-text-field>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="text-center">
            <v-btn
              :disabled="!valid"
              rounded
              color="primary"
              class="px-4 mx-2 mb-2 primaryBtn"
              :loading="loadingRegistryCreationBtn"
              @click="createRegistry(registry)"
            >
              CREAR REGISTRO
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row v-if="QRGenerated" class="pt-5">
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <p class="font-weight-medium text-center text-h6">
            Ubique este código QR en el lector del dispositivo para proceder con
            su registro.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <qrcode-vue
            class="d-flex justify-center align-center"
            :value="QRValue"
            :size="size"
            level="H"
          />
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <v-btn
            block
            color="error"
            class="mr-4"
            @click="
              QRGenerated = false;
              QRValue = '';
              registry.id = ''
            "
          >
            ATRAS
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <v-btn block color="primary" class="mr-4" @click="clearRegistry">
            GENERAR NUEVO QR
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-snackbar v-model="snackbar" timeout="10000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import {S3} from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import globals from "../globals";
import axios from "axios";
import auth from "../services/auth";
import TakePhoto from "../components/utils/take-photo.vue"
import backlog from "../services/logs";

export default {
  name: "UserRegistry",
  components: {
    QrcodeVue,
    TakePhoto
  },
  data: () => ({
    datepicker1: false,
    expeditionDatePicker: "",
    datepicker2: false,
    expirationDatePicker: "",
    QRValue: "",
    size: 250,
    QRGenerated: false,
    valid: true,
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    initModule: true,
    generateLink: false,
    manualRegister: false,
    permissionGroupsList: [],
    suborgsList: [],
    registryLink: "",
    role: null,
    suborg: null,
    showCopied: false,
    minDate: globals.COdate().substr(0, 10),
    namesRule: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => /^[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _]*$/.test(v) || "El texto no debe tener caracteres especiales, tildes o números.",
    ],
    secondNameRule: [
      (v) => /^$|[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _]*$/.test(v) || "El texto no debe tener caracteres especiales, tildes o números.",
    ],
    ccRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => (v && v.length >= 7) || "El documento debe tener minimo 6 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El documento solo debe tener números",
    ],
    passRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => (v && v.length >= 7) || "El documento debe tener minimo 7 digitos.",
      (v) => /^[0-9A-Za-z _]*[0-9A-Za-z][0-9A-Za-z _]*$/.test(v) || "El documento no debe tener caracteres especiales o tildes.",
    ],
    phoneRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => (v && v.length == 10) || "El número celular debe tener 10 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El campo solo permite números",
    ],
    emailRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "El correo electronico no esta formado correctamente",
    ],
    documentTypes: [
      { Type: "CC", Title: "Cédula de Ciudadanía" },
      { Type: "CE", Title: "Cédula de Extranjería" },
      { Type: "TI", Title: "Tarjeta de Identidad" },
      { Type: "PASS", Title: "Pasaporte" },
    ],
    genres: [
      { Type: "M", Title: "MASCULINO" },
      { Type: "F", Title: "FEMENINO" },
    ],
    bloodTypes: ["A+", "B+", "O+", "AB+", "A-", "B-", "O-", "AB-"],
    registry: {
      tipoDocumento: "",
      id: "",
      firstname: "",
      secondname: "",
      surname1: "",
      surname2: "",
      birthdate: "",
      expeditionDate: "",
      sex: "",
      blood: "",
    },
    registryRole: null,
    userUUID: "",
    companyID: "",
    roleID: "",
    suborgID: "",
    sedeID: "",
    sedeType: "",
    enterpriseID: auth.getUserEnterpriseID(),
    specificSedes: [
      { uuid: "1675667679", name: "Transgraneles" },
      { uuid: "1698736479", name: "Agrinal" },
      { uuid: "1675062879", name: "Distraves" },
      { uuid: "1696490079", name: "Super Pollos del Galpón" }
    ],
    loadingSelfieBtn: false,
    loadingFrontDocBtn: false,
    loadingBackDocBtn: false,
    loadingRegistryCreationBtn: false,

    selfieImg: null,
    frontDocImg: null,
    backDocImg: null,

    firstPanel: true,
    registered_person_panel: false,
    secondPanel: false,
    intermediaryPanel: false,
    thirdPanel: false,
    fourthPanel: false,

    selfieBase64: null,
    frontDocBase64: null,
    backDocBase64: null,

    s3SelfieKey: "",
    s3FrontDocKey: "",
    s3BackDocKey: "",

    selfieFile: null,
    frontDocFile: null,
    backDocFile: null,

    registered_person: null,

    selfiePhotoModule1: true,
    selfiePhotoModule2: false,
    frontDocumentPhotoModule1: true,
    frontDocumentPhotoModule2: false,
    backDocumentPhotoModule1: true,
    backDocumentPhotoModule2: false,

    alreadyRegisteredType: 'oneid'
  }),
  computed: {
    config() {
      return {
        bucketName: "vanguard-id",
        region: process.env.VUE_APP_S3_REGION,
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
        signatureVersion: 'v4',
      }
    },

    S3Client() {
      return new S3(this.config)
    }
  },
  methods: {
    //* ----------------------------------------- *//
    activateManualRegistry() {
      this.userUUID = uuidv4()
      if (this.userUUID == '' || this.userUUID == undefined || this.userUUID == null) {
        this.userUUID = uuidv4()
      }
      this.initModule = false; 
      this.manualRegister = true;
    },

    clickSelfieInput() {
      this.$refs.selfieInput.click();
    },

    readSelfieFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.selfieImg = fileReader.result;
        this.resizeAndCompressBase64Img1(this.selfieImg)
      });
      fileReader.readAsDataURL(files[0]);
    },

    resizeAndCompressBase64Img1(base64) {
      let resizingFactor = 0.5;
      const canvas1 = document.createElement("canvas");
      const context1 = canvas1.getContext("2d");
      let img1 = document.createElement("img");
      img1.src = base64;

      const originalWidth = img1.width;
      const originalHeight = img1.height;

      const canvasWidth = originalWidth * resizingFactor;
      const canvasHeight = originalHeight * resizingFactor;

      canvas1.width = canvasWidth;
      canvas1.height = canvasHeight;

      context1.drawImage(
        img1,
        0,
        0,
        originalWidth * resizingFactor,
        originalHeight * resizingFactor
      ); 

      canvas1.toBlob((blob1) => {
        if (blob1) {
          this.selfieFile = blob1;
        } else {
          this.resizeAndCompressBase64Img1(base64)
        }
      },
      "image/jpeg",
      0.6)
    },

    clickFrontDocInput() {
      this.$refs.frontDocInput.click();
    },

    readFrontDocFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.frontDocImg = fileReader.result;
        this.resizeAndCompressBase64Img2(this.frontDocImg)
      });
      fileReader.readAsDataURL(files[0]);
    },

    resizeAndCompressBase64Img2(base64) {
      let resizingFactor = 0.5;
      const canvas2 = document.createElement("canvas");
      const context2 = canvas2.getContext("2d");
      let img2 = document.createElement("img");
      img2.src = base64;

      const originalWidth = img2.width;
      const originalHeight = img2.height;

      const canvasWidth = originalWidth * resizingFactor;
      const canvasHeight = originalHeight * resizingFactor;

      canvas2.width = canvasWidth;
      canvas2.height = canvasHeight;

      context2.drawImage(
        img2,
        0,
        0,
        originalWidth * resizingFactor,
        originalHeight * resizingFactor
      );

      canvas2.toBlob((blob2) => {
        if (blob2) {
          this.frontDocFile = blob2;
        } else {
          this.resizeAndCompressBase64Img2(base64)
        }
      },
      "image/jpeg",
      0.8)
    },

    clickBackDocInput() {
      this.$refs.backDocInput.click();
    },

    readBackDocFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.backDocImg = fileReader.result;
        this.resizeAndCompressBase64Img3(this.backDocImg)
      });
      fileReader.readAsDataURL(files[0]);
    },

    resizeAndCompressBase64Img3(base64) {
      let resizingFactor = 0.5;
      const canvas3 = document.createElement("canvas");
      const context3 = canvas3.getContext("2d");
      let img3 = document.createElement("img");
      img3.src = base64;

      const originalWidth = img3.width;
      const originalHeight = img3.height;

      const canvasWidth = originalWidth * resizingFactor;
      const canvasHeight = originalHeight * resizingFactor;

      canvas3.width = canvasWidth;
      canvas3.height = canvasHeight;

      context3.drawImage(
        img3,
        0,
        0,
        originalWidth * resizingFactor,
        originalHeight * resizingFactor
      );

      canvas3.toBlob((blob3) => {
        if (blob3) {
          this.backDocFile = blob3;
        } else {
          this.resizeAndCompressBase64Img3(base64)
        }
      },
      "image/jpeg",
      0.8)
    },

    validateSelfieNew(data) {
      if (data && data.key) {
        this.s3SelfieKey = data.key;
      }
    },

    validateFrontPicNew(data) {
      if (data && data.key) {
        this.s3FrontDocKey = data.key;
      }
    },

    validateBackNew(data) {
      if (data && data.key) {
        this.s3BackDocKey = data.key
      }
    },

    validateSelfie() {
      this.loadingSelfieBtn = true
      let name = "selfie_" + this.userUUID + ".jpg";

      this.S3Client.upload({
          Bucket: "vanguard-id",
          Key: name,
          Body: this.selfieFile,//Buffer.from(esto, 'base64'),
          ContentType: 'image/jpeg',
        },  (err, data) => {
          if (err) {
            console.log(err)
            this.loadingSelfieBtn = false
          }

          this.s3SelfieKey = data.key;
          this.checkSelfie();
        });
    },

    checkSelfie() {
      this.loadingSelfieBtn = true

      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/face-rekognition-auth",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          login_img_key: this.s3SelfieKey,
        },
      }).then((res) => {
        if (res.data.document_number) {
          console.log(res.data)
          this.firstPanel = false;
          this.registered_person_panel = true;
          this.loadingSelfieBtn = false;
          this.registered_person = res.data;
          for (let i = 0; i < res.data.company_person.length; i++) {
            const element = res.data.company_person[i];
            
            if(element.company_id === auth.getUserEnterpriseID() ) {
              this.alreadyRegisteredType = "all"
            }
          }
        } else {
          this.checkFaceFeatures();
        }
      })
      .catch((err) => {
        if (err.response.data.Message === "No matching faces.") {
          this.checkFaceFeatures();
        } else {
          this.loadingSelfieBtn = false
        }
      });

      /* axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/auth-person",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          login_img_key: this.s3SelfieKey,
        },
      }).then((res) => {
          if (res.data.document_number) {
            console.log(res.data)
            this.firstPanel = false;
            this.registered_person_panel = true;
            this.loadingSelfieBtn = false;
            this.registered_person = res.data.document_number;
          } else {
            this.checkFaceFeatures();
          }
        })
        .catch((err) => {
          if (err.response.data.Message === "No matching faces.") {
            this.checkFaceFeatures();
          } else {
            this.loadingSelfieBtn = false
          }
        }); */
    },

    checkFaceFeatures() {
      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/dvf",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          SelfieImage: this.s3SelfieKey,
        },
      }).then((res) => {
          if (res.data) {
            if (
              res.data.faceAnalysisData.FaceDetails &&
              res.data.faceAnalysisData.FaceDetails.length > 0
            ) {
              if (
                !res.data.ppeData.Summary.PersonsWithRequiredEquipment ||
                res.data.ppeData.Summary.PersonsWithRequiredEquipment.length ==
                  0
              ) {
                if (
                  res.data.faceAnalysisData.FaceDetails[0].Eyeglasses.Value ==
                    false &&
                  res.data.faceAnalysisData.FaceDetails[0].Sunglasses.Value ==
                    false
                ) {
                  this.loadingSelfieBtn = false;
                  this.firstPanel = false;
                  this.secondPanel = true;
                } else {
                  this.loadingSelfieBtn = false
                  this.snackbar = true;
                  this.snackbarColor = "error";
                  this.snackbarText =
                    "La selfie tiene lentes, favor ingresar una sin ellos.";
                }
              } else {
                this.loadingSelfieBtn = false
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText =
                  "La selfie tiene tapabocas, favor ingresar una sin el.";
              }
            } else {
              this.loadingSelfieBtn = false
              this.snackbar = true;
              this.snackbarColor = "error";
              this.snackbarText =
                "La imagen ingresada no tiene un rostro reconocible.";
            }
          }
        })
        .catch((err) => {
          this.loadingSelfieBtn = false
          console.log(err);
        });
    },

    checkFrontDoc() {
      this.loadingFrontDocBtn = true;
      this.secondPanel = false
      //this.intermediaryPanel = true
      // this.compareFaces(); //TODO: COMMENTED FOR A WHILE
      this.documentHasFace();
      if (this.registry.tipoDocumento == "CC") {
        axios({
          method: 'POST',
          baseURL: globals.APIURL + "identity/extract-id-number",
          headers: {
            "Access-Control-Allow-Origin": "*"
          },
          data: {
            FrontDocumentImage: this.s3FrontDocKey,
          },
          }).then((res) => {
            if (res.data) {
              let cedula = res.data.textDetectData.DocumentNumber.replace(/\./gi, "")
              //console.log(res.data)
              //console.log(cedula)
              this.registry.id = cedula ? cedula : "";
              if (cedula !== "") {
                this.searchPersonData(cedula)
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.loadingFrontDocBtn = false;
          });
      }
    },

    searchPersonData(document_number) {
      axios
        .post(globals.APIURL + "identity/find-person-data", {
          document_number: document_number,
        }, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      })
        .then((res) => {
          if (res.status == 200 && res.data) {
            let names = res.data.names
            this.registry.firstname = names[0];
            this.registry.secondname = names.length == 3 ? "" : names[1];
            this.registry.surname1 = names.length == 3 ? names[1] : names[2];
            this.registry.surname2 = names.length == 3 ? names[2] : names[3];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    validateFrontDocumentImg() {
      this.loadingFrontDocBtn = true;
      let name = "front-doc_" + this.userUUID + ".jpg"

      this.S3Client.upload({
        Bucket: "vanguard-id",
        Key: name,
        Body: this.frontDocFile,//Buffer.from(esto, 'base64'),
        ContentType: 'image/jpeg',
      },  (err, data) => {
        if (err) {
          console.log(err)
          this.loadingFrontDocBtn = false
        }

        this.s3FrontDocKey = data.key;
        this.checkFrontDoc()
      });
    },

    documentHasFace() {
      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/vfd",
        headers: {
          "Access-Control-Allow-Origin": "*"
        },
        data: {
          FrontDocumentImage: this.s3FrontDocKey,
        },
      }).then((res) => {
        if (res.data) {
          if (res.data.faceAnalysisData.FaceDetails && res.data.faceAnalysisData.FaceDetails.length > 0) {
            console.log("Respuesta de validacion de rostro: ", res)
            this.loadingFrontDocBtn = false;
            this.secondPanel = false;
            this.intermediaryPanel = false;
            this.thirdPanel = true;
          } else {
            this.loadingFrontDocBtn = false;
            this.secondPanel = true;
            this.intermediaryPanel = false;
            this.snackbar = true;
            this.snackbarColor = "error";
            this.snackbarText = "La imagen del documento no tiene un rostro reconocible, por favor intente nuevamente con otra imagen.";
          }
          }
      }).catch((err) => {
          this.loadingFrontDocBtn = false;
          console.log(err);
      });
    },

    checkBackDoc() {
      this.thirdPanel = false
      this.fourthPanel = true
    },

    validateBackDocumentImg() {
      this.loadingBackDocBtn = true;
      let name = "back-doc_" + this.userUUID + ".jpg";

      this.S3Client.upload({
        Bucket: "vanguard-id",
        Key: name,
        Body: this.backDocFile,//Buffer.from(esto, 'base64'),
        ContentType: 'image/jpeg',
      },  (err, data) => {
        if (err) {
          console.log(err)
          console.log(data)
          this.loadingBackDocBtn = false;
        }

        this.loadingBackDocBtn = false;
        this.thirdPanel = false
        this.fourthPanel = true
      });
    },

    createRegistry(reg) {
      if (this.$refs.form.validate() == true) {
        if (this.registryRole && this.registryRole !== null) {
          this.loadingRegistryCreationBtn = true;
          this.roleID = this.registryRole.uuid;
          reg.expeditionDate = this.expeditionDatePicker
          reg.firstname = reg.firstname.toUpperCase().trim();
          reg.secondname = reg.secondname.toUpperCase().trim();
          reg.surname1 = reg.surname1.toUpperCase().trim();
          reg.surname2 = reg.surname2.toUpperCase().trim();

          axios
            .post(globals.APIURL + "identity/link-register-user", {
              user_data: reg,
              selfie_key: this.s3SelfieKey,
              images_ID: this.userUUID,
              company_id: auth.getUserEnterpriseID(),
              role_id: this.roleID,
              suborg_id: this.suborgID,
            }, {
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
          })
            .then((res) => {
              console.log("RESPUESTA DE LA CREACIÓN: ", res)
              backlog.generateLog({
                enterprise: auth.getUserEnterpriseName(),
                username: auth.getUsername(),
                role: auth.getRoleName(),
                action: "create-person-manually/registry",
                observation: reg.id ? reg.id : ""
              });
              this.clearRegistry()
              this.loadingRegistryCreationBtn = false;
              this.snackbar = true;
              this.snackbarColor = "success";
              this.snackbarText = "Se creo el registro de manera exitosa.";
              this.$bus.$emit("updateRegistry", "Update register list");
            })
            .catch((err) => {
              this.loadingRegistryCreationBtn = false;
              console.log(err);
            });
        } else {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "Debe seleccionar un grupo de permisos";
        }
      }
    },

    asignCompanyAndPermissionGroup() {
      if (this.registryRole && this.registryRole != null) {
        this.loadingRegistryCreationBtn = true
        axios
          .post(globals.APIURL + "identity/asign-company-permissions", {
            company_id: auth.getUserEnterpriseID(),
            role_data: this.registryRole,
            person_info: this.registered_person
          }, {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })
          .then((res) => {
            if (res.status == 200) {
              this.clearRegistry()
              this.loadingRegistryCreationBtn = false;
              this.snackbar = true;
              this.snackbarColor = "success";
              this.snackbarText = "Se asignarón correctamente los permisos.";
              this.$bus.$emit("updateRegistry", "Update register list");
            }
          })
          .catch((err) => {
            this.loadingRegistryCreationBtn = false;
            console.log(err);
          });
      } else {
        this.snackbar = true
        this.snackbarColor = "error"
        this.snackbarText = "Debe seleccionar un grupo de permisos."
      }
    },

    clearRegistry() {
      this.registry.tipoDocumento = "";
      this.registry.id = "";
      this.registry.firstname = "";
      this.registry.secondname = "";
      this.registry.surname1 = "";
      this.registry.surname2 = "";
      this.registry.expeditionDate = "";
      this.registry.sex = "";
      this.registry.blood = "";
      this.registry.phone = "";
      this.registry.email = "";
      this.expeditionDatePicker = "";
      this.QRGenerated = false;
      this.QRValue = "";
      this.valid = true;
      // *************
      this.userUUID = "";
      this.loadingSelfieBtn = false;
      this.loadingFrontDocBtn = false;
      this.loadingBackDocBtn = false;

      this.selfieImg = null;
      this.frontDocImg = null;
      this.backDocImg = null;

      this.firstPanel = true;
      this.registered_person_panel = false;
      this.secondPanel = false;
      this.intermediaryPanel = false;
      this.thirdPanel = false;
      this.fourthPanel = false;

      this.selfieBase64 = null;
      this.frontDocBase64 = null;
      this.backDocBase64 = null;

      this.s3SelfieKey = "";
      this.s3FrontDocKey = "";
      this.s3BackDocKey = "";

      this.selfieFile = null;
      this.frontDocFile = null;
      this.backDocFile = null;

      this.registered_person = null;

      this.selfiePhotoModule1 = true;
      this.selfiePhotoModule2 = false;
      this.frontDocumentPhotoModule1 = true;
      this.frontDocumentPhotoModule2 = false;
      this.backDocumentPhotoModule1 = true;
      this.backDocumentPhotoModule2 = false;

      this.alreadyRegisteredType = 'oneid'
      this.initModule = true; 
      this.manualRegister = false;
    },

    //* ----------------------------------------- *//

    async sleep(millis) {
      return new Promise(resolve => setTimeout(resolve, millis));
    },

    async copyLink() {
      let copyText = document.getElementById("generatedLink").value;
      navigator.clipboard.writeText(copyText).then(() => {});
      this.showCopied = true;
      await this.sleep(3000)
      this.showCopied = false;
    },

    buildLink(role, suborg, expirationDate, sedeType) {
      if (expirationDate) {
        if ((!role && !suborg) || (role && suborg) || (role && !suborg)) {
          if (role && role.need_acceptance && !suborg) {
            this.snackbarText = "A seleccionado un rol que requiere autorización previa por lo que es necesario seleccionar una suborganización.";
            this.snackbarColor = "error";
            this.snackbar = true;
          } else {
            expirationDate = expirationDate + "T23:59:59.000Z"
            let digits = '0123456789';
            let randomID = '';

            for (let i = 0; i < 3; i++ ) {
              randomID += digits[Math.floor(Math.random() * 10)];
            }

            let intoTimestamp = new Date(expirationDate);

            intoTimestamp = intoTimestamp.getTime()

            let link = ""

            if (auth.getUserEnterpriseID() == "dea02cee-7c13-4bfd-9f6d-87b4d80efd17") {
              let sedeID = sedeType && sedeType != null ? sedeType.uuid : "0000"
              link = "https://app.vanguardvision.ai/#/register?exp=" + randomID + intoTimestamp + "&pl=" + sedeID + "&id=" + auth.getUserEnterpriseID();
            } else {
              if (auth.getUserEnterpriseID() == "4e4c96b1-69b7-432d-8fd9-ef9460b11a8d") {
                link = "https://app.vanguardvision.ai/#/register?exp=" + randomID + intoTimestamp + "&id=" + auth.getUserEnterpriseID();
              } else {
                link = "https://app.vanguardvision.ai/#/registry-people?exp=" + randomID + intoTimestamp + "&id=" + auth.getUserEnterpriseID();
              }
            }

            //let link = "http://localhost:8080/#/register?exp=" + randomID + intoTimestamp + "&id=" + auth.getUserEnterpriseID();

            if (role && !suborg) {
              link = link + "&id2=" + role.uuid;
            } else if (role && suborg) {
              link = link + "&id2=" + role.uuid + "&id3=" + suborg.uuid;
            }

            this.registryLink = link;
            backlog.generateLog({
              enterprise: auth.getUserEnterpriseName(),
              username: auth.getUsername(),
              role: auth.getRoleName(),
              action: "generate-link/registry",
            });
          }
        } else if (!role && suborg) {
          this.snackbarText = "Si selecciona una suborganización es necesario que seleccione un rol";
          this.snackbarColor = "error";
          this.snackbar = true;
        }
      } else {
        this.snackbarText = "Es necesario que seleccione una fecha de expiración para el link.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    /* ---------- SELFIE READ IMG CODE BLOCK ---------- */
    clickSelfieInputOLD() {
      this.$refs.selfieInput.click();
    },

    readSelfieFileOLD(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.userUUID = uuidv4()
        this.selfieImg = fileReader.result;
        this.selfieFile = files[0]
        //const searchTerm = "base64,";
        //let base64Code = fileReader.result;
        //this.selfieBase64 = base64Code.substr(base64Code.indexOf(searchTerm) + searchTerm.length);
      });
      fileReader.readAsDataURL(files[0]);
    },

    /* ---------- FRONT DOC READ IMG CODE BLOCK ---------- */
    clickFrontDocInputOLD() {
      this.$refs.frontDocInput.click();
    },

    readFrontDocFileOLD(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.frontDocImg = fileReader.result;
        this.frontDocFile = files[0]
        //const searchTerm = "base64,";
        //let base64Code = fileReader.result;
        //this.frontDocBase64 = base64Code.substr(base64Code.indexOf(searchTerm) + searchTerm.length);
      });
      fileReader.readAsDataURL(files[0]);
    },

    /* ---------- BACK DOC READ IMG CODE BLOCK ---------- */
    clickBackDocInputOLD() {
      this.$refs.backDocInput.click();
    },

    readBackDocFileOLD(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.backDocImg = fileReader.result;
        this.backDocFile = files[0]
        //const searchTerm = "base64,";
        //let base64Code = fileReader.result;
        //this.backDocBase64 = base64Code.substr(base64Code.indexOf(searchTerm) + searchTerm.length);
      });
      fileReader.readAsDataURL(files[0]);
    },

    /* ---------- VALIDATE MASK & GLASSES ---------- */
    validateSelfieOLD() {
      this.loadingSelfieBtn = true
      this.S3Client
        .uploadFile(this.selfieFile, "selfie_" + this.userUUID)
        .then(data => {
          if (data.key && data.key != '') {
            this.s3SelfieKey = data.key;
            console.log("Respuesta de imagen subida a S3 en la validacion de la selfie", data)

            // ******************************************************************
            axios({
              method: 'POST',
              baseURL: globals.APIURL + "identity/auth-person",
              headers: {
                  "Access-Control-Allow-Origin": "*"
              },
              data: {
                login_img_key: this.s3SelfieKey,
              },
            }).then((res) => {
                if (res.data.document_number) {
                  this.firstPanel = false;
                  this.registered_person_panel = true;
                  this.registered_person = res.data.document_number;
                  this.loadingSelfieBtn = true
                } else {
                  this.checkFaceFeatures();
                }
              })
              .catch((err) => {
                console.log(err.response.data.Message)
                if (err.response.data.Message === "No matching faces.") {
                  this.checkFaceFeatures();
                }
              });
            // ******************************************************************

            //

          }
        })
        .catch(err => {
          console.log(err)
          this.loadingSelfieBtn = false
        })
    },

    checkFaceFeaturesOLD() {
      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/dvf",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          SelfieImage: this.s3SelfieKey,
        },
      }).then((res) => {
          console.log(res.data)
          if (res.data) {
            if (
              res.data.faceAnalysisData.FaceDetails &&
              res.data.faceAnalysisData.FaceDetails.length > 0
            ) {
              if (
                !res.data.ppeData.Summary.PersonsWithRequiredEquipment ||
                res.data.ppeData.Summary.PersonsWithRequiredEquipment.length ==
                  0
              ) {
                if (
                  res.data.faceAnalysisData.FaceDetails[0].Eyeglasses.Value ==
                    false &&
                  res.data.faceAnalysisData.FaceDetails[0].Sunglasses.Value ==
                    false
                ) {
                  this.loadingSelfieBtn = false;
                  this.firstPanel = false;
                  this.secondPanel = true;
                } else {
                  this.loadingSelfieBtn = false
                  this.snackbar = true;
                  this.snackbarColor = "error";
                  this.snackbarText =
                    "La selfie tiene lentes, favor ingresar una sin ellos.";
                }
              } else {
                this.loadingSelfieBtn = false
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText =
                  "La selfie tiene tapabocas, favor ingresar una sin el.";
              }
            } else {
              this.loadingSelfieBtn = false
              this.snackbar = true;
              this.snackbarColor = "error";
              this.snackbarText =
                "La imagen ingresada no tiene un rostro reconocible.";
            }
          }
        })
        .catch((err) => {
          this.loadingSelfieBtn = false
          console.log(err);
        });
    },

    /* ---------- VALIDATE FRONT DOC DATA ---------- */
    validateFrontDocumentImgOLD() {
      this.loadingFrontDocBtn = true;
      this.S3Client
        .uploadFile(this.frontDocFile, "front-doc_" + this.userUUID)
        .then(data => {
          if (data.key && data.key != '') {
            this.s3FrontDocKey = data.key;
            this.loadingFrontDocBtn = false;
            this.secondPanel = false;
            this.thirdPanel = true;
            /* axios({
              method: 'POST',
              baseURL: globals.APIURL + "identity/vfd",
              headers: {
                  "Access-Control-Allow-Origin": "*"
              },
              data: {
                FrontDocumentImage: data.key,
              },
            }).then((res) => {
                if (res.data) {
                  if (
                    res.data.faceAnalysisData.FaceDetails &&
                    res.data.faceAnalysisData.FaceDetails.length > 0
                  ) {
                    this.compareFaces(data);
                  } else {
                    this.loadingFrontDocBtn = false;
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText =
                      "La imagen del documento no tiene un rostro reconocible, por favor intente nuevamente con otra imagen.";
                  }
                }
              })
              .catch((err) => {
                this.loadingFrontDocBtn = false;
                console.log(err);
              }); */
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    /* ---------- COMPARE SELFIE AND DOCUMENT FACE ---------- */

    compareFacesOLD() {
        axios
          .post(globals.APIURL + "identity/cf", {
            SelfieImage: this.s3SelfieKey,
            DocumentImage: this.s3FrontDocKey,
          }, {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })
          .then((res) => {
            if (res.data.FaceMatches && res.data.FaceMatches.length > 0) {
              this.similarityNumber = Math.round(res.data.FaceMatches[0].Similarity);
              if (Math.round(res.data.FaceMatches[0].Similarity) > 65) {
                this.loadingFrontDocBtn = false;
                this.secondPanel = false;
                this.thirdPanel = true;
              } else {
                this.loadingFrontDocBtn = false;
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText =
                  "El rostro del documento no coincide con la selfie.";
              }
            }
          })
          .catch((err) => {
            this.overlaySimilarity = false;
            console.log(err);
          });
    },

    /* ---------- VALIDATE BACK DOC DATA ---------- */
    validateBackDocumentImgOLD() {
      this.loadingBackDocBtn = true;

      this.S3Client
        .uploadFile(this.backDocFile, "back-doc_" + this.userUUID)
        .then(data => {
          if (data.key && data.key != '') {
            this.loadingBackDocBtn = false;
            this.thirdPanel = false
            this.fourthPanel = true
          }
        })
        .catch(err => {
          this.loadingBackDocBtn = false;
          console.log(err)
        })
    },

    createRegistryOLD(reg) {
      if (this.$refs.form.validate() == true) {
        this.loadingRegistryCreationBtn = true;
        if (reg.id.length == 7) {
          reg.id = "000" + reg.id;
        } else if (reg.id.length == 8) {
          reg.id = "00" + reg.id;
        } else if (reg.id.length == 9) {
          reg.id = "0" + reg.id;
        }
        reg.expeditionDate = this.expeditionDatePicker
        reg.firstname = reg.firstname.toUpperCase().trim();
        reg.secondname = reg.secondname.toUpperCase().trim();
        reg.surname1 = reg.surname1.toUpperCase().trim();
        reg.surname2 = reg.surname2.toUpperCase().trim();
        axios
          .post(globals.APIURL + "identity/manual-register-user", {
            names: [reg.firstname, reg.secondname],
            last_names: [reg.surname1, reg.surname2],
            selfie_key: this.s3SelfieKey,
            images_ID: this.userUUID,
            type_document: reg.tipoDocumento,
            doc_number: reg.id,
            expiration_date: reg.expeditionDate,
            company_id: auth.getUserEnterpriseID(),
            campus_id: auth.getSelectedSede(),
          }, {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })
          .then((res) => {
            if (res.data.status == 200) {
              console.log("RESPUESTA DE LA CREACIÓN: ", res)
              this.clearRegistry()
              this.loadingRegistryCreationBtn = false;
              this.snackbar = true;
              this.snackbarColor = "success";
              this.snackbarText =
                "Se creo el registro de manera exitosa.";
              this.$bus.$emit("updateRegistry", "Update register list");
            }
          })
          .catch((err) => {
            this.loadingRegistryCreationBtn = false;
            console.log(err);
          });
      }
    },

    generateQROLD(reg) {
      console.log(reg)
      console.log(this.selfieImg)
      console.log(this.frontDocImg)
      console.log(this.backDocImg)
    
      /* if (this.$refs.form.validate() == true) {
          if (reg.id.length == 7) {
            reg.id = "000" + reg.id;
          } else if (reg.id.length == 8) {
            reg.id = "00" + reg.id;
          } else if (reg.id.length == 9) {
            reg.id = "0" + reg.id;
          }
        reg.birthdate = this.birthDatePicker.split("-").reverse().join("-");
        reg.firstname = reg.firstname.toUpperCase().trim();
        reg.secondname = reg.secondname.toUpperCase().trim();
        reg.surname1 = reg.surname1.toUpperCase().trim();
        reg.surname2 = reg.surname2.toUpperCase().trim();
        const str = JSON.stringify(reg, null, 0);
        this.QRValue = "QR" + str;
        this.QRGenerated = true;
        this.QRValue = this.QRValue.normalize("NFD").replace(/[\u0300-\u0302]/g, "");
        this.QRValue = this.QRValue.normalize("NFD").replace(/[\u0304-\u036f]/g, "");
        this.QRValue = this.QRValue.replace(/Ñ/g, "%");
        backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "create/QR/registry"})
      } */
    },

    clearRegistryOLD() {
      this.registry.tipoDocumento = "";
      this.registry.id = "";
      this.registry.firstname = "";
      this.registry.secondname = "";
      this.registry.surname1 = "";
      this.registry.surname2 = "";
      this.registry.expeditionDate = "";
      this.registry.sex = "";
      this.registry.blood = "";
      this.expeditionDatePicker = "";
      this.QRGenerated = false;
      this.QRValue = "";
      this.valid = true;
      // *************
      this.userUUID = "";
      this.loadingSelfieBtn = false;
      this.loadingFrontDocBtn = false;
      this.loadingBackDocBtn = false;

      this.selfieImg = null;
      this.frontDocImg = null;
      this.backDocImg = null;

      this.firstPanel = true;
      this.registered_person_panel = false;
      this.secondPanel = false;
      this.thirdPanel = false;
      this.fourthPanel = false;

      this.selfieBase64 = null;
      this.frontDocBase64 = null;
      this.backDocBase64 = null;

      this.s3SelfieKey = "";
      this.s3FrontDocKey = "";

      this.selfieFile = null;
      this.frontDocFile = null;
      this.backDocFile = null;

      this.registered_person = null;
    },

    getPremissionGroups() {
      axios({
        method: "GET",
        baseURL: globals.APIURL + "permission-group/get-pg/" + auth.get_asset_id(),
      }).then((data) => {
        if (data.data.length > 0) {
          for (let i = 0; i < data.data.length; i++) {
            const element = data.data[i];
            
            if (element.type_group == 'black_list') {
              data.data.splice(i, 1)
            }
          }
          this.permissionGroupsList = data.data
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    getSuborgs() {
      axios
        .post(globals.APIURL + "campus/fdso", {
          company_id: auth.getUserEnterpriseID()
        })
        .then((res) => {
          console.log(res)
          if (res.data && res.data.length > 0) {
            this.suborgsList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getPremissionGroups();
    this.getSuborgs();
  },
};
</script>
