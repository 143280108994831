<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12">
        <p class="d-flex justify-center grey--text text-h5 pa-0">Admin. de Equipos</p>
        <p v-if="roleChecker.iHaveGrants(userRole, 'DEVICES-MONITORING')">
            <v-icon v-if="!mapCard" @click="mapCard = !mapCard" class="grey--text pl-3">mdi-web-off</v-icon>
            <v-icon v-if="!mapCard" class="secondary--text pl-3">mdi-table</v-icon>
            <v-icon v-if="mapCard" class="secondary--text pl-3">mdi-web</v-icon>
            <v-icon v-if="mapCard" @click="mapCard = !mapCard" class="grey--text pl-3">mdi-table-off</v-icon>
        </p>
      </v-col>
    </v-row>
    <v-row v-if="devices && devices.length === 0 && roleChecker.iHaveGrants(userRole, 'DEVICES')" class="px-3">
        <v-col cols="12" md="3" v-for="(item, i) in 3" :key="i" class="d-flex justify-center pt-10">
            <v-skeleton-loader v-if="loading == true"
                transition="scale-transition"
                width="300px"
                type="card"
            ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" v-if="loading == false" class="text-center">
            <p>No hay equipos que mostrar.</p>
        </v-col>
    </v-row>
    <v-row v-if="devices && devices.length > 0 && roleChecker.iHaveGrants(userRole, 'DEVICES')">
        <v-col cols="12" md="4" v-for="(item, i) in devices" :key="i" class="pt-5">
            <v-card elevation="10" class="rounded-corners">
                <v-card-title class="d-flex justify-center align-center">
                    <v-row>
                        <v-col cols="12" md="6" class="d-flex justify-center ma-0 pa-0">
                            <span class="text-subtitle-1">
                                <span class="grey--text">Tipo: </span>
                                <strong>{{ item.type_device.toUpperCase() }}</strong>
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex justify-center ma-0 pa-0">
                            <span v-if="!item.editMode" class="text-subtitle-1">
                                <span class="grey--text">Nombre: </span>
                            <strong v-if="!item.editMode">{{ item.name ? item.name : 'S/A' }}</strong>
                            </span>
                            <v-text-field
                                v-model="item.name"
                                v-if="item.editMode"
                                class="ma-0 mr-5 pa-0"
                                label="Nombre"
                                outlined
                                dark
                                color="secondary"
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6" sm="12" class="d-flex justify-center ma-0 pa-0">
                            <span class="text-subtitle-1"> 
                                <span class="grey--text">ID: </span>
                                <strong class="white--text">{{ item.mac }}</strong>
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" sm="12" class="d-flex justify-center ma-0 pa-0">
                            <span class="text-subtitle-1"> 
                                <span class="grey--text">IP: </span>
                                <strong class="white--text">{{ item.ip }}</strong> 
                            </span>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-divider class="mx-3 mt-2"></v-divider>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-list two-line class="ma-0 pa-0" style="background: #31394c;">
                                <v-list-item class="ma-0 pa-0">
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center text-wrap">
                                            Salida Automática:
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="d-flex justify-center">
                                            <v-switch
                                                class="ml-4"
                                                v-model="item.automatic_exit"
                                                color="secondary"
                                                inset
                                                dark
                                                :disabled="!item.editMode"
                                            ></v-switch>
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-list two-line class="ma-0 pa-0" style="background: #31394c;">
                                <v-list-item class="ma-0 pa-0">
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            Hora Salida:
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="d-flex justify-center">
                                            <span v-if="!item.editMode">{{ item.automatic_exit && item.time_exit ? item.time_exit : 'N/A'}}</span>
                                            <span v-if="item.editMode && !item.automatic_exit">N/A</span>
                                            <v-menu
                                                ref="menu"
                                                v-model="menuPicker"
                                                v-if="item.editMode && item.automatic_exit"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="item.time_exit"
                                                transition="scale-transition"
                                                offset-y
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="item.time_exit"
                                                        class="centered-input"
                                                        placeholder="Seleccione Hora"
                                                        dense
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-if="menuPicker"
                                                    v-model="item.time_exit"
                                                    no-title
                                                    @click:minute="$refs.menu[0].save(item.time_exit)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-list two-line class="ma-0 pa-0" style="background: #31394c;">
                                <v-list-item class="ma-0 pa-0">
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            Modo:
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            <v-chip v-if="!item.editMode" class="ma-0" :color="item.mode === 'ENTRY' ? 'primary' : 'red'" text-color="white">
                                                {{ item.mode === "ENTRY" ? "Acceso" : "Salida" }}
                                            </v-chip>
                                            <v-chip v-if="item.editMode && item.automatic_exit" class="ma-0" :color="actualTime >= item.time_exit ? 'red' : 'primary'" text-color="white">
                                                {{ actualTime >= item.time_exit ? "Salida" : "Acceso" }}
                                            </v-chip>
                                            <v-select
                                                v-if="item.editMode && !item.automatic_exit"
                                                v-model="item.mode"
                                                class="pt-10"
                                                :items="modes"
                                                item-text="Name"
                                                item-value="ID"
                                                dense
                                                outlined
                                                dark
                                                label="Modo"
                                                style="width: 90%;"
                                            ></v-select>
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0">
                            <v-list two-line class="ma-0 pa-0" style="background: #31394c;">
                                <v-list-item class="ma-0 pa-0">
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            Estado:
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            <v-tooltip bottom transition="scale-transition">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-chip 
                                                    :color="validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8)) === true ? 'success' : 'error'" 
                                                    text-color="white"
                                                    v-bind="attrs"
                                                    v-on="on">
                                                        <v-icon v-if="validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8))" class="text-center" color="white">
                                                            mdi-power-plug-outline
                                                        </v-icon>
                                                        <v-icon v-if="!validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8))" class="text-center" color="white">
                                                            mdi-power-plug-off-outline
                                                        </v-icon>
                                                    </v-chip>
                                                </template>
                                                <span>{{!validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8)) ? 'El equipo no está activo - última conexión: ' + item.last_connection.slice(0, 10) + " | " + item.last_connection.slice(11, 16) : 'El equipo está activo - última conexión: ' + item.last_connection.slice(0, 10) + " | " + item.last_connection.slice(11, 16)}}</span>
                                            </v-tooltip>
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col v-if="userRole === 'ADMIN' || userRole === 'ADMIN2'">
                            <v-list two-line class="ma-0 pa-0" style="background: #31394c;">
                                <v-list-item class="ma-0 pa-0">
                                    <v-list-item-content class="ma-0 pa-0">
                                        <v-list-item-title class="text-center">
                                            Apertura manual:
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                    <v-list-item-content class="ma-0 pa-0 pl-6">
                                        <v-list-item-title class="text-center">
                                            <v-btn
                                                class="grey darken-1"
                                                rounded
                                                @click="openGate(item)"
                                            >
                                                <i class="fal fa-door-open" style="font-size: 20px;"></i>
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item-content>    
                                </v-list-item>
                            </v-list>
                        </v-col>    
                    </v-row>
                    <v-row>
                        <v-col cols="12" v-if="!item.editMode || item.editMode === false">
                            <v-btn
                                color="primary"
                                block
                                rounded
                                large
                                dark
                                @click="activateEditMode(item, i)"
                            >
                                Editar
                            </v-btn>
                        </v-col>
                        <v-col cols="6" v-if="item.editMode === true" class="text-center">
                            <v-btn
                                color="secondary"
                                block
                                rounded
                                large
                                dark
                                :loading="loadingBtns"
                                @click="updateDevice(item)"
                            >
                                Guardar
                            </v-btn>     
                        </v-col>
                        <v-col cols="6" v-if="item.editMode === true">
                            <v-btn
                                color="error"
                                block
                                rounded
                                large
                                dark
                                @click="cancelEditMode(item, i)"
                            >
                                cancelar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="roleChecker.iHaveGrants(userRole, 'DEVICES-MONITORING')">
        <v-col cols="12" v-if="mapCard">
            <devices-map :company_id="companyID"></devices-map>
        </v-col>
        <v-row v-if="!mapCard" class="pa-0 ma-0">
            <v-col cols="12" md="10" class="pb-0">
                <v-select
                    v-model="sedeDevice"
                    :items="sedes"
                    item-text="name"
                    item-value="uuid"
                    label="Sede"
                    outlined
                    dense
                    dark
                    color="secondary"
                    return-object
                ></v-select>
            </v-col>
            <v-col cols="12" md="2" class="pb-0">
                <v-btn
                    outlined
                    block
                    dense
                    color="secondary"
                    @click="getMonitoringDevices(sedeDevice)"
                >
                    buscar
                </v-btn>
            </v-col>
            <v-col cols="12" class="pt-0">
                <v-card elevation="3">
                    <v-card-text class="pt-0">
                        <v-row class="text-center">
                            <v-col cols="12" v-if="!devices">
                                <p>Filtre sus dispositivos.</p>
                            </v-col>
                            <v-col cols="12" v-if="devices && devices.length == 0">
                                <p v-if="loading == false">
                                    No hay dispositivos que mostrar.
                                </p>
                                <span v-if="loading == true">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    :indeterminate="loading"
                                    >
                                    </v-progress-circular>
                                    <p class="pt-2 text-subtitle-2">Cargando dispositivos...</p>
                                </span>
                            </v-col>
                            <v-col v-if="devices && devices.length > 0" cols="12" class="pt-0">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Filtrar dispositivos"
                                    single-line
                                    hide-details
                                    class="mb-5 mt-0"
                                ></v-text-field>
                                <v-layout child-flex>
                                    <v-data-table
                                        :headers="headers"
                                        :items="devices"
                                        :search="search"
                                        :items-per-page="5"
                                        :header-props="{
                                            mobile: true,
                                        }"
                                        :footer-props="{
                                            'items-per-page-options': [50],
                                        }"
                                        class="elevation-1"
                                        fixed-header
                                        no-results-text="No hay dispositivos con el dato ingresado. "
                                    >
                                        <template v-slot:[`item.campus.name`]="{ item }">
                                            <div v-if="item.campus_id">
                                                <span>{{ item.campus.name }}</span>
                                            </div>
                                            <span v-if="!item.campus_id">Sin asignar</span>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                            <span>{{
                                                item.name !== null ? item.name : "Sin nombrar"
                                            }}</span>
                                        </template>
                                        <template v-slot:[`item.last_connection`]="{ item }">
                                            <v-chip
                                                small
                                                :color="
                                                validateDvcConnection(
                                                    item.last_connection.slice(0, 10) +
                                                    ' ' +
                                                    item.last_connection.slice(
                                                        11,
                                                        item.last_connection.length - 8
                                                    )
                                                ) === true
                                                    ? 'green darken-2'
                                                    : 'error darken-2'
                                                "
                                                text-color="white"
                                                class="darken-1 px-1"
                                            >
                                            <span class="ml-3">{{
                                                item.last_connection
                                                ? item.last_connection.slice(0, 10) +
                                                    " " +
                                                    item.last_connection.slice(
                                                    11,
                                                    item.last_connection.length - 8
                                                    )
                                                : "---"
                                            }}</span>
                                                <v-icon
                                                color="white"
                                                size="20"
                                                class="text-center"
                                                >
                                                {{ validateDvcConnection(
                                                    item.last_connection.slice(0, 10) +
                                                        ' ' +
                                                        item.last_connection.slice(
                                                        11,
                                                        item.last_connection.length - 8
                                                        )
                                                    ) ? 'mdi-access-point' : 'mdi-access-point-off'
                                                }}
                                                </v-icon>
                                            </v-chip>
                                        </template>
                                        <template v-slot:[`item.options`]="{ item }">
                                            <v-icon
                                                class="text-center pr-1"
                                                color="white"
                                                title="Monitoreo"
                                                @click="openGate(item)"
                                                style="font-size: 18px"
                                            >
                                                fal fa-door-open
                                            </v-icon>
                                        </template>
                                    </v-data-table>
                                </v-layout>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import backlog from '../services/logs'
import roles from "../services/role";
import DevicesMap from "./utils/devices-map.vue"

export default {
  name: "AdminDevices",
  components: {
    DevicesMap
  },
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    menuPicker: false,
    devices: null,
    loading: false,
    loadingBtns: false,
    actualTime: globals.COdate().slice(11, globals.COdate().length - 8),
    search: "",
    headers: [
      { text: "ID", align: "center", value: "mac", sortable: false },
      { text: "IP", align: "center", value: "ip", sortable: true },
      {
        text: "Punto",
        align: "center",
        value: "campus.name",
        sortable: true,
      },
      { text: "Nombre", align: "center", value: "name", sortable: false },
      {
        text: "Ultima Conexión",
        align: "center",
        value: "last_connection",
        sortable: true,
      },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    modes: [
      { ID: "ENTRY", Name: "ACCESO" },
      { ID: "EXIT", Name: "SALIDA" },
    ],
    userRole: auth.getRole(),
    roleChecker: roles,
    sedeDevice: null,
    sedes: [
        {
            name: "Todas",
            zones: []
        }
    ],
    companyID: auth.getUserEnterpriseID(),
    mapCard: false
  }),
  methods: {
    openGate(item) {
      axios
        .post(globals.APIURL + "device/open-door", {
          mac: item.mac
        })
        .then((res) => {
          if (res.status == 200 && res.data.message == "success action") {
            backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "open-gate/devices"})
            this.snackbar = true
            this.snackbarColor = "success"
            this.snackbarText = "Se accionó apertura de puerta"
          } else if (res.status == 200 && res.data.message == "Mac must be filled") {
            this.snackbar = true
            this.snackbarColor = "success"
            this.snackbarText = "Ocurrio un error"
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    validateDvcConnection(lastConnectionTime) {
      const todaysTime = new Date(globals.COdate().slice(0, 10) + " " + globals.COdate().slice(11, globals.COdate().length - 8))
      lastConnectionTime = new Date(lastConnectionTime); 
      let difference = Math.round((todaysTime.getTime() - lastConnectionTime.getTime()) / 60000); // Diferencia entre fechas en minutos

      if (difference > 3) {
        return false
      } else {
        return true
      }
    },

    activateEditMode(item, i) {
        this._beforeEditingCache = Object.assign({}, item);
        this.devices[i].editMode = true
    },

    cancelEditMode(item, i) {
        Object.assign(item, this._beforeEditingCache);
        this.devices[i].editMode = false
    },

    getDevices() {
        this.loading = true;
        this.devices = [];
        /* axios
          .post(globals.APIURL + "device/fb", {
            filter: {
                campus_id: auth.getSelectedSede()
            },
          })
          .then((res) => {
            if (res.data && res.data.length > 0) {
                res.data.forEach(element => {
                    element.editMode = false
                });
                this.devices = res.data;
                this.loading = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          }); */
          axios// **NEW
            .post(globals.APIURL + "device/get-devices-grouped", {
              ids: auth.getGZ().zones
            })
            .then(res =>{ 
              if (res.data.length > 0) {
                res.data.forEach(element => {
                  element.editMode = false
                });
                this.devices = res.data;
                this.loading = false;
              }
              this.loading = false;
            })
            .catch(err => {
              this.loading = false;
              console.log(err);
            })
    },

    updateDevice(device) {
        if (device.name !== '') {
            (device.automatic_exit === false) ? device.time_exit = "" : device.time_exit
            if ((device.automatic_exit && device.time_exit) || (!device.automatic_exit && !device.time_exit)) {
                this.loadingBtns = true
                if (device.automatic_exit && device.time_exit && (this.actualTime >= device.time_exit)) {
                    device.mode = 'EXIT'
                } else {
                    device.mode = 'ENTRY'
                }
                axios
                    .put(globals.APIURL + "device/ud", {
                        device_id: device.uuid,
                        update_obj: {
                            name: device.name,
                            mode: device.mode,
                            automatic_exit: device.automatic_exit,
                            time_exit: device.time_exit,
                        },
                    })
                    .then((res) => {
                        if (res.data == "Device updated successfuly") {
                            backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "update/devices"})
                            this.snackbar = true;
                            this.snackbarColor = "success";
                            this.snackbarText = "Se actualizó el dispositivo con exito.";
                            this.getDevices()
                            this.loadingBtns = false
                        }
                        this.loadingBtns = false
                    })
                    .catch((err) => {
                        this.loadingBtns = false
                        console.log(err);
                    });
            } else {
                this.snackbar = true;
                this.snackbarColor = "red";
                this.snackbarText = "Si selecciona salida automática es necesario especificar una hora para el cambio.";
            }
        } else {
            this.snackbar = true;
            this.snackbarColor = "red";
            this.snackbarText = "El equipo no puede tener el campo Nombre vacío.";
        }
    },

    getGroupZones() {// **NEW
      axios
        .post(globals.APIURL + "org/get-group-zones", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.status == 200 && res.data.data) {
            for (let i = 0; i < res.data.data.length; i++) {
              const element = res.data.data[i];
              
              if (element.users.includes(auth.getUsername())) {
                this.sedes.push(element)
                this.sedes[0].zones = [...this.sedes[0].zones, ...element.zones]
              }
            }
            this.sedeDevice = this.sedes[0]
            this.getMonitoringDevices(this.sedes[0])
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMonitoringDevices(sede) {
        this.loading = true;
        this.devices = [];
        if (sede) {
            axios
                .post(globals.APIURL + "device/get-devices-grouped", {
                    ids: sede.zones
                })
                .then(res => { 
                    if (res.data.length > 0) {
                        this.devices = res.data;
                        this.loading = false;
                    }
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                })
        } else {
            this.loading = false
        }
    },
  },
  mounted() {
    if (this.roleChecker.iHaveGrants(this.userRole, 'DEVICES-MONITORING')) {
        this.getGroupZones();
    } else {
        this.getDevices()
    }
  },
};
</script>
<style>
    tr:hover {
        background-color: transparent !important;
    }
    .rounded-corners {
        border-radius: 25px !important;
    }
    .centered-input >>> input {
      text-align: center
    }

    .theme--light.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty) .v-input--switch__track {
        color: black !important
    }
</style>
