<template>
  <div>
    <v-stepper v-model="stepperModel">
      <div class="d-flex justify-end">
        <v-btn class="mt-1 mr-1" icon small dark @click="closeForm()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <!-- <v-stepper-header>
        <v-stepper-step
          v-for="(item, i) in headers"
          :key="i"
          :complete="stepperModel > item.stepNumber"
          :step="item.stepNumber"
        >
          <strong class="ml-1">{{ item.title }}</strong>
        </v-stepper-step>
      </v-stepper-header> -->

      <v-stepper-items>
        <v-stepper-content step="1"> <!-- // * Step 1 -->
          <v-row class="px-5">
            <v-col cols="12" class="text-center">
              <h4>Seleccione la fecha y hora de la visita</h4>
            </v-col>
            <v-col cols="12" class="pb-0">
              <label class="text-subtitle-1">Intervalo de fechas</label>
              <v-dialog
                ref="dialog"
                v-model="rangeDateModal"
                :return-value.sync="rangeDates"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    prepend-icon="fal fa-calendar-day"
                    readonly
                    outlined
                    dense
                    height="44"
                    dark
                    class="primaryInput"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="rangeDates"
                  no-title
                  scrollable
                  range
                  dark
                  @input="validateRangeDates"
                  :min="minDate"
                  locale="es-MX"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    rounded
                    large
                    outlined
                    class="px-4 mx-2 secondaryBtn"
                    @click="rangeDateModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    rounded
                    color="primary"
                    large
                    class="px-4 mx-2 primaryBtn"
                    @click="selectDates"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-subtitle-1">Hora Entrada</label>
              <input v-model="hour_entry" type="time" />
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-subtitle-1">Hora Salida</label>
              <input v-model="hour_exit" type="time" />
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn rounded color="primary" @click="validateStepOne">
                Continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="2"> <!-- // * Step 2 -->
          <div class="ma-0 pa-0" v-if="!personNotExist && !scheduleForm.firstname && !scheduleForm.surname">
            <v-row class="px-5">
              <v-col cols="12" class="text-center">
                <h4>Seleccione el tipo de identificación y digite el número</h4>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="scheduleForm.tipoDocumento"
                  :items="documentTypes"
                  item-text="Title"
                  item-value="Type"
                  :rules="[(v) => !!v || 'El campo es obligatorio.']"
                  label="Tipo Documento"
                  outlined
                  dense
                  dark
                  color="secondary"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="scheduleForm.document_number"
                  :rules="
                    scheduleForm.tipoDocumento == 'PASS' ? passRules : ccRules
                  "
                  label="Número Documento"
                  outlined
                  dense
                  dark
                  color="secondary"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn
                  class="mr-2"
                  rounded
                  color="grey darken-2"
                  @click="stepperModel = 1"
                >
                  Atras
                </v-btn>
                <v-btn 
                  rounded 
                  color="primary" 
                  @click="getUserData(scheduleForm.document_number)" 
                  :disabled="validateDocument(scheduleForm.tipoDocumento, scheduleForm.document_number)"
                  :loading="loadingUserDataBtn"
                >
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="pa-0 ma-0" v-if="!personNotExist && scheduleForm.firstname && scheduleForm.surname">
            <v-row class="px-5">
              <v-col cols="12" class="text-center">
                <h4>Estos datos son correctos?</h4>
              </v-col>
            </v-row>
            <v-row class="px-5">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="scheduleForm.document_number"
                  label="Documento"
                  outlined
                  dark
                  dense
                  color="secondary"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="scheduleForm.firstname"
                  :rules="namesRule"
                  label="Nombre"
                  outlined
                  dark
                  dense
                  color="secondary"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="px-5 d-flex justify-center">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="scheduleForm.surname"
                  label="Apellido"
                  :rules="namesRule"
                  outlined
                  dense
                  dark
                  color="secondary"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn
                  class="mr-2"
                  rounded
                  color="grey darken-2"
                  @click="personNotExist = false ; scheduleForm.firstname = '' ; scheduleForm.surname = ''"
                >
                  Atras
                </v-btn>
                <v-btn rounded color="primary" @click="validateExistUser">
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="pa-0 ma-0" v-if="personNotExist">
            <v-row class="px-5">
              <v-col cols="12" class="text-center">
                <h4 v-if="personNotExist && scheduleForm.firstname && scheduleForm.surname">Esta persona no se encuentra registrada, por favor verifique los datos</h4>
                <h4 v-if="personNotExist && (!scheduleForm.firstname || !scheduleForm.surname)">Esta persona no se encuentra registrada, por favor verifique el documento e ingrese los datos faltantes</h4>
              </v-col>
            </v-row>
            <v-row class="px-5">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="scheduleForm.document_number"
                  label="Nombre"
                  outlined
                  dark
                  dense
                  color="secondary"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="scheduleForm.firstname"
                  :rules="namesRule"
                  label="Nombre"
                  outlined
                  dark
                  dense
                  color="secondary"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="px-5 d-flex justify-center">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="scheduleForm.surname"
                  label="Apellido"
                  :rules="namesRule"
                  outlined
                  dense
                  dark
                  color="secondary"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn
                  class="mr-2"
                  rounded
                  color="grey darken-2"
                  @click="personNotExist = false ; scheduleForm.firstname = '' ; scheduleForm.surname = ''"
                >
                  Atras
                </v-btn>
                <v-btn rounded color="primary" @click="validateNotExistUser">
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-stepper-content>
        <v-stepper-content step="3"> <!-- // * Step 3 -->
          <v-row class="px-5">
            <v-col cols="12" class="text-center">
              <h4>Seleccione si el visitante viene en un vehículo</h4>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-checkbox
                v-model="scheduleForm.vehicle"
                label="Con Vehículo"
                color="secondary"
                class="ma-0"
                @change="clearVehicleInfo"
              ></v-checkbox>
            </v-col>
            <v-col v-if="scheduleForm.vehicle == true" cols="12" md="6">
              <v-text-field
                v-model="scheduleForm.plate"
                label="Placa del Automovil."
                :rules="plateRules"
                outlined
                dense
                dark
                :disabled="!scheduleForm.vehicle"
                color="secondary"
              ></v-text-field>
            </v-col>
            <v-col v-if="scheduleForm.vehicle == true" cols="12" md="6">
              <v-text-field
                v-model="scheduleForm.parking_cell"
                label="Celda Asignada"
                outlined
                dense
                dark
                :disabled="!scheduleForm.vehicle"
                color="secondary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                class="mr-2"
                rounded
                color="grey darken-2"
                @click="stepperModel = 2"
              >
                Atras
              </v-btn>
              <v-btn rounded color="primary" @click="validateVehicleStep" :disabled="validateVehicle()">
                Continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="4"> <!-- // * Step 4 -->
          <v-row class="px-5">
            <v-col cols="12" class="text-center">
              <h4>Como desea enviar la invitación?</h4>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-radio-group v-model="sendInviteType" row>
                <v-radio
                  label="Celular"
                  color="secondary"
                  value="phone"
                  @click="clearInviteType"
                ></v-radio>
                <v-radio
                  label="Email"
                  color="secondary"
                  value="email"
                  @click="clearInviteType"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-if="sendInviteType == 'phone'"
                v-model="scheduleForm.phone"
                label="Ingrese el número celular"
                :rules="phoneRules"
                outlined
                dense
                dark
                required
                color="secondary"
              ></v-text-field>
              <v-text-field
                v-if="sendInviteType == 'email'"
                v-model="scheduleForm.email"
                label="Ingrese el Email"
                :rules="emailRules"
                outlined
                dense
                dark
                required
                color="secondary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                class="mr-2"
                rounded
                color="grey darken-2"
                @click="stepperModel = 3"
              >
                Atras
              </v-btn>
              <v-btn rounded color="primary" @click="validateInvitationType" :disabled="validateEnvite()">
                Continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="5"> <!-- // * Step 5 -->
          <v-row class="px-5">
            <v-col cols="12" class="text-center">
              <h3>Resumen Invitación</h3>
              <label class="pt-2"
                >Por favor verifique la información de la invitación y proceda
                con su creación</label
              >
            </v-col>
            <div style="width: 100%" class="d-flex justify-center">
              <v-card elevation="4">
                <v-card-text>
                  <p class="font-weight-black">Invitado:</p>
                  <label class="ml-3 font-weight-bold">{{ scheduleForm.firstname + " " + scheduleForm.surname }}</label>
                  <p class="font-weight-black">
                    Fecha que se habilitará su visita:
                  </p>
                  <label class="ml-3 font-weight-bold">
                    {{scheduleForm.start_date.substr(0, 10)  + " / " + scheduleForm.start_date.substring(11, 16) + " A " + scheduleForm.finish_date.substr(0, 10) + " / " + scheduleForm.finish_date.substring(11, 16)}}
                  </label>
                  <p class="font-weight-black">Metodo de envío:</p>
                  <label class="ml-3 font-weight-bold" v-if="sendInviteType == 'email'">Email</label>
                  <label class="ml-3 font-weight-bold" v-if="sendInviteType == 'phone'">Celular</label>
                  <p class="font-weight-black" v-if="sendInviteType == 'email'">Email:</p>
                  <label class="ml-3 font-weight-bold" v-if="sendInviteType == 'email'">{{ scheduleForm.email }}</label>
                  <p class="font-weight-black" v-if="sendInviteType == 'phone'">Celular:</p>
                  <label class="ml-3 font-weight-bold" v-if="sendInviteType == 'phone'">{{ scheduleForm.phone }}</label>
                  <label class="font-weight-black" style="display: grid; grid-template-columns: 1fr 1fr 1fr">
                    <p class="font-weight-black">Automovil</p>
                    <p v-if="scheduleForm.vehicle" class="font-weight-black">Placa</p>
                    <p v-if="scheduleForm.vehicle" class="font-weight-black">Celda</p>
                  </label>
                  <p class="font-weight-black" style="display: grid; grid-template-columns: 1fr 1fr 1fr">
                    <label class="ml-3 font-weight-bold">{{scheduleForm.vehicle ? "SI" : "NO"}}</label>
                    <label v-if="scheduleForm.vehicle" class="ml-3 font-weight-bold">{{scheduleForm.plate}}</label>
                    <label v-if="scheduleForm.vehicle" class="ml-3 font-weight-bold">{{scheduleForm.parking_cell}}</label>
                  </p>
                </v-card-text>
              </v-card>
            </div>
            <v-col cols="12" class="text-center">
              <v-btn
                class="mr-2"
                rounded
                color="grey darken-2"
                @click="stepperModel = 4"
              >
                Atras
              </v-btn>
              <v-btn rounded color="success darken-1" @click="createScheduledVisitor(scheduleForm)" :loading="loadingCreationBtn">
                Crear
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <!-- // * SEPARATOR -->

    <!-- <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col>
          <label
            >La agendación previa de un visitante nos ayudara a mejorar la
            experiencia de usuario al momento de ingresar al activo.</label
          >
        </v-col>
      </v-row>
      <v-row class="pt-2">
        <v-col cols="12" class="pb-2 px-2">
          <label class="text-h6">Agendar Visitante</label>
        </v-col>
        <v-col cols="12" md="6" class="py-1 px-2">
          <v-select
            v-model="scheduleForm.tipoDocumento"
            :items="documentTypes"
            item-text="Title"
            item-value="Type"
            :rules="[(v) => !!v || 'El campo es obligatorio.']"
            label="Tipo Documento"
            outlined
            dense
            dark
            color="secondary"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" class="py-1 px-3">
          <v-text-field
            v-model="scheduleForm.document_number"
            :rules="scheduleForm.tipoDocumento == 'PASS' ? passRules : ccRules"
            label="Número Documento"
            outlined
            dense
            dark
            color="secondary"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="py-1 px-3">
          <v-text-field
            v-model="scheduleForm.firstname"
            :rules="namesRule"
            label="Primer Nombre"
            outlined
            dark
            dense
            color="secondary"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="py-1 px-3">
          <v-text-field
            v-model="scheduleForm.surname"
            label="Primer Apellido"
            :rules="namesRule"
            outlined
            dense
            dark
            color="secondary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="py-1 px-3">
          <v-text-field
            v-model="scheduleForm.phone"
            :rules="phoneRules"
            label="Celular"
            outlined
            dense
            dark
            color="secondary"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="py-1 px-3">
          <v-text-field
            v-model="scheduleForm.email"
            label="Correo Electronico"
            :rules="emailRules"
            outlined
            dense
            dark
            required
            color="secondary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="py-1 px-3">
          <v-text-field
            v-model="scheduleForm.reason"
            :rules="simpleRule"
            label="Motivo de la visita."
            outlined
            dense
            dark
            color="secondary"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="py-1 px-3">
          <v-text-field
            v-model="scheduleForm.who_visit"
            label="Persona a quién visita."
            :rules="simpleRule"
            outlined
            dense
            dark
            required
            color="secondary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="py-1 px-3 d-flex justify-center">
          <v-checkbox
            v-model="scheduleForm.vehicle"
            label="Con Automovil"
            color="secondary"
            class="ma-0"
            @change="clearVehicleInfo"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="4" class="py-1 px-3">
          <v-text-field
            v-model="scheduleForm.plate"
            label="Placa del Automovil."
            :rules="plateRules"
            outlined
            dense
            dark
            :disabled="!scheduleForm.vehicle"
            color="secondary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="py-1 px-3">
          <v-text-field
            v-model="scheduleForm.parking_cell"
            label="Celda Asignada"
            outlined
            dense
            dark
            :disabled="!scheduleForm.vehicle"
            color="secondary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-2 px-2">
          <label class="text-h6">Detalles de la Visita</label>
        </v-col>
        <v-col cols="12" class="my-0 py-0">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="6" class="my-0 py-0">
              <label class="text-subtitle-1">Intervalo de fechas</label>
              <v-dialog
                ref="dialog"
                v-model="rangeDateModal"
                :return-value.sync="rangeDates"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    prepend-icon="fal fa-calendar-day"
                    readonly
                    outlined
                    dense
                    height="44"
                    dark
                    class="primaryInput"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="rangeDates"
                  no-title
                  scrollable
                  range
                  dark
                  @input="validateRangeDates"
                  :min="minDate"
                  locale="es-MX"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    rounded
                    large
                    outlined
                    class="px-4 mx-2 secondaryBtn"
                    @click="rangeDateModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    rounded
                    color="primary"
                    large
                    class="px-4 mx-2 primaryBtn"
                    @click="selectDates"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="3" class="my-0 py-0">
              <label class="text-subtitle-1">Hora Entrada</label>
              <input v-model="hour_entry" type="time" />
            </v-col>
            <v-col cols="12" md="3" class="my-0 py-0">
              <label class="text-subtitle-1">Hora Salida</label>
              <input v-model="hour_exit" type="time" />
            </v-col>
          </v-row>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" class="text-center">
          <v-btn
            :disabled="!valid"
            rounded
            color="primary"
            class="px-4 mx-2 mb-2 primaryBtn"
            :loading="loadingScheduleBtn"
            @click="createScheduledVisitor(scheduleForm)"
          >
            CREAR REGISTRO
          </v-btn>
        </v-col>
      </v-row>
    </v-form> -->
    <v-snackbar v-model="snackbar" timeout="10000" :color="snackbarColor" top>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import globals from "../../globals";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import auth from "../../services/auth";

export default {
  name: "ScheduleCreateFlow",
  data: () => ({
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    simpleRule: [(v) => !!v || "El campo es obligatorio."],
    namesRule: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        /^[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _]*$/.test(v) ||
        "El texto no debe tener caracteres especiales, tildes o números.",
    ],
    ccRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        (v && v.length >= 6) || "El documento debe tener minimo 6 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El documento solo debe tener números",
    ],
    passRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        (v && v.length >= 7) || "El documento debe tener minimo 7 digitos.",
      (v) =>
        /^[0-9A-Za-z _]*[0-9A-Za-z][0-9A-Za-z _]*$/.test(v) ||
        "El documento no debe tener caracteres especiales o tildes.",
    ],
    plateRules: [
      (v) => v == "" || v.length == 6 || "La placa debe tener 6 caracteres.",
      (v) =>
        v == "" ||
        (v.charAt(0).toUpperCase() !== v.charAt(0).toLowerCase() &&
          v.charAt(1).toUpperCase() !== v.charAt(1).toLowerCase() &&
          v.charAt(2).toUpperCase() !== v.charAt(2).toLowerCase() &&
          !isNaN(v.charAt(3)) &&
          !isNaN(v.charAt(4)) &&
          !isNaN(v.charAt(5))) ||
        "La placa esta mal conformada.",
    ],
    phoneRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        (v && v.length == 10) || "El número celular debe tener 10 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El campo solo permite números",
    ],
    emailRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "El correo electronico no esta formado correctamente",
    ],
    headers: [
      { title: "Horario", stepNumber: 1 },
      { title: "Identificación", stepNumber: 2 },
      { title: "Vehículo", stepNumber: 3 },
      { title: "Invitación", stepNumber: 4 },
      { title: "Resumen", stepNumber: 5 },
    ],
    documentTypes: [
      { Type: "CC", Title: "Cédula de Ciudadanía" },
      { Type: "CE", Title: "Cédula de Extranjería" },
      { Type: "TI", Title: "Tarjeta de Identidad" },
      { Type: "PASS", Title: "Pasaporte" },
    ],
    scheduleForm: {
      tipoDocumento: "",
      document_number: "",
      reason: "",
      who_visit: "",
      firstname: "",
      surname: "",
      phone: "",
      email: "",
      start_date: "",
      finish_date: "",
      vehicle: false,
      plate: "",
      parking_cell: "",
    },
    loadingUserDataBtn: false,
    loadingCreationBtn: false,
    rangeDateModal: false,
    rangeDates: [
      globals.COdate().substr(0, 10),
      globals.COdate().substr(0, 10),
    ],
    minDate: globals.COdate().substr(0, 10),
    hour_entry: "00:00",
    hour_exit: "23:59",
    valid: true,
    group_permission: "",
    stepperModel: 1,
    sendInviteType: "",
    personNotExist: false
  }),
  computed: {
    dateRangeText() {
      return this.rangeDates.join(" ~ ");
    },
  },
  methods: {
    // * Triggers and Validators
    clearVehicleInfo(e) {
      if (e === false) {
        this.scheduleForm.plate = "";
        this.scheduleForm.parking_cell = "";
      }
    },

    clearInviteType() {
      if (this.sendInviteType == "phone") {
        this.scheduleForm.email = "";
      } else {
        this.scheduleForm.phone = "";
      }
    },

    selectDates() {
      this.$refs.dialog.save(this.rangeDates);
      this.rangeDateModal = false;
    },

    validateRangeDates(rangeDates) {
      if (rangeDates[0] && rangeDates[1]) {
        if (rangeDates[0] > rangeDates[1]) {
          this.snackbar = true;
          this.snackbarText = "La fecha final no puede ser menor que la inicial.";
          this.snackbarColor = "error";
          this.rangeDates = [rangeDates[0]];
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "Debe seleccionar fecha de inicio y fecha final";
        this.snackbarColor = "error";
      }
    },

    validateDocument(document_type, document) {
      let PASS_PATTERN = /^[0-9A-Za-z _]*[0-9A-Za-z][0-9A-Za-z _]*$/
      if (document_type != 'PASS') {
        if (document && document.length >= 6 && /^[0-9]*$/.test(document)) {
         return false
        } else {
          return true
        }
      } else {
        if (document && document.length >= 7 && PASS_PATTERN.test(document)) {
         return false
        } else {
          return true
        }
      }
    },

    validateVehicle() {
      if (this.scheduleForm.vehicle == false) {
        return false
      } else {
        if (this.scheduleForm.vehicle && (!this.scheduleForm.plate || !this.scheduleForm.parking_cell)) {
          return true
        } else {
          if (this.scheduleForm.plate && this.scheduleForm.plate.length == 6 && 
          (this.scheduleForm.plate.charAt(0).toUpperCase() !== this.scheduleForm.plate.charAt(0).toLowerCase() &&
          this.scheduleForm.plate.charAt(1).toUpperCase() !== this.scheduleForm.plate.charAt(1).toLowerCase() &&
          this.scheduleForm.plate.charAt(2).toUpperCase() !== this.scheduleForm.plate.charAt(2).toLowerCase() &&
          !isNaN(this.scheduleForm.plate.charAt(3)) &&
          !isNaN(this.scheduleForm.plate.charAt(4)) &&
          !isNaN(this.scheduleForm.plate.charAt(5)))) {
            return false
          } else {
            return true
          }
        }
      }
    },

    validateEnvite() {
      let EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!this.sendInviteType) {
        return true
      } else {
        if (this.sendInviteType == 'phone') {
          if (this.scheduleForm.phone && this.scheduleForm.phone.length == 10 && /^[0-9]*$/.test(this.scheduleForm.phone)) {
            return false
          } else {
            return true
          }
        } else {
          if (this.scheduleForm.email && EMAIL_PATTERN.test(this.scheduleForm.email)) {
            return false
          } else {
            return true
          }
        }
      }
    },

    // * Start functions

    closeForm() {
      this.$bus.$emit("closeScheduleOverlay", "Close schedule overlay");
    },

    clearCreation() {
      this.rangeDates = [
        globals.COdate().substr(0, 10),
        globals.COdate().substr(0, 10),
      ];
      this.minDate = globals.COdate().substr(0, 10);
      this.hour_entry = "00:00";
      this.hour_exit = "23:59";
      this.valid = true;
      this.scheduleForm.tipoDocumento = "";
      this.scheduleForm.document_number = "";
      this.scheduleForm.firstname = "";
      this.scheduleForm.surname = "";
      this.scheduleForm.phone = "";
      this.scheduleForm.email = "";
      this.scheduleForm.reason = "";
      this.scheduleForm.who_visit = "";
      this.scheduleForm.start_date = "";
      this.scheduleForm.finish_date = "";
      this.stepperModel =  1;
      this.sendInviteType =  "";
      this.personNotExist =  false;
    },

    createScheduledVisitor(data) {
      this.loadingCreationBtn = true;
      if (data.vehicle == false) {
        data.plate = "";
        data.parking_cell = "";
      }

      let body = {
        uuid: uuidv4(),
        company_id: auth.get_schedule_company_id(),
        sede_id: auth.get_schedule_campus_id(),
        suborg_id: auth.get_schedule_suborg_id(),
        firstname: data.firstname,
        surname: data.surname,
        document_type: data.tipoDocumento,
        document_number: data.document_number,
        phone: data.phone,
        email: data.email,
        reason: data.reason,
        who_visit: data.who_visit,
        vehicle: data.vehicle,
        plate: data.plate !== "" ? (data.plate = data.plate.toUpperCase()) : "",
        parking_cell: data.parking_cell !== "" ? (data.parking_cell = data.parking_cell.toUpperCase()) : "",
        start_date: data.start_date,
        finish_date: data.finish_date,
        created_at: globals.COdate(),
        created_by: auth.get_schedule_person_id(),
        updated_by: "",
      };
      axios({
        method: "POST",
        url: globals.APIURL + "entry/create-schedule-visitor",
        data: {
          schedule: body,
          company_id: auth.get_schedule_company_id(),
          group_id: this.group_permission,
          suborg_id: auth.get_schedule_suborg_id(),
          invite_type: this.sendInviteType
        },
      }).then((res) => {
        if (res.status == 200) {
          this.loadingCreationBtn = false
          this.snackbarText = "Se agendo el visitante correctamente.";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.clearCreation();
          this.closeForm()
        }
        this.loadingCreationBtn = false
      }).catch((err) => {
        this.loadingCreationBtn = false
        console.err(err)
      });
    },

    getPremissionGroups() {
      axios({
        method: "GET",
        baseURL: globals.APIURL + "permission-group/get-pg/" + auth.get_asset_id(),
      })
        .then((data) => {
          if (data.data.length > 0) {
            for (let i = 0; i < data.data.length; i++) {
              const element = data.data[i];

              if (element.type_group == 'black_list') {
                data.data.splice(i, 1)
              }

              if (element.need_acceptance == true) {
                this.group_permission = element.uuid;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // * Step 1
    validateStepOne() {
      if (this.rangeDates[0] && this.rangeDates[0] && this.hour_entry && this.hour_exit) {
        if (this.hour_entry && this.hour_exit && this.hour_entry < this.hour_exit) {
          this.scheduleForm.start_date = this.rangeDates[0] + "T" + this.hour_entry + ":00.000Z",
          this.scheduleForm.finish_date = this.rangeDates[1] + "T" + this.hour_exit + ":59.000Z",
          this.stepperModel = 2
        } else {
          this.snackbar = true;
          this.snackbarText = "La hora final no puede ser menor a la inicial.";
          this.snackbarColor = "error";
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "Es necesario que llene todos los campos para continuar";
        this.snackbarColor = "error";
      }
      
    },

    // * Step 2
    getUserData() {
      if (this.scheduleForm.tipoDocumento && this.scheduleForm.document_number) {
        this.loadingUserDataBtn = true
        axios({
          method: "POST",
          baseURL: globals.APIURL + "user/find-person",
          data: {
            document_type: this.scheduleForm.tipoDocumento,
            document_number: this.scheduleForm.document_number
          }
        })
          .then((data) => {
            if (data.data) {
              if (data.data.RekognitionId) {
                this.scheduleForm.firstname = data.data.first_name;
                this.scheduleForm.surname = data.data.first_last_name;
                this.scheduleForm.phone = data.data.phone;
                this.scheduleForm.email = data.data.email;
                //this.stepperModel = 3
                this.loadingUserDataBtn = false;
              } else {
                this.personNotExist = true;
                if (data.data && data.data != 'Person not found') {
                  this.scheduleForm.firstname = data.data.names[0];
                  this.scheduleForm.surname = data.data.names[2];
                }
                this.loadingUserDataBtn = false;
              }
            }
          })
          .catch(() => {
            //console.error(err.response.data);
            /* this.snackbar = true;
            this.snackbarText = "Se presento un error, por favor intentelo mas tarde.";
            this.snackbarColor = "error"; */
            this.personNotExist = true;
            this.loadingUserDataBtn = false;
          });
      } else {
        this.snackbar = true;
        this.snackbarText = "Es necesario que llene todos los campos para continuar";
        this.snackbarColor = "error";
        this.loadingUserDataBtn = false;
      }
    },

    // * Step 2 - User not exist
    validateNotExistUser() {
      if (this.scheduleForm.firstname && this.scheduleForm.surname) {
        this.personNotExist = false;
        this.stepperModel = 3
        console.log("FORM: ", this.scheduleForm)
      } else {
        this.snackbar = true;
        this.snackbarText = "Es necesario que llene todos los campos para continuar";
        this.snackbarColor = "error";
      }
    },

    // * Step 2.1 - User exist validate data
    validateExistUser() {
      this.personNotExist = false;
      this.stepperModel = 3
    },

    // * Step 3
    validateVehicleStep() {
      if (this.scheduleForm.vehicle === true) {
        if (this.scheduleForm.plate && this.scheduleForm.parking_cell) {
          this.stepperModel = 4
        } else {
          this.snackbar = true;
          this.snackbarText = "Es necesario que llene todos los campos para continuar";
          this.snackbarColor = "error";
        }
      } else {
        this.stepperModel = 4;
      }
    },

    // * Step 4
    validateInvitationType() {
      if ((this.sendInviteType == "phone" && this.scheduleForm.phone) || (this.sendInviteType == "email" && this.scheduleForm.email)) {
        this.stepperModel = 5
      } else {
        this.snackbar = true;
        this.snackbarText = "Es necesario que llene el campo para continuar";
        this.snackbarColor = "error";
      }
    }
  },
  mounted() {
    this.getPremissionGroups();
  },
};
</script>
<style scoped>
.theme--dark.v-stepper {
  background: #212734;
}
</style>

