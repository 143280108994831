<template>
  <v-container fluid>
    <div class="text-center" v-if="dialogValidate == true">
      <v-dialog v-model="dialogValidate" persistent width="450">
        <v-card>
          <v-card-text class="pa-5">
            <v-row class="text-center">
              <v-col cols="12" v-if="selectOwner === false">
                <p class="my-0 py-0">
                  Está seguro que desea modificar el vehículo a recurrente? 
                </p>
              </v-col>
              <v-col cols="12" v-if="selectOwner === true">
                <p class="my-0 py-0">
                  Seleccione el conductor del vehículo.  
                </p>
              </v-col>
              <v-col cols="12" v-if="selectOwner === true">
                <v-autocomplete
                  v-model="ownerToUpdate"
                  :items="personRegistry"
                  return-object
                  label="Responsable"
                  :filter="customFilter"
                  outlined
                  dark
                  color="secondary"
                  dense
                  :loading="loadingResponsable"
                  loader-height="4"
                  :disabled="loadingResponsable"
                  no-data-text="No hay datos que mostrar"
                >
                  <template v-slot:selection="{ item }">
                    <span>{{
                      item.first_name +
                      " " +
                      (item.second_name ? item.second_name +
                        " " : "") +
                      item.first_last_name +
                      " " +
                      item.second_last_name
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <p>
                      {{
                        item.first_name +
                        " " +
                        (item.second_name ? item.second_name +
                        " " : "") +
                        item.first_last_name +
                        " " +
                        item.second_last_name
                      }}
                    </p>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="my0 py-0">
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                <v-btn
                  color="red"
                  rounded
                  small
                  @click="dialogValidate = false ; selectedVehicle.type_access = 'VISITANTE' ; selectOwner = false ; ownerToUpdate = null"
                >
                  Cancelar
                </v-btn>
              </v-col>
              <v-col v-if="selectOwner === false" cols="12" md="6" class="d-flex justify-center align-center">
                <v-btn
                  color="secondary"
                  rounded
                  small
                  @click="selectOwner = true"
                >
                  Si, continuar
                </v-btn>
              </v-col>
              <v-col v-if="selectOwner === true" cols="12" md="6" class="d-flex justify-center align-center">
                <v-btn
                  color="secondary"
                  rounded
                  small
                  @click="dialogValidate = false"
                >
                  guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogDetail == true">
      <v-dialog v-model="dialogDetail" width="800">
        <v-card>
          <v-card-title
            style="background-color: #212734"
            class="d-flex justify-center"
          >
            <span class="text-h5"> Detalle Vehicular </span>
          </v-card-title>
          <v-card-text>
            <v-row class="text-center mt-5">
              <v-col cols="12" class="d-flex justify-center my-0 py-0 mb-3">
                <v-img
                  :src="
                    getVehicleImg(carEntries[carEntries.length - 1].image[0])
                  "
                  v-if="carEntries.length > 0"
                  max-height="250px"
                  max-width="350px"
                  contain
                  lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                  class="border-radius"
                >
                </v-img>
                <p
                  v-if="!carEntries || carEntries.length === 0"
                  class="text-subtitle-1"
                  style="color: #9e9e9e"
                >
                  Aún no hay imagen del vehículo
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="!editMode" cols="12" md="4" class="d-flex justify-center pa-3">
                  <strong class="text-subtitle-2 font-weight-bold"
                  >CC. Responsable:
                </strong>
                <span class="text-subtitle-2 font-weight-light">{{ ownerToUpdate.document_number }}</span>
              </v-col>
              <v-col v-if="!editMode" cols="12" md="4" class="d-flex justify-center pa-3">
                  <strong class="text-subtitle-2 font-weight-bold"
                  >Responsable:
                </strong>
                <span class="text-subtitle-2 font-weight-light ml-1"> {{ ownerToUpdate.first_name + " " + ownerToUpdate.first_last_name + " " + ownerToUpdate.second_last_name }}</span>
              </v-col>
              <v-col cols="12" :md="editMode === false ? 4 : 6" class="d-flex justify-center pa-3">
                <div v-if="!editMode">
                  <strong class="text-subtitle-2 font-weight-bold"
                    >Uso Vehículo:
                  </strong>
                  <span
                    class="text-subtitle-2 font-weight-light"
                    >{{ selectedVehicle.type_access === 'VISITANTE' ? selectedVehicle.type_access : 'RECURRENTE' }}</span
                  >
                </div>
                <div v-if="editMode">
                  <v-select
                    v-model="selectedVehicle.type_access"
                    :items="driverTypes"
                    item-text="Name"
                    item-value="ID"
                    label="Uso Vehículo"
                    @change="checkVehicleTypeChange"
                    outlined
                    dense
                    hide-details
                    dark
                    color="secondary"
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12" md="6" v-if="editMode && selectedVehicle.type_access === 'EMPLEADO'" class="d-flex justify-center pa-3">
                <v-autocomplete
                  v-model="ownerToUpdate"
                  :items="personRegistry"
                  return-object
                  label="Responsable"
                  :filter="customFilter"
                  outlined
                  dark
                  color="secondary"
                  dense
                  :loading="loadingResponsable"
                  loader-height="4"
                  :disabled="loadingResponsable"
                  no-data-text="No hay datos que mostrar"
                >
                  <template v-slot:selection="{ item }">
                    <span>{{
                      item.first_name +
                      " " +
                      (item.second_name ? item.second_name +
                        " " : "") +
                      item.first_last_name +
                      " " +
                      item.second_last_name
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <p>
                      {{
                        item.first_name +
                        " " +
                        (item.second_name ? item.second_name +
                        " " : "") +
                        item.first_last_name +
                        " " +
                        item.second_last_name
                      }}
                    </p>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="d-flex justify-center pa-3">
                <div v-if="!editMode">
                  <strong class="text-subtitle-2 font-weight-bold"
                    >Tipo Vehículo:
                  </strong>
                  <span
                    class="text-subtitle-2 font-weight-light"
                    v-for="(item, i) in vehicleTypes"
                    :key="i"
                    >{{ selectedVehicle.type === item.ID ? item.Name : "" }}</span
                  >
                </div>
                <div v-if="editMode">
                  <v-select
                    v-model="selectedVehicle.type"
                    :items="vehicleTypes"
                    item-text="Name"
                    item-value="ID"
                    label="Tipo Vehículo"
                    outlined
                    dense
                    hide-details
                    color="secondary"
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12" md="3" class="text-center pa-3">
                <div v-if="!editMode">
                  <strong class="text-subtitle-2 font-weight-bold"
                    >Color:
                  </strong>
                  <span class="text-subtitle-2 font-weight-light">{{
                    selectedVehicle.color ? selectedVehicle.color : "S/A"
                  }}</span>
                </div>
                <div v-if="editMode">
                  <v-text-field
                    v-model="selectedVehicle.color"
                    label="Color"
                    outlined
                    dense
                    hide-details
                    dark
                    color="secondary"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="3" class="d-flex justify-center pa-3">
                <div v-if="!editMode">
                  <strong class="text-subtitle-2 font-weight-bold"
                    >Marca:
                  </strong>
                  <span class="text-subtitle-2 font-weight-light">{{
                    selectedVehicle.brand ? selectedVehicle.brand : "S/A"
                  }}</span>
                </div>
                <div v-if="editMode">
                  <v-autocomplete
                    v-model="selectedVehicle.brand"
                    :items="brands"
                    label="Marca"
                    outlined
                    dense
                    hide-details
                    dark
                    color="secondary"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12" md="3" class="text-center pa-3">
                <div v-if="!editMode">
                  <strong class="text-subtitle-2 font-weight-bold"
                    >Año Modelo:
                  </strong>
                  <span class="text-subtitle-2 font-weight-light">{{
                    selectedVehicle.yearModel ? selectedVehicle.yearModel : "S/A"
                  }}</span>
                </div>
                <div v-if="editMode">
                  <v-text-field
                    v-model="selectedVehicle.yearModel"
                    label="Año Modelo"
                    outlined
                    dense
                    hide-details
                    dark
                    color="secondary"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
                <v-col v-if="!editMode || editMode === false" cols="12">
                    <v-btn
                    color="primary"
                    rounded
                    block
                    @click="activateEditMode(selectedVehicle)"
                    >
                    Editar Vehículo
                    </v-btn>
                </v-col>
              <v-col v-if="editMode" cols="12" md="6">
                <v-btn
                  color="secondary"
                  rounded
                  block
                  :loading="loadingBtns"
                  :disabled="
                    loadingBtns === false 
                      ? false
                      : true
                  "
                  @click="updateVehicle(selectedVehicle)"
                >
                  Guardar Cambios
                </v-btn>
              </v-col>
              <v-col v-if="editMode" cols="12" md="6">
                <v-btn
                  color="error"
                  rounded
                  block
                  @click="cancelEditMode(selectedVehicle)"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-layout child-flex>
                  <v-data-table
                    :headers="entriesHeaders"
                    :items="carEntries"
                    :header-props="{
                      mobile: true,
                    }"
                    :footer-props="{
                      'items-per-page-options': [20],
                    }"
                    class="dtTable"
                    :loading="loading"
                    loading-text="Cargando Información..."
                    :no-results-text="`No se encontrarón registros por su dato de busqueda (${search})`"
                    no-data-text="No hay datos que mostrar"
                    dense
                    fixed-header
                  >
                    <template v-slot:item="{ item }">
                      <tr class="mx-0 px-0">
                        <td class="text-center">
                          {{ item.mode == "ENTRY" ? "ENTRADA" : "SALIDA" }}
                        </td>
                        <td class="text-center">
                          {{ item.created_at.substring(0, 10) }} -
                          {{ item.created_at.substring(11, 19) }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-layout>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-row class="text-center">
      <v-col cols="12">
        <p class="white--text text-h5">Registros Vehiculares</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" class="ctTablaCam" style="border-radius: 10px">
          <v-row class="text-center">
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Filtrar registros"
                single-line
                outlined
                dark
                color="secondary"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-layout child-flex>
                <v-data-table
                  :headers="headers"
                  :items="itemsList"
                  :search="search"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [20],
                  }"
                  class="dtTable"
                  :loading="loading"
                  loading-text="Cargando Información..."
                  :no-results-text="`No se encontrarón registros por su dato de busqueda (${search})`"
                  no-data-text="No hay registros que mostrar"
                  dense
                  fixed-header
                >
                  <template v-slot:item="{ item }">
                    <tr :class="markedRow(item)" class="mx-0 px-0">
                      <td>{{ item.plate }}</td>
                      <td>{{ item.type_access }}</td>
                      <td>
                        <strong
                          @click="viewUser(item)"
                          style="cursor: pointer"
                          >{{ item.owner.document_number }}</strong
                        >
                      </td>
                      <td>
                        <strong
                          @click="viewUser(item)"
                          style="cursor: pointer"
                          >{{ item.owner.fullName }}</strong
                        >
                      </td>
                      <td>
                        {{ item.created_at.substring(0, 10) }}
                      </td>
                      <td>
                        <v-btn
                          icon
                          class="primary"
                          @click="selectOneRegistry(item)"
                        >
                          <i class="fal fa-eye"></i>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-layout>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import roles from "../services/role";

export default {
  name: "VehicleRegistrations",
  data: () => ({
    roleChecker: roles,
    userRole: auth.getRoleName(),
    modules: auth.getModules(),
    editMode: false,
    userOrgName: auth.getUserEnterpriseName(),
    todayDate: new Date().toISOString().substr(0, 10),
    itemsList: [],
    carEntries: [],
    loading: false,
    loadingBtns: false,
    loadingResponsable: false,
    gbls: globals,
    search: "",
    dialogValidate: false,
    dialogDetail: false,
    selectedVehicle: null,
    selectOwner: false,
    ownerToUpdate: null,
    personRegistry: [],
    headers: [
      { text: "Placa", align: "center", value: "plate", sortable: false },
      {
        text: "Tipo Acceso",
        align: "center",
        value: "type_access",
        sortable: true,
      },
      {
        text: "CC Resp.",
        align: "center",
        value: "owner.document_number",
        sortable: true,
      },
      {
        text: "Responsable",
        align: "center",
        value: "owner.fullName",
        sortable: false,
      },
      {
        text: "Fecha Creación",
        align: "center",
        value: "created_at",
        sortable: true,
      },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    entriesHeaders: [
      { text: "Tipo Evento", align: "center", value: "mode", sortable: false },
      {
        text: "Fecha - Hora",
        align: "center",
        value: "created_at",
        sortable: true,
      },
    ],
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    vehicleTypes: [
      { ID: "VEHICLE", Name: "Automóvil" },
      { ID: "TRUCK", Name: "Camioneta" },
    ],
    driverTypes: [
      { ID: "EMPLEADO", Name: "RECURRENTE" },
      { ID: "VISITANTE", Name: "VISITANTE" },
    ],
    brands: [
      "Acura",
      "Alfa Romeo",
      "Aptera",
      "Aston Martin",
      "Audi",
      "Austin",
      "Bentley",
      "BMW",
      "Bugatti",
      "Buick",
      "Cadillac",
      "Chevrolet",
      "Chrysler",
      "Citroën",
      "Corbin",
      "Daewoo",
      "Daihatsu",
      "Dodge",
      "Eagle",
      "Fairthorpe",
      "Ferrari",
      "FIAT",
      "Fillmore",
      "Foose",
      "Ford",
      "Geo",
      "GMC",
      "Hillman",
      "Holden",
      "Honda",
      "HUMMER",
      "Hyundai",
      "Infiniti",
      "Isuzu",
      "Jaguar",
      "Jeep",
      "Jensen",
      "Kia",
      "Lamborghini",
      "Land Rover",
      "Lexus",
      "Lincoln",
      "Lotus",
      "Maserati",
      "Maybach",
      "Mazda",
      "McLaren",
      "Mercedes-Benz",
      "Mercury",
      "Merkur",
      "MG",
      "MINI",
      "Mitsubishi",
      "Morgan",
      "Nissan",
      "Oldsmobile",
      "Panoz",
      "Peugeot",
      "Plymouth",
      "Pontiac",
      "Porsche",
      "Ram",
      "Rambler",
      "Renault",
      "Rolls-Royce",
      "Saab",
      "Saturn",
      "Scion",
      "Shelby",
      "Smart",
      "Spyker",
      "Spyker",
      "Studebaker",
      "Subaru",
      "Suzuki",
      "Tesla",
      "Toyota",
      "Volkswagen",
      "Volv",
    ],
  }),
  methods: {
    customFilter(item, queryText) {
      const textAllName = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.second_name) ? item.second_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '') + " " + ((item.second_last_name) ? item.second_last_name.toLowerCase() : '');
      const textNameAndFirstLastname = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '') + " " + ((item.second_last_name) ? item.second_last_name.toLowerCase() : '');
      const textNameAndAllLastname = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '');
      const cedula = (item.document_number) ? item.document_number : '';
      const searchText = queryText.toLowerCase();

      return (
        textAllName.indexOf(searchText) > -1 ||
        textNameAndFirstLastname.indexOf(searchText) > -1 ||
        textNameAndAllLastname.indexOf(searchText) > -1 || 
        cedula.indexOf(searchText) > -1
      );
    },

    markedRow(item) {
      if (this.itemContent) {
        if (item.uuid === this.itemContent.uuid) {
          return "selected-row";
        }
      }
    },

    activateEditMode(vehicle) {
      this.editMode = true;
      this._beforeEditingCache = Object.assign({}, vehicle);
    },

    cancelEditMode(vehicle) {
        this.editMode = false;
        Object.assign(vehicle, this._beforeEditingCache);
    },

    viewUser(item) {
      this.$router.replace({
        name: "registrations",
        params: {
          cedula_registro: {
            uuid: item.owner.uuid,
            document_number: item.owner.document_number,
          },
        },
      });
    },

    getRegistry() {
      this.loading = true;
      axios
        .post(globals.APIURL + "vehicle/fvbs", {
          company_id: auth.getUserEnterpriseID(),
          campus_id: auth.getSelectedSede(),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.itemsList = res.data;
            console.log(res.data);
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.getRegistry();
          console.log(err.response);
        });
    },

    getPersonRegistry() {
      this.loadingResponsable = true
      axios
        .post(globals.APIURL + "registry/fal", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              
              delete element.company_person
            }
            this.personRegistry = res.data;
            console.log(res.data)
            this.loadingResponsable = false
          }
          this.loadingResponsable = false
        })
        .catch((err) => {
          this.loadingResponsable = false
          console.log(err);
        });
    },

    updateVehicle(vehicle) {
        this.loadingBtns = true
        let vehicleToUpdate = {
            plate: vehicle.plate.toUpperCase(),
            type: vehicle.type,
            brand: vehicle.brand,
            yearModel: vehicle.yearModel,
            updated_at: globals.COdate(),
            type_access: vehicle.type_access,
            status: vehicle.status,
            owner_personId: this.ownerToUpdate.uuid
        };
        axios
            .put(globals.APIURL + "vehicle/ur", {
                vehicle_id: vehicle.uuid,
                update_vehicle_obj: vehicleToUpdate,
                company_id: auth.getUserEnterpriseID(),
                campus_id: auth.getSelectedSede()
            })
            .then((res) => {
                if (res.data && res.data == "Vehicle updated successful") {
                  this.editMode = false;
                  this.snackbarText = "Se actualizó el vehículo de manera exitosa.";
                  this.snackbarColor = "green";
                  this.snackbar = true;
                  this.loadingBtns = false
                  this.getRegistry()
                }
                this.loadingBtns = false
            })
            .catch((err) => {
                this.loadingBtns = false
                console.log(err);
            });
    },

    checkVehicleTypeChange(item) {
      if (item == "EMPLEADO") {
        this.dialogValidate = true;
      }
    },

    selectOneRegistry(item) {
      this.ownerToUpdate = {
        uuid: item.owner.uuid,
        document_number: item.owner.document_number,
        first_name: item.owner.first_name,
        second_name: item.owner.second_name,
        first_last_name: item.owner.first_last_name,
        second_last_name: item.owner.second_last_name
      }
      this.carEntries = [];
      this.selectedVehicle = item;
      this.carEntries = item.entries;
      this.dialogDetail = true;
    },

    getVehicleImg(id) {
      if (id) {
        var formatURL = "";
        if (id) {
          formatURL =
            globals.APIURL +
            "vehicle/entry-visitor/" +
            auth.getUserEnterpriseName() +
            "/" +
            id;
        }
        return formatURL;
      }
    },
  },
  mounted() {
    this.getPersonRegistry()
    this.getRegistry();
  },
};
</script>
<style>
table {
  border-collapse: collapse;
}

.selected-row {
  background-color: #d3d3d385;
  transform: scale(1.01);
}

tr:hover {
  background-color: #d3d3d385;
  transform: scale(1.01);
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>