<template>
    <v-container fluid>
      <div class="text-center" v-if="dialogShareValidation == true">
        <v-dialog v-model="dialogShareValidation" persistent width="500">
          <v-card>
            <v-card-title class="d-flex justify-center">
              Compartir Imformación
            </v-card-title>
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12">
                  <p class="my-0 py-0">
                    <small
                      >Está seguro que desea compartir su información con {{ companiesSelected.length == 1 ? 'la compañia seleccionada?' : 'las siguientes ' + companiesSelected.length + ' compañias seleccionadas?' }}: </small
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row class="my0 py-0">
                <v-col cols="12" md="6">
                  <v-btn
                    color="primary"
                    block
                    :loading="loadingShare"
                    @click="shareData()"
                  >
                    SI, COMPARTIR
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    color="error"
                    block
                    @click="
                      companiesSelected = null;
                      dialogShareValidation = false;
                    "
                  >
                    Cancelar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <v-overlay opacity="1" :value="survey && openSurveyMsg">
        <v-card dark>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="12" class="d-flex justify-end ma-0 pa-0">
                <v-btn class="mt-1 mr-1" icon x-small dark @click="openSurveyMsg = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="d-flex justify-center px-5">
                <p>Usted ya realizó la encuesta el día de hoy</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-overlay>

      <div v-if="overlayLoading" class="text-center">
        <v-overlay opacity="1" :value="overlayLoading">
          <v-card dark>
            <v-card-text>
              {{ overlayLoadingText }}
              <v-progress-linear
                indeterminate
                color="secondary"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-overlay>
      </div>

      <div class="text-center" v-if="changeImageDialog == true">
        <v-dialog v-model="changeImageDialog" width="500">
          <v-card>
            <v-card-text class="pb-0">
              <v-row class="text-center">
                <v-col cols="12" class="d-flex justify-center">
                  <v-expansion-panels accordion flat>
                    <v-expansion-panel style="background-color: #3C465D;">
                      <v-expansion-panel-header>Información a tener en cuenta</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <label class="text-caption mb-3">
                          -Tenga en cuenta que el cambio de imagen de perfil afectara de manera directa su reconocimiento con los dispositivos de acceso,
                          por lo anterior se le recomienda que no lleve alguno de los siguientes elementos: lentes, sombrero, gorra o mascarilla.
                        </label>
                        <label class="text-caption">
                          -Tambien se recomienda que el lente de su dispositivo este limpio y tenga una fuente de iluminación buena para que su foto quede con la mejor calidad posible.
                        </label>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col cols="12" class="d-flex justify-center pb-0">
                  <p class="text-h5">Actualizar Imagen de Perfil</p>
                </v-col>
                <div v-if="selfieMode1 && !selfieMode2" style="width: 100%">
                  <v-col cols="12" class="d-flex justify-center pt-0">
                    <take-photo :typeUse="'selfie'" :imageName="'new_selfie_' + newImgUUID" v-on:photo-taken-selfie="validateChangeImg"></take-photo>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center ma-0">
                    <v-btn
                      x-small
                      color="grey darken-3"
                      @click="selfieMode1 = !selfieMode1 ; selfieMode2 = !selfieMode2"
                    >
                      Cambiar Modo
                    </v-btn>
                  </v-col>
                  <v-divider></v-divider>
                </div>

                <div v-if="selfieMode2 && !selfieMode1" style="width: 100%">
                  <v-col cols="12" class="d-flex justify-center pt-0">
                    <v-icon v-if="newSelfieImg == null" style="font-size: 120px;" x-large color="grey" dark> mdi-account-box</v-icon>
                    <v-img
                      v-if="newSelfieImg != null"
                      :src="newSelfieImg"
                      width="250px"
                      height="200px"
                      class="white--text align-center"
                      
                      aspect-ratio="2"
                      contain
                    >
                    </v-img>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      color="success"
                      class="white--text"
                      small
                      @click="clickNewSelfieInput"
                    >
                      SUBIR SELFIE
                    </v-btn>
                    <input
                      type="file"
                      @change="readNewSelfieFile"
                      style="display: none"
                      ref="newSelfieInput"
                      accept="image/*"
                    />
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center ma-0">
                    <v-btn
                      x-small
                      color="grey darken-3"
                      @click="selfieMode1 = !selfieMode1 ; selfieMode2 = !selfieMode2"
                    >
                      Cambiar Modo
                    </v-btn>
                  </v-col>
                  <v-divider></v-divider>
                </div>

                <v-col cols="12" class="text-center">
                  <v-btn
                    rounded
                    color="primary"
                    small
                    class="px-4 mx-2 primaryBtn"
                    @click="changeProfileImg"
                    :loading="loadingChangeProfileImgBtn"
                    :disabled="!validFaceAnalysis"
                  >
                    Actualizar Imagen de Perfil
                  </v-btn> 
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div class="text-center" v-if="openChatModal == true">
        <v-dialog v-model="openChatModal" max-width="500px" persistent>
          <chat-bot :session_data="person_data"></chat-bot>
        </v-dialog>
      </div>

      <v-row>
        <v-col cols="12" class="d-flex align-center">
            <label class="line text-h6 ml-1" :class="personalInfo && !survey ? 'text-weight-bold white--text' : ''" style="cursor: pointer" @click="personalInfo = true ; survey = false">Datos Personales</label>
            <!-- <label v-if="enableSurvey == false" class="text-h6 ml-1">/</label>
            <label v-if="enableSurvey == false" class="line text-h6 ml-1" :class="!personalInfo && !survey ? 'text-weight-bold white--text' : ''" style="cursor: pointer" @click="personalInfo = false ; survey = false">Compartidos</label> -->
            <label v-if="enableSurvey == true" class="text-h6 ml-1">/</label>
            <label v-if="enableSurvey == true" class="text-h6 ml-1" :class="survey ? 'text-weight-bold white--text' : ''" style="cursor: pointer" @click="survey = true ; survey1 = false ; survey2 = false">Encuesta</label>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row v-if="personalInfo && !survey" class="mt-5" justify="center">
        <v-card class="profile-card" rounded="xl">
          <v-card-title class="pl-0 profile-card-title-layout profile-card-title-grid">
            <div class="pl-1">
              <v-img
                :src="setUrlRegisterImg(person_data)"
                class="white--text rounded-edges"
                max-height="150px"
                max-width="250px"
                contain
              >
                <!-- <div class="d-flex justify-center">
                  <v-btn
                    color="grey darken-3"
                    x-small
                    style="position: absolute; top: 85%;"
                    @click="startProfileImgUpdate"
                  >
                    MODIFICAR
                  </v-btn>
                </div> -->
              </v-img>
            </div>
            <div>
              <p>Datos: </p>
              <div class="pl-5">
                <p>
                  <v-icon class="pr-1 pb-1">mdi-card-account-details</v-icon>
                  {{person_data ? person_data.document_number : '---'}}
                </p>
                <p>
                  <v-icon class="pr-1 pb-1">mdi-account</v-icon>
                  {{person_data ? person_data.first_name : '---'}} {{person_data && person_data.second_name ? person_data.second_name : "N/R"}} {{person_data ? person_data.first_last_name : '---'}} {{person_data && person_data.second_last_name ? person_data.second_last_name : "N/R"}}
                </p>
              </div>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-row class="px-2">
              <v-col cols="12" md="6" class="pb-0 pt-5">
                <v-text-field
                  v-model="personal_email"
                  :rules="emailRules"
                  label="Correo Electronico"
                  :disabled="!editMode"
                  outlined
                  dense
                  dark
                  color="secondary"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0 pt-5">
                <v-text-field
                  v-model="personal_phone"
                  :rules="phoneRules"
                  label="Número Celular"
                  :disabled="!editMode"
                  outlined
                  dense
                  dark
                  color="secondary"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex justify-center pt-0">
                <v-btn
                  v-if="!editMode"
                  color="secondary"
                  @click="editMode = true"
                >
                Editar
                <v-icon class="pl-1" small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="editMode"
                  color="success"
                  class="mx-2"
                  @click="updateData(personal_email, personal_phone)"
                >
                Guardar
                <v-icon class="pl-1" small>mdi-arrow-right-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="editMode"
                  color="red"
                  class="mx-2"
                  @click="editMode = false"
                >
                Cancelar
                <v-icon class="pl-1" small>mdi-cancel</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-row>
      <v-row v-if="!personalInfo && !survey">
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <label class="text-h6 ml-2">Compartir información con: </label>
            </v-col>
            <v-col cols="12" md="9" class="d-flex align-center pl-4">
              <v-select
                v-model="companiesSelected"
                :items="companiesToChose"
                item-text="name"
                item-value="uuid"
                chips
                label="Compañias Disponibles"
                multiple
                outlined
                dense
                hide-details
              >
                <template v-slot:selection="{ item }">
                  <v-chip 
                    color="secondary"
                    label
                    outlined
                    class="mx-1 my-2">
                      <span>{{ item.name }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center justify-center" >
              <v-btn
                color="secondary"
                @click="dialogShareValidation = true"
                :disabled="!companiesSelected || companiesSelected.length == 0"
              >
              Compartir
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col cols="12" class="pt-0">
              <label class="text-h6 ml-2">Compañias con quien ya compartes tu información: </label>
            </v-col>
            <v-col cols="12">
              <v-chip
                v-for="(item, i) in companies" :key="i"
                class="ma-2"
                color="secondary"
                label
                outlined
              >
                {{ item.name }}
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="survey">
        <v-col v-if="surveyAlreadyMade" cols="12" class="my-0 pl-3 pb-0">
          <label>
            <strong>*</strong>
            Las encuestas se encuentran inhabilitadas dado que ya se realizó el día de hoy.
          </label>
        </v-col>
        <!-- <v-col v-if="survey && !survey1 && !survey2" cols="12" md="4">
          <v-card elevation="4" @click="openSurvey('survey_1')" :style="surveyAlreadyMade ? 'cursor: not-allowed;' : 'cursor: pointer;'">
              <v-card-text class="py-0">
                <v-row>
                  <v-col cols="10">
                    <strong class="text-left">Encuesta de Terceros</strong>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center" v-if="!surveyAlreadyMade">
                    <i class="fal fa-square text-right" style="font-size: 30px;" :style="'color: #E0E0E0;'"></i>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center" v-if="surveyAlreadyMade && lastSurvey && lastSurvey.survey_type == 'tercero'">
                    <i class="fal fa-check-square text-right" style="font-size: 30px;" :style="'color: #5AB55E;'"></i>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center" v-if="surveyAlreadyMade && lastSurvey && lastSurvey.survey_type != 'tercero'">
                    <i class="fal fa-minus-square text-right" style="font-size: 30px;" color="success"></i>
                  </v-col>
                </v-row>
              </v-card-text>
          </v-card>
        </v-col> -->
        <v-col v-if="survey && !survey1 && !survey2" cols="12" md="4">
          <v-card elevation="4" @click="openSurvey('survey_2')" :style="surveyAlreadyMade ? 'cursor: not-allowed;' : 'cursor: pointer;'">
              <v-card-text class="py-0">
                <v-row >
                  <v-col cols="10">
                    <strong class="text-left">Encuesta Colaboradores</strong>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center" v-if="!surveyAlreadyMade">
                    <i class="fal fa-square text-right" style="font-size: 30px;" :style="'color: #E0E0E0;'"></i>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center" v-if="surveyAlreadyMade && lastSurvey && lastSurvey.survey_type == 'colaborador'">
                    <i class="fal fa-square-check text-right" style="font-size: 30px;" :style="'color: #5AB55E;'"></i>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center" v-if="surveyAlreadyMade && lastSurvey && lastSurvey.survey_type != 'colaborador'">
                    <i class="fal fa-minus-square text-right" style="font-size: 30px;" color="success"></i>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- <div class="ma-0 pa-0 text-right">
                
              </div> -->
          </v-card>
        </v-col>

        <!-- // * Encuesta Terceros -->
        <v-col v-if="survey && survey1 && !survey2" cols="12">
          <v-row>
            <v-col cols="12">
              <label class="text-h5 text-center">Encuesta de salud - Terceros</label>
            </v-col>
            <v-col cols="12">
              <v-expansion-panels class="px-8" accordion>
                <v-expansion-panel style="background-color: #3C465D;">
                  <v-expansion-panel-header>Información...</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="text-caption mb-1">La Política de Tratamiento de Datos Personales de la Compañía, se encuentra publicada en la página web: <a href="https://www.solla.com" target="_blank" style="color: inherit">www.solla.com</a> </p>
                    <p class="text-caption mb-2">En cumplimiento de los Protocolos de Bioseguridad, SOLLA S.A., ha venido solicitando a sus empleados, contratistas, proveedores y a los visitantes ocasionales y/o periódicos de sus instalaciones, el suministro de datos personales relativos a su salud.</p>
                    <p class="text-caption mb-2">Por lo anterior, la compañía ha dispuesto el presente: </p>
                    <p class="text-caption mb-2">AVISO DE PRIVACIDAD.</p>
                    <p class="text-caption mb-2">SOLLA S.A., identificada con NIT. 890.900.291-8 y con domicilio en la Cra 42 # 33-80, Itagüí-
                    Antioquia, realizará el tratamiento de sus datos personales para dar cumplimiento a lo establecido en los Protocolos de Bioseguridad de la Organización, elaborados de conformidad con la legislación vigente. </p>
                    <p class="text-caption mb-2">Respecto al tratamiento de datos sensibles que sean recolectados para las finalidades anteriormente mencionadas, informamos que la entrega de los mismos es de carácter facultativo.</p>
                    <p class="text-caption mb-2">Como titular de sus datos personales, usted tiene derecho a: (i) Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o a aquellos cuyo tratamiento esté prohibido o no haya sido autorizado. (ii) Solicitar prueba de la autorización otorgada. (iii) Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud, respecto del uso que le ha dado a sus datos personales. (iv) Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente. (v) Revocar la autorización y/o solicitar la supresión del dato, cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <label class="px-10 pt-10"><strong style="color: #F87171;">*</strong> Obligatorio</label>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">Empresa a la que pertenece<strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-text-field
                v-model="survey_obj.company"
                placeholder="Escriba su respuesta"
                outlined
                dense
                dark
                color="secondary"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">Placa del vehículo</label>
              <v-text-field
                v-model="survey_obj.plate"
                placeholder="Escriba su respuesta"
                outlined
                dense
                dark
                color="secondary"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">Sede a la que desea ingresar<strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-select
                v-model="survey_obj.sede"
                :items="sollaSedes"
                placeholder="Selecciona la respuesta"
                outlined
                dense
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">Motivo de su visita<strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-radio-group
                v-model="survey_obj.reason"
                column
              >
                <v-radio
                  label="Cargar/Descargar"
                  value="Cargar/Descargar"
                ></v-radio>
                <v-radio
                  label="Soy contratista"
                  value="Soy contratista"
                ></v-radio>
                <v-radio
                  label="Soy visitante"
                  value="Soy visitante"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" class="px-10" v-if="survey_obj.reason == 'Cargar/Descargar'">
              <label class="mb-2">Seleccione el tipo de material<strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-radio-group
                v-model="survey_obj.materialType"
                column
              >
                <v-radio
                  label="Materia prima"
                  value="Materia prima"
                ></v-radio>
                <v-radio
                  label="Producto terminado"
                  value="Producto terminado"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">¿Ha presentado usted alguno de los siguientes síntomas (fiebre, tos,
              dolor de garganta, secreciones nasales, malestar general, dificultad para respirar, diarrea, perdida del olfato, perdida del gusto, escalofríos, dolor de pecho y dolor de cabeza asociado a otro síntoma de la lista) en los últimos 7 días y/o presenta alguna enfermedad en la piel de las manos en los últimos 15 días.?
              <strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-radio-group
                v-model="survey_obj.symptoms"
                column
              >
                <v-radio
                  label="Si"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="No"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-1">¿Cuentas con esquema completo de vacunación contra Covid-19 y las dosis de refuerzo? <strong class="pl-1" style="color: #F87171;">*</strong></label>
              <label class="mb-2">Recuerda que con el biológico JANSSEN una dosis de vacunación es <strong>esquema completo</strong>; con los demás biológicos debes de tener las dos dosis de la vacuna y dos dosis de refuerzo para cualquier vacuna. </label>
              <v-radio-group
                v-model="survey_obj.vaccinated"
                column
              >
                <v-radio
                  label="Si"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="No"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">El vehículo y el conductor cuenta con documentación al día (Licencia de conducción, SOAT y tecnicomecanica, etc.)<strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-radio-group
                v-model="survey_obj.vehicleAndDriverDocumentation"
                column
              >
                <v-radio
                  label="Si"
                  value="Si"
                ></v-radio>
                <v-radio
                  label="No"
                  value="No"
                ></v-radio>
                <v-radio
                  label="No aplica"
                  value="No aplica"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">El vehículo cuenta con inspección pre operacional y mantenimientos preventivos o correctivos que garantice el buen estado del mismo.<strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-radio-group
                v-model="survey_obj.vehicleInspectionToOperate"
                column
              >
                <v-radio
                  label="Si"
                  value="Si"
                ></v-radio>
                <v-radio
                  label="No"
                  value="No"
                ></v-radio>
                <v-radio
                  label="No aplica"
                  value="No aplica"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn color="secondary" @click="sendSurvey(survey_obj, 'survey_1')">
                Enviar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- // * Encuesta Colaboradores -->
        <v-col v-if="survey && !survey1 && survey2" cols="12">
          <v-row>
            <v-col cols="12">
              <label class="text-h5 text-center">Encuesta de salud - Colaboradores</label>
            </v-col>
            <v-col cols="12">
              <v-expansion-panels class="px-8" accordion>
                <v-expansion-panel style="background-color: #3C465D;">
                  <v-expansion-panel-header>Información...</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="text-caption mb-2">Autorizo de manera voluntaria, previa, explícita e informada a SOLLA S.A. para tratar mis datos personales de acuerdo con su Política de Tratamiento de Datos Personales para los fines relacionados con su objeto y en especial para fines legales, contractuales, misionales descritos en la Política de Tratamiento de Datos Personales de SOLLA S.A. que podrá consultar en <a href="https://www.solla.com" target="_blank" style="color: inherit">www.solla.com</a></p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <label class="px-10 pt-10"><strong style="color: #F87171;">*</strong> Obligatorio</label>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">Selecciona la que corresponda <strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-radio-group
                v-model="survey_obj.rol"
                column
              >
                <v-radio
                  label="Soy colaborador directo de la compañía"
                  value="Soy colaborador directo de la compañía"
                ></v-radio>
                <v-radio
                  label="Soy colaborador de empresa Temporal"
                  value="Soy colaborador de empresa Temporal"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">Sede <strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-select
                v-model="survey_obj.sede"
                :items="sollaSedes"
                placeholder="Selecciona la respuesta"
                outlined
                dense
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">Usted hoy se encuentra laborando en <strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-radio-group
                v-model="survey_obj.workType"
                column
              >
                <v-radio
                  label="La empresa"
                  value="La empresa"
                ></v-radio>
                <v-radio
                  label="La casa"
                  value="La casa"
                ></v-radio>
                <v-radio
                  label="Trabajo en campo"
                  value="Trabajo en campo"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-2">¿Ha presentado usted alguno de los siguientes síntomas (fiebre, tos, dolor de garganta, secreciones nasales, malestar general, dificultad para respirar, diarrea, perdida del olfato, perdida del gusto, escalofríos, dolor de pecho y dolor de cabeza asociado a otro síntoma de la lista) en los últimos 7 días y/o presenta alguna enfermedad en la piel de las manos en los últimos 15 días?<strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-radio-group
                v-model="survey_obj.symptoms"
                column
              >
                <v-radio
                  label="Si"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="No"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <!-- <v-col cols="12" md="6" class="px-10">
              <label class="mb-1">¿Cuentas con esquema completo de vacunación contra Covid-19 y las dosis de refuerzo? <strong class="pl-1" style="color: #F87171;">*</strong></label>
              <label class="mb-2">Recuerda que con el biológico JANSSEN una dosis de vacunación es <strong>esquema completo</strong>; con los demás biológicos debes de tener las dos dosis de la vacuna y dos dosis de refuerzo para cualquier vacuna. </label>
              <v-radio-group
                v-model="survey_obj.vaccinated"
                column
              >
                <v-radio
                  label="Si"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="No"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col> -->
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-1">¿Cuál es tu estado de ánimo hoy? <strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-select
                v-model="survey_obj.mood"
                :items="moods"
                placeholder="Selecciona una respuesta"
                outlined
                dense
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="px-10">
              <label class="mb-1">En caso que alguna de tus respuestas sea: Ansioso, de mal humor, desanimado, frustrado y atemorizado, por favor elige entre las opciones que se enuncian a continuación, cual consideras que sea la causa que te llevan a experimentar dicho estado de ánimo: <strong class="pl-1" style="color: #F87171;">*</strong></label>
              <v-select
                v-model="survey_obj.mood_reason"
                :items="mood_reasons"
                placeholder="Selecciona una respuesta"
                outlined
                dense
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn color="secondary" :loading="sendSurveyLoading" @click="sendSurvey(survey_obj, 'survey_2')">
                Enviar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-fab-transition v-if="person_data && (person_data.document_number === '1152707387' || person_data.document_number === '1039456721')">
        <v-btn
          color="primary darken-1"
          large
          fab
          dark
          bottom
          right
          fixed
          @click="openChatModal = !openChatModal"
        >
          <i style="font-size: 25px;" class="fal fa-message pt-1"></i>
        </v-btn>
      </v-fab-transition>
      <v-snackbar v-model="snackbar" timeout="10000" :color="snackbarColor" top>
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
</template>

<script>
import {S3} from "aws-sdk";
import globals from "../../globals";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import auth from "../../services/auth";
//import backlog from "../services/logs";
import TakePhoto from "../utils/take-photo.vue"
import ChatBot from "../chatbot/chatbot.vue"

export default {
  name: "PersonInfo",
  components: {
    TakePhoto,
    ChatBot
  },
  data: () => ({
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    simpleRule: [(v) => !!v || "El campo es obligatorio."],
    phoneRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        (v && v.length == 10) || "El número celular debe tener 10 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El campo solo permite números",
    ],
    emailRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "El correo electronico no esta formado correctamente",
    ],
    personalInfo: true,
    personal_email: "",
    personal_phone: "",
    editMode: false,
    companiesSelected: null,
    companies: [],
    sedes: [],
    companiesToChose: [],
    dialogShareValidation: false,
    loadingShare: false,
    person_data: null,
    survey: false,
    survey1: false,
    survey2: false,
    survey_obj: {
      company: "",
      plate: "",
      sede: "",
      reason: "",
      rol: "",
      materialType: "",
      workType: "",
      symptoms: null,
      vaccinated: null,
      vehicleAndDriverDocumentation: "",
      vehicleInspectionToOperate: "",
      mood: null,
      mood_reason: null
    },
    moods: [
      "Ansioso",
      "De mal humor",
      "Desanimado",
      "Frustrado",
      "Atemorizado",
      "Equilibrado",
      "Calmado",
      "Enérgico",
      "Comunicativo",
      "Alegre",
      "Satisfecho"
    ],
    mood_reasons: [
      //"Temor al contagio de COVID-19",
      "Asuntos económicos",
      "Dificultad en las relaciones interpersonales",
      "Temor a quedar desempleado",
      "Ninguna de las anteriores",
    ],
    sollaSedes: [
      /* "Bello",
      "Mosquera",
      "Cartagena",
      "Girón",
      "Buga",
      "SPG Buga",
      "Pasto",
      "Funza",
      "Ibagué",
      "Neiva",
      "Pereira",
      "Don Matías",
      "Itaguí",
      "Cucuta",
      "Barranquilla",
      "Buenaventura",
      "Santa Marta", */
      "Solla - Itagüí",
      "Solla - Bello",
      "Solla - Buga",
      "Solla - Mosquera",
      "Solla - Girón",
      "Agrinal - Cartagena",
      "Distraves - Administrativa",
      "Distraves - Planta Diamante",
      "Distraves - Planta ABA",
      "Distraves - Planta Incubación",
      "SPG - Candelaria",
      "SPG - ABA Buga"
    ],
    regionales: [
      "Itaguí",
      "Bello",
      "Cartagena",
      "Mosquera",
      "Girón",
      "Buga"
    ],
    enableSurvey: false,
    surveyAlreadyMade: false,
    openSurveyMsg: false,
    lastSurvey: null,
    changeImageDialog: false,
    newImgUUID: "",
    selfieMode1: true,
    selfieMode2: false,
    loadingChangeProfileImgBtn: false,
    s3NewSelfieKey: "",
    selfieFileBase64: null,
    newSelfieImg: null,
    validFaceAnalysis: false,
    overlayLoading: false,
    overlayLoadingText: "",
    sendSurveyLoading: false,
    openChatModal: false,
  }),
  computed: {
    config() {
      return {
        bucketName: "vanguard-id",
        region: process.env.VUE_APP_S3_REGION,
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
        signatureVersion: 'v4',
      }
    },

    S3Client() {
      return new S3(this.config)
    }
  },
  methods: {
    /* limiter(e) {
      if(e.length > 2) {
        this.snackbar = true
        this.snackbarColor = "error"
        this.snackbarText = "Se deben seleccionar maximo 2 opciones."
        e.pop()
      }
    }, */

    setUrlRegisterImg(person_data) {
      if (person_data && person_data.register_image !== "") {
        return globals.APIURL + 'entry/registry-pic/' + person_data.register_image
      }
    },

    startProfileImgUpdate() {
      this.changeImageDialog = true;
      this.newImgUUID = uuidv4()
      if (this.newImgUUID == '' || this.newImgUUID == undefined || this.newImgUUID == null) {
        this.newImgUUID = uuidv4()
      }
    },

    validateChangeImg(data) {
      if (data && data.key) {
        console.log(data)
        this.s3NewSelfieKey = data.key;
        this.analyzeNewProfileImg()
      }
    },

    clickNewSelfieInput() {
      this.$refs.newSelfieInput.click();
    },

    readNewSelfieFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.newSelfieImg = fileReader.result;
        this.resizeAndCompressBase64Img(this.newSelfieImg)
      });
      fileReader.readAsDataURL(files[0]);
    },

    resizeAndCompressBase64Img(base64) {
        let resizingFactor = 0.5;
        const canvas1 = document.createElement("canvas");
        const context1 = canvas1.getContext("2d");
        let img1 = document.createElement("img");
        img1.src = base64;

        const originalWidth = img1.width;
        const originalHeight = img1.height;

        const canvasWidth = originalWidth * resizingFactor;
        const canvasHeight = originalHeight * resizingFactor;

        canvas1.width = canvasWidth;
        canvas1.height = canvasHeight;

        context1.drawImage(
            img1,
            0,
            0,
            originalWidth * resizingFactor,
            originalHeight * resizingFactor
        ); 

        canvas1.toBlob((blob1) => {
            if (blob1) {
              this.selfieFileBase64 = blob1;
              this.createNewImage()
            } else {
              this.resizeAndCompressBase64Img(base64)
            }
        },
        "image/jpeg",
        0.6)
    },

    createNewImage() {
      let name = "new_selfie_" + this.newImgUUID + ".jpg";

      this.S3Client.upload({
        Bucket: "vanguard-id",
        Key: name,
        Body: this.selfieFileBase64,
        ContentType: 'image/jpeg',
      },  (err, data) => {
        if (err) {
          console.log(err)
          this.loadingSelfieBtn = false
        }

        console.log(data)
        this.s3NewSelfieKey = data.key;
        this.analyzeNewProfileImg()
      });
    },

    analyzeNewProfileImg() {
      //this.overlayLoading = true
      this.loadingChangeProfileImgBtn = true
      this.overlayLoadingText = "Validando Imagen..."
      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/analyze-person",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          SelfieImage: this.s3NewSelfieKey,
          register_image: this.person_data.register_image == '.jpg' ? null : this.person_data.register_image,
        },
      }).then((res) => {
        if (res.data.type == "ok") {
          this.validFaceAnalysis = true;
          this.overlayLoading = false
          this.loadingChangeProfileImgBtn = false
          this.snackbar = true;
          this.snackbarColor = "success";
          this.snackbarText = res.data.message;
        } else {
          this.overlayLoading = false
          this.loadingChangeProfileImgBtn = false
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = res.data.message;
        }
      })
      .catch((err) => {
        console.log(err);
        this.overlayLoading = false
        this.loadingChangeProfileImgBtn = false
      });
    },

    changeProfileImg() {
      //this.overlayLoading = true;
      this.loadingChangeProfileImgBtn = true
      this.overlayLoadingText = "Actualizando Imagen..."
      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/update-profile-image",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          SelfieImage: this.s3NewSelfieKey,
          IDNewSelfieImage: this.newImgUUID,
          register_image: this.person_data.register_image == '.jpg' ? null : this.person_data.register_image,
          person_id: this.person_data.uuid,
          oldRekognition: this.person_data.RekognitionId,
          label: this.person_data.first_name + "_" + this.person_data.first_last_name + "-" + this.person_data.document_number
        },
      }).then((res) => {
        if (res.status == 200) {
          this.overlayLoading = false;
          this.loadingChangeProfileImgBtn = false
          this.overlayLoadingText = "";
          this.cleanChangeImgsModule()
          this.changeImageDialog = false;
          this.getPersonInfo(auth.get_schedule_person_id())
          this.snackbarText = "Se actualizó su imagen de acceso exitosamente.";
          this.snackbarColor = "success";
          this.snackbar = true;
        }
      })
      .catch((err) => {
        console.log(err);
        this.overlayLoading = false;
        this.loadingChangeProfileImgBtn = false
        this.overlayLoadingText = "";
      });
    },

    cleanChangeImgsModule() {
      this.newImgUUID = "";
      this.selfieMode1 = true;
      this.selfieMode2 = false;
      this.loadingChangeProfileImgBtn = false;
      this.s3NewSelfieKey = "";
      this.selfieFileBase64 = null;
      this.newSelfieImg = null;
      this.validFaceAnalysis = false;
    },

    updateData(email, phone) {
      axios({
        method: "POST",
        url: globals.APIURL + "employee/update-data",
        data: {
          person_id: auth.get_schedule_person_id(),
          person: {
            email: email,
            phone: phone
          }
        },
      })
      .then((res) => {
        if (res.status == 200 && res.data == "Employee updated successfuly") {
          this.editMode = false;
          this.snackbar = true
          this.snackbarText = "Información actualizada"
          this.snackbarColor = "success"
        }
        //console.log("RESPUESTA PERSONA: ", res)

      }).catch(err => {
        console.log(err)
      });
    },

    getPersonInfo(person_id) {
      if (person_id) {
        let zones;
        axios({
          method: "POST",
          url: globals.APIURL + "employee/fo",
          data: {
            person_id: person_id,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.overlayLoading = false;
            this.overlayLoadingText = ""
            this.personal_phone = res.data.phone;
            this.personal_email = res.data.email;
            this.person_data = res.data;
            this.companies = res.data.company_person.map(e => e.company); 
            this.companies.forEach(element => {
              if (element.name == "solla") {
                this.enableSurvey = true;
                localStorage.setItem("schedule-company-id", element.uuid);
                zones = res.data.company_person.map(e => {
                  if (element.uuid == e.company_id) {
                    return e.Campus_company_person
                  }
                })
              }
            });
            for (let i = 0; i < zones.length; i++) {
              const element = zones[i];
              
              if (element != undefined) {
                this.sedes = element.map(e => e.campus_id)
              }
            }
            this.getAvailableCompanies(this.companies)
            this.surveyAvailability(true)
          }
        });
      } else {
        this.overlayLoading = false;
        this.overlayLoadingText = ""
      }
    },

    getAvailableCompanies(companies) {
      let companiesFilter = []

      for (let i = 0; i < companies.length; i++) {
        const element = companies[i];
        
        companiesFilter.push({uuid: element.uuid})
      }

      axios({
        method: "POST",
        url: globals.APIURL + "employee/available-companies",
        data: {
          filter: companiesFilter,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.companiesToChose = res.data
        }
      });
    },

    shareData() {
      this.loadingShare = true
      let body = []

      for (let i = 0; i < this.companiesSelected.length; i++) {
        const element = this.companiesSelected[i];
        
        body.push({company_id: element, person_id: auth.get_schedule_person_id(), type_person: "EMPLEADO"})
      }

      axios({
        method: "POST",
        url: globals.APIURL + "employee/share-data",
        data: body,
      })
      .then((res) => {
        //console.log("RESPUESTA DE COMPARTIDA DE DATOS: ", res)
        if (res.status == 200 && res.data == 'Company_person created successfuly') {
          this.loadingShare = false
          this.snackbar = true;
          this.snackbarText = "Se compartierón los datos de forma exitosa.";
          this.snackbarColor = "success";
          this.dialogShareValidation = false;
          this.companiesSelected = null;
          this.getPersonInfo(auth.get_schedule_person_id());
        }
      }).catch((err) =>{
        console.log(err)
        this.loadingShare = false
      });
    },

    cleanSurveyData() {
      this.survey_obj.company = "";
      this.survey_obj.plate = "";
      this.survey_obj.sede = "";
      this.survey_obj.reason = "";
      this.survey_obj.rol = "";
      this.survey_obj.materialType = "";
      this.survey_obj.workType = "";
      this.survey_obj.symptoms = null;
      this.survey_obj.vaccinated = null;
      this.survey_obj.vehicleAndDriverDocumentation = "";
      this.survey_obj.vehicleInspectionToOperate = "";
      this.survey_obj.mood = null;
      this.survey_obj.mood_reason = null;
    },

    openSurvey(type) {
      if (this.surveyAlreadyMade == false) {
        if (type == "survey_1") {
          this.survey1 = true;
          this.survey2 = false;
          this.cleanSurveyData();
        } else {
          this.survey1 = false; 
          this.survey2 = true; 
          this.cleanSurveyData();
        }
      }
    },

    sendSurvey(survey, surveyType) {
      let canDoSurvey = false;
      this.sendSurveyLoading = true
      if (surveyType == "survey_2") {
        if (survey.rol && survey.sede && survey.workType && (survey.symptoms == true || survey.symptoms == false) && survey.mood && survey.mood.length > 0 && survey.mood_reason && survey.mood_reason.length > 0) {
          canDoSurvey = true;
        } else {
          this.sendSurveyLoading = false
          this.snackbar = true
          this.snackbarColor = "error"
          this.snackbarText = "Es necesario gestionar todos los campos."
        }
      } else if (surveyType == "survey_1") {
        if (survey.company && survey.sede && survey.reason && (survey.symptoms == true || survey.symptoms == false) && survey.vehicleAndDriverDocumentation && survey.vehicleInspectionToOperate) {
          if (survey.reason === 'Cargar/Descargar' && !survey.materialType) {
            this.sendSurveyLoading = false
            this.snackbar = true
            this.snackbarColor = "error"
            this.snackbarText = "Es necesario gestionar todos los campos."
          } else {
            canDoSurvey = true
          }
        } else {
          this.sendSurveyLoading = false
          this.snackbar = true
          this.snackbarColor = "error"
          this.snackbarText = "Es necesario gestionar todos los campos."
        }
      }
      
      if (canDoSurvey == true) {
        //if (surveyType == 'survey_1') console.log("ENCUESTA TERCERO:", survey); else console.log("ENCUESTA COLABORADOR:", survey);
        let completeSurvey = {
          uuid: uuidv4(),
          person_id: auth.get_schedule_person_id(),
          name: this.person_data.first_name + " " + (this.person_data.second_name ? this.person_data.second_name : "") + " " + this.person_data.first_last_name + " " + (this.person_data.second_last_name ? this.person_data.second_last_name : ""),
          document_number: this.person_data.document_number,
          cellphone: this.personal_phone ? this.personal_phone : '',
          email: this.personal_email ? this.personal_email : '',
          company_id: auth.get_schedule_company_id(),
          //campus_id: auth.get_schedule_campus_id(),
          created_date: globals.COdate(),
          company_you_work_for: survey.company ? survey.company : '',
          vehicle_plate: survey.plate ? survey.plate : '',
          place_access: survey.sede ? survey.sede : '',
          role: survey.rol ? survey.rol : '',
          reason: survey.reason ? survey.reason : '',
          material_type: survey.reason == 'Cargar/Descargar' ? survey.materialType : '',
          work_type: survey.workType ? survey.workType : '',
          symptoms: survey.symptoms,
          vaccinated: survey.vaccinated,
          vehicle_and_driver_papers: survey.plate ? survey.vehicleAndDriverDocumentation : '',
          vehicle_inspection: survey.plate ? survey.vehicleInspectionToOperate : '',
          mood: survey.mood,
          mood_reason: survey.mood_reason
        }

        if (surveyType == 'survey_1') {
          completeSurvey.survey_type = "tercero"
        } else if (surveyType == 'survey_2') {
          completeSurvey.survey_type = "colaborador"
        }

        axios({
          method: "POST",
          url: globals.APIURL + "forms/create-survey",
          data: {
            survey: completeSurvey,
            sedes: this.sedes
          },
        })
        .then((res) => {
          if (res.status == 200 && res.data == "Survey created successfuly") {
            console.log("Survey Response: ", res.data)
            this.survey1 = false
            this.survey2 = false
            this.cleanSurveyData()
            this.surveyAvailability()
            this.snackbar = true;
            this.snackbarText = "Su encuesta se registró exitosamente."
            this.snackbarColor = "success"
            this.sendSurveyLoading = false
          }
          this.sendSurveyLoading = false
          //console.log("RESPUESTA PERSONA: ", res)

        }).catch(err => {
          this.sendSurveyLoading = false
          console.log(err)
        });
      }
    },

    surveyAvailability(val) {
      axios({
        method: "POST",
        url: globals.APIURL + "forms/find-last-surveys",
        data: {
          company_id: auth.get_schedule_company_id(),
          document_number: this.person_data.document_number,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.length > 0) {
            if (globals.validateSurveyStatus(res.data[res.data.length - 1].created_date) == true) {
              this.lastSurvey = res.data[0]
              this.surveyAlreadyMade = true;
              if (val) {
                this.openSurveyMsg = true;
              }
            } else {
              this.surveyAlreadyMade = false;
            }
          }
        }
      }).catch(err => {
        console.log(err)
      });
    },

  },
  mounted() {
    this.overlayLoading = true
    this.overlayLoadingText = "Cargando Información..."
    this.getPersonInfo(auth.get_schedule_person_id())
    this.$bus.$on("closeChatSignal", (closeChatSignal) => {
      this.openChatModal = closeChatSignal
    });
  },
};
</script>
<style scoped>
label.line {
  display: inline-block;
}

@media (max-width: 767px) {
  .profile-card {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .profile-card {
    width: 50%;
  }
}

.profile-card-title-layout {
  background: rgb(136,155,179);
  background: linear-gradient(148deg, rgba(136,155,179,0.9024859943977591) 0%, rgba(60,70,93,0.9248949579831933) 38%, rgba(33,39,52,0.891281512605042) 63%);
}

.profile-card-title-grid {
  display: grid;
  grid-template-columns: 40% auto;
}

@media (max-width: 767px) {
  .profile-card-title-grid {
    display: flex;
    grid-template-columns: 100%;
    justify-content: center; /* Add this line to center the content horizontally */
    align-items: center; /* Add this line to center the content vertically */
    text-align: center;
  }
}
</style>
