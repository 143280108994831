<template>
    <v-card>
        <!-- <v-card-title style="background-color: #212734;" class="d-flex justify-center">
            Mapa
        </v-card-title> -->
        <v-card-text class="map-container">
            <v-overlay opacity="1" absolute :value="loadingMap">
                <v-card color="primary" dark>
                    <v-card-text>
                        Cargando mapa...
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-overlay>
            <GmapMap :center="center" :zoom="mapZoom" :options="mapOptions" map-type-id="hybrid"
                style="width: 100%; height: 800px;">
                <GmapMarker v-for="(m, index) in markers" :key="index" :position="m.polygon_location[0]"
                    :icon="'https://mt.google.com/vt/icon/color=ff330000&name=icons/spotlight/spotlight-waypoint-b.png&ax=44&ay=48&scale=1.2'"
                    :clickable="true" @click="clickPlace(index)" :title="m.name">
                    <GmapInfoWindow :options="infoWindowOptions" :opened="m.isOpened"
                        @closeclick="m.isOpened = !m.isOpened; m.showChildren = false; mapZoom = 7">
                        <div>
                            <p class="black--text">
                                <v-icon class="text-right" @click="m.showChildren = !m.showChildren"
                                    color="black">{{ m.showChildren === false ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                                {{ m.showChildren === false ? 'Mostrar' : 'Ocultar' }}
                            </p>
                            <h3 class="black--text">Sede: </h3>
                            <p class="black--text pl-2">{{ m.name }}</p>
                            <h4 class="black--text pt-2">Dispositivos: </h4>
                            <ul class="pl-0" style="list-style-type:none;">
                                <li v-for="(device, deviceIndex) in m.devices" :key="deviceIndex"
                                    class="d-flex align-center pb-1" style="border-bottom: 1px solid #D3D3D3;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="pr-2" v-bind="attrs" v-on="on" :color="validateDvcConnection(
                                                device.last_connection.slice(0, 10) +
                                                ' ' +
                                                device.last_connection.slice(
                                                    11,
                                                    device.last_connection.length - 8
                                                )
                                            ) === true
                                                    ? 'green darken-2'
                                                    : 'error darken-2'
                                                ">mdi-access-point</v-icon>
                                        </template>
                                        <span>Ultima Conexíon: {{ device.last_connection ?
                                            device.last_connection.slice(0, 10) + " " +
                                            device.last_connection.slice(11, device.last_connection.length - 5) :
                                            "---" }}</span>
                                    </v-tooltip>
                                    <p style="color:black">{{ device.name }}</p>
                                </li>
                            </ul>
                        </div>
                    </GmapInfoWindow>
                    <GmapPolygon :paths="m.polygon_location" :options="polygonOptions" :clickable="true" />
                    <div v-if="m.showChildren" class="ma-0 pa-0">
                        <GmapMarker v-for="(child, childIndex) in m.devices" :key="childIndex"
                            :position="child.description" :icon="iconCam" :clickable="true"
                            @click="openChild(m.devices, childIndex)">
                            <GmapInfoWindow :options="infoWindowOptions" :opened="child.isChildOpen"
                                @closeclick="child.isChildOpen = !child.isChildOpen">
                                <p class="black--text text-center pl-2">{{ child.name }}</p>
                                <div v-if="userRole === 'SUPER'">
                                    <div class="text-center pt-2"
                                        v-if="child.lastHB && child.lastHB.length > 0 && validateDvcConnection(child.last_connection.slice(0, 10) + ' ' + child.last_connection.slice(11, child.last_connection.length - 8))">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="text-center pr-1"
                                                    :color="child.lastHB[0].camera_status == true ? 'green' : ''"
                                                    size="20" v-bind="attrs" v-on="on">
                                                    {{ child.lastHB[0].camera_status == true ? 'mdi-camera' :
                                                    'mdi-camera-off'}}
                                                </v-icon>
                                            </template>
                                            <span>{{ child.lastHB[0].camera_status == true ? 'Cámara con conexión' :
                                                'Cámara sin conexión' }}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="text-center pl-1"
                                                    :color="heatDeviceResult(child.lastHB[0], 'color', child)"
                                                    size="20" v-bind="attrs" v-on="on">
                                                    {{ heatDeviceResult(child.lastHB[0], 'icon', child) }}
                                                </v-icon>
                                            </template>
                                            <span v-if="child.lastHB[0].temp_status <= 58.9">Temperatura estable: {{
                                                child.lastHB[0].temp_status }}</span>
                                            <span
                                                v-if="child.lastHB[0].temp_status >= 59 && child.lastHB[0].temp_status <= 68.9">Temperatura
                                                en aumento: {{ child.lastHB[0].temp_status }}</span>
                                            <span v-if="child.lastHB[0].temp_status >= 69">Temperatura muy alta: {{
                                                child.lastHB[0].temp_status }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div class="text-center pt-2" v-else>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="text-center pr-1" color="grey darken-2" size="20"
                                                    v-bind="attrs" v-on="on">
                                                    mdi-camera-off
                                                </v-icon>
                                            </template>
                                            <span>Sin datos para validar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="text-center pr-1" color="grey darken-2" size="20"
                                                    v-bind="attrs" v-on="on" @click="openMonitoring(child)">
                                                    mdi-thermometer
                                                </v-icon>
                                            </template>
                                            <span>Sin datos para validar</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </GmapInfoWindow>
                        </GmapMarker>
                    </div>
                </GmapMarker>
            </GmapMap>

            <v-card v-if="!loadingMap" class="menu-card" style="z-index: 1 !important;">
                <v-list dense>
                    <v-list-item-group>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <strong class="grey--text">
                                        Ubicaciones:
                                    </strong>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="(marker, index) in markers" :key="index">
                            <v-list-item-content @click="clickPlace(index)">
                                <v-list-item-title>
                                <span class="text-right" style="display: inline-block; width: 35px !important;">
                                    <span class="success--text text-subtitle-2">{{ marker.devices.length - marker.offlineDevices }} </span>-
                                    <span class="error--text text-subtitle-2">{{ marker.offlineDevices }}</span> 
                                </span>
                                <span class="pl-3">
                                    {{ marker.name }}
                                </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>

        </v-card-text>
    </v-card>
</template>
<script>
import globals from "../../globals";
import axios from "axios";
import auth from "../../services/auth";
import markerCameraIcon from '@/assets/camera-16.png'

export default {
  name: "DevicesMap",
  props: {
    company_id: String
  },
  data: () => ({
    markers: [],
    center: { lat: 5.5510034559503705, lng: -74.1349526713027 },
    mapZoom: 7, 
    infoWindowOptions: {
      maxWidth: 200,
    },
    infoWindowOptions2: {
      maxWidth: 800,
    },
    mapOptions: {
      mapTypeControl: false,
      fullscreenControl: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }],
        },
      ],
    },
    polygonOptions: {
      fillColor: 'blue',
      fillOpacity: 0.35,
      strokeColor: 'darkblue',
      strokeOpacity: 0.8,
      strokeWeight: 2,
    },
    iconCam: markerCameraIcon,
    loadingMap: false,
    userRole: auth.getRole(),
  }),
  computed: {
  },
  methods: {
    openChild(devices, index) {
      devices.map((device) => {
        device.isChildOpen = false
      })
      devices[index].isChildOpen = !devices[index].isChildOpen
    },

    clickPlace(index) {
      this.center = this.markers[index].polygon_location[0] 
      this.markers[index].isOpened = !this.markers[index].isOpened
      this.markers[index].showChildren = !this.markers[index].showChildren
      this.mapZoom = 19
    },

    validateDvcConnection(lastConnectionTime) {
      const todaysTime = new Date(
        globals.COdate().slice(0, 10) +
          " " +
          globals.COdate().slice(11, globals.COdate().length - 8)
      );
      lastConnectionTime = new Date(lastConnectionTime);
      let difference = Math.round(
        (todaysTime.getTime() - lastConnectionTime.getTime()) / 60000
      ); // Diferencia entre fechas en minutos

      if (difference > 3) {
        return false;
      } else {
        return true;
      }
    },

    heatDeviceResult(lastHB, type, item) {
      lastHB.temp_status = Math.round(lastHB.temp_status)
      if (type == 'color' && this.validateDvcConnection(item.last_connection.slice(0, 10) + ' ' + item.last_connection.slice(11, item.last_connection.length - 8))) {
        let color
        if (lastHB.temp_status <= 58) {
          color = "green"
        } else if (lastHB.temp_status > 58 && lastHB.temp_status <= 68) {
          color = "orange"
        } else {
          color = "red"
        }
        return color
      } 
      
      if (type == 'icon') {
        let icon
        if (lastHB.temp_status <= 58) {
          icon = 'mdi-thermometer-check'
        } else if (lastHB.temp_status > 58 && lastHB.temp_status <= 68) {
          icon = 'mdi-thermometer-chevron-up'
        } else {
          icon = 'mdi-thermometer-alert'
        }
        return icon
      }
    },

    getLocations() {
      axios
        .post(globals.APIURL + "device/get-devices-location", {
            companyID: this.company_id
        })
        .then((res) => { 
          if (res.data && res.data.length > 0) {
            res.data.map((element) => {
              element.offlineDevices = 0;
              element.devices.map((child) => {
                if (this.validateDvcConnection(child.last_connection.slice(0, 10) + ' ' + child.last_connection.slice(11, child.last_connection.length - 8)) === false) {
                  element.offlineDevices += 1;
                }

              });
            });
            console.log("STOP LOADING!!!")
            this.loadingMap = false;
            this.markers = res.data;
          }
          this.loadingMap = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingMap = false;
        });
    },
  },
  beforeMount() {
    console.log("START LOADING!!!")
    this.loadingMap = true;
    this.getLocations();
  },
  created() {
  },
  beforeDestroy() {
  }
};
</script>
<style scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
