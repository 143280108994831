var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',{staticClass:"map-container"},[_c('v-overlay',{attrs:{"opacity":"1","absolute":"","value":_vm.loadingMap}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Cargando mapa... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('GmapMap',{staticStyle:{"width":"100%","height":"800px"},attrs:{"center":_vm.center,"zoom":_vm.mapZoom,"options":_vm.mapOptions,"map-type-id":"hybrid"}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.polygon_location[0],"icon":'https://mt.google.com/vt/icon/color=ff330000&name=icons/spotlight/spotlight-waypoint-b.png&ax=44&ay=48&scale=1.2',"clickable":true,"title":m.name},on:{"click":function($event){return _vm.clickPlace(index)}}},[_c('GmapInfoWindow',{attrs:{"options":_vm.infoWindowOptions,"opened":m.isOpened},on:{"closeclick":function($event){m.isOpened = !m.isOpened; m.showChildren = false; _vm.mapZoom = 7}}},[_c('div',[_c('p',{staticClass:"black--text"},[_c('v-icon',{staticClass:"text-right",attrs:{"color":"black"},on:{"click":function($event){m.showChildren = !m.showChildren}}},[_vm._v(_vm._s(m.showChildren === false ? 'mdi-eye' : 'mdi-eye-off'))]),_vm._v(" "+_vm._s(m.showChildren === false ? 'Mostrar' : 'Ocultar')+" ")],1),_c('h3',{staticClass:"black--text"},[_vm._v("Sede: ")]),_c('p',{staticClass:"black--text pl-2"},[_vm._v(_vm._s(m.name))]),_c('h4',{staticClass:"black--text pt-2"},[_vm._v("Dispositivos: ")]),_c('ul',{staticClass:"pl-0",staticStyle:{"list-style-type":"none"}},_vm._l((m.devices),function(device,deviceIndex){return _c('li',{key:deviceIndex,staticClass:"d-flex align-center pb-1",staticStyle:{"border-bottom":"1px solid #D3D3D3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-2",attrs:{"color":_vm.validateDvcConnection(
                                            device.last_connection.slice(0, 10) +
                                            ' ' +
                                            device.last_connection.slice(
                                                11,
                                                device.last_connection.length - 8
                                            )
                                        ) === true
                                                ? 'green darken-2'
                                                : 'error darken-2'}},'v-icon',attrs,false),on),[_vm._v("mdi-access-point")])]}}],null,true)},[_c('span',[_vm._v("Ultima Conexíon: "+_vm._s(device.last_connection ? device.last_connection.slice(0, 10) + " " + device.last_connection.slice(11, device.last_connection.length - 5) : "---"))])]),_c('p',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(device.name))])],1)}),0)])]),_c('GmapPolygon',{attrs:{"paths":m.polygon_location,"options":_vm.polygonOptions,"clickable":true}}),(m.showChildren)?_c('div',{staticClass:"ma-0 pa-0"},_vm._l((m.devices),function(child,childIndex){return _c('GmapMarker',{key:childIndex,attrs:{"position":child.description,"icon":_vm.iconCam,"clickable":true},on:{"click":function($event){return _vm.openChild(m.devices, childIndex)}}},[_c('GmapInfoWindow',{attrs:{"options":_vm.infoWindowOptions,"opened":child.isChildOpen},on:{"closeclick":function($event){child.isChildOpen = !child.isChildOpen}}},[_c('p',{staticClass:"black--text text-center pl-2"},[_vm._v(_vm._s(child.name))]),(_vm.userRole === 'SUPER')?_c('div',[(child.lastHB && child.lastHB.length > 0 && _vm.validateDvcConnection(child.last_connection.slice(0, 10) + ' ' + child.last_connection.slice(11, child.last_connection.length - 8)))?_c('div',{staticClass:"text-center pt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"text-center pr-1",attrs:{"color":child.lastHB[0].camera_status == true ? 'green' : '',"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(child.lastHB[0].camera_status == true ? 'mdi-camera' : 'mdi-camera-off')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(child.lastHB[0].camera_status == true ? 'Cámara con conexión' : 'Cámara sin conexión'))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"text-center pl-1",attrs:{"color":_vm.heatDeviceResult(child.lastHB[0], 'color', child),"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.heatDeviceResult(child.lastHB[0], 'icon', child))+" ")])]}}],null,true)},[(child.lastHB[0].temp_status <= 58.9)?_c('span',[_vm._v("Temperatura estable: "+_vm._s(child.lastHB[0].temp_status))]):_vm._e(),(child.lastHB[0].temp_status >= 59 && child.lastHB[0].temp_status <= 68.9)?_c('span',[_vm._v("Temperatura en aumento: "+_vm._s(child.lastHB[0].temp_status))]):_vm._e(),(child.lastHB[0].temp_status >= 69)?_c('span',[_vm._v("Temperatura muy alta: "+_vm._s(child.lastHB[0].temp_status))]):_vm._e()])],1):_c('div',{staticClass:"text-center pt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"text-center pr-1",attrs:{"color":"grey darken-2","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-camera-off ")])]}}],null,true)},[_c('span',[_vm._v("Sin datos para validar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"text-center pr-1",attrs:{"color":"grey darken-2","size":"20"},on:{"click":function($event){return _vm.openMonitoring(child)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-thermometer ")])]}}],null,true)},[_c('span',[_vm._v("Sin datos para validar")])])],1)]):_vm._e()])],1)}),1):_vm._e()],1)}),1),(!_vm.loadingMap)?_c('v-card',{staticClass:"menu-card",staticStyle:{"z-index":"1 !important"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',{staticClass:"grey--text"},[_vm._v(" Ubicaciones: ")])])],1)],1),_vm._l((_vm.markers),function(marker,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',{on:{"click":function($event){return _vm.clickPlace(index)}}},[_c('v-list-item-title',[_c('span',{staticClass:"text-right",staticStyle:{"display":"inline-block","width":"35px !important"}},[_c('span',{staticClass:"success--text text-subtitle-2"},[_vm._v(_vm._s(marker.devices.length - marker.offlineDevices)+" ")]),_vm._v("- "),_c('span',{staticClass:"error--text text-subtitle-2"},[_vm._v(_vm._s(marker.offlineDevices))])]),_c('span',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(marker.name)+" ")])])],1)],1)})],2)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }