<template>
  <v-container fluid class="ma-0 pa-0" style="height: 100%">

    <div v-if="overlay">
      <v-overlay opacity="1" :value="overlay">
        <v-card color="primary" dark>
          <v-card-text>
            Autenticando, por favor espere...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-overlay>
    </div>

    <div class="text-center" v-if="faceRekModal == true">
      <v-dialog v-model="faceRekModal" width="300px">
        <v-card rounded>
          <v-card-title class="d-flex justify-center">
            <p class="h6-text">Autenticación Facial</p>
          </v-card-title>
          <v-card-text>
            <take-photo v-if="!isIphone" :typeUse="'sign-in'" v-on:photo-taken-auth="faceRekognition"></take-photo>
            <v-row v-if="isIphone">
              <v-col cols="12" class="d-flex justify-center">
                <v-icon
                  color="secondary lighten-1"
                  dark
                  style="font-size: 60px"
                >
                  fal fa-id-badge
                </v-icon>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  color="secondary lighten-1"
                  class="white--text"
                  small
                  outlined
                  @click="clickAuthInput"
                  >
                  Comenzar Autenticación
                </v-btn>
                <input
                  type="file"
                  @change="readAuthFile"
                  style="display: none"
                  ref="authInput"
                  accept="image/*"
                />
              </v-col><!-- 
              <v-col cols="12" class="d-flex justify-center my-0 py-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="grey darken-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 1.5rem"
                    >
                      fal fa-question-circle
                    </v-icon>
                  </template>
                  <span
                    >Si estás intentando ingresar desde una computadora solo podrás subir una imagen desde los archivos, 
                    si lo estás haciendo desde un celular podrás subir una foto instantánea o de la galería.</span
                  >
                </v-tooltip>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="ma-0 pa-0 test-1" style="height: 100%">
      <div class="d-flex justify-center align-center" style="height: 100%">
        <v-card width="40%" height="600px" class="d-flex align-center">
          <v-row class="mx-10">
            <v-col cols="12" class="text-left">
              <label class="text-h5 font-weight-bold" style="max-width: 300px; color: #cccccc">Bienvenido a OneID</label>
              <label class="text-h5 font-weight-bold" style="max-width: 300px; color: #cccccc" >Aquí podrás gestionar tus visitas y administrar tu información.</label>
            </v-col>
            <v-col cols="12" class="mt-5 mb-0 pb-0">
              <label>Correo Electrónico</label>
              <v-text-field
                v-model="email"
                label="Email"
                :rules="emailRules"
                outlined
                dense
                dark
                color="secondary"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="allowSignin" cols="12" class="mt-0 pt-0">
              <label>Codigo de Verificación</label>
              <v-text-field
                v-model="otpCode"
                :append-icon="showPasswordIcon ? 'fal fa-eye' : 'fal fa-eye-slash'"
                :type="showPasswordIcon ? 'text' : 'password'"
                name="input-10-2"
                label="Contraseña"
                outlined
                dense
                dark
                required
                color="secondary"
                class="input-group--focused"
                @click:append="showPasswordIcon = !showPasswordIcon"
              ></v-text-field>
            </v-col>
            <v-col v-if="allowSignin" cols="12" class="pt-0 mt-0">
              <v-btn
                rounded
                block
                small
                color="primary"
                :disabled="!otpCode"
                @click="signIn(email, otpCode)"
                :loading="loadAuthBtn"
              >
                Iniciar Sesión
              </v-btn>
            </v-col>
            <v-col cols="12" class="pt-0 mt-0">
              <v-btn
                rounded
                block
                small
                color="secondary"
                outlined
                @click="sendVerificationCode(email)"
                :disabled="allowSignin || !email"
                :loading="loadVerificationEmailBtn"
              >
                {{ !allowSignin ? 'Enviar código de acceso' : '(' + countDown + ')' }}
              </v-btn>
            </v-col>

            <v-col cols="12" class="py-0 my-0 text-center">
              <p>O</p>
            </v-col>
            <v-col cols="12">
              <v-btn
                rounded
                block
                small
                color="secondary"
                outlined
                class="primaryBtn"
                @click="faceRekModal = true"
              >
                Acceder con reconocimiento facial
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-5">
              <v-img max-width="120px" src="../../assets/logo-white.png"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>

    <div class="test-2 ma-0 pa-0" style="height: 100%">
      <v-row class="mt-5 mx-5">
        <v-col cols="12" class="text-left">
          <label class="text-h5 font-weight-bold" style="max-width: 300px; color: #cccccc">Bienvenido a OneID</label>
          <label class="text-h5 font-weight-bold" style="max-width: 300px; color: #cccccc" >Aquí podrás gestionar tus visitas y administrar tu información.</label>
        </v-col>
        <v-col cols="12" class="mt-5 mb-0 pb-0">
          <label>Correo Electrónico</label>
          <v-text-field
            v-model="email"
            label="Email"
            :rules="emailRules"
            outlined
            dark
            color="secondary"
            required
          ></v-text-field>
        </v-col>
        <v-col v-if="allowSignin" cols="12" class="mt-0 pt-0">
          <label>Codigo de Verificación</label>
          <v-text-field
            v-model="otpCode"
            :append-icon="showPasswordIcon ? 'fal fa-eye' : 'fal fa-eye-slash'"
            :type="showPasswordIcon ? 'text' : 'password'"
            name="input-10-2"
            label="Contraseña"
            outlined
            dark
            required
            color="secondary"
            class="input-group--focused"
            @click:append="showPasswordIcon = !showPasswordIcon"
          ></v-text-field>
        </v-col>
        <v-col v-if="allowSignin" cols="12" class="pt-0 mt-0">
          <v-btn
            rounded
            block
            color="primary"
            :disabled="!otpCode"
            @click="signIn(email, otpCode)"
            :loading="loadAuthBtn"
          >
            Iniciar Sesión
          </v-btn>
        </v-col>
        <v-col cols="12" class="pt-0 mt-0">
          <v-btn
            rounded
            block
            color="secondary"
            outlined
            @click="sendVerificationCode(email)"
            :disabled="allowSignin || !email"
            :loading="loadVerificationEmailBtn"
          >
            {{ !allowSignin ? 'Enviar código de acceso' : '(' + countDown + ')' }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="py-0 my-0 text-center">
          <p>O</p>
        </v-col>
        <v-col cols="12">
          <v-btn
            rounded
            block
            small
            color="secondary"
            outlined
            class="primaryBtn"
            @click="faceRekModal = true"
          >
            Acceder con reconocimiento facial
          </v-btn>
        </v-col>

        <v-col cols="12" class="d-flex justify-center mt-10">
          <v-img max-width="150px" src="../../assets/logo-white.png"></v-img>
        </v-col>
      </v-row>
    </div>

    <v-snackbar v-model="snackbar" timeout="10000" :color="snackbarColor" top>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {S3} from "aws-sdk";
import globals from "../../globals";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import auth from "../../services/auth";
//import backlog from "../services/logs";
import TakePhoto from "../utils/take-photo.vue"

export default {
  name: "SignIn",
  components: {
    TakePhoto
  },
  data: () => ({
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    email: "",
    allowSignin: false,
    showPasswordIcon: false,
    otpCode: null,
    loadVerificationEmailBtn: false,
    loadAuthBtn: false,
    person: null,
    emailRules: [
      (v) => !!v || "El campo es obligatorio",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "El correo electronico no esta formado correctamente",
    ],
    countDown: 120,
    faceRekModal: false,
    EMAIL_PATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    authImg: null,
    authImgFile: null,
    loadingAuthBtn: false,
    overlay: false,
    isIphone: false
  }),
  computed: {
    config() {
      return {
        bucketName: "vanguard-id",
        region: process.env.VUE_APP_S3_REGION,
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
        signatureVersion: 'v4',
      }
    },

    S3Client() {
      return new S3(this.config)
    }
  },
  methods: {
    countDownTimer () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else if (this.countDown == 0) {
        this.allowSignin = false;
        this.countDown = 120;
      }
    },

    signIn(email, otp) {
      this.loadAuthBtn = false;
      axios({
        method: "POST",
        url: globals.APIURL + "registry/validate-otp",
        data: {
          "email": email.toLowerCase(),
          "otp": otp
        },
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.person_info.company_person.length == 1) {
            localStorage.setItem("schedule-person-id", res.data.person_info.uuid);
            /* localStorage.setItem("schedule-company-person-id", res.data.person_info.company_person[0].uuid);
            localStorage.setItem("schedule-company-id", res.data.person_info.company_person[0].company_id);
            localStorage.setItem("schedule-campus-id", res.data.person_info.company_person[0].Campus_company_person[0].campus.uuid);
            localStorage.setItem("schedule-suborg-id", res.data.person_info.company_person[0].suborgs_person[0].sub_org.uuid); */
            this.allowSignin = false;
            this.loadAuthBtn = false;
            this.$router.replace({ name: 'schedule', params: { session_data: res.data } });
          } else if (res.data.person_info.company_person.length > 1) {
            this.person = res.data.person_info;
            localStorage.setItem("schedule-person-id", this.person.uuid);
            this.allowSignin = false;
            this.loadAuthBtn = false;
            this.faceRekModal = false;
            this.snackbar = true;
            this.snackbarText = "Bienvenido(a) a OneID.";
            this.snackbarColor = "success";
            this.$router.replace({ name: 'schedule', params: { session_data: this.person } });
          }
        }
        this.loadAuthBtn = false;
      }).catch(err => {
        if (err.response.data == "Invalid authentication, otp not matched.") {
          this.snackbar = true;
          this.snackbarColor = "error"
          this.snackbarText = "Por favor verifique que el código que esta ingresando sea el mismo que se envió a su correo electronico."
          this.loadAuthBtn = false;
        }
      });
    },

    sendVerificationCode(email) {
      if (this.EMAIL_PATTERN.test(email) == false) {
        this.snackbar = true
        this.snackbarColor = "error"
        this.snackbarText = "El correo electronico debe estar bien formado."
        this.loadVerificationEmailBtn = false;
      } else {
        this.loadVerificationEmailBtn = true;
        axios
          .post(globals.APIURL + "registry/check-auth", {
            email: email.toLowerCase()
          })
          .then(res =>{ 
            if (res.status == 200) {
              this.loadVerificationEmailBtn = false;
              this.countDownTimer();
              this.allowSignin = true;
              this.snackbar = true;
              this.snackbarColor = "success"
              this.snackbarText = "Se envió el código de autenticación a su correo electronico."
            }
            this.loadVerificationEmailBtn = false;
          })
          .catch(err => {
            if (err.response.data == "No person found") {
              this.snackbar = true;
              this.snackbarColor = "error"
              this.snackbarText = "El correo electronico con el que intenta ingresar no existe."
              this.loadVerificationEmailBtn = false;
            }
          })
      }
    },

    faceRekognition(res) {
      if (res.status == 200) {
        if (res.data.company_person.length == 1) {
          localStorage.setItem("schedule-person-id", res.data.uuid);
          /* localStorage.setItem("schedule-company-person-id", res.data.company_person[0].uuid);
          localStorage.setItem("schedule-company-id", res.data.company_person[0].company_id);
          localStorage.setItem("schedule-campus-id", res.data.company_person[0].Campus_company_person[0].campus.uuid);
          localStorage.setItem("schedule-suborg-id", res.data.company_person[0].suborgs_person[0].sub_org.uuid); */
          this.allowSignin = false;
          this.loadAuthBtn = false;
          this.snackbar = true;
          this.snackbarText = "Bienvenido(a) a OneID.";
          this.snackbarColor = "success";
          this.$router.replace({ name: 'schedule', params: { session_data: res.data } });
        } else if (res.data.company_person.length > 1) {
          this.person = res.data;
          localStorage.setItem("schedule-person-id", this.person.uuid);
          this.allowSignin = false;
          this.loadAuthBtn = false;
          this.faceRekModal = false;
          this.snackbar = true;
          this.snackbarText = "Bienvenido(a) a OneID.";
          this.snackbarColor = "success";
          this.$router.replace({ name: 'schedule', params: { session_data: this.person } });
        }
      }
    },

    // * Auth methods
    clickAuthInput() {
      this.$refs.authInput.click();
    },

    readAuthFile(e) {
      this.faceRekModal = false
      this.overlay = true;
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.authImg = fileReader.result;
        this.resizeAndCompressBase64Auth(this.authImg)
      });
      fileReader.readAsDataURL(files[0]);
    },

    resizeAndCompressBase64Auth(base64) {
        
        let resizingFactor = 0.5;
        const canvas2 = document.createElement("canvas");
        const context2 = canvas2.getContext("2d");
        let img2 = document.createElement("img");
        img2.src = base64;

        const originalWidth = img2.width;
        const originalHeight = img2.height;

        const canvasWidth = originalWidth * resizingFactor;
        const canvasHeight = originalHeight * resizingFactor;

        canvas2.width = canvasWidth;
        canvas2.height = canvasHeight;

        context2.drawImage(
            img2,
            0,
            0,
            originalWidth * resizingFactor,
            originalHeight * resizingFactor
        );

        canvas2.toBlob((blob2) => {
            if (blob2) {
                this.authImgFile = blob2;
                this.validateAuthImg();
            } else {
                this.resizeAndCompressBase64Auth(base64)
            }
        },
        "image/jpeg",
        0.8)
    },

    validateAuthImg() {
      this.S3Client.upload({
        Bucket: "vanguard-id",
        Key: "login" + uuidv4() + ".jpg",
        Body: this.authImgFile,
        ContentType: 'image/jpeg',
      },  (err, data) => {
        if (err) {
          console.log(err);
          this.overlay = false;
        }

        axios({
          method: 'POST',
          baseURL: globals.APIURL + "identity/face-rekognition-auth",
          headers: {
              "Access-Control-Allow-Origin": "*"
          },
          data: {
            login_img_key: data.key,
          },
        }).then((res) => {
          this.overlay = false;
          this.faceRekognition(res)
        })
        .catch((err) => {
          this.overlay = false;
          console.log(err.response.data);
        });
      });
    },
  },
  mounted() {
    auth.logout();
    this.isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  },
};
</script>
<style scoped>
.test-1 {
  display: contents !important;
}
.test-2 {
  display: none !important;
}
/* media query 1000px */
@media (max-width: 1250px) {
  .test-1 {
    display: none !important;
  }
  .test-2 {
    display: contents !important;
  }
}
</style>
