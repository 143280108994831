<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <p class="white--text text-h5">Backlog</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-select
          v-model="org"
          :items="organizations"
          item-text="name"
          label="Organización"
          outlined
          dense
          dark
          color="secondary"
          return-object
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" class="pb-0 mb-0">
        <v-menu
          v-model="datepicker1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Fecha Inicial"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              dense
              dark
              color="secondary"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            @input="datepicker1 = false"
            locale="es-MX"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4" class="pb-0 mb-0">
        <v-menu
          v-model="datepicker2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="finishDate"
              label="Fecha Final"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              dense
              dark
              color="secondary"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="finishDate"
            @input="datepicker2 = false"
            locale="es-MX"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-btn
          color="primary"
          block
          small
          :disabled="org != null ? false : true"
          @click="
            getLogs(org, startDate, finishDate);
          "
        >
          <v-icon dark> mdi-magnify </v-icon> FILTRAR
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-btn
          color="success"
          block
          small
          :disabled="logsList && logsList.length > 0 ? false : true"
          @click="generateReport(logsList)"
        >
          <v-icon dark> mdi-microsoft-excel </v-icon> EXPORTAR LOGS
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" class="ctTablaCam" style="border-radius: 10px;">
          <v-row v-if="logsList && logsList.length > 0">
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar por palabra clave..."
                single-line
                hide-details
                outlined
                color="secondary"
                dark
                dense
                class="mb-5"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12" v-if="!logsList"><p>Filtre sus datos.</p></v-col>
            <v-col cols="12" v-if="logsList && logsList.length == 0">
              <p v-if="loading == false">No hay datos que mostrar.</p>
              <p v-if="loading == true">
                <v-progress-circular
                :size="50"
                color="primary"
                :indeterminate="loading"
              ></v-progress-circular>
              </p>
            </v-col>
            <v-col cols="12" v-if="logsList && logsList.length > 0">
              <v-layout child-flex>
                <v-data-table
                  :headers="headers"
                  :items="logsList"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [20],
                  }"
                  class="dtTable"
                  fixed-header
                  no-results-text="No existen datos con texto filtrado."
                >
                  <template v-slot:[`item.ip`]="{ item }" >
                    {{ item.ip ? item.ip : "---" }}
                  </template>
                  <template v-slot:[`item.observation`]="{ item }" >
                    {{ item.observation && item.observation != '' ? item.observation : "---" }}
                  </template>
                </v-data-table>
              </v-layout>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import roles from "../services/role";
import xlsx from "xlsx";
import backlog from "../services/logs";

export default {
  name: "Logs",

  data: () => ({
    loading: false,
    roleChecker: roles,
    userRole: auth.getRole(),
    organizations: [],
    org: null,
    logsList: [],
    startDate: new Date().toISOString().substr(0, 10),
    finishDate: new Date().toISOString().substr(0, 10),
    datepicker1: false,
    datepicker2: false,
    search: "",
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    headers: [
      { text: "Fecha - Hora", align: "center", value: "creationDate" },
      { text: "IP", align: "center", value: "ip" },
      { text: "Usuario", align: "center", value: "username" },
      { text: "Empresa", align: "center", value: "enterprise" },
      { text: "Acción", align: "center", value: "action" },
      { text: "Observación", align: "center", value: "observation" },
    ]
  }),
  methods: {
    getOrganizations() {
      axios
        .get(globals.APIURL + "org/fa")
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.organizations.push({name: "Todos"})
            res.data.forEach(element => {
              if (!this.organizations.some(org => org.name === element.name)) {
                this.organizations.push(element)
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getLogs(org, startDate, finishDate) {
      if (finishDate >= startDate) {
      this.loading = true;
      this.logsList = [];
        if (org.name && org.name != "Todos") {
          axios
            .post(globals.APIURL + "log/fbd", {
              StartDate: globals.DDMMYYYYstring(new Date(startDate)) + " 00:00:00",
              FinishDate: globals.DDMMYYYYstring(new Date(finishDate)) + " 23:59:59",
              filter: {
                "enterprise": org.name
              }, 
            })
            .then((res) => {
              if (res.data && res.data.length > 0) {
                this.logsList = res.data;
                this.loading = false;
              }
              this.loading = false;                                                                                                                                                                                                                         
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          axios
            .get(globals.APIURL + "log/fa")
            .then((res) => {
              if (res.data && res.data.length > 0) {
                this.logsList = res.data;
                this.loading = false;
              }
                this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        }
      }
    },

    generateReport(jsonContent) {
      if (jsonContent.length > 0) {
        for (let i = 0; i < jsonContent.length; i++) {
          const element = jsonContent[i];
          delete element._id;
        }

        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet(jsonContent, {
          header: [
            "creationDate",
            "username",
            "role",
            "enterprise",
            "action"
          ],
        });

        newWS["A1"].v = "FECHA - HORA";
        newWS["B1"].v = "USERNAME";
        newWS["C1"].v = "ROL";
        newWS["D1"].v = "EMPRESA";
        newWS["E1"].v = "ACCION";

        xlsx.utils.book_append_sheet(newWB, newWS, "Logs");

        xlsx.writeFile(
          newWB,
          "Logs " +
            globals.DDMMYYYY(new Date(), "-") +
            ".xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );

        this.snackbarText = "Se descargó su reporte de logs.";
        this.snackbarColor = "blue";
        this.snackbar = true;
        backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "export/logs"})
      }
    },
  },
  mounted() {
    this.getOrganizations()
  },
};
</script>
