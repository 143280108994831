<template>
  <v-container fluid>
    <!--message alert-->
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Secure Alert Configuration Modal -->
    <div class="text-center" v-if="dialogSecurityAlert">
      <v-dialog
        v-model="dialogSecurityAlert"
        content-class="modalVs"
        width="800"
        no-click-animation
        :retain-focus="false"
      >
        <v-card>
          <v-card-title> Configurar Alerta de Seguridad </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p class="pb-2">Ingrese el mensaje con el que le gustaría alertar a la seguridad: </p>
                <v-textarea
                  v-model="securityAlert.alert_msg"
                  outlined
                  rows="2"
                  label="Mensaje de Alerta"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <p class="pb-2">Datos de contacto: </p>
                <v-text-field
                  v-model="securityAlert.contact_data.phone"
                  label="Número de Contacto"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
                <v-text-field
                  v-model="securityAlert.contact_data.email"
                  label="Email de Contacto"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="my-0 py-0">
                <p class="pb-2">Datos de equipo 1 a alertar: </p>
                <v-text-field
                  v-model="securityAlert.sender_data.name"
                  label="Nombre de Equipo"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
                <v-text-field
                  v-model="securityAlert.sender_data.phone"
                  label="Número de Contacto"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
                <v-text-field
                  v-model="securityAlert.sender_data.email"
                  label="Email de Contacto"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="my-0 py-0">
                <p class="pb-2">Datos de equipo 2 a alertar: </p>
                <v-text-field
                  v-model="securityAlert.second_sender_data.name"
                  label="Nombre de Equipo"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
                <v-text-field
                  v-model="securityAlert.second_sender_data.phone"
                  label="Número de Contacto"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
                <v-text-field
                  v-model="securityAlert.second_sender_data.email"
                  label="Email de Contacto"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="my-0 py-0">
                <p class="pb-2">Datos de equipo 3 a alertar: </p>
                <v-text-field
                  v-model="securityAlert.third_sender_data.name"
                  label="Nombre de Equipo"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
                <v-text-field
                  v-model="securityAlert.third_sender_data.phone"
                  label="Número de Contacto"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
                <v-text-field
                  v-model="securityAlert.third_sender_data.email"
                  label="Email de Contacto"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <small>Los ultimos 2 contactos no son obligatorios, asi que es importante que se llenen correctamente los campos que se vayan a usar, para una una mayor eficiencia a la hora de alertar.</small>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              large
              outlined
              class="px-4"
              @click="dialogSecurityAlert = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              large
              class="px-4"
              @click="createAlertConfig(securityAlert)"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!--modal ROI-->
    <div class="text-center" v-if="openROI">
      <v-dialog
        v-model="openROI"
        content-class="modalVs"
        width="1325"
        no-click-animation
        :retain-focus="false"
      >
        <v-card>
          <v-card-title> Configurar área de interes </v-card-title>
          <v-card-text>
            <div class="text-center ma-4">
              <button
                class="btnOne btnOnlyIcon secondary"
                @click="
                  isDone = false;
                  ROIcounter = 10;
                "
              >
                <i class="fal fa-plus"></i>
              </button>
              <button
                class="btnOne btnOnlyIcon secondary"
                @click="
                  ROIcoordinates.pop();
                  drawPolygon();
                "
              >
                <i class="fal fa-minus"></i>
              </button>
              <button
                class="btnOne btnOnlyIcon secondary"
                @click="isDone = true"
              >
                <i class="fal fa-arrows"></i>
              </button>
            </div>
            <div class="ctConfImgROI ma-0">
              <v-img :src="imgCanvas" :load="activateROI()"></v-img>
              <canvas id="canvas" width="1280" height="720"></canvas>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              large
              outlined
              class="px-4"
              @click="cancelModalRoi(ROIcoordinatesOld)"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              large
              class="px-4"
              @click="openROI = false"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- ----------------------- -->
    <!--pag modulo vision - config-->
    <!-- ----------------------- -->
    <div class="ctModulConfig">
      <div class="ctTablaCam">
        <!--mapa de camara-->
        <div id="ctTableCam" style="display: initial">
          <!--button search camera-->
          <v-row class="ma-0 pa-0 mb-4">
            <v-col md="4" class="ma-0 pa-0">
              <button class="btnOne" @click="dialogFormCam = true">
                <i class="fal fa-plus mx-2"></i> Agregar cámara
              </button>
            </v-col>
            <v-col md="4" class="ma-0 pa-0"> 
              <button class="btnOne error" @click="dialogSecurityAlert = true">
                <i class="fal fa-bell-exclamation"></i> Config. Seguridad
              </button>
            </v-col>
            <v-col md="4" class="ma-0 pa-0">
              <v-text-field
                v-model="search"
                append-icon="fal fa-search"
                label="Filtrar registros"
                single-line
                hide-details
                outlined
                dark
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- -------- -->
          <!--table for view cameras-->
          <!-- -------- -->
          <div>
            <v-data-table
              :headers="tableHeaders"
              :items="tableConfigCamData"
              :search="search"
              :items-per-page="10"
              :header-props="{
                mobile: true,
              }"
              :footer-props="{
                'items-per-page-options': [10],
              }"
              class="dtTable"
              :loading="loadingTable"
              loading-text="Cargando Información..."
              no-data-text="No hay datos que mostrar"
              @click:row="selectRow"
            >
              <!-- <template v-slot:item="{ item }">
              <tr :class="selectRow(item)" @click="selectedEvent = null;selectedEvent = item">
                <td>
                  <v-icon
                    :class="item.status == true ? 'on' : 'off'"
                    class="camStatus"
                  >
                    fal fa-cctv
                  </v-icon>
                </td>
                <td>{{item.place}}</td>
                <td>{{item.name}}</td>
                <td class="pa-0 text-center">
                  <v-icon
                    class="blueV"
                    title="Ver img de la cámara"
                    @click="modalViewImgCam(item)"
                  >
                    fal fa-eye
                  </v-icon>
                </td>
                <td>
                  <v-icon
                    class="blue"
                    title="Editar cámara"
                    @click="setFormCamera(item)"
                  >
                    fal fa-edit
                  </v-icon>
                </td>
                <td>
                  <v-icon
                    class="red"
                    title="Eliminar cámara"
                    @click="dialogMessageDelete(item)"
                  >
                    fal fa-trash-alt
                  </v-icon>
                </td>
              </tr>
            </template> -->
              <template v-slot:[`item.status`]="{ item }">
                <v-icon
                  :class="item.status == true ? 'on' : 'off'"
                  class="camStatus"
                >
                  fal fa-cctv
                </v-icon>
              </template>
              <template v-slot:[`item.btnViewCam`]="{ item }">
                <v-icon
                  class="blueV"
                  title="Ver img de la cámara"
                  @click="modalViewImgCam(item)"
                >
                  fal fa-eye
                </v-icon>
              </template>
              <template v-slot:[`item.btnEdit`]="{ item }">
                <v-icon
                  class="blue"
                  title="Editar cámara"
                  @click="setFormCamera(item)"
                >
                  fal fa-edit
                </v-icon>
              </template>
              <template v-slot:[`item.btnDelete`]="{ item }">
                <v-icon
                  class="red"
                  title="Eliminar cámara"
                  @click="dialogMessageDelete(item)"
                >
                  fal fa-trash-alt
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>

      <!--config cam-->
      <div class="ctOpcCofigCam">
        <!-- -------- -->
        <!--form create or edit alarm-->
        <!-- -------- -->
        <div>
          <div>
            <div>
              <p>
                <b>Nombre: </b>
                <!-- <span id="idItemSelect">{{ idItemSelect.place }}</span> -->
                <span id="idItemSelect">{{ idItemSelect.name }}</span>
              </p>
            </div>
            <div>
              <div class="CtTitleCam">
                <p>Estado de la cámara</p>
                <hr />
              </div>
              <div>
                <label class="mb-2">Estado:</label>
                <b
                  id="idItemSelectStatusCam"
                  :class="idItemSelect.status == true ? 'on' : 'off'"
                >
                  {{
                    idItemSelect.status == true ? "Conectado" : "Desconectado"
                  }}
                </b>
              </div>
              <br />
              <div>
                <label class="mb-2">Ultima conexión de la cámara</label>
                <b id="idItemSelectDateCamare">{{ idItemSelect.timeStatus ? idItemSelect.timeStatus.substring(0, 10) + " - " + idItemSelect.timeStatus.substring(11, 16) : ""  }}</b>
              </div>
            </div>
            <div>
              <div class="CtTitleCam">
                <p>Auditoria</p>
                <hr />
              </div>
              <div>
                <label class="mb-2">Ultimo editor:</label>
                <b id="idItemSelectNameEdit">{{ idItemSelect.userMod }}</b>
              </div>
              <br />
              <div>
                <label class="mb-2">Fecha de edición</label>
                <b id="idItemSelectDateEdit">{{ idItemSelect.modDate ? idItemSelect.modDate.substring(0, 10) + " - " + idItemSelect.modDate.substring(11, 16) : "" }}</b>
              </div>
            </div>
          </div>
          <div>
            <form id="formConfigAlarm" name="formConfigAlarm">
              <div class="CtTitleCam">
                <p>Configurar Alarma</p>
                <hr />
              </div>
              <div style="display: none">
                <label>ID</label>
                <!-- <input type="text" name="idAlarm" v-model=""/> -->
                <v-text-field
                  name="idAlarm"
                  v-model="dataAlarmItemSelect.alarmkey"
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                  dense
                  placeholder="ID"
                ></v-text-field>
              </div>
              <div style="display: none">
                <label>IDCam</label>
                <input type="text" id="idCam" name="idCam" />
              </div>
              <div>
                <label>Nombre*</label>
                <!-- <input type="text" id="nameAlarm" name="nameAlarm" placeholder="Nombre..." v-model="dataAlarmItemSelect.name" required style="border: 1px solid #6b7284;"/> -->
                <v-text-field
                  name="nameAlarm"
                  v-model="dataAlarmItemSelect.name"
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                  dense
                  placeholder="Nombre..."
                  required
                ></v-text-field>
              </div>
              <br />
              <div>
                <label>Modelo*</label>
                <v-select
                  item-text="name"
                  item-value="key"
                  v-model="dataAlarmItemSelect.classObj"
                  :items="modeloClass"
                  chips
                  multiple
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                  dense
                  placeholder="Modelo"
                ></v-select>
              </div>
              <br />
              <div>
                <label>ROI</label>
                <button
                  type="button"
                  class="btnOne roi btnModalROI"
                  @click="manageROI(idItemSelect)"
                  :disabled="disabledRoi"
                >
                  Área de interés
                </button>
                <label id="roiState" class="switch" style="margin-top: 13px">
                  <input
                    type="checkbox"
                    id="ckRoi"
                    name="roiState"
                    @click="checkROI()"
                    v-model="dataAlarmItemSelect.roiState"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="mt-1" :class="ROIinvertido">
                <span
                  style="
                    padding: 11px 18px;
                    border: 2px solid #21242b;
                    border-radius: 10px;
                  "
                >
                  <span>ROI invertido</span>
                  <label
                    id="roiInverse"
                    class="switch"
                    style="margin-top: 13px"
                  >
                    <input
                      type="checkbox"
                      id="ckRoiInverse"
                      name="roiInverse"
                      form="formConfigAlarm"
                      v-model="dataAlarmItemSelect.roiInverse"
                    />
                    <span class="slider round"></span>
                  </label>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="secondary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                        style="font-size: 1.5rem"
                      >
                        fal fa-question-circle
                      </v-icon>
                    </template>
                    <span
                      >Invertir el área de interés, si esta opción<br />esta
                      habilitada el área de detección sera<br />la que este por
                      fuera de la figura</span
                    >
                  </v-tooltip>
                </span>
              </div>
              <br />
              <div class="ctWeekOpc02">
                <div>
                  <input
                    type="checkbox"
                    id="lun"
                    name="lun"
                    :checked="parseInt(alarmDays[0])"
                  />
                  <label for="lun">L</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="mar"
                    name="mar"
                    :checked="parseInt(alarmDays[1])"
                  />
                  <label for="mar">M</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="mie"
                    name="mie"
                    :checked="parseInt(alarmDays[2])"
                  />
                  <label for="mie">M</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="jue"
                    name="jue"
                    :checked="parseInt(alarmDays[3])"
                  />
                  <label for="jue">J</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="vie"
                    name="vie"
                    :checked="parseInt(alarmDays[4])"
                  />
                  <label for="vie">V</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="sab"
                    name="sab"
                    :checked="parseInt(alarmDays[5])"
                  />
                  <label for="sab">S</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="dom"
                    name="dom"
                    :checked="parseInt(alarmDays[6])"
                  />
                  <label for="dom">D</label>
                </div>
              </div>
              <br />
              <div class="ctConfigIntTime">
                <div>
                  <label>Desde*</label>
                  <!-- <input type="time" id="startTimeAlarm" name="startTimeAlarm" v-model="dataAlarmItemSelect.startTime" required style="border: 1px solid #6b7284;"/> -->
                  <v-text-field
                    type="time"
                    id="startTimeAlarm"
                    name="startTimeAlarm"
                    v-model="dataAlarmItemSelect.startTime"
                    outlined
                    dark
                    color="secondary"
                    hide-details="auto"
                    dense
                    placeholder="Nombre..."
                    required
                  ></v-text-field>
                </div>
                <div>
                  <label>Hasta*</label>
                  <!-- <input type="time" id="stopTimeAlarm" name="stopTimeAlarm" v-model="dataAlarmItemSelect.stopTime" required style="border: 1px solid #6b7284;"/> -->
                  <v-text-field
                    type="time"
                    id="stopTimeAlarm"
                    name="stopTimeAlarm"
                    v-model="dataAlarmItemSelect.stopTime"
                    outlined
                    dark
                    color="secondary"
                    hide-details="auto"
                    dense
                    placeholder="Nombre..."
                    required
                  ></v-text-field>
                </div>
              </div>
              <br />
              <div>
                <button
                  class="btnOne btnOnlyIcon"
                  type="submit"
                  @click.prevent="createAlarm(dataAlarmItemSelect)"
                >
                  <i class="fal fa-save"></i>
                </button>
                <button
                  class="btnOne btnOnlyIcon"
                  type="button"
                  @click="btnClearFormAlarm"
                  style="background: #f87171; border: 1px solid #f87171"
                >
                  <i class="fal fa-eraser"></i>
                </button>
              </div>
            </form>
          </div>
        </div>

        <!-- -------- -->
        <!--list of alarms-->
        <!-- -------- -->
        <div>
          <div>
            <div class="CtTitleCam">
              <p>Lista de Alarmas</p>
              <hr />
            </div>

            <v-data-table
              :headers="tableHeadersAlarms"
              :items="tableListAlarms"
              :items-per-page="10"
              :header-props="{
                mobile: true,
              }"
              :footer-props="{
                'items-per-page-options': [10],
              }"
              class="dtTable"
              :loading="loadingTable"
              loading-text="Cargando Información..."
              no-data-text="No hay datos que mostrar"
              @click:row="selectRowAlarm"
            >
              <template v-slot:[`item.persona`]="{ item }">
                {{ item.persona == true ? "Persona" : "Persona" }}
              </template>
              <template v-slot:[`item.active`]="{ item }">
                <label class="switch mt-5" id="stateAlarmCk">
                  <input
                    type="checkbox"
                    id="stateAlarm"
                    name="stateAlarmCk"
                    v-model="item.active"
                    @click="statusAlamr()"
                  />
                  <!-- <input type="checkbox" id="stateAlarm" name="stateAlarmCk" v-model="item.active" @click:row="statusAlamr"> -->
                  <span class="slider round"></span>
                </label>
              </template>
              <template v-slot:[`item.btnEdit`]="{ item }">
                <v-icon class="blue" @click="setFormAlarm(item)">
                  fal fa-edit
                </v-icon>
              </template>
              <template v-slot:[`item.btnDelete`]="{ item }">
                <v-icon class="red" @click="dialogMessageDeleteAlarma(item)">
                  fal fa-trash-alt
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>

    <!-- -------- -->
    <!--modal for view img cam-->
    <!-- -------- -->
    <v-row justify="center">
      <v-dialog
        content-class="modalVs"
        v-model="dialogViewImgCam"
        max-width="900"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Visualizar cámara</span>
          </v-card-title>
          <v-card-text class="ma-0">
            <v-img
              max-height="900"
              max-width="900"
              :src="urlViewImgCam"
            ></v-img>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              large
              outlined
              class="px-4"
              @click="dialogViewImgCam = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- -------- -->
    <!--modal add camera-->
    <!-- -------- -->
    <v-row>
      <v-dialog
        content-class="modalVs"
        v-model="dialogFormCam"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <p>Configuración</p>
          </v-card-title>
          <v-card-text class="mt-5">
            <!--place Cam-->
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="editedItem.placeCam"
                label="Lugar*"
                outlined
                dark
                color="secondary"
              ></v-text-field>
            </v-col>
            <!--rtsp Cam-->
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="editedItem.rtspCam"
                label="RTSP*"
                outlined
                dark
                color="secondary"
              ></v-text-field>
            </v-col>
            <!--name Cam-->
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="editedItem.nameCam"
                label="Nombre*"
                outlined
                dark
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              large
              outlined
              class="px-4"
              @click="dialogFormCam = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              large
              class="px-4"
              @click="createCamera(editedItem)"
            >
              Crear
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- -------- -->
    <!--modal edit cam-->
    <!-- -------- -->
    <v-row justify="center">
      <v-dialog content-class="modalVs" v-model="dialogEditCam" max-width="500">
        <v-card>
          <v-card-title>
            <span class="text-h5">Configuración</span>
          </v-card-title>
          <v-card-text class="mt-5">
            <!--key Cam-->
            <v-col cols="12" class="py-1 px-3 d-none">
              <v-text-field
                v-model="editedItem.keyCam"
                label="key*"
                outlined
                dark
                color="secondary"
              ></v-text-field>
            </v-col>
            <!--place Cam-->
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="editedItem.placeCam"
                label="Lugar*"
                outlined
                dark
                color="secondary"
              ></v-text-field>
            </v-col>
            <!--rtsp Cam-->
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="editedItem.rtspCam"
                label="RTSP*"
                outlined
                dark
                color="secondary"
              ></v-text-field>
            </v-col>
            <!--name Cam-->
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="editedItem.nameCam"
                label="Nombre*"
                outlined
                dark
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              large
              outlined
              class="px-4"
              @click="dialogEditCam = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              large
              class="px-4"
              @click="editCamera(editedItem)"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- -------- -->
    <!--modal message delete-->
    <!-- -------- -->
    <v-row justify="center">
      <v-dialog
        content-class="modalVs modalAlert"
        v-model="dialogMessage"
        max-width="500"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Eliminar Registro</span>
          </v-card-title>
          <v-card-text class="my-5 text-center">
            <i
              class="fal fa-exclamation-circle error--text ma-8"
              style="font-size: 100px"
            ></i>
            <br /><br />
            <p>Estas seguro de eliminar el registro.</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              large
              outlined
              class="px-4"
              @click="dialogMessage = false"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              large
              class="px-4"
              @click="deleteCamera(editedItem)"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- -------- -->
    <!--modal message delete alarma-->
    <!-- -------- -->
    <v-row justify="center">
      <v-dialog
        content-class="modalVs modalAlert"
        v-model="dialogMessageAlarm"
        max-width="500"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Eliminar Registro</span>
          </v-card-title>
          <v-card-text class="my-5 text-center">
            <i
              class="fal fa-exclamation-circle error--text ma-8"
              style="font-size: 100px"
            ></i>
            <br /><br />
            <p>Estas seguro de eliminar el registro.</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              large
              outlined
              class="px-4"
              @click="dialogMessageAlarm = false"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              large
              class="px-4"
              @click="deleteAlarm(dataAlarmItemSelect)"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>



<script>
import axios from "axios";
import globals from "../../globals";
import auth from "../../services/auth";

export default {
  name: "Settings",

  //-----------------------
  //global var
  //-----------------------
  data: () => ({
    //table cam
    tableConfigCamData: [],
    dateStatusCamare: [],
    loadingTable: false,
    search: "",
    tableHeaders: [
      { text: "Estado", align: "center", value: "status" },
      { text: "Lugar", align: "center", value: "place"},
      { text: "Nombre", align: "center", value: "name"},
      { text: "", align: "center", value: "btnViewCam", sortable: false },
      { text: "", align: "center", value: "btnEdit", sortable: false },
      { text: "", align: "center", value: "btnDelete", sortable: false },
    ],
    idItemSelect: [],
    //table alarm
    uuidAlarm: "",
    stateAlarm: "",
    tableListAlarms: [],
    tableHeadersAlarms: [
      { text: "Nombre", align: "center", value: "name" },
      { text: "Modelo", align: "center", value: "persona" },
      { text: "Estado", align: "center", value: "active" },
      //{ text: "", align: "center", value: "btnEdit", sortable: false },
      { text: "", align: "center", value: "btnDelete", sortable: false },
    ],
    dataAlarmItemSelect: [],
    alarmRoi: [],
    alarmDays: [],
    disabledRoi: "",
    ROIinvertido: "d-none",
    //row
    classSelectRow: "",
    //alert
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    //modal
    dialogViewImgCam: false,
    urlViewImgCam: "",
    dialogFormCam: false,
    dialogDelete: false,
    dialogEditCam: false,
    dialogMessage: false,
    dialogMessageAlarm: false,
    dataCamDelete: [],
    editedIndex: -1,
    editedItem: {
      keyCam: "",
      placeCam: "",
      rtspCam: "",
      nameCam: "",
    },
    defaultItem: {
      name: "",
    },
    // --------------------------
    openROI: false,
    isDone: false,
    ROIcounter: 3,
    ROIcoordinates: [
      { x: 290, y: 165 },
      { x: 475, y: 165 },
      { x: 475, y: 340 },
      { x: 290, y: 340 },
    ],
    ROIcoordinatesOld: [],
    ROImouse: {},
    ROIdraggable: true,
    ROIcontext: null,
    ROIcanvas: null,
    ROIcw: null,
    ROIch: null,
    imgCanvas: null,
    // --------------------------
    //modeloClass: ['Carro', 'Moto', 'Persona'],
    modeloClass: [
      { name: "Carro", key: "0" },
      { name: "Moto", key: "1" },
      { name: "Persona", key: "2" },
    ],
    valueClass: [],
    classDt: "",
    dialogSecurityAlert: false,
    securityAlert: {
      alert_msg: "",
      contact_data: {
        email: "",
        phone: ""
      },
      sender_data: {
        name: "",
        email: "",
        phone: ""
      },
      second_sender_data: {
        name: "",
        email: "",
        phone: ""
      },
      third_sender_data: {
        name: "",
        email: "",
        phone: ""
      }
    },
  }),

  //-----------------------
  //methods
  //-----------------------
  methods: {
    // ------------------->
    //select row table cameras
    // ------------------->
    selectRow(value) {
      console.log(value)
      this.idItemSelect = value;
      this.loadingListAlarms(value);
      this.dataAlarmItemSelect = [];
      this.alarmRoi = [];
      this.alarmDays = [];
      setTimeout(() => this.checkROI(), 100);
      document.formConfigAlarm.idCam.value = this.idItemSelect.camkey;
      this.ROIcoordinates = [
        { x: 290, y: 165 },
        { x: 475, y: 165 },
        { x: 475, y: 340 },
        { x: 290, y: 340 },
      ];
      this.ROIcoordinatesOld = "290;165;475;165;475;340;290;340;";
    },

    // ------------------->
    // ROI logic
    // ------------------->
    activateROI() {
      setTimeout(() => this.initROI(), 2000);
    },

    manageROI(camData) {
      this.openROI = true;
      axios({
        method: "GET",
        baseURL:
          globals.APIURLVision + "/cameras/" + camData.camkey + "/preview",
      }).then((res) => {
        this.initROI();
        this.imgCanvas = res.data;
      });
    },

    initROI() {
      this.ROIcanvas = document.getElementById("canvas");
      this.ROIcontext = this.ROIcanvas.getContext("2d");
      this.ROIcw = this.ROIcanvas.width;
      this.ROIch = this.ROIcanvas.height;

      this.ROIcontext.lineWidth = 5;
      this.ROIcontext.fillStyle = "yellow";
      this.ROIcontext.strokeStyle = "yellow";

      //this.ROIcoordinates = [{x:290, y:165},{x:475, y:165},{x:475, y:340},{x:290, y:340}];

      this.ROIcontext.clearRect(0, 0, this.ROIcw, this.ROIch);
      this.ROIcontext.beginPath();
      this.ROIcontext.moveTo(
        this.ROIcoordinates[0].x,
        this.ROIcoordinates[0].y
      );

      for (let i = 1; i < this.ROIcoordinates.length; i++) {
        this.ROIcontext.lineTo(
          this.ROIcoordinates[i].x,
          this.ROIcoordinates[i].y
        );
      }
      this.ROIcontext.closePath();
      this.ROIcontext.stroke();
      // Additionaly I'm drawing a small circle around every point
      // you can delete this.
      for (let j = 0; j < this.ROIcoordinates.length; j++) {
        this.ROIcontext.beginPath();
        this.ROIcontext.arc(
          this.ROIcoordinates[j].x,
          this.ROIcoordinates[j].y,
          5,
          0,
          2 * Math.PI
        );
        this.ROIcontext.stroke();
      }

      let that = this;

      this.ROIcanvas.addEventListener("mousedown", function (e) {
        that.handleMouseDown(e);
      });

      this.ROIcanvas.addEventListener("mousemove", function (e) {
        if (that.isDone) {
          if (that.ROIdraggable) {
            that.ROImouse = that.oMousePos(that.ROIcanvas, e);
            // draggable - 1 is the index of the point in the coordinates array
            that.ROIcoordinates[that.ROIdraggable - 1].x = that.ROImouse.x;
            that.ROIcoordinates[that.ROIdraggable - 1].y = that.ROImouse.y;
            that.drawPolygon();
          }
        }
      });

      this.ROIcanvas.addEventListener("mouseup", function (e) {
        //valor de la lista de puntos al soltar el mouse
        if (that.ROIdraggable) {
          console.log("MOUSEUP: ", e);
          that.ROIdraggable = false;
        }
      });
    },

    handleMouseDown(e) {
      this.ROImouse = this.oMousePos(this.ROIcanvas, e);
      //if isDone you can drag
      if (this.isDone || this.ROIcoordinates.length > this.ROIcounter) {
        for (let index = 0; index < this.ROIcoordinates.length; index++) {
          // you draw a small circle no stroke, no fill
          this.ROIcontext.beginPath();
          this.ROIcontext.arc(
            this.ROIcoordinates[index].x,
            this.ROIcoordinates[index].y,
            10,
            0,
            5 * Math.PI
          );
          // if the ROImouse is inside the circle
          if (this.ROIcontext.isPointInPath(this.ROImouse.x, this.ROImouse.y)) {
            // you can drag this point
            // I'm using index + 1 because index == 0 is false
            this.ROIdraggable = index + 1;
            // if I have a point a can break the loop
            break;
          }
        }
      } else {
        this.ROIcoordinates.push({ x: this.ROImouse.x, y: this.ROImouse.y });
        this.drawPolygon();
      }
    },

    drawPolygon() {
      this.ROIcontext.clearRect(0, 0, this.ROIcw, this.ROIch);
      this.ROIcontext.beginPath();
      this.ROIcontext.moveTo(
        this.ROIcoordinates[0].x,
        this.ROIcoordinates[0].y
      );
      for (let index = 1; index < this.ROIcoordinates.length; index++) {
        this.ROIcontext.lineTo(
          this.ROIcoordinates[index].x,
          this.ROIcoordinates[index].y
        );
      }
      this.ROIcontext.closePath();
      this.ROIcontext.stroke();
      // Additionaly I'm drawing a small circle around every point
      // you can delete this.
      for (let index = 0; index < this.ROIcoordinates.length; index++) {
        this.ROIcontext.beginPath();
        this.ROIcontext.arc(
          this.ROIcoordinates[index].x,
          this.ROIcoordinates[index].y,
          5,
          0,
          2 * Math.PI
        );
        this.ROIcontext.stroke();
      }
    },

    oMousePos(ROIcanvas, evt) {
      var ClientRect = ROIcanvas.getBoundingClientRect();
      return {
        //objeto
        x: Math.round(evt.clientX - ClientRect.left),
        y: Math.round(evt.clientY - ClientRect.top),
      };
    },

    //-------------------->
    //function for create a new UUID
    //-------------------->
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },

    //-------------------->
    //function for get JSON of cameras
    //-------------------->
    tableConfigCam() {
      this.loadingTable = true;
      axios({
        method: "GET",
        baseURL:
          globals.APIURLVision +
          "/cameras/cameralist?assetkey=" +
          auth.get_asset_id(),
      }).then((data) => {
        this.loadingTable = false;
        this.camstate(data.data);
      });
    },

    //-------------------->
    //function for identify state of the camara
    //-------------------->
    camstate(dataCam) {
      for (let i = 0; i < dataCam.length; i++) {
        const element = dataCam[i];
        element.status = false
      }
      axios({
        method: "GET",
        baseURL:
          globals.APIURLVision +
          "/cameras/camstate?assetkey=" +
          auth.get_asset_id(),
      }).then((data) => {
        if (data.data.length !== 0) {
          let hoy = new Date();
          for (let i = 0; i < dataCam.length; i++) {
            var cam_state = data.data.filter(each_cam => {return dataCam[i].camkey === each_cam.camkey });

            dataCam[i].modDate = this.testDate(dataCam[i].modDate * 1000);
            if (cam_state.length > 0 && cam_state[0].timestamp) {
              dataCam[i].timeStatus = this.testDate2(cam_state[0].timestamp);
              if (
                this.testDate2(cam_state[0].timestamp) > this.testDate3(hoy)
              ) {
                dataCam[i].status = true;
              } else {
                dataCam[i].status = false;
              } 
            }
          }
        }
        this.tableConfigCamData = dataCam;
      });
    },

    // ------------------->
    //update date UTC for local
    // ------------------->
    testDate(data) {
      var newDate = new Date(data);
      //newDate = new Date(newDate.getTime() - newDate.getTimezoneOffset()*60*1000);
      var dateLocal = newDate.getFullYear() + "-" + (newDate.getMonth() + 1 > 9 ? '' : '0') + (newDate.getMonth() + 1)  + "-" + (newDate.getDate() > 9 ? '' : '0') + (newDate.getDate())  + "T" + (newDate.getHours() > 9 ? '' : '0') + (newDate.getHours()) + ":" + (newDate.getMinutes() > 9 ? '' : '0') + (newDate.getMinutes()) + ":" + (newDate.getSeconds() > 9 ? '' : '0') + (newDate.getSeconds());
      return dateLocal;
    },

    testDate2(data) {
      var newDate = new Date(data);
      newDate = new Date(
        newDate.getTime() - newDate.getTimezoneOffset() * 60 * 1000
      );
      var dateLocal = newDate.getFullYear() + "-" + (newDate.getMonth() + 1 > 9 ? '' : '0') + (newDate.getMonth() + 1)  + "-" + (newDate.getDate() > 9 ? '' : '0') + (newDate.getDate())  + "T" + (newDate.getHours() > 9 ? '' : '0') + (newDate.getHours()) + ":" + (newDate.getMinutes() > 9 ? '' : '0') + (newDate.getMinutes()) + ":" + (newDate.getSeconds() > 9 ? '' : '0') + (newDate.getSeconds());
      return dateLocal;
    },

    testDate3(data) {
      var newDate = new Date(data);
      newDate = new Date(newDate.getTime() - 180000);
      var dateLocal = newDate.getFullYear() + "-" + (newDate.getMonth() + 1 > 9 ? '' : '0') + (newDate.getMonth() + 1)  + "-" + (newDate.getDate() > 9 ? '' : '0') + (newDate.getDate())  + "T" + (newDate.getHours() > 9 ? '' : '0') + (newDate.getHours()) + ":" + (newDate.getMinutes() > 9 ? '' : '0') + (newDate.getMinutes()) + ":" + (newDate.getSeconds() > 9 ? '' : '0') + (newDate.getSeconds());
      return dateLocal;
    },

    //-------------------->
    //modal for view img cam & ROI
    //-------------------->
    modalViewImgCam(obj) {
      this.dialogViewImgCam = true;
      this.urlViewImgCam = "";
      axios({
        method: "GET",
        baseURL: globals.APIURLVision + "/cameras/" + obj.camkey + "/preview",
      }).then((res) => {
        this.urlViewImgCam = res.data;
      });
    },

    //-------------------->
    //CRUD cameras
    //-------------------->
    createCamera(obj) {
      let idCamera = this.uuidv4();
      axios
        .post(globals.APIURLVision + "/cameras/cam", {
          assetkey: auth.get_asset_id(),
          camkey: idCamera,
          active: true,
          userMod: auth.getUsername(),
          place: obj.placeCam,
          name: obj.nameCam,
          rtsp: obj.rtspCam,
          xmap: 0,
          ymap: 0,
        })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            this.dialogFormCam = false;
            this.selectRow({ key: idCamera });
            this.clearForm();
            this.tableConfigCam();
            this.snackbarText = "Registro creado exitosamente.";
            this.snackbarColor = "success";
            this.snackbar = true;
          }
        });
    },
    setFormCamera(obj) {
      this.dialogEditCam = true;
      this.editedItem.keyCam = obj.camkey;
      this.editedItem.placeCam = obj.place;
      this.editedItem.rtspCam = obj.rtsp;
      this.editedItem.nameCam = obj.name;
    },
    editCamera(obj) {
      axios
        .post(globals.APIURLVision + "/cameras/cam", {
          assetkey: auth.get_asset_id(),
          camkey: obj.keyCam,
          active: true,
          userMod: auth.getUsername(),
          place: obj.placeCam,
          name: obj.nameCam,
          rtsp: obj.rtspCam,
          xmap: 0,
          ymap: 0,
        })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            this.dialogEditCam = false;
            this.selectRow(this.idItemSelect);
            this.clearForm();
            this.tableConfigCam();
            this.snackbarText = "Registro actualizado exitosamente.";
            this.snackbarColor = "success";
            this.snackbar = true;
          }
        });
    },
    dialogMessageDelete() {
      this.dialogMessage = true;
    },
    deleteCamera() {
      axios
        //.post(globals.APIURLVision + '/cameras/' + this.idItemSelect.camkey, {
        .post(globals.APIURLVision + "/cameras/cam", {
          assetkey: auth.get_asset_id(),
          camkey: this.idItemSelect.camkey,
          active: false,
          userMod: auth.getUsername(),
          place: this.idItemSelect.place,
          name: this.idItemSelect.name,
          rtsp: this.idItemSelect.rtsp,
          xmap: 0,
          ymap: 0,
        })
        .then((res) => {
          console.log(res, this.idItemSelect);
          if (res.status == 200 || res.status == 201) {
            this.dialogMessage = false;
            this.tableConfigCam();
            this.snackbarText = "Registro eliminado exitosamente.";
            this.snackbarColor = "success";
            this.snackbar = true;
          }
        });
    },
    clearForm() {
      this.editedItem.placeCam = "";
      this.editedItem.rtspCam = "";
      this.editedItem.nameCam = "";
    },

    //-------------------->
    //function for get JSON of alarms
    //-------------------->
    loadingListAlarms(value) {
      axios({
        method: "GET",
        baseURL: globals.APIURLVision + "/cameras/" + value.camkey + "/alarms",
      }).then((data) => {
        this.tableListAlarms = data.data;
        this.loadingTable = false;
        //time star & stop
        if (data.data !== 0) {

          for (let i = 0; i < this.tableListAlarms.length; i++) {

            this.tableListAlarms[i].startTime = this.tableListAlarms[i].startTime - 500;
            this.tableListAlarms[i].stopTime = this.tableListAlarms[i].stopTime - 500;

            if (this.tableListAlarms[i].startTime <= 900) {
              this.tableListAlarms[i].startTime =
                "0" +
                this.tableListAlarms[i].startTime.toString().substr(0, 1) +
                ":" +
                this.tableListAlarms[i].startTime.toString().substr(1, 2);
            } else {
              this.tableListAlarms[i].startTime =
                this.tableListAlarms[i].startTime.toString().substr(0, 2) +
                ":" +
                this.tableListAlarms[i].startTime.toString().substr(2, 3);
            }
            if (this.tableListAlarms[i].stopTime <= 900) {
              this.tableListAlarms[i].stopTime =
                "0" +
                this.tableListAlarms[i].stopTime.toString().substr(0, 1) +
                ":" +
                this.tableListAlarms[i].stopTime.toString().substr(1, 2);
            } else {
              this.tableListAlarms[i].stopTime =
                this.tableListAlarms[i].stopTime.toString().substr(0, 2) +
                ":" +
                this.tableListAlarms[i].stopTime.toString().substr(2, 3);
            }
            //class
            this.tableListAlarms[i].classObj = this.selectClass(
              data.data[i].class
            );
          }
        }
      });
    },
    selectClass(data) {
      let classMd02 = [];
      let roiP = data.split(";");
      for (let i = 0; i < roiP.length; i++) {
        switch (roiP[i]) {
          case "0":
            classMd02.push("0");
            break;
          case "1":
            classMd02.push("1");
            break;
          case "2":
            classMd02.push("2");
            break;
        }
      }
      return classMd02;
    },

    //-------------------->
    //function for create string of week
    //-------------------->
    getValueWeekOpc() {
      let lun = document.getElementById("lun").checked ? 1 : 0;
      let mar = document.getElementById("mar").checked ? 1 : 0;
      let mir = document.getElementById("mie").checked ? 1 : 0;
      let jue = document.getElementById("jue").checked ? 1 : 0;
      let vie = document.getElementById("vie").checked ? 1 : 0;
      let sab = document.getElementById("sab").checked ? 1 : 0;
      let dom = document.getElementById("dom").checked ? 1 : 0;
      let res =
        lun +
        " " +
        mar +
        " " +
        mir +
        " " +
        jue +
        " " +
        vie +
        " " +
        sab +
        " " +
        dom;
      return res;
    },

    //-------------------->
    //CRUD alarms
    //-------------------->
    createAlarm(obj) {
      if (
        document.formConfigAlarm.idCam.value == null ||
        document.formConfigAlarm.idCam.value == ""
      ) {
        this.snackbarText = "Seleccione una cámara";
        this.snackbarColor = "error";
        this.snackbar = true;
      } else {
        console.log(obj);
        //formant time
        let startTime = obj.startTime.replace(":", "");
        let stopTime = obj.stopTime.replace(":", "");
        if (startTime == 1200) startTime = 1159;
        else if (startTime == 0) startTime = 2359;
        if (stopTime == 1200) stopTime = 1159;
        else if (stopTime == 0) stopTime = 2359;

        //select uuid alam
        let uidAlarm = obj.alarmkey;
        if (uidAlarm == null || uidAlarm == "" || uidAlarm == undefined) {
          this.uuidAlarm = this.uuidv4();
          this.snackbarText = "Registro creado exitosamente";
        } else {
          this.uuidAlarm = uidAlarm;
          this.snackbarText = "Registro actualizado exitosamente";
        }

        //roiCoordinates
        let roiCoordinates = "";
        for (let i = 0; i < this.ROIcoordinates.length; i++) {
          roiCoordinates +=
            this.ROIcoordinates[i].x + ";" + this.ROIcoordinates[i].y + ";";
        }

        axios
          .post(
            globals.APIURLVision + "/cameras/" + this.uuidAlarm + "/alarms",
            {
              alarmkey: this.uuidAlarm,
              name: obj.name,
              camkey: this.idItemSelect.camkey,
              userMod: auth.getUsername(),
              class: this.classAlarm(obj.classObj),
              roiState: document.getElementById("ckRoi").checked ? true : false,
              roi: roiCoordinates,
              roiInverse: document.getElementById("ckRoiInverse").checked
                ? true
                : false,
              days: this.getValueWeekOpc(),
              startTime: parseInt(startTime) + 500,
              stopTime: parseInt(stopTime) + 500,
              active: true,
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200 || res.status == 201) {
              this.loadingListAlarms(this.idItemSelect);
              this.dataAlarmItemSelect = [];
              this.snackbarColor = "success";
              this.snackbar = true;
            }
          });
      }
    },
    //-------------------->
    //funcion para crear string del modelo / class
    classAlarm(data) {
      this.classDt = "";
      for (let i = 0; i < data.length; i++) {
        if (i == data.length - 1) this.classDt += data[i];
        else this.classDt += data[i] + ";";
      }
      return this.classDt;
    },
    //-------------------->
    //seleccionar la fila de alarmas
    selectRowAlarm(obj) {
      this.dataAlarmItemSelect = [];
      this.dataAlarmItemSelect = obj;
      //checkboxWeek
      this.alarmDays = obj.days !== null ? obj.days.split(" ") : "";
      //roi
      let roiP = obj.roi.split(";");
      let cont = (roiP.length - 1) / 2;
      var dataCoor = [];
      for (let i = 0; i < cont; i++) {
        let n = i * 2;
        let obj = { x: parseInt(roiP[n]), y: parseInt(roiP[n + 1]) };
        dataCoor.push(obj);
      }
      this.ROIcoordinates = dataCoor;
      this.ROIcoordinatesOld = obj.roi;
      //roi-invertido
      setTimeout(() => this.checkROI(), 100);
    },
    dialogMessageDeleteAlarma() {
      this.dialogMessageAlarm = true;
    },
    deleteAlarm(obj) {
      let body = {
        cam_key: obj.camkey,
        alarm_key: obj.alarmkey
      }
      axios({
          method: "POST",
          url: globals.APIURL + 'vision/delete-alarm',
          data: body,
        })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            this.loadingListAlarms(this.idItemSelect);
            this.dataAlarmItemSelect = [];
            this.dialogMessageAlarm = false;
            this.snackbarText = "Registro eliminado exitosamente";
            this.snackbarColor = "success";
            this.snackbar = true;
          }
        });
    },
    checkROI() {
      this.disabledRoi = document.getElementById("ckRoi").checked
        ? false
        : true;
      this.ROIinvertido = document.getElementById("ckRoi").checked
        ? "d-block"
        : "d-none";
    },
    btnClearFormAlarm() {
      setTimeout(() => this.checkROI(), 100);
      document.formConfigAlarm.idCam.value = this.idItemSelect.key;
      this.ROIcoordinates = [
        { x: 290, y: 165 },
        { x: 475, y: 165 },
        { x: 475, y: 340 },
        { x: 290, y: 340 },
      ];
      this.dataAlarmItemSelect = [];
      this.alarmRoi = [];
      this.alarmDays = [];
    },

    //-------------------->
    //cambiar estado de la alarma
    statusAlamr() {
      let obj = this.dataAlarmItemSelect;
      //formant time
      let startTime = obj.startTime.replace(":", "");
      let stopTime = obj.stopTime.replace(":", "");
      if (startTime == 1200) startTime = 1159;
      else if (startTime == 0) startTime = 2359;
      if (stopTime == 1200) stopTime = 1159;
      else if (stopTime == 0) stopTime = 2359;

      axios
        .post(globals.APIURLVision + "/cameras/" + obj.alarmkey + "/alarms", {
          alarmkey: obj.alarmkey,
          name: obj.name,
          camkey: obj.camkey,
          userMod: auth.getUsername(),
          class: obj.class,
          roiState: obj.roiState,
          roi: obj.roi,
          roiInverse: obj.roiInverse,
          days: obj.days,
          startTime: (parseInt(startTime) + 500),
          stopTime: (parseInt(stopTime) + 500),
          active: document.getElementById("stateAlarm").checked ? true : false,
        })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            this.loadingListAlarms(this.idItemSelect);
            this.snackbarText = "Registro actualizado exitosamente";
            this.snackbarColor = "success";
            this.snackbar = true;
          }
        });
    },

    //-------------------->
    //cancel modal ROI
    cancelModalRoi(obj) {
      console.log(this.ROIcoordinatesOld, obj);
      if (obj.length == 0) {
        this.ROIcoordinates = [
          { x: 290, y: 165 },
          { x: 475, y: 165 },
          { x: 475, y: 340 },
          { x: 290, y: 340 },
        ];
      } else {
        //roi
        let roiP = obj.split(";");
        let cont = (roiP.length - 1) / 2;
        var dataCoor = [];
        for (let i = 0; i < cont; i++) {
          let n = i * 2;
          let obj = { x: parseInt(roiP[n]), y: parseInt(roiP[n + 1]) };
          dataCoor.push(obj);
        }
        this.ROIcoordinates = dataCoor;
        //this.ROIcoordinates = obj;
      }
      this.openROI = false;
    },

    // * Config Alert
    getConfig() {
      axios({
        method: "POST",
        url: globals.APIURL + "org/get-org-config",
        data: {
          company_id: auth.get_asset_id()
        }
      }).then((res) => {
        if (res) {
          if (res.data.data != null) {
            this.securityAlert = res.data.data
            if (this.securityAlert.second_sender_data == undefined) {
              this.securityAlert.second_sender_data = {
                name: "",
                phone: "",
                email: "",
              }
            }
  
            if (this.securityAlert.third_sender_data == undefined) {
              this.securityAlert.third_sender_data = {
                name: "", 
                phone: "",
                email: ""
              }
            }
            console.log(this.securityAlert)
          }
        }
      }).catch((error) => {
        console.log(error)
      });
    },

    createAlertConfig(data) {
      let EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let PHONE_PATTERN = /^[0-9]*$/
      
      if (data.alert_msg && data.contact_data.phone && data.contact_data.email && data.sender_data.name && data.sender_data.phone && data.sender_data.email) {
        if (!EMAIL_PATTERN.test(data.contact_data.email)) {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "El correo electronico de contacto esta mal conformado"
        } else if (data.contact_data.phone.length != 10 || !PHONE_PATTERN.test(data.contact_data.phone)) {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "El número de contacto esta mal conformado"
        } else if (data.sender_data.phone.length != 10 || !PHONE_PATTERN.test(data.sender_data.phone)) {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "El número del remitente esta mal conformado"
        } else if (!EMAIL_PATTERN.test(data.sender_data.email)) {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "El correo electronico del remitente esta mal conformado"
        } else {
          let configuration = data;

          configuration.company_id =  auth.get_asset_id()
          
          axios({
            method: "POST",
            url: globals.APIURL + "org/create-org-config",
            data: {
              configuration: configuration
            }
          }).then((res) => {
            if (res.data && res.data.status == 200) {
              this.dialogSecurityAlert = false
              this.snackbar = true;
              this.snackbarColor = "success"
              this.snackbarText = "Se actualizó la configuración de alerta."
            }
          }).catch((error) => {
            console.log(error)
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarColor = "error";
        this.snackbarText = "Todos los campos son obligatorios."
      }
    }
  },

  mounted() {
    this.getConfig()
    this.tableConfigCam();
    setInterval(() => this.tableConfigCam(), 180000);
  },
};
</script>
<style>
.ctConfImgROI {
  position: relative;
}

.ctConfImgROI canvas {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
</style>