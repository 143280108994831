<template>
  <v-container>
    
    <div class="text-center" v-if="surveyModal == true">
      <v-dialog v-model="surveyModal" width="60%">
        <v-card>
          <v-overlay
            :value="overlay"
            absolute
            opacity="0.9"
          >
            <v-card color="primary" dark>
              <v-card-text>
                Generando archivo, por favor espere...
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-overlay>
          <v-card-title>
            <h5>Exportar datos de encuesta</h5>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="5">
                <!-- <label class="text-subtitle-1">Intervalo de fechas</label> -->
                <v-dialog
                  ref="dialog1"
                  v-model="rangeDateModal"
                  :return-value.sync="rangeDatesSurvey"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeTextSurvey"
                      prepend-icon="fal fa-calendar-day"
                      readonly
                      outlined
                      dense
                      height="44"
                      dark
                      class="primaryInput"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="rangeDatesSurvey"
                    no-title
                    scrollable
                    range
                    dark
                    @input="validateRangeDatesSurvey"
                    :max="maxDate"
                    locale="es-MX"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      rounded
                      large
                      outlined
                      class="px-4 mx-2 secondaryBtn"
                      @click="rangeDateModal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      rounded
                      color="primary"
                      large
                      class="px-4 mx-2 primaryBtn"
                      @click="selectDates"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="5">
                <!-- <label class="text-subtitle-1">Tipo de encuesta</label> -->
                <v-select
                  v-model="typeSurvey"
                  :items="surveyTypes"
                  item-text="title"
                  item-value="type"
                  label="Seleccione una opción"
                  outlined
                  dense
                  dark
                  color="secondary"
                  required
                  height="45"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2" class="d-flex justify-center">
                <v-btn
                  color="primary"
                  large
                  @click="generateSurveyFile(typeSurvey)"
                >
                  GENERAR
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-row class="text-center">
      <v-col cols="12">
        <p class="white--text text-h5">Histórico de Accesos</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" class="ctTablaCam" style="border-radius: 10px;">
          <v-row>
            <v-col
              cols="12"
              md="5"
            >
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="rangeDates"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Intervalo de fechas"
                    prepend-icon="fal fa-calendar-day"
                    readonly
                    outlined
                    dark
                    class="primaryInput"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="rangeDates"
                  no-title
                  scrollable
                  range
                  dark
                  @input="validateRangeDates"
                  :max="maxDate"
                  locale="es-MX"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    rounded
                    large
                    outlined
                    class="px-4 mx-2 secondaryBtn"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    rounded
                    color="primary"
                    large
                    class="px-4 mx-2 primaryBtn"
                    @click="searchByDates"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" :md="roleChecker.iHaveGrants(userRole, 'HISTORY-EXPORT-BTN') ? 6 : 7" class="text-center">
              <v-text-field
                v-model="search"
                append-icon="fal fa-search"
                label="Buscar por palabra clave..."
                outlined
                color="secondary"
                dark
                class="mb-5"
              ></v-text-field>
            </v-col>

            <v-col cols="12" :md="roleChecker.iHaveGrants(userRole, 'HISTORY-EXPORT-BTN') ? 1 : 0" class="text-center" v-if="roleChecker.iHaveGrants(userRole, 'HISTORY-EXPORT-BTN')">
              <v-btn
                color="success"
                x-large
                rounded
                block
                title="Exportar Historico"
                :disabled="entryList.length == 0"
                @click="generateReport"
              >
                <v-icon>fal fa-file-excel</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12">
              <v-layout child-flex>
                <v-data-table
                  :headers="headers"
                  :items="entryList"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [20],
                  }"
                  class="dtTable"
                  fixed-header
                  :loading="loading"
                  loading-text="Cargando Información..."
                  :no-results-text="`No se encontrarón ingresos por su dato de busqueda (${search})`"
                  no-data-text="No hay ingresos que mostrar"
                >
                  <template v-slot:[`item.ImgAccess`]="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          size="45"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-img
                            :src="getEntryPic(item)"
                            lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                          >
                          </v-img>
                        </v-avatar>
                      </template>
                      <v-img
                        :src="getEntryPic(item)"
                        width="100"
                        lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                      >
                      </v-img>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.asset`]="{ item }">
                    <div v-for="(zone, j) in sedes" :key="j">
                      {{ item.asset === zone.uuid ? zone.name : "" }}
                    </div>
                  </template>
                  <template v-slot:[`item.type_entry`]="{ item }">
                    <v-chip
                      class="small-chip ma-0"
                      :color="item.type_entry === 'ENTRY' ? 'primary' : 'red'"
                      text-color="white"
                      small
                    >
                      {{ item.type_entry === "ENTRY" ? "Acceso" : "Salida" }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.created_time`]="{ item }" >
                    {{
                      item.created_time.substring(0, 10)
                    }} - {{
                      item.created_time.substring(11, 16)
                    }}
                  </template>
                  <!-- <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-body-2">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                              size="45"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-img
                                :src="getEntryPic(item)"
                                lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                              >
                              </v-img>
                            </v-avatar>
                          </template>
                          <v-img
                            :src="getEntryPic(item)"
                            width="100"
                            lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                          >
                          </v-img>
                        </v-tooltip>
                      </td>
                      <td class="text-body-2">{{
                          item.document_number
                        }}
                      </td>
                      <td class="text-body-2">
                        {{
                          item.name
                        }}
                      </td>
                      <td class="text-body-2">
                        <div v-for="(zone, j) in sedes" :key="j">
                          {{ item.asset === zone.uuid ? zone.name : "" }}
                        </div>
                      </td>
                      <td class="text-body-2">
                        <v-chip
                          class="small-chip ma-0"
                          :color="item.type_entry === 'ENTRY' ? 'primary' : 'red'"
                          text-color="white"
                          small
                        >
                          {{ item.type_entry === "ENTRY" ? "Acceso" : "Salida" }}
                        </v-chip>
                      </td>
                      <td class="text-body-2">
                        {{
                          item.created_time.substring(0, 10)
                        }} - {{
                          item.created_time.substring(11, 16)
                        }}
                      </td>
                    </tr>
                  </template> -->
                </v-data-table>
              </v-layout>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-fab-transition v-if="validateOrg() && roleChecker.iHaveGrants(userRole, 'SURVEY-EXPORT-BTN')">
      <v-btn
        color="success darken-1"
        large
        fab
        dark
        bottom
        right
        fixed
        @click="cleanSurveyModal() ; surveyModal = true"
      >
        <i style="font-size: 25px;" class="fal fa-poll-people"></i>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import roles from "../services/role";
import xlsx from "xlsx";
import backlog from "../services/logs";

export default {
  name: "EntryHistory",
  // TODO: RekID Vacio '9e5bdaba-a9f6-4f26-b986-e3b06c1b409b' 
  data: () => ({
    loading: false,
    modal: false,
    roleChecker: roles,
    userRole: auth.getRole(),
    organizations: [],
    orgID: auth.getUserEnterpriseID(),
    entryList: [],
    entryListReport: [],
    reportAlreadyCreated: 0,
    rangeDates: [globals.COdate().substr(0, 10), globals.COdate().substr(0, 10)],
    search: "",
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    maxDate: "",
    headers: [
      { text: "Img. Acceso", sortable: false, align: "center", value: "ImgAccess" },
      { text: "CC", sortable: false, align: "center", value: "document_number" },
      { text: "Nombre", sortable: false, align: "center", value: "name" },
      { text: "Zona", sortable: false, align: "center", value: "asset" },
      { text: "Evento", sortable: false, align: "center", value: "type_entry" },
      { text: "Fecha - Hora", sortable: true, align: "center", value: "created_time" },
    ],
    surveyModal: false,
    surveyTypes: [
      {type: "colaborador", title: "Colaboradores"},
      {type: "tercero", title: "Terceros"}
    ],
    rangeDateModal: false,
    rangeDatesSurvey: [
      globals.COdate().substr(0, 10),
      globals.COdate().substr(0, 10),
    ],
    typeSurvey: "",
    surveyData: [],
    overlay: false,
    sedes: []// **NEW
  }),
  computed: {
    dateRangeText () {
      return this.rangeDates.join(' ~ ')
    },

    dateRangeTextSurvey () {
      return this.rangeDatesSurvey.join(' ~ ')
    }
  },
  methods: {
    validateOrg() {
      if (this.orgID == 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17') {
        return true
      }
    },

    selectDates() {
      this.$refs.dialog1.save(this.rangeDatesSurvey);
      this.rangeDateModal = false;
    },

    searchByDates() {
      this.$refs.dialog.save(this.rangeDates)
      this.modal = false
      this.getEntries()
    },

    cleanSurveyModal() {
      this.rangeDateModal = false,
      this.rangeDatesSurvey = [
        globals.COdate().substr(0, 10),
        globals.COdate().substr(0, 10),
      ],
      this.typeSurvey = "";
      this.surveyData = [];
    },

    getEntries() {
      this.loading = true
      this.entryList = []
      this.entryListReport = []
      this.reportAlreadyCreated = 0
      /* axios
        .post("https://ikdw6qev45.execute-api.us-east-1.amazonaws.com/Dev/entries", {
          //campus_id: auth.getSelectedSede(),
          //start_date: this.rangeDates[0] + "T00:00:00.000Z",
          //finish_date: this.rangeDates[1] + "T23:59:59.000Z"
          asset_key: auth.getSelectedSede(),
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          limit: null
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.entryList = res.data;
            this.entryListReport = res.data
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data == "No documents were found") {
            console.log("No hay documentos que mostrar!");
          } else {
            console.log(err);
          }
        }); */
        axios({// **NEW
        method: "POST",
        url: globals.APIURL + "entry/grouped-entries",
        data: {
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          limit: 20000,
          ids: auth.getGZ().zones
        },
      })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          this.entryList = res.data;
          this.entryListReport = res.data
          this.loading = false;
        } else {
          this.loading = false;
        }
      })
      .catch((err) => {
        this.loading = false;
        if (err.response.data == "No documents were found") {
          console.log("No hay documentos que mostrar!");
        } else {
          console.log(err);
        }
      });
    },

    restartEntries() {
      axios
        .post(globals.APIURL + "entry/fabd", {
          campus_id: auth.getSelectedSede(),
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z"
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.entryList = res.data;
            this.entryListReport = res.data
            console.log(res.data)
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data == "No documents were found") {
            console.log("No hay documentos que mostrar!");
          } else {
            console.log(err);
          }
        });
    },

    getEntryPic(item) {
      var formatURL = "";
      if (item.document_number !== "") {
        formatURL = globals.APIURL + "entry/daily-pic/" + item.entry_img;
      }
      return formatURL;
    },

    generateReport() {
      console.log(this.entryListReport)
      if (this.entryListReport.length > 0) {
        if (this.reportAlreadyCreated === 0) {
          this.entryListReport = this.entryListReport.filter(value => value.document_number !== "")

          for (let i = 0; i < this.entryListReport.length; i++) {
            const element = this.entryListReport[i];

            element.DocumentNumber = element.document_number
            element.FullName = element.name
            element.type_entry = element.type_entry == 'ENTRY' ? "ENTRADA" : "SALIDA"
            element.EntryDate = element.created_time.substring(0, 10)
            element.EntryTime = element.created_time.substring(11, 19)
            this.sedes.forEach(e => {// **NEW
              if (e.uuid == element.asset) {
                element.asset = e.name
              }
            });
            delete element.person_id
            delete element.device
            delete element.uuid
            delete element.type_person
            delete element.register_image
            delete element.document_number
            delete element.name
            delete element.created_time
            delete element.entry_img
          }
          this.reportAlreadyCreated = this.reportAlreadyCreated + 1
        }

        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet(this.entryListReport, {
          header: [
            "DocumentNumber",
            "FullName",
            "asset",
            "type_entry",
            "EntryDate",
            "EntryTime"
          ],
        });

        newWS["A1"].v = "CC";
        newWS["B1"].v = "NOMBRE";
        newWS["C1"].v = "ZONA";
        newWS["D1"].v = "TIPO";
        newWS["E1"].v = "FECHA";
        newWS["F1"].v = "HORA ACCESO";

        xlsx.utils.book_append_sheet(newWB, newWS, "Ingresos");

        xlsx.writeFile(
          newWB,
          "Reporte Vanguard Security Access de " + this.rangeDates[0] + " a " + this.rangeDates[1] + ".xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );
        
        this.snackbarText = "Se descargó su reporte de ingresos.";
        this.snackbarColor = "success";
        this.snackbar = true;
        //this.restartEntries()
        this.getEntries()
        backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "export/entries"})
      }
    },

    validateRangeDates(rangeDates) {
      if (rangeDates[0] && !rangeDates[1]) {
        let startDate = new Date(rangeDates[0])
        this.maxDate = new Date(startDate.setDate(startDate.getDate() + 6)).toISOString().substring(0, 10);
      } else {
        if (rangeDates[0] > rangeDates[1]) {
          this.snackbar = true;
          this.snackbarText = "La fecha final no puede ser menor que la inicial."
          this.snackbarColor = "error"
          this.rangeDates = []
          this.maxDate = ""
        }
      }
    },

    validateRangeDatesSurvey(rangeDates) {
      if (rangeDates[0] && !rangeDates[1]) {
        let startDate = new Date(rangeDates[0])
        this.maxDate = new Date(startDate.setDate(startDate.getDate() + 14)).toISOString().substring(0, 10);
      } else {
        if (rangeDates[0] > rangeDates[1]) {
          this.snackbar = true;
          this.snackbarText = "La fecha final no puede ser menor que la inicial."
          this.snackbarColor = "error"
          this.rangeDatesSurvey = []
          this.maxDate = ""
        }
      }
    },

    generateSurveyFile(typeSurvey) {
      if (this.rangeDatesSurvey[0] && this.rangeDatesSurvey[1] && typeSurvey) {
        this.overlay = true;
        axios
          .post(globals.APIURL + "forms/find-many-surveys", {
            company_id: auth.getUserEnterpriseID(),
            survey_type: typeSurvey,
            start_date: this.rangeDatesSurvey[0] + "T00:00:00.000Z",
            finish_date: this.rangeDatesSurvey[1] + "T23:59:59.000Z",
          })
          .then((res) => {
            if (res.status == 200 && !res.message) {
              if (res.data.length == 0) {
                this.snackbar = true;
                this.snackbarColor = "error"
                this.snackbarText = "No hay datos en las fechas seleccionadas"
                this.overlay = false;
              } else {
                backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "export-surveys/entries"})
                res.data.sort(function(a, b) {
                    var c = new Date(a.created_date);
                    var d = new Date(b.created_date);
                    return c-d;
                });
                this.surveyData = res.data
                this.createSurveyExportFile()
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.overlay = false;
          });
      } else {
        this.snackbar = true;
        this.snackbarColor = "error"
        this.snackbarText = "Todos los campos son obligatorios."
      }
    },

    createSurveyExportFile() {
      if (this.typeSurvey == "colaborador") {
        for (let i = 0; i < this.surveyData.length; i++) {
          const element = this.surveyData[i];
          
          delete element.company_id
          delete element.vehicle_and_driver_papers
          delete element.person_id
          delete element.vehicle_plate
          delete element.reason
          delete element.material_type
          delete element.vehicle_inspection
          delete element.company_you_work_for
          delete element.uuid
          delete element.vaccinated
          element.name = element.name.split(" ");
          element.name = element.name.map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(" ");
          element.created_date = element.created_date.substring(0, 10) + " / " + element.created_date.substring(11, 16);
          //element.vaccinated = element.vaccinated == true ? "SI" : "NO";
          element.symptoms = element.symptoms == true ? "SI" : "NO";
          if (element.mood) {
            if (Array.isArray(element.mood)) {
              element.mood = element.mood.join(' - ')
            }
          } else {
            element.mood = "N/R"
          }

          if (element.mood_reason) {
            if (Array.isArray(element.mood_reason)) {
              element.mood_reason = element.mood_reason.join(' - ')
            }
          } else {
            element.mood_reason = "N/R"
          }
        }

        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet(this.surveyData, {
          header: [
            "document_number",
            "name",
            "created_date",
            "place_access",
            "cellphone",
            "email",
            "work_type",
            "survey_type",
            "role",
            "mood",
            "mood_reason",
            //"vaccinated",
            "symptoms",
          ],
        });

        newWS["A1"].v = "NÚMERO DOCUMENTO";
        newWS["B1"].v = "NOMBRE";
        newWS["C1"].v = "FECHA / HORA";
        newWS["D1"].v = "REGIONAL";
        newWS["E1"].v = "CELULAR";
        newWS["F1"].v = "CORREO";
        newWS["G1"].v = "LUGAR DE TRABAJO";
        newWS["H1"].v = "TIPO ENCUESTA";
        newWS["I1"].v = "TIPO COLABORADOR";
        newWS["J1"].v = "ESTADO DE ÁNIMO";
        newWS["K1"].v = "RAZÓN ESTADO DE ANIMO";
        //newWS["L1"].v = "ESQUEMA COMPLETO";
        newWS["L1"].v = "ALGÚN SÍNTOMA";

        xlsx.utils.book_append_sheet(newWB, newWS, "Encuestas");

        xlsx.writeFile(
          newWB,
          "Reporte encuestas de salud " + this.rangeDatesSurvey[0] + " a " + this.rangeDatesSurvey[1] + ".xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );

        this.overlay = false;
        this.snackbar = true
        this.snackbarText = "Se generó el archivo!!"
        this.snackbarColor  = "success"
        this.cleanSurveyModal()
      }
    },

    getSedes() {// **NEW
      this.sedes = []
      axios
        .post(globals.APIURL + "org/fo", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.Campus.length > 0) {
            this.sedes = res.data.Campus;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getSedes()// **NEW
    this.getEntries()
  },
};
</script>
