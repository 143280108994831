import globals from '../globals'
import axios from 'axios';

const formatDate = () => {
    const date = new Date()
    const mm = date.toISOString().substring(5, 7);
    const dd = date.toISOString().substring(8, 10);
    const hh = date.getHours();
    const mnmn = date.getMinutes();
    const ss = date.getSeconds();
    const time = (hh > 9 ? '' : '0') + hh + ":" + (mnmn > 9 ? '' : '0') + mnmn + ":" + (ss > 9 ? '' : '0') + ss;

    return [dd, mm, date.getFullYear()].join('/') + " " + time;
}

const getIP = async () => {
    let IP = "0.0.0.0";

    let resIP = await axios.get('https://api.ipify.org?format=json')

    if (resIP.data && resIP.data.ip) {
        IP = resIP.data.ip
    }

    return IP;
}

const generateLog = async (info) => {
    info.creationDate = formatDate();
    info.ip = await getIP()
    await axios.post(globals.APIURL + "log/cl", info)
        .then(() => {
        })
        .catch((err) => {
            console.log(err);
        });
}

export default { generateLog, formatDate }