<template>
  <v-container fluid>
    <div v-if="deleteValidation">
      <v-dialog
        content-class="modalVs modalAlert"
        v-model="deleteValidation"
        persistent max-width="500"
      >
        <v-card>
          <v-card-title class="text-h5">
            Eliminar Registro
          </v-card-title>
          <v-card-text class="my-4 text-center">
            <i class="fal fa-exclamation-circle error--text ma-8" style="font-size: 100px;"></i>
            <br/><br/>
            <p>Está seguro que desea eliminar este centro de costo?</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              large
              outlined
              class="px-4"
              @click="deleteValidation = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="error"
              rounded
              large
              class="px-4"
              @click="deleteCostCenter(selectedCostCenter.uuid)"
            >
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
      
    <div v-if="dialog">
      <v-dialog
        content-class="modalVs"
        v-model="dialog"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title class="text-h5">
            Creación de Centro Costos
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newCostCenter.code"
                  label="Código"
                  outlined
                  dark
                  color="secondary"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newCostCenter.name"
                  label="Nombre"
                  outlined
                  dark
                  color="secondary"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              large
              outlined
              class="px-4"
              @click="dialog = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              large
              class="px-4"
              @click="createCostCenter(newCostCenter)"
            >
              Crear
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="dialogEdit">
      <v-dialog v-model="dialogEdit" content-class="modalVs" persistent max-width="500">
        <v-card>
          <v-card-title class="text-h5">
            Editar Centro Costos
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="selectedCostCenter.code"
                  label="Código"
                  outlined
                  dark
                  color="secondary"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="selectedCostCenter.name"
                  label="Nombre"
                  outlined
                  dark
                  color="secondary"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              large
              outlined
              class="px-4"
              @click="cancelEditMode(selectedCostCenter)"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              large
              class="px-4"
              @click="editCostCenter(selectedCostCenter)"
            >
              Editar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-row>
      <v-col cols="12">
        <p class="d-flex justify-center grey--text text-h5 pa-0">Configuración General</p>
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <v-data-table
                :headers="headers"
                :items="costCenters"
                :items-per-page="10"
                :header-props="{
                    mobile: true,
                }"
                :footer-props="{
                    'items-per-page-options': [15],
                }"
                class="dtTable"
                dense
                fixed-header
            >
            <template v-slot:top>
              <v-toolbar flat style="background: #272e3d;">
                <v-toolbar-title>
                    <div class="text-h6 text-center font-weight-bold secondary--text">Centro de Costos</div>
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn rounded dark color="primary" @click="dialog = true">
                    Crear
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.options`]="{ item }">
              <v-btn
                icon
                class="primary"
                @click="editMode(item)"
              >
                <i class="fal fa-edit"></i>
              </v-btn>
              <span class="mx-1"></span>
              <v-btn
                icon
                class="red"
                @click="deleteValidation = true ; selectedCostCenter = item"
              >
                <i class="fal fa-trash-alt"></i>
              </v-btn>
            </template>
            </v-data-table>
        </v-col>
        <v-col cols="6"></v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";

export default {
  name: "Configuration",
  data: () => ({
    //modal
    dialog: false,
    dialogEdit: false,
    deleteValidation: false,
    headers: [
      { text: "Código", align: "left", value: "code", sortable: true },
      { text: "Nombre", align: "left", value: "name", sortable: true },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    costCenters: [],
    newCostCenter: {
        code: "",
        name: "",
    },
    selectedCostCenter: {},
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
  }),
  methods: {
    editMode(item) {
        this.dialogEdit = true
        this.selectedCostCenter = item
        this._beforeEditingCache = Object.assign({}, item);
    },
    
    cancelEditMode(item) {
      this.dialogEdit = false
      this.selectedCostCenter = {}
      Object.assign(item, this._beforeEditingCache);
    },

    getCostCenters() {
      axios
        .post(globals.APIURL + "working-group/all-cost-center", {
          campus_id: auth.getSelectedSede()
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.costCenters = res.data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createCostCenter(obj) {
        console.log(obj)
        axios
            .post(globals.APIURL + "working-group/create-cost-center", {
                code: obj.code,
                name: obj.name,
                campus_id: auth.getSelectedSede(),
            })
            .then((res) => {
                if (res.data && res.data == "Cost center inserted successfuly") {
                    this.dialog = false
                    this.clearForm()
                    this.getCostCenters()
                    this.snackbarText = "El centro de costos fue creado.";
                    this.snackbarColor = "success";
                    this.snackbar = true;
                }
            })
            .catch((err) => {
                console.log(err);
            }); 
    },

    editCostCenter(costCenter) {
        axios
            .put(globals.APIURL + "working-group/edit-cost-center", {
                cost_center_id: costCenter.uuid,
                cost_center: {
                    code: costCenter.code,
                    name: costCenter.name
                },
            })
            .then((res) => {
                if (res.data && res.data == "Cost center updated successfuly") {
                    this.dialogEdit = false
                    this.selectedCostCenter = {};
                    this.getCostCenters()
                    this.snackbarText = "El centro de costos fue actualizado.";
                    this.snackbarColor = "success";
                    this.snackbar = true;
                }
            })
            .catch((err) => {
                console.log(err);
            }); 
    },

    deleteCostCenter(costCenterID) {
        axios
            .post(globals.APIURL + "working-group/delete-cost-center", {
                cost_center_id: costCenterID
            })
            .then((res) => {
                if (res.data && res.data == "Cost center deleted successfuly") {
                    this.deleteValidation = false
                    this.selectedCostCenter = {};
                    this.getCostCenters()
                    this.snackbarText = "El centro de costos fue eliminado.";
                    this.snackbarColor = "error";
                    this.snackbar = true;
                }
            })
            .catch((err) => {
                console.log(err);
            }); 
    },

    getSede() {
      axios
        .post(globals.APIURL + "campus/foc", {
          campus_id: auth.getSelectedSede(),
        })
        .then((res) => {
          if (res.data) {
            this.generalCampus = res.data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    clearForm() {
        this.newCostCenter.name = "";
        this.newCostCenter.code = "";
    },
  },
  created() {
    this.getSede()
    this.getCostCenters()
  },
};
</script>
<style>
</style>

