<template>
  <v-container>
    <div class="text-center" v-if="dialog == true">
      <v-dialog v-model="dialog" width="1000">
        <v-card>
          <v-card-title
            class="black--text headline grey lighten-2 d-flex justify-center"
          >
            USUARIO CON SÍNTOMAS
          </v-card-title>
          <v-card-text>
            <v-row class="pl-4 pr-4">
              <v-col cols="12" md="4">
                <p>
                  Fecha: <strong>{{ userWithSymptoms.creationDate.substring(0, 10) }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <p>
                  Hora: <strong>{{ userWithSymptoms.creationDate.substring(11, 16) }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <p>
                  CC: <strong>{{ userWithSymptoms.cc }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <p>
                  Nombre: <strong>{{ userWithSymptoms.name }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <p>
                  Cel: <strong>{{ userWithSymptoms.cellphone }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <p>
                  Email: <strong>{{ userWithSymptoms.email }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <p>
                  T. Trabajo: <strong>{{ userWithSymptoms.workType }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <p>
                  Empresa: <strong>{{ userWithSymptoms.enterprise }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="12">
                <p>
                  Síntomas: 
                </p>
                <p v-for="(item, i) in userWithSymptoms.symptoms" :key="i">
                  <strong>- {{item}}</strong>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-row class="text-center">
      <v-col cols="12">
        <p class="grey--text font-weight-bold text-h3">FORMULARIOS COVID-19</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="pb-0 mb-0">
        <v-menu
          v-model="datepicker1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Fecha Inicial"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            @input="datepicker1 = false"
            locale="es-MX"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6" class="pb-0 mb-0">
        <v-menu
          v-model="datepicker2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="finishDate"
              label="Fecha Final"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="finishDate"
            @input="datepicker2 = false"
            locale="es-MX"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="12">
        <v-select
          v-model="org"
          :items="organizations"
          item-text="title"
          label="Sede"
          return-object
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-btn
          color="primary"
          block
          small
          :disabled="org != null ? false : true"
          @click="
            getForms(org, startDate, finishDate);
          "
        >
          <v-icon dark> mdi-magnify </v-icon> FILTRAR
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-btn
          color="success"
          block
          small
          :disabled="itemsList && itemsList.length > 0 ? false : true"
          @click="generateReport(itemsList, org)"
        >
          <v-icon dark> mdi-microsoft-excel </v-icon> EXPORTAR FORMULARIOS
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-row v-if="itemsList && itemsList.length > 0">
            <v-col cols="9">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Filtrar registros por Cedula o Nombre"
                single-line
                hide-details
                class="mb-5"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="d-flex justify-center">
              <v-checkbox
                v-model="checkSort"
                label="Con síntomas"
                @click="sortWithSymptoms(checkSort)"
              ></v-checkbox>  
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12" v-if="!itemsList"><p>Filtre sus datos.</p></v-col>
            <v-col cols="12" v-if="itemsList && itemsList.length == 0">
              <p v-if="loading == false">No hay datos que mostrar.</p>
              <p v-if="loading == true">
                <v-progress-circular
                :size="50"
                color="primary"
                :indeterminate="loading"
              ></v-progress-circular>
              </p>
            </v-col>
            <v-col cols="12" v-if="itemsList && itemsList.length > 0">
              <v-layout child-flex>
                <v-data-table
                  :headers="headers"
                  :items="itemsList"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [15],
                  }"
                  class="elevation-1"
                  dense
                  fixed-header
                  no-results-text="No hay datos con la Cedula o Nombre ingresados. "
                >
                  <template v-slot:[`item.creationDate`]="{ item }">
                    {{ item.creationDate.substring(0, 10) }}
                  </template>
                  <template #[`item.horaIngreso`]="{ item }">
                    {{ item.creationDate.substring(11, 16) }}
                  </template>
                  <template #[`item.status`]="{ item }">
                    <div v-if="item.isPositive">Con Síntomas</div>
                    <div v-if="!item.isPositive">Sin Síntomas</div>
                  </template>
                  <template v-slot:[`item.options`]="{ item }">
                    <v-icon
                      :disabled="!item.isPositive"
                      class="text-center"
                      @click="
                        dialog = true;
                        userWithSymptoms = item;
                      "
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                </v-data-table>
              </v-layout>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import backlog from "../services/logs";
import xlsx from "xlsx";

export default {
  name: "Forms",

  data: () => ({
    loading: false,
    checkSort: false,
    dialog: false,
    userWithSymptoms: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    organizations: [],
    userOrgName: auth.getUserEnterpriseName(),
    org: null,
    startDate: new Date().toISOString().substr(0, 10),
    finishDate: new Date().toISOString().substr(0, 10),
    datepicker1: false,
    datepicker2: false,
    enterprise: "",
    itemsList: null,
    search: "",
    headers: [
      { text: "CC", align: "start", value: "cc" },
      { text: "Nombre", align: "start", value: "name" },
      { text: "Organización", align: "start", value: "enterprise" },
      { text: "Fecha", align: "start", value: "creationDate" },
      { text: "Hora", align: "start", value: "horaIngreso", sortable: false },
      { text: "Estado", align: "start", value: "status", sortable: false },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
  }),
  methods: {
    getOrganizations() {
      axios
        .get(globals.APIURL + "org/fa")
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.fillSedes(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fillSedes(orgs) {
      orgs.forEach((element) => {
        if (element.name == this.userOrgName) {
          this.organizations.push(element);
        }
      });
    },

    getForms(org, startDate, finishDate) {
      this.loading = true;
      this.itemsList = [];
      axios
        .post(globals.APIURL + "forms/fbd", {
          sede: org.name,
          StartDate: globals.DDMMYYYYstring(new Date(startDate)) + " 00:00:00",
          FinishDate: globals.DDMMYYYYstring(new Date(finishDate)) + " 23:59:59",
          filter: {
            sede: org._id,
          },
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.itemsList = res.data;
            if (res.data.length > 0) {
              this.loading = false;
            } else {
              this.loading = false;
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    generateReport(jsonContent, org) {
      if (org && jsonContent.length > 0) {
        for (let i = 0; i < jsonContent.length; i++) {
          const element = jsonContent[i];
          if (element.isPositive == true) {
            element.hasSymptoms = "SI";
            element.symptomsList = element.symptoms.toString();
          } else {
            element.hasSymptoms = "NO";
            element.symptomsList = "NO APLICA";
          }
          delete element._id;
          delete element.sede;
          delete element.isPositive;
          delete element.symptoms;
        }

        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet(jsonContent, {
          header: [
            "cc",
            "name",
            "cellphone",
            "email",
            "workType",
            "enterprise",
            "creationDate",
            "hasSymptoms",
            "symptomsList",
          ],
        });

        newWS["A1"].v = "CC";
        newWS["B1"].v = "NOMBRE";
        newWS["C1"].v = "CELULAR";
        newWS["D1"].v = "EMAIL";
        newWS["E1"].v = "TIPO TRABAJO";
        newWS["F1"].v = "EMPRESA";
        newWS["G1"].v = "FECHA - HORA";
        newWS["H1"].v = "TIENE SÍNTOMAS";
        newWS["I1"].v = "SÍNTOMAS";

        xlsx.utils.book_append_sheet(newWB, newWS, "Ingresos");

        xlsx.writeFile(
          newWB,
          "Formularios COVID-19 " +
            org.title +
            " " +
            globals.DDMMYYYY(new Date(), "-") +
            ".xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );

        this.snackbarText = "Se descargó su reporte de formularios.";
        this.snackbarColor = "blue";
        this.snackbar = true;
        this.itemsList = null;
        this.org = null;
        this.startDate = new Date().toISOString().substr(0, 10);
        this.finishDate = new Date().toISOString().substr(0, 10);
        backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "export/forms"})
      }
    },

    sortWithSymptoms(check) {
      if (check == true) {
        globals.sortArray(this.itemsList, "isPositive", "desc")
      } else {
        globals.sortArray(this.itemsList, "creationDate", "asc")  
      }
    }
  },
  mounted() {
    this.getOrganizations();
  },
};
</script>
