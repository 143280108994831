<template>
  <v-container fluid>

    <div class="text-center" v-if="modalStatus"> <!-- v-if="exampleModal && exampleModal.length > 0"> -->
      <v-dialog v-model="modalStatus" content-class="modalVs" width="1000" persistent no-click-animation :retain-focus="false">
         <!-- style="top:10%;width='85%'" :style="'margin-left:' + (i * 10) + 'px;margin-top:' + (i * 10) + 'px;z-index:' + ((i + 1) * 1000)" -->
        <div v-for="(event, i) in exampleModal" :key="i">
        <v-card v-if="event.status">
          <v-card-title>
            <v-row class="ma-0 pa-0">
              <v-col cols="1" class="d-flex justify-left ma-0 pa-0">
                <v-btn class="mx-2"
                  fab
                  small
                  outlined
                  color="black"
                  style="pointer-events: none; color: white!important">
                  <label class="h6-text white--text">{{i+1}}</label>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="10" class="d-flex justify-center align-center ma-0 pa-0">
                <p class="text-h5">
                  REGISTRAR {{ event.mode === 'ENTRY' ? 'INGRESO' : 'SALIDA' }}
                </p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="1" class="d-flex justify-center ma-0 pa-0">
                <v-btn icon color="white" title="Minimizar evento" @click="closeOne(i)">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-btn icon color="white" title="Minimizar evento" @click="closeAllPopups">
                  <v-icon>mdi-collapse-all</v-icon>
                </v-btn>
                <v-btn icon color="red lighten-2" title="Desechar evento" @click="discardEvent(event, i)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                :md="event.image[1] !== '' ? 6 : 12"
              >
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" class="d-flex justify-center ma-0 pa-0">
                    <p class="text-center text-h6 font-weight-light pb-2 secondary--text">Vehículo</p>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center ma-0 pa-0">
                    <v-img
                      class="rounded-lg"
                      :src="getVehicleImg(event.image[0])"
                      height="200"
                      contain
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="event.image[1] !== ''"
                cols="12"
                md="6"
              >
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" class="d-flex justify-center ma-0 pa-0">
                    <p class="text-center text-h6 font-weight-light pb-2 secondary--text">Placa</p>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center ma-0 pa-0">
                    <v-img
                      class="rounded-lg"
                      :src="getVehicleImg(event.image[1])"
                      height="200"
                      contain
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-row>
                  <v-col cols="6" class="pb-0">
                    <v-img
                      src="../assets/vehicle.png"
                      height="50"
                      contain
                    ></v-img>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <v-img
                      src="../assets/camioneta.png"
                      height="50"
                      contain
                    ></v-img>
                  </v-col>
                  <v-col cols="6" class="d-flex justify-center pt-0">
                    <v-checkbox
                      v-model="event.Vehicle"
                      label="Automovil"
                      color="secondary"
                      value="VEHICLE"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" class="d-flex justify-center pt-0">
                    <v-checkbox
                      v-model="event.Vehicle"
                      label="Camioneta"
                      color="secondary"
                      value="TRUCK"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="event.Destination"
                      :items="registry"
                      return-object
                      label="Responsable"
                      :filter="customFilter"
                      outlined
                      dark
                      color="secondary"
                      dense
                      :loading="loadingResponsable"
                      loader-height="4"
                      :disabled="loadingResponsable"
                      no-data-text="No hay datos que mostrar"
                    >
                      <template v-slot:selection="{ item }">
                        <span>{{
                          item.first_name +
                          " " +
                          (item.second_name ? item.second_name +
                            " " : "") +
                          item.first_last_name +
                          " " +
                          item.second_last_name
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <p>
                          {{
                            item.first_name +
                            " " +
                            (item.second_name ? item.second_name +
                            " " : "") +
                            item.first_last_name +
                            " " +
                            item.second_last_name
                          }}
                        </p>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="event.plate_ocr"
                      class="uppertext-field"
                      label="Placa del vehiculo."
                      hide-details
                      outlined
                      dark
                      dense
                      color="secondary"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"  class="d-flex justify-center">
                <v-btn
                  block
                  color="secondary"
                  rounded
                  large
                  class="px-4"
                  :loading="loadingBtns"
                  :disabled="
                    loadingBtns === false 
                      ? false
                      : true
                  "
                  @click="generateVisit(event, i)"
                >
                  Generar y abrir talanquera
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </div>
      </v-dialog>
    </div>

    <div class="text-center" v-if="filterModal === true">
      <v-dialog
        v-model="filterModal"
        max-width="1100"
        content-class="modalVs"
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Filtro de eventos</span>
          </v-card-title>
          <v-card-text>
              <!-- // TODO: Contadores, de pronto se usan luego. 
                <v-row class="mx-10">
                <v-col cols="12" md="5" sm="5" class="ma-0 pa-0 text-center">
                  <v-list two-line class="ma-0 pa-0" style="background: #31394C !important;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold text-body-2"
                          ><strong class="font-weight-bold text-body-2">Entradas</strong>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="secondary--text pr-0 mr-0 text-h4">{{
                            dailyEntries.length
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="5" sm="5" class="ma-0 pa-0 text-center">
                  <v-list two-line class="ma-0 pa-0" style="background: #31394C !important;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><strong class="font-weight-bold text-body-2">Salidas</strong>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="red--text pr-0 mr-0 text-h4">{{
                            dailyExits.length
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="2" sm="2" class="ma-0 pa-0 text-center">
                  <v-list two-line class="ma-0 pa-0" style="background: #31394C !important;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon :color="exampleModal.length > 0 ? 'success' : 'black'" v-bind="attrs" v-on="on" @click="getPopups" :disabled="exampleModal.length == 0">
                                <v-icon v-if="exampleModal.length > 0">mdi-bell-ring</v-icon>
                                <v-icon v-else>mdi-bell</v-icon>
                              </v-btn>
                            </template>
                            <span v-if="exampleModal.length > 0"><strong>{{ exampleModal.length }}</strong> eventos sin resolver</span>
                          </v-tooltip>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row> -->
            <div class="ctModal">
              <div class="ctSearchLog mb-3 mt-5">
                <div>
                  <v-menu
                    v-model="datepicker1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Desde*"
                        readonly
                        outlined
                        dark
                        color="secondary"
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @input="datepicker1 = false"
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div>
                  <v-menu
                    v-model="datepicker2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="finishDate"
                        label="Hasta*"
                        readonly
                        outlined
                        dark
                        color="secondary"
                        hide-details="auto"
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="finishDate"
                      @input="datepicker2 = false"
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div style="display: none;"> <!-- // TODO: Quitar este estilo para esconder la busqueda avanzada hasta su implementacion en version 2.0 -->
                <div class="text-center">
                  <v-btn
                    color="secondary"
                    rounded
                    large
                    class="px-4 my-4"
                    @click="advancedQuery = !advancedQuery"
                  >
                    Consulta avanzada <i class="fal fa-angle-down ml-3"></i>
                  </v-btn>
                </div>
                <div v-if="advancedQuery">
                  <hr />
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-text-field
                          v-model="bodyAdvancedQuery.plate"
                          label="Placa"
                          outlined
                          dark
                          dense
                          color="secondary"
                          hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="bodyAdvancedQuery.typeEvent"
                        label="Tipo Evento"
                        item-text="name"
                        item-value="key"
                        :items="typeEvents"
                        chips
                        multiple
                        outlined
                        dark
                        dense
                        color="secondary"
                        item-color="secondary"
                        hide-details="auto"
                        placeholder="Todos"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="bodyAdvancedQuery.typeEvent.length === typeEvents.length && index === 0">
                            <span>TODOS</span>
                          </v-chip>
                          <v-chip v-if="bodyAdvancedQuery.typeEvent.length < typeEvents.length">
                            <span>{{ item.name }}</span>
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <hr/>
              <div class="text-center">
                <v-btn
                  color="primary"
                  rounded
                  large
                  class="px-4 my-4"
                  @click="detailedFilter(startDate, finishDate)"
                >
                  Buscar
                </v-btn>
              </div>
              <!-- <hr v-if="noContent || loadingModalTable" />
              <div v-if="loadingModalTable" class="text-center mt-5">
                <v-progress-circular
                  :width="4"
                  color="grey"
                  indeterminate
                ></v-progress-circular>
              </div>
              <p
                v-if="noContent"
                class="no-content py-2"
              >
                No hay registros en el periodo de fecha seleccionada
              </p> -->
              <!-- // TODO: data-table  -->
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Filtrar Eventos"
                single-line
                outlined
                dark
                color="secondary"
              ></v-text-field>
              <v-data-table
                :headers="headers"
                :items="itemsList"
                :search="search"
                :items-per-page="10"
                :header-props="{
                  mobile: true,
                }"
                :footer-props="{
                  'items-per-page-options': [10],
                }"
                class="dtTable"
                :loading="loadingReport"
                loading-text="Cargando Información..."
                no-data-text="No hay datos que mostrar"
                dense
              >
                <template v-slot:item="{ item }">
                  <tr class="mx-0 px-0" :style="'border-left: 5px solid '+ asignActionColor(item.action)[1]">
                    <td class="text-body-2 text-center">
                      <small>{{
                        item.vehicle ? item.vehicle.owner.document_number : '---'
                      }}</small>
                    </td>
                    <td class="text-body-2 text-center">
                      <small>{{
                        item.vehicle ? item.vehicle.owner.first_name +
                        " " +
                        item.vehicle.owner.first_last_name : '---'
                      }}</small>
                    </td>
                    <td class="text-body-2 text-center">
                      <small>{{
                        item.vehicle ? item.vehicle.type_access : '---'
                      }}</small>
                    </td>
                    <td class="text-body-2 text-center">
                      <small>{{ item.plate_ocr ? item.plate_ocr : '---' }}</small>
                    </td>
                    <td class="text-body-2 text-center">
                      <small>{{ item.vehicle ? item.vehicle.plate : '---' }}</small>
                    </td>
                    <td class="text-body-2 text-center">
                      <v-chip
                        v-if="item.action != 'OVERRIDE'"
                        class="small-chip ma-0"
                        :color="item.mode === 'ENTRY' ? 'primary' : 'red'"
                        text-color="white"
                        x-small
                        label
                      >
                        {{ item.mode === "ENTRY" ? "ACCESO" : "SALIDA" }}
                      </v-chip>
                      <small v-if="item.action === 'OVERRIDE'">{{ '---' }}</small>
                    </td>
                    <td class="text-body-2 text-center">
                      <small>{{
                        item.created_at.substring(0, 10)
                      }}</small>
                    </td>
                    <td class="text-body-2 text-center">
                      <small>{{
                        item.created_at.substring(11, 16)
                      }}</small>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-btn
                color="success"
                rounded
                block
                class="px-4 mt-4"
                :disabled="itemsList.length > 0 ? false : true"
                :loading="loadingBtns && !visitorModal"
                @click="generateReport(itemsList)"
              >
                <v-icon class="mr-2">fal fa-file-excel</v-icon> Exportar ingresos
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-row>
      <v-col cols="12" md="5" class="py-0 my-0">
        <v-row class="full-height">
          <!-- --------------------------- DETALLE VHICULO --------------------------- -->
          <v-col cols="12">
            <v-card elevation="24" class="full-height" style="border-radius: 10px;">
              <v-row
                v-if="lastItems && lastItems.length > 0 && carSelected"
                class="full-height d-flex justify-center align-center px-3 py-3"
              >
                <v-col cols="12" md="6" sm="6">
                  <p class="text-center text-h6 font-weight-light pb-2 secondary--text">VEHÍCULO</p>
                  <v-card elevation="0" outlined>
                    <v-img
                      :src="getVehicleImg(carSelected.image[0])"
                      max-height="250px"
                      contain
                      lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                    >
                      <div class="d-flex justify-end">
                        <v-img
                          :src="getVehicleImg(carSelected.image[1])"
                          max-height="100px"
                          max-width="110px"
                          contain
                          lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                        >
                        </v-img>
                      </div>
                    </v-img>
                    <v-card-text class="pa-0 ma-0 my-3">
                      <v-row>
                        <v-col cols="6" class="d-flex justify-center pa-1 ma-0">
                          <v-chip
                            class="ma-2 backgroundSecondary--text"
                            style="font-weight: 600;"
                            color="warning"
                            label
                          >
                            {{ carSelected.vehicle.plate }}
                          </v-chip>
                        </v-col>
                        <v-col cols="6" class="pa-0 ma-0">
                          <p class="text-center mb-0">
                            <v-icon class="mb-2 mr-2 pt-1" style="color: #ffffffb3; font-size: 1.3rem;">
                              {{
                                carSelected.vehicle.type === "TRUCK"
                                  ? "fal fa-truck-pickup"
                                  : "fal fa-car-side"
                              }}
                            </v-icon>
                            {{
                              carSelected.vehicle.type === "TRUCK"
                                ? "Camioneta"
                                : "Automovil"
                            }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="6"
                          class="d-flex justify-center align-center mb-0 pb-0"
                          title="NOMBRE CONDUCTOR"
                        >
                          <p class="text-subtitle-1">
                            <v-icon class="mb-2 mr-2 pt-1" style="color: #ffffffb3; font-size: 1.3rem;">
                              fal fa-clock
                            </v-icon>
                            {{ carSelected.created_at.slice(11, 16) }}
                          </p>
                        </v-col>
                        <v-col
                          cols="6"
                          class="d-flex justify-center mb-0 pb-0"
                          title="TIPO DE EVENTO"
                        >
                          <v-chip
                            class="ma-0"
                            :color="
                              carSelected.mode === 'ENTRY' ? 'primary' : 'red'
                            "
                            text-color="white"
                          >
                            {{
                              carSelected.mode === "ENTRY" ? "Acceso" : "Salida"
                            }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <p class="text-center text-h6 font-weight-light pb-2 secondary--text">CONDUCTOR</p>
                  <v-card elevation="0" outlined>
                    <v-img
                      :src="PSRegistryImg"
                      max-height="250px"
                      contain
                      lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                      @error="imgErrorHandler"
                    >
                    </v-img>
                    <v-card-text class="pa-0 ma-0 my-2">
                      <v-row>
                        <v-col
                          cols="12"
                          class="text-center pa-0 ma-0"
                          title="DOCUMENTO CONDUCTOR"
                        >
                          <span class="mb-0">
                            <v-icon class="mb-2 mr-2 pt-1" style="color: #ffffffb3; font-size: 1.3rem;">
                              fal fa-address-card
                            </v-icon>
                            {{ carSelected.vehicle.owner.document_number }}
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          class="text-center pa-0 ma-0"
                          title="NOMBRE CONDUCTOR"
                        >
                          <span class="mb-0">
                            <v-icon class="mb-2 mr-2 pt-1" style="color: #ffffffb3; font-size: 1.2rem;">
                              fal fa-user-alt
                            </v-icon>
                            {{
                              carSelected.vehicle.owner.first_name +
                              " " +
                              carSelected.vehicle.owner.first_last_name
                            }}
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          class="text-center ma-0 pa-0"
                          title="TIPO DE USUARIO"
                        >
                          <span class="text-subtitle-1"> {{ carSelected.vehicle.type_access ? carSelected.vehicle.type_access : 'VISITANTE' }} </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0 text-center">
                  <v-btn
                    color="primary"
                    rounded
                    class="px-4"
                    @click="goToDetailedProfile(carSelected.vehicle)"
                  >
                    Ver perfil detallado del conductor
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="lastItems && lastItems.length > 0 && !carSelected"
                class="full-height"
              >
                <v-col cols="12" class="d-flex justify-center align-center">
                  <div id="ctExpCardInf" style="width: 100%">
                    <p class="txtExpCardInf">
                      Seleccionar un registro para visualizar toda su información
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row
                v-if="!lastItems || lastItems.length === 0"
                class="full-height"
              >
                <v-col cols="12" class="d-flex justify-center align-center">
                  <div id="ctExpCardInf" style="width: 100%">
                    <p class="txtExpCardInf">
                      Seleccionar un registro para visualizar toda su información
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- ---------------------------- TAKE PICS ------------------------------ -->
          <!-- <v-col cols="12">
            <v-card elevation="24" class="full-height d-flex align-center" style="border-radius: 10px;">
              <v-row v-for="(item, i) in devices" :key="i" class="text-center">
                <v-col cols="12" md="4" class="d-flex justify-center align-center">
                  <span class="text-wrap text-body-2 font-weight-bold">Lugar: {{ item.name }}</span>
                </v-col>
                <v-col cols="12" md="4" class="d-flex justify-center align-center">
                  <v-btn
                    color="primary"
                    rounded
                    large
                    class="px-4"
                    @click="sendMsgToBroker(item.type_device + '/' + item.mac + staticEndTopic, 'TAKE_PIC')"
                  >
                    <v-icon class="mr-2">fal fa-cars</v-icon>GENERAR INGRESO
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4" class="d-flex justify-center align-center">
                  <v-btn
                    color="error"
                    rounded
                    large
                    class="px-4"
                    @click="sendMsgToBroker(item.type_device + '/' + item.mac + staticEndTopic, 'TAKE_PIC_2')"
                  >
                    <v-icon class="mr-2">fal fa-cars</v-icon>GENERAR SALIDA
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col> -->
          <!-- --------------------------- LISTA Y REPORTE --------------------------- -->
          <v-col cols="12">
            <v-card elevation="24" class="full-height" style="border-radius: 10px;">
              <!-- <v-row class="mx-10">
                <v-col cols="12" md="5" sm="5" class="ma-0 pa-0 text-center">
                  <v-list two-line class="ma-0 pa-0" style="background: #31394C !important;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold text-body-2"
                          ><strong class="font-weight-bold text-body-2">Entradas</strong>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="secondary--text pr-0 mr-0 text-h4">{{
                            dailyEntries.length
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="5" sm="5" class="ma-0 pa-0 text-center">
                  <v-list two-line class="ma-0 pa-0" style="background: #31394C !important;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><strong class="font-weight-bold text-body-2">Salidas</strong>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="red--text pr-0 mr-0 text-h4">{{
                            dailyExits.length
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="2" sm="2" class="ma-0 pa-0 text-center">
                  <v-list two-line class="ma-0 pa-0" style="background: #31394C !important;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon :color="exampleModal.length > 0 ? 'success' : 'black'" v-bind="attrs" v-on="on" @click="getPopups" :disabled="exampleModal.length == 0">
                                <v-icon v-if="exampleModal.length > 0">mdi-bell-ring</v-icon>
                                <v-icon v-else>mdi-bell</v-icon>
                              </v-btn>
                            </template>
                            <span v-if="exampleModal.length > 0"><strong>{{ exampleModal.length }}</strong> eventos sin resolver</span>
                          </v-tooltip>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row> -->
              <v-row class="my-0 mx-3 py-5">
                <v-col md="6" class="pa-0">
                  <button class="btnOne" @click="filterModal = true">
                    <i class="fal fa-table"></i> Filtro detallado
                  </button>
                </v-col>
                <v-col md="3" class="pa-0 d-flex align-items-center">
                  <strong class="secondary--text pr-0 mr-0 text-h5"> {{
                    dailyEntries.length
                  }} <span style="color: #68789e">Entradas </span> </strong>
                </v-col>
                <v-col md="3" class="pa-0 d-flex align-items-center">
                  <strong class="red--text pr-0 mr-0 text-h5"> {{
                    dailyExits.length
                  }} <span style="color: #68789e"> Salidas </span></strong>
                </v-col>
              </v-row>
              <v-row class="mx-3 text-center">
                <!-- <v-col cols="12" md="5" sm="6" class="pb-0 mb-0 mb-4">
                  <v-menu
                    v-model="datepicker1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Fecha Inicial"
                        prepend-icon="fal fa-calendar-day"
                        readonly
                        outlined
                        dark
                        color="secondary"
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @input="datepicker1 = false"
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="5" sm="6" class="pb-0 mb-0 mb-4">
                  <v-menu
                    v-model="datepicker2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="finishDate"
                        label="Fecha Final"
                        prepend-icon="fal fa-calendar-day"
                        readonly
                        outlined
                        dark
                        color="secondary"
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="finishDate"
                      @input="datepicker2 = false"
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" class="d-flex align-center pb-0 mb-0 mb-4">
                  <v-btn
                    color="primary"
                    rounded
                    large
                    class="px-4"
                    @click="getEntriesToExport(startDate, finishDate)"
                  >
                    <v-icon dark>fal fa-search</v-icon>
                  </v-btn>
                </v-col> -->
                
                <v-col cols="12" class="pt-0 mt-0">
                  <v-data-table
                    :headers="headers"
                    :items="itemsListDash.slice(0, 10)"
                    :items-per-page="5"
                    :header-props="{
                      mobile: true,
                    }"
                    :footer-props="{
                      'items-per-page-options': [10],
                    }"
                    hide-default-footer
                    class="dtTable elevation-1"
                    no-data-text="No hay datos que mostrar"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <tr class="mx-0 px-0" :style="'border-left: 5px solid '+ asignActionColor(item.action)[1]">
                        <td class="text-body-2">
                          <small>{{
                            item.vehicle ? item.vehicle.owner.document_number : '---'
                          }}</small>
                        </td>
                        <td class="text-body-2">
                          <small>{{
                            item.vehicle ? item.vehicle.owner.first_name +
                            " " +
                            item.vehicle.owner.first_last_name : '---'
                          }}</small>
                        </td>
                        <td class="text-body-2">
                          <small>{{
                            item.vehicle ? item.vehicle.type_access : '---'
                          }}</small>
                        </td>
                        <td class="text-body-2">
                          <small>{{ item.plate_ocr ? item.plate_ocr : '---' }}</small>
                        </td>
                        <td class="text-body-2">
                          <small>{{ item.vehicle ? item.vehicle.plate : '---' }}</small>
                        </td>
                        <td class="text-body-2">
                          <v-chip
                            v-if="item.action != 'OVERRIDE'"
                            class="small-chip ma-0"
                            :color="item.mode === 'ENTRY' ? 'primary' : 'red'"
                            text-color="white"
                            x-small
                            label
                          >
                            {{ item.mode === "ENTRY" ? "ACCESO" : "SALIDA" }}
                          </v-chip>
                          <small v-if="item.action === 'OVERRIDE'">{{ '---' }}</small>
                        </td>
                        <td class="text-body-2">
                          <small>{{
                            item.created_at.substring(0, 10)
                          }}</small>
                        </td>
                        <td class="text-body-2">
                          <small>{{
                            item.created_at.substring(11, 16)
                          }}</small>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- <v-btn
                    color="success"
                    rounded
                    large
                    block
                    class="px-4 mt-4"
                    :disabled="itemsList.length > 0 ? false : true"
                    :loading="loadingBtns && !visitorModal"
                    @click="generateReport(itemsList)"
                  >
                    <v-icon class="mr-2">fal fa-file-excel</v-icon> Exportar ingresos
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
        <!-- --------------------------- LISTADO DE ACCESOS --------------------------- -->
      <v-col cols="12" md="7">
        <v-card elevation="0" class="full-height px-0" style="background: #212734 !important">
          <v-card-title
            v-if="lastItems && lastItems.length > 0"
            class="d-flex justify-center font-weight-light grey--text"
            >EVENTOS VSPARK</v-card-title
          >
          <v-row
            v-if="
              !lastItems || (lastItems.length === 0 && loadingEntries === true)
            "
            class="full-height"
          >
            <v-col v-for="(item, i) in 6" :key="i" cols="12" md="4">
              <v-skeleton-loader
                width="200px"
                type="image, list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row
            v-if="
              !lastItems || (lastItems.length === 0 && loadingEntries === false)
            "
            class="full-height"
          >
            <v-col cols="12" class="d-flex justify-center align-center">
              <v-row>
                <v-col class="text-center">
                  <v-icon x-large color="grey" style="font-size: 70px">
                    mdi-car-info
                  </v-icon>
                  <p class="text-h6 mt-5" style="color: #9e9e9e">
                    AÚN NO HAY EVENTOS EL DÍA DE HOY
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="cards-box" v-if="lastItems && lastItems.length > 0">
            <div id="ctCardInf">
            <div
              v-for="(item, i) in lastItems"
              :key="i"
              class="ctCardInf"
              :id="item.uuid"
              @click="selectCar(item)"
            >
              <div>
                <img :src="getVehicleImg(item.image[0])" />
              </div>
              <div :style="item.action === 'OVERRIDE' || item.action === 'DISCARD' ? 'display: none' : ''">
                <div class="text-center">
                  <span>
                    <p>
                      <v-chip
                        class="backgroundSecondary--text"
                        style="border: 4px double #040404 !important; font-weight: 600;"
                        color="warning"
                        label
                      >
                        {{ item.vehicle ? item.vehicle.plate.slice(0, 3) + "•" + item.vehicle.plate.slice(3, 6) : '---'}}
                      </v-chip>
                    </p>
                  </span>
                </div>
                <div class="text-center">
                  <span>
                    <p>
                      <v-chip
                        style="font-weight: 600;"
                        :color="item.mode === 'ENTRY' ? 'primary' : 'red'"
                        label
                      >
                        {{ item.mode === "ENTRY" ? "ACCESO" : "SALIDA" }}
                      </v-chip>
                    </p>
                  </span>
                </div>
              </div>
              <div>
                <div class="text-center">
                  <p><i class="fal fa-calendar-alt"></i>{{ item.created_at.slice(0, 10) }}</p>
                </div>
                <div class="text-center">
                  <p><i class="fal fa-clock"></i>{{ item.created_at.slice(11, 19) }}</p>
                </div>
              </div>
              <div class="mx-4" v-if="item.action === 'AI' || item.action === 'NO_OCR' || item.action === 'TAKE_PIC'"  :style="'background: ' + asignActionColor(item.action)[1]">
                <p>
                  <span class="d-flex justify-center align-center text-body-2 mb-0">
                    <strong style="color: #414544">{{ asignActionColor(item.action)[2] }} - </strong>
                    <v-btn class="my-0 mx-1 pa-0"
                      x-small
                      fab
                      outlined
                      color="secondary"
                      style="pointer-events: none; color: #414544 !important; height:24px; width:24px">
                      <label style="color: #414544">{{ item.vehicle.type_access === 'VISITANTE' ? 'NR' : 'R' }}</label>
                    </v-btn>
                    <strong style="color: #414544">{{ item.vehicle ? item.vehicle.owner.first_name + " " + item.vehicle.owner.first_last_name : '___'}}</strong>
                  </span>
                </p>
              </div>
              <div class="mx-4" v-if="item.action === 'OVERRIDE' || item.action === 'DISCARD'" :style="item.action === 'DISCARD' ? 'background: rgb(255, 96, 96)' : 'background: #607D8B'">
                <p>
                  <span class="text-body-2">{{item.action === 'OVERRIDE' ? 'APERTURA MANUAL' : 'DESCARTADO'}}</span>
                </p>
              </div>
              <div :style="item.action != 'AI' ? '' : 'display: none;'">
                <p style="display: flex;align-items: center; justify-content: center;">
                  <i class="fal fa-address-card mr-1"></i>
                  <span>[ {{sede}} ]</span> {{username}}
                </p>
              </div>
            </div>
          </div>
            <!-- <v-col
              v-for="(item, i) in lastItems"
              :key="i"
              cols="12"
              md="4"
              sm="6"
            >
              <v-card elevation="10" class="pointer" :class="lastItems.length > 3 ? 'full-height' : ''" :style="item.action != 'OVERRIDE' && item.action != 'DISCARD' ? 'cursor:pointer' : 'cursor:not-allowed'" @click="selectCar(item)" style="border-radius: 10px;">
                <v-card-text class="full-height pa-0 ma-0">
                  <div style="height: 55%" :style="item.action === 'OVERRIDE' || item.action === 'DISCARD' ? 'display:grid' : ''">
                    <v-img
                      :src="getVehicleImg(item.image[0])"
                      width="100%"
                      contain
                      lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png"
                    >
                    </v-img>
                  </div>
                  <div style="height:45%" :class="item.action === 'OVERRIDE' || item.action === 'DISCARD' ? 'd-flex align-content-end' : ''">
                    <v-row v-if="item.vehicle && (item.action != 'OVERRIDE' && item.action != 'DISCARD')">
                      <v-col
                        cols="12"
                        class="text-center pa-0 mt-1 mb-0 mx-0"
                        title="PLACA"
                      >
                        <v-chip
                          class="ma-2 backgroundSecondary--text"
                          style="font-weight: 600;"
                          color="warning"
                          label
                        >
                          {{ item.vehicle ? item.vehicle.plate : '---'}}
                        </v-chip>
                      </v-col>
                      <v-col cols="12" class="pa-0 my-1 mx-0" title="NOMBRE CONDUCTOR">
                        <p class="d-flex justify-center align-center text-body-2 mb-0">
                          <v-btn class="my-0 mx-2 pa-0"
                            x-small
                            fab
                            outlined
                            color="secondary"
                            style="pointer-events: none; color: #fff !important; height:22px; width:22px">
                            <label class="h6-text white--text">{{ item.vehicle.type_access === 'VISITANTE' ? 'NR' : 'R' }}</label>
                          </v-btn>
                          {{ item.vehicle ? item.vehicle.owner.first_name + " " + item.vehicle.owner.first_last_name : '___'}}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                        sm="12"
                        class="d-flex justify-center align-center my-0 py-0 mb-2"
                        title="HORA EVENTO"
                        style="height: 25px"
                      >
                        <p class="text-body-2 my-0">
                          <v-icon class="mb-2 mr-2 pt-1 grey--text" style="font-size:1.2rem;">
                            fal fa-calendar-alt
                          </v-icon>
                          {{ item.created_at.slice(0, 10) }}
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="12"
                        class="d-flex justify-center align-center my-0 py-0 mb-2"
                        title="HORA EVENTO"
                        style="height: 25px"
                      >
                        <p class="text-body-2 my-0">
                          <v-icon class="mb-2 mr-2 pt-1 grey--text" style="font-size:1.2rem;">
                            fal fa-clock
                          </v-icon>
                          {{ item.created_at.slice(11, 19) }}
                        </p>
                      </v-col>
                      <v-col cols="12" md="6" sm="12" v-if="item.action === 'AI' || item.action === 'NO_OCR' || item.action === 'TAKE_PIC'" class="d-flex justify-center align-center pa-0 ma-0" title="TIPO EVENTO">
                        <v-chip
                          class="ma-0 mb-2 pa-0 d-flex justify-center"
                          :color="item.mode === 'ENTRY' ? 'primary' : 'red'"
                          text-color="white"
                          x-small
                          label
                          style="width: 70%; height: 30px;"
                        >
                          <label style="width: 100%; color: white;">{{ item.mode === "ENTRY" ? "ACCESO" : "SALIDA" }}</label>
                        </v-chip>
                      </v-col>
                      <v-col cols="12" md="6" sm="12" v-if="item.action === 'AI' || item.action === 'NO_OCR' || item.action === 'TAKE_PIC'" class="d-flex justify-center align-center pa-0 ma-0" title="TIPO EVENTO">
                        <v-chip
                          class="ma-0 mb-2 pa-0 d-flex justify-center"
                          :color="asignActionColor(item.action)[1]"
                          text-color="white"
                          x-small
                          label
                          style="width: 70%; height: 30px;"
                        >
                          <label style="width: 100%; color: white;">{{ asignActionColor(item.action)[2] }}</label>
                        </v-chip>
                      </v-col>
                      <v-col cols="12" v-if="item.action === 'OVERRIDE' || item.action === 'DISCARD'" class="d-flex justify-center align-center pa-0 ma-0" title="TIPO EVENTO">
                        <v-chip
                          class="ma-0 mb-2 pa-0 d-flex justify-center"
                          :color="item.action === 'DISCARD' ? 'rgb(255, 96, 96)' : '#607D8B'"
                          text-color="white"
                          small
                          label
                          style="width: 80%; pointer-events: none; height: 30px;"
                        >
                          <label style="width: 100%; color: white;"><v-icon small class="mb-2 pt-1"> {{ item.action === 'OVERRIDE' ? 'mdi-boom-gate-alert-outline' : 'mdi-cancel' }} </v-icon>
                          {{item.action === 'OVERRIDE' ? 'APERTURA MANUAL' : 'EVENTO DESCARTADO'}}</label>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
      <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import xlsx from "xlsx";
import auth from "../services/auth";
import backlog from "../services/logs";

export default {
  name: "DashboardCars",
  data() {
    return {
      loadingResponsable: true,
      intervall: "",
      visitorModal: false,
      snackbar: false,
      loadingEntries: false,
      loadingReport: false,
      loadingBtns: false,
      snackbarText: "",
      snackbarColor: "",
      dailyEntries: [],
      dailyExits: [],
      devices: [],
      organizationName: auth.getUserEnterpriseName(),
      itemsList: [],
      itemsListDash: [],
      startDate: new Date().toISOString().substr(0, 10),
      finishDate: new Date().toISOString().substr(0, 10),
      datepicker1: false,
      datepicker2: false,
      carSelected: null,
      search: "",
      staticEndTopic: "/vehicle-access/response",
      headers: [
        { text: "CC", align: "center", value: "vehicle.owner.document_number", sortable: true },
        { text: "Nombre", align: "center", value: "allName", sortable: false },
        {
          text: "Tipo Usuario",
          align: "center",
          value: "vehicle.type_access",
          sortable: true,
        },
        { text: "Placa OCR", align: "center", value: "vehicle.plate", sortable: false },
        { text: "Placa", align: "center", value: "plate_ocr", sortable: false },
        { text: "Evento", align: "center", value: "tipoEntrada", sortable: true },
        {
          text: "Fecha",
          align: "center",
          value: "FechaIngreso",
          sortable: true,
        },
        {
          text: "Hora",
          align: "center",
          value: "HoraIngreso",
          sortable: true,
        },
      ],
      lastItems: [],
      visitorSkt: {},
      visitorNotRegistered: {
        Vehicle: null,
        Destination: "",
        PersonType: "",
      },
      registry: [],
      exampleModal: [],
      modalStatus: false,
      mqttTopic: "",
      PSRegistryImg: "",
      registerImgBackup: "",
      filterModal: false,
      advancedQuery: false,
      bodyAdvancedQuery: {
        plate: "",
        typeEvent: []
      },
      typeEvents: [
        { name: "ENTRADA", key: "ENTRY" },
        { name: "SALIDA", key: "EXIT" },
      ],
      sede: auth.getSedeName(),
      username: auth.getUsername()
    };
  },
  sockets: {
    // Socket channel to send visitor information.
    visitor(data) {
      this.mqttTopic = ""
      console.log(data)
      if (data.varStatus == "refresh") {
        console.log("ESTOY REFRESCANDO POR EL SOCKET: " + data)
        this.getPopups()
        this.mqttTopic = data.mqttTopic
      }
    },
  },
  methods: {
    imgErrorHandler(url) {
      console.log("Handler de imagen de registro", url)
      this.PSRegistryImg = this.registerImgBackup//"https://847395.smushcdn.com/1790738/wp-content/uploads/2015/09/imagen-no-encontrada.jpg?lossy=0&strip=1&webp=1"
      this.$forceUpdate()
    },

    closeAllPopups() {
      this.exampleModal.forEach(element => {
        element.status = false
      });
      this.modalStatus = false
    },

    closeOne(i) {
      this.exampleModal[i].status = false
      this.exampleModal[i-1].status = true
    },

    asignActionColor(action) {
      let action_color = ""
      let color = ""
      let text = ""
      switch (action) {
        case 'NO_OCR':
            action_color = 'event-action-no-ocr'
            color = 'rgb(100, 100, 253)'
            text = 'EDITADO'
            break
        case 'AI':
            action_color = 'event-action-ai'
            //color = '#34d399'
            color = '#4ce6ad'
            text = 'AI'
            break
        case 'TAKE_PIC':
            action_color = 'event-action-take-pic'
            color = 'rgb(255, 184, 52)'
            text = 'NO DETECT'
            break
        case 'OVERRIDE':
            action_color = 'event-action-override'
            color = '#607D8B'
            text = 'EXTERNO'
            break
        case 'DISCARD':
            action_color = 'event-action-discard'
            color = 'rgb(255, 96, 96)'
            text = 'ELIMINADO'
      }

      return [action_color, color, text];
    },

    customFilter(item, queryText) {
      const textAllName = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.second_name) ? item.second_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '') + " " + ((item.second_last_name) ? item.second_last_name.toLowerCase() : '');
      const textNameAndFirstLastname = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '') + " " + ((item.second_last_name) ? item.second_last_name.toLowerCase() : '');
      const textNameAndAllLastname = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '');
      const cedula = (item.document_number) ? item.document_number : '';
      const searchText = queryText.toLowerCase();

      return (
        textAllName.indexOf(searchText) > -1 ||
        textNameAndFirstLastname.indexOf(searchText) > -1 ||
        textNameAndAllLastname.indexOf(searchText) > -1 || 
        cedula.indexOf(searchText) > -1
      );
    },

    validatePlate(plate) {
      if (
        plate.length === 6 &&
        plate.charAt(0).toUpperCase() !== plate.charAt(0).toLowerCase() &&
        plate.charAt(1).toUpperCase() !== plate.charAt(1).toLowerCase() &&
        plate.charAt(2).toUpperCase() !== plate.charAt(2).toLowerCase() &&
        !isNaN(plate.charAt(3)) &&
        !isNaN(plate.charAt(4)) &&
        !isNaN(plate.charAt(5))
      ) {
        return true;
      } else {
        return false;
      }
    },

    sendMsgToBroker(topic, msg) {
      axios
        .post(globals.APIURL + "vehicle/bcs", {
          topic: topic,
          message: msg,
        })
        .then((res) => {
          console.log("RESPUESTA TAKE_PICS: ", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async discardEvent(event, i) {
      await axios
        .post(globals.APIURL + "vehicle/ue", {
          uuid: event.uuid,
          update_entry_obj: {
            action: "DISCARD",
            plate_ocr: event.old_ocr,
            campus_id: auth.getSelectedSede(),
          }
        })
        .then(async (res) => {
          console.log("REPUESTA DE DESHECHO DE POPUP", res)
          if (res.data && res.data === 'Entry updated successfuly') {
            this.snackbarText = "Se deshecho el evento de manera exitosa.";
            this.snackbarColor = "red";
            this.snackbar = true;
            if (i > 0) {
              this.exampleModal[i].status = false
              this.exampleModal[i-1].status = true
            } else {
              this.exampleModal[i].status = false
              this.modalStatus = false
            }
            this.getDailyEntries();
            this.loadingBtns = false

          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async createVisitorVehicle(accessData) {
      await axios
        .post(globals.APIURL + "vehicle/cv", {
          plate: accessData.plate_ocr.toUpperCase(),
          type: accessData.Vehicle,
          type_access: "VISITANTE",
          status: true,
          owner_personId: accessData.Destination.uuid,
          created_at: globals.COdate(),
        })
        .then(async (res) => {
          if (res.data && res.data.response === 'Vehicle inserted successfuly') {
            accessData.action = 'AI'
            await this.generateAccess(res.data.result, accessData)
          }
        })
        .catch((err) => {
          this.loadingBtns = false
          console.log(err);
        });
    },

    async generateAccess(vehicle, accessData) {
      await axios
        .post(globals.APIURL + "vehicle/ue", {
          uuid: accessData.uuid,
          update_entry_obj: {
            vehicle_id: vehicle.uuid,
            action: accessData.action,
            plate_ocr: accessData.old_ocr,
            campus_id: auth.getSelectedSede(),
          }
        })
        .then(async (res) => {
          console.log("REPUESTA DE ACTUALIZACION DE POPUP", res)
          if (res.data && res.data === 'Entry updated successfuly') {
            if (vehicle.type_access === 'VISITANTE') { // ** El vehículo existe pero es visitante
              await this.updateExistingVisitor(vehicle, accessData)
            }
            this.snackbarText = "Se generó el ingreso de manera exitosa.";
            this.snackbarColor = "green";
            this.snackbar = true;
            this.getDailyEntries();
            this.loadingBtns = false

          }
        })
        .catch((err) => {
          this.loadingBtns = false
          console.log(err);
        });
    },

    async updateExistingVisitor(vehicle, evento) {
      await axios
        .put(globals.APIURL + "vehicle/ur", {
          vehicle_id: vehicle.uuid,
          update_vehicle_obj: {
            status: true,
            owner_personId: evento.Destination.uuid
          }
        })
        .then((res) => {
          if (res.data && res.data === 'Vehicle updated successful') {
            console.log("Actualicé el vehiculo visitante")
          }
        })
        .catch((err) => {
          this.loadingBtns = false
          console.log(err);
        });
    },

    async sendPassMsg() {
      if (this.devices.length == 1) {
        await axios
          .post(globals.APIURL + "vehicle/bcs", {
            topic: "vspark/" + this.devices[0].mac + "/vehicle-access/response",
            message: "PASS",
          })
          .then((res) => {
            console.log("RESPUESTA PASS DE GENERATE VISIT: ", res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    async generateVisit(eve, i) {
      console.log("ENTRÉ A LA GENERACION DE APERTURA DE TALANQUERA:   ", eve)
      if (eve.plate_ocr !== "") { 
        if (this.validatePlate(eve.plate_ocr)) {
          if (eve.action != 'OVERRIDE') {
            if ((eve.image[0] != '' && eve.image[1] != '') && eve.plate_ocr != eve.old_ocr) {
              eve.action = 'NO_OCR'
            } else if (eve.image[0] !== '' && eve.image[1] === '') {
              eve.action = 'TAKE_PIC'
            } else {
              eve.action = 'AI'
            }
          }

          this.loadingBtns = true
          // ** Envio señal de apertura de talanquera
          //await this.sendPassMsg();

          // ** Valido si el vehiculo existe 
          await axios
            .post(globals.APIURL + "vehicle/fov", {
              plate: eve.plate_ocr
            })
            .then((res) => {
              if (res.data && res.data.uuid) {
                //eve.status = false
                if (i > 0) {
                  this.exampleModal[i].status = false
                  this.exampleModal[i-1].status = true
                } else {
                  this.exampleModal[i].status = false
                  this.modalStatus = false
                }
                this.generateAccess(res.data, eve)
              } else {
                //eve.status = false
                if (i > 0) {
                  this.exampleModal[i].status = false
                  this.exampleModal[i-1].status = true
                } else {
                  this.exampleModal[i].status = false
                  this.modalStatus = false
                }
                this.createVisitorVehicle(eve)
              }
            })
            .catch((err) => {
              this.loadingBtns = false
              console.log(err);
            });
        } else {
          this.snackbarText =
            "La placa debe estar compuesta por solo tres letras y tres números.";
          this.snackbarColor = "red";
          this.snackbar = true;
          this.loadingBtns = false
        }
      } else {
        this.snackbarText = "Es necesario que llene el campo de la placa";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    cancelVisitor() {
      this.visitorNotRegistered = {
        Vehicle: null,
        Destination: "",
      };
      this.visitorModal = false;
      this.visitorSkt = null;
    },
    
    getPopups() {
      axios
        .post(globals.APIURL + "vehicle/fbd", {
          campus_id: auth.getSelectedSede(),
          start_date: globals.COdate().substr(0, 10) + "T00:00:00.000Z",
          finish_date: globals.COdate().substr(0, 10) + "T23:59:59.000Z",
          actions: [
            { action: "POPUP" },
          ],
          order_by: 'desc'
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            for (let index = 0; index < res.data.length; index++) {
              const element = res.data[index];
              
              element.status = index == res.data.length - 1 ? true : false;
              element.Destination = ""
              element.Vehicle = null
              element.old_ocr = element.plate_ocr
            }
            this.exampleModal = res.data
            this.modalStatus = true
            console.log(this.exampleModal)
            this.$store.commit('asignParkNotifications', res.data)
          } else {
            console.log("NO HAY POPUPS:::")
          }
        })
        .catch((err) => {
          this.loadingEntries = false;
          console.log(err);
        });
    },

    getDailyEntries() {
      axios
        .post(globals.APIURL + "vehicle/fbd", {
          campus_id: auth.getSelectedSede(),
          start_date: globals.COdate().substr(0, 10) + "T00:00:00.000Z",
          finish_date: globals.COdate().substr(0, 10) + "T23:59:59.000Z",
          actions: [
            { action: "AI" },
            { action: "NO_OCR" },
            { action: "TAKE_PIC" },
            { action: "OVERRIDE" },
            { action: "DISCARD" },
          ],
          order_by: 'desc'
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            var entryCounter = [];
            var exitCounter = [];
            var manualEntries = []
            res.data.forEach((element) => {
              if (element.action == 'OVERRIDE') {
                manualEntries.push(element)
              }
              if (element.mode === "ENTRY" && (element.action == 'AI' || element.action == 'NO_OCR' || element.action == 'TAKE_PIC')) {
                entryCounter.push(element);
              } else if (element.mode != "ENTRY" && (element.action == 'AI' || element.action == 'NO_OCR' || element.action == 'TAKE_PIC')) {
                exitCounter.push(element);
              }
            });
            this.lastItems = res.data/* .slice(0, 9) */;
            this.itemsListDash = res.data
            this.dailyEntries = entryCounter;
            this.dailyExits = exitCounter;
            this.loadingEntries = false;
            //console.log(manualEntries.length)
          }
          this.loadingEntries = false;
        })
        .catch((err) => {
          this.loadingEntries = false;
          console.log(err);
        });
    },

    goToDetailedProfile(personSelected) {
      this.$router.replace({ name: 'registrations', params: { cedula_registro: { uuid: personSelected.owner.uuid, document_number: personSelected.owner.document_number } } });
    },

    getOwnerImg(item) {
      if (item) {
        this.PSRegistryImg = ""
        this.registerImgBackup = ""
        if (item.vehicle.owner.register_image) {
          this.PSRegistryImg = globals.APIURL + "entry/registry-pic/" + item.vehicle.owner.register_image;
        } else {
          this.PSRegistryImg = globals.APIURL + "entry/entry-picture/" + this.organizationName + "/" + item.vehicle.owner.first_name + "/" + item.vehicle.owner.first_last_name + "/" + item.vehicle.owner.document_number;
        }

        if (item.vehicle.owner.entries.length > 0) {
          this.registerImgBackup = globals.APIURL + "entry/daily-pic/" + item.vehicle.owner.entries[0].image[0];
        }
      }
    },

    getVehicleImg(id) {
      if (id) {
        var formatURL = "";
        if (id) {
          formatURL =
            globals.APIURL +
            "vehicle/entry-visitor/" +
            this.organizationName +
            "/" +
            id;
        }
        return formatURL;
      }
    },

    selectCar(item) {
      if (item && (item.action != 'OVERRIDE' || item.action != 'DISCARD')) {
        this.getOwnerImg(item)
        this.carSelected = item;
      }
    },

    getRegistry() {
      this.loadingResponsable = true
      axios
        .post(globals.APIURL + "registry/fal", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.registry = res.data;
            this.loadingResponsable = false
          }
          this.loadingResponsable = false
        })
        .catch((err) => {
          this.loadingResponsable = false
          console.log(err);
        });
    },

    getEntriesToExport(startDate, finishDate) {
      if (finishDate >= startDate) {
        this.loadingReport = true;
        axios
          .post(globals.APIURL + "vehicle/fbd", {
            campus_id: auth.getSelectedSede(),
            start_date: startDate + "T00:00:00.000Z",
            finish_date: finishDate + "T23:59:59.000Z",
            actions: [
              { action: "AI" },
              { action: "NO_OCR" },
              { action: "TAKE_PIC" },
              { action: "OVERRIDE" },
              { action: "DISCARD" },
            ],
            order_by: 'desc'
          })
          .then((res) => {
            if (res.data && res.data.length > 0) {
             //this.itemsList = res.data;
              this.itemsListDash = res.data;
              console.log(res.data)
              this.loadingReport = false;
            }
            this.loadingReport = false;
          })
          .catch((err) => {
            this.loadingReport = false;
            console.log(err);
          });
      } else {
        this.snackbarText = "La fecha final no puede ser menor a la inicial.";
        this.snackbarColor = "red";
        this.snackbar = true;
        this.finishDate = "";
      }
    },

    detailedFilter(startDate, finishDate) {
      if (finishDate >= startDate) {
        this.loadingReport = true;
        axios
          .post(globals.APIURL + "vehicle/fbd", {
            campus_id: auth.getSelectedSede(),
            start_date: startDate + "T00:00:00.000Z",/* "2021-09-01T00:00:00.000Z", */
            finish_date: finishDate + "T23:59:59.000Z",/* "2021-09-01T23:59:59.000Z", */
            actions: [
              { action: "AI" },
              { action: "NO_OCR" },
              { action: "TAKE_PIC" },
              { action: "OVERRIDE" },
              { action: "DISCARD" },
            ],
            order_by: 'desc'
          })
          .then((res) => {
            if (res.data && res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                element.FechaIngreso = element.created_at.substring(0, 10)
                element.HoraIngreso = element.created_at.substring(11, 16)
                element.allName = element.vehicle ? element.vehicle.owner.first_name + " " + element.vehicle.owner.first_last_name : "";
                element.tipoEntrada = element.mode == "ENTRY" ? "entrada" : "salida"
              }
              this.itemsList = res.data;
              //this.itemsListDash = res.data;
              console.log(res.data)
              this.loadingReport = false;
            }
            this.loadingReport = false;
          })
          .catch((err) => {
            this.loadingReport = false;
            console.log(err);
          });
      } else {
        this.snackbarText = "La fecha final no puede ser menor a la inicial.";
        this.snackbarColor = "red";
        this.snackbar = true;
        this.finishDate = "";
      }
    },

    generateReport(jsonContent) {
      this.loadingBtns = true
      if (jsonContent.length > 0) {
        for (let i = 0; i < jsonContent.length; i++) {
          const element = jsonContent[i];
          element.Destino = element.vehicle.owner.document_number;
          element.NombreConductor = element.vehicle.owner.first_name + " " + element.vehicle.owner.first_last_name;
          element.Placa = element.vehicle.plate
          element.TipoConductor = "EMPLEADO"
          element.mode === "ENTRY" ? element.TipoAcceso = "ACCESO" : element.TipoAcceso = "SALIDA";
          element.FechaIngreso = element.created_at.slice(0, 10)
          element.HoraIngreso = element.created_at.slice(11, 16)
          delete element.created_at
          delete element.image
          delete element.mode
          delete element.uuid
          delete element.vehicle
          delete element.vehicle_id

          delete element.action
          delete element.plate_ocr
          delete element.campus_id
          delete element.campus
          delete element.allName
          delete element.tipoEntrada
        }

        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet(jsonContent, {
          header: [
            "Destino",
            "NombreConductor",
            "Placa",
            "TipoConductor",
            "TipoAcceso",
            "FechaIngreso",
            "HoraIngreso",
          ],
        });

        newWS["A1"].v = "CC";
        newWS["B1"].v = "NOMBRE RESPONSABLE";
        newWS["C1"].v = "PLACA";
        newWS["D1"].v = "TIPO PERSONA";
        newWS["E1"].v = "TIPO EVENTO";
        newWS["F1"].v = "FECHA";
        newWS["G1"].v = "HORA";

        xlsx.utils.book_append_sheet(newWB, newWS, "Ingresos y Salidas");

        xlsx.writeFile(
          newWB,
          "Ingresos Vehículares_" +
            this.startDate +
            "_" +
            this.finishDate +
            ".xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );
        this.snackbarText = "Se descargó su reporte vehícular.";
        this.snackbarColor = "green";
        this.snackbar = true;
        this.loadingBtns = false
        backlog.generateLog({
          enterprise: auth.getUserEnterpriseName(),
          username: auth.getUsername(),
          role: auth.getRole(),
          action: "export/entries/vehicles",
        });
        //this.detailedFilter(this.startDate, this.finishDate);
      }
    },

    getCampus() {
      axios
        .post(globals.APIURL + "org/fo", {
          company_id: auth.getUserEnterpriseID(),
          campus_id: auth.getSelectedSede(),
        })
        .then((res) => {
          if (res.data.Campus[0]) {
            let index = res.data.Campus.findIndex(item => item.uuid === auth.getSelectedSede())
            res.data.Campus[index].devices.forEach(element => {
              if (element.type_device == 'vspark') {
                this.devices.push(element)
              }
            });

            this.$store.commit('asignParkdevices', this.devices)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    callEntriesEvery() {
      this.intervall = window.setInterval(
        function () {
          this.getDailyEntries();
        }.bind(this),
        10000
      );
    },
  },
  /* created() {
    // websocket
    //let that = this;
    
    this.WSConnection = new WebSocket("wss://nadeomank9.execute-api.us-east-1.amazonaws.com/dev?userid=" + auth.getUsername() + "&assetkey=" + auth.getSelectedSede());

    this.WSConnection.onopen = function (event) {
      console.log(event);
      console.log("Successfully connected to the websocket server...");
    };

    this.WSConnection.onmessage = function (event) {
      if (event.data) {
        let evt = JSON.parse(event.data);
        console.log(evt)
        // that.popupList.push(evt)
      }
    };
  }, */
  beforeMount() {
    this.getRegistry();
  },
  mounted() {
    this.loadingEntries = true;
    this.getDailyEntries();
    this.getEntriesToExport(this.startDate, this.finishDate)
    this.callEntriesEvery();
    this.getCampus()
    this.getPopups()
    this.$bus.$on("pupups", () => {
      this.getPopups();
    });
  },
  /* beforeDestroy() {
    //clearInterval(this.intervall);
    this.WSConnection.close();
  }, */
};
</script>

<style>
.event-action-no-ocr {
  border-bottom: 5px solid rgb(100, 100, 253) !important;
}

.event-action-override {
  border-bottom: 5px solid #607D8B !important;
}

.event-action-take-pic {
  border-bottom: 5px solid rgb(255, 184, 52) !important;
}

.event-action-ai {
  border-bottom: 5px solid rgb(77, 214, 77) !important;
}

.event-action-discard {
  border-bottom: 5px solid rgb(255, 96, 96) !important;
}

.uppertext-field input {
  text-transform: uppercase;
}

.small-chip {
  height: 20px !important;
}

.img-border {
  border-radius: 10px;
}

.full-height {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.cards-box {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1b7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>