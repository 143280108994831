<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      <p>Vanguard Vision AI - Release notes (V 1.0)</p>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="text-right">
          <p>01/06/2022</p>
        </v-col>
        <v-col md="12">
          <p>Overview:</p>
          <ul>
            <li>
              <small
                >Unificación de módulos access/park y vision. →
                <a href="https://app.vanguardvision.ai" style="color: inherit"
                  >app.vanguardvision.ai</a
                ></small
              >
            </li>
            <li>
              <small>Manejo de usuarios independientes.</small>
            </li>
            <li>
              <small>Tema oscuro.</small>
            </li>
          </ul>
        </v-col>
        <v-col md="12">
          <p>Access:</p>
          <ul>
            <li>
              <p>Features:</p>
              <ul>
                <li>
                  <small>Enrolamiento a través de app móvil OneID.</small>
                </li>
                <li>
                  <small
                    >Disminución del tiempo de identificación facial.</small
                  >
                </li>
                <li>
                  <small
                    >Manejo de bases de datos locales para mayor seguridad y
                    privacidad de la información.</small
                  >
                </li>
                <li>
                  <small
                    >Separación de bases de datos de registros según
                    sedes.</small
                  >
                </li>
                <li>
                  <small
                    >Notificación de usuarios enrolados a la organización, pero
                    sin sede asignada.</small
                  >
                </li>
              </ul>
            </li>
            <li>
              <p>Módulo Nómina:</p>
              <ul>
                <li>
                  <small
                    >Creación de grupos de trabajo para liquidación de horas
                    laboradas.</small
                  >
                </li>
                <li>
                  <small
                    >Dashboard de visualización de control de nómina.</small
                  >
                </li>
                <li>
                  <small>Generación de reporte de liquidación de nómina:</small>
                  <ul>
                    <li>
                      <small
                        >Horas laboradas, extras, nocturnas, festivas y
                        Ausentismos.</small
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </v-col>
        <v-col md="12">
          <p>Park:</p>
          <ul>
            <li>
              <p>Features:</p>
              <ul>
                <li>
                  <small>Modificación layout para nuevo tema oscuro.</small>
                </li>
                <li><small>Consulta de logs con filtros avanzados.</small></li>
              </ul>
            </li>
          </ul>
        </v-col>
        <v-col md="12">
          <p>Vision:</p>
          <ul>
            <li>
              <p>Features:</p>
              <ul>
                <li>
                  <p>Vista Eventos:</p>
                  <ul>
                    <li>
                      <small
                        >Aumento de la velocidad de respuesta y notificación de
                        los eventos.</small
                      >
                    </li>
                    <li>
                      <small
                        >Se adiciona nueva opción de resolución de eventos
                        {{ markups }} para diferenciar los falsos positivos
                        error de AI de los eventos que se descartan por su
                        naturaleza.</small
                      >
                    </li>
                    <li>
                      <small
                        >Se adiciona campo opcional para dejar notas sobre un
                        evento.</small
                      >
                    </li>
                    <li>
                      <small
                        >Filtros avanzados para búsqueda en histórico de
                        eventos.</small
                      >
                    </li>
                    <li>
                      <small
                        >Nueva interfaz de anotaciones de AI que permite
                        diferenciar los diferentes clases de objetos detectados.
                      </small>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Vista Configuración:</p>
                  <ul>
                    <li>
                      <small
                        >Opción de selección de clases de AI para las
                        alarmas.</small
                      >
                    </li>
                    <li><small>Opción de ROI.</small></li>
                    <li>
                      <small
                        >Opción de ROI invertido (genera alarma cuando se
                        detecta un objeto por fuera de la región
                        marcada).</small
                      >
                    </li>
                    <li><small>Opción para desactivar alarmas.</small></li>
                    <li>
                      <small
                        >Actualización de configuraciones Over The Air para
                        mayor agilidad en el despliegue y cambios de
                        configuración del firmware.</small
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "ReleaseNotes",
  data: () => ({
    markups: "<>",
  }),
  methods: {},
  mounted() {},
};
</script>
<style>
</style>
