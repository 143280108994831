<template>
  <v-container fluid>
    <div v-if="createSuborgDialog">
      <v-dialog
        v-model="createSuborgDialog"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title class="d-flex justify-center">
            <span class="text-h5">Crear Suborganización</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="newSuborg.name"
                  label="Nombre Suborganización"
                  outlined
                  dark
                  dense
                  hide-details
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="text-left">
                <span>Autorizadores</span>
              </v-col>
              <v-row class="mx-2">
                <v-col cols="12" class="my-0 py-0">
                    <span># 1</span>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                    <v-autocomplete
                      v-model="newSuborg.first_auth.person"
                      :items="registry"
                      return-object
                      label="Responsable"
                      :filter="customFilter"
                      outlined
                      dark
                      color="secondary"
                      dense
                      hide-details
                      loader-height="4"
                      no-data-text="No hay datos que mostrar"
                    >
                      <template v-slot:selection="{ item }">
                        <span>{{
                          item.first_name +
                          " " +
                          (item.second_name ? item.second_name +
                            " " : "") +
                          item.first_last_name +
                          " " +
                          item.second_last_name
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <p>
                          {{
                            item.first_name +
                            " " +
                            (item.second_name ? item.second_name +
                            " " : "") +
                            item.first_last_name +
                            " " +
                            item.second_last_name
                          }}
                        </p>
                      </template>
                    </v-autocomplete>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="newSuborg.first_auth.email"
                        label="Correo"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="newSuborg.first_auth.phone"
                        label="Celular"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col v-if="!activateSecondAuthorizer && !activateThirdAuthorizer" cols="12">
                    <v-btn
                        color="primary"
                        rounded
                        block
                        x-small
                        class="px-4"
                        @click="activateSecondAuthorizer = true"
                    >
                        Agregar autorizador
                    </v-btn>
                </v-col>
              </v-row>
              <v-divider v-if="activateSecondAuthorizer" class="ma-3"></v-divider>
              <v-row v-if="activateSecondAuthorizer" class="mx-2">
                <v-col cols="12" class="my-0 py-0">
                    <span># 2</span>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                    <v-autocomplete
                      v-model="newSuborg.second_auth.person"
                      :items="registry"
                      return-object
                      label="Responsable"
                      :filter="customFilter"
                      outlined
                      dark
                      color="secondary"
                      dense
                      hide-details
                      loader-height="4"
                      no-data-text="No hay datos que mostrar"
                    >
                      <template v-slot:selection="{ item }">
                        <span>{{
                          item.first_name +
                          " " +
                          (item.second_name ? item.second_name +
                            " " : "") +
                          item.first_last_name +
                          " " +
                          item.second_last_name
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <p>
                          {{
                            item.first_name +
                            " " +
                            (item.second_name ? item.second_name +
                            " " : "") +
                            item.first_last_name +
                            " " +
                            item.second_last_name
                          }}
                        </p>
                      </template>
                    </v-autocomplete>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="newSuborg.second_auth.email"
                        label="Correo"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="newSuborg.second_auth.phone"
                        label="Celular"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col v-if="activateSecondAuthorizer && !activateThirdAuthorizer" cols="12">
                    <v-btn
                        color="primary"
                        rounded
                        block
                        x-small
                        class="px-4"
                        @click="activateThirdAuthorizer = true"
                    >
                        Agregar Autorizador
                    </v-btn>
                    <v-btn
                        color="red"
                        rounded
                        block
                        x-small
                        class="mt-2 px-4"
                        @click="activateSecondAuthorizer = false ; removeAuthorizer(2)"
                    >
                        Remover Autorizador
                    </v-btn>
                </v-col>
              </v-row>
              <v-divider v-if="activateSecondAuthorizer && activateThirdAuthorizer" class="ma-3"></v-divider>
              <v-row v-if="activateSecondAuthorizer && activateThirdAuthorizer" class="mx-2">
                <v-col cols="12" class="my-0 py-0">
                    <span># 3</span>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                    <v-autocomplete
                      v-model="newSuborg.third_auth.person"
                      :items="registry"
                      return-object
                      label="Responsable"
                      :filter="customFilter"
                      outlined
                      dark
                      color="secondary"
                      dense
                      hide-details
                      loader-height="4"
                      no-data-text="No hay datos que mostrar"
                    >
                      <template v-slot:selection="{ item }">
                        <span>{{
                          item.first_name +
                          " " +
                          (item.second_name ? item.second_name +
                            " " : "") +
                          item.first_last_name +
                          " " +
                          item.second_last_name
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <p>
                          {{
                            item.first_name +
                            " " +
                            (item.second_name ? item.second_name +
                            " " : "") +
                            item.first_last_name +
                            " " +
                            item.second_last_name
                          }}
                        </p>
                      </template>
                    </v-autocomplete>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="newSuborg.third_auth.email"
                        label="Correo"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="newSuborg.third_auth.phone"
                        label="Celular"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col v-if="activateSecondAuthorizer && activateThirdAuthorizer" cols="12">
                    <v-btn
                        color="red"
                        rounded
                        block
                        x-small
                        class="px-4"
                        @click="activateThirdAuthorizer = false ; removeAuthorizer(3)"
                    >
                        Remover Autorizador
                    </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              outlined
              class="px-4"
              @click="createSuborgDialog = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              class="px-4"
              @click="createSuborg(newSuborg)"
            >
              Crear
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="editSuborganization">
      <v-dialog
        v-model="editSuborganization"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title class="d-flex justify-center">
            <span class="text-h5">Editar Suborganización</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="selectedSuborg.name"
                  label="Nombre Suborganización"
                  outlined
                  dark
                  dense
                  hide-details
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="text-left">
                <span>Autorizadores</span>
              </v-col>
              <v-row class="mx-2">
                <v-col cols="12" class="my-0 py-0">
                    <span># 1</span>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                    <v-autocomplete
                      v-model="selectedSuborg.authorizers[0].document_number"
                      :items="registry"
                      item-value="document_number"
                      return-object
                      label="Responsable"
                      :filter="customFilter"
                      outlined
                      dark
                      color="secondary"
                      dense
                      hide-details
                      loader-height="4"
                      no-data-text="No hay datos que mostrar"
                    >
                      <template v-slot:selection="{ item }">
                        <span>{{
                          item.first_name +
                          " " +
                          (item.second_name ? item.second_name +
                            " " : "") +
                          item.first_last_name +
                          " " +
                          item.second_last_name
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <p>
                          {{
                            item.first_name +
                            " " +
                            (item.second_name ? item.second_name +
                            " " : "") +
                            item.first_last_name +
                            " " +
                            item.second_last_name
                          }}
                        </p>
                      </template>
                    </v-autocomplete>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="selectedSuborg.authorizers[0].email"
                        label="Correo"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="selectedSuborg.authorizers[0].phone"
                        label="Celular"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col v-if="selectedSuborg.authorizers.length == 1 || (!activateSecondAuthorizer && !activateThirdAuthorizer)" cols="12">
                    <v-btn
                        color="primary"
                        rounded
                        block
                        x-small
                        class="px-4"
                        @click="activateSecondAuthorizer = true ; activateAuthorizer()"
                    >
                        Agregar autorizador
                    </v-btn>
                </v-col>
              </v-row>
              <v-divider v-if="(selectedSuborg.authorizers.length == 2 || selectedSuborg.authorizers.length == 3) || activateSecondAuthorizer" class="ma-3"></v-divider>
              <v-row v-if="(selectedSuborg.authorizers.length == 2 || selectedSuborg.authorizers.length == 3) || activateSecondAuthorizer" class="mx-2">
                <v-col cols="12" class="my-0 py-0">
                    <span># 2</span>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                    <v-autocomplete
                      v-model="selectedSuborg.authorizers[1].document_number"
                      :items="registry"
                      item-value="document_number"
                      return-object
                      label="Responsable"
                      :filter="customFilter"
                      outlined
                      dark
                      color="secondary"
                      dense
                      hide-details
                      loader-height="4"
                      no-data-text="No hay datos que mostrar"
                    >
                      <template v-slot:selection="{ item }">
                        <span>{{
                          item.first_name +
                          " " +
                          (item.second_name ? item.second_name +
                            " " : "") +
                          item.first_last_name +
                          " " +
                          item.second_last_name
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <p>
                          {{
                            item.first_name +
                            " " +
                            (item.second_name ? item.second_name +
                            " " : "") +
                            item.first_last_name +
                            " " +
                            item.second_last_name
                          }}
                        </p>
                      </template>
                    </v-autocomplete>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="selectedSuborg.authorizers[1].email"
                        label="Correo"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="selectedSuborg.authorizers[1].phone"
                        label="Celular"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col v-if="selectedSuborg.authorizers.length == 2 || (activateSecondAuthorizer && !activateThirdAuthorizer)" cols="12">
                    <v-btn
                        color="primary"
                        rounded
                        block
                        x-small
                        class="px-4"
                        @click="activateThirdAuthorizer = true ; activateAuthorizer()"
                    >
                        Agregar Autorizador
                    </v-btn>
                    <v-btn
                        color="red"
                        rounded
                        block
                        x-small
                        class="mt-2 px-4"
                        @click="activateSecondAuthorizer = false ; removeAuthorizer('edit', 2)"
                    >
                        Remover Autorizador
                    </v-btn>
                </v-col>
              </v-row>
              <v-divider v-if="selectedSuborg.authorizers.length == 3 || (activateSecondAuthorizer && activateThirdAuthorizer)" class="ma-3"></v-divider>
              <v-row v-if="selectedSuborg.authorizers.length == 3 || (activateSecondAuthorizer && activateThirdAuthorizer)" class="mx-2">
                <v-col cols="12" class="my-0 py-0">
                    <span># 3</span>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                    <v-autocomplete
                      v-model="selectedSuborg.authorizers[2].document_number"
                      :items="registry"
                      item-value="document_number"
                      return-object
                      label="Responsable"
                      :filter="customFilter"
                      outlined
                      dark
                      color="secondary"
                      dense
                      hide-details
                      loader-height="4"
                      no-data-text="No hay datos que mostrar"
                    >
                      <template v-slot:selection="{ item }">
                        <span>{{
                          item.first_name +
                          " " +
                          (item.second_name ? item.second_name +
                            " " : "") +
                          item.first_last_name +
                          " " +
                          item.second_last_name
                        }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <p>
                          {{
                            item.first_name +
                            " " +
                            (item.second_name ? item.second_name +
                            " " : "") +
                            item.first_last_name +
                            " " +
                            item.second_last_name
                          }}
                        </p>
                      </template>
                    </v-autocomplete>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="selectedSuborg.authorizers[2].email"
                        label="Correo"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field
                        v-model="selectedSuborg.authorizers[2].phone"
                        label="Celular"
                        outlined
                        dark
                        dense
                        hide-details
                        color="secondary"
                    ></v-text-field>
                </v-col>
                <v-col v-if="selectedSuborg.authorizers.length == 3 || activateSecondAuthorizer && activateThirdAuthorizer" cols="12">
                    <v-btn
                        color="red"
                        rounded
                        block
                        x-small
                        class="px-4"
                        @click="activateThirdAuthorizer = false ; removeAuthorizer('edit', 3)"
                    >
                        Remover Autorizador
                    </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              outlined
              class="px-4"
              @click="cancelEditMode(selectedSuborg)"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="success"
              rounded
              class="px-4"
              @click="editSuborg(selectedSuborg)"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="deleteSuborganization">
      <v-dialog v-model="deleteSuborganization" max-width="600">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12">
                <label class="text-h6 text-center"
                  >¿Está seguro que desea eliminar esta suborganización?</label
                >
                <label class="text-body-2 text-center mt-y"
                  >Recuerde que al eliminar la suborganización las personas asignadas a esta quedaran sin alguna asignada</label
                >
              </v-col>
              <v-col cols="12">
                <label class="white--text"><small>Confime la eliminación de la suborganización, escriba <strong>eliminar</strong> en el cuadro de texto.</small></label>
                <v-text-field
                  v-model="labelConfirmDeletion"
                  placeholder='eliminar'
                  outlined
                  dark
                  dense
                  hide-details
                  solo
                  color="secondary"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              outlined
              class="px-4"
              @click="deleteSuborganization = false ; labelConfirmDeletion = ''"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="red"
              rounded
              class="px-4"
              :disabled="!labelConfirmDeletion"
              @click="deleteSuborg(selectedSuborg)"
            >
              Eliminar
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-row class="text-center">
      <v-col cols="12">
        <p class="grey--text font-weight-bold text-h6">
          Configurar Suborganizaciones
        </p>
      </v-col>
      
      <v-col cols="12">
        <v-card elevation="0" class="ctTablaCam" style="border-radius: 10px">
          <v-row class="text-center">
            <v-col class="no-mobile" cols="10"></v-col>
            <v-col cols="12" md="2">
                <v-btn
                  color="secondary"
                  rounded
                  outlined
                  class="px-2"
                  small
                  @click="createSuborgDialog = true"
                >
                Crear Suborganización
                </v-btn>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12">
              <v-layout child-flex>
                <v-data-table
                  :headers="headers"
                  :items="suborganizationsList"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [20],
                  }"
                  class="dtTable"
                  fixed-header
                  :loading="loading"
                  loading-text="Cargando Información..."
                  :no-results-text="`No se encontrarón ingresos por su dato de busqueda (${search})`"
                  no-data-text="No hay suborganizaciones que mostrar"
                >
                <template v-slot:[`item.first_auth`]="{ item }">
                    <label>{{ item.authorizers[0].document_number && Object.keys(item.authorizers[0].document_number).length > 0 ? item.authorizers[0].document_number.document_number : item.authorizers[0].document_number }}</label>
                    <label>{{ item.authorizers[0].name }}</label>
                    <label>{{ item.authorizers[0].email }}</label>
                    <label>{{ item.authorizers[0].phone }}</label>
                </template>
                <template v-slot:[`item.second_auth`]="{ item }">
                    <div v-if="item.authorizers.length > 1">
                        <label>{{ item.authorizers[1].document_number && Object.keys(item.authorizers[1].document_number).length > 0 ? item.authorizers[1].document_number.document_number : item.authorizers[1].document_number }}</label>
                        <label>{{ item.authorizers[1].name }}</label>
                        <label>{{ item.authorizers[1].email }}</label>
                        <label>{{ item.authorizers[1].phone }}</label>
                    </div>
                    <div v-if="item.authorizers.length == 1">
                        <label>S/A</label>
                    </div>
                </template>
                <template v-slot:[`item.third_auth`]="{ item }">
                    <div v-if="item.authorizers.length == 3">
                        <label>{{ item.authorizers[2].document_number && Object.keys(item.authorizers[2].document_number).length > 0 ? item.authorizers[2].document_number.document_number : item.authorizers[2].document_number }}</label>
                        <label>{{ item.authorizers[2].name }}</label>
                        <label>{{ item.authorizers[2].email }}</label>
                        <label>{{ item.authorizers[2].phone }}</label>
                    </div>
                    <div v-if="item.authorizers.length == 1 || item.authorizers.length == 2">
                        <label>S/A</label>
                    </div>
                </template>
                <template v-slot:[`item.options`]="{ item }">
                  <v-btn
                    icon
                    class="secondary mr-2"
                    outlined
                    small
                    @click="activateEditMode(item)"
                  >
                    <i class="fal fa-pencil" style="font-size: 15px;" color="primary"></i>
                  </v-btn>
                  <v-btn
                    icon
                    class="error"
                    outlined
                    small
                    @click="deleteSuborganization = true ; selectedSuborg = item"
                  >
                    <i class="fal fa-trash" style="font-size: 15px;" color="error"></i>
                  </v-btn>
                </template>
                </v-data-table>
              </v-layout>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";

export default {
  name: "Visitors",
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    loading: false,
    modal: false,
    userRole: auth.getRole(),
    organizations: [],
    org: null,
    suborganizationsList: [],
    registry: [],
    reportAlreadyCreated: 0,
    rangeDates: [globals.COdate().substr(0, 10), globals.COdate().substr(0, 10)],
    search: "",
    maxDate: "",
    headers: [
      { text: "Nombre Suborganización", sortable: false, align: "center", value: "name" },
      { text: "Autorizador #1", sortable: false, align: "center", value: "first_auth" },
      { text: "Autorizador #2", sortable: false, align: "center", value: "second_auth" },
      { text: "Autorizador #3", sortable: false, align: "center", value: "third_auth" },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    createSuborgDialog: false,
    editSuborganization: false,
    deleteSuborganization: false,
    selectedSuborg: null,
    newSuborg: {
        name: "",
        first_auth: {
            person: null,
            email: "",
            phone: ""
        },
        second_auth: {
            person: null,
            email: "",
            phone: ""
        },
        third_auth: {
            person: null,
            email: "",
            phone: ""
        }
    },
    activateSecondAuthorizer: false,
    activateThirdAuthorizer: false,
    allSedeList: [],
    labelConfirmDeletion: ""
  }),
  methods: {
    customFilter(item, queryText) {
      const textAllName = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.second_name) ? item.second_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '') + " " + ((item.second_last_name) ? item.second_last_name.toLowerCase() : '');
      const textNameAndFirstLastname = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '') + " " + ((item.second_last_name) ? item.second_last_name.toLowerCase() : '');
      const textNameAndAllLastname = ((item.first_name) ? item.first_name.toLowerCase() : '') + " " + ((item.first_last_name) ? item.first_last_name.toLowerCase() : '');
      const cedula = (item.document_number) ? item.document_number : '';
      const searchText = queryText.toLowerCase();

      return (
        textAllName.indexOf(searchText) > -1 ||
        textNameAndFirstLastname.indexOf(searchText) > -1 ||
        textNameAndAllLastname.indexOf(searchText) > -1 || 
        cedula.indexOf(searchText) > -1
      );
    },

    removeAuthorizer(type, num) {
      if (type == "edit") {
        this.selectedSuborg.authorizers.pop()
      } else {
        switch (num) {
          case 2:
            this.newSuborg.second_auth.person = null;
            this.newSuborg.second_auth.email = "";
            this.newSuborg.second_auth.phone = "";
            break;
      
          case 3:
            this.newSuborg.third_auth.person = null;
            this.newSuborg.third_auth.email = "";
            this.newSuborg.third_auth.phone = "";
            break;
        }
      }
    },

    getSuborgs() {
      axios
        .post(globals.APIURL + "campus/fdso", {
          company_id: auth.getUserEnterpriseID()
        })
        .then((res) => {
          console.log(res)
          if (res.data && res.data.length > 0) {
            this.suborganizationsList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createSuborg(newSuborg) {
      if (newSuborg.name && (newSuborg.first_auth.person != null || newSuborg.second_auth.person != null || newSuborg.third_auth.person != null)) {
        let authPeople = [];

        if (newSuborg.first_auth.person) {
          authPeople.push({
            document_number: newSuborg.first_auth.person.document_number,
            name: newSuborg.first_auth.person.first_name + (newSuborg.first_auth.person.second_name ? " " + newSuborg.first_auth.person.second_name : "") + " " + newSuborg.first_auth.person.first_last_name + (newSuborg.first_auth.person.second_last_name ? " " + newSuborg.first_auth.person.second_last_name : ""),
            email: newSuborg.first_auth.email,
            phone: newSuborg.first_auth.phone
          })
        }

        if (newSuborg.second_auth.person) {
          authPeople.push({
            document_number: newSuborg.second_auth.person.document_number,
            name: newSuborg.second_auth.person.first_name + (newSuborg.second_auth.person.second_name ? " " + newSuborg.second_auth.person.second_name : "") + " " + newSuborg.second_auth.person.first_last_name + (newSuborg.second_auth.person.second_last_name ? " " + newSuborg.second_auth.person.second_last_name : ""),
            email: newSuborg.second_auth.email,
            phone: newSuborg.second_auth.phone
          })
        }

        if (newSuborg.third_auth.person) {
          authPeople.push({
            document_number: newSuborg.third_auth.person.document_number,
            name: newSuborg.third_auth.person.first_name + (newSuborg.third_auth.person.second_name ? " " + newSuborg.third_auth.person.second_name : "") + " " + newSuborg.third_auth.person.first_last_name + (newSuborg.third_auth.person.second_last_name ? " " + newSuborg.third_auth.person.second_last_name : ""),
            email: newSuborg.third_auth.email,
            phone: newSuborg.third_auth.phone
          })
        }

        let body = {
          company_id: auth.getUserEnterpriseID(),
          campus_id: auth.getSelectedSede(),
          name: newSuborg.name,
          authorizers: authPeople,
          first_person: newSuborg.first_auth.person ? newSuborg.first_auth.person.uuid : null,
          second_person: newSuborg.second_auth.person ? newSuborg.second_auth.person.uuid : null,
          third_person: newSuborg.third_auth.person ? newSuborg.third_auth.person.uuid : null,
        }

        axios({
          method: "POST",
          url: globals.APIURL + "campus/cso",
          data: body,
        })
        .then((res) => {
          console.log(res)
          if (res.status == 200) {
            this.getSuborgs()
            this.createSuborgDialog = false;
            this.cleanSuborg();
            this.snackbarText = "Se creo la suborganización.";
            this.snackbarColor = "success";
            this.snackbar = true;
          }
        });
      } else {
        this.snackbarText = "Es necesario ponerle un nombre y agregar al menos 1 autorizador.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },
    
    cleanSuborg() {
      this.newSuborg.name =  "";
      this.newSuborg.first_auth.person = null
      this.newSuborg.first_auth.email = ""
      this.newSuborg.first_auth.phone = ""
      this.newSuborg.second_auth.person = null
      this.newSuborg.second_auth.email = ""
      this.newSuborg.second_auth.phone = ""
      this.newSuborg.third_auth.person = null
      this.newSuborg.third_auth.email = ""
      this.newSuborg.third_auth.phone = ""
    },

    activateAuthorizer() {
      this.selectedSuborg.authorizers.push({
        document_number: null,
        name: "",
        email: "",
        phone: ""
      })
    },

    activateEditMode(item) {
      this.selectedSuborg = null;
      console.log("entro aqui 1", item)
      this.editSuborganization = true; 
      this.selectedSuborg = item;
      if (item.authorizers.length == 2) {
          this.activateSecondAuthorizer = true;
      } else if (item.authorizers.length == 3) {
          this.activateSecondAuthorizer = true
          this.activateThirdAuthorizer = true
      }
      this._beforeEditingCacheSuborg = Object.assign({}, item);
      this._beforeEditingCacheSuborgFirstAuthorizer = Object.assign({}, item.authorizers[0]);
      if (item.authorizers.length == 2) {
        console.log(0.1)
        this._beforeEditingCacheSuborgSecondAuthorizer = Object.assign({}, item.authorizers[1]);
      } else if (item.authorizers.length == 3) {
        console.log(0.2)
        this._beforeEditingCacheSuborgSecondAuthorizer = Object.assign({}, item.authorizers[1]);
        this._beforeEditingCacheSuborgThirdAuthorizer = Object.assign({}, item.authorizers[2]);
      }
    },

    cancelEditMode(item) {
      console.log("entro aqui", this._beforeEditingCacheSuborg)
      Object.assign(item, this._beforeEditingCacheSuborg);
      Object.assign(item.authorizers[0], this._beforeEditingCacheSuborgFirstAuthorizer);
      
      if (this._beforeEditingCacheSuborgSecondAuthorizer && !this._beforeEditingCacheSuborgThirdAuthorizer) {
        console.log(1.1)
        if (item.authorizers.length == 2) {
          Object.assign(item.authorizers[1], this._beforeEditingCacheSuborgSecondAuthorizer);
        } else {
          console.log(1.12)
          if (item.authorizers.length == 1) {
            item.authorizers.push(this._beforeEditingCacheSuborgSecondAuthorizer)
          } else {
            item.authorizers.pop()
          }
        }
      } else if (this._beforeEditingCacheSuborgSecondAuthorizer && this._beforeEditingCacheSuborgThirdAuthorizer) {
        console.log(1.2)
        if (this._beforeEditingCacheSuborgSecondAuthorizer && item.authorizers.length == 2) {
          Object.assign(item.authorizers[1], this._beforeEditingCacheSuborgSecondAuthorizer);
        } else {
          item.authorizers.push(this._beforeEditingCacheSuborgSecondAuthorizer)
        }

        if (this._beforeEditingCacheSuborgThirdAuthorizer && item.authorizers.length == 3) {
          Object.assign(item.authorizers[2], this._beforeEditingCacheSuborgThirdAuthorizer);
        } else {
          item.authorizers.push(this._beforeEditingCacheSuborgThirdAuthorizer)
        }
      } else {
        if (item.authorizers.length == 2) {
          item.authorizers.pop()
          console.log(1.3)
        } else if (item.authorizers.length == 3) {
          item.authorizers.pop()
          item.authorizers.pop()
        }
      }
      this.editSuborganization = false;
      this.selectedSuborg = null;
      this.activateSecondAuthorizer = false
      this.activateThirdAuthorizer = false
    },

    editSuborg(suborg) {
      if (suborg.name && suborg.authorizers.length > 0 && ((suborg.authorizers.length == 1 && suborg.authorizers[0].document_number) || (suborg.authorizers.length == 2 && suborg.authorizers[1].document_number) || (suborg.authorizers.length == 3 && suborg.authorizers[2].document_number))) {
        if (suborg.authorizers[0] && suborg.authorizers[0].document_number.uuid) {
          suborg.authorizers[0].name = suborg.authorizers[0].document_number.first_name + (suborg.authorizers[0].document_number.second_name ? " " + suborg.authorizers[0].document_number.second_name : "") +
          " " + suborg.authorizers[0].document_number.first_last_name + (suborg.authorizers[0].document_number.second_last_name ? " " + suborg.authorizers[0].document_number.second_last_name : "");
          suborg.authorizers[0].document_number = suborg.authorizers[0].document_number.document_number
        }

        if (suborg.authorizers[1] && suborg.authorizers[1].document_number.uuid) {
          suborg.authorizers[1].name = suborg.authorizers[1].document_number.first_name + (suborg.authorizers[1].document_number.second_name ? " " + suborg.authorizers[1].document_number.second_name : "") +
          " " + suborg.authorizers[1].document_number.first_last_name + (suborg.authorizers[1].document_number.second_last_name ? " " + suborg.authorizers[1].document_number.second_last_name : "");
          suborg.authorizers[1].document_number = suborg.authorizers[1].document_number.document_number
        }

        if (suborg.authorizers[2] && suborg.authorizers[2].document_number.uuid) {
          suborg.authorizers[2].name = suborg.authorizers[2].document_number.first_name + (suborg.authorizers[2].document_number.second_name ? " " + suborg.authorizers[2].document_number.second_name : "") +
          " " + suborg.authorizers[2].document_number.first_last_name + (suborg.authorizers[2].document_number.second_last_name ? " " + suborg.authorizers[2].document_number.second_last_name : "");
          suborg.authorizers[2].document_number = suborg.authorizers[2].document_number.document_number
        }

        axios({
          method: "POST",
          url: globals.APIURL + "campus/uso",
          data: suborg,
        })
        .then((res) => {
          console.log(res)
          if (res.status == 200) {
            this.getSuborgs()
            this.editSuborganization = false;
            this.selectedSuborg = null;
            this.activateSecondAuthorizer = false
            this.activateThirdAuthorizer = false
            this.snackbarText = "Se actualizó la suborganización.";
            this.snackbarColor = "success";
            this.snackbar = true;
          }
        });
      } else {
        this.snackbarText = "Es necesario llenar el campo nombre y agregar al menos 1 autorizador.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    deleteSuborg(suborg) {
      if (this.labelConfirmDeletion && this.labelConfirmDeletion == "eliminar") {
        axios({
          method: "POST",
          url: globals.APIURL + "campus/dso",
          data: suborg,
        })
        .then((res) => {
          if (res.status == 200) {
            this.deleteSuborganization = false;
            this.selectedSuborg = null;
            this.labelConfirmDeletion = "";
            this.getSuborgs()
            this.snackbarText = "Se eliminó correctamente la suborganización.";
            this.snackbarColor = "error";
            this.snackbar = true;
          }
        });
      } else {
        this.snackbarText = "Es necesario confirmar la eliminación con la caja de texto.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    getRegistry() {
      axios
        .post(globals.APIURL + "registry/fasl", {
          company_id: auth.getUserEnterpriseID(),
          campus_id: auth.getSelectedSede()
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.registry = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getSuborgs()
    this.getRegistry()
  },
};
</script>
<style>
tr:hover {
  background-color: transparent !important;
}
</style>
