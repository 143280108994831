<template>
  <v-container
    style="height: 100%"
    class="d-flex align-center justify-center"
  >
    <div v-if="!allowedAccess">
      <v-row class="pt-3 text-center">
        <v-col cols="12" class="mb-10">
          <i
            class="fal fa-unlink grey--text ma-4 text-center"
            style="font-size: 70px"
          ></i>
          <p class="text-h6 grey--text">
            Oh no, parece que el link que te asignarón ya expiró.
          </p>
          <p class="text-subtitle-2 grey--text">
            Comunicate con el encargado para que te genere uno nuevo.
          </p>
        </v-col>
        <v-col cols="12" class="d-flex justify-center mt-10">
          <v-img max-width="150px" src="../../assets/logo.png"></v-img>
        </v-col>
      </v-row>
    </div>
    <div v-if="allowedAccess">
      <div class="text-center" v-if="habeasDataDialog == true">
        <v-dialog v-model="habeasDataDialog" fullscreen>
          <div class="d-flex justify-end" style="background-color: #31394c">
            <v-btn
              class="mt-1 mr-1"
              icon
              small
              @click="habeasDataDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <habeas-data
            :enterprise="companyID"
            :habeasType="empresaHabeasID"
            :habeasStatus="habeasData"
          ></habeas-data>
        </v-dialog>
      </div>
      <v-overlay opacity="1" :value="loadingSelfieBtn">
        <v-card color="primary" dark>
          <v-card-text>
            Validando selfie, por favor espere...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-overlay>
      <v-overlay opacity="1" :value="loadingFrontDocBtn">
        <v-card color="primary" dark>
          <v-card-text>
            Validando documento, por favor espere...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-overlay>
      <v-overlay opacity="1" :value="loadingBackDocBtn">
        <v-card color="primary" dark>
          <v-card-text>
            Validando documento, por favor espere...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-overlay>

      <v-card width="100%" elevation="0" style="background-color: #212734 !important;">
        <v-card-title class="d-flex justify-center pb-0">
          <!-- <span class="text-h6">Registro Vanguard Vision</span> -->
          <v-img max-width="170px" src="../../assets/22.png"></v-img>
        </v-card-title>
        <v-card-text>
          <!-- INFORMATIVE PANEL -->
          <v-row class="pt-3" v-if="infoPanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="h5-text mb-1">
                  Bienvenido(a) al modulo de registro OneID de
                  <strong>Vanguard Vision.</strong>
                </p>
                <!-- <p class="h5-text mb-1">Al hacer click en el botón de <strong>COMENCEMOS</strong>, usted esta confirmando y aceptando la</p>
              <p v-if="companyID !== 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17'" class="h5-text mb-1">politica de tratamiento de datos</p> -->
                <p
                  v-if="companyID === 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17'"
                  class="h5-text mb-1"
                >
                  <strong
                    >"AUTORIZACION TRATAMIENTO DE DATOS PERSONALES<!--  DE EMPLEADOS QUE INGRESAN A LAS SEDES DE LA ORGANIZACIÓN SOLLA S.A. -->"</strong
                  >
                </p>
                <v-btn
                  v-if="
                    companyID === 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17' ||
                    companyID === 'bbc39d33-128f-474a-bea7-81e2edabdc5c'
                  "
                  class="mb-2"
                  color="secondary"
                  x-small
                  @click="
                    iReadPolicy = true;
                    habeasDataDialog = true;
                  "
                  >Tratamiento de Datos Personales</v-btn
                >
                <!-- <p class="h5-text mb-1">Al hacer click en el botón de comencemos, usted esta confirmando y aceptando los <strong style="cursor: pointer">Terminos y Condiciones</strong> </p> -->
                <!-- <p class="h5-text mb-1">y usted acepta que ha leido la <strong style="cursor: pointer">Politica de Privacidad</strong> </p> -->
                <p class="h6-text mb-3">
                  Antes de iniciar con el proceso por favor tener en cuenta lo
                  siguiente para obtener un registro exitoso
                </p>
                <p><strong>-</strong> Limpiar el lente de la cámara.</p>
                <p>
                  <strong>-</strong> Aceptar el permiso de la cámara cuando esta
                  lo pregunte.
                </p>
                <p>
                  <strong>-</strong> Evitar fuentes directas de luz como
                  bombillos o lámparas, preferiblemente hacerlo en un lugar con
                  luz natural.
                </p>
                <p>
                  <strong>-</strong> Tener a la mano el documento de identidad.
                </p>
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
              <v-btn
                v-if="
                  companyID === 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17' ||
                  companyID === 'bbc39d33-128f-474a-bea7-81e2edabdc5c'
                "
                small
                color="primary"
                class="px-4 mx-2 primaryBtn"
                :disabled="!habeasData"
                @click="
                  infoPanel = false;
                  firstPanel = true;
                "
              >
                COMENCEMOS
              </v-btn>
              <v-btn
                v-else
                small
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="
                  infoPanel = false;
                  newFirstPanel = true;
                "
              >
                COMENCEMOS
              </v-btn>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newFirstPanel" justify="center">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h5 mb-1 text-center font-weight-bold">
                  Registro
                </p>
              </div>
            </v-col>
            <div style="width: 60%">
							<v-row class="pa-0">
								<v-col cols="12" class="mb-0">
									<label class="font-weight-thin">
										Comencemos por saber tus nombres y apellidos.
									</label>
								</v-col>
							</v-row>
							<v-row class="pa-0">
								<v-col cols="12" md="6" class="py-0">
									<v-text-field
										v-model="registry.firstname"
										label="Primer Nombre"
										:rules="namesRule"
										outlined
										dense
										dark
										color="white"
									></v-text-field>
								</v-col>
								<v-col cols="12" md="6" class="py-0">
									<v-text-field
										v-model="registry.secondname"
                    :rules="secondNameRule"
										label="Segundo Nombre"
										outlined
										dense
										dark
										color="white"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row class="pa-0">
								<v-col cols="12" md="6" class="py-0">
									<v-text-field
										v-model="registry.surname1"
										label="Primer Apellido"
										:rules="namesRule"
										outlined
										dense
										dark
										color="white"
									></v-text-field>
								</v-col>
								<v-col cols="12" md="6" class="py-0">
									<v-text-field
										v-model="registry.surname2"
										label="Segundo Apellido"
                    :rules="secondNameRule"
										outlined
										dense
										dark
										color="white"
									></v-text-field>
								</v-col>
							</v-row>
						</div>

            <v-col cols="12" class="text-center">
              <v-btn
                color="primary"
                class="px-4 mx-2 primaryBtn"
                small
                @click="validateNames"
              >
                Ir a verificación biometrica
              </v-btn>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newGuestPanel" justify="center">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h5 mb-1 text-center font-weight-bold">
                  Información adicional
                </p>
              </div>
            </v-col>
            <div style="width: 55%">
							<v-row class="pa-0">
                <v-col cols="12" class="d-flex justify-center">
                  <v-radio-group
                    v-model="guest.typeGuest"
                    row
                  >
                    <v-radio
                      label="Huesped"
                      value="guest"
                      color="secondary"
                    ></v-radio>
                    <v-radio
                      label="Visitante"
                      value="visitor"
                      color="secondary"
                    ></v-radio>
                    <v-radio
                      label="Visistante día sol"
                      value="pool_visitor"
                      color="secondary"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
							</v-row>
              <v-row v-if="guest.typeGuest && guest.typeGuest == 'guest'" class="pa-0">
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-text-field
                    v-model="guest.placeGuestCode"
										label="Número de habitación o Cabaña"
										outlined
										dense
										dark
										color="white"
                    style="max-width: 50%"
									></v-text-field>
                </v-col>
							</v-row>
              <v-row class="pa-0">
                <v-col cols="12" class="text-center">
                  <!-- <p class="text-h6 ">Tiempo de Estadia</p> -->
                  <label class="text-h6 ">Tiempo de Estadia</label>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-menu
                    v-model="datepickerCheckin"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="guest.checkinDate"
                        label="Fecha Llegada"
                        prepend-inner-icon="mdi-calendar"
                        :rules="[(v) => !!v || 'El campo es obligatorio.']"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        dark
                        color="secondary"
                        required
                        solo
                        class="pt-1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="guest.checkinDate"
                      @input="datepickerCheckin = false"
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-menu
                    v-model="datepickerCheckout"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="guest.checkoutDate"
                        label="Fecha Salida"
                        prepend-inner-icon="mdi-calendar"
                        :rules="[(v) => !!v || 'El campo es obligatorio.']"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        dark
                        color="secondary"
                        required
                        solo
                        class="pt-1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="guest.checkoutDate"
                      @input="datepickerCheckout = false"
                      locale="es-MX"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
						</div>

            <v-col cols="6" class="text-right">
              <v-btn
                color="grey darken-2"
                class="px-4 mx-2 primaryBtn"
                small
								@click="newGuestPanel = false; newFirstPanel = true"
              >
                Atras
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                color="primary"
                class="px-4 mx-2 primaryBtn"
                small
                @click="validateGuestInfo"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newBiometricInfoPanel">
            <v-col cols="12" justify="center">
              <div>
                <p class="text-h6 mb-1 text-center font-weight-bold">
                  Validación Biometrica
                </p>
              </div>
            </v-col>
            <v-col cols="12" justify="center">
              <v-img 
                src="../../assets/oneid-1.png"
                height="120px"
                class="white--text"
                aspect-ratio="2"
                contain>
              </v-img>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-list color="transparent">
                <v-list-item>
                  <v-list-item-avatar class="mr-2">
                    <div class="circle">1</div>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-subtitle style="text-wrap: wrap;">
                      Escanea tu pasaporte o ID con tu celular.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-avatar class="mr-2">
                    <div class="circle">2</div>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-subtitle style="text-wrap: wrap;">
                      Toma una selfie para confirmar tu identidad.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-avatar class="mr-2">
                    <div class="circle">3</div>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-subtitle style="text-wrap: wrap;">
                      Después de la verificación, tu información se guardará de manera segura en OneID.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="6" class="text-right">
              <v-btn
                color="grey darken-2"
                class="px-4 mx-2 primaryBtn"
                small
								@click="getBackFromBiometricPanel"
              >
                Atras
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                color="primary"
                class="px-4 mx-2 primaryBtn"
                small
                @click="newBiometricInfoPanel = false; newCountryPanel = true"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newCountryPanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h6 mb-1 text-center font-weight-bold">
                  Que país otorgó tu identificación?
                </p>
                <label class="text-center text-caption">
                  Por favor selecciona el país en el que tu documento fue expedido
                </label>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-select
                v-model="typeDocCountry"
                :items="countryTypes"
                label="País"
                outlined
                dense
                rounded
                style="width: 10px;"
                color="white"
              ></v-select>
            </v-col>

            <v-col cols="6" class="text-right">
              <v-btn
                color="grey darken-2"
                class="px-4 mx-2 primaryBtn"
                small
								@click="newCountryPanel = false; newBiometricInfoPanel = true"
              >
                Atras
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                color="primary"
                class="px-4 mx-2 primaryBtn"
                small
                @click="newSelectedDoc = ''; newCountryPanel = false; newDocumentTypePanel = true"
								:disabled="typeDocCountry == ''"
              >
                Siguiente
              </v-btn>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newDocumentTypePanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h6 mb-1 text-center font-weight-bold">
                  Selecciona el ID que deseas verificar
                </p>
                <label class="text-center text-caption pt-2">
                  Una vez tu documento es verificado será almacenado de forma segura en OneID.
                </label>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <div style="width: 100%;">
                <div v-if="typeDocCountry == 'Colombia'" class="my-2 pl-2 py-1 country-card" :class="newSelectedDoc == 'colID' ? 'card-selected' : 'card-not-selected'" @click="newSelectedDoc = 'colID'; registry.tipoDocumento = 'CC'" width="100%">
                  <p>ID Colombiano</p>
                  <label style="cursor: pointer;">Frente y Atras</label>
                </div>
                <div v-if="typeDocCountry == 'Colombia'" class="my-2 pl-2 py-1 country-card" :class="newSelectedDoc == 'colDigID' ? 'card-selected' : 'card-not-selected'" @click="newSelectedDoc = 'colDigID'; registry.tipoDocumento = 'CC'" width="100%">
                  <p>ID Colombiano (C.C. Digital)</p>
                  <label style="cursor: pointer;">Frente</label>
                </div>
                <div v-if="typeDocCountry == 'Colombia'" class="my-2 pl-2 py-1 country-card" :class="newSelectedDoc == 'CE' ? 'card-selected' : 'card-not-selected'" @click="newSelectedDoc = 'CE'; registry.tipoDocumento = 'CE'" width="100%">
                  <p>PPT o CE</p>
                  <label style="cursor: pointer;">Frente</label>
                </div>
                <div v-if="typeDocCountry == 'Otro'" class="my-2 pl-2 py-1 country-card" :class="newSelectedDoc == 'pass' ? 'card-selected' : 'card-not-selected'" @click="newSelectedDoc = 'pass'; registry.tipoDocumento = 'PASS'" width="100%">
                  <p>Pasaporte</p>
                  <label style="cursor: pointer;">Frente</label>
                </div>
              </div>
            </v-col>

						<v-col cols="6" class="text-right">
              <v-btn
                color="grey darken-2"
                class="px-4 mx-2 primaryBtn"
                small
								@click="newDocumentTypePanel = false; newCountryPanel = true"
              >
                Atras
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                color="primary"
                class="px-4 mx-2 primaryBtn"
                small
                @click="newDocumentTypePanel = false; newDocSuggestionPanel = true"
								:disabled="newSelectedDoc == ''"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newDocSuggestionPanel">
            <v-col cols="12">
              <v-stepper v-model="e1" style="box-shadow: none">
                <v-stepper-items>
                  <v-stepper-content step="1" style="background-color: #212734;">
                    <div style="display: grid; grid-template-columns: auto auto auto;gap: 5px;">
                      <div style="grid-row: 1 / span 2; display: flex; align-self: center;">
                        <v-btn
                          icon
                          color="secondary"
                          disabled
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                      </div>
                      <div style="height: 100%;grid-row: 1 / span 2;"
                        justify="center"
                        align="center">
                        <v-img 
                          src="../../assets/oneid-2.png"
                          width="300px"
                          height="300px"
                          class="white--text"
                          aspect-ratio="2"
                          contain
                        ></v-img>
                        <p class="text-h5 text-center py-3" style="text-wrap: wrap;">Recomendación</p>
                        <p class="text-center pt-5" style="text-wrap: wrap;">Posiciona tu documento en el recuadro</p>
                      </div>
                      <div style="grid-row: 1 / span 2; display: flex; align-self: center;">
                        <v-btn
                          icon
                          color="secondary"
                          @click="e1 = 2"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="2" style="background-color: #212734;">
                    <div style="display: grid; grid-template-columns: auto auto auto;gap: 5px;">
                      <div style="grid-row: 1 / span 2; display: flex; align-self: center;">
                        <v-btn
                          icon
                          color="secondary"
                          @click="e1 = 1"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                      </div>
                      <div style="height: 100%;grid-row: 1 / span 2;"
                        justify="center"
                        align="center">
                        <v-img 
                          src="../../assets/oneid-3.png"
                          width="300px"
                          height="300px"
                          class="white--text"
                          aspect-ratio="2"
                          contain
                        ></v-img>
                        <p class="text-h5 text-center py-3" style="text-wrap: wrap;">Recomendación</p>
                        <p class="text-center pt-5" style="text-wrap: wrap;">Asegúrate que la información y foto</p>
                        <p class="text-center px-5" style="text-wrap: wrap;">queden legibles y sin reflejos</p>
                      </div>
                      <div style="grid-row: 1 / span 2; display: flex; align-self: center;">
                        <v-btn
                          icon
                          color="secondary"
                          @click="e1 = 3"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="3" style="background-color: #212734;">
                    <div style="display: grid; grid-template-columns: auto auto auto;gap: 5px;">
                      <div style="grid-row: 1 / span 2; display: flex; align-self: center;">
                        <v-btn
                          icon
                          color="secondary"
                          @click="e1 = 2"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                      </div>
                      <div style="height: 100%;grid-row: 1 / span 2;"
                        justify="center"
                        align="center">
                        <v-img 
                          src="../../assets/oneid-4.png"
                          width="300px"
                          height="300px"
                          class="white--text"
                          aspect-ratio="2"
                          contain
                        ></v-img>
                        <p class="text-h5 text-center py-3" style="text-wrap: wrap;">Recomendación</p>
                        <p class="text-center pt-5 pb-0" style="text-wrap: wrap;">Asegúrate que la imagen no quede borrosa</p>
                        <div class="px-3 pt-3 text-center" style="width: 100%">
                          <v-btn
                            color="grey darken-2"
                            class="px-4 mx-2 primaryBtn"
                            x-small
                            @click="newDocSuggestionPanel = false; newDocumentTypePanel = true"
                          >
                            Atras
                          </v-btn>
                          <v-btn
                            color="primary"
                            class="px-4 mx-2 primaryBtn"
                            x-small
                            @click="newDocSuggestionPanel = false; newDocFirstPicturePanel = true; e1 = 1"
                          >
                            Continuar
                          </v-btn>
                        </div>
                      </div>
                      <div style="grid-row: 1 / span 2; display: flex; align-self: center;">
                        <v-btn
                          icon
                          color="secondary"
                          disabled
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newDocFirstPicturePanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p v-if="newSelectedDoc === 'colID'" class="text-h6 mb-1 text-center font-weight-bold">
                  Foto documento - frontal
                </p>
								<p v-if="newSelectedDoc === 'colDigID' || newSelectedDoc === 'CE'" class="text-h6 mb-1 text-center font-weight-bold">
                  Foto documento
                </p>
								<p v-if="newSelectedDoc === 'pass'" class="text-h6 mb-1 text-center font-weight-bold">
                  Foto pasaporte
                </p>
              </div>
            </v-col>
						<!-- // * Imagen documento frontal - tomar foto desde la camara del dispositivo -->
            <div v-if="frontDocumentPhotoModule1 && !frontDocumentPhotoModule2 && !isIphone" style="width: 100%">
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <take-photo
                  :typeUse="'frontDoc'"
                  :imageName="'front-doc_' + userUUID"
                  :exampleTest="'new-version'"
                  v-on:photo-taken-front="validateFrontPicNew"
                ></take-photo>
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  x-small
                  color="secondary"
                  @click="
                    frontDocumentPhotoModule1 = !frontDocumentPhotoModule1;
                    frontDocumentPhotoModule2 = !frontDocumentPhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
							<v-row class="pa-0">
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										color="grey darken-2"
										class="px-4 mx-2 primaryBtn"
										small
										@click="newDocFirstPicturePanel = false; newDocSuggestionPanel = true"
									>
										Atras
									</v-btn>
								</v-col>
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										small
										color="primary"
										class="px-4 mx-2 primaryBtn"
										@click="checkFrontDoc"
										:loading="loadingFrontDocBtn"
										:disabled="!s3FrontDocKey"
									>
										VALIDAR Y CONTINUAR
									</v-btn>
								</v-col>
							</v-row>
            </div>
						<!-- // * Imagen de documento frontal - subir imagen de la galeria -->
            <div v-if="frontDocumentPhotoModule2 && !frontDocumentPhotoModule1" style="width: 100%">
              <v-col cols="12" class="d-flex justify-center">
                <v-img
                  v-if="frontDocImg == null"
                  src="../../assets/doc2-white.png"
                  width="300px"
                  height="150px"
                  class="white--text"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
                <v-img
                  v-if="frontDocImg != null"
                  :src="frontDocImg"
                  width="250px"
                  height="200px"
                  class="white--text align-center"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  color="success"
                  class="white--text"
                  x-small
                  @click="clickFrontDocInput"
                >
                  SUBIR DOCUMENTO LADO FRONTAL
                </v-btn>
                <input
                  type="file"
                  @change="readFrontDocFile"
                  style="display: none"
                  ref="frontDocInput"
                  accept="image/*"
                />
              </v-col>
              <v-col v-if="!isIphone" cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  x-small
                  color="secondary"
                  @click="
                    frontDocumentPhotoModule1 = !frontDocumentPhotoModule1;
                    frontDocumentPhotoModule2 = !frontDocumentPhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
							<v-row class="pa-0">
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										color="grey darken-2"
										class="px-4 mx-2 primaryBtn"
										small
										@click="newDocFirstPicturePanel = false; newDocSuggestionPanel = true"
									>
										Atras
									</v-btn>
								</v-col>
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										small
										color="primary"
										class="px-4 mx-2 primaryBtn"
										@click="validateFrontDocumentImg"
										:loading="loadingFrontDocBtn"
										:disabled="!frontDocImg"
									>
										VALIDAR Y CONTINUAR
									</v-btn>
								</v-col>
							</v-row>
            </div>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newDocSecondPicturePanel">
						<!-- // * Imagen documento posterior - Tomar foto desde la camara del dispositivo -->
            <div v-if="backDocumentPhotoModule1 && !backDocumentPhotoModule2 && !isIphone" style="width: 100%">
							<v-col cols="12" class="d-flex justify-center">
								<div>
									<p class="text-h6 mb-1 text-center font-weight-bold">
										Foto documento - posterior
									</p>
								</div>
							</v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
								<take-photo
                  :typeUse="'backDoc'"
                  :imageName="'back-doc_' + userUUID"
                  :exampleTest="'new-version'"
                  v-on:photo-taken-back="validateBackNew"
                ></take-photo>
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
								<v-btn
                  x-small
                  color="secondary"
                  @click="
                    backDocumentPhotoModule1 = !backDocumentPhotoModule1;
                    backDocumentPhotoModule2 = !backDocumentPhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
							<v-row class="pa-0">
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										color="grey darken-2"
										class="px-4 mx-2 primaryBtn"
										small
										@click="newDocSecondPicturePanel = false; newDocFirstPicturePanel = true"
									>
										Atras
									</v-btn>
								</v-col>
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										small
										color="primary"
										class="px-4 mx-2 primaryBtn"
										@click="checkBackDoc"
										:loading="loadingBackDocBtn"
										:disabled="!s3BackDocKey"
									>
										VALIDAR Y CONTINUAR
									</v-btn>
								</v-col>
							</v-row>
            </div>
						<!-- // * Imagen documento posterior - Subir iamgen de la galeria -->
            <div v-if="backDocumentPhotoModule2 && !backDocumentPhotoModule1" style="width: 100%">
							<v-col cols="12" class="d-flex justify-center">
								<div>
									<p class="text-h6 mb-1 text-center font-weight-bold">
										Foto documento - posterior
									</p>
								</div>
							</v-col>
							<v-col cols="12" class="d-flex justify-center">
                <v-img
                  v-if="backDocImg == null"
                  src="../../assets/doc-white.png"
                  width="300px"
                  height="150px"
                  class="white--text"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
                <v-img
                  v-if="backDocImg != null"
                  :src="backDocImg"
                  width="250px"
                  height="200px"
                  class="white--text align-center"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  color="success"
                  class="white--text"
                  x-small
                  @click="clickBackDocInput"
                >
                  SUBIR DOCUMENTO LADO POSTERIOR
                </v-btn>
                <input
                  type="file"
                  @change="readBackDocFile"
                  style="display: none"
                  ref="backDocInput"
                  accept="image/*"
                />
              </v-col>
              <v-col v-if="!isIphone" cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  x-small
                  color="secondary"
                  @click="
                    backDocumentPhotoModule1 = !backDocumentPhotoModule1;
                    backDocumentPhotoModule2 = !backDocumentPhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
							<v-row class="pa-0">
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										color="grey darken-2"
										class="px-4 mx-2 primaryBtn"
										small
										@click="newDocSecondPicturePanel = false; newDocFirstPicturePanel = true"
									>
										Atras
									</v-btn>
								</v-col>
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										small
										color="primary"
										class="px-4 mx-2 primaryBtn"
										@click="validateBackDocumentImg"
										:loading="loadingBackDocBtn"
										:disabled="!backDocImg"
									>
										VALIDAR Y CONTINUAR
									</v-btn>
								</v-col>
							</v-row>
            </div>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newValidateOCRPanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h6 mb-1 text-center font-weight-bold">
                  Confirma tus datos
                </p>
								<label class="text-caption text-center">Termina de llenar los campos, por favor.</label>
              </div>
            </v-col>
            <div v-if="!editMode" style="width: 100%">
							<v-col cols="12" class="d-flex justify-center pb-0">
								<p style="position: relative; right: 10%;">Nombres:</p>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pt-0">
								<label style="position: relative; left: 10%;">{{registry.firstname.toUpperCase()}} {{registry.secondname.toUpperCase()}}</label>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pb-0">
								<p style="position: relative; right: 10%;">Apellidos:</p>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pt-0">
								<label style="position: relative; left: 10%;">{{registry.surname1.toUpperCase()}} {{registry.surname2.toUpperCase()}}</label>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pb-0">
								<p style="position: relative; right: 10%;">Número Documento:</p>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pt-0">
								<label style="position: relative; left: 10%;">{{registry.id != '' ? registry.id : "---"}}</label>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pb-0">
								<p style="position: relative; right: 10%;">Fecha Expedición:</p>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pt-0">
								<label style="position: relative; left: 10%;">{{registry.expeditionDate != '' ? registry.expeditionDate : "aaaa-mm-dd"}}</label>
							</v-col>
						</div>
						<div v-if="editMode" style="width: 100%">
							<v-col cols="12" class="d-flex justify-center pb-0">
								<p style="position: relative; right: 10%;">Nombres:</p>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pt-0">
								<div style="width: 40%" class="py-0">
									<v-row class="py-0">
										<v-col cols="12" md="6" class="py-0">
											<v-text-field
												v-model="registry.firstname"
												label="Primer Nombre"
												:rules="namesRule"
												outlined
												dense
												dark
												solo
												color="white"
												class="pt-1"
											></v-text-field>
										</v-col>
										<v-col cols="12" md="6" class="py-0">
											<v-text-field
												v-model="registry.secondname"
												label="Segundo Nombre"
												outlined
												dense
												dark
												solo
												color="white"
												class="pt-1"
											></v-text-field>
										</v-col>
									</v-row>
								</div>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pb-0">
								<p style="position: relative; right: 10%;">Apellidos:</p>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pt-0">
								<div style="width: 40%" class="py-0">
									<v-row class="py-0">
										<v-col cols="12" md="6" class="py-0">
											<v-text-field
												v-model="registry.surname1"
												label="Primer Apellido"
												:rules="namesRule"
												outlined
												dense
												dark
												solo
												color="white"
												class="pt-1"
											></v-text-field>
										</v-col>
										<v-col cols="12" md="6" class="py-0">
											<v-text-field
												v-model="registry.surname2"
												label="Segundo Apellido"
												outlined
												dense
												dark
												solo
												color="white"
												class="pt-1"
											></v-text-field>
										</v-col>
									</v-row>
								</div>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pb-0">
								<p style="position: relative; right: 10%;">Número Documento:</p>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pt-0">
								<div style="width: 45%" class="py-0">
									<v-row class="py-0" justify="center">
										<v-col cols="12" md="6" class="py-0">
											<v-text-field
												v-model="registry.id"
												label="Número Documento"
												:rules="newSelectedDoc == 'pass' ? passRules : ccRules"
												outlined
												dense
												dark
												solo
												color="white"
												class="pt-1"
											></v-text-field>
										</v-col>
									</v-row>
								</div>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pb-0">
								<p style="position: relative; right: 10%;">Fecha Expedición:</p>
							</v-col>
							<v-col cols="12" class="d-flex justify-center pt-0">
								<div style="width: 45%" class="py-0">
									<v-row class="py-0" justify="center">
										<v-col cols="12" md="6" class="py-0">
											<v-menu
												v-model="datepicker1"
												:close-on-content-click="false"
												:nudge-right="40"
												transition="scale-transition"
												offset-y
												min-width="290px"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														v-model="registry.expeditionDate"
														label="Fecha de expedición"
														prepend-inner-icon="mdi-calendar"
														:rules="[(v) => !!v || 'El campo es obligatorio.']"
														readonly
														v-bind="attrs"
														v-on="on"
														outlined
														dense
														dark
														color="secondary"
														required
														solo
														class="pt-1"
													></v-text-field>
												</template>
												<v-date-picker
													v-model="registry.expeditionDate"
													@input="datepicker1 = false"
													locale="es-MX"
												></v-date-picker>
											</v-menu>
										</v-col>
									</v-row>
								</div>
							</v-col>
						</div>

						<v-col cols="12" class="text-center">
              <v-btn
								v-if="newSelectedDoc == 'ColID'"
                color="grey darken-2"
                class="px-4 mt-2 mx-2 primaryBtn"
                small
								@click="newValidateOCRPanel = false; newDocSecondPicturePanel = true"
              >
                Atras
              </v-btn>
							<v-btn
								v-else
                color="grey darken-2"
                class="px-4 mt-2 mx-2 primaryBtn"
                small
								@click="newValidateOCRPanel = false; newDocFirstPicturePanel = true"
              >
                Atras
              </v-btn>
							<v-btn
                color="secondary"
                class="px-4 mt-2 mx-2 primaryBtn"
                small
								@click="editMode = !editMode"
              >
                {{editMode == true ? 'Guardar' : 'Editar'}}
              </v-btn>
              <v-btn
                color="primary"
                class="px-4 mt-2 mx-2 primaryBtn"
                small
                @click="validateOCRView"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newSelfiePicturePanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h6 mb-1 text-center font-weight-bold">
                  Toma de selfie
                </p>
              </div>
            </v-col>
            <div v-if="selfiePhotoModule1 && !selfiePhotoModule2 && !isIphone" style="width: 100%">
              <v-col cols="12" class="d-flex justify-center">
                <take-photo
                  :typeUse="'selfie'"
                  :imageName="'selfie_' + userUUID"
                  :exampleTest="'new-version'"
                  v-on:photo-taken-selfie="validateSelfieNew"
                ></take-photo>
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
								<v-btn
                  x-small
                  color="secondary"
                  @click="
                    selfiePhotoModule1 = !selfiePhotoModule1;
                    selfiePhotoModule2 = !selfiePhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
							<v-row class="pa-0">
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										color="grey darken-2"
										class="px-4 mx-2 primaryBtn"
										small
										@click="newSelfiePicturePanel = false; newValidateOCRPanel = true"
									>
										Atras
									</v-btn>
								</v-col>
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										small
										color="primary"
										class="px-4 mx-2 primaryBtn"
										@click="checkSelfie"
										:loading="loadingSelfieBtn"
										:disabled="!s3SelfieKey"
									>
										VALIDAR Y CONTINUAR
									</v-btn>
								</v-col>
							</v-row>
            </div>
						<div v-if="selfiePhotoModule2 && !selfiePhotoModule1" style="width: 100%">
              <v-col cols="12" class="d-flex justify-center">
                <v-icon
                  v-if="selfieImg == null"
                  style="font-size: 120px"
                  x-large
                  color="grey"
                  dark
                >
                  mdi-account-box</v-icon
                >
                <v-img
                  v-if="selfieImg != null"
                  :src="selfieImg"
                  width="250px"
                  height="200px"
                  class="white--text align-center"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  color="success"
                  class="white--text"
                  x-small
                  @click="clickSelfieInput"
                >
                  SUBIR SELFIE
                </v-btn>
                <input
                  type="file"
                  @change="readSelfieFile"
                  style="display: none"
                  ref="selfieInput"
                  accept="image/*"
                />
              </v-col>
              <v-col v-if="!isIphone" cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  x-small
                  color="secondary"
                  @click="
                    selfiePhotoModule1 = !selfiePhotoModule1;
                    selfiePhotoModule2 = !selfiePhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
							<v-row class="pa-0">
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										color="grey darken-2"
										class="px-4 mx-2 primaryBtn"
										small
										@click="newSelfiePicturePanel = false; newValidateOCRPanel = true"
									>
										Atras
									</v-btn>
								</v-col>
								<v-col cols="12" md="6" class="text-center">
									<v-btn
										small
										color="primary"
										class="px-4 mx-2 primaryBtn"
										@click="validateSelfie"
										:loading="loadingSelfieBtn"
										:disabled="!selfieImg"
									>
										VALIDAR SELFIE
									</v-btn>
								</v-col>
							</v-row>
            </div>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newPhonePanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h6 mb-1 text-center font-weight-bold">
                  Número Celular
                </p>
                <label class="text-center text-caption">
                  Escribe tú número de celular para recibir el código de verificación
                </label>
              </div>
            </v-col>
						<v-col cols="12" md="4">
							<v-select
								v-model="selectCountryCode"
								label="Codigo Pais"
								:items="codeCountries"
								item-value="codigo"
								item-text="nombre_pais"
								dense
								outlined
							>
							</v-select>
						</v-col>
            <v-col cols="12" md="8">
              <v-text-field
								v-model="registry.phone"
								:rules="phoneRules"
								label="Celular"
								outlined
								dense
								dark
								color="secondary"
								required
							></v-text-field>
            </v-col>

						<v-col cols="6" class="text-right">
              <v-btn
                color="grey darken-2"
                class="px-4 mx-2 primaryBtn"
                small
								@click="newPhonePanel = false; newSelfiePicturePanel = true"
              >
                Atras
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                color="primary"
                class="px-4 mx-2 primaryBtn"
                small
                @click="sendOTPPhone"
              >
                Enviar
              </v-btn>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newPhoneValidationPanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h6 mb-1 text-center font-weight-bold">
                  Ingresa el código de seguridad
                </p>
								<label class="text-center text-caption">El código fue enviado al número <strong>{{registry.phone}}</strong></label>
              </div>
            </v-col>
            <div style="width: 100%" class="d-flex justify-center">
              <v-text-field
								v-model="otpPhoneNew"
								outlined
                solo
								dense
								dark
								color="white"
								@keydown="handleKeyDownPhone($event)"
							></v-text-field>
							<!-- <v-col v-for="(digit, index) in otpPhoneLength" :key="index" cols="auto" class="px-1">
								<v-text-field
									v-model="otpPhone[index]"
									outlined
									maxlength="1"
									solo
									dense
									color="white"
									class="digit-input"
									@input="moveToNextInputPhone(index)"
									@keydown="handleKeyDownPhone(index, $event)"
									:ref="'otpPhoneInput_' + index"
                  :style="{ fontSize: isMobile ? '24px' : '32px' }"
								></v-text-field>
							</v-col> -->
						</div>

						<v-col cols="6" class="text-right">
              <v-btn
                color="grey darken-2"
                class="px-4 mx-2 primaryBtn"
                small
								@click="newPhoneValidationPanel = false; newPhonePanel = true"
              >
                Atras
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                color="primary"
                class="px-4 mx-2 primaryBtn"
                small
                @click="verifyOTPPhone"
              >
                Confirmar
              </v-btn>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newEmailPanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h6 mb-1 text-center font-weight-bold">
                  Correo Electronico
                </p>
                <label class="text-center text-caption">
                  Escribe tú correo electronico para recibir el codigo de verificación
                </label>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-text-field
								v-model="registry.email"
								label="Correo Electronico"
								:rules="emailRules"
								outlined
								dense
								dark
								required
								color="secondary"
							></v-text-field>
            </v-col>

						<v-col cols="6" class="text-right">
              <v-btn
                color="grey darken-2"
                class="px-4 mx-2 primaryBtn"
                small
								@click="newEmailPanel = false; newPhoneValidationPanel = true"
              >
                Atras
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                color="primary"
                class="px-4 mx-2 primaryBtn"
                small
                @click="sendOTPEmail"
              >
                Enviar
              </v-btn>
            </v-col>
          </v-row>

          <!-- // TODO: NEW VISUALIZATION -->
          <v-row class="pt-3" v-if="newEmailValidationPanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h6 mb-1 text-center font-weight-bold">
                  Ingresa tú código de seguridad
                </p>
								<label class="text-center text-caption">El código fue enviado al correo <strong>{{registry.email}}</strong></label>
              </div>
            </v-col>
            <div style="width: 100%" class="d-flex justify-center">
              <v-text-field
								v-model="otpEmailNew"
								outlined
                solo
								dense
								dark
								color="white"
								@keydown="handleKeyDownEmail($event)"
							></v-text-field>
							<!-- <v-col v-for="(digit, index) in otpEmailLength" :key="index" cols="auto" class="px-1">
								<v-text-field
									v-model="otpEmail[index]"
									outlined
									maxlength="1"
									solo
									dense
									color="white"
									class="digit-input"
									@input="moveToNextInputEmail(index)"
									@keydown="handleKeyDownEmail(index, $event)"
									:ref="'otpEmailInput_' + index"
                  :style="{ fontSize: isMobile ? '24px' : '32px' }"
								></v-text-field>
							</v-col> -->
						</div>

						<v-col cols="6" class="text-right">
              <v-btn
                color="grey darken-2"
                class="px-4 mx-2 primaryBtn"
                small
								@click="newEmailValidationPanel = false; newEmailPanel = true"
              >
                Atras
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                color="primary"
                class="px-4 mx-2 primaryBtn"
                small
                @click="verifyOTPEmail"
								:loading="loadingFinalBtn"
              >
                CONFIRMAR Y TERMINAR REGISTRO
              </v-btn>
            </v-col>
          </v-row>

					<!-- // TODO: NEW VISUALIZATION -->
					<v-row class="pt-3" v-if="newSuccessRegistrationPanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h5 mb-2 text-center">Registro Exitoso</p>
                <p class="mb-3">
                  Su registro finalizó correctamente, haciendo clic en el botón 'IR' podra llevarlo al inicio de sesión de OneID.
                </p>
              </div>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                small
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="goToSignIn"
              >
                IR
              </v-btn>
            </v-col>
          </v-row>

          <!-- FIRST PANEL -->
          <v-row class="pt-3" v-if="firstPanel">
            <div v-if="selfiePhotoModule1 && !selfiePhotoModule2">
              <v-col cols="12" class="d-flex justify-center">
                <div class="text-center">
                  <p class="text-h5 pb-2">Toma de Selfie</p>
                  <p class="text-body-2">
                    Retire lentes, sombrero, gorra o mascarilla antes de hacer
                    la toma de la selfie.
                  </p>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <take-photo
                  :typeUse="'selfie'"
                  :imageName="'selfie_' + userUUID"
                  v-on:photo-taken-selfie="validateSelfieNew"
                ></take-photo>
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  x-small
                  color="secondary"
                  @click="
                    selfiePhotoModule1 = !selfiePhotoModule1;
                    selfiePhotoModule2 = !selfiePhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="text-center">
                <v-btn
                  rounded
                  color="primary"
                  class="px-4 mx-2 primaryBtn"
                  @click="checkSelfie"
                  :loading="loadingSelfieBtn"
                  :disabled="!s3SelfieKey"
                >
                  VALIDAR SELFIE
                </v-btn>
              </v-col>
            </div>
            <div v-if="selfiePhotoModule2 && !selfiePhotoModule1">
              <v-col cols="12" class="d-flex justify-center">
                <div class="text-center">
                  <p class="text-h5 pb-2">Toma de Selfie</p>
                  <p class="text-body-2">
                    Retire lentes, sombrero, gorra o mascarilla antes de hacer
                    la toma de la selfie.
                  </p>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-icon
                  v-if="selfieImg == null"
                  style="font-size: 120px"
                  x-large
                  color="grey"
                  dark
                >
                  mdi-account-box</v-icon
                >
                <v-img
                  v-if="selfieImg != null"
                  :src="selfieImg"
                  width="250px"
                  height="200px"
                  class="white--text align-center"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  color="success"
                  class="white--text"
                  small
                  @click="clickSelfieInput"
                >
                  SUBIR SELFIE
                </v-btn>
                <input
                  type="file"
                  @change="readSelfieFile"
                  style="display: none"
                  ref="selfieInput"
                  accept="image/*"
                />
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  x-small
                  color="secondary"
                  @click="
                    selfiePhotoModule1 = !selfiePhotoModule1;
                    selfiePhotoModule2 = !selfiePhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="text-center">
                <v-btn
                  rounded
                  color="primary"
                  class="px-4 mx-2 primaryBtn"
                  @click="validateSelfie"
                  :loading="loadingSelfieBtn"
                  :disabled="!selfieImg"
                >
                  VALIDAR SELFIE
                </v-btn>
              </v-col>
            </div>
          </v-row>
          <!-- PERSON ALREADY EXIST PANEL -->
          <v-row class="pt-3" v-if="registered_person_panel">
            <!-- <v-col cols="12" class="d-flex justify-center">
            <v-img
              v-if="selfieImg != null"
              :src="selfieImg"
              width="250px"
              height="200px"
              class="white--text align-center"
              
              aspect-ratio="2"
              contain
            >
            </v-img>
          </v-col> -->
            <v-col cols="12" class="d-flex justify-center">
              <p>Usted ya se encuentra registrado(a) en la plataforma.</p>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
              <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="clearRegistry"
              >
                VOLVER A INICIO
              </v-btn>
            </v-col>
          </v-row>
          <!-- SECOND PANEL -->
          <v-row
            class="pt-3"
            v-if="secondPanel && registry.tipoDocumento == ''"
          >
            <v-col cols="12">
              <v-select
                v-model="registry.tipoDocumento"
                :items="documentTypes"
                item-text="Title"
                item-value="Type"
                :rules="[(v) => !!v || 'El campo es obligatorio.']"
                label="Seleccione el tipo de documento con el que hará su registro"
                outlined
                dense
                dark
                color="secondary"
                required
              ></v-select>
            </v-col>
          </v-row>
          <!-- SECOND PANEL IMG DOCUMENT -->
          <v-row
            class="pt-3"
            v-if="
              secondPanel &&
              registry.tipoDocumento != '' &&
              registry.tipoDocumento != 'PASS'
            "
          >
            <div v-if="frontDocumentPhotoModule1 && !frontDocumentPhotoModule2">
              <v-col cols="12" class="d-flex justify-center">
                <div class="text-center">
                  <p class="text-h5 pb-2">
                    Documento de Identidad Lado Frontal
                  </p>
                  <p class="text-body-2">
                    Acerque el documento y verifique por favor que el contenido
                    de la foto se vea claramente.
                  </p>
                </div>
              </v-col>
              <v-col
                v-if="secondPanel && registry.tipoDocumento != ''"
                cols="12"
                class="d-flex justify-center"
              >
                <take-photo
                  :typeUse="'frontDoc'"
                  :imageName="'front-doc_' + userUUID"
                  v-on:photo-taken-front="validateFrontPicNew"
                ></take-photo>
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  small
                  color="secondary"
                  @click="
                    frontDocumentPhotoModule1 = !frontDocumentPhotoModule1;
                    frontDocumentPhotoModule2 = !frontDocumentPhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
                >
                ATRAS
                </v-btn> -->
                <v-btn
                  rounded
                  color="primary"
                  class="px-4 mx-2 primaryBtn"
                  @click="checkFrontDoc"
                  :loading="loadingFrontDocBtn"
                  :disabled="!s3FrontDocKey"
                >
                  VALIDAR FOTO FRONTAL
                </v-btn>
              </v-col>
            </div>
            <div v-if="frontDocumentPhotoModule2 && !frontDocumentPhotoModule1">
              <v-col cols="12" class="d-flex justify-center">
                <div class="text-center">
                  <p class="text-h5 pb-2">
                    Documento de Identidad Lado Frontal
                  </p>
                  <p class="text-body-2">
                    Acerque el documento y verifique por favor que el contenido
                    de la foto se vea claramente.
                  </p>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-img
                  v-if="frontDocImg == null"
                  src="../../assets/doc2-white.png"
                  width="300px"
                  height="150px"
                  class="white--text"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
                <v-img
                  v-if="frontDocImg != null"
                  :src="frontDocImg"
                  width="250px"
                  height="200px"
                  class="white--text align-center"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  color="success"
                  class="white--text"
                  small
                  @click="clickFrontDocInput"
                >
                  SUBIR DOCUMENTO LADO FRONTAL
                </v-btn>
                <input
                  type="file"
                  @change="readFrontDocFile"
                  style="display: none"
                  ref="frontDocInput"
                  accept="image/*"
                />
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  small
                  color="secondary"
                  @click="
                    frontDocumentPhotoModule1 = !frontDocumentPhotoModule1;
                    frontDocumentPhotoModule2 = !frontDocumentPhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                large
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
              >
                ATRAS
              </v-btn> -->
                <v-btn
                  rounded
                  color="primary"
                  class="px-4 mx-2 primaryBtn"
                  @click="validateFrontDocumentImg"
                  :loading="loadingFrontDocBtn"
                  :disabled="!frontDocImg"
                >
                  VALIDAR FOTO FRONTAL
                </v-btn>
              </v-col>
            </div>
          </v-row>

          <!-- SECOND PANEL IMG DOCUMENT PASSPORT -->
          <v-row
            class="pt-3"
            v-if="
              secondPanel &&
              registry.tipoDocumento != '' &&
              registry.tipoDocumento == 'PASS'
            "
          >
            <div v-if="frontDocumentPhotoModule1 && !frontDocumentPhotoModule2">
              <v-col cols="12" class="d-flex justify-center">
                <div class="text-center">
                  <p class="text-h5 pb-2">
                    Documento de Identidad en el Exterior - Pasaporte
                  </p>
                  <p class="text-body-2">
                    Acerque el documento y verifique por favor que el contenido
                    de la foto se vea claramente.
                  </p>
                </div>
              </v-col>
              <v-col
                v-if="secondPanel && registry.tipoDocumento != ''"
                cols="12"
                class="d-flex justify-center"
              >
                <take-photo
                  :typeUse="'frontDoc'"
                  :imageName="'front-doc_' + userUUID"
                  v-on:photo-taken-front="validateFrontPicNew"
                ></take-photo>
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  small
                  color="secondary"
                  @click="
                    frontDocumentPhotoModule1 = !frontDocumentPhotoModule1;
                    frontDocumentPhotoModule2 = !frontDocumentPhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
                >
                ATRAS
                </v-btn> -->
                <v-btn
                  rounded
                  color="primary"
                  class="px-4 mx-2 primaryBtn"
                  @click="checkFrontDoc"
                  :loading="loadingFrontDocBtn"
                  :disabled="!s3FrontDocKey"
                >
                  VALIDAR DOCUMENTO
                </v-btn>
              </v-col>
            </div>
            <div v-if="frontDocumentPhotoModule2 && !frontDocumentPhotoModule1">
              <v-col cols="12" class="d-flex justify-center">
                <div class="text-center">
                  <p class="text-h5 pb-2">
                    Documento de Identidad en el Exterior - Pasaporte
                  </p>
                  <p class="text-body-2">
                    Acerque el documento y verifique por favor que el contenido
                    de la foto se vea claramente.
                  </p>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-img
                  v-if="frontDocImg == null"
                  src="../../assets/doc2-white.png"
                  width="300px"
                  height="150px"
                  class="white--text"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
                <v-img
                  v-if="frontDocImg != null"
                  :src="frontDocImg"
                  width="250px"
                  height="200px"
                  class="white--text align-center"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  color="success"
                  class="white--text"
                  small
                  @click="clickFrontDocInput"
                >
                  SUBIR DOCUMENTO
                </v-btn>
                <input
                  type="file"
                  @change="readFrontDocFile"
                  style="display: none"
                  ref="frontDocInput"
                  accept="image/*"
                />
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  small
                  color="secondary"
                  @click="
                    frontDocumentPhotoModule1 = !frontDocumentPhotoModule1;
                    frontDocumentPhotoModule2 = !frontDocumentPhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                large
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
              >
                ATRAS
              </v-btn> -->
                <v-btn
                  rounded
                  color="primary"
                  class="px-4 mx-2 primaryBtn"
                  @click="validateFrontDocumentImg"
                  :loading="loadingFrontDocBtn"
                  :disabled="!frontDocImg"
                >
                  VALIDAR DOCUMENTO
                </v-btn>
              </v-col>
            </div>
          </v-row>

          <!-- INTERMEDIARY PANEL -->
          <v-row class="pt-3" v-if="intermediaryPanel">
            <v-col cols="12" class="text-center"> </v-col>
          </v-row>
          <!-- THIRD PANEL -->
          <v-row class="pt-3" v-if="thirdPanel">
            <div v-if="backDocumentPhotoModule1 && !backDocumentPhotoModule2">
              <v-col cols="12" class="d-flex justify-center">
                <div class="text-center">
                  <p class="text-h5 pb-2">
                    Documento de Identidad Lado Posterior
                  </p>
                  <p class="text-body-2">
                    Acerque el documento y verifique por favor que el contenido
                    de la foto se vea claramente.
                  </p>
                </div>
              </v-col>
              <v-col v-if="thirdPanel" cols="12" class="d-flex justify-center">
                <take-photo
                  :typeUse="'backDoc'"
                  :imageName="'back-doc_' + userUUID"
                  v-on:photo-taken-back="validateBackNew"
                ></take-photo>
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  small
                  color="secondary"
                  @click="
                    backDocumentPhotoModule1 = !backDocumentPhotoModule1;
                    backDocumentPhotoModule2 = !backDocumentPhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                class="px-4 mx-2 primaryBtn"
                @click="thirdPanel = false ; secondPanel = true"
              >
                ATRAS
              </v-btn> -->
                <v-btn
                  rounded
                  color="primary"
                  class="px-4 mx-2 primaryBtn"
                  @click="checkBackDoc"
                  :loading="loadingBackDocBtn"
                  :disabled="!s3BackDocKey"
                >
                  VALIDAR POSTERIOR
                </v-btn>
              </v-col>
            </div>
            <div v-if="backDocumentPhotoModule2 && !backDocumentPhotoModule1">
              <v-col cols="12" class="d-flex justify-center">
                <div class="text-center">
                  <p class="text-h5 pb-2">
                    Documento de Identidad Lado Posterior
                  </p>
                  <p class="text-body-2">
                    Acerque el documento y verifique por favor que el contenido
                    de la foto se vea claramente.
                  </p>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-img
                  v-if="backDocImg == null"
                  src="../../assets/doc-white.png"
                  width="300px"
                  height="150px"
                  class="white--text"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
                <v-img
                  v-if="backDocImg != null"
                  :src="backDocImg"
                  width="250px"
                  height="200px"
                  class="white--text align-center"
                  aspect-ratio="2"
                  contain
                >
                </v-img>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  color="success"
                  class="white--text"
                  small
                  @click="clickBackDocInput"
                >
                  SUBIR DOCUMENTO LADO POSTERIOR
                </v-btn>
                <input
                  type="file"
                  @change="readBackDocFile"
                  style="display: none"
                  ref="backDocInput"
                  accept="image/*"
                />
              </v-col>
              <v-col cols="12" class="d-flex justify-center ma-0">
                <v-btn
                  small
                  color="secondary"
                  @click="
                    backDocumentPhotoModule1 = !backDocumentPhotoModule1;
                    backDocumentPhotoModule2 = !backDocumentPhotoModule2;
                  "
                >
                  Cambiar Modo
                </v-btn>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                large
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
              >
                ATRAS
              </v-btn> -->
                <v-btn
                  rounded
                  color="primary"
                  class="px-4 mx-2 primaryBtn"
                  @click="validateBackDocumentImg"
                  :loading="loadingBackDocBtn"
                  :disabled="!backDocImg"
                >
                  VALIDAR FOTO POSTERIOR
                </v-btn>
              </v-col>
            </div>
          </v-row>
          <!-- FOURTH PANEL -->
          <v-form v-if="fourthPanel" ref="form" v-model="valid" lazy-validation>
            <v-row class="pt-3" v-if="fourthPanel">
              <v-col cols="12" md="12" class="py-1 px-3">
                <!-- <p>** En caso de que su documento no sea una Cédula de Ciudadanía, seleccione el tipo correcto.</p> -->
                <p
                  v-if="registry.tipoDocumento == 'CC'"
                  class="mb-2 grey--text"
                >
                  * Valide los datos que se extrajeron del documento y en caso
                  de no ser correctos modifiquelos por favor.
                </p>
                <p class="mb-2 grey--text">
                  * Todos los campos son obligatorios.
                </p>
                <p class="mb-2 grey--text">
                  * Es importante que ingrese su numero celular y su correo de
                  manera correcta.
                </p>
              </v-col>
              <!-- <v-col cols="12" md="4" class="py-1 px-2">
                <v-select
                v-model="registry.tipoDocumento"
                :items="documentTypes"
                item-text="Title"
                item-value="Type"
                :rules="[(v) => !!v || 'El campo es obligatorio.']"
                label="Tipo Documento"
                outlined
                dense
                dark
                color="secondary"
                required
                ></v-select>
            </v-col> -->
              <v-col cols="12" md="6" class="py-1 px-3">
                <v-text-field
                  v-model="registry.id"
                  :rules="
                    registry.tipoDocumento == 'PASS' ? passRules : ccRules
                  "
                  label="Número Documento"
                  outlined
                  dense
                  dark
                  color="secondary"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-1 px-3">
                <v-menu
                  v-model="datepicker1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="expeditionDatePicker"
                      label="Fecha de expedición"
                      prepend-inner-icon="mdi-calendar"
                      :rules="[(v) => !!v || 'El campo es obligatorio.']"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      dark
                      color="secondary"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expeditionDatePicker"
                    @input="datepicker1 = false"
                    locale="es-MX"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="py-1 px-3">
                <v-text-field
                  v-model="registry.firstname"
                  :rules="namesRule"
                  label="Primer Nombre"
                  outlined
                  dark
                  dense
                  color="secondary"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-1 px-3">
                <v-text-field
                  v-model="registry.secondname"
                  label="Segundo Nombre"
                  :rules="secondNameRule"
                  outlined
                  dense
                  dark
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-1 px-3">
                <v-text-field
                  v-model="registry.surname1"
                  :rules="namesRule"
                  label="Primer Apellido"
                  outlined
                  dense
                  dark
                  color="secondary"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-1 px-3">
                <v-text-field
                  v-model="registry.surname2"
                  label="Segundo Apellido"
                  :rules="namesRule"
                  outlined
                  dense
                  dark
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-1 px-3">
                <v-text-field
                  v-model="registry.phone"
                  :rules="phoneRules"
                  label="Celular"
                  outlined
                  dense
                  dark
                  color="secondary"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-1 px-3">
                <v-text-field
                  v-model="registry.email"
                  label="Correo Electronico"
                  :rules="emailRules"
                  outlined
                  dense
                  dark
                  required
                  color="secondary"
                ></v-text-field>
              </v-col>
              <!-- 

            <v-col cols="12" md="3" class="py-1 px-3">
                <v-select
                v-model="registry.sex"
                :items="genres"
                item-text="Title"
                item-value="Type"
                :rules="[(v) => !!v || 'El campo es obligatorio.']"
                label="Género"
                outlined
                dark
                color="secondary"
                required
                ></v-select>
            </v-col>
            <v-col cols="12" md="3" class="py-1 px-3">
                <v-select
                v-model="registry.blood"
                :items="bloodTypes"
                item-text="Title"
                :rules="[(v) => !!v || 'El campo es obligatorio.']"
                label="Tipo de Sangre"
                outlined
                dark
                color="secondary"
                required
                ></v-select>
            </v-col> -->
              <v-divider></v-divider>
              <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                class="px-4 mx-2 mb-2 primaryBtn"
                @click="fourthPanel = false ; thirdPanel = true"
                >
                ATRAS
                </v-btn> -->
                <v-btn
                  :disabled="!valid"
                  rounded
                  color="primary"
                  class="px-4 mx-2 mb-2 primaryBtn"
                  :loading="loadingRegistryCreationBtn"
                  @click="createRegistry(registry)"
                >
                  CREAR REGISTRO
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <!-- FINAL VISITOR PANEL -->
          <v-row class="pt-3" v-if="successRegistrationVisitorPanel">
            <v-col cols="12" class="d-flex justify-center">
              <div>
                <p class="text-h5 mb-2 text-center">Registro Exitoso</p>
                <p class="mb-3">
                  Su registro se finalizó correctamente, la siguiente
                  información es acerca de su visita programada.
                </p>
                <p class="mb-2">
                  Lugar de la visita:
                  <strong class="grey--text">
                    {{ scheduleVisitSede.Campus[0].name }}
                  </strong>
                </p>
                <p>Intervalo de fecha y horario habilitado para su ingreso:</p>
                <p>
                  Desde:
                  <strong class="grey--text">{{
                    scheduleVisit.start_date.substring(0, 10)
                  }}</strong>
                  a las:
                  <strong class="grey--text">{{
                    scheduleVisit.start_date.substring(11, 16)
                  }}</strong>
                </p>
                <p>
                  Hasta:
                  <strong class="grey--text">{{
                    scheduleVisit.finish_date.substring(0, 10)
                  }}</strong>
                  a las:
                  <strong class="grey--text">{{
                    scheduleVisit.finish_date.substring(11, 16)
                  }}</strong>
                </p>
                <div v-if="scheduleVisit.vehicle == true">
                  <p class="mt-2">
                    Adicionalmente se habilitó la celda
                    <strong class="grey--text">{{
                      scheduleVisit.parking_cell
                    }}</strong>
                    para el vehículo con placa:
                    <strong class="grey--text">{{
                      scheduleVisit.plate
                    }}</strong>
                  </p>
                </div>
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
              <v-btn
                small
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="clearRegistry"
              >
                TERMINAR
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="QRGenerated" class="pt-5">
            <v-col cols="12" md="6" class="d-flex justify-center align-center">
              <p class="font-weight-medium text-center text-h6">
                Ubique este código QR en el lector del dispositivo para proceder
                con su registro.
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <qrcode-vue
                class="d-flex justify-center align-center"
                :value="QRValue"
                :size="size"
                level="H"
              />
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-center align-center">
              <v-btn
                block
                color="error"
                class="mr-4"
                @click="
                  QRGenerated = false;
                  QRValue = '';
                  registry.id = '';
                "
              >
                ATRAS
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-center align-center">
              <v-btn block color="primary" class="mr-4" @click="clearRegistry">
                GENERAR NUEVO QR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-snackbar
          v-model="snackbar"
          timeout="10000"
          :color="snackbarColor"
          top
        >
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { S3 } from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import globals from "../../globals";
import axios from "axios";
import TakePhoto from "../utils/take-photo.vue";
import HabeasData from "./habeas-data.vue";
//import auth from "../../services/auth";
//import backlog from "../services/logs";

export default {
  name: "RegistryPeople",
  components: {
    QrcodeVue,
    TakePhoto,
    HabeasData,
  },
  data: () => ({
    datepicker1: false,
    expeditionDatePicker: "",
    QRValue: "",
    size: 250,
    QRGenerated: false,
    valid: true,
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    namesRule: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        /^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/.test(v) ||
        "El texto no debe tener caracteres especiales, tildes o números.",
    ],
    secondNameRule: [
      (v) =>
        /^$|[A-Za-z _]*[A-Za-z][A-Za-z _]*$/.test(v) ||
        "El texto no debe tener caracteres especiales, tildes o números.",
    ],
    ccRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        (v && v.length >= 6) || "El documento debe tener minimo 6 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El documento solo debe tener números",
    ],
    passRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        (v && v.length >= 7) || "El documento debe tener minimo 7 digitos.",
      (v) =>
        /^[0-9A-Za-z _]*[0-9A-Za-z][0-9A-Za-z _]*$/.test(v) ||
        "El documento no debe tener caracteres especiales o tildes.",
    ],
    phoneRules: [
      (v) => !!v || "El campo es obligatorio.",
      /* (v) =>
        (v && v.length == 10) || "El número celular debe tener 10 digitos.", */
      (v) => /^[0-9]*$/.test(v) || "El campo solo permite números",
    ],
    emailRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "El correo electronico no esta formado correctamente",
    ],
    documentTypes: [
      { Type: "CC", Title: "Cédula de Ciudadanía" },
      { Type: "CE", Title: "Cédula de Extranjería" },
      { Type: "TI", Title: "Tarjeta de Identidad" },
      { Type: "PASS", Title: "Pasaporte" },
    ],
    genres: [
      { Type: "M", Title: "MASCULINO" },
      { Type: "F", Title: "FEMENINO" },
    ],
    bloodTypes: ["A+", "B+", "O+", "AB+", "A-", "B-", "O-", "AB-"],
    registry: {
      tipoDocumento: "",
      id: "",
      firstname: "",
      secondname: "",
      surname1: "",
      surname2: "",
      birthdate: "",
      expeditionDate: "",
      sex: "",
      blood: "",
      phone: "",
      email: "",
    },
    userUUID: "",
    companyID: null,
    roleID: null,
    suborgID: null,
    loadingSelfieBtn: false,
    loadingFrontDocBtn: false,
    loadingBackDocBtn: false,
    loadingRegistryCreationBtn: false,

    selfieImg: null,
    frontDocImg: null,
    backDocImg: null,

    infoPanel: true,
    firstPanel: false,
    registered_person_panel: false,
    secondPanel: false,
    intermediaryPanel: false,
    thirdPanel: false,
    fourthPanel: false,
    successRegistrationVisitorPanel: false,

    selfieBase64: null,
    frontDocBase64: null,
    backDocBase64: null,

    s3SelfieKey: "",
    s3FrontDocKey: "",
    s3BackDocKey: "",

    selfieFile: null,
    frontDocFile: null,
    backDocFile: null,

    registered_person: "",
    scheduleVisit: null,
    scheduleVisitSede: null,

    allowedAccess: false,
    todayDate: globals.COdate().substring(0, 10),
    habeasData: false,
    habeasDataDialog: false,
    iReadPolicy: false,
    empresaHabeasID: "",
    // * -------------------------------------
    selfiePhotoModule1: true,
    selfiePhotoModule2: false,
    frontDocumentPhotoModule1: true,
    frontDocumentPhotoModule2: false,
    backDocumentPhotoModule1: true,
    backDocumentPhotoModule2: false,

    // * --------------------------------------
    newFirstPanel: false,
    newGuestPanel: false,
    newBiometricInfoPanel: false,
    newCountryPanel: false,
    newDocumentTypePanel: false,
    newDocSuggestionPanel: false,
    newDocFirstPicturePanel: false,
    newDocSecondPicturePanel: false,
    newValidateOCRPanel: false,
    newSelfiePicturePanel: false,
    newPhonePanel: false,
    newPhoneValidationPanel: false,
    newEmailPanel: false,
    newEmailValidationPanel: false,
		newSuccessRegistrationPanel: false,

		loadingFinalBtn: false,
    countryTypes: ["Colombia", "Otro"],
    typeDocCountry: "",
		newSelectedDoc: "",
		otpPhone: Array(4).fill(''),
    otpPhoneNew: "",
		otpEmail: Array(6).fill(''),
    otpEmailNew: "",
		editMode: false,
		selectCountryCode: "",
    isMobile: false,
    isIphone: false,
		codeCountries: [
			{
				"codigo": "+57",
				"nombre_pais": "Colombia (+57)"
			},
			{ 
				"codigo": "+1" ,
				"nombre_pais": "Canadá (+1)"
			},
			{
				"codigo": "+1",
				"nombre_pais": "Estados Unidos (+1)"
			},
			{
				"codigo": "+52",
				"nombre_pais": "México (+52)"
			},
			{
				"codigo": "+54",
				"nombre_pais": "Argentina (+54)"
			},
			{
				"codigo": "+591",
				"nombre_pais": "Bolivia (+591)"
			},
			{
				"codigo": "+55",
				"nombre_pais": "Brasil (+55)"
			},
			{
				"codigo": "+56",
				"nombre_pais": "Chile (+56)"
			},
			{
				"codigo": "+593",
				"nombre_pais": "Ecuador (+593)"
			},
			{
				"codigo": "+595",
				"nombre_pais": "Paraguay (+595)"
			},
			{
				"codigo": "+51",
				"nombre_pais": "Perú (+51)"
			},
			{
				"codigo": "+598",
				"nombre_pais": "Uruguay (+598)"
			},
			{
				"codigo": "+58",
				"nombre_pais": "Venezuela (+58)"
			},
			{
				"codigo": "+49",
				"nombre_pais": "Alemania (+49)"
			},
			{
				"codigo": "+34",
				"nombre_pais": "España (+34)"
			},
			{
				"codigo": "+33",
				"nombre_pais": "Francia (+33)"
			},
			{
				"codigo": "+39",
				"nombre_pais": "Italia (+39)"
			},
			{
				"codigo": "+351",
				"nombre_pais": "Portugal (+351)"
			},
			{
				"codigo": "+44",
				"nombre_pais": "Reino Unido (+44)"
			}
		],
    guest: {
      typeGuest: null,
      placeGuestCode: "",
      checkinDate: "",
      checkoutDate: "",
    },
    datepickerCheckin: false,
    datepickerCheckout: false,
    e1: 1,
  }),
  computed: {
    config() {
      return {
        bucketName: "vanguard-id",
        region: process.env.VUE_APP_S3_REGION,
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
        signatureVersion: "v4",
      };
    },

    S3Client() {
      return new S3(this.config);
    },

    otpPhoneLength() {
      return 4; // Longitud del código OTP para celular
    },

		otpEmailLength() {
      return 6; // Longitud del código OTP para correo
    }
  },
  methods: {
    getBackFromBiometricPanel() {
      if (this.companyID == 'ce7c32ef-2625-45a6-9c7f-f70b1b9919ac') {
        this.newDocumentTypePanel = false
        this.newGuestPanel = true  
      } else {
        this.newBiometricInfoPanel = false
        this.newFirstPanel = true
      }
    },

    moveToNextInputPhone(index) {
      if (this.otpPhone[index].length === 1 && index < this.otpPhone.length - 1) {
        this.$nextTick(() => {
          this.$refs['otpPhoneInput_' + (index + 1)][0].focus();
        });
      }
    },

		handleKeyDownPhone(/* index, */ event) {
			const keyCode = event.keyCode;
      if (!(keyCode >= 48 && keyCode <= 57) && keyCode !== 8 && keyCode !== 46) {
        event.preventDefault();
      }/*  else if (keyCode === 8 && index > 0 && !this.otpPhone[index]) {
        // Si se presiona la tecla de retroceso y el campo actual está vacío, enfocar el campo anterior
        this.$refs['otpPhoneInput_' + (index - 1)][0].focus();
      } */
    },

		moveToNextInputEmail(index) {
      if (this.otpEmail[index].length === 1 && index < this.otpEmail.length - 1) {
        this.$nextTick(() => {
          this.$refs['otpEmailInput_' + (index + 1)][0].focus();
        });
      }
    },

		handleKeyDownEmail(/* index, */ event) {
      const keyCode = event.keyCode;
      if (!(keyCode >= 48 && keyCode <= 57) && keyCode !== 8 && keyCode !== 46) {
        event.preventDefault();
      }/*  else if (keyCode === 8 && index > 0 && !this.otpEmail[index]) {
        // Si se presiona la tecla de retroceso y el campo actual está vacío, enfocar el campo anterior
        this.$refs['otpEmailInput_' + (index - 1)][0].focus();
      } */
    },

		sendOTPPhone() {
      if (this.selectCountryCode && this.registry.phone) {
        if (this.selectCountryCode == '+57') {
          axios({
            method: "POST",
            baseURL: globals.APIURL + "registry/phone-otp-request",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            data: {
              phone: this.selectCountryCode + this.registry.phone,
            },
          })
            .then((res) => {
              if (res.status == 200) {
                this.newPhonePanel = false; 
                this.newPhoneValidationPanel = true
                this.snackbar = true
                this.snackbarColor = "success"
                this.snackbarText = "Se envió el código correctamente."
              }
            })
            .catch(() => {
              this.snackbar = true
              this.snackbarColor = "error" 
              this.snackbarText = "Por favor vaide que el número ingresado es correcto."
            });
        } else {
          this.newPhonePanel = false
          this.newEmailPanel = true
        }
      } else {
        this.snackbar = true
        this.snackbarColor = "error"
        this.snackbarText = "Por favor valide que haya seleccionado un pais y haya llenado el campo del celular."
      }
		},

		verifyOTPPhone() {
			let otpCode = this.otpPhoneNew//this.otpPhone.join('')
			let phoneNumber = this.selectCountryCode + this.registry.phone
			if (otpCode != '') {
				axios({
					method: "PUT",
					baseURL: globals.APIURL + "registry/phone-otp-validation",
					headers: {
						"Access-Control-Allow-Origin": "*",
					},
					data: {
						phone: phoneNumber,
						otpCode: otpCode
					},
				})
					.then((res) => {
						console.log("RESPUESTA VALIDACION OTP PHONE: ", res)
						if (res.status == 200) {
							if (res.data.status == 'SUCCESSFUL') {
								this.newPhoneValidationPanel = false;
								this.newEmailPanel = true
								this.snackbar = true
								this.snackbarColor = "success"
								this.snackbarText = "Validación exitosa."
							} else if (res.data.status == 'FAIL' && res.data.message.status == 'Expired') {
								this.snackbar = true
								this.snackbarColor = "error"
								this.snackbarText = "El código ya expiró."
							} else {
								this.snackbar = true
								this.snackbarColor = "error" 
								this.snackbarText = "Por favor valide que el número ingresado es correcto."		
							}
						}
					})
					.catch(() => {
						this.snackbar = true
						this.snackbarColor = "error" 
						this.snackbarText = "Por favor valide que el número ingresado es correcto."
					});
			} else {
				this.snackbar = true
				this.snackbarColor = "error" 
				this.snackbarText = "Por favor ingrese un código para continuar"
			}
		},

		sendOTPEmail() {
			if (this.registry.email) {
				axios({
					method: "POST",
					baseURL: globals.APIURL + "registry/email-otp-request",
					headers: {
						"Access-Control-Allow-Origin": "*",
					},
					data: {
						email: this.registry.email,
					},
				})
					.then((res) => {
						if (res.status == 200) {
							this.newEmailPanel = false; 
							this.newEmailValidationPanel = true
							this.snackbar = true
							this.snackbarColor = "success"
							this.snackbarText = "Se envió el código correctamente."
						}
					})
					.catch(() => {
						this.snackbar = true
						this.snackbarColor = "error" 
						this.snackbarText = "Por favor valide que el correo ingresado sea el correcto."
					});
			} else {
				this.snackbar = true
				this.snackbarColor = "error"
				this.snackbarText = "Por favor valide que haya llenado el campo correctamente."
			}
		},

		verifyOTPEmail() {
			this.loadingFinalBtn = true
			let otpCode = this.otpEmailNew//this.otpEmail.join('')
			
			if (otpCode) {
				axios({
					method: "PUT",
					baseURL: globals.APIURL + "registry/email-otp-validation",
					headers: {
						"Access-Control-Allow-Origin": "*",
					},
					data: {
						email: this.registry.email,
						otpCode: otpCode
					},
				})
					.then((res) => {
						if (res.status == 200 && res.data == 'Successful validation') {
							this.createRegistry();
							/* this.snackbar = true
							this.snackbarColor = "success"
							this.snackbarText = "Se validó satisfactoriamente el correo." */
						}
						//this.loadingFinalBtn = false
					})
					.catch(() => {
						this.snackbar = true
						this.snackbarColor = "error" 
						this.snackbarText = "Por favor valide que el correo ingresado es correcto."
						this.loadingFinalBtn = false
					});
			} else {
				this.snackbar = true
				this.snackbarColor = "error" 
				this.snackbarText = "Por favor ingrese un código para continuar"
				this.loadingFinalBtn = false
			}
		},

		goToSignIn() {
			this.$router.push("/oneid");
		},

		validateNames() {
			if (this.registry.firstname && this.registry.surname1) {
				this.newFirstPanel = false;
				this.companyID == 'ce7c32ef-2625-45a6-9c7f-f70b1b9919ac' ? this.newGuestPanel = true : this.newBiometricInfoPanel = true
			} else {
				this.snackbar = true;
				this.snackbarColor = "error"
				this.snackbarText = "Es necesario que pongas al menos tu primer nombre y primer apellido"
			}
		},

    validateGuestInfo() {
			if (this.guest.typeGuest && this.guest.checkinDate && this.guest.checkoutDate) {
				if ((this.guest.typeGuest == 'guest' && this.guest.placeGuestCode != '') || (this.guest.typeGuest != 'guest' && !this.guest.placeGuestCode)) {
          this.newGuestPanel = false; 
          this.newBiometricInfoPanel = true
        } else {
          this.snackbar = true;
          this.snackbarColor = "error"
          this.snackbarText = "Por favor ingrese el número de habitación o cabaña."
        }
			} else {
				this.snackbar = true;
				this.snackbarColor = "error"
				this.snackbarText = "Todos los campos son obligatorios"
			}
		},

		validateOCRView() {
			if (this.registry.firstname && this.registry.surname1 && this.registry.id && this.registry.expeditionDate) {
        axios({
          method: "POST",
          baseURL: globals.APIURL + "employee/fob",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          data: {
            filter: {
              "document_number": this.registry.id,
            }
          },
        })
          .then((res) => {
            if (res.data && res.data == 'Person not found') {
              this.newValidateOCRPanel = false;
              this.newSelfiePicturePanel = true;
            } else {
              this.snackbar = true
              this.snackbarColor = "error"
              this.snackbarText = "Usted ya se encuentra registrado(a) en la plataforma."
              this.clearRegistry()
            }
          })
          .catch((err) => {
            console.log(err)
          });
			} else {
				this.snackbar = true
				this.snackbarColor = "error"
				this.snackbarText = "El primer nombre, primer apellido, número documento y fecha de expedición son campos obligatorios"
			}
		},
    // ** ----------------------------------------------------------------
    validateAccess() {
      if (this.$route.query.exp) {
        let expirationCode = this.$route.query.exp;
        let expirationTimestamp = expirationCode.substring(
          3,
          expirationCode.length
        );
        expirationTimestamp = parseInt(expirationTimestamp);
        let timestampToDate = new Date(expirationTimestamp).toISOString();

        if (
          this.todayDate /* "2022-12-01" */ <= timestampToDate.substring(0, 10)
        ) {
          this.allowedAccess = true;
          this.$route.query.id ? (this.companyID = this.$route.query.id) : "";

          this.$route.query.id2 ? (this.roleID = this.$route.query.id2) : "";

          this.$route.query.id3 ? (this.suborgID = this.$route.query.id3) : "";

          if (this.$route.query.id == "dea02cee-7c13-4bfd-9f6d-87b4d80efd17") {
            this.empresaHabeasID = this.$route.query.pl;
          }

          if (this.$route.query.id4) {
            this.getScheduledVisit(this.$route.query.id4);
          }
          this.userUUID = uuidv4();
          if (
            this.userUUID == "" ||
            this.userUUID == undefined ||
            this.userUUID == null
          ) {
            this.userUUID = uuidv4();
          }
        }
      }
    },

    validateSelfieNew(data) {
      if (data && data.key) {
        //console.log("validateSelfieNew: ", data)
        this.s3SelfieKey = data.key;
      }
    },

    validateFrontPicNew(data) {
      if (data && data.key) {
        //console.log("validateFrontPicNew: ", data)
        this.s3FrontDocKey = data.key;
      }
    },

    validateBackNew(data) {
      if (data && data.key) {
        this.s3BackDocKey = data.key;
      }
    },

    checkSelfie() {
      this.loadingSelfieBtn = true;

      axios({
        method: "POST",
        baseURL: globals.APIURL + "identity/auth-person",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          login_img_key: this.s3SelfieKey,
        },
      })
        .then((res) => {
          if (res.data.document_number) {
            /* this.firstPanel = false;
            this.registered_person_panel = true; */
            this.loadingSelfieBtn = false;
            this.registered_person = res.data.document_number;
						this.snackbar = true
						this.snackbarColor = "error"
						this.snackbarText = "Usted ya se encuentra registrado(a) en la plataforma."
						this.clearRegistry()
          } else {
            this.checkFaceFeatures();
          }
        })
        .catch((err) => {
          //console.log(err.response.data.Message)
          if (err.response.data.Message === "No matching faces.") {
            this.checkFaceFeatures();
          } else {
            this.loadingSelfieBtn = false;
          }
        });
    },

    checkFrontDoc() {
      this.loadingFrontDocBtn = true;
      //this.secondPanel = false;
      //this.intermediaryPanel = true;

      // this.compareFaces(); //TODO: COMMENTED FOR A WHILE
      //this.documentHasFace();
      if (this.registry.tipoDocumento == "CC") {
        axios({
          method: "POST",
          baseURL: globals.APIURL + "identity/extract-id-number",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          data: {
            FrontDocumentImage: this.s3FrontDocKey,
          },
        })
          .then((res) => {
            if (res.data) {
              if (res.data.typeIsCorrect === true) {
                let cedula = res.data.textDetectData.DocumentNumber.replace(/\D/g, '');
                //console.log(res.data)
                //console.log("CEDULA: ", cedula)
                this.registry.id = cedula ? cedula : "";
                if (cedula !== "") {
                  this.searchPersonData(cedula);
                } else {
                  if (this.newSelectedDoc == 'colID') {
                    this.newDocSecondPicturePanel = true
                    this.loadingFrontDocBtn = false;
                    this.newDocFirstPicturePanel = false
                  } else {
                    this.newValidateOCRPanel = true
                    this.loadingFrontDocBtn = false;
                    this.newDocFirstPicturePanel = false
                  }
                }
              } else {
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText = "El documento no es una cédula, por favor verifique que el documento se vea completo en la imagen y sea el correcto.";
                this.loadingFrontDocBtn = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.loadingFrontDocBtn = false;
          });
      } else if (this.registry.tipoDocumento == "PASS") {
        axios({
          method: "POST",
          baseURL: globals.APIURL + "identity/extract-pass-data",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          data: {
            FrontDocumentImage: this.s3FrontDocKey,
          },
        })
          .then((res) => {
            if (res.data) {
              if (res.data.typeIsCorrect === true) {
                // console.log("ID PASSPORT", res.data[0].DetectedText);
                let document =  res.data.passExtraction.length > 0 ? res.data.passExtraction[0].DetectedText : "";
                this.registry.tipoDocumento = "PASS";
                this.registry.id = document ? document : "";
                this.newDocFirstPicturePanel = false
                this.newValidateOCRPanel = true
                this.loadingFrontDocBtn = false;
              } else {
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText = "El documento no es un pasaporte, por favor verifique que el documento se vea completo en la imagen y sea el correcto.";
                this.loadingFrontDocBtn = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.loadingFrontDocBtn = false;
          });
      } else if (this.registry.tipoDocumento == "CE") {
        axios({
          method: "POST",
          baseURL: globals.APIURL + "identity/extract-ppt-data",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          data: {
            FrontDocumentImage: this.s3FrontDocKey,
          },
        })
          .then((res) => {
            if (res.data) {
              if (res.data.typeIsCorrect === true) {
                //console.log("ID CE o PPT", res.data.pptDetection);
                let document = res.data.pptDetection && res.data.pptDetection !== '' ? res.data.pptDetection : "";
                this.registry.tipoDocumento = "CE";
                this.registry.id = document ? document : "";
                this.newDocFirstPicturePanel = false
                this.newValidateOCRPanel = true
                this.loadingFrontDocBtn = false;
              } else {
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText = "El documento no es PPT, por favor verifique que el documento se vea completo en la imagen y sea el correcto.";
                this.loadingFrontDocBtn = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.loadingFrontDocBtn = false;
          });
      }
    },

    checkBackDoc() {
      /* this.thirdPanel = false;
      this.fourthPanel = true; */
			//this.newDocSecondPicturePanel = false;
			//this.newValidateOCRPanel = true;
      this.loadingBackDocBtn = true;
      this.extractExpeditionDate(this.s3BackDocKey);
    },

    extractExpeditionDate(imgURL) {
      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/vbd",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          BackDocumentImage: imgURL
        },
      }).then((res) => {
          if (res.data) {
            if (res.data.DetectedText) {
              let responseDate = res.data.DetectedText.replace(/[^\d.-]/g, '');
              let expeditionDay = responseDate.substr(0, 2);
              let expeditionMonth = responseDate.substr(3, 2);
              let expeditionYear = responseDate.substr(6, 4);
              this.registry.expeditionDate = expeditionYear + "-" + expeditionMonth + "-" + expeditionDay;
              this.loadingBackDocBtn = false;
              this.newDocSecondPicturePanel = false;
              this.newValidateOCRPanel = true;
            } else {
              this.loadingBackDocBtn = false;
              this.newDocSecondPicturePanel = false;
              this.newValidateOCRPanel = true;
              this.snackbar = true;
              this.snackbarColor = "error";
              this.snackbarText = "Debido a la calidad de la imagen tomada, no se pudo extraer la fecha de expedición. Ingresela manualmente.";
            }
          }
        })
        .catch(() => {
          this.loadingBackDocBtn = false;
          //console.log(err);
        });
    },
    // ** ----------------------------------------------------------------
    /* ---------- SELFIE READ IMG CODE BLOCK ---------- */
    clickSelfieInput() {
      this.$refs.selfieInput.click();
    },

    readSelfieFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        //this.userUUID = uuidv4()
        this.selfieImg = fileReader.result;
        //this.selfieFile = files[0]
        this.resizeAndCompressBase64Img1(this.selfieImg);
        //const searchTerm = "base64,";
        //let base64Code = fileReader.result;
        //this.selfieBase64 = base64Code.substr(base64Code.indexOf(searchTerm) + searchTerm.length);
      });
      fileReader.readAsDataURL(files[0]);
    },

    /* ---------- FRONT DOC READ IMG CODE BLOCK ---------- */
    clickFrontDocInput() {
      this.$refs.frontDocInput.click();
    },

    readFrontDocFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.frontDocImg = fileReader.result;
        //this.frontDocFile = files[0]
        this.resizeAndCompressBase64Img2(this.frontDocImg);
        //const searchTerm = "base64,";
        //let base64Code = fileReader.result;
        //this.frontDocBase64 = base64Code.substr(base64Code.indexOf(searchTerm) + searchTerm.length);
      });
      fileReader.readAsDataURL(files[0]);
    },

    /* ---------- BACK DOC READ IMG CODE BLOCK ---------- */
    clickBackDocInput() {
      this.$refs.backDocInput.click();
    },

    readBackDocFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.backDocImg = fileReader.result;
        //this.backDocFile = files[0]
        this.resizeAndCompressBase64Img3(this.backDocImg);
        //const searchTerm = "base64,";
        //let base64Code = fileReader.result;
        //this.backDocBase64 = base64Code.substr(base64Code.indexOf(searchTerm) + searchTerm.length);
      });
      fileReader.readAsDataURL(files[0]);
    },

    /* ---------- VALIDATE MASK & GLASSES ---------- */
    resizeAndCompressBase64Img1(base64) {
      console.log("ENTRE AL 1RO");
      let resizingFactor = 0.5;
      const canvas1 = document.createElement("canvas");
      const context1 = canvas1.getContext("2d");
      let img1 = document.createElement("img");
      img1.src = base64;

      const originalWidth = img1.width;
      const originalHeight = img1.height;

      const canvasWidth = originalWidth * resizingFactor;
      const canvasHeight = originalHeight * resizingFactor;

      canvas1.width = canvasWidth;
      canvas1.height = canvasHeight;

      context1.drawImage(
        img1,
        0,
        0,
        originalWidth * resizingFactor,
        originalHeight * resizingFactor
      );

      canvas1.toBlob(
        (blob1) => {
          console.log("ENTRE AL 1ER CANVAS BLOB!!");
          console.log(blob1);
          if (blob1) {
            this.selfieFile = blob1;
            console.log("1111: ", this.selfieFile);
          } else {
            this.resizeAndCompressBase64Img1(base64);
          }
        },
        "image/jpeg",
        0.6
      );
    },

    resizeAndCompressBase64Img2(base64) {
      console.log("ENTRE AL 2DO");
      let resizingFactor = 0.5;
      const canvas2 = document.createElement("canvas");
      const context2 = canvas2.getContext("2d");
      let img2 = document.createElement("img");
      img2.src = base64;

      const originalWidth = img2.width;
      const originalHeight = img2.height;

      const canvasWidth = originalWidth * resizingFactor;
      const canvasHeight = originalHeight * resizingFactor;

      canvas2.width = canvasWidth;
      canvas2.height = canvasHeight;

      context2.drawImage(
        img2,
        0,
        0,
        originalWidth * resizingFactor,
        originalHeight * resizingFactor
      );

      canvas2.toBlob(
        (blob2) => {
          console.log("ENTRE AL 2DO CANVAS BLOB");
          console.log(blob2);
          if (blob2) {
            this.frontDocFile = blob2;
            console.log("2: ", this.frontDocFile);
          } else {
            this.resizeAndCompressBase64Img2(base64);
          }
        },
        "image/jpeg",
        0.8
      );
    },

    resizeAndCompressBase64Img3(base64) {
      console.log("ENTRE AL 3RO");
      let resizingFactor = 0.5;
      const canvas3 = document.createElement("canvas");
      const context3 = canvas3.getContext("2d");
      let img3 = document.createElement("img");
      img3.src = base64;

      const originalWidth = img3.width;
      const originalHeight = img3.height;

      const canvasWidth = originalWidth * resizingFactor;
      const canvasHeight = originalHeight * resizingFactor;

      canvas3.width = canvasWidth;
      canvas3.height = canvasHeight;

      context3.drawImage(
        img3,
        0,
        0,
        originalWidth * resizingFactor,
        originalHeight * resizingFactor
      );

      canvas3.toBlob(
        (blob3) => {
          console.log("ENTRE AL 3ER CANVAS BLOB");
          console.log(blob3);
          if (blob3) {
            this.backDocFile = blob3;
            console.log("3: ", this.backDocFile);
          } else {
            this.resizeAndCompressBase64Img3(base64);
          }
        },
        "image/jpeg",
        0.8
      );
    },

    validateSelfie() {
      this.loadingSelfieBtn = true;
      let name = "selfie_" + this.userUUID + ".jpg";

      this.S3Client.upload(
        {
          Bucket: "vanguard-id",
          Key: name,
          Body: this.selfieFile, //Buffer.from(esto, 'base64'),
          ContentType: "image/jpeg",
        },
        (err, data) => {
          if (err) {
            console.log(err);
            this.loadingSelfieBtn = false;
          }

          this.s3SelfieKey = data.key;
          this.checkSelfie();
          /* console.log("Respuesta de imagen subida a S3 en la validacion de la selfie", data)
          // ******************************************************************
            axios({
              method: 'POST',
              baseURL: globals.APIURL + "identity/auth-person",
              headers: {
                  "Access-Control-Allow-Origin": "*"
              },
              data: {
                login_img_key: this.s3SelfieKey,
              },
            }).then((res) => {
                console.log(res)
                if (res.data.document_number) {
                  this.firstPanel = false;
                  this.registered_person_panel = true;
                  this.registered_person = res.data.document_number;
                  this.loadingSelfieBtn = true
                } else {
                  this.checkFaceFeatures();
                }
              })
              .catch((err) => {
                console.log(err.response.data.Message)
                if (err.response.data.Message === "No matching faces.") {
                  this.checkFaceFeatures();
                }
              });
            // ****************************************************************** */
        }
      );
    },

    checkFaceFeatures() {
      axios({
        method: "POST",
        baseURL: globals.APIURL + "identity/dvf",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          SelfieImage: this.s3SelfieKey,
        },
      })
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            if (
              res.data.faceAnalysisData.FaceDetails &&
              res.data.faceAnalysisData.FaceDetails.length > 0
            ) {
              if (
                !res.data.ppeData.Summary.PersonsWithRequiredEquipment ||
                res.data.ppeData.Summary.PersonsWithRequiredEquipment.length ==
                  0
              ) {
                if (
                  res.data.faceAnalysisData.FaceDetails[0].Eyeglasses.Value ==
                    false &&
                  res.data.faceAnalysisData.FaceDetails[0].Sunglasses.Value ==
                    false
                ) {
                  this.loadingSelfieBtn = false;
                  /* this.firstPanel = false;
                  this.secondPanel = true; */
									this.newSelfiePicturePanel = false;
									this.newPhonePanel = true
                } else {
                  this.loadingSelfieBtn = false;
                  this.snackbar = true;
                  this.snackbarColor = "error";
                  this.snackbarText =
                    "La selfie tiene lentes, favor ingresar una sin ellos.";
                }
              } else {
                this.loadingSelfieBtn = false;
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText =
                  "La selfie tiene tapabocas, favor ingresar una sin el.";
              }
            } else {
              this.loadingSelfieBtn = false;
              this.snackbar = true;
              this.snackbarColor = "error";
              this.snackbarText =
                "La imagen ingresada no tiene un rostro reconocible.";
            }
          }
        })
        .catch((err) => {
          this.loadingSelfieBtn = false;
          console.log(err);
        });
    },

    /* ---------- VALIDATE FRONT DOC DATA ---------- */
    validateFrontDocumentImg() {
      this.loadingFrontDocBtn = true;
      let name = "front-doc_" + this.userUUID + ".jpg";

      this.S3Client.upload(
        {
          Bucket: "vanguard-id",
          Key: name,
          Body: this.frontDocFile, //Buffer.from(esto, 'base64'),
          ContentType: "image/jpeg",
        },
        (err, data) => {
          if (err) {
            console.log(err);
            this.loadingFrontDocBtn = false;
          }

          this.s3FrontDocKey = data.key;
          this.checkFrontDoc();
          /* // this.compareFaces(); //TODO: COMMENTED FOR A WHILE
          this.documentHasFace();
            if (this.registry.tipoDocumento == "CC") {
                axios({
                    method: 'POST',
                    baseURL: globals.APIURL + "identity/extract-id-number",
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    },
                    data: {
                        FrontDocumentImage: data.key,
                    },
                    }).then((res) => {
                        if (res.data) {
                            let cedula = res.data.textDetectData.DocumentNumber.replace(/\./gi, "")
                            console.log(res.data)
                            console.log(cedula)
                            this.registry.id = cedula ? cedula : "";
                            if (cedula !== "") {
                                this.searchPersonData(cedula)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } */
        }
      );
    },

    documentHasFace() {
      axios({
        method: "POST",
        baseURL: globals.APIURL + "identity/vfd",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          FrontDocumentImage: this.s3FrontDocKey,
        },
      })
        .then((res) => {
          if (res.data) {
            if (
              res.data.faceAnalysisData.FaceDetails &&
              res.data.faceAnalysisData.FaceDetails.length > 0
            ) {
              console.log("Respuesta de validacion de rostro: ", res);
              if (this.registry.tipoDocumento !== "PASS") {
                this.loadingFrontDocBtn = false;
                //this.secondPanel = false;
                this.intermediaryPanel = false;
								this.newDocFirstPicturePanel = false
								if (this.newSelectedDoc == 'colID') {
									this.newDocSecondPicturePanel = true
								} else {
									this.newValidateOCRPanel = true
								}
                //this.thirdPanel = true;
              } else {
                this.loadingFrontDocBtn = false;
                //this.secondPanel = false;
                this.intermediaryPanel = false;
                //this.fourthPanel = true;
              }
            } else {
              this.loadingFrontDocBtn = false;
              //this.secondPanel = true;
              this.intermediaryPanel = false;
              this.snackbar = true;
              this.snackbarColor = "error";
              this.snackbarText =
                "La imagen del documento no tiene un rostro reconocible, por favor intente nuevamente con otra imagen.";
            }
          }
        })
        .catch((err) => {
          this.loadingFrontDocBtn = false;
          console.log(err);
        });
    },

    /* ---------- COMPARE SELFIE AND DOCUMENT FACE ---------- */

    compareFaces() {
      axios
        .post(
          globals.APIURL + "identity/cf",
          {
            SelfieImage: this.s3SelfieKey,
            DocumentImage: this.s3FrontDocKey,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.data.FaceMatches && res.data.FaceMatches.length > 0) {
            console.log(res.data.FaceMatches);
            this.similarityNumber = Math.round(
              res.data.FaceMatches[0].Similarity
            );
            if (Math.round(res.data.FaceMatches[0].Similarity) > 40) {
              this.loadingFrontDocBtn = false;
              this.secondPanel = false;
              this.thirdPanel = true;
            } else {
              this.loadingFrontDocBtn = false;
              this.snackbar = true;
              this.snackbarColor = "error";
              this.snackbarText =
                "El rostro del documento no coincide con la selfie.";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /* ---------- VALIDATE BACK DOC DATA ---------- */
    validateBackDocumentImg() {
      this.loadingBackDocBtn = true;
      let name = "back-doc_" + this.userUUID + ".jpg";

      this.S3Client.upload(
        {
          Bucket: "vanguard-id",
          Key: name,
          Body: this.backDocFile,
          ContentType: "image/jpeg",
        },
        (err, data) => {
          if (err) {
            console.log(err);
            console.log(data);
            this.loadingBackDocBtn = false;
          }

          /* this.thirdPanel = false;
          this.fourthPanel = true; */
          this.extractExpeditionDate(data.key);
        }
      );
      // **---------------------------------------

      /* this.S3Client
        .uploadFile(this.backDocFile, "back-doc_" + this.userUUID)
        .then(data => {
          if (data.key && data.key != '') {
            this.loadingBackDocBtn = false;
            this.thirdPanel = false
            this.fourthPanel = true
          }
        })
        .catch(err => {
          this.loadingBackDocBtn = false;
          console.log(err)
        }) */
    },

    searchPersonData(document_number) {
      axios
        .post(
          globals.APIURL + "identity/find-person-data",
          {
            document_number: document_number,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status == 200 && res.data) {
            let names = res.data.names;
            this.registry.firstname = names[0];
            this.registry.secondname = names.length == 3 ? "" : names[1];
            this.registry.surname1 = names.length == 3 ? names[1] : names[2];
            this.registry.surname2 = names.length == 3 ? names[2] : names[3];
						if (this.newSelectedDoc == 'colID') {
							this.newDocSecondPicturePanel = true
							this.loadingFrontDocBtn = false;
							this.newDocFirstPicturePanel = false
						} else {
							this.newValidateOCRPanel = true
							this.loadingFrontDocBtn = false;
							this.newDocFirstPicturePanel = false
						}
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createRegistry() {
			this.registry.firstname = this.registry.firstname.toUpperCase().trim()
			this.registry.secondname = this.registry.secondname ? this.registry.secondname.toUpperCase().trim() : "";
			this.registry.surname1 = this.registry.surname1.toUpperCase().trim()
			this.registry.surname2 = this.registry.surname2 ? this.registry.surname2.toUpperCase().trim() : "";
			
			axios
				.post(
					globals.APIURL + "identity/link-register-user",
					{
						user_data: this.registry,
						selfie_key: this.s3SelfieKey,
						images_ID: this.userUUID,
						company_id: this.companyID,
						role_id: this.roleID,
						suborg_id: this.suborgID,
            guest: this.guest.typeGuest ? this.guest : null
					},
					{
						headers: {
							"Access-Control-Allow-Origin": "*",
						},
					}
				)
				.then((res) => {
					console.log("RESPUESTA DE LA CREACIÓN: ", res);
					if (this.scheduleVisit !== null) {
						this.loadingRegistryCreationBtn = false;
						//this.fourthPanel = false;
						this.newEmailValidationPanel = false;
						this.successRegistrationVisitorPanel = true;
						this.snackbar = true;
						this.snackbarColor = "success";
						this.snackbarText = "Registro creado.";
						this.loadingFinalBtn = false
					} else {
						//this.clearRegistry();
						this.loadingRegistryCreationBtn = false;
						this.newEmailValidationPanel = false;
						this.newSuccessRegistrationPanel = true;
						this.snackbar = true;
						this.snackbarColor = "success";
						this.snackbarText = "Se creo el registro de manera exitosa.";
						this.loadingFinalBtn = false
					}
					//this.$bus.$emit("updateRegistry", "Update register list");
					/* if (res.data.status == 200) {
						console.log("RESPUESTA DE LA CREACIÓN: ", res)
						this.clearRegistry()
						this.loadingRegistryCreationBtn = false;
						this.snackbar = true;
						this.snackbarColor = "success";
						this.snackbarText =
							"Se creo el registro de manera exitosa.";
						//this.$bus.$emit("updateRegistry", "Update register list");
					} */
				})
				.catch((err) => {
					this.loadingRegistryCreationBtn = false;
					console.log(err);
				});
    },

    generateQR(reg) {
      console.log(reg);
      console.log(this.selfieImg);
      console.log(this.frontDocImg);
      console.log(this.backDocImg);

      /* if (this.$refs.form.validate() == true) {
          if (reg.id.length == 7) {
            reg.id = "000" + reg.id;
          } else if (reg.id.length == 8) {
            reg.id = "00" + reg.id;
          } else if (reg.id.length == 9) {
            reg.id = "0" + reg.id;
          }
        reg.birthdate = this.birthDatePicker.split("-").reverse().join("-");
        reg.firstname = reg.firstname.toUpperCase().trim();
        reg.secondname = reg.secondname.toUpperCase().trim();
        reg.surname1 = reg.surname1.toUpperCase().trim();
        reg.surname2 = reg.surname2.toUpperCase().trim();
        const str = JSON.stringify(reg, null, 0);
        this.QRValue = "QR" + str;
        this.QRGenerated = true;
        this.QRValue = this.QRValue.normalize("NFD").replace(/[\u0300-\u0302]/g, "");
        this.QRValue = this.QRValue.normalize("NFD").replace(/[\u0304-\u036f]/g, "");
        this.QRValue = this.QRValue.replace(/Ñ/g, "%");
        backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "create/QR/registry"})
      } */
    },

    clearRegistry() {
      this.registry.tipoDocumento = "";
      this.registry.id = "";
      this.registry.firstname = "";
      this.registry.secondname = "";
      this.registry.surname1 = "";
      this.registry.surname2 = "";
      this.registry.expeditionDate = "";
      this.registry.sex = "";
      this.registry.blood = "";
      this.registry.phone = "";
      this.registry.email = "";
      this.expeditionDatePicker = "";
      this.QRGenerated = false;
      this.QRValue = "";
      this.valid = true;
      // *************
      this.userUUID = "";
      this.loadingSelfieBtn = false;
      this.loadingFrontDocBtn = false;
      this.loadingBackDocBtn = false;

      this.selfieImg = null;
      this.frontDocImg = null;
      this.backDocImg = null;

      this.infoPanel = true;
      this.firstPanel = false;
      this.registered_person_panel = false;
      this.secondPanel = false;
      this.thirdPanel = false;
      this.fourthPanel = false;
      this.successRegistrationVisitorPanel = false;

      this.selfieBase64 = null;
      this.frontDocBase64 = null;
      this.backDocBase64 = null;

      this.s3SelfieKey = "";
      this.s3FrontDocKey = "";
      this.s3BackDocKey = "";

      this.selfieFile = null;
      this.frontDocFile = null;
      this.backDocFile = null;

      this.registered_person = "";
      this.scheduleVisit = null;
      this.scheduleVisitSede = null;

			// *******************************

			
			
			this.habeasData = false
			this.habeasDataDialog = false
			this.iReadPolicy = false
			// * -------------------------------------

      if (this.isIphone) {
        this.selfiePhotoModule1 = false
        this.selfiePhotoModule2 = true
        this.frontDocumentPhotoModule1 = false
        this.frontDocumentPhotoModule2 = true
        this.backDocumentPhotoModule1 = false
        this.backDocumentPhotoModule2 = true
      } else {
        this.selfiePhotoModule1 = true
        this.selfiePhotoModule2 = false
        this.frontDocumentPhotoModule1 = true
        this.frontDocumentPhotoModule2 = false
        this.backDocumentPhotoModule1 = true
        this.backDocumentPhotoModule2 = false
      }

			// * --------------------------------------
			this.newFirstPanel =  false
			this.newBiometricInfoPanel =  false
			this.newCountryPanel =  false
			this.newDocumentTypePanel =  false
			this.newDocSuggestionPanel =  false
			this.newDocFirstPicturePanel =  false
			this.newDocSecondPicturePanel =  false
			this.newValidateOCRPanel =  false
			this.newSelfiePicturePanel =  false
			this.newPhonePanel =  false
			this.newPhoneValidationPanel =  false
			this.newEmailPanel =  false
			this.newEmailValidationPanel =  false

			this.typeDocCountry = ""
			this.newSelectedDoc = ""
			this.otpPhone = Array(4).fill('')
			this.otpEmail = Array(6).fill('')
      this.otpPhoneNew = ""
      this.otpEmailNew = ""
			this.editMode = false
			this.selectCountryCode = ""
      this.guest.typeGuest = null
      this.guest.placeGuestCode = ""
      this.guest.checkinDate = ""
      this.guest.checkoutDate = ""
    },

    getScheduledVisit(ID) {
      axios
        .post(
          globals.APIURL + "entry/get-schedule-visit",
          {
            schedule_visit_id: ID,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            console.log("RESPUESTA DE VISITA PROGRAMADA: ", res.data);
            this.scheduleVisit = res.data;
            this.getSedeInfo(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSedeInfo(scheduleData) {
      axios
        .post(
          globals.APIURL + "org/foc",
          {
            company_id: scheduleData.company_id,
            campus_id: scheduleData.sede_id,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            console.log("RESPUESTA DE CAMPUS: ", res.data);
            this.scheduleVisitSede = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.validateAccess();
    this.$bus.$on("acceptRegisterHabeasData", (acceptanceHabeasData) => {
      this.habeasData = acceptanceHabeasData;
      //this.habeasDataDialog = false;
    });
    this.isMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);
    this.isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (this.isIphone) {
      this.selfiePhotoModule1 = false;
      this.selfiePhotoModule2 = true;
      this.frontDocumentPhotoModule1 = false;
      this.frontDocumentPhotoModule2 = true;
      this.backDocumentPhotoModule1 = false;
      this.backDocumentPhotoModule2 = true;
    }
  },
};
</script>
<style scoped>
.circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #465068;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
  margin-right: 10px;
}
.country-card {
  background-color: #212734;
  border-radius: 10px;
  border: solid white 1px;
  cursor: pointer;
}
.digit-input {
  width: 40px; /* Ajusta el ancho según sea necesario */
  /* font-size: 24px; */ /* Ajusta el tamaño de la fuente según sea necesario */
}

.card-selected {
	background-color: rgb(49,57,76)
}
.card-not-selected { 
	background-color: rgb(33,39,52)
}
</style>
