<template>
  <div>
      <v-card>
          <v-card-title>
            <p>Generación de reporte.</p>
          </v-card-title>
          <v-card-text>
            <v-row>
                <v-col cols="12" md="3" class="d-flex justify-center">
                <v-select
                    v-model="year"
                    :items="years"
                    item-text="y"
                    item-disabled="dis"
                    dense
                    label="Año"
                    outlined
                ></v-select>
                </v-col>
                <v-col cols="12" md="3" class="d-flex justify-center">
                <v-select
                    v-model="month"
                    :items="months"
                    item-text="name"
                    item-value="id"
                    dense
                    label="Mes"
                    outlined
                ></v-select>
                </v-col>
                <v-col cols="12" md="3" class="d-flex justify-center">
                <v-select
                    v-model="type"
                    :items="levels"
                    item-text="text"
                    item-value="id"
                    dense
                    label="Quincena"
                    outlined
                ></v-select>
                </v-col>
                <v-col cols="12" md="3" class="d-flex justify-center">
                    <v-btn dark color="success" :loading="loading" @click="searchReportData(year, month, type)">
                        GENERAR
                    </v-btn>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
            </v-btn>
        </template>
        </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import globals from "../../globals";
import auth from "../../services/auth";
import xlsx from "xlsx";

export default {
  name: "ExportReport",
  props: {
    allRegistry: {
      type: Array,
    },
    groups: {
        type: Array
    },
    costCenters: {
        type: Array
    }
  },
  data: () => ({
    loading: false,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    dateNow: globals.COdate(),
    year: null,
    month: null,
    type: null,
    years: [
      { y: "2021", dis: false}, 
      { y: "2022", dis: false}, 
      { y: "2023", dis: false}, 
      { y: "2024", dis: false},
      { y: "2025", dis: false}
    ],
    levels: [
      { id: "09", text: "1ra" },
      { id: "18", text: "2da" },
    ],
    months: [
      { id: "01", name: "Enero" },
      { id: "02", name: "Febrero" },
      { id: "03", name: "Marzo" },
      { id: "04", name: "Abril" },
      { id: "05", name: "Mayo" },
      { id: "06", name: "Junio" },
      { id: "07", name: "Julio" },
      { id: "08", name: "Agosto" },
      { id: "09", name: "Septiembre" },
      { id: "10", name: "Octubre" },
      { id: "11", name: "Noviembre" },
      { id: "12", name: "Diciembre" },
    ],
    operativeCenters: [],
    pageOne: [],
    pageTwo: [],
    headers: ["CC", "NOMBRE", "GRUPO TRABAJO", "C. OPERACIONES", "CENTRO COSTOS", "T. TRABAJADO", "H. EXTRAS", "H. DOMINICALES", "H. FESTIVAS", "H. NOCTURNAS", "AUSENCIAS", "INCOMPLETAS"]
  }),
  methods: {
    checkIsYearDisabled() {
      for (let i = 0; i < this.years.length; i++) {
        const element = this.years[i];
        if (element.y > this.dateNow.substring(0, 4)) {
          element.dis = true
        }
      }
    },

    extractGroupsInfo(ID, key) {
        for (let i = 0; i < this.groups.length; i++) {
            const element = this.groups[i];
            
            if (element.uuid === ID) {
                return element[key]
            }
        }
    },

    extractCCInfo(ID, key) {
        for (let i = 0; i < this.costCenters.length; i++) {
            const element = this.costCenters[i];
            
            if (element.uuid === ID) {
                return element[key]
            }
        }
    },

    extractOCInfo(ID, key) {
        for (let i = 0; i < this.operativeCenters.length; i++) {
            const element = this.operativeCenters[i];
            
            if (element.person_id == ID) {
                return element[key]
            }
        }
    },

    calculateRes(entry) {
        if (entry.horasTrabajadas && entry.horasTrabajadas > 0) {
            return entry.horasTrabajadas
        } else if (entry.horasTrabajadas == 0 && entry.entrada && entry.salida) {
            return "< 1"
        } else if (entry.horasTrabajadas == 0 && !entry.entrada && !entry.salida && entry.dia != "Domingo") {
            return "AUS"
        } else if (entry.horasTrabajadas == 0 && !entry.entrada && !entry.salida && entry.dia == "Domingo") {
            return "---"
        } else if (!entry.horasTrabajadas && !entry.entrada && entry.salida) {
            return "E-I"
        } else if (!entry.horasTrabajadas && entry.entrada && !entry.salida) {
            return "S-I"
        }
    },
    
    async searchReportData(year, month, type) {
        this.loading = true
      let listPeople = []

      for (let i = 0; i < this.allRegistry.length; i++) {
        const element = this.allRegistry[i];

        listPeople.push({uuid: element.uuid})
      }

        await axios
            .post(globals.APIURL + "working-group/list-assigned-people-by-date", {
                campus_id: auth.getSelectedSede(),
                company_id: auth.getUserEnterpriseID(),
                people: listPeople,
                fecha: year + "-" + month + "-" + type,
                groupSelected: {
                    fortnight_working_time: "96",
                    finish_hour: "18:00",
                    eachDayHours: 10
                }
            })
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        //let weekHours = (element.company_person[0].working_group_id ? this.extractGroupsInfo(element.company_person[0].working_group_id, "fortnight_working_time") : 96)
                        element.first_name = element.first_last_name + " " + element.second_last_name + " " + element.first_name
                        element.group = (element.company_person[0].working_group_id ? this.extractGroupsInfo(element.company_person[0].working_group_id, "name") : "--SIN ASIGNAR--")
                        element.costCenter = (element.company_person[0].cost_center_id ? this.extractCCInfo(element.company_person[0].cost_center_id, "name") : "--SIN ASIGNAR--")
                        //element.totalExtraHours = (element.totalWorkedTime > weekHours ? Math.round((element.totalWorkedTime - weekHours)) : 0)
                        element.operativeCenter = (this.extractOCInfo(element.uuid, "operationCenter") ? this.extractOCInfo(element.uuid, "operationCenter") : "--SIN ASIGNAR--")

                        delete element.uuid
                        delete element.email
                        delete element.second_name
                        delete element.first_last_name
                        delete element.second_last_name
                        delete element.phone
                        delete element.birthdate
                        delete element.expedition_date
                        delete element.rh
                        delete element.gender
                        delete element.RekognitionId
                        delete element.facePrintId
                        delete element.document_type
                        delete element.register_image
                        delete element.collection
                        delete element.updated_at
                        delete element.created_at
                        delete element.entries
                        delete element.totalSundayHours
                        delete element.totalFestiveHours
                        delete element.totalNightHours
                        delete element.company_person
                        delete element.totalAbsences
                        delete element.entryButNotExit
                    }

                    this.pageOne = res.data;
                }
            })
            .catch((err) => {
                this.loading = false
                console.log(err);
            });
        await axios
            .post(globals.APIURL + "working-group/list-assigned-people-by-date", {
                campus_id: auth.getSelectedSede(),
                company_id: auth.getUserEnterpriseID(),
                people: listPeople,
                fecha: year + "-" + month + "-" + type,
                groupSelected: {
                  fortnight_working_time: "96",
                  finish_hour: "18:00",
                  eachDayHours: 10
                }
            })
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        if (i == 0) {
                            for (let index = 0; index < element.entries.length; index++) {
                                const el = element.entries[index];
                                this.headers.push(el.entradaSalida.fecha.substring(8, 10))
                                
                            }
                        }
                        for (let k = 0; k < element.entries.length; k++) {
                            const ele = element.entries[k];
                            
                            element[ele.entradaSalida.fecha.substring(8, 10)] = this.calculateRes(ele.entradaSalida)

                        }
                        //let weekHours = (element.company_person[0].working_group_id ? this.extractGroupsInfo(element.company_person[0].working_group_id, "fortnight_working_time") : 96)
                        element.CC = element.document_number;
                        element.NOMBRE = element.first_last_name + " " + element.second_last_name + " " + element.first_name
                        element["GRUPO TRABAJO"] = (element.company_person[0].working_group_id ? this.extractGroupsInfo(element.company_person[0].working_group_id, "name") : "--SIN ASIGNAR--")
                        element["C. OPERACIONES"] = (this.extractOCInfo(element.uuid, "operationCenter") ? this.extractOCInfo(element.uuid, "operationCenter") : "--SIN ASIGNAR--")
                        element["CENTRO COSTOS"] = (element.company_person[0].cost_center_id ? this.extractCCInfo(element.company_person[0].cost_center_id, "name") : "--SIN ASIGNAR--")
                        element["T. TRABAJADO"] = element.totalWorkedTime
                        element["H. EXTRAS"] = element.totalExtraHours//(element.totalWorkedTime > weekHours ? Math.round((element.totalWorkedTime - weekHours)) : 0)

                        element["H. DOMINICALES"] = element.totalSundayHours
                        element["H. FESTIVAS"] = element.totalFestiveHours
                        element["H. NOCTURNAS"] = element.totalNightHours
                        element["AUSENCIAS"] = element.totalAbsences
                        element["INCOMPLETAS"] = element.entryButNotExit

                        delete element.uuid
                        delete element.email
                        delete element.first_name
                        delete element.second_name
                        delete element.first_last_name
                        delete element.second_last_name
                        delete element.phone
                        delete element.birthdate
                        delete element.expedition_date
                        delete element.rh
                        delete element.gender
                        delete element.RekognitionId
                        delete element.facePrintId
                        delete element.document_number
                        delete element.document_type
                        delete element.register_image
                        delete element.collection
                        delete element.updated_at
                        delete element.created_at
                        delete element.entries
                        delete element.totalSundayHours
                        delete element.totalFestiveHours
                        delete element.totalNightHours
                        delete element.company_person
                        delete element.totalAbsences
                        delete element.entryButNotExit
                        delete element.totalWorkedTime
                        delete element.totalExtraHours
                    }
                    this.pageTwo = res.data;
                }
            })
            .catch((err) => {
                this.loading = false
                console.log(err);
            });
            this.generateReport(this.pageOne, this.pageTwo)
    },

    generateReport(jsonData1, jsonData2) {
        let newWB = xlsx.utils.book_new();
        let newWS2 = xlsx.utils.json_to_sheet(jsonData2, {
            header: this.headers
        })
        let newWS = xlsx.utils.json_to_sheet(jsonData1, {
          header: [
            "document_number",
            "first_name",
            "group",
            "operativeCenter",
            "costCenter",
            "totalWorkedTime",
            "totalExtraHours"
          ],
        });

        newWS["A1"].v = "CC";
        newWS["B1"].v = "NOMBRE";
        newWS["C1"].v = "GRUPO TRABAJO";
        newWS["D1"].v = "C. OPERACIONES";
        newWS["E1"].v = "CENTRO COSTOS";
        newWS["F1"].v = "T. TRABAJADO";
        newWS["G1"].v = "H. EXTRAS";

        xlsx.utils.book_append_sheet(newWB, newWS, "Resumen - " + (this.months[parseInt(this.month) - 1].name.substring(0, 3)) + "" + (this.type === "09" ? "01" : "02"));
        xlsx.utils.book_append_sheet(newWB, newWS2, "Detallado - " + (this.months[parseInt(this.month) - 1].name.substring(0, 3)) + "" + (this.type === "09" ? "01" : "02"));

        xlsx.writeFile(
          newWB,
          "Reporte nominal - " + globals.COdate().substring(0, 10) + ".xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );
        
        this.snackbarText = "Se genero su reporte de nómina.";
        this.snackbarColor = "success";
        this.snackbar = true;
        this.loading = false
        this.year = null
        this.month = null
        this.type = null
    },

    getOperativeCenters() {
        axios
        .post(globals.APIURL + "registry/getAllFields", {})
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.operativeCenters = res.data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.getOperativeCenters()
    this.checkIsYearDisabled()
  },
};
</script>
