<template>
  <v-container fluid>
    <div v-if="createGroup">
      <v-dialog
        v-model="createGroup"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title class="d-flex justify-center">
            <span class="text-h5">Creación de Grupo</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="newGroup.name"
                  label="Nombre o Rol"
                  outlined
                  dark
                  dense
                  hide-details
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                    v-model="newGroup.places"
                    label="Lugares Permitidos"
                    item-text="name"
                    item-value="uuid"
                    :items="allSedeList"
                    chips
                    multiple
                    outlined
                    dark
                    dense
                    color="secondary"
                    item-color="secondary"
                    hide-details="auto"
                    placeholder="Lugares..."
                >
                  <template v-slot:selection="{ item }">
                    <!-- <v-chip v-if="bodyAdvancedQuery.eventAction.length === selectAccionLog.length && index === 0">
                        <span>TODOS</span>
                    </v-chip> -->
                    <v-chip small>
                        <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <label class="text-subtitle-1">Hora Entrada</label>
                <input v-model="newGroup.hour_entry" type="time">
              </v-col>
               <v-col cols="12" md="6">
                <label class="text-subtitle-1">Hora Salida</label>
                <input v-model="newGroup.hour_exit" type="time">
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newGroup.tolerance"
                  label="Tolerancia de entrada y salida"
                  type="number"
                  outlined
                  dark
                  dense
                  hide-details
                  color="secondary"
                ></v-text-field>
                <label><small>Este valor debe ponerlo en minutos.</small></label>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                  <label class="text-subtitle-1">Autorización</label>
                  <v-spacer></v-spacer>
                  <v-switch
                    v-model="newGroup.need_acceptance"
                    class="ma-0 pa-0"
                    color="secondary"
                    dark
                    inset
                    dense
                    hide-details
                  ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              outlined
              class="px-4"
              @click="createGroup = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              class="px-4"
              @click="createPermissionGroup(newGroup)"
            >
              Crear
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="editGroup">
      <v-dialog
        v-model="editGroup"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title class="d-flex justify-center">
            <span class="text-h5">Editar Grupo</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="selectedGroup.name"
                  label="Nombre o Rol"
                  outlined
                  dark
                  dense
                  hide-details
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                    v-model="selectedGroup.places"
                    label="Lugares Permitidos"
                    item-text="name"
                    item-value="uuid"
                    :items="allSedeList"
                    chips
                    multiple
                    outlined
                    dark
                    dense
                    color="secondary"
                    item-color="secondary"
                    hide-details="auto"
                    placeholder="Lugares..."
                >
                  <template v-slot:selection="{ item }">
                    <!-- <v-chip v-if="bodyAdvancedQuery.eventAction.length === selectAccionLog.length && index === 0">
                        <span>TODOS</span>
                    </v-chip> -->
                    <v-chip small>
                        <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <label class="text-subtitle-1">Hora Entrada</label>
                <input v-model="selectedGroup.hour_entry" type="time">
              </v-col>
               <v-col cols="12" md="6">
                <label class="text-subtitle-1">Hora Salida</label>
                <input v-model="selectedGroup.hour_exit" type="time">
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="selectedGroup.tolerance"
                  label="Tolerancia de entrada y salida"
                  outlined
                  type="number"
                  dark
                  dense
                  hide-details
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                  <label class="text-subtitle-1">Autorización</label>
                  <v-spacer></v-spacer>
                  <v-switch
                    v-model="selectedGroup.need_acceptance"
                    class="ma-0 pa-0"
                    color="secondary"
                    dark
                    inset
                    dense
                    hide-details
                  ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              outlined
              class="px-4"
              @click="editGroup = false ; selectedGroup = null"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="success"
              rounded
              class="px-4"
              @click="editPermissionGroup(selectedGroup)"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="deleteGroup">
      <v-dialog v-model="deleteGroup" max-width="600">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12">
                <label class="text-body-1 text-center"
                  >¿Está seguro que desea eliminar este grupo de permisos?</label
                >
                <label class="text-center"
                  >Las personas que se encuentren asociadas a este grupo de permisos quedarán sin alguno asignado.</label
                >
              </v-col>
              <v-col cols="12">
                <label class="white--text">Para confirmar la eliminación del grupo, escriba <strong>eliminar</strong> en el cuadro de texto.</label>
                <v-text-field
                  v-model="labelConfirmDeletion"
                  placeholder='eliminar'
                  outlined
                  dark
                  dense
                  hide-details
                  solo
                  color="secondary"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              outlined
              class="px-4"
              @click="deleteGroup = false ; labelConfirmDeletion = ''"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="red"
              rounded
              class="px-4"
              :disabled="!labelConfirmDeletion"
              @click="deletePermissionGroup(selectedGroup)"
            >
              Eliminar
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="configZones">
      <v-overlay
        :value="configZones"
        opacity="1"
        z-index="100"
      >
        <div v-if="createGroupZonesDialog">
          <v-dialog
            v-model="createGroupZonesDialog"
            persistent
            width="60%"
          >
            <v-card>
              <v-card-title class="d-flex justify-center">
                <span class="text-h5">Creación Grupo de Zonas</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="groupZones.name"
                      label="Nombre"
                      outlined
                      dark
                      dense
                      hide-details
                      color="secondary"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-select
                        v-model="groupZones.zones"
                        label="Zonas"
                        item-text="name"
                        item-value="uuid"
                        :items="allSedeList"
                        chips
                        multiple
                        outlined
                        dark
                        dense
                        color="secondary"
                        item-color="secondary"
                        hide-details="auto"
                        placeholder="Lugares..."
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip small>
                            <span>{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="cancel"
                  rounded
                  outlined
                  class="px-4"
                  @click="createGroupZonesDialog = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  rounded
                  class="px-4"
                  @click="putGroupZones(groupZones)"
                >
                  {{typeGroupZonesAction == 'create' ? "Crear" : "Actualizar"}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <v-card>
          <v-card-title class="d-flex justify-center">
            <span class="text-h5">Configuración de Zonas Agrupadas</span>
            <v-spacer></v-spacer>
            <v-btn class="mt-1 mr-1" icon small dark @click="configZones = !configZones">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12">
                <v-layout child-flex>
                  <v-data-table
                    :headers="zoneHeaders"
                    :items="groupedZones"
                    :items-per-page="10"
                    :header-props="{
                      mobile: true,
                    }"
                    :footer-props="{
                      'items-per-page-options': [20],
                    }"
                    class="dtTable"
                    fixed-header
                    loading-text="Cargando Información..."
                    no-data-text="No hay grupos que mostrar"
                  >
                  <template v-slot:top>
                    <v-toolbar flat style="background-color: #272e3d">
                      <v-toolbar-title>
                        <div
                          class="text-h5 text-center font-weight-bold secondary--text"
                        >
                          Grupos
                        </div>
                      </v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        small
                        dark
                        @click="groupZones.name = '' ; groupZones.zones = [] ; createGroupZonesDialog = true"
                      >
                        Crear Grupo
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.zones`]="{ item }">
                    <v-chip-group column>
                      <div v-for="(permittedPlace, i) in item.zones" :key="i">
                        <v-chip
                          v-for="(place, j) in allSedeList" :key="j"
                          small
                          class="sede-chip"
                          primary
                          disabled
                          :style="permittedPlace !== place.uuid ? 'display: none' : ''"
                        >
                          {{ permittedPlace === place.uuid ? place.name : "" }}
                        </v-chip>
                      </div>
                    </v-chip-group>
                  </template>
                  <template v-slot:[`item.options`]="{ item }">
                    <v-btn
                      icon
                      class="secondary mr-2"
                      outlined
                      small
                      @click="groupZones = item ; typeGroupZonesAction = 'edit' ; createGroupZonesDialog = true"
                    >
                      <i class="fal fa-pencil" style="font-size: 15px;" color="primary"></i>
                    </v-btn>
                    <v-btn
                      icon
                      class="error"
                      outlined
                      small
                      @click="deleteGroupZones(item)"
                    >
                      <i class="fal fa-trash" style="font-size: 15px;" color="error"></i>
                    </v-btn>
                  </template>
                  </v-data-table>
                </v-layout>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-overlay>
    </div>

    <div v-if="blackList">
      <v-overlay
        :value="blackList"
        opacity="1"
        z-index="100"
      >
        <div v-if="createBlackListDialog">
          <v-dialog
            v-model="createBlackListDialog"
            persistent
            width="60%"
          >
            <v-card>
              <v-card-title class="d-flex justify-center">
                <span class="text-h5">Creación Listas Negras</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="blackListGroup.name"
                      label="Nombre"
                      outlined
                      dark
                      dense
                      hide-details
                      color="secondary"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="cancel"
                  rounded
                  outlined
                  class="px-4"
                  @click="createBlackListDialog = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  rounded
                  class="px-4"
                  @click="createBlackListGroup(blackListGroup)"
                >
                  Crear
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <div v-if="editBlackListDialog">
          <v-dialog
            v-model="editBlackListDialog"
            persistent
            max-width="500"
          >
            <v-card>
              <v-card-title class="d-flex justify-center">
                <span class="text-h5">Editar Lista Negra</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="selectedBL.name"
                      label="Nombre o Rol"
                      outlined
                      dark
                      dense
                      hide-details
                      color="secondary"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="cancel"
                  rounded
                  outlined
                  class="px-4"
                  @click="editBlackListDialog = false ; selectedBL = null"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="success"
                  rounded
                  class="px-4"
                  @click="editBlackList(selectedBL)"
                >
                  Actualizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <div v-if="deleteBlackListDialog">
          <v-dialog v-model="deleteBlackListDialog" max-width="600">
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col cols="12">
                    <label class="text-body-1 text-center"
                      >¿Está seguro que desea eliminar la lista negra?</label
                    >
                    <label class="text-center"
                      >Las personas que se encuentren asociadas a esta lista negra quedarán sin alguna asignada.</label
                    >
                  </v-col>
                  <v-col cols="12">
                    <label class="white--text">Para confirmar la eliminación de la lista, escriba <strong>eliminar</strong> en el cuadro de texto.</label>
                    <v-text-field
                      v-model="labelConfirmBLDeletion"
                      placeholder='eliminar'
                      outlined
                      dark
                      dense
                      hide-details
                      solo
                      color="secondary"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="cancel"
                  rounded
                  outlined
                  class="px-4"
                  @click="deleteBlackListDialog = false ; labelConfirmBLDeletion = ''"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="red"
                  rounded
                  class="px-4"
                  :disabled="!labelConfirmBLDeletion"
                  @click="deleteBlackList(selectedBL)"
                >
                  Eliminar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <v-card>
          <v-card-title class="d-flex justify-center">
            <span class="text-h5">Configuración de Listas Negras</span>
            <v-spacer></v-spacer>
            <v-btn class="mt-1 mr-1" icon small dark @click="blackList = !blackList">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12">
                <v-layout child-flex>
                  <v-data-table
                    :headers="blackListHeaders"
                    :items="blackLists"
                    :items-per-page="10"
                    :header-props="{
                      mobile: true,
                    }"
                    :footer-props="{
                      'items-per-page-options': [20],
                    }"
                    class="dtTable"
                    fixed-header
                    loading-text="Cargando Información..."
                    no-data-text="No hay listas que mostrar"
                  >
                  <template v-slot:top>
                    <v-toolbar flat style="background-color: #272e3d">
                      <v-toolbar-title>
                        <div
                          class="text-h5 text-center font-weight-bold secondary--text"
                        >
                          Listas Negras
                        </div>
                      </v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        small
                        dark
                        @click="blackListGroup.name = '' ; blackListGroup.notify = [] ; createBlackListDialog = true"
                      >
                        Crear Lista Negra
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.options`]="{ item }">
                    <v-btn
                      icon
                      class="secondary mr-2"
                      outlined
                      small
                      @click="selectedBL = item ; editBlackListDialog = true"
                    >
                      <i class="fal fa-pencil" style="font-size: 15px;" color="primary"></i>
                    </v-btn>
                    <v-btn
                      icon
                      class="error"
                      outlined
                      small
                      @click="deleteBlackListDialog = true ; selectedBL = item"
                    >
                      <i class="fal fa-trash" style="font-size: 15px;" color="error"></i>
                    </v-btn>
                  </template>
                  </v-data-table>
                </v-layout>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-overlay>
    </div>

    <v-row class="text-center">
      <v-col cols="12">
        <p class="grey--text font-weight-bold text-h6">
          Configuración<!--  de Permisos -->
        </p>
      </v-col>
      
      <v-col cols="12">
        <v-card elevation="0" class="ctTablaCam" style="border-radius: 10px">
          <!-- <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="search"
                append-icon="fal fa-search"
                label="Buscar por palabra clave..."
                outlined
                color="secondary"
                dark
                class="mb-5"
              ></v-text-field>
            </v-col>
          </v-row> -->
          <div class="no-mobile pa-0 ma-0">
            <v-row class="d-flex align-end">
              <v-col cols="6"></v-col>
              <v-col cols="2">
                  <v-btn
                    color="secondary"
                    rounded
                    outlined
                    small
                    @click="configZones = true"
                  >
                  Configurar Zonas
                  </v-btn>
              </v-col>
              <v-col cols="2">
                  <v-btn
                    color="grey lightin-2"
                    rounded
                    outlined
                    small
                    @click="blackList = true"
                  >
                  Listas Negras
                  </v-btn>
              </v-col>
              <v-col cols="2">
                  <v-btn
                    color="secondary"
                    rounded
                    outlined
                    class="px-2"
                    small
                    @click="createGroup = true"
                  >
                  Crear Nuevo Grupo
                  </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-row class="mobile">
            <v-col cols="12">
                <v-btn
                  color="secondary"
                  rounded
                  outlined
                  block
                  class="px-2"
                  @click="configZones = true"
                >
                Configurar Zonas
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-btn
                  color="secondary"
                  rounded
                  outlined
                  block
                  class="px-2"
                  @click="createGroup = true"
                >
                Crear Nuevo Grupo
                </v-btn>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12">
              <v-layout child-flex>
                <v-data-table
                  :headers="headers"
                  :items="permissionGroupsList"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [20],
                  }"
                  class="dtTable"
                  fixed-header
                  :loading="loading"
                  loading-text="Cargando Información..."
                  :no-results-text="`No se encontrarón ingresos por su dato de busqueda (${search})`"
                  no-data-text="No hay grupos que mostrar"
                >
                <template v-slot:[`item.places`]="{ item }">
                  <v-chip-group column style="max-width: 450px">
                    <div v-for="(permittedPlace, i) in item.places" :key="i">
                      <v-chip
                        v-for="(place, j) in allSedeList" :key="j"
                        small
                        class="sede-chip"
                        primary
                        disabled
                        :style="permittedPlace !== place.uuid ? 'display: none' : ''"
                      >
                        {{ permittedPlace === place.uuid ? place.name : "" }}
                      </v-chip>
                    </div>
                  </v-chip-group>
                </template>
                <template v-slot:[`item.tolerance`]="{ item }">
                  <div class="ma-0 pa-0">{{ item.tolerance + " Minutos" ? item.tolerance : "S/A" }}</div>
                </template>
                <template v-slot:[`item.need_acceptance`]="{ item }">
                  <v-chip
                    small
                    :class="item.need_acceptance ? 'primary' : 'red'"
                  >
                    {{ item.need_acceptance === true ? "SI" : "NO" }}
                  </v-chip>
                </template>
                <template v-slot:[`item.options`]="{ item }">
                  <v-btn
                    icon
                    class="secondary mr-2"
                    outlined
                    small
                    @click="editGroup = true ; selectedGroup = item"
                  >
                    <i class="fal fa-pencil" style="font-size: 15px;" color="primary"></i>
                  </v-btn>
                  <v-btn
                    icon
                    class="error"
                    outlined
                    small
                    @click="deleteGroup = true ; selectedGroup = item"
                  >
                    <i class="fal fa-trash" style="font-size: 15px;" color="error"></i>
                  </v-btn>
                </template>
                </v-data-table>
              </v-layout>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";
import { v4 as uuidv4 } from "uuid";
import backlog from '../services/logs'

export default {
  name: "Permissions",
  data: () => ({
    snackbar: false,
    snackbarColor: "",
    snackbarText: "",
    loading: false,
    modal: false,
    userRole: auth.getRole(),
    organizations: [],
    org: null,
    permissionGroupsList: [],
    entryListReport: [],
    reportAlreadyCreated: 0,
    rangeDates: [globals.COdate().substr(0, 10), globals.COdate().substr(0, 10)],
    search: "",
    maxDate: "",
    headers: [
      { text: "Nombre / Rol", sortable: false, align: "center", value: "name" },
      { text: "Lugares Permitidos", sortable: false, align: "center", value: "places" },
      { text: "Hora Entrada Permitida", sortable: false, align: "center", value: "hour_entry" },
      { text: "Hora Salida Permitida", sortable: false, align: "center", value: "hour_exit" },
      { text: "Tolerancia", sortable: true, align: "center", value: "tolerance" },
      { text: "Autorización previa", sortable: true, align: "center", value: "need_acceptance" },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    createGroup: false,
    editGroup: false,
    deleteGroup: false,
    selectedGroup: null,
    newGroup: {
        name: "",
        places: [],
        hour_entry: "",
        hour_exit: "",
        tolerance: "",
        need_acceptance: false
    },
    allSedeList: [],
    labelConfirmDeletion: "",
    createGroupZonesDialog: false,
    groupZones: {
      name: "",
      zones: []
    },
    configZones: false,
    zoneHeaders: [
      { text: "Nombre", sortable: true, align: "center", value: "name" },
      { text: "Zonas", sortable: false, align: "center", value: "zones" },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    groupedZones: [],
    typeGroupZonesAction: 'create',
    createBlackListDialog: false,
    editBlackListDialog: false,
    deleteBlackListDialog: false,
    blackListGroup: {
      name: "",
      notify: [],
      places: [],
      hour_entry: "",
      hour_exit: "",
      tolerance: "",
      need_acceptance: false
    },
    blackList: false,
    blackLists: [{name: "Lista Negra Itagüi"}],
    blackListHeaders: [
      { text: "Nombre", sortable: true, align: "center", value: "name" },
      { text: "Opciones", align: "center", value: "options", sortable: false },
    ],
    labelConfirmBLDeletion: "",
    selectedBL: null,
  }),
  methods: {
    createPermissionGroup(newGroup) {
      newGroup.company_id = auth.getUserEnterpriseID();
      newGroup.uuid = uuidv4();
      axios({
        method: "POST",
        url: globals.APIURL + "permission-group/put-pg/",
        data: newGroup,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "create/permission-group"})
          this.cleanNewGroup()
          this.getPremissionGroups()
          this.createGroup = false;
          this.snackbarText = "Se creo el grupo de permisos.";
          this.snackbarColor = "success";
          this.snackbar = true;
        }
      });
    },
    
    cleanNewGroup() {
        this.newGroup.name = ""
        this.newGroup.places = []
        this.newGroup.hour_entry = ""
        this.newGroup.hour_exit = ""
        this.newGroup.tolerance = ""
        this.newGroup.need_acceptance = false
    },

    editPermissionGroup(group) {
      axios({
        method: "POST",
        url: globals.APIURL + "permission-group/put-pg/",
        data: group,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "update/permission-group"})
          this.editGroup = false;
          this.selectedGroup = null;
          this.getPremissionGroups()
          this.snackbarText = "Se actualizó correctamente el grupo.";
          this.snackbarColor = "success";
          this.snackbar = true;
        }
      });
    },

    deletePermissionGroup(group) {
      if (this.labelConfirmDeletion && this.labelConfirmDeletion == "eliminar") {
        axios({
          method: "POST",
          url: globals.APIURL + "permission-group/delete-pg/",
          data: group,
        })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "delete/permission-group"})
            this.deleteGroup = false;
            this.selectedGroup = null;
            this.labelConfirmDeletion = "";
            this.getPremissionGroups()
            this.snackbarText = "Se eliminó correctamente el grupo.";
            this.snackbarColor = "error";
            this.snackbar = true;
          }
        });
      } else {
        this.snackbarText = "Es necesario confirmar la eliminación con la caja de texto.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    getPremissionGroups() {
      axios({
        method: "GET",
        baseURL: globals.APIURL + "permission-group/get-pg/" + auth.get_asset_id(),
      }).then((data) => {
        if (data.data.length > 0) {
          for (let i = 0; i < data.data.length; i++) {
            const element = data.data[i];
            
            if (element.type_group == 'black_list') {
              data.data.splice(i, 1)
            }
          }
          this.permissionGroupsList = data.data
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    getSedes() {
      this.sedes = []
      axios
        .post(globals.APIURL + "org/fo", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.Campus.length > 0) {
            this.allSedeList = res.data.Campus;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // * Black Lists
    createBlackListGroup(item) {
      item.uuid = uuidv4();
      item.company_id = auth.getUserEnterpriseID();
      item.type_group = "black_list"
      axios({
        method: "POST",
        url: globals.APIURL + "permission-group/put-pg/",
        data: item,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          //backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "create/black-list"})
          this.cleanBLGroup()
          this.getBlackLists()
          this.createBlackListDialog = false;
          this.snackbarText = "Se creo la lista negra.";
          this.snackbarColor = "success";
          this.snackbar = true;
        }
      });
    },

    editBlackList(item) {
      axios({
        method: "POST",
        url: globals.APIURL + "permission-group/put-pg/",
        data: item,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          //backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "update/permission-group"})
          this.editBlackListDialog = false;
          this.selectedBL = null;
          this.getBlackLists()
          this.snackbarText = "Se actualizó correctamente la lista negra.";
          this.snackbarColor = "success";
          this.snackbar = true;
        }
      });
    },

    deleteBlackList(item) {
      if (this.labelConfirmBLDeletion && this.labelConfirmBLDeletion == "eliminar") {
        axios({
          method: "POST",
          url: globals.APIURL + "permission-group/delete-pg/",
          data: item,
        })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            //backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "delete/permission-group"})
            this.deleteBlackListDialog = false;
            this.selectedBL = null;
            this.labelConfirmBLDeletion = "";
            this.getBlackLists()
            this.snackbarText = "Se eliminó correctamente la lista negra.";
            this.snackbarColor = "error";
            this.snackbar = true;
          }
        });
      } else {
        this.snackbarText = "Es necesario confirmar la eliminación en la caja de texto.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    getBlackLists() {
      axios({
        method: "POST",
        url: globals.APIURL + "permission-group/get-black-lists",
        data: {
          company_id: auth.getUserEnterpriseID(),
          type_group: "black_list"
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          this.blackLists = res.data
        }
      })
      .catch((err) => {
        console.log(err)
      });
    },

    cleanBLGroup() {
        this.blackListGroup.name = ""
        this.blackListGroup.places = []
        this.blackListGroup.notify = []
        this.blackListGroup.hour_entry = ""
        this.blackListGroup.hour_exit = ""
        this.blackListGroup.tolerance = ""
        this.blackListGroup.need_acceptance = false
    },
    
    // * Group Zones
    getGroupZones() {
      axios
        .post(globals.APIURL + "org/get-group-zones", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.status == 200 && res.data.data) {
            this.groupedZones = res.data.data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    putGroupZones(groupZonesData) {
      if (groupZonesData.name && groupZonesData.name !== '' && groupZonesData.zones && groupZonesData.zones.length > 0) {
        if (this.typeGroupZonesAction == 'create') {
          groupZonesData.uuid = uuidv4();
          groupZonesData.company_id = auth.getUserEnterpriseID();
          axios({
            method: "POST",
            url: globals.APIURL + "org/create-group-zones",
            data: {
              group_zones: groupZonesData
            },
          })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "create-zone/permission-group"})
              this.createGroupZonesDialog = false;
              this.groupZones.name = "";
              this.groupZones.zones = [];
              this.getGroupZones()
              this.snackbarText = "Se creo el grupo de zonas.";
              this.snackbarColor = "success";
              this.snackbar = true;
            }
          });
        } else {
          axios({
            method: "POST",
            url: globals.APIURL + "org/update-group-zones",
            data: {
              group_zones: groupZonesData
            },
          })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "update-zone/permission-group"})
              this.createGroupZonesDialog = false;
              this.groupZones.name = "";
              this.groupZones.zones = [];
              this.getGroupZones()
              this.snackbarText = "Se actualizó el grupo de zonas.";
              this.snackbarColor = "success";
              this.snackbar = true;
            }
          });
        }
      } else {
        this.snackbarText = "Todos los campos son obligatorios.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    deleteGroupZones(groupZones) {
      console.log(groupZones)
        axios({
          method: "POST",
          url: globals.APIURL + "org/delete-group-zones",
          data: {
            uuid: groupZones.uuid,
            company_id: groupZones.company_id
          },
        })
        .then((res) => {
          if (res.data.status == 200 || res.data.status == 201) {
            backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "delete-zone/permission-group"})
            this.getGroupZones()
            this.snackbarText = "Se eliminó correctamente el grupo.";
            this.snackbarColor = "error";
            this.snackbar = true;
          }
        });
    },
  },
  mounted() {
    this.getSedes()
    this.getPremissionGroups()
    this.getGroupZones()
    this.getBlackLists()
  },
};
</script>
<style>
tr:hover {
  background-color: transparent !important;
}
</style>
