<template>
  <v-container fluid style="height: 100%">
      <v-row>
          <v-col cols="12" md="12" sm="12" class="d-flex justify-center">
              <p class="text-center text-h5 font-weight-medium" style="color: #60a5fa">Seleccione una sede</p>
          </v-col>
      </v-row>
      <v-row class="mx-2" v-if="!organizations || organizations.length === 0">
        <v-col v-for="(item, i) in 4" :key="i" cols="12" md="3" sm="4">
          <v-skeleton-loader v-if="loading == true"
              class="pr-5"
              transition="scale-transition"
              width="300"
              type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col v-if="loading == false" cols="12" class="text-center">
          <p>No tiene sedes registradas, por favor comunicarse con el equipo tecnico de <strong>Vanguard Technologies</strong>.</p>
        </v-col>
      </v-row>
      <v-row class="mx-2" v-if="organizations && organizations.length > 0">
          <v-col v-for="(item, i) in organizations" :key="i" cols="12" md="3" sm="4" @click="goToSede(item)">
              <v-card class="card-org" elevation="10">
                <v-row>
                  <v-col cols="4" class="d-flex justify-center align-end mx-0 px-0">
                    <v-icon
                      x-large
                      style="font-size: 70px"
                      class="ml-3 icon-color"
                    >
                      mdi-office-building-marker-outline
                    </v-icon>
                  </v-col>
                  <v-col cols="8" class="d-flex align-center mx-0 px-0">
                    <span class="text-h6 font-weight-medium text-wrap" style="color: #9ca3af">{{item.name}}</span>
                  </v-col>
                </v-row>
              </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import auth from "../services/auth";

export default {
  name: "MainModule",
  data() {
    return {
      organizations: [],
      loading: false,
      userOrganization: auth.getUserEnterpriseID(),
    };
  },
  methods: {
    goToSede(item) {
      /* let modules = []
      let modulePlan = null
      item.devices.forEach(element => {
        if (element.type_device == "restaurant") {
          this.$store.commit('asignLunchModuleStatus', true)
        }
        if (!modules.includes(element.type_device)) {
          modules.push(element.type_device)
        }
      })
      if (modules.includes('vsgate') && modules.includes('vspark') && modules.includes('vision')) {
        modulePlan = 3
      } else if (modules.includes('vsgate') && modules.includes('vspark') && !modules.includes('vision')) {
        modulePlan = 2
      } else if (modules.includes('vsgate') && !modules.includes('vspark') && !modules.includes('vision')) {
        modulePlan = 1
      }
      console.log(modulePlan)
      localStorage.setItem("modules", modulePlan); */
      // **NEW
      localStorage.setItem("grouped-zones", JSON.stringify({name: item.name, zones: item.zones}));
      localStorage.setItem("selected-sede", item.zones[0]); // TODO: NEW FEATURE TO TEST
      localStorage.setItem("sede-name", item.name); // TODO: NEW FEATURE TO TEST
      /* localStorage.setItem("selected-sede", item.uuid);
      localStorage.setItem("sede-name", item.name)*/
      if (auth.getModules() == 5 || auth.getModules() == 6 || auth.getModules() == 9 || auth.getModules() == 13) {
        this.$router.push({name: "dashCars"})
        this.$router.go()
      } else if (auth.getModules() == 7 || auth.getModules() == 10) {
        this.$router.push({name: "visionid"})
        this.$router.go()
      } else {
        this.$router.push({name: "dashboard"})
        this.$router.go()
      }
    },

    asignModules(campusID) {
      this.modules = []
      axios
        .post(globals.APIURL + "device/fb", {
          filter: {
              campus_id: campusID
          },
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            res.data.forEach(element => {
              this.modules.push(element.type_device)
            })
            console.log("MODULOS:::: ", this.modules)
            //localStorage.setItem("modules", this.modules[0]);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    getOrganizations() {
      this.loading = true
      axios
        .post(globals.APIURL + "org/fo", {
          company_id: this.userOrganization
        })
        .then((res) => {
          if (res.data && res.data.Campus.length > 0) {
            if (auth.getRoleName() !== 'SUPER') {
              for (let i = 0; i < res.data.Campus.length; i++) {
                const element = res.data.Campus[i];
                if (element.devices.length > 0) {
                  this.organizations.push(element)
                }
              }
            } else {
              this.organizations = res.data.Campus
            }
            this.loading = false
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err);
        });
    },

    getGroupZones() {// **NEW
    this.loading = true
      axios
        .post(globals.APIURL + "org/get-group-zones", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.status == 200 && res.data.data) {
            for (let i = 0; i < res.data.data.length; i++) {
              const element = res.data.data[i];
              
              if (element.users.includes(auth.getUsername())) {
                this.organizations.push(element)
              }
            }
            this.loading = false
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err);
        });
    },
  },
  created() {
    //this.getOrganizations();
    this.getGroupZones()// **NEW
  },
};
</script>

<style>
.card-org {
  width: 100%;
  cursor: pointer;
}

.icon-color {
  color: #60a5fa !important; 
}
</style>