<template  class="mt-13" style="margin-left: 80px">
  <v-container fluid>
    <div class="text-center" v-if="secureEvent">
      <v-dialog
        v-model="secureEvent"
        content-class="modalVs"
        width="500"
        no-click-animation
        :retain-focus="false"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p class="text-center text-body-2 pb-2">Está seguro de generar la alarma de seguridad?</p>
                <label class="text-body-2">Esta enviará un mensaje via correo electronico y whatsapp de alerta al grupo de seguridad que seleccione, con el siguiente mensaje ya pre-configurado.</label>
              </v-col>
              <v-col cols="12">
                <p>Mensaje de Alerta: </p>
                <label>
                  {{ securityAlert.alert_msg }}
                </label>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mb-0 pb-0">
                <p>Estos son los datos de los equipos a alertar: </p>
              </v-col>
              <v-col cols="12" class="mt-0 pt-2 ml-2">
                <label style="white-space: nowrap;"><strong class="white--text">Nombre: </strong> <span>{{securityAlert.sender_data.name}}</span></label>
                <label style="white-space: nowrap;"><strong class="white--text">Numero Celular: </strong> <span>{{securityAlert.sender_data.phone}}</span></label>
                <label style="white-space: nowrap;"><strong class="white--text">Correo Electronico: </strong> <span>{{securityAlert.sender_data.email}}</span></label>
              </v-col>
              <v-divider v-if="securityAlert.second_sender_data !== undefined"></v-divider>
              <v-col v-if="securityAlert.second_sender_data !== undefined" cols="12" class="mt-0 pt-2 ml-2">
                <label style="white-space: nowrap;"><strong class="white--text">Nombre: </strong> <span>{{securityAlert.second_sender_data.name ? securityAlert.second_sender_data.name : "N/R"}}</span></label>
                <label style="white-space: nowrap;"><strong class="white--text">Numero Celular: </strong> <span>{{securityAlert.second_sender_data.phone ? securityAlert.second_sender_data.phone : "N/R"}}</span></label>
                <label style="white-space: nowrap;"><strong class="white--text">Correo Electronico: </strong> <span>{{securityAlert.second_sender_data.email ? securityAlert.second_sender_data.email : "N/R"}}</span></label>
              </v-col>
              <v-divider v-if="securityAlert.third_sender_data !== undefined"></v-divider>
              <v-col v-if="securityAlert.third_sender_data !== undefined" cols="12" class="mt-0 pt-2 ml-2">
                <label style="white-space: nowrap;"><strong class="white--text">Nombre: </strong> <span>{{securityAlert.third_sender_data.name ? securityAlert.third_sender_data.name : "N/R"}}</span></label>
                <label style="white-space: nowrap;"><strong class="white--text">Numero Celular: </strong> <span>{{securityAlert.third_sender_data.phone ? securityAlert.third_sender_data.phone : "N/R"}}</span></label>
                <label style="white-space: nowrap;"><strong class="white--text">Correo Electronico: </strong> <span>{{securityAlert.third_sender_data.email ? securityAlert.third_sender_data.email : "N/R"}}</span></label>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
           <button small class="btnOne secondary" @click="sendAlertSignal()">Si, proceder</button>
           <button small class="btnOne error">Cancelar</button>
           <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="solveAllEvents">
      <v-dialog
        v-model="solveAllEvents"
        content-class="modalVs"
        width="500"
        no-click-animation
        :retain-focus="false"
      >
        <v-card>
          <v-card-title class="text-center">
            <span class="text-h6">Resolucion de eventos</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
           <button @click="actionNewEventBulk('one')" class="btnOne secondary">Resolver evento individual</button>
           <button @click="actionNewEventBulk('bulk')" class="btnOne">Resolver los {{ solveInBulk.eventBulk.length }} eventos</button>
           <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="test">
      <v-dialog
        v-model="test"
        width="1000"
        content-class="modalVs"
        no-click-animation
      >
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="#212734"
            dark
          >
            <v-tab
              v-for="item in testCams"
              :key="item.camkey"
            >
              {{ eventCAM(item.camkey) }}
            </v-tab>
           <!--  <v-icon @click="test = !test" style="cursor: pointer;right: 16px;position: absolute;padding: 10px;">mdi-collapse-all</v-icon> -->
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(cam, camIndex) in testCams"
              :key="camIndex"
            >
            <div v-for="(item, i) in cam.clusters" :key="i">
              <div v-if="cam.clusters[i].status" class="modal-content" style="width: 100%; border-radius: 0px 0px 10px 10px;">
                <v-card-title style="background-color: #212734;">
                  <span class="text-h5"
                    ><v-icon v-if="i > 0" @click="cam.clusters[i].status = false ; i = (i - 1) ; cam.clusters[i].status = true" large style="cursor: pointer;" class="pr-10 py-0 my-0">mdi-chevron-left</v-icon>Evento {{i+1}}  /  {{cam.clusters.length}}<v-icon v-if="cam.clusters.length > 1 && i+1 < cam.clusters.length"  @click="cam.clusters[i].status = false ; i = (i + 1) ; cam.clusters[i].status = true" large style="cursor: pointer;" class="pl-10 py-0 my-0">mdi-chevron-right</v-icon>
                  </span>
                </v-card-title>
                <div v-for="(alarm, j) in cam.clusters[i].events" :key="j" class="pa-0 ma-0">
                  <div v-if="cam.clusters[i].events[j].status" class="pa-0 ma-0">
                    <div class="ctModalInfNewEvent">
                      <div>
                        <v-img class="mlImgEvn" contain style="max-width: 790px;" :src="cam.clusters[i].events[j].url" >
                          <v-icon v-if="j > 0" @click="cam.clusters[i].events[j].status = false ; j = (j - 1) ; cam.clusters[i].events[j].status = true" large style="cursor: pointer; font-size: 60px; top: 45%;" class="pr-10 py-0 my-0">mdi-chevron-left</v-icon>
                          <v-icon v-if="cam.clusters[i].events.length > 1 && j+1 < cam.clusters[i].events.length"  @click="cam.clusters[i].events[j].status = false ; j = (j + 1) ; cam.clusters[i].events[j].status = true" large :style="j === 0 ? 'left: 92%;' : 'left: 80%;'" style="cursor: pointer;font-size: 60px; top: 45%;" class="pr-10 py-0 my-0">mdi-chevron-right</v-icon>
                          <span style="float: right;" class="pa-0 ma-3 text-h5">{{j + 1}} / {{cam.clusters[i].events.length}}</span>
                        </v-img>
                      </div>
                      <div class="ctModalInfData">
                        <div>
                          <p>Cámara</p>
                          <p><i class="fal fa-video"></i> {{ eventCAM(cam.clusters[i].events[j].camkey) }}</p>
                        </div>
                        <div>
                          <p>Evento</p>
                          <p>
                            <i class="fal fa-exclamation-circle"></i> {{ cam.clusters[i].events[j].eventType }}
                          </p>
                        </div>
                        <div>
                          <p>Fecha</p>
                          <p><i class="fal fa-calendar-alt"></i> {{ cam.clusters[i].events[j].timestamp ? new Date(cam.clusters[i].events[j].timestamp).toLocaleDateString() : '' }}</p>
                        </div>
                        <div>
                          <p>Hora</p>
                          <p><i class="fal fa-clock"></i> {{ cam.clusters[i].events[j].timestamp ? new Date(cam.clusters[i].events[j].timestamp).toLocaleTimeString() : '' }}</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <div class="pt-2">
                        <v-col cols="12" md="10" class="mx-auto">
                          <v-textarea
                            id="desEvent"
                            rows="2"
                            label="Descripción del evento"
                            outlined
                            dark
                            color="secondary"
                            hide-details="auto"
                            v-model="cam.clusters[i].events[j].log"
                          >
                          </v-textarea>
                        </v-col>
                      </div>
                      <div class="ctBtnNewEvent pt-2">
                        <button
                          class="btnOne"
                          @click="
                              actionNewEventDet(1, cam.clusters[i].events[j].eventkey, cam.clusters[i].events[j].log, camIndex, i, j)
                          "
                        >
                          Seguridad
                        </button>
                        <button
                          class="btnOne"
                          @click="
                              actionNewEventDet(2, cam.clusters[i].events[j].eventkey, cam.clusters[i].events[j].log, camIndex, i, j)
                          "
                        >
                          Inspección
                        </button>
                        <button
                          class="btnOne"
                          @click="
                              actionNewEventDet(3, cam.clusters[i].events[j].eventkey, cam.clusters[i].events[j].log, camIndex, i, j)
                          "
                        >
                          No Riesgo
                        </button>
                        <button
                          class="btnOne"
                          @click="
                              actionNewEventDet(4, cam.clusters[i].events[j].eventkey, cam.clusters[i].events[j].log, camIndex, i, j)
                          "
                        >
                          Falso Positivo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="loadingUnsolve" class="text-center">
      <v-overlay
        opacity="0.9"
        :value="loadingUnsolve"
        z-index="9999"
      >
        <v-card
          color="#212734"
          dark
        >
          <v-card-text>
            Cargando eventos
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-overlay>
    </div>

    <!-- -------- -->
    <!--modal new event-->
    <!-- -------- -->
    <div class="text-center" v-if="unsolvedEvents && unsolvedEvents.length > 0">
      <v-dialog
        v-for="(elem, i) in unsolvedEvents"
        :key="i"
        v-model="elem.status"
        width="1000"
        content-class="modalVs"
        persistent
        no-click-animation
        :retain-focus="false"
      >
        <div class="modal-content">
          <v-card-title style="background-color: #212734;">
            <span class="text-h5"
              >Nuevo evento detectado
              <span class="contEvent" @click="elem.status = false">{{ i + 1 }}</span>
            </span>
            <span class="collapseOne">
              <v-icon @click="elem.status = false" style="cursor: pointer;">mdi-minus</v-icon>
            </span>
            <span class="collapseAll">
              <v-icon @click="closeAllPopups" style="cursor: pointer;">mdi-collapse-all</v-icon>
            </span>
          </v-card-title>
          <div class="ctModalInfNewEvent">
            <div>
              <img class="mlImgEvn" :src="elem.url" />
            </div>
            <div class="ctModalInfData">
              <div>
                <p>Cámara</p>
                <p><i class="fal fa-video"></i> {{ eventCAM(elem.camkey) }}</p>
              </div>
              <div>
                <p>Evento</p>
                <p>
                  <i class="fal fa-exclamation-circle"></i> {{ elem.eventType }}
                </p>
              </div>
              <div>
                <p>Fecha</p>
                <p><i class="fal fa-calendar-alt"></i> {{ elem.date }}</p>
              </div>
              <div>
                <p>Hora</p>
                <p><i class="fal fa-clock"></i> {{ elem.time }}</p>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div class="pt-2">
              <v-col cols="12" md="10" class="mx-auto">
                <v-textarea
                  id="desEvent"
                  rows="2"
                  label="Descripción del evento"
                  outlined
                  dark
                  color="secondary"
                  hide-details="auto"
                  v-model="elem.log"
                >
                </v-textarea>
              </v-col>
            </div>
            <div class="ctBtnNewEvent pt-2">
              <button
                class="btnOne"
                @click="
                  (elem.status = false),
                    actionNewEventBySocket(1, elem.eventkey, elem.log)
                "
              >
                Seguridad
              </button>
              <button
                class="btnOne"
                @click="
                  (elem.status = false),
                    actionNewEventBySocket(2, elem.eventkey, elem.log)
                "
              >
                Inspección
              </button>
              <button
                class="btnOne"
                @click="
                  (elem.status = false),
                    actionNewEventBySocket(3, elem.eventkey, elem.log)
                "
              >
                No Riesgo
              </button>
              <button
                class="btnOne"
                @click="
                  (elem.status = false),
                    actionNewEventBySocket(4, elem.eventkey, elem.log)
                "
              >
                Falso Positivo
              </button>
            </div>
          </div>
        </div>
      </v-dialog>
    </div>

    <!-- -------- -->
    <!--modal Log de eventos-->
    <!-- -------- -->
    <div class="text-center" v-if="searchModal">
      <v-dialog
        v-model="searchModal"
        max-width="1100"
        content-class="modalVs"
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Log de eventos</span>
          </v-card-title>
          <v-card-text>
            <div class="ctModal">
              <div class="ctSearchLog mb-3 mt-5">
                <div>
                  <v-text-field
                    v-model="bodyAdvancedQuery.ts1"
                    type="date"
                    data-date-format="yyyy-mm-dd"
                    label="Desde*"
                    outlined
                    dark
                    color="secondary"
                    hide-details="auto"
                    dense
                  ></v-text-field>
                </div>
                <div>
                  <v-text-field
                    v-model="bodyAdvancedQuery.ts2"
                    type="date"
                    data-date-format="yyyy-mm-dd"
                    label="Hasta*"
                    outlined
                    dark
                    color="secondary"
                    hide-details="auto"
                    dense
                  ></v-text-field>
                </div>
              </div>
              <div class="text-center">
                <v-btn
                  color="secondary"
                  rounded
                  large
                  class="px-4 my-4"
                  @click="
                    advancedQuery
                      ? (advancedQuery = false)
                      : (advancedQuery = true),
                      (bodyAdvancedQuery.eventAction = 0),
                      (bodyAdvancedQuery.camkey = 0),
                      (bodyAdvancedQuery.alarm = 0),
                      (bodyAdvancedQuery.class = 0)
                  "
                >
                  Consulta avanzada <i class="fal fa-angle-down ml-3"></i>
                </v-btn>
              </div>
              <div v-if="advancedQuery">
                <hr />
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-select
                      v-model="bodyAdvancedQuery.eventAction"
                      label="Acción"
                      item-text="name"
                      item-value="key"
                      :items="selectAccionLog"
                      chips
                      multiple
                      outlined
                      dark
                      dense
                      color="secondary"
                      item-color="secondary"
                      hide-details="auto"
                      placeholder="Todas"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="bodyAdvancedQuery.eventAction.length === selectAccionLog.length && index === 0">
                          <span>TODOS</span>
                        </v-chip>
                        <v-chip v-if="bodyAdvancedQuery.eventAction.length < selectAccionLog.length">
                          <span>{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="bodyAdvancedQuery.camkey"
                      label="Cámaras"
                      item-text="name"
                      item-value="key"
                      :items="selectCamLog"
                      chips
                      multiple
                      outlined
                      dark
                      dense
                      color="secondary"
                      item-color="secondary"
                      hide-details="auto"
                      placeholder="Todas"
                      @change="changeCamLog"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="bodyAdvancedQuery.camkey.length === selectCamLog.length && index === 0">
                          <span>TODOS</span>
                        </v-chip>
                        <v-chip v-if="bodyAdvancedQuery.camkey.length < selectCamLog.length">
                          <span>{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <!-- <v-col cols="3">
                    <v-select
                      v-model="bodyAdvancedQuery.alarm"
                      label="Alarmas"
                      item-text="name"
                      item-value="key"
                      :items="selectAlarmLog"
                      chips
                      multiple
                      outlined
                      dark
                      dense
                      color="secondary"
                      hide-details="auto"
                      placeholder="Alarmas"
                    ></v-select>
                  </v-col> -->
                  <v-col cols="4">
                    <v-select
                      v-model="bodyAdvancedQuery.class"
                      label="Modelo"
                      item-text="name"
                      item-value="key"
                      :items="selectClassLog"
                      chips
                      multiple
                      outlined
                      dark
                      dense
                      color="secondary"
                      item-color="secondary"
                      hide-details="auto"
                      placeholder="Todos"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="bodyAdvancedQuery.class.length === selectClassLog.length && index === 0">
                          <span>TODOS</span>
                        </v-chip>
                        <v-chip v-if="bodyAdvancedQuery.class.length < selectClassLog.length">
                          <span>{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </div>
              <hr />
              <p
                class="ConsultTableMes text-center ma-2"
                style="color: #f87171"
              >
                {{ ConsultTableMes }}
              </p>
              <div class="text-center">
                <v-btn
                  color="primary"
                  rounded
                  large
                  class="px-4 my-4"
                  @click="cargarjson(bodyAdvancedQuery)"
                >
                  Buscar
                </v-btn>
              </div>
              <hr v-if="noContent || loadingModalTable" />
              <div v-if="loadingModalTable" class="text-center mt-5">
                <v-progress-circular
                  :width="4"
                  color="grey"
                  indeterminate
                ></v-progress-circular>
              </div>
              <p
                v-if="noContent"
                class="no-content py-2"
              >
                No hay registros en el periodo de fecha seleccionada
              </p>
              <v-data-table
                v-if="modalTableContent.length > 0"
                :headers="tableHeaders"
                :items="modalTableContent"
                :search="search"
                :items-per-page="10"
                :header-props="{
                  mobile: true,
                }"
                :footer-props="{
                  'items-per-page-options': [10],
                }"
                class="dtTable"
                :loading="loadingTable"
                loading-text="Cargando Información..."
                no-data-text="No hay datos que mostrar"
              >
                <template v-slot:item="{ item }">
                  <tr
                    :class="selectRowFilter(item)"
                    @click="detailEventFilter(item)"
                  >
                    <td class="pa-0 text-center">{{ item.eventType === "detection" ? "Detección" : item.eventType }}</td>
                    <td class="pa-0 text-center">{{ eventCAM(item.camkey) }}</td>
                    <td class="pa-0 text-center">{{ item.date }}</td>
                    <td class="pa-0 text-center">{{ item.time }}</td>
                    <td class="pa-0 text-center">
                      <p class="px-2" :class="item.eventAction">
                        {{ eventAction(item.eventAction) }}
                      </p>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!-- -------- -->
    <!--modal img-->
    <!-- -------- -->
    <div class="text-center" v-if="eventeDetailModal">
      <v-dialog
        v-model="eventeDetailModal"
        content-class="modalVs"
        width="1100"
        no-click-animation
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Imagen del evento</span>
          </v-card-title>
          <v-card-text>
            <img :src="detailedEvent.signedURL" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!-- -------- -->
    <!--modal text log evento / info-->
    <!-- -------- -->
    <div class="text-center" v-if="eventeDetailModalLog">
      <v-dialog
        v-model="eventeDetailModalLog"
        content-class="modalVs"
        width="800"
        no-click-animation
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Descripción del evento</span>
          </v-card-title>
          <v-card-text>
            <v-col cols="12" md="10" class="mx-auto">
              <p>{{ selectedEvent.eventLog }}</p>
            </v-col>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="cnModulVision">
      <div>
        <!-- -------- -->
        <!--informacion ampliada-->
        <!-- -------- -->
          <!-- <div v-for="(alarm, i) in testSelected.alarms" :key="i" class="pa-0 ma-0">
            <div v-if="testSelected.alarms[i].status" id="ctExpCardInf">
              <div id="ctExpCardInf">
                <div>
                  <div class="ctExpCardInfImg">
                    <v-img contain style="max-width: 675px;" :src="alarm.url">
                      <v-icon :style="testSelected.alarms.length > 1 && i+1 < testSelected.alarms.length ? '' : 'display:none;'"  @click="testSelected.alarms[i].status = false ; i = (i + 1) ; testSelected.alarms[i].status = true" large style="cursor: pointer;font-size: 60px; top: 45%;left:5%" class="pr-10 py-0 my-0">mdi-chevron-right</v-icon>
                      <v-icon :style="i > 0 ? '' : 'display: none'"  @click="testSelected.alarms[i].status = false ; i = (i - 1) ; testSelected.alarms[i].status = true" large style="cursor: pointer;font-size: 60px; top: 45%;right:70%" class="pr-10 py-0 my-0">mdi-chevron-left</v-icon>
                      <span class="pa-3 ma-0 text-h5">{{i + 1}} / {{testSelected.alarms.length}}</span>
                    </v-img>
                  </div>
                </div>
                <div>
                  <div>
                    <p>Tipo</p>
                    <p>
                      <i class="fal fa-exclamation-circle"></i>
                      {{ alarm.eventType }}
                    </p>
                  </div>
                  <div>
                    <p>Cámara</p>
                    <div v-for="(cam, j) in cameras" :key="j">
                      <p v-if="cam.camkey === alarm.camkey">
                        <i class="fal fa-video"></i>
                        {{ cam.name }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p>Lugar</p>
                    <div v-for="(cam, j) in cameras" :key="j">
                      <p v-if="cam.camkey === alarm.camkey">
                        <i class="fal fa-map-marker-alt"></i>
                        {{ cam.place }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p>Fecha</p>
                    <p>
                      <i class="fal fa-calendar-alt"></i> {{ alarm.date }}
                    </p>
                  </div>
                  <div>
                    <p>Hora</p>
                    <p><i class="fal fa-clock"></i> {{ alarm.time }}</p>
                  </div>
                  <div>
                    <p>Acción</p>
                    <div>
                      <p class="ctAction" :class="alarm.eventAction">
                        {{ eventAction(alarm.eventAction) }}
                      </p>
                      <p @click="activateMenu = !activateMenu">
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </p>
                      <div v-if="activateMenu === true" class="ctEditAction">
                        <p
                          @click="
                            editAction(
                              1,
                              alarm.eventkey,
                              alarm.eventLog
                            )
                          "
                        >
                          Seguridad
                        </p>
                        <p
                          @click="
                            editAction(
                              2,
                              alarm.eventkey,
                              alarm.eventLog
                            )
                          "
                        >
                          Inspección
                        </p>
                        <p
                          @click="
                            editAction(
                              3,
                              alarm.eventkey,
                              alarm.eventLog
                            )
                          "
                        >
                          No Riesgo
                        </p>
                        <p
                          @click="
                            editAction(
                              4,
                              alarm.eventkey,
                              alarm.eventLog
                            )
                          "
                        >
                          Falso Positivo
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>ID Usuario</p>
                    <p>
                      <i class="fal fa-address-card"></i>
                      {{ alarm.actionUser }}
                    </p>
                  </div>
                  <div style="top: -10px; position: relative">
                    <p
                      class="btnOne"
                      :class="
                        !alarm.eventLog ||
                        alarm.eventLog.length == 0
                          ? 'd-none'
                          : ''
                      "
                      @click="eventeDetailModalLog = true"
                    >
                      <i class="fal fa-book"></i> Log
                    </p>
                  </div>
                </div>
                <div>
                  <i class="fal fa-times btnOne" @click="testSelected = null"></i>
                </div>
              </div>
              
            </div> -->


          <!-- inf data json -->
          
        <div id="ctExpCardInf">
          <p v-if="!selectedEvent" class="txtExpCardInf">
            Seleccionar un registro para visualizar toda su información
          </p>
          <div v-else id="ctExpCardInf">
            <div>
              <div class="ctExpCardInfImg">
                <img :src="selectedEvent.url" />
              </div>
            </div>
            <div>
              <div>
                <p>Tipo</p>
                <p>
                  <i class="fal fa-exclamation-circle"></i>
                  {{ selectedEvent.eventType }}
                </p>
              </div>
              <div>
                <p>Cámara</p>
                <div v-for="(cam, j) in cameras" :key="j">
                  <p v-if="cam.camkey === selectedEvent.camkey">
                    <i class="fal fa-video"></i>
                    {{ cam.name }}
                  </p>
                </div>
              </div>
              <div>
                <p>Lugar</p>
                <div v-for="(cam, j) in cameras" :key="j">
                  <p v-if="cam.camkey === selectedEvent.camkey">
                    <i class="fal fa-map-marker-alt"></i>
                    {{ cam.place }}
                  </p>
                </div>
              </div>
              <div>
                <p>Fecha</p>
                <p>
                  <i class="fal fa-calendar-alt"></i> {{ selectedEvent.date }}
                </p>
              </div>
              <div>
                <p>Hora</p>
                <p><i class="fal fa-clock"></i> {{ selectedEvent.time }}</p>
              </div>
              <div>
                <p>Acción</p>
                <div>
                  <p class="ctAction" :class="selectedEvent.eventAction">
                    {{ eventAction(selectedEvent.eventAction) }}
                  </p>
                  <p @click="activateMenu = !activateMenu">
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </p>
                  <div v-if="activateMenu === true" class="ctEditAction">
                    <p
                      @click="
                        editAction(
                          1,
                          selectedEvent.eventkey,
                          selectedEvent.eventLog
                        )
                      "
                    >
                      Seguridad
                    </p>
                    <p
                      @click="
                        editAction(
                          2,
                          selectedEvent.eventkey,
                          selectedEvent.eventLog
                        )
                      "
                    >
                      Inspección
                    </p>
                    <p
                      @click="
                        editAction(
                          3,
                          selectedEvent.eventkey,
                          selectedEvent.eventLog
                        )
                      "
                    >
                      No Riesgo
                    </p>
                    <p
                      @click="
                        editAction(
                          4,
                          selectedEvent.eventkey,
                          selectedEvent.eventLog
                        )
                      "
                    >
                      Falso Positivo
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p>ID Usuario</p>
                <p>
                  <i class="fal fa-address-card"></i>
                  {{ selectedEvent.actionUser }}
                </p>
              </div>
              <div style="top: -10px; position: relative">
                <p
                  class="btnOne"
                  :class="
                    !selectedEvent.eventLog ||
                    selectedEvent.eventLog.length == 0
                      ? 'd-none'
                      : ''
                  "
                  @click="eventeDetailModalLog = true"
                >
                  <i class="fal fa-book"></i> Log
                </p>
              </div>
            </div>
            <div>
              <i class="fal fa-times btnOne" @click="selectedEvent = null"></i>
            </div>
          </div>
        </div>

        <!-- -------- -->
        <!--table Log de eventos-->
        <!-- -------- -->
        <div id="ctTableInf">
          <div>
            <v-row class="ma-0 pa-0">
              <v-col md="12" class="mb-4 pa-0">
                <button class="btnOne" @click="cleanFilterModal() ; searchModal = true">
                  <i class="fal fa-table"></i> Consultar Log
                </button>
              </v-col>
            </v-row>
            <v-data-table
              :headers="tableHeaders"
              :items="tableContent"
              :search="search"
              :items-per-page="10"
              :header-props="{
                mobile: true,
              }"
              :footer-props="{
                'items-per-page-options': [10],
              }"
              class="dtTable"
              :loading="loadingTable"
              loading-text="Cargando Información..."
              no-data-text="No hay datos que mostrar"
              :hide-default-footer="true"
            >
              <template v-slot:item="{ item }">
                <tr
                  :class="selectRowTe(item)"
                  @click="
                    selectedEvent = null;
                    selectedEvent = item;
                  "
                >
                  <td>{{ item.eventType }}</td>
                  <td>{{ eventCAM(item.camkey) }}</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.time }}</td>
                  <td class="pa-0 text-center">
                    <p class="px-2" :class="item.eventAction">
                      {{ eventAction(item.eventAction) }}
                    </p>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>

      <!-- -------- -->
      <!--list card event-->
      <!-- -------- -->
      <div>
        <div>
          <!--inf json-->
          <div v-if="eventsInf.length === 0">
            <p>No hay datos</p>
          </div>
          <div v-else id="ctCardInf">
            <div
              v-for="(item, i) in eventsInf"
              :key="i"
              class="ctCardInf"
              :id="item.key"
              :class="selectRowTe(item)"
              @click="
                selectedEvent = null;
                selectedEvent = item;
              "
            >
              <div>
                <img :src="item.url" />
              </div>
              <div>
                <div>
                  <span v-for="(cam, j) in cameras" :key="j">
                    <p v-if="cam.camkey === item.camkey">
                      <i class="fal fa-video"></i>
                      {{ cam.name }}
                    </p>
                  </span>
                </div>
                <div>
                  <p><i class="fal fa-calendar-alt"></i>{{ item.date }}</p>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    <i class="fal fa-exclamation-circle"></i>
                    {{ item.eventType }}
                  </p>
                </div>
                <div>
                  <p><i class="fal fa-clock"></i> {{ item.time }}</p>
                </div>
              </div>
              <div :class="item.eventAction">
                <p>
                  <i class="fal fa-bullhorn pl-3"></i>
                  {{ eventAction(item.eventAction) }}
                </p>
              </div>
              <div>
                <p>
                  <i class="fal fa-address-card pl-2"></i>
                  <span>[ {{ item.actionOrg }} ]</span> {{ item.actionUser }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button class="btnOne expCard" @click="expandsCards">
          <p>Cargar más</p>
        </button>
      </div>
    </div>
    <div id="ctModalEvent"></div>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../../globals";
import auth from "../../services/auth";

export default {
  name: "Vision",

  //-----------------------
  //global var
  //-----------------------
  data: () => ({
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    endpoinCam: "",
    modalEvent: false,
    contCards: 9,
    eventsInf: [],
    securityEvents: [],
    unsolvedEvents: [],
    cameras: [],
    selectedEvent: null,
    tableContent: [],
    loadingTable: false,
    modalTableContent: [],
    loadingModalTable: false,
    search: "",
    modal: true,
    searchModal: false,
    eventeDetailModal: false,
    eventeDetailModalLog: false,
    detailedEvent: null,
    minDate: null,
    maxDate: null,
    activateMenu: false,
    ConsultTableMes: "",
    tableHeaders: [
      { text: "Tipo", align: "center", value: "eventType", sortable: false },
      { text: "Cámara", align: "center", value: "camkey", sortable: false },
      { text: "Fecha", align: "center", value: "date", sortable: true },
      { text: "Hora", align: "center", value: "time", sortable: true },
      { text: "Acción", align: "center", value: "action", sortable: false },
    ],
    //modal log
    advancedQuery: false,
    bodyAdvancedQuery: [],
    selectAccionLog: [
      { name: "Seguridad", key: "Secure" },
      { name: "Inspección", key: "Inspect" },
      { name: "No Riesgo", key: "Norisk" },
      { name: "Falso positivo", key: "Pass" },
      { name: "Ninguno", key: "None" },
    ],
    selectCamLog: [],
    selectAlarmLog: [],
    selectClassLog: [
      { name: "Carro", key: "0" },
      { name: "Moto", key: "1" },
      { name: "Persona", key: "2" },
    ],
    noContent: false,
    //-----------------------------------
    allAlarms: 12,
    solveAllEvents: false,
    solveInBulk: {},
    test: false,
    tab: null,
    testCams: [],
    testSelected: null,
    loadingUnsolve: false,
    // ------------------------
    secureEvent: false,
    securityAlert: null,
    eventos: null
  }),

  //-----------------------
  //methods
  //-----------------------
  methods: {
    //table log
    selectRowTe(value) {
      if (
        this.selectedEvent &&
        value.eventkey === this.selectedEvent.eventkey
      ) {
        return "selectRowTable";
      }
    },

    selectRowFilter(value) {
      if (
        this.detailedEvent &&
        value.eventkey === this.detailedEvent.eventkey
      ) {
        return "selectRowTable";
      }
    },

    closeAllPopups () {
      this.unsolvedEvents.forEach(element => {
        element.status = false
      });
    },

    cleanFilterModal() {
      this.advancedQuery = false;
      this.bodyAdvancedQuery = [];
      this.noContent = false;
    },

    detailEventFilter(item) {
      this.detailedEvent = {};

      axios
        .get(globals.APIURLVision + "/cameras/" + item.eventkey + "/preview")
        .then(data => {
          if (data.status == 200) {
            item.signedURL = data.data;
            this.detailedEvent = item;
            this.eventeDetailModal = true
          }
        })
        .catch(err => {
          console.log(err)
        });
    },

    //-----------------------
    //method for select type of action
    //-----------------------
    eventAction(action) {
      switch (action) {
        case "Secure":
          return "Seguridad";
        case "Inspect":
          return "Inspección";
        case "Norisk":
          return "No Riesgo";
        case "Pass":
          return "Falso positivo";
        default:
          return "Ninguno";
      }
    },

    //-----------------------
    //method to go through the list of cameras
    //-----------------------
    eventCAM(cam) {
      for (let i = 0; i < this.cameras.length; i++) {
        const element = this.cameras[i];
        if (cam === element.camkey) {
          return element.name;
        }
      }
    },

    //-----------------------
    //method to get the endpoints
    //-----------------------
    requestGetEndpoint() {
      this.loadingTable = true;
      axios
        .all([
          axios.get(
            globals.APIURLVision +
              "/events/" +
              auth.get_asset_id() +
              "/security?n=" +
              this.contCards,
            {}
          ),
          axios.get(
            globals.APIURLVision + "/events/" + auth.get_asset_id() + "/10",
            {}
          ),
          axios.get(
            globals.APIURLVision +
              "/cameras/cameralist?assetkey=" +
              auth.get_asset_id(),
            {}
          ),
        ])
        .then(
          axios.spread((data1, data2, data3) => {
            for (let i = 0; i < data1.data.length; i++) {
              var dateEvt = new Date(data1.data[i].timestamp);
              data1.data[i].time = dateEvt.toLocaleTimeString();
              data1.data[i].date = dateEvt.toLocaleDateString();
            }
            this.eventsInf = data1.data;
            this.cameras = data3.data;

            //log event
            for (let i = 0; i < data3.data.length; i++) {
              this.selectCamLog.push({
                name: data3.data[i].name,
                key: data3.data[i].camkey,
              });
            }

            if (data2.data.length !== 0) {
              for (let i = 0; i < data2.data.length; i++) {
                var dateEvt2 = new Date(data2.data[i].timestamp);
                data2.data[i].time = dateEvt2.toLocaleTimeString();
                data2.data[i].date = dateEvt2.toLocaleDateString();
              }
              this.tableContent = data2.data;
              this.loadingTable = false;
            }
          })
        );
    },
    requestGetEndpointCronjob() {
      axios({
        method: "GET",
        baseURL:
          globals.APIURLVision + "/events/" + auth.get_asset_id() + "/10",
      }).then((data2) => {
        if (data2.data.length !== 0) {
          for (let i = 0; i < data2.data.length; i++) {
            var dateEvt2 = new Date(data2.data[i].timestamp);
            data2.data[i].time = dateEvt2.toLocaleTimeString();
            data2.data[i].date = dateEvt2.toLocaleDateString();
          }
          this.tableContent = data2.data;
          this.loadingTable = false;
        }
      });
    },

    //-----------------------
    //get security new event
    //-----------------------
    getSecurityEvents() {
      //this.test = true
      this.loadingUnsolve = true
      axios({
        method: "GET",
        baseURL:
          globals.APIURLVision + "/events/" + auth.get_asset_id() + "/unsolved",
      }).then((data) => {
        this.loadingUnsolve = false
        this.$store.commit('asignVisionNotifications', data.data.number)
        if (data.data.number > 0) {
          for (let i = 0; i < data.data.events.length; i++) {
            for (let j = 0; j < data.data.events[i].clusters.length; j++) {
              const cluster = data.data.events[i].clusters[j];
              for (let k = 0; k < data.data.events[i].clusters[j].events.length; k++) {
                const event = data.data.events[i].clusters[j].events[k];
                if (k == 0) {
                  event.status = true;
                } else {
                  event.status = false;
                }
              }
              if (j == 0) {
                cluster.status = true;
              } else {
                cluster.status = false;
              }            
            }
          }
          this.testCams = data.data.events;
          this.test = true;
        }
        // for (let i = 0; i < data.data.length; i++) {
        //   const element = data.data[i];
        //   console.log(element)
        // }
        // for (let i = 0; i < data.data.length; i++) {
        //   if (data.data[i].eventAction === undefined) {
        //     var dateEvt3 = new Date(data.data[i].timestamp);
        //     data.data[i].time = dateEvt3.toLocaleTimeString();
        //     data.data[i].date = dateEvt3.toLocaleDateString();
        //     const element = data.data[i];
        //     element.status = true;
        //     this.securityEvents.push(data.data[i]);
        //     console.log("ENTRE AQUIIIII!!!!!!!!!!!!!!!")
        //     this.$store.commit('pushVisionEvent', data.data[i])
        //   }
        // }
        // this.unsolvedEvents = this.securityEvents.reverse();
      }).catch(() => {
        this.loadingUnsolve = false;
      });
    },

    //-----------------------
    //Modal consulta del log de eventos
    //-----------------------
    cargarjson(obj) {
      this.ConsultTableMes = "";
      let filterObj = {};
      if (!obj.ts1 || !obj.ts2) {
        this.ConsultTableMes = "Los campos 'Desde' y 'Hasta' son obligatorios.";
      } else if (obj.ts1 > obj.ts2) {
        this.ConsultTableMes =
          "El campo 'Desde' debe ser menor que el campo 'Hasta'.";
      } else {
        this.loadingModalTable = true
        if (!obj.eventAction || obj.eventAction.length == 0) {
          obj.eventAction = [];
          for (let i = 0; i < this.selectAccionLog.length; i++) {
            obj.eventAction.push(this.selectAccionLog[i].key);
          }
        }

        if (!obj.camkey || obj.camkey.length == 0) {
          obj.camkey = [];
          for (let i = 0; i < this.selectCamLog.length; i++) {
            obj.camkey.push(this.selectCamLog[i].key);
          }
        }

        if (!obj.class || obj.class.length == 0) {
          obj.class = [];
          for (let i = 0; i < this.selectClassLog.length; i++) {
            obj.class.push(this.selectClassLog[i].key);
          }
        }

         /* if (!obj.alarm || obj.alarm.length == 0) {
           obj.alarm = ["detection"];
           for (let i = 0; i < obj.camkey.length; i++) {
             axios({
               method: "GET",
               baseURL:
                 globals.APIURLVision + "/cameras/" + obj.camkey[i] + "/alarms",
             }).then((data) => {
               if (data.data) {
                 for (let j = 0; j < data.data.length; j++) {
                   if (!obj.alarm.includes(data.data[j].name)) {
                     obj.alarm.push(data.data[j].name);
                   }
                 }
               }
             });
           }
         } */

        //let startFilterDate = new Date(obj.ts1);
        //let finishFilterDate = new Date(obj.ts2);
        let nigthTS2 = obj.ts2 + "T23:59:59.000Z"
        filterObj.assetkey = auth.get_asset_id();
        filterObj.ts1 = this.timestampCO(obj.ts1, "0")//startFilterDate.getTime(); 
        filterObj.ts2 = this.timestampCO(nigthTS2, "0")//finishFilterDate.getTime();
        filterObj.camkey = obj.camkey;
        filterObj.eventAction = obj.eventAction;
        filterObj.classes = obj.class ? obj.class : [];
        filterObj.roi = [];
        this.modalTableContent = [];
        this.noContent = false;

        axios
          .post(globals.APIURLVision + "/events/filter", filterObj)
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.loadingModalTable = false
              for (let i = 0; i < res.data.length; i++) {
                  let eventDatetime = new Date(res.data[i].timestamp);
                  res.data[i].time = eventDatetime.toLocaleTimeString();
                  res.data[i].date = eventDatetime.toLocaleDateString();
              }
              this.modalTableContent = res.data;
              this.noContent = false
            } else {
              this.noContent = true;
            }
            this.loadingModalTable = false
          }).catch(err => {
            this.loadingModalTable = false
            console.log(err)
          });
      }

      //   axios({
      //         method: 'GET',
      //         url: globals.APIURLVision + '/events/' + auth.get_asset_id() + '/table',
      //         headers: {
      //             "Access-Control-Allow-Origin": "*",
      //             "Content-Type": "application/json",
      //         },
      //         withCredentials: false,
      //         params: {
      //             preDate: el1,
      //             postDate: el2
      //         },
      //     }).then((data) => {
      //       this.loadingModalTable = false
      //         this.modalTableContent = data.data.events
      //         /* let filteredAccounts = data.data.events;
      //         tablaConsult(filteredAccounts); */
      //     }).catch((err) => {
      //       this.loadingModalTable = false
      //       console.log(err);
      //   });
    },

    changeCamLog(obj) {
      this.selectAlarmLog = [];
      this.selectAlarmLog = [{ name: "detection", key: "detection" }];
      for (let i = 0; i < obj.length; i++) {
        axios({
          method: "GET",
          baseURL: globals.APIURLVision + "/cameras/" + obj[i] + "/alarms",
        }).then((data) => {
          if (data.data.body) {
            for (let j = 0; j < data.data.length; j++) {
              this.selectAlarmLog.push({
                name: data.data[j].name,
                key: data.data[j].name,
                keyCam: data.data[j].camkey,
              });
            }
          }
        });
      }
    },

    //-----------------------
    //post new event / accion
    //-----------------------
    actionNewEventDet(e, key, log, camIndex, clusterIndex, eventIndex) {
      console.log(e, key, log, camIndex, clusterIndex, eventIndex);
      let nameEvent;

      switch (e) {
        case 1:
          nameEvent = "Secure";
          break;
        case 2:
          nameEvent = "Inspect";
          break;
        case 3:
          nameEvent = "Norisk";
          break;
        case 4:
          nameEvent = "Pass";
          break;
      }

      if (this.testCams[camIndex].clusters[clusterIndex].events.length === 1) {
        if (nameEvent == "Secure") {
          //this.secureEvent = true
          this.eventos = this.testCams[camIndex].clusters[clusterIndex].events
          console.log("[ALERT]: Es un solo evento y es Secure.")
          axios({
            method: "post",
            url: globals.APIURLVision + "/events/action/",
            data: {
              key: {
                assetkey: auth.get_asset_id(),
                eventkey: [key],
              },
              action: {
                action: nameEvent,
                actionUser: auth.getUsername(),
                actionOrg: auth.get_org(),
                log: log === undefined ? "" : log,
              },
            },
          })
            .then((res) => {
              console.log("RESPUESTA DE BULK: ", res)
              if (res.status == 200 || res.status == 201) {
                this.secureEvent = true
                this.testCams[camIndex].clusters[clusterIndex].events.splice(eventIndex, 1)
                if (this.testCams[camIndex].clusters[clusterIndex].events.length > 0) {
                  if (eventIndex > 0) {
                    this.testCams[camIndex].clusters[clusterIndex].events[eventIndex - 1].status = true
                  } else {
                    this.testCams[camIndex].clusters[clusterIndex].events[eventIndex].status = true
                  }
                } else {
                  this.testCams[camIndex].clusters.splice(clusterIndex, 1)
                }

                if (this.testCams[camIndex].clusters.length > 0) {
                  if (clusterIndex == this.testCams[camIndex].clusters.length) {
                    this.testCams[camIndex].clusters[clusterIndex - 1].status = true
                  } else {
                    this.testCams[camIndex].clusters[clusterIndex].status = true
                  }
                } else {
                  this.testCams.splice(camIndex, 1)
                }

                if (this.testCams.length === 0) {
                  this.test = false;
                }
                this.requestGetEndpoint();
                this.$store.commit('removeVisionNotification', 1)
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // TODO: JUST UNCOMMENT
          axios({
            method: "post",
            url: globals.APIURLVision + "/events/action/",
            data: {
              key: {
                assetkey: auth.get_asset_id(),
                eventkey: [key],
              },
              action: {
                action: nameEvent,
                actionUser: auth.getUsername(),
                actionOrg: auth.get_org(),
                log: log === undefined ? "" : log,
              },
            },
          })
            .then((res) => {
              console.log("RESPUESTA DE BULK: ", res)
              if (res.status == 200 || res.status == 201) {
                this.testCams[camIndex].clusters[clusterIndex].events.splice(eventIndex, 1)
                if (this.testCams[camIndex].clusters[clusterIndex].events.length > 0) {
                  if (eventIndex > 0) {
                    this.testCams[camIndex].clusters[clusterIndex].events[eventIndex - 1].status = true
                  } else {
                    this.testCams[camIndex].clusters[clusterIndex].events[eventIndex].status = true
                  }
                } else {
                  this.testCams[camIndex].clusters.splice(clusterIndex, 1)
                }

                if (this.testCams[camIndex].clusters.length > 0) {
                  if (clusterIndex == this.testCams[camIndex].clusters.length) {
                    this.testCams[camIndex].clusters[clusterIndex - 1].status = true
                  } else {
                    this.testCams[camIndex].clusters[clusterIndex].status = true
                  }
                } else {
                  this.testCams.splice(camIndex, 1)
                }

                if (this.testCams.length === 0) {
                  this.test = false;
                }
                this.requestGetEndpoint();
                this.$store.commit('removeVisionNotification', 1)
              }
            })
            .catch((err) => {
              console.log(err);
            });
          }
      } else {
        let keysToSolve = []
        for (let i = 0; i < this.testCams[camIndex].clusters[clusterIndex].events.length; i++) {
          keysToSolve.push(this.testCams[camIndex].clusters[clusterIndex].events[i].eventkey)
        }
        this.solveInBulk = {
          eventBulk: keysToSolve,
          individualEvent: key,
          log: log,
          nameEvent: nameEvent,
          camIndex: camIndex, 
          clusterIndex: clusterIndex,
          eventIndex: eventIndex
        }
        this.solveAllEvents = true;
      }
    },

    actionNewEventBulk(typeOfAction) {
      if (typeOfAction === 'bulk') {
        if (this.solveInBulk.nameEvent == "Secure") {
          //this.secureEvent = true;
          this.eventos = this.solveInBulk
          console.log("[ALERT]: Es Secure y son varios.")
          axios({
            method: "post",
            url: globals.APIURLVision + "/events/action/",
            data: {
              key: {
                assetkey: auth.get_asset_id(),
                eventkey: this.solveInBulk.eventBulk,
              },
              action: {
                action: this.solveInBulk.nameEvent,
                actionUser: auth.getUsername(),
                actionOrg: auth.get_org(),
                log: this.solveInBulk.log === undefined ? "" : this.solveInBulk.log,
              },
            },
          })
            .then((res) => {
              if (res.status == 200 || res.status == 201) {
                this.secureEvent = true
                this.testCams[this.solveInBulk.camIndex].clusters.splice(this.solveInBulk.clusterIndex, 1)

                if (this.testCams[this.solveInBulk.camIndex].clusters.length > 0) {
                  if (this.solveInBulk.clusterIndex == this.testCams[this.solveInBulk.camIndex].clusters.length) {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex - 1].status = true
                  } else {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].status = true
                  }
                } else {
                  this.testCams.splice(this.solveInBulk.camIndex, 1)
                }

                if (this.testCams.length === 0) {
                  this.test = false;
                }  
                this.requestGetEndpoint();
                this.$store.commit('removeVisionNotification', this.solveInBulk.eventBulk.length)
                this.solveAllEvents = false;
                this.solveInBulk = {};
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // TODO: JUST UNCOMMENT
          axios({
            method: "post",
            url: globals.APIURLVision + "/events/action/",
            data: {
              key: {
                assetkey: auth.get_asset_id(),
                eventkey: this.solveInBulk.eventBulk,
              },
              action: {
                action: this.solveInBulk.nameEvent,
                actionUser: auth.getUsername(),
                actionOrg: auth.get_org(),
                log: this.solveInBulk.log === undefined ? "" : this.solveInBulk.log,
              },
            },
          })
            .then((res) => {
              if (res.status == 200 || res.status == 201) {
                this.testCams[this.solveInBulk.camIndex].clusters.splice(this.solveInBulk.clusterIndex, 1)

                if (this.testCams[this.solveInBulk.camIndex].clusters.length > 0) {
                  if (this.solveInBulk.clusterIndex == this.testCams[this.solveInBulk.camIndex].clusters.length) {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex - 1].status = true
                  } else {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].status = true
                  }
                } else {
                  this.testCams.splice(this.solveInBulk.camIndex, 1)
                }

                if (this.testCams.length === 0) {
                  this.test = false;
                }  
                this.requestGetEndpoint();
                this.$store.commit('removeVisionNotification', this.solveInBulk.eventBulk.length)
                this.solveAllEvents = false;
                this.solveInBulk = {};
              }
            })
            .catch((err) => {
              console.log(err);
            });
          }
      } else {
        if (this.solveInBulk.nameEvent == "Secure") {
          //this.secureEvent = true;
          this.eventos = this.solveInBulk
          console.log("[ALERT]: Es Secure y es uno solo.")
          axios({
            method: "post",
            url: globals.APIURLVision + "/events/action/",
            data: {
              key: {
                assetkey: auth.get_asset_id(),
                eventkey: [this.solveInBulk.individualEvent],
              },
              action: {
                action: this.solveInBulk.nameEvent,
                actionUser: auth.getUsername(),
                actionOrg: auth.get_org(),
                log: this.solveInBulk.log === undefined ? "" : this.solveInBulk.log,
              },
            },
          })
            .then((res) => {
              if (res.status == 200 || res.status == 201) {
                this.secureEvent = true
                this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].events.splice(this.solveInBulk.eventIndex, 1)
                if (this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].events.length > 0) {
                  if (this.solveInBulk.eventIndex > 0) {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].events[this.solveInBulk.eventIndex - 1].status = true
                  } else {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].events[this.solveInBulk.eventIndex].status = true
                  }
                } else {
                  this.testCams[this.solveInBulk.camIndex].clusters.splice(this.solveInBulk.clusterIndex, 1)
                }

                if (this.testCams[this.solveInBulk.camIndex].clusters.length > 0) {
                  if (this.solveInBulk.clusterIndex == this.testCams[this.solveInBulk.camIndex].clusters.length) {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex - 1].status = true
                  } else {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].status = true
                  }
                } else {
                  this.testCams.splice(this.solveInBulk.camIndex, 1)
                }

                if (this.testCams.length === 0) {
                  this.test = false;
                }    
                this.requestGetEndpoint();
                this.$store.commit('removeVisionNotification', 1)
                this.solveAllEvents = false;
                this.solveInBulk = {};
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // TODO: JUST UNCOMMENT
          axios({
            method: "post",
            url: globals.APIURLVision + "/events/action/",
            data: {
              key: {
                assetkey: auth.get_asset_id(),
                eventkey: [this.solveInBulk.individualEvent],
              },
              action: {
                action: this.solveInBulk.nameEvent,
                actionUser: auth.getUsername(),
                actionOrg: auth.get_org(),
                log: this.solveInBulk.log === undefined ? "" : this.solveInBulk.log,
              },
            },
          })
            .then((res) => {
              if (res.status == 200 || res.status == 201) {
                this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].events.splice(this.solveInBulk.eventIndex, 1)
                if (this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].events.length > 0) {
                  if (this.solveInBulk.eventIndex > 0) {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].events[this.solveInBulk.eventIndex - 1].status = true
                  } else {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].events[this.solveInBulk.eventIndex].status = true
                  }
                } else {
                  this.testCams[this.solveInBulk.camIndex].clusters.splice(this.solveInBulk.clusterIndex, 1)
                }

                if (this.testCams[this.solveInBulk.camIndex].clusters.length > 0) {
                  if (this.solveInBulk.clusterIndex == this.testCams[this.solveInBulk.camIndex].clusters.length) {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex - 1].status = true
                  } else {
                    this.testCams[this.solveInBulk.camIndex].clusters[this.solveInBulk.clusterIndex].status = true
                  }
                } else {
                  this.testCams.splice(this.solveInBulk.camIndex, 1)
                }

                if (this.testCams.length === 0) {
                  this.test = false;
                }    
                this.requestGetEndpoint();
                this.$store.commit('removeVisionNotification', 1)
                this.solveAllEvents = false;
                this.solveInBulk = {};
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    actionNewEventBySocket(e, key, log) {
      console.log(key, log)
      let nameEvent;
      switch (e) {
        case 1:
          nameEvent = "Secure";
          break;
        case 2:
          nameEvent = "Inspect";
          break;
        case 3:
          nameEvent = "Norisk";
          break;
        case 4:
          nameEvent = "Pass";
          break;
      }
      if (nameEvent == "Secure") {
        //this.secureEvent = true
        this.eventos = e
        console.log("[ALERT]: Evento del socket y es Secure.")
        axios({
          method: "post",
          url: globals.APIURLVision + "/events/action/",
          data: {
            key: {
              assetkey: auth.get_asset_id(),
              eventkey: [key],
            },
            action: {
              action: nameEvent,
              actionUser: auth.getUsername(),
              actionOrg: auth.get_org(),
              log: log === undefined ? "" : log,
            },
          },
        })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              this.secureEvent = true
              this.$store.commit('removeVisionNotification', 1)
              this.requestGetEndpoint();
              //this.getSecurityEvents() // TODO...
              //this.$bus.$emit("visionNotification", "Get Popups")
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // TODO: JUST UNCOMMENT
        axios({
          method: "post",
          url: globals.APIURLVision + "/events/action/",
          data: {
            key: {
              assetkey: auth.get_asset_id(),
              eventkey: [key],
            },
            action: {
              action: nameEvent,
              actionUser: auth.getUsername(),
              actionOrg: auth.get_org(),
              log: log === undefined ? "" : log,
            },
          },
        })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              this.$store.commit('removeVisionNotification', 1)
              this.requestGetEndpoint();
              //this.getSecurityEvents() // TODO...
              //this.$bus.$emit("visionNotification", "Get Popups")
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    //-----------------------
    //method to increment the card list counter
    //-----------------------
    expandsCards() {
      this.contCards = this.contCards + 9;
      this.requestGetEndpoint();
    },

    //-----------------------
    //function for edit the action
    editAction(e, key, log) {
      console.log(key, log)
      let nameEvent; //, nameEvent, colorEven;

      switch (e) {
        case 1:
          nameEvent = "Secure";
          break;
        case 2:
          nameEvent = "Inspect";
          break;
        case 3:
          nameEvent = "Norisk";
          break;
        case 4:
          nameEvent = "Pass";
          break;
      }

      if (nameEvent == "Secure") {
        //this.secureEvent = true
        this.eventos = e
        console.log("[ALERT]: Evento del socket y es Secure.")
        axios({
          method: "post",
          url: globals.APIURLVision + "/events/action/",
          data: {
            key: {
              assetkey: auth.get_asset_id(),
              eventkey: [key],
            },
            action: {
              action: nameEvent,
              actionUser: auth.getUsername(),
              actionOrg: auth.get_org(),
              log: log === undefined ? "" : log,
            },
          },
        })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              this.secureEvent = true
              this.requestGetEndpoint();
              this.selectedEvent.eventAction = nameEvent;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios({
          method: "post",
          url: globals.APIURLVision + "/events/action/",
          data: {
            key: {
              assetkey: auth.get_asset_id(),
              eventkey: [key],
            },
            action: {
              action: nameEvent,
              actionUser: auth.getUsername(),
              actionOrg: auth.get_org(),
              log: log === undefined ? "" : log,
            },
          },
        })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              this.requestGetEndpoint();
              this.selectedEvent.eventAction = nameEvent;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    newEvent(evt) {
      let dateEvt3 = new Date(parseInt(evt.timestamp));
      evt.time = dateEvt3.toLocaleTimeString();
      evt.date = dateEvt3.toLocaleDateString();
      evt.status = true;
      this.unsolvedEvents.push(evt);
      this.$store.commit('asignVisionNotifications', 1)
      console.log(evt)
      this.getSecurityEvents();
    },
    
    timestampCO(entry_date, offset) {
      const date = new Date(entry_date)

      const utc = date.getTime() + (date.getTimezoneOffset() * 60000)

      const exactDatetime = new Date(utc + (3600000 * offset))

      //const exactDate = exactDatetime.getFullYear() + '-' + (exactDatetime.getMonth() + 1 > 9 ? '' : '0') + (exactDatetime.getMonth() + 1) + '-' + (exactDatetime.getDate() > 9 ? '' : '0') + (exactDatetime.getDate())
      //const exactTime = (exactDatetime.getHours() > 9 ? '' : '0') + (exactDatetime.getHours()) + ':' + (exactDatetime.getMinutes() > 9 ? '' : '0') + (exactDatetime.getMinutes()) + ':' + (exactDatetime.getSeconds() > 9 ? '' : '0') + (exactDatetime.getSeconds())

      return exactDatetime.getTime() /* + " - " + exactDate + " - " + exactTime */
    },

    timestampLessOffset(event_timestamp) {
      const date = new Date(event_timestamp)

      const utc = date.getTime() + (date.getTimezoneOffset() * 60000)

      const exactDatetime = new Date(utc + (3600000 * ("0")))

      return exactDatetime.getTime()
    },

    // * Security Alert Functions
    sendAlertSignal() {
      axios({
        method: "POST",
        url: globals.APIURL + "org/send-security-alert",
        data: {
          company_id: auth.get_asset_id(),
          name_place: auth.get_org_sede(),
          security_alert_data: this.securityAlert,
          events_data: this.eventos
        }
      }).then((res) => {
        console.log(res)
        this.snackbar = true;
        this.snackbarColor = "success";
        this.snackbarText = "Se genero la alerta correctamente.";
        this.eventos = null;
        this.secureEvent = false;
      }).catch((error) => {
        console.log(error)
      });
    },

    getConfig() {
      axios({
        method: "POST",
        url: globals.APIURL + "org/get-org-config",
        data: {
          company_id: auth.get_asset_id()
        }
      }).then((res) => {
        if (res.data && res.data.status == 200) {
          this.securityAlert = res.data.data
        } else {
          console.log("Hubo un problema cargando la configuracion.")
        }
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  created() {
    this.$bus.$emit("visionNotification", "Get Popups")
    //-----------------------
    //websocket
    //-----------------------
    let that = this;
    this.WSConnection = new WebSocket(
      "wss://30bydkelbl.execute-api.us-east-1.amazonaws.com/dev?userid=" +
        auth.getUsername() +
        "&assetkey=" +
        auth.get_asset_id()
    );

    this.WSConnection.onopen = function (event) {
      console.log(event);
      console.log("Successfully connected to the echo websocket server...");
      this.unsolvedEvents = [];
    };

    this.WSConnection.onmessage = function (event) {
      if (event.data) {
        let evt = JSON.parse(event.data);
        that.newEvent(evt);
      }
    };
  },
  mounted() {
    this.requestGetEndpoint();
    this.getConfig()
    this.getSecurityEvents();
    //setInterval(() => this.requestGetEndpointCronjob(), 300000);
    setInterval(() => this.requestGetEndpointCronjob(), 10000);
    this.$bus.$on("popupsVision", () => {
      this.getSecurityEvents();
    });
  },
  beforeDestroy() {
    this.WSConnection.close();
  },
};
</script>
<style>
.no-content {
  color: #68789e;
  width: calc(100% - 40px);
  width: 470px;
  font-size: 1.5rem;
  margin: 0 auto !important;
  text-align: center;
}

.collapseOne {
  position: absolute;
  right: 10px;
  top: 12px;
  display: inline-block;
  width: 45px;
  background: initial;
  padding: 5px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 100;
}

.collapseAll {
  position: absolute;
  right: 45px;
  top: 12px;
  display: inline-block;
  width: 45px;
  background: initial;
  padding: 5px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 100;
}

.contentCluster {
  position: absolute;
  left: 16px;
  display: inline-block;
  width: 45px;
  background: initial;
  padding: 5px;
  font-size: 1rem;
  color: #fff;
  font-weight: 100;
}

.v-tab--active {
  color: white !important;
}

.v-tabs-slider-wrapper {
    bottom: 0;
    margin: 0 !important;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    z-index: 1;
    color: white;
}
</style>