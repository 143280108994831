<template>
  <v-container class="pa-0" style="height: 100%">
	<v-overlay opacity="1" :value="askToCloseChatbot">
			<v-card dark>
				<v-card-text class="pt-0">
					<v-row>
						<v-col cols="12" class="d-flex justify-center">
							<p>¿Esta seguro de terminar la conversación?</p>
						</v-col>
						<v-col cols="12" class="d-flex justify-center ma-0 pa-0">
							<v-btn class="mt-1 mr-1" color="primary" x-small dark @click="closeModal()">
								Si
							</v-btn>
							<v-btn class="mt-1 mr-1" color="error" x-small dark @click="askToCloseChatbot = false">
								No
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-overlay>

		<v-card class="ma-0">
			<v-row class="ma-0">
				<v-col cols="12">
					<v-card>
						<div align="right" class="pr-2 pt-1 ma-0" @click="askToCloseModal()" style="cursor: pointer">
							<v-icon
								class="text-center"
								color="white"
								size="25"
							>
								mdi-close-circle
							</v-icon>
						</div>
						<v-card-title class="headline font-weight-bold">Charlie AI</v-card-title>
						<v-divider></v-divider>
						<v-card-text class="chat-container">
							<div v-if="messages.length == 0" class="text-center">
								<small>--- Empieza la conversación ---</small>
							</div>
							<div v-for="(message, index) in messages" :key="index" class="message" :class="message.role != 'assistant' ? 'my-message' : 'chat-message'">
								{{ message.content[0].text.value }}
							</div>
							<div v-if="loadingResponse" class="ma-0 pa-0" style="width: 6%;">
								<v-progress-linear
									color="white"
									buffer-value="0"
									stream
								></v-progress-linear>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row class="ma-0">
				<v-col cols="12">
					<v-row align="center" class="ma-0">
						<v-col cols="9">
							<v-textarea
								v-model="newMessage"
								label="Escribe tu mensaje"
								solo
								outlined
								auto-grow
								rows="1"
								clearable
								hide-details
								dense
								@keydown.enter="sendMessage(newMessage)"
							></v-textarea>
						</v-col>
						<v-col cols="3" class="text-center">
							<v-btn @click="sendMessage(newMessage)" :disabled="!newMessage" color="primary">Enviar</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card>

    
    <v-snackbar v-model="snackbar" timeout="10000" :color="snackbarColor" top>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
//import globals from "../../globals";
import axios from "axios";
//import auth from "../../services/auth";
//import backlog from "../services/logs";

export default {
  name: "ChatBot",
  props: ['session_data'],
  data: () => ({
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    messages: [],
    newMessage: '',
		assistantID: "asst_Zs1zInCDO550QLUrKMES38jc",
		threadID: null,
		messageID: null,
		runID: null,
		loadingResponse: false,
		askToCloseChatbot: false
  }),
  methods: {
    /* sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.messages.push(`Yo: ${this.newMessage}`);
        this.newMessage = '';
        //this.modalVisible = false; // Cerrar el modal después de enviar el mensaje
      }
    } */
		/* sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.messages.push({ text: this.newMessage, isMe: true });
        // Simulamos una respuesta del otro usuario después de un breve retraso
        setTimeout(() => {
          this.messages.push({ text: 'Hola, soy CharlieAI, en que te puedo ayudar?', isMe: false });
        }, 500);
        this.newMessage = '';
      }
    }, */

		askToCloseModal() {
			this.askToCloseChatbot = true
		},

		closeModal() {
			this.askToCloseModal = false
			this.$bus.$emit("closeChatSignal", false);
		},

		createThread() {
			axios({
        method: 'POST',
        url: "https://api.openai.com/v1/threads",
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer sk-proj-674fuvP7eVG7Id6Xu0OCT3BlbkFJCoduV6VSxV5XviiZw5od',
          'OpenAI-Beta': 'assistants=v2',
        },
        data: {}
      }).then((res) => {
				this.threadID = res.data.id
        /* console.log("CREATION THREAD RESPONSE OPEN-AI : ", res)
        console.log("ABCD: ", this.threadID) */
      })
      .catch((err) => {
        console.log("CREATION THREAD ERROR OPEN-AI: ", err)
      });
		},

		sendMessage(newMsg) {
			axios({
        method: 'POST',
        url: "https://api.openai.com/v1/threads/" + this.threadID + "/messages",
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer sk-proj-674fuvP7eVG7Id6Xu0OCT3BlbkFJCoduV6VSxV5XviiZw5od',
          'OpenAI-Beta': 'assistants=v2',
        },
        data: {
          "role": "user",//this.session_data.first_name + " " + this.first_last_name,
          "content": newMsg
        }
      }).then((res) => {
				this.messageID = res.data.id
				this.messages.push(res.data)
				this.newMessage = ''
				this.loadingResponse = true
        console.log("ASK MESSAGE RESPONSE OPEN-AI: ", res)
				this.makeRun()
      })
      .catch((err) => {
        console.log("ASK MESSAGE RESPONSE OPEN-AI: ", err)
				this.loadingResponse = false
      });
		},

		makeRun() {
			// * SEND RUN WITH THE MSG
			axios({
				method: 'POST',
				url: "https://api.openai.com/v1/threads/" + this.threadID + "/runs",
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer sk-proj-674fuvP7eVG7Id6Xu0OCT3BlbkFJCoduV6VSxV5XviiZw5od',
					'OpenAI-Beta': 'assistants=v2',
				},
				data: {
					"assistant_id": this.assistantID
				}
			}).then((res) => {
				this.runID = res.data.id 
				console.log("RUN THREAD RESPONSE OPEN-AI: ", res)
				this.validateRunStatus()
			})
			.catch((err) => {
				console.log("RUN THREAD ERROR OPEN-AI: ", err)
				this.loadingResponse = false
			}); 
		},

		validateRunStatus() {
			axios({
        method: 'GET',
        url: "https://api.openai.com/v1/threads/" + this.threadID + "/runs/" + this.runID,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer sk-proj-674fuvP7eVG7Id6Xu0OCT3BlbkFJCoduV6VSxV5XviiZw5od',
          'OpenAI-Beta': 'assistants=v2',
        }
      }).then((res) => {
				console.log("RETRIEVE RUN THREAD RESPONSE OPEN-AI: ", res)
				if (res.data.status == 'completed') {
					this.responseOfMsg()
				} else {
					setTimeout(() => { 
						this.validateRunStatus()
					}, 3000);
				}
      })
      .catch((err) => {
        console.log("RETRIEVE RUN THREAD ERROR OPEN-AI: ", err)
				this.loadingResponse = false
      });
		},

		responseOfMsg() {
			// * GET THE MSGs TO ANSWER
			axios({
				method: 'GET',
				url: "https://api.openai.com/v1/threads/" + this.threadID + "/messages",
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer sk-proj-674fuvP7eVG7Id6Xu0OCT3BlbkFJCoduV6VSxV5XviiZw5od',
					'OpenAI-Beta': 'assistants=v2',
				}
			}).then((res) => {
				this.loadingResponse = false
				console.log("ALL MESSAGES RESPONSE OPEN-AI: ", res)
				this.messages.push(res.data.data[0])
			})
			.catch((err) => {
				console.log("ALL MESSAGES ERROR OPEN-AI: ", err)
				this.loadingResponse = false
			}); 
		}
  },
  mounted() {
		this.createThread();
		//console.log(this.session_data)
  },
	beforeDestroy() {
    console.log("Se termino chat!")
  },
};
</script>
<style scoped>
	.chat-container {
    height: 300px;
    overflow-y: auto;
  }

  .message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .my-message {
    background-color: lightseagreen;
		color: white;
    align-self: flex-end;
		margin-left: 50px;
  }

	.chat-message {
    background-color: lightblue;
		color: black;
    align-self: flex-start;
		margin-right: 50px;
  }
</style>